import { useEffect, useState } from "react";
import GarageRegistrationStatistics from "src/components/Finance/Garage/GarageRegistrationStatistics";
import GarageRegistrationTable from "src/components/Finance/Garage/GarageRegistrationTable";
import Header from "src/components/Header/Header";
import { ddmmyyyy } from "src/helper/getFormattedDate";
import t from "src/hooks/useTranslate";
import {
  useFinanceGarageQuery,
  useFinanceGarageStatisticsQuery,
} from "src/services/financeService";

const Garage = () => {
  const [value, setValue] = useState(ddmmyyyy(new Date()));
  const { data: garageStatistics, isLoading: garageStatisticsLoading } =
    useFinanceGarageStatisticsQuery("");

  const [params, setParams] = useState("");
  const {
    data: garage,
    refetch,
    isFetching: garageLoading,
  } = useFinanceGarageQuery(params);

  useEffect(() => {
    refetch();
  }, [params]);

  return (
    <>
      <Header title={t("garage")} />
      <div className="space-y-6">
        <GarageRegistrationStatistics
          data={garageStatistics}
          isLoading={garageStatisticsLoading}
          value={value}
          setValue={setValue}
        />
        <GarageRegistrationTable
          data={garage}
          isLoading={garageLoading}
          setParams={setParams}
          value={value}
          setValue={setValue}
        />
      </div>
    </>
  );
};

export default Garage;
