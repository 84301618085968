import React, { useCallback, useEffect, useState } from "react";
import t from "src/hooks/useTranslate";
import Button from "src/components/Common/Inputs/Button";
import InputRangeDatePicker from "src/components/Common/Inputs/InputRangeDatePicker";
import InputSearch from "src/components/Common/Inputs/InputSearch";
import Header from "src/components/Header/Header";
import RequestsSidebar from "src/components/Requests/RequestsSidebar";
import RequestsTable from "src/components/Requests/RequestsTable";
import { Link } from "react-router-dom";
import {
  useGetRequestPartiallyQuery,
  useGetRequestsStatusQuery,
} from "src/services/requestsService";
import usePermission from "src/hooks/usePermission";
import { EPermissions } from "src/enums/e_permissions";
import Pagination from "src/components/Common/Pagination";

const Requests = () => {
  const { data: requestsStatus, isLoading: isRequestStatusesLoading } =
    useGetRequestsStatusQuery("");
  const [params] = useState(new URLSearchParams());
  const [paramsState, setParamsState] = useState<any>();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  
  const {
    data: requests,
    refetch,
    isFetching: isRequestsLoading,
    isSuccess,
  } = useGetRequestPartiallyQuery(
    params.toString() != "" ? "?" + params.toString() : ""
  );
  const [activeTab, setActiveTab] = useState(requestsStatus?.[0]);

  const onStatusChange = ({ key, value }) => {
    if (key == "all") {
      params.delete("status");
      setParamsState(params.toString());
    } else {
      params.set(key, value);
      setParamsState(params.toString());
    }
  };

  useEffect(() => {
    refetch();
  }, [paramsState, page, perPage]);

  return (
    <>
      <Header title={t("requests")} />
      <div className="w-full relative bg-white dark:bg-lightBlack rounded-xl drop-shadow-lg z-10">
        <div className="flex items-center gap-x-4 p-4 border-b border-solid border-gray">
          <InputSearch
            data={[]}
            placeholder={t("search")}
            showItemCount={5}
            optionHidden
            onInput={(query: string) => {
              params.delete("page")
              query == ""
                ? params.delete("search")
                : params.set("search", query);
              setParamsState(params.toString());
            }}
          />
          <InputRangeDatePicker
            className="w-56"
            onChange={(e: string) => {
              params.delete("page")
              e == "__.__.____-__.__.____"
                ? params.delete("date")
                : params.set("date", e);
              setParamsState(params.toString());
            }}
          />
          
            <Link
              to="create"
              className="bg-blue text-white !px-20 whitespace-nowrap rounded"
            >
              <p className="flex items-center gap-x-1">
                <span className="text-30">+</span> {t("new_request")}
              </p>
            </Link>
          
        </div>
        <div className="flex">
          <RequestsSidebar
            data={requestsStatus}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            isLoading={isRequestStatusesLoading}
            onStatusChange={onStatusChange}
          />
          <RequestsTable
            data={requests?.data}
            activeTab={activeTab}
            isLoading={isRequestsLoading}
          />
        </div>
      {requests?.meta.total > 5 && (
          <div className="px-4 py-4 border-t border-gray">
            <Pagination
              page={Math.ceil(requests?.meta.total / perPage)}
              total={requests?.meta.total}
              perPage={perPage}
              pageChange={(e) => {
                setPage(e + 1);
                params.set("page", (e + 1).toString());
              }}
              setPerPage={(val) => {
                setPerPage(val);
                params.set("per_page", val.toString());
              }}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default Requests;
