import React, { useEffect, useState } from "react";
import Header from "src/components/Header/Header";
import NotificationsTable from "src/components/Notifications/NotificationsTable";
import t from "src/hooks/useTranslate";
import {
  useMarkAsReadMutation,
  useNotificationsQuery,
} from "src/services/notificationService";

const Notifications = () => {
  const [params, setParams] = useState("");
  const {
    data: notifications,
    refetch,
    isFetching: isNotificationsLoading,
    isSuccess,
  } = useNotificationsQuery(params);

  const [markAsRead, { isLoading, isSuccess: markedAsRead }] =
    useMarkAsReadMutation();

  const onMarkAsRead = async (id) => {
    await markAsRead({ notification: id });
  };

  useEffect(() => {
    refetch();
  }, [params]);

  return (
    <>
      <Header title={t("notifications")} />
      <NotificationsTable
        data={notifications}
        isLoading={isNotificationsLoading && !markedAsRead}
        setParams={setParams}
        onMarkAsRead={onMarkAsRead}
      />
    </>
  );
};

export default Notifications;
