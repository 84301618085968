import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getCookie } from "src/helper/custom_cookies";

export const financeService = createApi({
  reducerPath: "financeService",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: (headers) => {
      headers.set(
        "Authorization",
        getCookie("mcrmtkn") ? "Bearer " + getCookie("mcrmtkn") : ""
      );
      headers.set("Accept", "application/json");
      return headers;
    },
    // credentials: "include",
  }),
  tagTypes: ["Finance", "MashinalAppNotification", "MashinalAppRoutes"],
  endpoints: (builder) => ({
    financeDashboard: builder.query({
      query: (query) => {
        return {
          url: `/finance/dashboard${query}`,
        };
      },
      providesTags: ["Finance"],
    }),
    financeDashboardStatistics: builder.query({
      query: (query) => {
        return {
          url: `/finance/dashboard_statistic${query}`,
        };
      },
    }),
    financeDashboardDetailStatistics: builder.query({
      query: (id) => {
        return {
          url: `/finance/auto_salon/${id}/detail`,
        };
      },
    }),
    financeDashboardDetail: builder.query({
      query: ({ id, query }) => {
        return {
          url: `/finance/auto_salon/${id}/list${query}`,
        };
      },
    }),
    financeDashboardDetailExcel: builder.mutation({
      query: ({ id, query }) => {
        return {
          url: `/finance/auto_salon/${id}/list${query}`,
          method: "get",
          responseHandler: async (response) =>
            window.location.assign(
              window.URL.createObjectURL(await response.blob())
            ),
        };
      },
    }),
    financeIndividual: builder.query({
      query: (query) => {
        return {
          url: `/finance/individual${query}`,
        };
      },
      providesTags: ["Finance"],
    }),
    financeIndividualExcel: builder.mutation({
      query: (query) => {
        return {
          url: `/finance/individual${query}`,
          method: "get",
          responseHandler: async (response) =>
            window.location.assign(
              window.URL.createObjectURL(await response.blob())
            ),
        };
      },
    }),

    financeUserOperations: builder.query({
      query: (query) => {
        return {
          url: `/finance/payment_operation${query}`,
        };
      },
      providesTags: ["Finance"],
    }),
    financeUserOperationsExcel: builder.mutation({
      query: (query) => {
        return {
          url: `/finance/payment_operation${query}`,
          method: "get",
          responseHandler: async (response) =>
            window.location.assign(
              window.URL.createObjectURL(await response.blob())
            ),
        };
      },
    }),
    financeGarageStatistics: builder.query({
      query: () => {
        return {
          url: `/finance/garage_statistic`,
        };
      },
      transformResponse: (response) => response["data"],
      providesTags: ["Finance"],
    }),
    financeGarage: builder.query({
      query: (query) => {
        return {
          url: `/finance/garage${query}`,
        };
      },
      providesTags: ["Finance"],
    }),
    mashinalAppNotifications: builder.query({
      query: (query) => {
        return {
          url: `mashinal/notifications${query}`,
        };
      },
      providesTags: ["MashinalAppNotification"],
    }),
    mashinalAppRoutes: builder.query({
      query: () => {
        return {
          url: "mashinal/notifications/routes",
        };
      },
    }),
    mashinalAppNotificationById: builder.query({
      query: (notificationId) => {
        return {
          url: `mashinal/notifications/${notificationId}`,
        };
      },
    }),
    createMashinalAppNotification: builder.mutation({
      query: (body) => {
        return {
          url: "mashinal/notifications",
          method: "post",
          body,
        };
      },
      invalidatesTags: ["MashinalAppNotification"],
    }),
    getUserInfoByPhone: builder.mutation({
      query: (body) => {
        return {
          url: "https://mashin.al/api/tms/user_info",
          method: "post",
          body,
        };
      },
      invalidatesTags: ["MashinalAppNotification"],
    }),
    updateMashinalAppNotification: builder.mutation({
      query: ({ id, body }) => {
        return {
          url: `mashinal/notifications/${id}`,
          method: "post",
          body,
        };
      },
      invalidatesTags: ["MashinalAppNotification"],
    }),
    deleteMashinalAppNotification: builder.mutation({
      query: (notificationId) => {
        return {
          url: `mashinal/notifications/${notificationId}`,
          method: "delete",
        };
      },
      invalidatesTags: ["MashinalAppNotification"],
    }),
    rejectMashinalAppNotification: builder.mutation({
      query: (notificationId) => {
        return {
          url: `mashinal/notifications/${notificationId}/stop`,
          method: "get",
        };
      },
      invalidatesTags: ["MashinalAppNotification"],
    }),
    mashinalAppRoutesList: builder.query({
      query: (query) => {
        return {
          url: `mashinal/notifications/routes/list${query}`,
        };
      },
      providesTags: ["MashinalAppRoutes"],
    }),
    createMashinalAppRoute: builder.mutation({
      query: (body) => {
        return {
          url: "mashinal/notifications/routes",
          method: "post",
          body,
        };
      },
      invalidatesTags: ["MashinalAppRoutes"],
    }),
    updateMashinalAppRoute: builder.mutation({
      query: ({ id, body }) => {
        return {
          url: `mashinal/notifications/routes/${id}`,
          method: "post",
          body,
        };
      },
      invalidatesTags: ["MashinalAppRoutes"],
    }),
    deleteMashinalAppRoute: builder.mutation({
      query: (id) => {
        return {
          url: `mashinal/notifications/routes/${id}`,
          method: "delete",
        };
      },
      invalidatesTags: ["MashinalAppRoutes"],
    }),
    mashinalAppNotificationStatistics: builder.query({
      query: (query) => {
        return {
          url: `mashinal/notifications/stats${query}`,
        };
      },
    }),
  }),
});

export const {
  useFinanceDashboardQuery,
  useFinanceDashboardStatisticsQuery,
  useFinanceDashboardDetailStatisticsQuery,
  useFinanceDashboardDetailQuery,
  useFinanceDashboardDetailExcelMutation,
  useFinanceIndividualQuery,
  useFinanceIndividualExcelMutation,
  useFinanceUserOperationsQuery,
  useFinanceUserOperationsExcelMutation,
  useFinanceGarageStatisticsQuery,
  useFinanceGarageQuery,
  useMashinalAppNotificationsQuery,
  useMashinalAppRoutesQuery,
  useMashinalAppNotificationByIdQuery,
  useCreateMashinalAppNotificationMutation,
  useGetUserInfoByPhoneMutation,
  useUpdateMashinalAppNotificationMutation,
  useDeleteMashinalAppNotificationMutation,
  useRejectMashinalAppNotificationMutation,
  useMashinalAppRoutesListQuery,
  useCreateMashinalAppRouteMutation,
  useUpdateMashinalAppRouteMutation,
  useDeleteMashinalAppRouteMutation,
  useMashinalAppNotificationStatisticsQuery,
} = financeService;
