import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { getCookie } from "src/helper/custom_cookies";

export const operatorsService = createApi({
    reducerPath: "operatorsService",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_BASE_URL,
        prepareHeaders: (headers) => {
            headers.set("Authorization", getCookie("mcrmtkn") ? "Bearer " + getCookie("mcrmtkn") : "")
            headers.set('Accept', 'application/json')
            return headers
        },
    }),
    tagTypes: ["Operators", "Excel"],
    endpoints: (builder) => ({
        getOperators: builder.query({
            query: (query) => {
                return {
                    url: "/calls/operators" + query,
                }
            },
            providesTags: ["Operators"]
        }),
        getTopOperators: builder.query({
            query: () => {
                return {
                    url: "/users/max_values"
                }
            },
            // providesTags: ["Operators"]
        }),
        deleteUser: builder.mutation({
            query: ({ id }) => {
                return {
                    url: `/user/${id}/destroy`,
                    method: "post",
                }
            },
            invalidatesTags: ["Operators"]
        }),
        changeStatusUser: builder.mutation({
            query: ({ id }) => {
                return {
                    url: `/user/${id}/disable`,
                    method: "post",
                }
            },
            invalidatesTags: ["Operators"]
        }),
        changeStatusTemporaryUser: builder.mutation({
            query: ({ id }) => {
                return {
                    url: `/user/${id}/temporary-disable`,
                    method: "post",
                }
            },
            invalidatesTags: ["Operators"]
        }),
        updateOperatorWorkingHours: builder.mutation({
            query: ({ id, body }) => {
                return {
                    url: `/users/${id}/update_working_hours`,
                    method: "post",
                    body
                }
            },
            invalidatesTags: ["Operators"]
        }),
        adminShiftChangeList: builder.query({
            query: (query) => {
                return {
                    url: `/working_days${query}`
                }
            },
            // providesTags: ["Operators"]
        }),
        getSuitableOperators: builder.query({
            query: ({ week_day, date }) => {
                return {
                    url: `users/call_center/without_vacation?week_day=${week_day}&date=${date}`,
                    method: "get"
                }
            },
            providesTags: ["Operators"]
        }),
        sendShiftChangeRequest: builder.mutation({
            query: (body) => {
                return {
                    url: `profile/change-working-day/send`,
                    method: "post",
                    body
                }
            },
            invalidatesTags: ["Operators"]
        }),
        operatorShiftChangeList: builder.query({
            query: (query) => {
                return {
                    url: `/profile/change-working-day/list${query}`
                }
            },
            providesTags: ["Operators"]
        }),
        showShiftChangeRequest: builder.query({
            query: (query) => {
                return {
                    url: `/profile/change-working-day/${query}`
                }
            },
            // providesTags: ["Operators"]
        }),
        shiftChangeConfirm: builder.mutation({
            query: (body) => {
                return {
                    url: `/profile/change-working-day/updateStatus`,
                    method: "post",
                    body
                }
            },
            invalidatesTags: ["Operators"]
        }),
        shiftChangeDelete: builder.mutation({
            query: (id) => {
                return {
                    url: `/working_days/${id}`,
                    method: "delete",
                }
            },
            invalidatesTags: ["Operators"]
        }),
        lastOperatorExcels: builder.query({
            query: () => {
                return {
                    url: "/operator/export_working_hours_history"
                }
            },
            providesTags: ["Excel"]
        }),
        operatorsExcel: builder.mutation({
            query: () => {
                return {
                    url: "/operator/export_work_hours",
                    method: "get",
                    responseHandler: async (response) => window.location.assign(window.URL.createObjectURL(await response.blob())),
                }
            },
            invalidatesTags: ["Excel"]
        }),
        operatorsLastExcelExport: builder.mutation({
            query: (id) => {
                return {
                    url: `/operator/export_history/${id}`,
                    method: "get",
                    responseHandler: async (response) => window.location.assign(window.URL.createObjectURL(await response.blob())),
                }
            },
        }),
        
    })
})

export const { useGetOperatorsQuery, useGetTopOperatorsQuery, useDeleteUserMutation, useChangeStatusUserMutation, useChangeStatusTemporaryUserMutation, useGetSuitableOperatorsQuery, useSendShiftChangeRequestMutation, useUpdateOperatorWorkingHoursMutation, useAdminShiftChangeListQuery, useOperatorShiftChangeListQuery, useShowShiftChangeRequestQuery, useShiftChangeConfirmMutation, useShiftChangeDeleteMutation, useLastOperatorExcelsQuery, useOperatorsExcelMutation, useOperatorsLastExcelExportMutation } = operatorsService