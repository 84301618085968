import Shimmer from "../Common/Shimmer";

const CommonInformation = ({ data, isLoading }) => {
  return (
    <div className="flex items-center gap-x-12 p-4">
      {data && !isLoading
        ? data.common.map((info) => (
            <div
              className="flex-1 flex justify-between items-center gap-x-4"
              key={info.id}
            >
              <p className="text-14 font-semibold dark:text-white">
                {info.label}
              </p>
              <div className="flex justify-center items-center h-9 px-10 border border-solid dark:border-white rounded">
                <p className="text-20 font-semibold dark:text-white">
                  {info.value}
                </p>
              </div>
            </div>
          ))
        : [...Array(4)].map((_, i) => (
            <div className="flex-1 flex items-center gap-x-4" key={i}>
              <Shimmer className="w-full h-5" />
              <Shimmer className="w-full h-9" />
            </div>
          ))}
    </div>
  );
};

export default CommonInformation;
