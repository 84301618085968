import { useState } from "react";
import { ReactComponent as XSVG } from "src/assets/vectors/x.svg";
import { ReactComponent as XCircledSVG } from "src/assets/vectors/x_circled.svg";
import { ReactComponent as CheckCircledSVG } from "src/assets/vectors/check_circled.svg";
import t from "src/hooks/useTranslate";
import { useShowShiftChangeRequestQuery } from "src/services/operatorsService";
import Button from "../Common/Inputs/Button";
import Input from "../Common/Inputs/Input";

const ShiftChangeRequestModal = ({ setModal, id, onSubmit, isLoading }) => {
  const initial_state = {
    id,
    note: "",
    status: null,
  };
  const { data } = useShowShiftChangeRequestQuery(id);
  const [state, setState] = useState(initial_state);

  return (
    <div className="min-w-[736px]">
      <div className="flex items-center justify-between gap-x-4 p-4 border-b border-solid border-gray">
        <p className="text-16 font-bold dark:text-white">
          {t("shift_change_request")}
        </p>
        <XSVG
          className="text-gray cursor-pointer dark:text-white"
          onClick={() => setModal(false)}
        />
      </div>
      <div className="p-4">
        <div className="flex gap-x-4">
          <Input
            label={t("request_sender_coworker")}
            placeholder={data?.data.changer_user ?? ""}
            className="relative text-gray z-20 pointer-events-none after:absolute after:inset-0 after:bg-gray dark:after:bg-black after:opacity-50"
            disabled
          />
          <Input
            label={t("suggested_shift_date_hours")}
            placeholder={
              data
                ? `${data?.data.change_day} | ${data?.data.workHours.join(
                    " - "
                  )}`
                : ""
            }
            className="relative text-gray z-20 pointer-events-none after:absolute after:inset-0 after:bg-gray dark:after:bg-black after:opacity-50"
            disabled
          />
        </div>
        <p className="text-center text-16 font-bold my-6">{t("add_note")}</p>
        <div className={`flex gap-x-4 items-end`}>
          <div className="flex flex-col flex-1 space-y-2">
            <p className="text-gray dark:text-white">{t("note_subject")}</p>
            <div
              className={`relative w-full ${
                data?.data.status !== 2
                  ? "pointer-events-none after:absolute after:inset-0 after:bottom-2 after:bg-gray dark:after:bg-black after:opacity-50 after:rounded"
                  : ""
              }`}
            >
              <textarea
                className="w-full h-[100px] px-4 py-2 border border-solid border-gray rounded resize-none bg-white dark:bg-softBlack dark:text-white text-gray "
                placeholder={t("enter_the_text")}
                defaultValue={data?.data.note}
                disabled={data?.data.note}
                onKeyDown={(e) =>
                  state.note.length > 0 &&
                  state.note[state.note.length - 1] == " " &&
                  e.code === "Space" &&
                  e.preventDefault()
                }
                onChange={(e) => setState({ ...state, note: e.target.value })}
              />
            </div>
          </div>
          <div className="flex-1 space-y-2">
            {data?.data.status === 2 ? (
              <div className="space-y-4">
                <Button
                  className="w-full border border-gray disabled:bg-gray"
                  isLoading={isLoading && state.status == 0}
                  onClick={() => {
                    setState({ ...state, status: 0 });
                    onSubmit({ ...state, status: 0 });
                  }}
                >
                  {t("reject")}
                </Button>
                <Button
                  className="w-full bg-blue text-white disabled:bg-gray"
                  isLoading={isLoading && state.status == 1}
                  onClick={() => {
                    setState({ ...state, status: 1 });
                    onSubmit({ ...state, status: 1 });
                  }}
                >
                  {t("agree")}
                </Button>
              </div>
            ) : data?.data.status === 3 ? (
              <div className="flex flex-col items-center justify-center gap-y-2 text-red">
                <XCircledSVG className="h-16 w-16" />
                <p className="font-medium">{t("shift_not_changed")}</p>
              </div>
            ) : (
              <div className="flex flex-col items-center justify-center gap-y-2 text-green">
                <CheckCircledSVG className="h-16 w-16" />
                <p className="font-medium">{t("shift_changed_successfully")}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShiftChangeRequestModal;
