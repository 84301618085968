import React, { FC } from "react";
import { Popover } from "@headlessui/react";
import Button from "./Inputs/Button";
//TODO: w-fit removed from popover
const Popoover: FC<any> = React.forwardRef(
  ({ button, className, popoverClassName = "", onClick, children }, ref) => {
    return (
      <Popover className={["relative z-10 w-fit", popoverClassName].join(" ")}>
        <Popover.Button className="w-full h-full" onClick={onClick}>
        {/* @ts-ignore */}
          <div  ref={ref}>{button}</div>
        </Popover.Button>
        <Popover.Panel className={className}>
          {({ close }) => {
            return <div>{children}</div>;
          }}
        </Popover.Panel>
      </Popover>
    );
  }
);

export default Popoover;
