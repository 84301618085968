import { useCallback, useState } from "react";
import { ReactComponent as XSVG } from "src/assets/vectors/x.svg";
import { ReactComponent as TrashSVG } from "src/assets/vectors/trash.svg";
import { ReactComponent as CheckSVG } from "src/assets/vectors/check.svg";
import { ReactComponent as InfoSVG } from "src/assets/vectors/info.svg";
import t from "src/hooks/useTranslate";
import { useGetUsersPartiallyMutation } from "src/services/usersService";
import Button from "../Common/Inputs/Button";
import Input from "../Common/Inputs/Input";
import { IUser } from "src/types/i_user";
import Shimmer from "../Common/Shimmer";
import { useDebounceEffect } from "src/hooks/useDebounceEffect";
import IconWithTooltip from "../Common/IconWithTooltip";

const GroupAddUserModal = ({ setModal, onSubmit, isLoading }) => {
  const [selected, setSelected] = useState<IUser[]>([]);
  const [query, setQuery] = useState<string>("");
  const [
    getUsers,
    { data: users, isLoading: isUsersLoading, isSuccess: isUsersLoaded },
  ] = useGetUsersPartiallyMutation();

  const fetchUsers = useCallback(async (search) => {
    await getUsers(`?search=${search}&pagination=0`);
  }, []);

  const changeSelectedUser = (user: IUser) => {
    if (selected.some((slcd) => slcd.id === user.id)) {
      setSelected(selected.filter((slcd) => slcd.id !== user.id));
    } else {
      setSelected((prev) => [...prev, user]);
    }
  };

  useDebounceEffect(() => fetchUsers(query), 500, [query]);

  return (
    <>
      <div className="flex items-center justify-between gap-x-4 p-4 border-b border-solid border-gray">
        <p className="text-16 font-bold dark:text-white">
          Qrupa istifadəçi əlavə edin
        </p>
        <XSVG
          className="text-gray cursor-pointer dark:text-white"
          onClick={() => setModal(false)}
        />
      </div>
      <div className="flex-1 flex gap-x-4 p-4">
        <div className="flex-1 flex flex-col justify-between space-y-4">
          <div className="h-full flex flex-col gap-y-2">
            <Input
              placeholder="Axtar..."
              onChange={(e) => setQuery(e.target.value)}
            />
            {query.trim() != "" && (
              <ul className="flex-1 max-h-[303.2px] p-4 space-y-2 border border-solid border-gray rounded overflow-auto">
                {users && !isUsersLoading ? (
                  users.data.length ? (
                    users.data.map((user: IUser) => (
                      <li
                        className={`relative cursor-pointer rounded px-2 py-0.5 pl-10 hover:bg-light dark:hover:bg-softBlack ${
                          selected.map((slcd) => slcd.id).includes(user.id)
                            ? "text-green"
                            : "dark:text-white"
                        }`}
                        onClick={() => changeSelectedUser(user)}
                        key={user.id}
                      >
                        {selected.map((slcd) => slcd.id).includes(user.id) && (
                          <CheckSVG className="absolute w-4 h-4 top-1/2 -translate-y-1/2 left-4" />
                        )}
                        {user.full_name}
                      </li>
                    ))
                  ) : (
                    <div className="w-full relative top-1/2 -translate-y-1/2 p-4 flex items-center justify-center">
                      <div className="w-fit flex items-center gap-x-3 p-4 rounded-xl bg-light dark:bg-softBlack text-gray dark:text-white">
                        <InfoSVG />
                        <p>Göstəriləcək hər hansı bir məlumat yoxdur</p>
                      </div>
                    </div>
                  )
                ) : (
                  [...Array(5)].map((_, i) => (
                    <Shimmer className="h-10.5" key={i} />
                  ))
                )}
              </ul>
            )}
          </div>
          <Button
            className="min-h-[42px] w-full bg-blue text-white"
            isLoading={isLoading}
            onClick={() => onSubmit(selected.map((slcd) => slcd.id))}
          >
            {t("remember")}
          </Button>
        </div>
        <div className="flex-1 border border-solid border-gray rounded">
          <p className="h-10.5 flex justify-center items-center font-medium border-b border-solid border-gray dark:text-white">
            {t("selecteds")} ({selected.length})
          </p>
          <ul className="p-4">
            {selected.map((user, i) => (
              <li
                className="h-10.5 flex justify-between items-center gap-x-4 dark:text-white"
                key={user.id}
              >
                <p>{user?.full_name}</p>
                <IconWithTooltip
                  icon={<TrashSVG className="text-red" />}
                  tooltip={t("delete")}
                  onClick={() => changeSelectedUser(user)}
                />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default GroupAddUserModal;
