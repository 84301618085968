import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { ReactComponent as ExcelSVG } from "src/assets/vectors/file_excel.svg";
import { ReactComponent as InfoSVG } from "src/assets/vectors/info.svg";
import Button from "src/components/Common/Inputs/Button";
import Pagination from "src/components/Common/Pagination";
import Shimmer from "src/components/Common/Shimmer";
import { EIsActive } from "src/enums/e_is_active";
import t from "src/hooks/useTranslate";
import { useFinanceDashboardDetailExcelMutation } from "src/services/financeService";

type Props = {
  data: any;
  isLoading: boolean;
  setParams: (params: string) => void;
};

const FinanceDetailsTable = ({ data, isLoading, setParams }: Props) => {
  const urlParams = useParams();
  const [params, _] = useState(new URLSearchParams());
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  // const [query, setQuery] = useState("");
  // const [type, setType] = useState(0);

  const selectData = useMemo(
    () => [
      {
        id: 0,
        name: "all",
      },
      {
        id: 1,
        name: "contract",
      },
      {
        id: 2,
        name: "package_usage",
      },
      {
        id: 3,
        name: "finance",
      },
    ],
    []
  );

  useEffect(() => {
    data && setParams("?" + params.toString());
  }, [page, perPage]);

  const [excelExport, { isLoading: excelExportLoading }] =
    useFinanceDashboardDetailExcelMutation();

  const onExcelExport = async () => {
    await excelExport({
      id: urlParams.autosalonId,
      query: params.toString()
        ? "?want_export=true&" + params.toString()
        : "?want_export=true",
    });
  };

  return (
    <div className="w-full min-h-[556px] relative bg-white dark:bg-lightBlack rounded-xl drop-shadow-lg">
      <div className="h-10.5 p-4 box-content flex items-center gap-x-4">
        <p className="text-16 font-bold mr-auto dark:text-white">Detallar</p>
        {/* <div className="w-80">
          <Select
            data={selectData}
            defaultTitle={""}
            defaultValue={type}
            onChange={(select: IBaseSelect) => {
              params.delete("page");
              select.id === 0
                ? params.delete("type")
                : params.set("type", select.id.toString());
              setType(select.id);
            }}
          />
        </div>
        <div className="w-80">
          <InputSearch
            data={[]}
            placeholder={t("search")}
            optionHidden
            onInput={(query: string) => {
              params.delete("page");
              query.trim() === ""
                ? params.delete("search")
                : params.set("search", query);
              setQuery(query);
            }}
          />
        </div> */}
        <Button
          className="w-44 gap-x-2 bg-darkBlue dark:bg-blue text-white whitespace-nowrap"
          onClick={onExcelExport}
          isLoading={excelExportLoading}
        >
          <ExcelSVG /> <p>{t("export_to_excel")}</p>
        </Button>
      </div>
      <table className="min-w-[991px] w-full">
        <thead>
          <tr className="[&>th]:text-left [&>th]:py-6 [&>th]:px-2 [&>th]:dark:text-white [&>th]:whitespace-nowrap border-y border-solid border-gray">
            <th className="w-auto !pl-4">{t("package")}</th>
            <th className="w-auto">{t("start_date")}</th>
            <th className="w-auto">{t("end_date")}</th>
            <th className="w-auto">{t("status")}</th>
            <th className="w-auto">{t("package_price")}</th>
            <th className="w-auto !pr-4">{t("origin_history")}</th>
          </tr>
        </thead>
        <tbody>
          {data && !isLoading ? (
            data.data.map((finance) => (
              <tr
                className="relative [&>td]:py-4 [&>td]:px-2 [&>td]:dark:text-white after:absolute after:top-0 after:left-0 after:w-full after:h-[1px] after:bg-gray after:opacity-50"
                key={finance.id}
              >
                <td className="!pl-4">{finance.package}</td>
                <td>{finance.start_date}</td>
                <td>{finance.end_date}</td>
                <td>
                  {finance.status === EIsActive.active
                    ? t("active")
                    : t("deactive")}
                </td>
                <td>{finance.package_price} AZN</td>
                <td className="!pr-4">{finance.created_at}</td>
              </tr>
            ))
          ) : (
            <>
              {[...Array(10)].map((_, i) => (
                <tr
                  className="[&>td]:py-4 [&>td]:px-2 bg-white dark:bg-lightBlack"
                  key={i}
                >
                  <td>
                    <Shimmer className="h-4" />
                  </td>
                  <td>
                    <Shimmer className="h-4" />
                  </td>
                  <td>
                    <Shimmer className="h-4" />
                  </td>
                  <td>
                    <Shimmer className="h-4" />
                  </td>
                  <td>
                    <Shimmer className="h-4" />
                  </td>
                  <td>
                    <Shimmer className="h-4" />
                  </td>
                </tr>
              ))}
            </>
          )}
        </tbody>
      </table>
      {data && !data.data.length && (
        <div className="w-full h-[500px] p-4 flex items-center justify-center">
          <div className="w-fit flex items-center gap-x-3 p-4 rounded-xl bg-light dark:bg-softBlack text-gray dark:text-white">
            <InfoSVG />
            <p>Göstəriləcək hər hansı bir məlumat yoxdur</p>
          </div>
        </div>
      )}
      {data?.total > 5 && (
        <div className="w-full px-4 py-4 border-t border-solid border-gray">
          <Pagination
            page={Math.ceil(data?.total / perPage)}
            total={data?.total}
            perPage={perPage}
            pageChange={(e) => {
              setPage(e + 1);
              params.set("page", (e + 1).toString());
            }}
            setPerPage={(val) => {
              setPerPage(val);
              // params.set("search", query);
              params.set("per_page", val.toString());
              params.delete("page");
            }}
          />
        </div>
      )}
    </div>
  );
};

export default FinanceDetailsTable;
