import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getCookie } from "src/helper/custom_cookies";
import { operatorCountSetter } from "src/helper/operatorCountSetter";

export const usersService = createApi({
  reducerPath: "usersService",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: (headers) => {
      localStorage.setItem("ssnxprd", "180");
      headers.set(
        "Authorization",
        getCookie("mcrmtkn") ? "Bearer " + getCookie("mcrmtkn") : ""
      );
      headers.set("Accept", "application/json");
      return headers;
    },
    // credentials: "include",
  }),
  tagTypes: ["Users"],
  endpoints: (builder) => ({
    getUsers: builder.query({
      query: (query) => {
        return {
          url: "/users" + query,
        };
      },
      providesTags: ["Users"],
    }),
    getUsersPartially: builder.mutation({
      query: (query: any) => {
        return {
          url: "/users" + query,
          method: "get",
        };
      },
      // invalidatesTags: ["Users"]
    }),
    deleteUser: builder.mutation({
      query: ({ id }) => {
        return {
          url: `/user/${id}/destroy`,
          method: "post",
          body: id,
        };
      },
      invalidatesTags: ["Users"],
    }),
    createUser: builder.mutation({
      query: (body: any) => {
        return {
          url: "/user/store",
          method: "post",
          body,
        };
      },
      invalidatesTags: ["Users"],
    }),
    getResponsiblePersonPartially: builder.mutation({
      query: (body: any) => {
        return {
          url: `/group/${body.groupId}/search_user` + body.query,
          method: "get",
        };
      },
      invalidatesTags: ["Users"],
    }),
    createResponsiblePerson: builder.mutation({
      query: (body: any) => {
        return {
          url: `/group/${body.groupId}/add_group_responsible`,
          method: "post",
          body: body.data,
        };
      },
      invalidatesTags: ["Users"],
    }),
    getResponsiblePersons: builder.mutation({
      query: (groupId) => {
        return {
          url: `/group/${groupId}/all_responsibles`,
          method: "get",
        };
      },
      invalidatesTags: ["Users"],
    }),
    deleteResponsible: builder.mutation({
      query: ({ groupId, respId }) => {
        return {
          url: `group/${groupId}/remove_group_responsible/${respId}`,
          method: "get",
        };
      },
      invalidatesTags: ["Users"],
    }),
    updateUserPermissions: builder.mutation({
      query: (body: any) => {
        return {
          url: `user/${body.id}/change_permission`,
          method: "post",
          body: { permission: body.permission },
        };
      },
      invalidatesTags: ["Users"],
    }),
    updateUserPasswords: builder.mutation({
      query: (body: any) => {
        return {
          url: `user/${body.id}/change_password`,
          method: "post",
          body: body.passwords,
        };
      },
      invalidatesTags: ["Users"],
    }),
    addUserFile: builder.mutation({
      query: (body: any) => {
        return {
          url: `user/${body.id}/file/add`,
          method: "post",
          body: body.files,
        };
      },
      invalidatesTags: ["Users"],
    }),
    deleteUserFile: builder.mutation({
      query: (body: any) => {
        return {
          url: `user/${body.id}/file/${body.fileId}/remove`,
          method: "post",
        };
      },
      invalidatesTags: ["Users"],
    }),

    updateUser: builder.mutation({
      query: (body: any) => {
        return {
          url: `/user/${body.id}/update`,
          method: "post",
          body: body.data,
        };
      },
      invalidatesTags: ["Users"],
    }),
    getUserById: builder.query({
      query: (id) => {
        return {
          url: `/user/${id}/view`,
        };
      },
      providesTags: ["Users"],
    }),
    getUsersAndGroups: builder.mutation({
      query: (query: any) => {
        return {
          url: "/users/filter_with_role" + query,
          method: "get",
        };
      },
      invalidatesTags: ["Users"],
    }),
    setGroupsToUser: builder.mutation({
      query: (body: any) => {
        return {
          url: `/user/update_group`,
          method: "post",
          body,
        };
      },
      invalidatesTags: ["Users"],
    }),
    setUsersToGroup: builder.mutation({
      query: (body: any) => {
        return {
          url: `/users/add_all_to_group`,
          method: "post",
          body,
        };
      },
      invalidatesTags: ["Users"],
    }),
    usersExcel: builder.mutation({
      query: () => {
        return {
          url: "/users/export",
          method: "get",
          responseHandler: async (response) =>
            window.location.assign(
              window.URL.createObjectURL(await response.blob())
            ),
        };
      },
    }),
  }),
});

export const {
  useGetUsersQuery,
  useGetUsersPartiallyMutation,
  useDeleteUserMutation,
  useCreateUserMutation,
  useUpdateUserPermissionsMutation,
  useUpdateUserPasswordsMutation,
  useAddUserFileMutation,
  useDeleteUserFileMutation,
  useUpdateUserMutation,
  useGetUserByIdQuery,
  useGetUsersAndGroupsMutation,
  useSetGroupsToUserMutation,
  useSetUsersToGroupMutation,
  useUsersExcelMutation,
  useGetResponsiblePersonPartiallyMutation,
  useCreateResponsiblePersonMutation,
  useGetResponsiblePersonsMutation,
  useDeleteResponsibleMutation,
} = usersService;
