import { FC, useRef } from "react";
import t from "src/hooks/useTranslate";
import { IInput } from "src/types/Common/i_input";

const InputFile: FC<any> = ({
  label,
  fileName,
  className,
  onChange,
  multiple = false,
  ...inputProps
}) => {
  const input = useRef<any>(null);
  return (
    <div className={className}>
      {label && <p className="mb-2 dark:text-white">{label}</p>}
      <div className="flex border h-10.5 border-solid border-gray rounded overflow-hidden bg-white dark:bg-softBlack">
        <div
          className="flex items-center border-r border-solid border-gray px-4 cursor-pointer hover:bg-light dark:text-white dark:hover:bg-lightBlack"
          onClick={() => {
            input.current.value = "";
            input.current.click();
          }}
        >
          <p>{t("choose_file")}</p>
        </div>
        <p
          className={`h-10.5 px-4 leading-[42px] ${
            fileName ? "dark:text-white" : "text-darkSnow"
          }`}
        >
          {fileName
            ? multiple
              ? fileName[0].name + " +" + (fileName.length - 1)
              : fileName
            : "Fayl yoxdur"}
        </p>
        <input
          {...inputProps}
          multiple={multiple}
          type="file"
          className="hidden"
          ref={input}
          onChange={(e) => {
            onChange(e);
          }}
        />
      </div>
    </div>
  );
};

export default InputFile;
