import { createSlice, PayloadAction } from '@reduxjs/toolkit'


const initialState: { notifications } = {
    notifications: {
        data: [],
        links: [],
        meta: []
    }
}

export const notificationSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        setNotifications: (state, action: PayloadAction<any>) => {
            state.notifications = action.payload
        },
        newNotification: (state, action: PayloadAction<any>) => {
            state.notifications.data.length > 9 && state.notifications.data.pop()
            state.notifications.data.unshift(action.payload)
        },
    },
})

export const { setNotifications, newNotification } = notificationSlice.actions

export default notificationSlice.reducer