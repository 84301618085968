import { Menu } from "@headlessui/react";
import { Link } from "react-router-dom";
import Dropdown from "../Common/Dropdown";
import { ReactComponent as SettingsSVG } from "src/assets/vectors/settings.svg";
 import t from "src/hooks/useTranslate";

const HeaderSettings = () => {
   
  return (
    <Dropdown button={<SettingsSVG className="dark:text-white" />} className="flex">
      <div className="absolute top-[calc(100%+16px)] -right-4 bg-white dark:bg-lightBlack px-4 py-5 rounded-xl drop-shadow after:absolute after:right-4 after:-top-1.5 after:w-3 after:h-3 after:bg-white dark:after:bg-lightBlack after:rotate-45">
        <Menu.Item>
          <Link
            to="/interface-setting"
            className="ui-active:bg-light dark:ui-active:bg-softBlack dark:text-white px-4 py-3 rounded whitespace-nowrap"
          >
            {t("interface_settings")}
          </Link>
        </Menu.Item>
      </div>
    </Dropdown>
  );
};

export default HeaderSettings;
