import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { removeArrayValueByIndex } from "src/helper/removeArrayValueByIndex";
import { ddmmyyyy } from "src/helper/getFormattedDate";
import { EUsersCrudSteps } from "src/enums/e_users_crud_steps";
import { IUser } from "src/types/i_user";
import { EIsActive } from "src/enums/e_is_active";

const initialState: {
  users_form: any;
  newUser: any;
  all_users_permission_selected: number[];
  users_permissions: any;
  visitedSteps: EUsersCrudSteps[];
  user_passwords: any,
  user_file: any,
} = {
  users_form: {
    avatar: null,
    name: "",
    lastname: "",
    father_name: "",
    position: "",
    birthday: "",
    gender: 0,
    email: "",
    password: "",
    phone: "",
    roles: 0,
    salary: "",
    vacation: "",
    status: EIsActive.active,
    all_permissions: [],
    working_hours: []
  },
  newUser: {},
  all_users_permission_selected: [],
  user_passwords: {
    password: "",
    password_confirmation: ""
  },
  user_file: {
    file_name: "",
    file: null
  },
  users_permissions: {
    id: 0,
    name: "All",
    child: [],
  },
  visitedSteps: [],
};

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<IUser>) => {
      state.users_form = {
        ...action.payload, birthday: ddmmyyyy(new Date(action.payload.birthday)), roles: action.payload.main_role_id,
      }
    },
    setAvatar: (state, action: PayloadAction<string>) => {
      state.users_form = { ...state.users_form, avatar: action.payload };
    },
    setAllInputs: (state, action: PayloadAction<any>) => {
      state.users_form = {
        ...state.users_form,
        [action.payload.name]: action.payload.value,
      };
    },
    setPasswordInputs: (state, action: PayloadAction<{ name: string; value: string; }>) => {
      state.user_passwords = {
        ...state.user_passwords,
        [action.payload.name]: action.payload.value.trim(),
      };
    },
    setPhoneInput: (state, action: PayloadAction<string>) => {
      state.users_form = { ...state.users_form, phone: action.payload };
    },
    setCalendarInput: (state, action: PayloadAction<string>) => {
      state.users_form = { ...state.users_form, birthday: action.payload };
    },
    setSelectInput: (
      state,
      action: PayloadAction<{ name: string; value: string | number }>
    ) => {
      state.users_form = {
        ...state.users_form,
        [action.payload.name]: action.payload.value,
      };
    },
    setSwitchInput: (state, action: PayloadAction<number>) => {
      state.users_form = { ...state.users_form, status: action.payload };
    },
    setPermissions: (state, action: PayloadAction<any>) => {
      state.users_permissions.child = action.payload?.permissions;
    },
    setNewUser: (state, action: PayloadAction<any>) => {
      state.newUser = action.payload;
    },
    setVisitedUserStep: (state, action: PayloadAction<EUsersCrudSteps>) => {
      !state.visitedSteps.includes(action.payload) &&
        state.visitedSteps.push(action.payload);
    },
    setUserFile: (state, action: PayloadAction<File | null>) => {
      state.user_file = {
        ...state.user_file,
        file: action.payload,
      };
    },
    setUserFileName: (state, action: PayloadAction<string>) => {
      state.user_file = {
        ...state.user_file,
        file_name: action.payload,
      };
    },
    reset: (state) => {
      Object.assign(state, initialState);
    },
  },
});

export const {
  setUser,
  setAvatar,
  setAllInputs,
  setPasswordInputs,
  setPhoneInput,
  setCalendarInput,
  setSelectInput,
  setSwitchInput,
  setNewUser,
  setPermissions,
  setVisitedUserStep,
  setUserFile,
  setUserFileName,
  reset,
} = usersSlice.actions;
export default usersSlice.reducer;
