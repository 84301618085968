import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import MashinAlAppNotificationForm from "src/components/Finance/MashinAlApp/MashinAlAppNotificationForm";
import Header from "src/components/Header/Header";
import { dataURLtoFile } from "src/helper/dataUrlToFile";
import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import t from "src/hooks/useTranslate";
import {
  useCreateMashinalAppNotificationMutation,
  useMashinalAppNotificationByIdQuery,
  useUpdateMashinalAppNotificationMutation,
} from "src/services/financeService";
import { resetForm, setForm } from "src/store/MashinalApp/mashinalAppStore";
import { errorToast, successToast } from "src/store/Settings/toastStore";
import { useLocation } from "react-router-dom";

type Props = {};

const MashinalAppNotificationEdit = (props: Props) => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const notificationForm = useAppSelector(
    (state) => state.mashinalApp.mashinalAppNotificationForm
  );

  const location = useLocation();
  const isView = location?.search?.split("?").includes("view=true");
  const isClone = location?.search?.split("?").includes("clone=true");

  const title = isClone
    ? t("clone_notification")
    : isView
    ? "Bildiriş"
    : t("edit_notification");

  const {
    data,
    refetch,
    isSuccess: dataFetched,
  } = useMashinalAppNotificationByIdQuery(params.mashinalAppNotificationId);

  const [
    updateAppNotification,
    {
      isSuccess: notificationUpdated,
      isLoading: notificationUpdating,
      error: notificationUpdateError,
      isError: isUpdateError,
    },
  ] = useUpdateMashinalAppNotificationMutation();

  const [
    createAppNotification,
    {
      isSuccess: createIsSuccess,
      isLoading: createIsLoading,
      error: createError,
      isError: isCreateError,
    },
  ] = useCreateMashinalAppNotificationMutation();

  const isLoadingNoti = isClone ? createIsLoading : notificationUpdating;

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (dataFetched) {
      dispatch(setForm(data));
    }
  }, [data, dataFetched, dispatch]);

  const onNotificationUpdate = async (e, type) => {
    e.preventDefault();

    const formData = new FormData();
    for (const key in notificationForm) {
      formData.append(
        key,
        notificationForm[
          key as keyof typeof notificationForm
        ] as unknown as string
      );
    }

    if (type === 1) {
      formData.delete("send_at");
    }

    if (!notificationForm.image) {
      formData.delete("image");
      formData.append("remove_image", "true");
    } else if (
      notificationForm.image.startsWith(
        process.env.REACT_APP_BASE_API_PATHNAME!
      )
    ) {
      formData.delete("image");
    } else {
      formData.set("image", dataURLtoFile(notificationForm.image, "image"));
    }

    if (isClone) {
      await createAppNotification(formData);
    } else {
      await updateAppNotification({
        id: params.mashinalAppNotificationId,
        body: formData,
      });
    }
  };


  useEffect(() => {
    if (createIsSuccess) {
      dispatch(successToast("Bildiriş uğurla əlavə olundu"));
    }

    if (notificationUpdated) {
      dispatch(successToast("Bildiriş uğurla dəyişdirildi"));
    
    }
    if (notificationUpdated || createIsSuccess) {
      dispatch(resetForm());
      navigate("/mashin-al-app");
    }
  }, [notificationUpdated, createIsSuccess]);

  useEffect(() => {
    if (isCreateError) {
      //@ts-ignore
      Object.values(createError?.data?.errors).forEach((x) =>
        //@ts-ignore
        dispatch(errorToast(x[0]))
      );
    }
    if (isUpdateError) {
      //@ts-ignore
      Object.values(notificationUpdateError?.data?.errors).forEach((x) =>
        //@ts-ignore
        dispatch(errorToast(x[0]))
      );
    }
  }, [isCreateError, isUpdateError]);

  return (
    <>
      <Header title={"Mashin.al APP"} />
      <MashinAlAppNotificationForm
        onSubmit={onNotificationUpdate}
        form={data}
        isLoading={isLoadingNoti}
        title={title}
      />
    </>
  );
};

export default MashinalAppNotificationEdit;
