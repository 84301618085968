import { useEffect, useState } from "react";
import Shimmer from "src/components/Common/Shimmer";
import months from "src/data/months.json";
import { ddmmyyyy } from "src/helper/getFormattedDate";
import t from "src/hooks/useTranslate";

const GarageRegistrationStatistics = ({ data, isLoading, value, setValue }) => {
  const [active, setActive] = useState(0);

  useEffect(() => {
    if (
      value !== ddmmyyyy(new Date()) &&
      value.split(".")[2] == new Date().getFullYear()
    ) {
      setActive(Number(value.split(".")[1]));
    } else {
      setActive(0);
    }
  }, [value]);

  return (
    <div className="grid grid-cols-6 gap-6">
      {data && !isLoading
        ? Object.values(data.allData).map((month: any, i) => (
            <div
              onClick={() => {
                setActive(months[i].id);
                setValue(
                  `01.${
                    months[i].id < 10 ? "0" + months[i].id : months[i].id
                  }.${new Date().getFullYear()}`
                );
              }}
              className={[
                "w-full p-4 space-y-4 relative bg-white dark:bg-lightBlack rounded-xl drop-shadow-lg cursor-pointer",
                active === months[i].id ? "bg-light dark:bg-softBlack" : "",
              ].join(" ")}
              key={months[i].id}
            >
              <p className="font-semibold dark:text-white">
                {t(months[i].name)}
              </p>
              <p className="text-gray font-medium">
                Rag & Renew : {month.count}
              </p>
              <p className="text-gray font-medium">
                Reactivate : {month.activate_count}
              </p>
            </div>
          ))
        : [...Array(12)].map((_, i) => (
            <div
              className="w-full p-4 space-y-4 relative bg-white dark:bg-lightBlack rounded-xl drop-shadow-lg"
              key={i}
            >
              <Shimmer className="w-2/3 h-[24.35px]" />
              <Shimmer className="w-full h-[24.35px]" />
              <Shimmer className="w-4/5 h-[24.35px]" />
            </div>
          ))}
      <div className="w-full p-4 relative bg-white dark:bg-lightBlack rounded-xl drop-shadow-lg">
        <p className="text-16 font-semibold dark:text-white">
          SMS COUNT : {data ? data.smsCount : "0"}
        </p>
      </div>
    </div>
  );
};

export default GarageRegistrationStatistics;
