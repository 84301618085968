import { FC, useEffect, useMemo, useState } from "react";
import { IHeader } from "src/types/i_header";
import HeaderMail from "./HeaderMail";
import HeaderSettings from "./HeaderSettings";
import { ReactComponent as BackSVG } from "src/assets/vectors/back.svg";
import { useNavigate } from "react-router-dom";
import {
  useMarkAsReadMutation,
  useNotificationsQuery,
} from "src/services/notificationService";
import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import { setNotifications } from "src/store/Notifications/notificationStore";
import {
  errorToast,
  infoToast,
  successToast,
} from "src/store/Settings/toastStore";
import CountDownHHMM from "../Common/CountdownHHMM";
import Button from "../Common/Inputs/Button";
import t from "src/hooks/useTranslate";
import { hhmm } from "src/helper/getFormattedDate";
import CountDownAnimated from "../Common/CountDownAnimated";
import InputPassword from "../Common/Inputs/InputPassword";
import { passwordRequiredPattern } from "src/utils/validationPatterns";
import IsOperatorActiveAlert from "../Common/IsOperatorActiveAlert";
import { usePingMutation } from "src/services/workingHoursKPIService";
import usePermission from "src/hooks/usePermission";
import { EPermissions } from "src/enums/e_permissions";
import { initTheme } from "src/store/Settings/themeStore";
import { getCookie, setCookie } from "src/helper/custom_cookies";
import BreakTimesModal from "../Common/BreakTimesModal";
import {
  useCloseBreakMutation,
  useCloseLunchMutation,
  useTakeABreakMutation,
  useTakeLunchMutation,
} from "src/services/profileService";
import { setOperatorOptions } from "src/store/Profile/profileStore";
import { EBreakType } from "src/enums/e_break_type";
import BreakInfoModal from "../Common/BreakInfoModal";
import Modal from "../Common/Modal";

const Header: FC<IHeader> = ({ backLink, title, children }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const operatorOptions = useAppSelector(
    (state) => state.profile.operatorOptions
  );

  
  const user = useAppSelector((state) => state.profile.user);
  const [isLunchTime, setIsLunchTime] = useState(false);
  const [hasBreak, setHasBreak] = useState(false);
  const [breakModal, setBreakModal] = useState(false);
  const [operatorSessionAlert, setOperatorSessionAlert] = useState(false);
  const [breakType, setBreakType] = useState(EBreakType.break);
  const [modal, setModal] = useState(false);
  const isAutoExpire = usePermission([EPermissions.user_session_will_expire]);
  const {
    data: notifications,
    refetch,
    isFetching: isNotificationsLoading,
    isSuccess: isNotificationLoaded,
  } = useNotificationsQuery("");

  const [ping, { isSuccess: pong, isLoading: pingPongLoading }] =
    usePingMutation();

  const [
    takeABreak,
    {
      data: takeABreakData,
      isSuccess: takeABreakSuccess,
      isLoading: takeABreakLoading,
    },
  ] = useTakeABreakMutation();

  const [
    takeLunch,
    {
      data: takeLunchData,
      isSuccess: takeLunchSuccess,
      isLoading: takeLunchLoading,
    },
  ] = useTakeLunchMutation();

  const [
    closeBreak,
    {
      data: closeBreakData,
      isSuccess: closeBreakSuccess,
      isLoading: closeBreakLoading,
      error: closeBreakError,
      isError: isCloseBreakError,
    },
  ] = useCloseBreakMutation();

  const [
    closeLunch,
    {
      data: closeLunchData,
      isSuccess: closeLunchSuccess,
      isLoading: closeLunchLoading,
      error: closeLunchError,
      isError: isCloseLunchError,
    },
  ] = useCloseLunchMutation();

  useEffect(() => {
    dispatch(initTheme());
    !getCookie("locale") && setCookie("locale", "az", 365);
    if (operatorOptions) {
      if (operatorOptions.hasActiveBreakTime) {
        setBreakType(EBreakType.break);
      }
      if (operatorOptions.hasActiveLunchTime) {
        setBreakType(EBreakType.lunch);
      }
      setBreakModal(
        operatorOptions.hasActiveBreakTime || operatorOptions.hasActiveLunchTime
      );
    }
  }, []);

  useEffect(() => {
    const operatorCountInterval =
      user?.is_operator && +localStorage.getItem("ssnxprd") > 0
        ? setInterval(() => {
            if (+localStorage.getItem("ssnxprd") > 0) {
              localStorage.setItem(
                "ssnxprd",
                (+localStorage.getItem("ssnxprd") - 1).toString()
              );
              setOperatorSessionAlert(false);
            } else if (+localStorage.getItem("ssnxprd") <= 0) {
              clearInterval(operatorCountInterval);
              setOperatorSessionAlert(true);
            }
          }, 1000)
        : null;
    return () => clearInterval(operatorCountInterval);
  }, [user, localStorage.getItem("ssnxprd")]);

  const [markAsRead, { isLoading, isSuccess: markedAsRead }] =
    useMarkAsReadMutation();

  const onMarkAsRead = async (id) => {
    await markAsRead({ notification: id });
  };

  useEffect(() => {
    dispatch(setNotifications(notifications));
  }, [notifications]);

  // useEffect(() => {
  //   if (markedAsRead) {
  //     dispatch(infoToast("Yeni bildirişiniz yoxdur"));
  //   }
  // }, [markedAsRead]);

  function isLunchBreak(workHours: string[]): boolean {
    if (!workHours.length) {
      return false;
    }
    const [start, end] = workHours;
    const workStart = new Date();
    workStart.setHours(Number(start.slice(0, 2)), Number(start.slice(3)), 0, 0);
    const workEnd = new Date();
    workEnd.setHours(Number(end.slice(0, 2)), Number(end.slice(3)), 0, 0);
    const workDuration =
      (workEnd.getTime() - workStart.getTime()) / (60 * 60 * 1000);

    if (workDuration < 4) {
      return false;
    }

    const lunchStart = new Date(workStart.getTime() + 4 * 60 * 60 * 1000);
    const lunchEnd = new Date(lunchStart.getTime() + 1 * 60 * 60 * 1000);
    const now = new Date();
    let lunchTime = now >= lunchStart && now <= lunchEnd;

    return lunchTime;
  }

  function isBreakTime(workHours: string[]): boolean {
    if (!workHours.length) {
      return false;
    }
    const [start, end] = workHours;
    const workStart = new Date();
    workStart.setHours(Number(start.slice(0, 2)), Number(start.slice(3)), 0, 0);
    const workEnd = new Date();
    workEnd.setHours(Number(end.slice(0, 2)), Number(end.slice(3)), 0, 0);
    const breakStart = new Date(workStart.getTime() + 1 * 60 * 60 * 1000);
    const breakEnd = new Date(workEnd.getTime() - 1 * 60 * 60 * 1000);
    const lunchStart = new Date(workStart.getTime() + 4 * 60 * 60 * 1000);
    const lunchEnd = new Date(lunchStart.getTime() + 1 * 60 * 60 * 1000);
    const now = new Date();
    let breakTime =
      now > breakStart &&
      now < breakEnd &&
      (now < lunchStart || now > lunchEnd);

    return breakTime;
  }

  useEffect(() => {
    const workhours =
      new Date().getDay() == 0
        ? user.working_hours[6]
        : user.working_hours[new Date().getDay() - 1];
    const isLunch = isLunchBreak(workhours);
    const isBreak = isBreakTime(workhours);
    setIsLunchTime(isLunch);
    setHasBreak(isBreak);
    const intervalId = setInterval(() => {
      const isLunchI = isLunchBreak(workhours);
      const isBreakI = isBreakTime(workhours);
      setIsLunchTime(isLunchI);
      setHasBreak(isBreakI);
    }, 30000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const onPingPong = async () => {
    localStorage.setItem("ssnxprd", "180");
    setOperatorSessionAlert(false);
    await ping("");
  };

  const onTakeABreak = async () => {
    setBreakType(EBreakType.break);
    await takeABreak("");
  };

  useEffect(() => {
    if (takeABreakSuccess) {
      setBreakModal(true);
      setModal(false);
      dispatch(successToast(takeABreakData.message));
      dispatch(
        setOperatorOptions({
          ...operatorOptions,
          isBreakTime: {
            hour: takeABreakData.hour,
            minute: takeABreakData.minute,
          },
        })
      );
    }
  }, [takeABreakSuccess]);

  const onBreakClose = async (password: string) => {
    await closeBreak({ password });
  };

  useEffect(() => {
    if (closeBreakSuccess) {
      setBreakModal(false);
      dispatch(successToast(closeBreakData.message));
      dispatch(
        setOperatorOptions({
          ...operatorOptions,
          hasActiveBreakTime: false,
          isBreakTime: {
            hour: closeBreakData.hour,
            minute: closeBreakData.minute,
          },
        })
      );
    }
  }, [closeBreakSuccess]);

  useEffect(() => {
    if (isCloseBreakError) {
      //@ts-ignore
      dispatch(errorToast(closeBreakError?.data.message));
    }
  }, [isCloseBreakError]);

  const onTakeLunch = async () => {
    setBreakType(EBreakType.lunch);
    await takeLunch("");
  };

  useEffect(() => {
    if (takeLunchSuccess) {
      setBreakModal(true);
      setModal(false);
      dispatch(successToast(takeLunchData.message));
      dispatch(
        setOperatorOptions({
          ...operatorOptions,
          isLunchTime: {
            hour: takeLunchData.hour,
            minute: takeLunchData.minute,
          },
        })
      );
    }
  }, [takeLunchSuccess]);

  const onLunchClose = async (password: string) => {
    await closeLunch({ password });
  };

  useEffect(() => {
    if (closeLunchSuccess) {
      setBreakModal(false);
      dispatch(successToast(closeLunchData.message));
      dispatch(
        setOperatorOptions({
          ...operatorOptions,
          hasActiveLunchTime: false,
          isLunchTime: {
            hour: closeLunchData.hour,
            minute: closeLunchData.minute,
          },
        })
      );
    }
  }, [closeLunchSuccess]);

  useEffect(() => {
    if (isCloseLunchError) {
      //@ts-ignore
      dispatch(errorToast(closeLunchError?.data.message));
    }
  }, [isCloseLunchError]);

  return (
    <>
      <div className="relative z-50 h-20 flex items-center justify-between px-[1%]">
        <h1 className="text-16 font-bold flex items-center gap-x-2 dark:text-white">
          {backLink && (
            <BackSVG
              className="cursor-pointer"
              onClick={() =>
                typeof backLink == "string" ? navigate(backLink) : navigate(-1)
              }
            />
          )}
          {title}
        </h1>
        <div className="flex gap-x-4 text-gray pr-4">
          {children}
          {user.is_operator && isAutoExpire && isLunchTime && (
            <Button
              className="w-full bg-green text-white gap-x-1 whitespace-nowrap"
              onClick={() => {
                setBreakType(EBreakType.lunch);
                setModal(true);
              }}
            >
              {t("lunch_break")}
            </Button>
          )}
          {user.is_operator && isAutoExpire && hasBreak && (
            <Button
              className="min-w-[180px] bg-green text-white gap-x-1 whitespace-nowrap"
              onClick={() => {
                setBreakType(EBreakType.break);
                setModal(true);
              }}
            >
              {`${t("break")} ${
                operatorOptions.isBreakTime.hour < 10
                  ? "0" + operatorOptions.isBreakTime.hour
                  : operatorOptions.isBreakTime.hour
              }:${
                operatorOptions.isBreakTime.minute < 10
                  ? "0" + operatorOptions.isBreakTime.minute
                  : operatorOptions.isBreakTime.minute
              }`}
            </Button>
          )}
          {operatorOptions?.remainingTime && isAutoExpire && (
            <Button className="min-w-[180px] border border-solid border-gray text-gray gap-x-1 whitespace-nowrap">
              {t("remaining_working_hours")}:
              <CountDownHHMM data={operatorOptions.remainingTime} />
            </Button>
          )}
          <HeaderSettings />
          <HeaderMail data={notifications?.data} onMarkAsRead={onMarkAsRead} />
        </div>
      </div>
      {breakModal && (
        <BreakTimesModal
          onSubmit={
            breakType === EBreakType.break ? onBreakClose : onLunchClose
          }
          isLoading={
            breakType === EBreakType.break
              ? closeBreakLoading
              : closeLunchLoading
          }
          time={
            breakType === EBreakType.break
              ? operatorOptions.isBreakTime
              : operatorOptions.isLunchTime
          }
          breakType={breakType}
          full_name={user.full_name}
        />
      )}
      {operatorSessionAlert && isAutoExpire && !breakModal && (
        <IsOperatorActiveAlert
          onSubmit={onPingPong}
          isLoading={pingPongLoading}
        />
      )}
      <Modal
        visible={modal}
        setVisible={setModal}
        dialogClassName="max-w-[567px]"
      >
        <BreakInfoModal
          setModal={setModal}
          breakType={breakType}
          full_name={user.full_name}
          onSubmit={breakType === EBreakType.lunch ? onTakeLunch : onTakeABreak}
        />
      </Modal>
    </>
  );
};

export default Header;
