import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Button from "src/components/Common/Inputs/Button";
import Input from "src/components/Common/Inputs/Input";
import InputCarAndMotoNumber from "src/components/Common/Inputs/InputCarAndMotoNumber";

import InputCarNumber from "src/components/Common/Inputs/InputCarNumber";
import Header from "src/components/Header/Header";
import AutoHistoryInformations from "src/components/MashinAl/AutoHistoryInformations";
import { useAppDispatch } from "src/hooks/redux";
import t from "src/hooks/useTranslate";
import {
  useCheckSmsRadarByCarNumberMutation,
  useCheckSmsRadarByVinNumberMutation,
} from "src/services/mashinAlDashboardService";
import { errorToast } from "src/store/Settings/toastStore";

import {
  carAndMotoNumberPattern,
  carNumberPattern,
} from "src/utils/validationPatterns";

const AutoHistory = () => {
  const [carNumber, setCarNumber] = useState(null);
  const [vinNumber, setVinNumber] = useState("");
  const [paramsData, setParamsData] = useState(new URLSearchParams());
  const [btnDisabled, setBtnDisabled] = useState(true);

  const dispatch = useAppDispatch();

  const [
    getSmsRadarDataByCarNumber,
    {
      data: smsRadarDataByCarNumber,
      isSuccess: isSuccessSmsRadarDataByCarNumber,
      isLoading: smsRadarDataByCarNumberIsLoading,
      isError: smsRadarDataByCarNumberIsError,
      error: smsRadarDataByCarNumberError,
    },
  ] = useCheckSmsRadarByCarNumberMutation();
  const [
    getSmsRadarDataByVinNumber,
    {
      data: smsRadarDataByVinNumber,
      isSuccess: isSuccessSmsRadarDataByVinNumber,
      isLoading: smsRadarDataByVinNumberIsLoading,
      isError: smsRadarDataByVinNumberIsError,
      error: smsRadarDataByVinNumberError,
    },
  ] = useCheckSmsRadarByVinNumberMutation();

  useEffect(() => {
    if (smsRadarDataByVinNumberIsError || smsRadarDataByCarNumberIsError) {
      dispatch(errorToast(t("info_not_found")));
    }
  }, [smsRadarDataByVinNumberIsError, smsRadarDataByCarNumberIsError]);

  const autoHistoryHandler = (e) => {
    e.preventDefault();
    if (carNumber) {
      getSmsRadarDataByCarNumber(paramsData.toString());
    } else if (vinNumber) {
      getSmsRadarDataByVinNumber(paramsData.toString());
    }
  };
  const carNumberHandler = (e) => {
    setCarNumber(e);
    paramsData.set("car_number", e);
  };
  const vinNumberHandler = (e) => {
    setVinNumber(e.target.value);
    paramsData.set("vin", e.target.value);
  };

  useEffect(() => {
    if (carNumber || vinNumber?.length >= 17) {
      setBtnDisabled(false);
    }
  }, [carNumber, vinNumber]);

  return (
    <>
      <Header title={t("auto_history")} backLink="/" />
      <div className="space-y-6">
        <div className="w-full flex items-end gap-x-4 relative p-4 bg-white dark:bg-lightBlack rounded-xl drop-shadow-lg">
          <form
            className="w-full flex items-end gap-x-4 relative"
            onSubmit={autoHistoryHandler}
          >
            <InputCarAndMotoNumber
              label={t("car_number")}
              className="w-full h-10.5 block border border-solid border-gray rounded uppercase"
              validPattern={carAndMotoNumberPattern}
              onChange={(e) => carNumberHandler(e)}
            />
            {/* <Input
              label={t("car_number")}
              onChange={(e) => carNumberHandler(e.target.value)}
              validpattern={carNumberPattern}
            /> */}
            <Input label="VIN" onChange={(e) => vinNumberHandler(e)} />
            <Button
              className="min-w-[180px] bg-blue text-white disabled:bg-gray"
              // onClick={autoHistoryHandler}
              type={"submit"}
              isLoading={
                smsRadarDataByCarNumberIsLoading ||
                smsRadarDataByVinNumberIsLoading
              }
              disabled={btnDisabled}
            >
              {t("check")}
            </Button>
          </form>

          <Link
            to="logs"
            className="h-10.5 min-w-[180px] flex items-center justify-center border border-solid border-gray text-gray rounded whitespace-nowrap"
          >
            {t("look_at_logs")}
          </Link>
        </div>

        {(isSuccessSmsRadarDataByCarNumber ||
          isSuccessSmsRadarDataByVinNumber) && (
          <AutoHistoryInformations
            data={smsRadarDataByCarNumber || smsRadarDataByVinNumber}
          />
        )}
      </div>
    </>
  );
};

export default AutoHistory;
