import React from "react";
import { Link } from "react-router-dom";
import Button from "src/components/Common/Inputs/Button";
import t from "src/hooks/useTranslate";

const ServerError = ({ resetErrorBoundary }) => {
  const isDark =
    (window.matchMedia("(prefers-color-scheme: dark)").matches &&
      !localStorage.getItem("theme")) ||
    localStorage.getItem("theme") == "dark";

  return (
    <div
      className={`w-full h-screen relative flex flex-col justify-center items-center gap-y-4 ${
        isDark ? "bg-black text-white" : ""
      }`}
    >
      <div className="absolute right-[12%] top-0 w-2/5 aspect-[1.6] bg-[#a895ff2c] rounded-full blur-3xl pointer-events-none"></div>
      <div className="absolute left-[12%] bottom-0  w-2/5 aspect-[1.6] bg-[#A895FF2c] rounded-full blur-3xl pointer-events-none"></div>
      <img
        src={isDark ? "/assets/images/500dark.png" : "/assets/images/500.png"}
        alt="500"
      />
      <p className="text-22 font-semibold">Server xətası!</p>

      <Link
        to="/"
        onClick={resetErrorBoundary}
        className="h-10.5 bg-blue dark:bg-red px-16 flex items-center gap-x-2 rounded text-white whitespace-nowrap"
      >
        <p>{t("return_back")}</p>
      </Link>
    </div>
  );
};

export default ServerError;
