import { SyntheticEvent, useEffect, useState } from "react";
import { ReactComponent as EditSVG } from "src/assets/vectors/edit.svg";
import { ReactComponent as TrashSVG } from "src/assets/vectors/trash.svg";
import { ReactComponent as XSVG } from "src/assets/vectors/x.svg";
import { ReactComponent as CheckSVG } from "src/assets/vectors/check.svg";
import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import t from "src/hooks/useTranslate";
import { setSectionInput } from "src/store/FAQ/FAQStore";
import Button from "../Common/Inputs/Button";
import Input from "../Common/Inputs/Input";
import usePermission from "src/hooks/usePermission";
import { EPermissions } from "src/enums/e_permissions";
import IconWithTooltip from "../Common/IconWithTooltip";

const FAQSectionsModal = ({
  data,
  setVisible,
  onCreate,
  onUpdate,
  isLoading,
  onDelete,
  isSuccess,
}) => {
  const dispatch = useAppDispatch();
  const section_form = useAppSelector((state) => state.faq.section);
  const [editSectionActive, setEditSectionActive] = useState(0);
  const [createInput, setCreateInput] = useState("");
  const canCreate = usePermission([EPermissions.faq_category_create]);
  const canEdit = usePermission([EPermissions.faq_category_edit]);
  const canDelete = usePermission([EPermissions.faq_category_delete]);

  useEffect(() => {
    if (isSuccess) {
      setCreateInput("");
    }
  }, [isSuccess]);

  return (
    <>
      <div className="flex items-center justify-between gap-x-4 border-b border-solid dark:border-gray p-4">
        <p className="text-16 font-bold dark:text-white">
          Bölmələrin tənzimlənməsi
        </p>
        <XSVG
          className="text-gray cursor-pointer dark:text-white"
          onClick={() => setVisible(false)}
        />
      </div>
      {canCreate && (
        <div className="flex items-end gap-x-4 p-4 border-b border-solid dark:border-gray">
          <div className="flex-1 space-y-4">
            <p className="text-16 font-bold dark:text-white">Bölmə adı</p>
            <Input
              placeholder="Məlumatları daxil edin"
              defaultValue={createInput}
              maxLength={64}
              onChange={(e) => {
                setCreateInput(e.target.value);
                setEditSectionActive(0);
                dispatch(setSectionInput({ title: e.target.value }));
              }}
            />
          </div>
          <Button
            className="w-36 bg-blue text-white disabled:bg-gray"
            isLoading={isLoading}
            disabled={createInput === ""}
            onClick={() => onCreate(section_form.title)}
          >
            {t("remember")}
          </Button>
        </div>
      )}

      <ul className="p-4 space-y-4 h-[360px] overflow-auto">
        {data?.map((section, i) => (
          <li
            className="h-10.5 flex items-center justify-between gap-x-4 font-medium dark:text-white"
            key={i}
          >
            {editSectionActive === i + 1 ? (
              <>
                <Input
                  defaultValue={section.title}
                  onChange={(e) =>
                    dispatch(
                      setSectionInput({ id: section.id, title: e.target.value })
                    )
                  }
                />
                <div className="flex gap-x-1">
                  {canEdit && (
                    <IconWithTooltip
                    icon={<CheckSVG className="text-blue w-6 h-6" />}
                    tooltip={t("submit")}
                    tooltipBottom={i === 0}
                    onClick={() => {
                      onUpdate(section_form);
                      setEditSectionActive(0);
                    }}
                    />
                    )}
                  {canDelete && (
                    <IconWithTooltip
                    icon={<XSVG className="text-red w-6 h-6" />}
                    tooltip={t("cancel")}
                    tooltipBottom={i === 0}
                    onClick={() => setEditSectionActive(0)} />
                  )}
                </div>
              </>
            ) : (
              <>
                {i + 1 + ". " + section.title + " (" + section.faqs_count + ")"}
                <div className="flex gap-x-1">
                  <IconWithTooltip
                    icon={<EditSVG className="text-blue" />}
                    tooltip={t("edit")}
                    tooltipBottom={i === 0}
                    onClick={() => setEditSectionActive(i + 1)}
                  />
                  <IconWithTooltip
                    icon={
                      <TrashSVG
                        className={
                          section.faqs_count > 0 ? "text-gray" : "text-red"
                        }
                      />
                    }
                    tooltip={t("delete")}
                    tooltipBottom={i === 0}
                    onClick={() =>
                      section.faqs_count === 0 && onDelete(section.id)
                    }
                    disabled={section.faqs_count > 0}
                  />
                </div>
              </>
            )}
          </li>
        ))}
      </ul>
    </>
  );
};

export default FAQSectionsModal;
