import { FC, SyntheticEvent, useEffect, useState } from "react";
import ReactInputMask from "react-input-mask";
import Button from "../Common/Inputs/Button";
import Input from "../Common/Inputs/Input";
import Select from "../Common/Select";
import { ReactComponent as XSVG } from "src/assets/vectors/x.svg";
import {
  carNumberRequiredPattern,
  phonePattern,
  phoneRequiredPattern,
  requiredPattern,
} from "src/utils/validationPatterns";
import t from "src/hooks/useTranslate";
import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import {
  resetForm,
  setAllInputs,
} from "src/store/Announcements/announcementStore";
import announceTypeData from "src/data/announce_type_data.json";
import InputPhone from "../Common/Inputs/InputPhone";
import { validatePhone } from "src/helper/validation";
import InputCarNumber from "../Common/Inputs/InputCarNumber";

const AnnouncementFormCreate: FC<{
  onClose?: () => void;
  onSubmit: (e: SyntheticEvent) => void;
  isLoading: boolean;
}> = ({ onClose, onSubmit, isLoading }) => {
  const dispatch = useAppDispatch();
  const [validForm, setValidForm] = useState(false);
  const announcement_form = useAppSelector(
    (state) => state.announcements.announcement_form
  );
  const maskChars = {
    "#": "[0-9]",
    $: "[a-zA-Z]",
  };

  useEffect(() => {
    dispatch(resetForm());
  }, []);

  useEffect(() => {
    if (
      validatePhone(announcement_form.phone!, true) &&
      announcement_form.announce_type
    ) {
      setValidForm(true);
    } else {
      setValidForm(false);
    }
  }, [announcement_form]);

  return (
    <>
      <div className="relative flex items-center justify-between gap-x-4 p-4 border-b border-solid border-gray">
        <p className="text-16 font-bold dark:text-white">
          {t("create_announcement")}
        </p>
        <XSVG
          className="text-gray cursor-pointer dark:text-white"
          onClick={onClose}
        />
      </div>
      <form className="grid grid-cols-2 gap-4 p-4" onSubmit={onSubmit}>
        <InputPhone
          validpattern={phonePattern}
          wideOpenSelect
          label={t("phone") + "*"}
          onChange={(e) =>
            dispatch(
              setAllInputs({
                key: "announcement_form",
                name: "phone",
                value: e,
              })
            )
          }
          name="phone"
        />
        <Select
          label={t("type")}
          data={announceTypeData.slice(1)}
          defaultTitle={t("choose")}
          defaultValue={1}
          onChange={(e) =>
            dispatch(
              setAllInputs({
                key: "announcement_form",
                name: "announce_type",
                value: e.id,
              })
            )
          }
        />
        {(announcement_form.announce_type === 1 ||
          announcement_form.announce_type === 4) && (
          <div className="">
            <InputCarNumber
                label={t("car_number")}
                className="w-full h-10.5 block border border-solid border-gray rounded uppercase"
                validPattern={carNumberRequiredPattern}
                onChange={(e) => dispatch(
                    setAllInputs({
                        key: "announcement_form",
                        name: "car_number",
                        value: e,
                    })
                )} value={undefined}
            />
          </div>
        )}
        {announcement_form.announce_type === 1 && (
          <Input
            label={"VIN"}
            className="[&>input]:uppercase"
            validpattern={/^(?:[A-Za-z0-9]{17})?$/}
            onChange={(e) =>
              dispatch(
                setAllInputs({
                  key: "announcement_form",
                  name: "vin",
                  value: e.target.value.trim(),
                })
              )
            }
          />
        )}
        <div className={announcement_form.announce_type === 4 ? "" : "col-span-2"}>
          <Input
            label={t("link")}
            placeholder="https://..."
            onChange={(e) =>
              dispatch(
                setAllInputs({
                  key: "announcement_form",
                  name: "link",
                  value: e.target.value.trim(),
                })
              )
            }
          />
        </div>
        <div className="col-span-2 space-y-2">
          <p className="dark:text-white">{t("comment")}</p>
          <textarea
            className="w-full p-4 mb-4 border border-solid border-gray rounded resize-none dark:bg-softBlack dark:text-white"
            placeholder="Səbəbi yazın"
            rows={4}
            onChange={(e) =>
              dispatch(
                setAllInputs({
                  key: "announcement_form",
                  name: "comment",
                  value: e.target.value,
                })
              )
            }
          />
        </div>
        <Button
          className="bg-blue text-white col-span-2"
          isLoading={isLoading}
          disabled={!validForm}
        >
          {t("remember")}
        </Button>
      </form>
    </>
  );
};

export default AnnouncementFormCreate;
