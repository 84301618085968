import { Outlet } from "react-router-dom";
import Footer from "src/components/Footer";

const Inner = () => {
  return (
    <div className="w-full min-h-screen flex flex-col p-4">
      <Outlet />
      <div className="mt-auto">

      <Footer />
      </div>
    </div>
  );
};

export default Inner;
