import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { init } from 'echarts';
import { removeArrayValueByIndex } from 'src/helper/removeArrayValueByIndex';
import { IRequestForm } from 'src/types/i_request_form';

const initialState: { request_form: IRequestForm, request_detail: any } = {
    request_form: {
        type: 1,
        source_type: 0,
        title_id: 0,
        project_id: 0,
        subject_id: 0,
        assigned_group_id: 0,
        full_name: "",
        priority_status: 2,
        phone: "",
        assigned_user_id: 0,
        email: "",
        tags: [],
        text: "",
    },
    request_detail: {
        files: [],
        assigned_groups: [],
        assigned_users: [],
        tags: [],
        text: ""
    }
}

export const requestsSlice = createSlice({
    name: 'requests',
    initialState,
    reducers: {
        setAllInputs: (state, action: PayloadAction<any>) => {
            //@ts-ignore
            state[action.payload.key as keyof typeof state] = {
                //@ts-ignore
                ...state[action.payload.key as keyof typeof state],
                [action.payload.name]: action.payload.value,
            };
        },
        setRequestCheckboxes: (state, action: PayloadAction<any>) => {
            if (action.payload === 0) {
                if (state.request_form.tags.includes(action.payload)) {
                    state.request_form.tags = []
                } else {
                    state.request_form.tags.push(action.payload)
                }
            }
            if (state.request_form.tags.includes(action.payload)) {
                removeArrayValueByIndex(state.request_form.tags, action.payload)
            } else {
                state.request_form.tags.push(action.payload)
            }
        },
        setAssignedRequests: (state, action) => {
            let type = action.payload.type == "user" ? "assigned_users" : "assigned_groups"

            if (state.request_detail[type].some((req) => req.id == action.payload.id)) {
                state.request_detail[type] = state.request_detail[type].filter((reqq) => reqq.id != action.payload.id)
            } else {
                state.request_detail[type].push(action.payload)
            }
        },
        resetRequestDetail: (state) => {
            Object.assign(state.request_detail, initialState.request_detail);
        },
        resetRequestForm: (state) => {
            Object.assign(state.request_form, initialState.request_form);
        },
    },
})

export const { setAllInputs, setRequestCheckboxes, setAssignedRequests, resetRequestDetail, resetRequestForm } = requestsSlice.actions

export default requestsSlice.reducer