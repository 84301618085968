import { FC, useEffect, useState } from "react";
import { IInput } from "src/types/Common/i_input";
import { ReactComponent as EyeOffSVG } from "src/assets/vectors/eye_off.svg";
import { ReactComponent as EyeSVG } from "src/assets/vectors/eye.svg";

const InputPassword: FC<IInput> = ({
  label,
  className,
  validpattern,
  onChange,
  children,
  ...inputProps
}) => {
  const [visible, setVisible] = useState(false);
  const [error, setError] = useState(false);
  const [dirty, setDirty] = useState(false);

  const blurHandler = (e: any) => {
    setDirty(true);
  };

  useEffect(() => {
    if (typeof validpattern == "boolean") {
      setError(validpattern);
    }
  }, [onChange]);

  return (
    <div className="w-full">
      {label && <p className="mb-2 dark:text-white">{label}</p>}
      <div
        className={[
          `flex items-center px-4 border h-10.5 border-solid rounded dark:bg-softBlack ${
            error && dirty ? "border-red" : "border-gray"
          }`,
          className,
        ].join(" ")}
      >
        <input
          {...inputProps}
          type={visible ? "text" : "password"}
          className="w-full dark:bg-softBlack dark:text-white"
          onBlur={(e) => blurHandler(e)}
          onChange={(e) => {
            onChange?.(e);
            if (typeof validpattern != "boolean") {
              validpattern?.test(e.target.value)
                ? setError(false)
                : setError(true);
            }
          }}
        />
        {visible ? (
          <EyeSVG
            className="cursor-pointer dark:text-white"
            onClick={() => setVisible(!visible)}
          />
        ) : (
          <EyeOffSVG
            className="cursor-pointer dark:text-white"
            onClick={() => setVisible(!visible)}
          />
        )}
      </div>
    </div>
  );
};

export default InputPassword;
