import { ChangeEvent, FC, Fragment, useEffect, useState } from "react";
import { Combobox, Transition } from "@headlessui/react";
import { ISearch } from "src/types/Common/i_search";
import { IBaseSelect } from "src/types/Common/i_base_select";
import t from "src/hooks/useTranslate";
import { useDebounceEffect } from "src/hooks/useDebounceEffect";
import { ReactComponent as SearchSVG } from "src/assets/vectors/search.svg";
import { ReactComponent as CheckSVG } from "src/assets/vectors/check.svg";
import { ReactComponent as UsersSvg } from "src/assets/vectors/users.svg";
import { ReactComponent as UserSvg } from "src/assets/vectors/user.svg";
import Shimmer from "../Shimmer";

//TODO: Loading

const InputSearchGroupNUsersMixed: FC<any> = ({
  data,
  className,
  placeholder,
  showItemCount,
  isLoading,
  selectedData,
  label,
  optionHidden = false,
  onChange,
  onInput,
}) => {
  const [selecteds, setSelecteds] = useState<{ id: number; name: string }[]>(
    []
  );
  const [allSelected, setAllSelected] = useState([]);
  const [query, setQuery] = useState("");
  const [value, setValue] = useState("");
  const [dirty, setDirty] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const changeHandler = (e: any) => {
    const lookup = e.reduce((a, b) => {
      a[b.id] = ++a[b.id] || 0;
      return a;
    }, {});

    e.filter((item) => lookup[item.id]).length > 0
      ? setSelecteds(
          selecteds.filter(
            (item) => item.id != e.filter((item) => lookup[item.id])[0].id
          )
        )
      : setSelecteds(e);
    // onChange?.(e);
  };

  useDebounceEffect(() => dirty && onInput(value), 500, [query, value]);

  const inputHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setQuery(e.currentTarget.value);
    setValue(e.currentTarget.value);
    // setSelected([{ id: 0, name: e.currentTarget.value }]);
    setDirty(true);
  };

  useEffect(() => {
    if (data) {
      // setAllSelected([]);
      data.forEach((el) => {
        selectedData.forEach((slcEl) => {
          if (el.id == slcEl.id && el.type == slcEl.type) {
            setAllSelected((prev) => [...prev, slcEl]);
          }
        });
      });
    }
  }, [data, setSelecteds]);

  return (
    <div className={["w-full flex", className].join(" ")}>
      {label && <p className="mb-2 dark:text-white">{label}</p>}
      <Combobox value={selecteds} onChange={changeHandler} multiple>
        <div className="relative w-full">
          <div className="relative h-10.5 px-3 border border-gray flex gap-x-2 items-center cursor-default overflow-hidden rounded bg-white dark:bg-softBlack text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
            <Combobox.Input
              className="w-full border-none text-sm leading-5 text-darkBlue bg-white dark:bg-softBlack dark:text-white"
              // displayValue={(search: any) => search.name}
              onChange={inputHandler}
              placeholder={placeholder}
            />
            <Combobox.Button className="flex items-center">
              <SearchSVG className="text-gray dark:text-white" />
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            // afterLeave={() => setQuery("")}
          >
            <Combobox.Options
              className={`absolute
                ${
                  optionHidden ? "hidden" : ""
                } right-0 mt-1 min-w-[100%] w-auto overflow-auto z-20 rounded-md bg-white dark:bg-softBlack py-4 px-4 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm border border-solid border-gray ${
                data?.length > 4 ? "h-[245px]" : "h-auto"
              }`}
            >
              <>
                {data && !isLoading ? (
                  data?.length === 0 && query !== "" ? (
                    <div className="relative h-auto cursor-default select-none py-2 px-4 text-gray dark:text-white">
                      {t("nothing_found")}
                    </div>
                  ) : (
                    data?.slice(0, showItemCount).map((obj: any) => (
                      <Combobox.Option
                        key={obj.id}
                        onClick={() => {
                          onChange(obj);
                        }}
                        className={({ active }) =>
                          `relative h-10.5 cursor-pointer flex items-center justify-between select-none p-2 pl-10 rounded hover:bg-light dark:hover:bg-lightBlack dark:text-white ${
                            active ? "bg-light dark:bg-lightBlack" : ""
                          }`
                        }
                        value={obj}
                      >
                        {({ selected, active }) => {
                          return (
                            <>
                              {/* allSelected.some(
                                (el) => el.id == obj.id && el.type == obj.type
                              ) || */}
                              {selecteds.some((el) => el.id == obj.id) ? (
                                <span
                                  className={`absolute inset-y-0 left-0 flex items-center text-green pl-3 ${
                                    active ? "" : ""
                                  }`}
                                >
                                  <CheckSVG />
                                </span>
                              ) : null}
                              {obj.name} {obj.lastname || ""}{" "}
                              {obj.type === "role" ? (
                                <span className="h-6 rounded-full flex items-center gap-x-2 bg-blue px-4 py-1 ml-4 text-white">
                                  <UsersSvg className="w-4 h-4" />
                                  {t("group")}
                                </span>
                              ) : (
                                obj.role && (
                                  <span className="h-6 rounded-full flex items-center gap-x-2 bg-green px-4 py-1 ml-4 text-white whitespace-nowrap">
                                    <UserSvg className="w-4 h-4" />{" "}
                                    {obj.role.name}
                                  </span>
                                )
                              )}
                            </>
                          );
                        }}
                      </Combobox.Option>
                    ))
                  )
                ) : (
                  <div className="space-y-4">
                    <Shimmer className="w-full h-10.5" />
                    <Shimmer className="w-full h-10.5" />
                    <Shimmer className="w-full h-10.5" />
                  </div>
                )}
              </>
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </div>
  );
};

export default InputSearchGroupNUsersMixed;
