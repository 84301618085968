import { useState } from "react";
import { ddmmyyyy } from "src/helper/getFormattedDate";
import t from "src/hooks/useTranslate";
import { useOperatorsReportByTargetMutation } from "src/services/reportService";
import ReportTemplate from "./ReportTemplate";

const OperatorsCommon = () => {
  const [params, _] = useState(new URLSearchParams());
  const [date, setDate] = useState(
    `${ddmmyyyy(
      new Date(new Date().setMonth(new Date().getMonth() - 1))
    )}-${ddmmyyyy(new Date())}`
  );
  const [
    getExcel,
    { isLoading: isExcelDownloading, isSuccess: isExcelDownloaded },
  ] = useOperatorsReportByTargetMutation();

  const onGetExcel = async (check) => {
    params.set("check_target", check.toString());
    await getExcel("?" + params.toString());
  };

  return (
    <ReportTemplate
      title={t("Ümumi hesabat")}
      index={1}
      onChange={(e: string) => {
        setDate(e);
        params.set("date", e);
      }}
      onSubmit={() => onGetExcel(0)}
      isLoading={isExcelDownloading}
    />
  );
};

export default OperatorsCommon;
