import { useEffect, useState } from "react";
import t from "src/hooks/useTranslate";
import { ReactComponent as UserStatisticsSVG } from "src/assets/vectors/user_statistics.svg";
import { ReactComponent as InfoSVG } from "src/assets/vectors/info.svg";
import alverchi_meter_data from "src/data/alverchi_meter_data.json";
import Shimmer from "../Common/Shimmer";

const AlverchiMeter = ({ data, isLoading, setParams }) => {
  const [selectedTab, setSelectedTab] = useState(1);
  const [min, setMin] = useState(1);
  const [max, setMax] = useState(0);
  const tabs = [
    {
      id: 1,
      key: "quarter",
      name: "quarter",
    },
    {
      id: 2,
      key: "half_year",
      name: "half_year",
    },
    {
      id: 3,
      key: "year",
      name: "yearly",
    },
  ];

  useEffect(() => {
    if (data && data.data?.length > 0) {
      let arr = [];
      for (let i = 0; i < data.data?.length; i++) {
        arr.push(data.data[i]?.announcement_count);
      }
      setMax(Math.max(...arr));
      setMin(Math.min(...arr));
    }
  }, [data]);

  return (
    <div className="flex flex-col relative h-[400px] px-4 py-6 w-[calc(65%-12px)] bg-white dark:bg-lightBlack rounded-xl drop-shadow-lg">
      <div className="flex items-center gap-x-2">
        <UserStatisticsSVG className="text-red" />
        <h3 className="text-16 font-bold dark:text-white">ALVERCHI METR</h3>
        <div className="ml-auto">
          {tabs.map((tab) => (
            <button
              key={tab.id}
              className={`text-gray mr-4 last:mr-0 ${
                selectedTab === tab.id
                  ? "underline font-semibold"
                  : "font-medium"
              }`}
              onClick={() => {
                setSelectedTab(tab.id);
                setParams(`?filter=${tab.key}`);
              }}
            >
              {t(tab.name)}
            </button>
          ))}
        </div>
      </div>
      <ul className="h-full flex gap-x-4 overflow-auto pb-4 mt-6">
        {data && !isLoading ? (
          data.data?.map((user) => (
            <li
              key={user.id}
              className="flex flex-col items-center gap-y-4 text-10"
            >
              <p className="dark:text-white">
                {user.announcement_count + " " + t("announcement")}
              </p>
              <div className="relative h-full w-1 bg-cream rounded-full">
                <div
                  className="absolute bottom-0 left-1/2 -translate-x-1/2 w-[200%] bg-green rounded-full"
                  style={{
                    height:
                      min == max
                        ? "100%"
                        : user.announcement_count == min
                        ? "3%"
                        : ((user.announcement_count - min) * 100) /
                            (max - min) +
                          "%",
                  }}
                ></div>
              </div>
              <p className="text-center dark:text-white">
                +{user.user_phone} <br />
                <span className="whitespace-nowrap">({user.user_name})</span>
              </p>
            </li>
          ))
        ) : <div className="w-full flex items-center gap-x-4">
          {[...Array(6)].map((_, i) => (
            <div className="w-32 h-full flex flex-col items-center gap-y-2" key={i}>
              <Shimmer className="w-24 h-4" />
              <Shimmer className="w-4 h-full" />
              <Shimmer className="w-16 h-6" />
              <Shimmer className="w-24 h-6" />
            </div>
          ))}
        </div> }
      </ul>
      <p className="mt-4 text-12 text-gray flex items-center gap-x-2">
        <InfoSVG /> Rüb ərzində 2 və daha artıq elan sahibi
      </p>
    </div>
  );
};

export default AlverchiMeter;
