import LightGallery from "lightgallery/react";

// import styles
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
// import plugins if you need
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";
import { useCallback, useEffect, useRef, useState } from "react";

const items = [
  {
    id: "1",
    src: "/assets/images/Cover.webp",
  },
  {
    id: "2",
    src: "/assets/images/Cover.webp",
  },
  {
    id: "3",
    src: "/assets/images/Cover.webp",
  },
  {
    id: "4",
    src: "/assets/images/Cover.webp",
  },
  {
    id: "5",
    src: "/assets/images/Cover.webp",
  },
];

type Props = {};

const MashinAlMessagesGallery = ({ images }) => {
  const lightGallery = useRef<any>(null);

  const onInit = useCallback((detail) => {
    if (detail) {
      lightGallery.current = detail.instance;
    }
  }, []);

  const getItems = useCallback(() => {
    return images.map((item) => (
      <div key={item.id} className="w-full cursor-pointer" data-src={item.src}>
        <img
          className="w-full min-h-full object-cover rounded"
          src={item.thumb}
        />
      </div>
    ));
  }, [images]);

  useEffect(() => {
    lightGallery.current.refresh();
  }, [images]);

  const afterLightBoxOpen = () => {
    document.documentElement.style.overflow = "hidden";
  };
  const afterLightBoxClose = () => {
    document.documentElement.style.overflow = "visible";
  };
  return (
    <LightGallery
      plugins={[lgZoom, lgThumbnail]}
      elementClassNames="w-full flex gap-4"
      onInit={onInit}
      onAfterOpen={afterLightBoxOpen}
      onAfterClose={afterLightBoxClose}
    >
      {getItems()}
    </LightGallery>
  );
};

export default MashinAlMessagesGallery;
