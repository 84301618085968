
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { getCookie } from "src/helper/custom_cookies";

export const requestsService = createApi({
    reducerPath: "requestsService",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_BASE_URL,
        prepareHeaders: (headers) => {
            localStorage.setItem("ssnxprd", "180")
            headers.set("Authorization", getCookie("mcrmtkn") ? "Bearer " + getCookie("mcrmtkn") : "")
            headers.set('Accept', 'application/json')
            return headers
        },
    }),
    tagTypes: ["Requests"],
    endpoints: (builder) => ({
        addRequestTemplates: builder.mutation({
            query: ({ type, data }) => {
                return {
                    url: `/tickets/settings/${type}/store`,
                    method: "post",
                    body: data
                }
            },
            invalidatesTags: ["Requests"]
        }),
        deleteRequestTemplates: builder.mutation({
            query: ({ type, id }) => {
                return {
                    url: `/tickets/settings/${type}/${id}`,
                    method: "delete",
                }
            },
            invalidatesTags: ["Requests"]
        }),
        getAllTemplates: builder.query({
            query: (withCount) => {
                return {
                    url: `/tickets/settings/all${withCount}`,
                    method: "get"
                }
            },
            providesTags: ["Requests"]
        }),
        addRequest: builder.mutation({
            query: (body) => {
                return {
                    url: `/tickets/store`,
                    method: "post",
                    body
                }
            },
            invalidatesTags: ["Requests"]
        }),
        getRequestPartially: builder.query({
            query: (query: any) => {
                return {
                    url: "/tickets/list" + query,
                    method: "get"
                }
            },
            providesTags: ["Requests"]
        }),
        getRequestsStatus: builder.query({
            query: () => {
                return {
                    url: "/tickets/statues",
                    method: "get"
                }
            },
            providesTags: ["Requests"]
        }),
        getRequest: builder.query({
            query: (id) => {
                return {
                    url: `/tickets/${id}`,
                    method: "get"
                }
            },
            providesTags: ["Requests"]
        }),
        getRequestTags: builder.query({
            query: () => {
                return {
                    url: `/tickets/settings/tags`,
                    method: "get"
                }
            },
            providesTags: ["Requests"]
        }),
        sendRequestAnswer: builder.mutation({
            query: ({id, body}) => {
                return {
                    url: `tickets/${id}/answer`,
                    method: "post",
                    body
                }
            },
            invalidatesTags: ["Requests"]
        }),
        closeRequest: builder.mutation({
            query: (id) => {
                return {
                    url: `tickets/close/${id}`,
                    method: "post"
                }
            },
            invalidatesTags: ["Requests"]
        }),
        openRequest: builder.mutation({
            query: (id) => {
                return {
                    url: `tickets/open/${id}`,
                    method: "post"
                }
            },
            invalidatesTags: ["Requests"]
        }),
        deleteRequest: builder.mutation({
            query: (body) => {
                return {
                    url: `tickets/delete`,
                    method: "delete",
                    body
                }
            },
            invalidatesTags: ["Requests"]
        }),
    })
})

export const { useAddRequestTemplatesMutation, useDeleteRequestTemplatesMutation, useGetAllTemplatesQuery, useAddRequestMutation, useGetRequestPartiallyQuery, useGetRequestsStatusQuery, useGetRequestQuery, useGetRequestTagsQuery, useSendRequestAnswerMutation, useCloseRequestMutation, useOpenRequestMutation, useDeleteRequestMutation } = requestsService