import React, { useEffect, useRef, useState } from "react";
import t from "src/hooks/useTranslate";
import { ReactComponent as CameraSVG } from "src/assets/vectors/camera.svg";
import { ReactComponent as XSVG } from "src/assets/vectors/x.svg";
import Modal from "./Modal";
import Shimmer from "./Shimmer";
import TestCropper from "./Cropper";
import imageCompression from "browser-image-compression";

const Avatar = ({ setImage, imageUrl }: any) => {
  const [modal, setModal] = useState(false);
  const [file, setFile] = useState<any>(null);
  const [previewImg, setPreviewImg] = useState("");
  const fileRef = useRef<any>(null);
  const canvas = useRef<any>(null);

  const openModal = () => {
    fileRef.current.click();
  };

  const onSelectFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const imageFile = e.target.files[0];

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1024,
    };
    try {
      const compressedFile = await imageCompression(imageFile, options);
      setFile([compressedFile]);
      setModal(true);
    } catch (error) {
      console.log(error);
    }
    // setFile(e.target.files);
    // setModal(true);
  };

  const onSave = (base64: any) => {
    fileRef.current.value = "";
    setPreviewImg(base64);
    setImage(base64);
    setModal(false);
  };

  const removeSelected = () => {
    setPreviewImg("");
    setImage("");
  };

  useEffect(() => {
    imageUrl && setPreviewImg(imageUrl);
  }, [imageUrl]);

  return (
    <div className="flex flex-col items-center justify-center h-min gap-y-2">
      <p className="leading-[1.45] dark:text-white">{t("avatar")}</p>
      {previewImg ? (
        <div className="relative">
          <div
            className="relative min-w-[112px] max-w-[112px] min-h-[112px] max-h-[112px] rounded-full overflow-hidden cursor-pointer group"
            onClick={openModal}
          >
            <img src={previewImg} alt="" className="w-full" />

            <div className="absolute inset-0 w-full h-full flex flex-col justify-center items-center duration-300 opacity-0 group-hover:opacity-100  bg-white/50 z-10">
              <CameraSVG />
              <p className="text-12 font-medium">{t("change_photo")}</p>
            </div>
          </div>
          <XSVG
            className="absolute top-0 right-0 z-10 text-white bg-red w-8 h-8 rounded-full p-1 border-2 border-solid border-white dark:border-lightBlack cursor-pointer hover:brightness-150"
            onClick={removeSelected}
          />
        </div>
      ) : (
        <div
          className="min-w-[112px] max-w-[112px] min-h-[112px] max-h-[112px] border border-solid border-gray flex flex-col items-center justify-center rounded-full text-gray dark:text-white cursor-pointer"
          onClick={openModal}
        >
          <CameraSVG />
          <p className="text-12 font-medium">{t("add_photo")}</p>
        </div>
      )}
      <input
        type="file"
        accept="image/*"
        ref={fileRef}
        className="hidden"
        onChange={onSelectFile}
      />
      <Modal
        visible={modal}
        setVisible={(e) => {
          fileRef.current.value = "";
          setModal(e);
        }}
        dialogClassName="w-1/2"
      >
        <div className="p-4">
          <TestCropper files={file} onSave={onSave} />
        </div>
      </Modal>
    </div>
  );
};

export default Avatar;
