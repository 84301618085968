export const ddmmyyyy = (d: any) => {
    const yyyy = d.getFullYear();
    let mm = d.getMonth() + 1;
    let dd = d.getDate();

    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;

    return dd + "." + mm + "." + yyyy;
}

export const hhmm = (d: any) => {
    let hh = d.getHours()
    let mins = d.getMinutes()
    return (hh < 10 ? "0" + hh : hh) + ":" + (mins < 10 ? "0" + mins : mins);
}

export const hhmmss = (d: any) => {
    let ss = d.getSeconds()
    return hhmm(d) + ":" + (ss < 10 ? "0" + ss : ss);
}

export const ddmmyyyy_hhmm = (d: any) => {
    return ddmmyyyy(d) + " " + hhmm(d);
}

export const ddmmyyyy_hhmmss = (d: any) => {
    return ddmmyyyy(d) + " " + hhmmss(d)
}

export const msToHMS = (ms: number) => {
    // 1- Convert to seconds:
    let seconds: number = Number(ms / 1000);
    // @ts-ignore
    let hours: number = parseInt(seconds / 3600); // 3,600 seconds in 1 hour
    seconds = seconds % 3600; // seconds remaining after extracting hours
    // @ts-ignore
    let minutes: number = parseInt(seconds / 60); // 60 seconds in 1 minute
    // @ts-ignore
    seconds = parseInt(seconds % 60);
    return hours + ":" + minutes + ":" + seconds;
}

export const secondsDiff = (dateTimeValue2, dateTimeValue1) => {
    var differenceValue =(dateTimeValue2.getTime() - dateTimeValue1.getTime()) / 1000;
    differenceValue /= 1;
    return Math.abs(Math.round(differenceValue));
 }