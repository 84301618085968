import { SetStateAction, useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import Button from "src/components/Common/Inputs/Button";
import GroupsForm from "src/components/Groups/GroupsForm";
import GroupsPermissions from "src/components/Groups/GroupsPermissions";
import GroupsUsers from "src/components/Groups/GroupsUsers";
import Header from "src/components/Header/Header";
import { GroupsStepsData } from "src/data/create_groups_steps_data";
import { EGroupsCrudSteps } from "src/enums/e_groups_crud_steps";
import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import t from "src/hooks/useTranslate";
import {
  useGetGroupAndPermissionsQuery,
  useGetGroupByIdQuery,
  useSetGroupPermissionsMutation,
  useUpdateGroupMutation,
} from "src/services/groupAndPermissionsService";
import { ReactComponent as PlusSVG } from "src/assets/vectors/plus.svg";
import { setGroup, setPermissions } from "src/store/Groups/groupsStore";
import { setPermissions as setPermissionStore } from "src/store/Settings/permissionsStore";
import { successToast } from "src/store/Settings/toastStore";
import Modal from "src/components/Common/Modal";
import GroupAddUserModal from "src/components/Groups/GroupAddUserModal";
import { useSetUsersToGroupMutation } from "src/services/usersService";
import GroupAddResponsiblePerson from "src/components/Groups/GroupAddResponsiblePerson";

const GroupsEdit = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [modal, setModal] = useState(false);
  const { data: group } = useGetGroupByIdQuery(params.groupId);
  const { data: groupAndPermissions } = useGetGroupAndPermissionsQuery("");
  const [currentStep, setCurrentStep] = useState(EGroupsCrudSteps.form);
  const groupForm = useAppSelector((state) => state.groups.groups_form);
  const newGroupData = useAppSelector((state) => state.groups.newGroup);
  const groupPermissions = useAppSelector(
    (state) => state.groups.groups_form.permissions
  );
  const groups_permissions = useAppSelector(
    (state) => state.groups.groups_permissions
  );

  useEffect(() => {
    groups_permissions.child.length > 0 &&
      groupPermissions &&
      dispatch(
        setPermissionStore({
          exist: groupPermissions,
          data: groups_permissions.child,
        })
      );
  }, [groups_permissions, groupPermissions]);

  const [
    updateGroup,
    {
      data: updatedGroupData,
      isLoading: isGroupFormUpdating,
      isSuccess: isGroupFormUpdated,
      isError: isGroupFormNotUpdated,
    },
  ] = useUpdateGroupMutation();

  const [
    setGroupsPermission,
    {
      data: groupsPermissionData,
      isLoading: isGroupsPermissionLoading,
      isSuccess: isGroupsPermissionSuccess,
      isError: isGroupsPermissionError,
    },
  ] = useSetGroupPermissionsMutation();

  const [
    setUsersToGroup,
    {
      isLoading: isSetUsersToGroupLoading,
      isSuccess: isSetUsersToGroupSuccess,
      isError: isSetUsersToGroupError,
    },
  ] = useSetUsersToGroupMutation();

  const renderSwitch = () => {
    switch (currentStep) {
      case EGroupsCrudSteps.form:
        return (
          <GroupsForm
            onSubmit={onUpdateForm}
            groupForm={groupForm}
            isGroupUpdating={isGroupFormUpdating}
          />
        );
      case EGroupsCrudSteps.permissions:
        return (
          <GroupsPermissions
            isLoading={isGroupsPermissionLoading}
            onSubmit={(allSelected: number[]) =>
              onUpdatePermissions(allSelected)
            }
          />
        );
      case EGroupsCrudSteps.users:
        return (
          <GroupsUsers
          // isLoading={isGroupsPermissionLoading}
          // onSubmit={(allSelected: number[]) =>
          //   onUpdatePermissions(allSelected)
          // }
          />
        );
      case EGroupsCrudSteps.responsible_person:
        return (
          <GroupAddResponsiblePerson
            isLoading={isGroupsPermissionLoading}
            onSubmit={(allSelected: number[]) =>
              onUpdatePermissions(allSelected)
            }
            setModal={undefined}
            group={group}
          />
        );
      default:
        return;
    }
  };

  useEffect(() => {
    setCurrentStep(EGroupsCrudSteps.form);
  }, []);

  useEffect(() => {
    groupAndPermissions && dispatch(setPermissions(groupAndPermissions));
  }, [groupAndPermissions]);

  useEffect(() => {
    group && dispatch(setGroup(group));
  }, [group]);

  const onUpdateForm = async (e: any) => {
    e.preventDefault();
    const body = {
      id: group.id,
      name: groupForm.name,
    };
    await updateGroup(body);
  };

  const onUpdatePermissions = async (allSelected: number[]) => {
    await setGroupsPermission({
      id: group.id,
      permission: allSelected.filter((permission: number) => permission !== 0),
    });
  };

  const onSetUsersToGroup = async (selectedUserIDs) => {
    await setUsersToGroup({
      id: selectedUserIDs,
      role: group.id,
    });
  };

  useEffect(() => {
    isGroupFormUpdated && dispatch(successToast("Məlumatlar dəyişdirildi"));
  }, [isGroupFormUpdated]);

  useEffect(() => {
    if (isGroupsPermissionSuccess) {
      dispatch(successToast("Məlumatlar dəyişdirildi"));
    }
  }, [isGroupsPermissionSuccess]);

  useEffect(() => {
    if (isSetUsersToGroupSuccess) {
      dispatch(successToast("Qrup isdifadəçilərə əlavə edildi"));
      setModal(false);
    }
  }, [isSetUsersToGroupSuccess]);

  return (
    <>
      <Header title={t("edit_group")} backLink="/groups" />
      <div className="relative py-6 bg-white dark:bg-lightBlack rounded-xl drop-shadow-lg">
        <ul className="flex items-center gap-x-4 px-4 pb-6 border-b border-solid border-b-gray">
          {GroupsStepsData.map((step) => {
            const Icons = step.icon;
            return (
              <li
                key={step.id}
                className={`flex items-center gap-x-2 px-4 py-2 rounded cursor-pointer ${
                  step.id === currentStep
                    ? "bg-light text-darkBlue dark:bg-softBlack dark:text-white"
                    : "font-medium text-gray dark:text-white"
                }`}
                onClick={() => setCurrentStep(step.id)}
              >
                <Icons />
                {t(step.title)}
              </li>
            );
          })}
          {currentStep === EGroupsCrudSteps.users && (
            <li className="flex items-center gap-x-4 ml-auto">
              <Button
                className="bg-green text-white flex items-center"
                onClick={() => setModal(true)}
              >
                <PlusSVG /> {t("add_user")}
              </Button>
            </li>
          )}
        </ul>
        <div
          className={`pt-6 ${
            currentStep === EGroupsCrudSteps.users ? "" : "px-4"
          }`}
        >
          {renderSwitch()}
        </div>
      </div>

      <Modal
        visible={modal}
        setVisible={setModal}
        dialogClassName="flex flex-col min-w-[700px] min-h-[500px]"
      >
        <GroupAddUserModal
          setModal={setModal}
          onSubmit={onSetUsersToGroup}
          isLoading={isSetUsersToGroupLoading}
        />
      </Modal>
    </>
  );
};

export default GroupsEdit;
