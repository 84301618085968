import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { getCookie } from "src/helper/custom_cookies"

export const groupAndPermissionsService = createApi({
    reducerPath: "groupAndPermissionsService",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_BASE_URL,
        prepareHeaders: (headers) => {
            headers.set("Authorization", getCookie("mcrmtkn") ? "Bearer " + getCookie("mcrmtkn") : "")
            headers.set('Accept', 'application/json')
            return headers
        },
        // credentials: "include",
    }),
    tagTypes: ["Group and Permissions"],
    endpoints: (builder) => ({
        getGroupAndPermissions: builder.query({
            query: (query) => {
                return {
                    url: `/users/permissions${query}`,
                }
            },
            providesTags: ["Group and Permissions"]
        }),
        createGroup: builder.mutation({
            query: (body: any) => {
                return {
                    url: "/group/store",
                    method: "post",
                    body,
                }
            },
            invalidatesTags: ["Group and Permissions"]
        }),
        deleteGroup: builder.mutation({
            query: ({ id }) => {
                return {
                    url: `/group/${id}/destroy`,
                    method: "post",
                    body: id
                }
            },
            invalidatesTags: ["Group and Permissions"]
        }),
        setGroupPermissions: builder.mutation({
            query: (body: any) => {
                return {
                    url: `group/${body.id}/change_permission`,
                    method: "post",
                    body: {permission: body.permission}
                }
            },
            invalidatesTags: ["Group and Permissions"]
        }),
        updateGroup: builder.mutation({
            query: (body: any) => {
                return {
                    url: `/group/${body.id}/update`,
                    method: "post",
                    body,
                }
            },
            invalidatesTags: ["Group and Permissions"]
        }),
        getGroupById: builder.query({
            query: (id) => {
                return {
                    url: `/group/${id}/view`,
                }
            },
            providesTags: ["Group and Permissions"]
        }),
    })
})

export const { useGetGroupAndPermissionsQuery, useCreateGroupMutation, useDeleteGroupMutation, useSetGroupPermissionsMutation, useUpdateGroupMutation, useGetGroupByIdQuery } = groupAndPermissionsService