import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { getCookie } from "src/helper/custom_cookies"
import { IGetAPermissionFields, IGetVacationFields } from "src/store/Profile/profileStore"
import { IProfile } from "src/types/i_profile"

export const profileService = createApi({
    reducerPath: "profileService",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_BASE_URL,
        prepareHeaders: (headers) => {
            localStorage.setItem("ssnxprd", "180")
            headers.set("Authorization", getCookie("mcrmtkn") ? "Bearer " + getCookie("mcrmtkn") : "")
            headers.set('Accept', 'application/json')
            return headers
        },
        // credentials: "include",
    }),
    tagTypes: ["Profile"],
    endpoints: (builder) => ({
        vacations: builder.query({
            query: (query) => {
                return {
                    url: "/profile/vacations" + query,
                }
            },
            providesTags: ["Profile"]
        }),
        sendVacationFile: builder.mutation({
            query: ({ id, body }) => {
                return {
                    url: `/profile/update_vacation/${id}`,
                    method: "post",
                    body,
                }
            },
            invalidatesTags: ["Profile"]
        }),
        timeOffs: builder.query({
            query: (query) => {
                return {
                    url: `/time_offs${query}`,
                }
            },
            providesTags: ["Profile"]
        }),
        profileUpdate: builder.mutation({
            query: (body: IProfile) => {
                return {
                    url: "/profile/update_info",
                    method: "post",
                    body,
                }
            },
            invalidatesTags: ["Profile"]
        }),
        addTimeOff: builder.mutation({
            query: (body: IGetAPermissionFields) => {
                return {
                    url: "/profile/add_time_off",
                    method: "post",
                    body,
                }
            },
            invalidatesTags: ["Profile"]
        }),
        addVacation: builder.mutation({
            query: (body: IGetVacationFields) => {
                return {
                    url: "/profile/add_vacation",
                    method: "post",
                    body,
                }
            },
            invalidatesTags: ["Profile"]
        }),
        takeABreak: builder.mutation({
            query: () => {
                return {
                    url: "/working_hour/takeBreak",
                }
            },
        }),
        closeBreak: builder.mutation({
            query: (body: { password: string }) => {
                return {
                    url: "/working_hour/closeBreakTime",
                    method: "post",
                    body,
                }
            },
        }),
        takeLunch: builder.mutation({
            query: () => {
                return {
                    url: "/working_hour/lunchTime",
                }
            },
        }),
        closeLunch: builder.mutation({
            query: (body: { password: string }) => {
                return {
                    url: "/working_hour/closeLunchTime",
                    method: "post",
                    body,
                }
            },
        }),
    })
})

export const { useVacationsQuery, useSendVacationFileMutation, useTimeOffsQuery, useProfileUpdateMutation, useAddTimeOffMutation, useAddVacationMutation, useTakeABreakMutation, useCloseBreakMutation, useTakeLunchMutation, useCloseLunchMutation } = profileService