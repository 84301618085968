import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "src/components/Common/Inputs/Button";
import Header from "src/components/Header/Header";
import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import { ReactComponent as BackSVG } from "src/assets/vectors/back.svg";
import { ReactComponent as PlusSVG } from "src/assets/vectors/plus.svg";
import t from "src/hooks/useTranslate";
import { errorToast, successToast } from "src/store/Settings/toastStore";
import FAQForm from "src/components/FAQ/FAQForm";
import {
  useGetFaqByIdQuery,
  useGetFaqSectionsQuery,
  useUpdateFaqMutation,
} from "src/services/faqService";
import { setFaq } from "src/store/FAQ/FAQStore";

const FAQEdit = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useAppDispatch();
  const faqForms = useAppSelector((state) => state.faq.faq_forms);
  const { data: sections, isFetching: isSectionsFetching } =
    useGetFaqSectionsQuery("");
  const [validForm, setValidForm] = useState(false);

  const sectionData = useMemo(() => {
    let sectionData = [];
    sections?.forEach((element) => {
      sectionData.push({ id: element.id, name: element.title });
    });
    return sectionData;
  }, [sections]);

  const {
    data: faq,
    isFetching: isFaqFetching,
    isError: isFaqNotFetched,
  } = useGetFaqByIdQuery(params.faqId);
  
  const [
    updateFaq,
    {
      isLoading: isFaqUpdating,
      isSuccess: isFaqUpdated,
      isError: isFaqNotUpdated,
      error: faqError,
    },
  ] = useUpdateFaqMutation();

  const onUpdateFaq = async () => {
    await updateFaq(faqForms[0]);
  };

  useEffect(() => {
    if (isFaqNotUpdated) {
      //@ts-ignore
      dispatch(errorToast(faqError?.data.message));
    }
  }, [isFaqNotUpdated]);

  useEffect(() => {
    faq && dispatch(setFaq(faq));
  }, [faq]);

  useEffect(() => {
    if (
      faqForms.every((form) => form.title !== "") &&
      faqForms.every((form) => form.description !== "") &&
      // faqForms.every((form) =>
      //   form.quick_replies.some((reply) => reply.reply !== "")
      // ) &&
      faqForms.every((form) => form.category_id !== 0)
    ) {
      setValidForm(true);
    } else {
      setValidForm(false);
    }
  }, [faqForms]);

  useEffect(() => {
    if (isFaqUpdated) {
      dispatch(successToast("Faq successfully created"));
      navigate(`/faq?section=${faqForms[0].category_id}`);
    }
  }, [isFaqUpdated]);

  return (
    <>
      <Header title={t("faq")} />
      <div className="w-full relative bg-white dark:bg-lightBlack rounded-xl drop-shadow-lg">
        <div className="py-4 px-2 border-b border-solid border-gray text-gray">
          <Button
            className="flex items-center gap-x-1 font-medium"
            onClick={() => navigate("/faq")}
          >
            <BackSVG className="w-5" />
            {t("return_back")}
          </Button>
        </div>
        <div className="w-full">
          {faqForms.map((faq, i) => (
            <FAQForm sectionData={sectionData} faq={faq} faqForm={faqForms} key={faq.id} />
          ))}
          <div className="flex">
            <Button
              className="w-full bg-blue m-4 text-white"
              disabled={!validForm}
              isLoading={isFaqUpdating}
              onClick={() => {
                if (validForm) {
                  onUpdateFaq();
                }
              }}
            >
              {t("remember")}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default FAQEdit;
