import { useEffect, useState } from "react";
import IconWithTooltip from "src/components/Common/IconWithTooltip";
import { ReactComponent as EditSVG } from "src/assets/vectors/edit.svg";
import { ReactComponent as TrashSVG } from "src/assets/vectors/trash.svg";
import { ReactComponent as InfoSVG } from "src/assets/vectors/info.svg";
import { ReactComponent as EyeSVG } from "src/assets/vectors/eye.svg";
import { ReactComponent as XCircledSVG } from "src/assets/vectors/x_circled.svg";
import { ReactComponent as CloneSVG } from "src/assets/vectors/clone.svg";
import Shimmer from "src/components/Common/Shimmer";
import t from "src/hooks/useTranslate";
import { Link } from "react-router-dom";
import Pagination from "src/components/Common/Pagination";
import { useAppDispatch } from "src/hooks/redux";
import Select from "src/components/Common/Select";
import { IBaseSelect } from "src/types/Common/i_base_select";
import { resetForm } from "src/store/MashinalApp/mashinalAppStore";
import Modal from "src/components/Common/Modal";
import DeleteCautionModal from "src/components/Common/DeleteCautionModal";
import {
  useDeleteMashinalAppNotificationMutation,
  useRejectMashinalAppNotificationMutation,
} from "src/services/financeService";
import { successToast } from "src/store/Settings/toastStore";

type Props = {};

const mashinalAppNotificationStatus = [
  {
    id: 0,
    name: t("rejected"),
    color: "red",
  },
  {
    id: 1,
    name: t("pending"),
    color: "gray",
  },
  {
    id: 2,
    name: t("sent"),
    color: "green",
  },
];

const MashinAlAppTable = ({ data, isLoading, setParams }: any) => {
  const dispatch = useAppDispatch();
  const [params, setParamsState] = useState(new URLSearchParams());
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [activeId, setActiveId] = useState(0);
  const [modal, setModal] = useState(false);

  const [
    deleteNotification,
    {
      isLoading: notificationDeleting,
      isSuccess: notificationDeleted,
      isError,
      error,
    },
  ] = useDeleteMashinalAppNotificationMutation();

  const [
    rejectNotification,
    { isLoading: notificationRejecting, isSuccess: notificationRejected },
  ] = useRejectMashinalAppNotificationMutation();

  const onDeleteNotification = async () => {
    await deleteNotification(activeId);
  };

  const onRejectNotification = async (id) => {
    await rejectNotification(id);
  };

  useEffect(() => {
    dispatch(resetForm());
  }, []);

  useEffect(() => {
    if (notificationDeleted) {
      dispatch(successToast("Bildiriş uğurla silindi"));
      setModal(false);
    }
  }, [notificationDeleted]);

  useEffect(() => {
    if (notificationRejected) {
      dispatch(successToast("Bildirişe imtina edildi"));
    }
  }, [notificationRejected]);

  return (
    <>
      <div className="min-h-[556px] w-full relative flex flex-col justify-between bg-white dark:bg-lightBlack rounded-xl drop-shadow-lg">
        <div>
          <div className="py-6 px-4 flex justify-between items-center gap-x-4">
            <h2 className="text-16 font-bold dark:text-white">
              Mashin.al App üçün bildirişlər
            </h2>
            <div className="flex items-center gap-x-4">
              <div className="min-w-[200px]">
                <Select
                  data={[
                    { id: 10, name: t("all") },
                    ...mashinalAppNotificationStatus,
                  ]}
                  defaultValue={10}
                  onChange={(e) => {
                    params.set("status", e.id.toString());
                    params.delete("page");
                    setParams("?" + params.toString());
                  }}
                />
              </div>
              <Link
                to="create"
                className="w-full rounded px-6 bg-green text-white h-10 flex items-center justify-center whitespace-nowrap"
              >
                <span className="text-22 mr-2">+</span> {t("new_notification")}
              </Link>
              <Link
                to="statistics"
                className="w-full rounded px-6 bg-blue text-white h-10 flex items-center justify-center whitespace-nowrap"
              >
                {t("notification_statistics")}
              </Link>
            </div>
          </div>
          <table className="w-full">
            <thead>
              <tr className="[&>th]:text-left [&>th]:py-6 [&>th]:px-2 [&>th]:dark:text-white [&>th]:whitespace-nowrap border-y border-solid border-gray">
                <th className="w-[30%] !pl-4">{t("title")}</th>
                <th className="w-[25%]">{t("link")}</th>
                <th className="w-[18%]">{t("date")}</th>
                <th className="w-[17%]">{t("status")}</th>
                <th className="w-[15%] !pr-4">{t("operations")}</th>
              </tr>
            </thead>
            <tbody>
              {data && !isLoading ? (
                data.data.length > 0 &&
                data.data.map((notification) => (
                  <tr
                    className="relative [&>td]:py-4 [&>td]:px-2 [&>td]:dark:text-white after:absolute after:top-0 after:left-0 after:w-full after:h-[1px] after:bg-gray"
                    key={notification.id}
                  >
                    <td className="!pl-4">{notification.title}</td>
                    <td>{notification.route}</td>
                    <td>{notification.send_at}</td>
                    <td
                      style={{
                        color: mashinalAppNotificationStatus.find(
                          (m) => m.id === notification.status
                        ).color,
                      }}
                    >
                      {
                        mashinalAppNotificationStatus.find(
                          (m) => m.id === notification.status
                        ).name
                      }
                    </td>
                    <td className="!pr-4">
                      {notification.status === 2 ? (
                        <Link to={`edit/${notification.id}?view=true`}>
                          <IconWithTooltip
                            icon={<EyeSVG className="text-gray" />}
                            tooltip={t("view")}
                            onClick={() => {
                              setActiveId(notification.id);
                              setModal(true);
                            }}
                          />
                        </Link>
                      ) : (
                        <div className="flex items-center gap-x-1">
                          <Link to={`edit/${notification.id}`}>
                            <IconWithTooltip
                              icon={<EditSVG className="text-blue" />}
                              tooltip={t("edit")}
                            />
                          </Link>

                          <IconWithTooltip
                            icon={<TrashSVG className="text-red" />}
                            tooltip={t("delete")}
                            onClick={() => {
                              setActiveId(notification.id);
                              setModal(true);
                            }}
                          />
                          {notification.status === 1 &&
                            (notificationRejecting ? (
                              <div className="p-2">
                                <div className="relative">
                                  <div className="w-6 h-6 border-light opacity-20 border-2 rounded-full"></div>
                                  <div className="w-6 h-6 border-light opacity-70 border-t-2 animate-spin rounded-full absolute left-0 top-0"></div>
                                </div>
                              </div>
                            ) : (
                              <IconWithTooltip
                                icon={
                                  <XCircledSVG className="text-black dark:text-white" />
                                }
                                tooltip={t("reject")}
                                onClick={() => {
                                  onRejectNotification(notification.id);
                                }}
                              />
                            ))}
                        </div>
                      )}
                      <Link to={`edit/${notification.id}?clone=true`}>
                        <IconWithTooltip
                          icon={<CloneSVG className="text-gray" />}
                          tooltip={t("clone")}
                          onClick={() => {
                            console.log("clone", notification.id);
                            setActiveId(notification.id);
                            setModal(true);
                          }}
                        />
                      </Link>
                    </td>
                  </tr>
                ))
              ) : (
                <>
                  {[...Array(10)].map((_, i) => (
                    <tr className="[&>td]:py-4 [&>td]:px-2" key={i}>
                      <td className="w-[30%]">
                        <Shimmer className="h-10.5" />
                      </td>
                      <td className="w-[25%]">
                        <Shimmer className="h-10.5" />
                      </td>
                      <td className="w-[18%]">
                        <Shimmer className="h-10.5" />
                      </td>
                      <td className="w-[17%]">
                        <Shimmer className="h-10.5" />
                      </td>
                      <td className="w-[15%]">
                        <Shimmer className="h-10.5" />
                      </td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </table>
          {data && !data.data.length && (
            <div className="w-full h-[500px] p-4 flex items-center justify-center">
              <div className="w-fit flex items-center gap-x-3 p-4 rounded-xl bg-light dark:bg-softBlack text-gray dark:text-white">
                <InfoSVG />
                <p>Göstəriləcək hər hansı bir məlumat yoxdur</p>
              </div>
            </div>
          )}
        </div>
        {data?.meta.total > 5 && (
          <div className="w-full px-4 py-6">
            <Pagination
              page={Math.ceil(data?.meta.total / perPage)}
              total={data?.meta.total}
              perPage={perPage}
              pageChange={(e) => {
                setPage(e + 1);
                params.set("page", (e + 1).toString());
                setParams("?" + params.toString());
              }}
              setPerPage={(val) => {
                setPerPage(val);
                params.set("page", "1");
                params.set("per_page", val.toString());
                setParams("?" + params.toString());
              }}
            />
          </div>
        )}
      </div>
      <Modal
        visible={modal}
        setVisible={setModal}
        dialogClassName="min-w-[450px]"
      >
        <DeleteCautionModal
          setModal={setModal}
          onSubmit={onDeleteNotification}
          isLoading={notificationDeleting}
        />
      </Modal>
    </>
  );
};

export default MashinAlAppTable;
