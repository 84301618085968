import { useEffect, useMemo, useState } from "react";
import t from "src/hooks/useTranslate";
import Doughnut from "../Common/Charts/Doughnut";
import Shimmer from "../Common/Shimmer";

const SuccessfullAnnouncementsGraphics = ({ data, isLoading, setParams }) => {
  const [selectedTab, setSelectedTab] = useState(1);
  const [chartData, setChartData] = useState([]);

  const tabs = [
    {
      id: 1,
      key: "month",
      name: "monthly",
    },
    {
      id: 2,
      key: "quarter",
      name: "quarter",
    },
    {
      id: 3,
      key: "year",
      name: "yearly",
    },
  ];

  useMemo(() => {
    if (data && data.data.length > 0) {
      setChartData([]);
      let totalData = [];
      for (let i = 0; i < data.data.length; i++) {
        totalData.push({
          value: data.data[i].count,
          name: data.data[i].full_name,
        });
      }
      totalData.forEach((d) => {
        if (d.value == Math.max(...totalData.map((o) => o.value))) {
          d.itemStyle = { color: "#64BD79" };
        }
      });

      setChartData(totalData);
    }
  }, [data]);

  return (
    <div className="flex relative px-4 pt-[22px] pb-4 w-[calc(65%-12px)] bg-white dark:bg-lightBlack rounded-xl drop-shadow-lg">
      <div className="flex flex-col w-2/5">
        <h3 className="text-16 font-bold dark:text-white">
          {t("successful_announcements_graphic")}
        </h3>
        <div className="h-full flex mt-4">
          <div className="flex flex-col min-w-[260px]">
            <div className="flex">
              {tabs.map((tab) => (
                <button
                  key={tab.id}
                  className={`text-gray mr-4 ${
                    selectedTab === tab.id
                      ? "underline font-semibold"
                      : "font-medium"
                  }`}
                  onClick={() => {
                    setSelectedTab(tab.id);
                    setParams(`?filter=${tab.key}`);
                  }}
                >
                  {t(tab.name)}
                </button>
              ))}
            </div>
            <ul className="space-y-4 mt-6 h-[186px] overflow-auto">
              {data && !isLoading ? (
                data.data.length > 0 &&
                data?.data.map((el, i) => (
                  <li
                    key={i}
                    className="max-w-[200px] flex justify-between gap-x-3 last:mb-0"
                  >
                    <p className="truncate dark:text-white">{el.full_name}</p>
                    <span className="font-bold dark:text-white">
                      {el.percent}%
                    </span>
                  </li>
                ))
              ) : (
                <div className="space-y-4">
                  {[...Array(5)].map((_, i) => (
                    <Shimmer className="h-6" key={i} />
                  ))}
                </div>
              )}
            </ul>
            <p className="text-16 font-bold mt-auto dark:text-white">
              Cəmi: {data?.count} uğurlu elan
            </p>
          </div>
        </div>
      </div>
      <div className="w-3/5">
        {chartData && (
          <Doughnut
            dataSummary={data?.data.reduce(
              (prev: any, current: any) => prev + current.count,
              0
            )}
            chartData={chartData}
          />
        )}
      </div>
    </div>
  );
};

export default SuccessfullAnnouncementsGraphics;
