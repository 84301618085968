import { Fragment, useEffect, useState } from "react";
import t from "src/hooks/useTranslate";
import Button from "src/components/Common/Inputs/Button";
import InputCalendar from "src/components/Common/Inputs/InputCalendar";
import Shimmer from "src/components/Common/Shimmer";
import Header from "src/components/Header/Header";
import { ddmmyyyy } from "src/helper/getFormattedDate";
import Dropdown from "src/components/Common/Dropdown";
import { Menu } from "@headlessui/react";
import { ReactComponent as UpArrowSVG } from "src/assets/vectors/up_arrow.svg";
import { ReactComponent as InfoSVG } from "src/assets/vectors/info.svg";
import { ReactComponent as ExcelSVG } from "src/assets/vectors/file_excel.svg";
import {
  useWorkingHoursReportKPIExcelMutation,
  useWorkingHoursReportKPIQuery,
} from "src/services/workingHoursKPIService";
import Pagination from "src/components/Common/Pagination";
import { useAppDispatch } from "src/hooks/redux";
import { successToast } from "src/store/Settings/toastStore";

const OperatorsWorkingHoursKPI = () => {
  const dispatch = useAppDispatch();
  const [value, setValue] = useState(ddmmyyyy(new Date()));
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [params, setParams] = useState(new URLSearchParams());
  const {
    data: workingHoursReportKPI,
    refetch,
    isFetching,
  } = useWorkingHoursReportKPIQuery(
    params.toString() ? "?" + params.toString() : ""
  );

  const [
    workingHoursReportKPIExcel,
    { isLoading: isExcelDownloading, isSuccess: isExcelDownloaded },
  ] = useWorkingHoursReportKPIExcelMutation();

  const onWorkingHoursReportKPIExcel = async () => {
    await workingHoursReportKPIExcel(
      params.toString() ? "?export=true&" + params.toString() : "?export=true"
    );
  };

  useEffect(() => {
    if (isExcelDownloaded) {
      dispatch(successToast("Excel uğurla yükləndi"));
    }
  }, [isExcelDownloaded]);

  useEffect(() => {
    refetch();
  }, [setPage, setPerPage]);

  return (
    <>
      <Header title={t("operator_working_hours_kpi")} />
      <div className="relative py-6 bg-white dark:bg-lightBlack rounded-xl drop-shadow-lg dark:text-white">
        <div className="flex items-center gap-x-4 px-4 pb-6">
          <p className="text-16 font-bold mr-auto">{t("view_working_hours")}</p>
          <div className="relative">
            <InputCalendar
              className="min-w-[260px] z-10"
              maxDetail={"decade"}
              maxDate={new Date()}
              yearView
              value={value}
              //@ts-ignore
              onChange={(e) => {
                let year = e.split(".")[2];
                setValue(e);
                workingHoursReportKPI && params.set("year", year);
              }}
            />
            {/* <p className="absolute inset-[1px] mr-14 flex items-center pl-4 font-medium text-16 rounded-l bg-white z-10 dark:bg-softBlack dark:text-white">
              {value.split(".")[2]}
            </p> */}
          </div>
          <Button
            className="w-48 items-center gap-x-2 bg-darkBlue dark:bg-blue text-light"
            onClick={onWorkingHoursReportKPIExcel}
            isLoading={isExcelDownloading}
          >
            <ExcelSVG /> {t("export_to_excel")}
          </Button>
        </div>
        <div className="relative min-h-[467px]">
          <table className="min-w-[991px] w-full">
            <thead>
              <tr className="[&>th]:text-left [&>th]:py-6 [&>th]:px-2 [&>th]:whitespace-nowrap border-y border-solid border-gray">
                <th className="w-auto !pl-4">№</th>
                <th className="w-auto">{t("user")}</th>
                <th className="w-auto">{t("january")}</th>
                <th className="w-auto">{t("february")}</th>
                <th className="w-auto">{t("march")}</th>
                <th className="w-auto">{t("april")}</th>
                <th className="w-auto">{t("may")}</th>
                <th className="w-auto">{t("june")}</th>
                <th className="w-auto">{t("july")}</th>
                <th className="w-auto">{t("august")}</th>
                <th className="w-auto">{t("september")}</th>
                <th className="w-auto">{t("october")}</th>
                <th className="w-auto">{t("november")}</th>
                <th className="w-auto">{t("december")}</th>
              </tr>
            </thead>
            <tbody>
              {workingHoursReportKPI?.data && !isFetching ? (
                workingHoursReportKPI?.data.length > 0 &&
                workingHoursReportKPI?.data.map((report) => (
                  <Fragment key={report.user_id}>
                    <tr className="relative [&>td]:py-4 [&>td]:px-2 after:absolute after:top-0 after:left-0 after:w-full after:h-[1px] after:bg-gray after:opacity-50">
                      <td className="!pl-4">{report.user_id}</td>
                      <td>{report.full_name}</td>
                      {report.months.map((month) => (
                        <td className="relative" key={month.id}>
                          <Dropdown
                            button={<p>{month.total_hour}</p>}
                            className="flex relative"
                          >
                            {month.weeks && (
                              <div className="absolute bottom-[calc(100%+10px)] -right-2 bg-white dark:bg-lightBlack px-4 py-2 rounded-xl drop-shadow border border-solid border-gray after:border-r after:border-b after:border-solid after:border-gray after:absolute after:right-4 after:-bottom-1.5 after:w-3 after:h-3 after:bg-white dark:after:bg-lightBlack after:rotate-45">
                                <Menu.Item
                                  as={"div"}
                                  className="flex gap-x-4"
                                  onClick={(e) => e.preventDefault()}
                                >
                                  <div className="space-y-3 whitespace-nowrap">
                                    {month.weeks.map((week) => (
                                      <p key={week.id}>
                                        {week.week_number} - {week.hour}
                                      </p>
                                    ))}
                                  </div>
                                  {month.direction === "up" && (
                                    <div className="flex flex-col items-center justify-center gap-y-2 whitespace-nowrap text-green">
                                      <UpArrowSVG className="w-8 h-8" />
                                      <p>{month.time_diff}</p>
                                    </div>
                                  )}
                                </Menu.Item>
                              </div>
                            )}
                          </Dropdown>
                        </td>
                      ))}
                    </tr>
                  </Fragment>
                ))
              ) : (
                <>
                  {[...Array(perPage)].map((_, i) => (
                    <tr className="[&>td]:py-4 [&>td]:px-2" key={i}>
                      {[...Array(14)].map((__, idx) => (
                        <td className="w-auto" key={idx}>
                          <Shimmer className="h-10.5" />
                        </td>
                      ))}
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </table>
          {workingHoursReportKPI?.data.length < 1 && !isFetching && (
            <div className="absolute inset-0 flex items-center justify-center">
              <div className="w-fit flex items-center gap-x-3 p-4 rounded-xl bg-light dark:bg-softBlack text-gray dark:text-white">
                <InfoSVG />
                <p>Göstəriləcək hər hansı bir məlumat yoxdur</p>
              </div>
            </div>
          )}
        </div>
        {workingHoursReportKPI?.meta.total > 5 && (
          <div className="w-full px-4 pt-4 border-t border-solid border-gray">
            <Pagination
              page={Math.ceil(workingHoursReportKPI?.meta.total / perPage)}
              total={workingHoursReportKPI?.meta.total}
              perPage={perPage}
              pageChange={(e) => {
                setPage(e + 1);
                params.set("page", (e + 1).toString());
              }}
              setPerPage={(val) => {
                setPerPage(val);
                params.set("per_page", val.toString());
              }}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default OperatorsWorkingHoursKPI;
