import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getCookie } from "src/helper/custom_cookies";
import { IProfile } from "src/types/i_profile";

export const timeOffService = createApi({
  reducerPath: "timeOffService",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: (headers) => {
      headers.set(
        "Authorization",
        getCookie("mcrmtkn") ? "Bearer " + getCookie("mcrmtkn") : ""
      );
      headers.set("Accept", "application/json");
      return headers;
    },
    // credentials: "include",
  }),
  tagTypes: ["TIMEOFF"],
  endpoints: (builder) => ({
    getAllTimeOffs: builder.query({
      query: (query) => {
       
        return {
          url: `/time_offs/all${query}`,
        };
      },
      providesTags: ["TIMEOFF"],
    }),

    profileUpdate: builder.mutation({
      query: (body: IProfile) => {
        return {
          url: "/profile/update_info",
          method: "post",
          body,
        };
      },
      invalidatesTags: ["TIMEOFF"],
    }),
    timeOffApprove: builder.mutation({
      query: ({ id, body }) => {
        return {
          url: `/time_offs/approve/${id}`,
          method: "post",
          body,
        };
      },
      invalidatesTags: ["TIMEOFF"],
    }),
    timeOffsExcel: builder.mutation({
      query: (query) => {
        return {
          url: `/time_offs/export${query}`,
          method: "get",
          responseHandler: async (response) => {
            window.location.assign(
              window.URL.createObjectURL(await response.blob())
            );
          },
        };
      },
    }),
  }),
});

export const {
  useGetAllTimeOffsQuery,
  useProfileUpdateMutation,
  useTimeOffApproveMutation,
  useTimeOffsExcelMutation,
} = timeOffService;
