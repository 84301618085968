import { useEffect, useState } from "react";
import Button from "src/components/Common/Inputs/Button";
import InputRangeDatePicker from "src/components/Common/Inputs/InputRangeDatePicker";
import Header from "src/components/Header/Header";
import AnnounceInformation from "src/components/MashinAl/AnnounceInformation";
import CommonInformation from "src/components/MashinAl/CommonInformation";
import ConfirmProcessingTable from "src/components/MashinAl/ConfirmProcessingTable";
import LateModeratorsTable from "src/components/MashinAl/LateModeratorsTable";
import MashinAlChat from "src/components/MashinAl/MashinAlChat";
import MashinAlStatistics from "src/components/MashinAl/MashinAlStatistics";
import ModeratorsTable from "src/components/MashinAl/ModeratorsTable";
import t from "src/hooks/useTranslate";
import {
  useMashinAlGetMessageByIdQuery,
  useMashinAlGetMessagesGroupsQuery,
  useMashinAlLateModeratorsQuery,
  useMashinAlModeratorsQuery,
  useMashinAlStatisticsQuery,
  useMashinAlWaitingAnnouncementsQuery,
  useMashinAlModerationAverageQuery
} from "src/services/mashinAlDashboardService";

const mashin_al_dashboard_categories = [
  {
    id: 1,
    name: "announcements",
  },
  {
    id: 2,
    name: "working_time",
  },
  {
    id: 3,
    name: "moderator",
  },
  {
    id: 4,
    name: "moderator_up_3min",
  },
  {
    id: 5,
    name: "waiting_for_confirm",
  },
  {
    id: 6,
    name: "messages",
  },
];

const MashinAlDashboard = () => {
  const [activeTab, setActiveTab] = useState(1);
  const [date, setDate] = useState("");
  const [moderatorParams, setModeratorParams] = useState("");
  const [lateModeratorParams, setLateModeratorParams] = useState("");

  const {
    data: announcementStatistics,
    refetch: announcementStatisticsRefetch,
    isLoading: isAnnouncementStatisticsLoading,
  } = useMashinAlStatisticsQuery(date ? `?date=${date}` : "", {
    skip: activeTab !== 1,
  });

  //  const {
  //    data: workingTime,
  //    refetch: announcementStatisticsRefetch,
  //    isLoading: isAnnouncementStatisticsLoading,
  //  } = useMashinAlModerationAverageQuery(date ? `?date=${date}` : "", {
  //    skip: activeTab !== 1,
  //  });

  const {
    data: moderators,
    refetch: moderatorsRefetch,
    isFetching: isModeratorsLoading,
  } = useMashinAlModeratorsQuery(moderatorParams, {
    skip: activeTab !== 3,
  });

  const {
    data: lateModerators,
    refetch: lateModeratorsRefetch,
    isFetching: isLateModeratorsLoading,
  } = useMashinAlLateModeratorsQuery(lateModeratorParams, {
    skip: activeTab !== 4,
  });

  const {
    data: waitingAnnouncements,
    refetch: waitingAnnouncementsRefetch,
    isFetching: isWaitingAnnouncementsLoading,
  } = useMashinAlWaitingAnnouncementsQuery(date ? `?date=${date}` : "", {
    skip: activeTab !== 5,
  });

  const {
    data: messagesGroups,
    refetch: messagesGroupsRefetch,
    isFetching: isMessagesGroupsLoading,
    isSuccess: isMessagesGroupsSuccess,
  } = useMashinAlGetMessagesGroupsQuery(date ? `?date=${date}` : "", {
    skip: activeTab !== 6,
  });

  const [messageId, setMessageId] = useState(0);

  const {
    data: messages,
    refetch: messagesRefetch,
    isFetching: isMessagesLoading,
  } = useMashinAlGetMessageByIdQuery(messageId, {
    skip: activeTab !== 6 || messageId === 0,
  });

  const onOpenMessages = (id) => {
    setMessageId(id);
  };

  // useEffect(() => {
  //   activeTab === 6 && messagesRefetch();
  // }, [activeTab]);

  useEffect(() => {
    if (isMessagesGroupsSuccess && messagesGroups.data.length > 0) {
      setMessageId(messagesGroups.data[0].id);
    }
  }, [messagesGroups]);

  // useEffect(() => {
  //   waitingAnnouncementsRefetch();
  // }, [date, waitingAnnouncementsRefetch]);

  const renderSwitch = () => {
    switch (activeTab) {
      case 1:
        return (
          <CommonInformation
            data={announcementStatistics}
            isLoading={isAnnouncementStatisticsLoading}
          />
        );
      case 2:
        return <MashinAlStatistics date={date}/>;
      case 3:
        return (
          <ModeratorsTable
            data={moderators}
            isLoading={isModeratorsLoading}
            setParams={setModeratorParams}
            date={date}
          />
        );
      case 4:
        return (
          <LateModeratorsTable
            data={lateModerators}
            isLoading={isLateModeratorsLoading}
            setParams={setLateModeratorParams}
            date={date}
          />
        );
      case 5:
        return (
          <ConfirmProcessingTable
            data={waitingAnnouncements}
            isLoading={isWaitingAnnouncementsLoading}
          />
        );
      case 6:
        return (
          <MashinAlChat
            groups={messagesGroups}
            onOpenMessages={onOpenMessages}
            messages={messages}
            groupsLoading={isMessagesGroupsLoading}
            messagesLoading={isMessagesLoading}
          />
        );
      default:
        return;
    }
  };


  return (
    <>
      <Header title={t("moderators")} />
      <div className="w-full relative bg-white dark:bg-lightBlack rounded-xl drop-shadow-lg z-10">
        <div className="flex items-end gap-x-4 p-4 pb-9">
          <div className="space-y-2 w-[210px] dark:text-white">
            {t("date")}
            <InputRangeDatePicker
              className="z-20"
              value={date}
              onChange={(e: string) => setDate(e)}
            />
          </div>
          {mashin_al_dashboard_categories.map((category) => (
            <Button
              className={`flex-1 whitespace-nowrap border border-solid ${
                activeTab == category.id
                  ? "bg-darkBlue border-darkBlue text-white dark:bg-blue dark:border-blue"
                  : "border-gray text-gray"
              }`}
              onClick={() => setActiveTab(category.id)}
              key={category.id}
            >
              {t(category.name)}
            </Button>
          ))}
        </div>
        {renderSwitch()}
      </div>
      {activeTab === 1 && (
        <div className="mt-6 space-y-6">
          {announcementStatistics?.dynamic.map((section) => (
            <AnnounceInformation key={section.id} data={section} />
          ))}
        </div>
      )}
    </>
  );
};

export default MashinAlDashboard;
