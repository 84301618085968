//@ts-nocheck
import React, { useState, useEffect } from "react";
import Button from "src/components/Common/Inputs/Button";
import Input from "src/components/Common/Inputs/Input";
import InputPhone from "src/components/Common/Inputs/InputPhone";
import { validatePhone } from "src/helper/validation";
import t from "src/hooks/useTranslate";
import { useGetUserInfoByPhoneMutation } from "src/services/financeService";
import {
  phoneRequiredPattern,
  requiredPattern,
} from "src/utils/validationPatterns";
import { errorToast, infoToast } from "src/store/Settings/toastStore";
import { useAppDispatch } from "src/hooks/redux";
import Select from "src/components/Common/Select";

type Props = {
  onSubmit: (query: string) => void;
};

const mashin_al_token = process.env.REACT_APP_MASHINAL_TOKEN;

const IndividualForm = ({ onSubmit }: Props) => {
  const [paramsData, setParamsData] = useState(new URLSearchParams());
  const [amount, setAmount] = useState(null);
  const [phone, setPhone] = useState(null);
  const [userName, setUserName] = useState("");
  const [comment, setComment] = useState("");
  const [requestedPhone, setRequestedPhone] = useState("");
  const [isAmountDisabled, setIsAmountDisabled] = useState(true);
  const [isSelectOptionsVisible, setIsSelectOptionsVisible] = useState(false);
  const [packageItem, setPackageItem] = useState({});
  const [duration, setDuration] = useState({
    id: 1,
    name: "1 aylıq",
    value: 1,
  });
  const [totalAmount, setTotalAmount] = useState("");
  const durationArray = [
    { id: 1, name: "1 aylıq", value: 1 },
    { id: 2, name: "2 aylıq", value: 2 },
    { id: 3, name: "3 aylıq", value: 3 },
  ];

  const dispatch = useAppDispatch();

  const [
    getUserInfoByPhone,
    {
      data: userData,
      isSuccess: userFound,
      isLoading: userFinding,
      isError: userNotFound,
      error: userFindError,
    },
  ] = useGetUserInfoByPhoneMutation();

 

  const isValid = () => {
    return (
      validatePhone(phone, true) &&
      (!comment || comment.length < 255) &&
      amount &&
      requestedPhone === phone
    );
  };

  const isValidUserMobile = () => {
    return validatePhone(phone, true);
  };

  useEffect(() => {
    if (userFound && userData?.autosalon_name) setIsSelectOptionsVisible(true);
  }, [userFound, userData?.autosalon_name]);

  const handleUser = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setRequestedPhone(phone);
    if (requestedPhone === phone) {
      dispatch(infoToast(t("user_found")));
      e.preventDefault();
    } else {
      setUserName("");

      const body_data = { phone: "994" + phone, token: mashin_al_token };
      await getUserInfoByPhone(body_data);
    }
  };

  useEffect(() => {
    if (userNotFound) {
      dispatch(errorToast(t("user_not_found")));
      setUserName(t("user_not_found"));
      setRequestedPhone("");
    } else {
      setUserName(
        userData?.user_name === null
          ? t("user_with_noname")
          : userData?.user_name
      );
    }
    if (userFound) setIsAmountDisabled(false);
  }, [userNotFound, userFound, userData?.user_name]);

  useEffect(() => {
    if (isSelectOptionsVisible) {
      paramsData.set("days_type", durationArray[0].id);
    }
  }, [isSelectOptionsVisible]);

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();

    if (isValid() || packageItem?.id) {
      onSubmit(paramsData.toString());
      resetInputs();
    }
  };

  useEffect(() => {
    if (!phone) {
      resetInputs();
    }
  }, [phone]);

  const resetInputs = () => {
    setAmount(null);
    setPhone(null);
    setComment("");
    setRequestedPhone("");
    setUserName("");
    setIsAmountDisabled(true);
    paramsData.delete("comment");
    paramsData.delete("days_type");
    setIsSelectOptionsVisible(false);
    setTotalAmount("");
    setPackageItem({});
    setDuration(durationArray[0]);
  };

  return (
    <div className="bg-white dark:bg-lightBlack rounded-xl drop-shadow-lg mb-8">
      <div className="flex items-center justify-between gap-x-4 p-4 border-b border-solid border-gray">
        <p className="text-16 font-bold dark:text-white">
          {t("mashinal_balance_increment")}
        </p>
      </div>
      <div className="flex gap-x-14">
        <form onSubmit={handleSubmit} className="w-[40%]">
          <div className="p-4 space-y-4">
            <div className="flex gap-x-5 w-full [&>*:first-child]:w-full">
              <InputPhone
                className="min-w-[260px] w-[100%]"
                validpattern={phoneRequiredPattern}
                label={t("phone") + "*"}
                //   value={form.phone ? form.phone : ""}
                onChange={(e) => {
                  paramsData.set("phone", "994" + e.toString());
                  setPhone(e);
                }}
                value={phone || ""}
                isClear={!phone}
                name="phone"
              />
              <Button
                className="w-[40%] bg-blue text-white disabled:bg-gray mt-auto min-w-[100px]"
                disabled={!isValidUserMobile()}
                onClick={handleUser}
                isLoading={userFinding}
              >
                {t("search")}
              </Button>
            </div>

            <div className=" dark:text-white">
              <p className="mb-2">{t("name")}</p>
              <div className="px-4  h-[42px] py-2 text-inherit border-solid rounded bg-gray/20 dark:bg-softBlack border-gray">
                {userName}
              </div>
            </div>
            {isSelectOptionsVisible && (
              <div>
                <Select
                  data={userData?.packages}
                  label={t("package_option")}
                  defaultTitle={t("package_option")}
                  defaultValue={"default"}
                  onChange={(e) => {
                    paramsData.set("package_id", e.id);

                    setPackageItem(e);
                    const total = Number(duration?.value) * Number(e.price);
                    setTotalAmount(total);
                  }}
                />
              </div>
            )}
            {isSelectOptionsVisible && (
              <div>
                <Select
                  data={durationArray}
                  label={t("duration_option")}
                  defaultTitle={duration.name}
                  defaultValue={duration.id}
                  onChange={(e) => {
                    setDuration(e);
                    paramsData.set("days_type", e.id || durationArray[0].id);
                    const total = e.value * Number(packageItem?.price);
                    setTotalAmount(total);
                  }}
                />
              </div>
            )}

            <div className="flex items-center gap-x-5">
              <Input
                type="number"
                className={[
                  "min-w-[260px] w-full",
                  isAmountDisabled
                    ? "opacity-30"
                    : isSelectOptionsVisible
                    ? "opacity-70 font-extrabold"
                    : "opacity-100",
                ].join(" ")}
                label={
                  isSelectOptionsVisible
                    ? t("total_amount") + " (AZN)"
                    : t("amount") + " (AZN)" + "*"
                }
                placeholder={
                  isSelectOptionsVisible ? t("total_amount") : t("amount")
                }
                maxLength={9}
                defaultValue={amount || totalAmount || ""}
                onChange={(e) => {
                  paramsData.set("amount", e.target.value || totalAmount);
                  setAmount(e.target.value);
                }}
                disabled={isAmountDisabled || isSelectOptionsVisible}
                name="amount"
              />
              <Button
                className="w-[40%] bg-green mt-auto text-white disabled:bg-gray"
                disabled={
                  isValid() && !isSelectOptionsVisible
                    ? false
                    : packageItem?.price
                    ? false
                    : true
                }
              >
                {t("add_balance")}
              </Button>
            </div>
            <div>
              <Input
                label={t("comment")}
                //   defaultValue={form.description}
                textarea={true}
                validpattern={requiredPattern}
                className="w-full h-[80px]"
                defaultValue={comment || ""}
                placeholder={t("comment")}
                onChange={(e) => {
                  const input = e.target.value;
                  if (input.length < 257) {
                    paramsData.set("comment", input);
                    setComment(input);
                  }
                }}
              />
              {comment.length > 255 ? (
                <span className="text-red">{t("text_limit")}</span>
              ) : (
                <span></span>
              )}

              <p className="text-gray">{comment.length}/256</p>
            </div>
          </div>
        </form>
        <div className="w-[60%]">
          <img src={"/assets/images/manual_payment_side.png"} alt="500" />
        </div>
      </div>
    </div>
  );
};

export default IndividualForm;
