import { ReactComponent as XSVG } from "src/assets/vectors/x.svg";
import Button from "src/components/Common/Inputs/Button";
import Input from "src/components/Common/Inputs/Input";
import { useAppDispatch } from "src/hooks/redux";
import t from "src/hooks/useTranslate";
import { setRoutesInputs } from "src/store/MashinalApp/mashinalAppStore";

type Props = {};

const MashinAlAppRoutesForm = ({
  title,
  setModal,
  form,
  onSubmit,
  isLoading,
}) => {
  const dispatch = useAppDispatch();

  return (
    <div className="min-w-[567px]">
      <div className="flex items-center justify-between gap-x-4 p-4 border-b border-solid border-gray">
        <p className="text-16 font-bold dark:text-white">{title}</p>
        <XSVG
          className="text-gray cursor-pointer dark:text-white"
          onClick={() => setModal(false)}
        />
      </div>
      <div className="p-4 dark:text-white">
        <form className="flex flex-col gap-y-4" onSubmit={onSubmit}>
          <Input
            label={t("name")}
            placeholder="Linkin labelini daxil edin"
            defaultValue={form.name}
            onChange={(e) =>
              dispatch(setRoutesInputs({ key: "name", value: e.target.value }))
            }
          />
          <Input
            label={t("link")}
            placeholder="/..."
            defaultValue={form.route}
            onChange={(e) =>
              dispatch(setRoutesInputs({ key: "route", value: e.target.value }))
            }
          />
          <Input
            label={t("Payload")}
            textarea
            defaultValue={form.payload}
            validpattern={
              /^(?:\"[a-z0-9\_]+\":{1}(\"?[a-z0-9\_]+\"?)|(\{.+\})[,\n\}]?)?$/
            }
            className="min-h-[267px] !h-full"
            onChange={(e) =>
              dispatch(
                setRoutesInputs({ key: "payload", value: e.target.value })
              )
            }
          />
          <Button className="bg-blue text-white" isLoading={isLoading}>
            {t("submit")}
          </Button>
        </form>
      </div>
    </div>
  );
};

export default MashinAlAppRoutesForm;
