import { FC, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import {
  setVisitedGroupStep,
} from "src/store/Groups/groupsStore";
import Permissions from "src/components/Common/Permissions";
import { EGroupsCrudSteps } from "src/enums/e_groups_crud_steps";

const GroupsPermissions: FC<{
  isLoading: boolean;
  onSubmit: (allSelected: number[]) => void;
}> = ({ onSubmit, isLoading }: any) => {
  const dispatch = useAppDispatch();
  

  useEffect(() => {
    dispatch(setVisitedGroupStep(EGroupsCrudSteps.permissions));
  }, []);

  return (
    <Permissions
      isLoading={isLoading}
      onSubmit={(allSelected: number[]) => onSubmit(allSelected)}
    />
  );
};

export default GroupsPermissions;
