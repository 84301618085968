import React, { useEffect, useState } from "react";
import Button from "../Common/Inputs/Button";
import t from "src/hooks/useTranslate";
import { ReactComponent as BookmarkIcon } from "src/assets/vectors/bookmark.svg";
import { ReactComponent as ViewIcon } from "src/assets/vectors/view_icon.svg";

const SelectRingo = ({ car, bodyOptionsValue }) => {
  const [fuel, setFuel] = useState([]);
  const [gearingD, setGearingD] = useState([]);

  useEffect(() => {
    if (car?.fuel) {
      const fuelName = bodyOptionsValue["default_options"]?.[
        "tip-dvigatelya"
      ]?.values?.filter((item) => {
        if (item?.key === car?.fuel) {
          return item?.name;
        }
      });
      setFuel(fuelName);
    }

    if (car.gear) {
      const gearing = bodyOptionsValue["default_options"][
        "privod"
      ]?.values?.filter((item) => {
        if (item.key === car.gear) {
          return item.name;
        }
      });

      setGearingD(gearing);
    }
  }, [bodyOptionsValue, car]);



  return (
    <div className="flex justify-between w-full p-6">
      <div className="flex gap-x-5 w-[40%]">
        <div className="rounded-md w-[calc(100%-200px)]">
          <img
            src={car.image || "/assets/images/manual_payment_side.png"}
            alt="500"
            className="rounded-md"
          />
        </div>
        <div className="w-[200px] flex flex-col gap-y-2 text-black dark:text-white">
          <span className="font-semibold">{car?.price ?? "N/A"}</span>
          <span>{car?.breadcrumb?.model ?? "N/A"}</span>
          <span>{t(`${gearingD?.[0]?.name ?? "N/A"}`)}</span>
          <span className="first-letter:uppercase">
            {fuel?.[0]?.name ?? "N/A"}
          </span>
          <Button className={"bg-blue mt-3 text-white"}>
            <a href={car?.link ?? ""} target="_blank" rel="noreferrer">
              {t("detailed")}
            </a>
          </Button>
        </div>
      </div>
      <div className="flex gap-x-4 dark:text-white">
        <div>{car?.created_at ?? "N/A"}</div>
        <div className="flex gap-x-1">
          <span className="mt-0.5">
            <BookmarkIcon />
          </span>
          <span>{car?.favorites_count ?? "N/A"}</span>
        </div>
        <div className="flex gap-x-1">
          <span className="mt-0.5">
            <ViewIcon />
          </span>
          <span> {car?.view_count ?? "N/A"}</span>
        </div>
      </div>
    </div>
  );
};

export default SelectRingo;
