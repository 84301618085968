import { RadioGroup } from "@headlessui/react";
import { useState, useEffect, useMemo } from "react";
import t from "src/hooks/useTranslate";
import Doughnut from "../Common/Charts/Doughnut";
import user_call_average from "src/data/user_call_average.json";
import { ETopPopularForSaleCategory } from "src/enums/e_top_popular_for_sale_category";
import Shimmer from "../Common/Shimmer";

const TopPopularForSale = ({ data, isLoading, setParams }) => {
  const [params, setParamsData] = useState(new URLSearchParams());
  const [selectedTab, setSelectedTab] = useState(1);
  const [category, setCategory] = useState(ETopPopularForSaleCategory.colors);
  const [chartData, setChartData] = useState([]);
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(0);

  const tabs = [
    {
      id: 1,
      key: "quarter",
      name: "quarter",
    },
    {
      id: 2,
      key: "half_year",
      name: "half_year",
    },
    {
      id: 3,
      key: "yearly",
      name: "yearly",
    },
  ];

  const radioData = [
    { id: 1, key: "color", value: ETopPopularForSaleCategory.colors },
    { id: 2, key: "box", value: ETopPopularForSaleCategory.gearbox },
    { id: 3, key: "engine_type", value: ETopPopularForSaleCategory.engineType },
    { id: 4, key: "capacity", value: ETopPopularForSaleCategory.engineVolumes },
    {
      id: 5,
      key: "body_type",
      value: ETopPopularForSaleCategory.bodyType,
    },
    {
      id: 6,
      key: "averageMileage",
      value:
        ETopPopularForSaleCategory.averageMileage +
        `(${new Date(
          new Date().setFullYear(new Date().getFullYear() - 10)
        ).getFullYear()}-${new Date().getFullYear()})`,
    },
  ];

  useMemo(() => {
    if (data && data.data?.length > 0) {
      setChartData([]);
      let totalData = [];
      for (let i = 0; i < data.data?.length; i++) {
        totalData.push({
          value: data.data[i].count,
          name: data.data[i].name,
        });
      }
      totalData.forEach((d) => {
        if (d.value == Math.max(...totalData.map((o) => o.value))) {
          d.itemStyle = { color: "#64BD79" };
        }
      });

      setChartData(totalData.slice(0, 6));
    }
  }, [data]);

  useEffect(() => {
    if (data && data.data.length > 0) {
      let arr = [];
      for (let i = 0; i < data.data.length; i++) {
        arr.push(data.data[i].count);
      }
      setMax(Math.max(...arr));
      setMin(Math.min(...arr));
    }
  }, [data]);

  useEffect(() => {
    setParams("?" + params.toString());
  }, [selectedTab, category]);

  return (
    <div className="flex flex-col relative h-[576px] px-4 pt-[22px] pb-4 w-full  bg-white dark:bg-lightBlack rounded-xl drop-shadow-lg">
      <div className="flex gap-x-4">
        <h3 className="text-16 font-bold dark:text-white">
          {t("popular_cars_for_sale")}
        </h3>
        <RadioGroup
          value={category}
          onChange={(e) => {
            params.set("type", radioData.find((rd) => rd.value == e).key);
            setCategory(e);
          }}
          className="flex items-center flex-wrap gap-4 ml-auto"
        >
          {radioData.map((option) => (
            <RadioGroup.Option value={option.value} key={option.id}>
              {({ checked }) => (
                <div className="flex items-center gap-x-2 cursor-pointer whitespace-nowrap">
                  <div
                    className={`relative w-4 h-4 border-2 border-solid rounded-full bg-transparent after:absolute after:left-1/2 after:top-1/2 after:-translate-y-1/2 after:-translate-x-1/2 after:w-2 after:h-2 after:rounded-full ${
                      checked
                        ? "border-green after:bg-green"
                        : "border-darkBlue dark:border-white after:bg-transparent"
                    }`}
                  ></div>
                  <span
                    className={
                      checked ? "text-green" : "text-darkBlue dark:text-white"
                    }
                  >
                    {option.value}
                  </span>
                </div>
              )}
            </RadioGroup.Option>
          ))}
        </RadioGroup>
      </div>
      <div className="flex-1 flex justify-between gap-x-4 w-full mt-4">
        <div className="flex-1">
          {tabs.map((tab) => (
            <button
              key={tab.id}
              className={`text-gray mr-4 ${
                selectedTab === tab.id
                  ? "underline font-semibold"
                  : "font-medium"
              }`}
              onClick={() => {
                params.set("filter", tab.key);
                setSelectedTab(tab.id);
              }}
            >
              {t(tab.name)}
            </button>
          ))}
          <ul className="h-96 overflow-auto pr-3 mt-8 space-y-4">
            {data && !isLoading
              ? data.data?.map((type) => (
                  <li key={type.id} className="h-6 flex items-center gap-x-6">
                    <p className="whitespace-nowrap min-w-[128px] truncate dark:text-white">
                      {type.name}
                    </p>
                    <div className="relative h-1 w-full bg-light rounded-full overflow-hidden">
                      <div
                        className="absolute top-0 left-0 h-full bg-red"
                        style={{
                          width:
                            min == max
                              ? "100%"
                              : type.count == min
                              ? "0"
                              : ((type.count - min) * 100) / (max - min) + "%",
                        }}
                      ></div>
                    </div>
                    <p className="ml-auto min-w-[128px] text-16 font-bold dark:text-white">
                      {`${type.count} / ${(
                        (type.count * 100) /
                        data?.data.reduce(
                          (prev: any, current: any) => prev + current.count,
                          0
                        )
                      ).toFixed(2)}%`}
                    </p>
                  </li>
                ))
              : [...Array(10)].map((_, i) => (
                  <Shimmer className="h-6" key={i} />
                ))}
          </ul>
        </div>
        <div className="flex-1">
          <Doughnut
            dataSummary={data?.data.reduce(
              (prev: any, current: any) => prev + current.count,
              0
            )}
            chartData={chartData}
          />
        </div>
      </div>
    </div>
  );
};

export default TopPopularForSale;
