import { useEffect } from "react";

const useEventListener = <T extends HTMLElement, K extends keyof HTMLElementEventMap>(
    target: any,
    type: K,
    listener: (this: T, ev: HTMLElementEventMap[K]) => any,
    ...options: (boolean | AddEventListenerOptions)[]
) => {
    useEffect(() => {
        target.addEventListener(type, listener, ...options);

        return () => target.removeEventListener(type, listener, ...options);
    }, [target, type, listener, options]);
};

export default useEventListener;