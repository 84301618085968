import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { getCookie } from "src/helper/custom_cookies"

export const notificationService = createApi({
    reducerPath: "notificationService",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_BASE_URL,
        prepareHeaders: (headers) => {
            localStorage.setItem("ssnxprd", "180")
            headers.set("Authorization", getCookie("mcrmtkn") ? "Bearer " + getCookie("mcrmtkn") : "")
            headers.set('Accept', 'application/json')
            return headers
        },
        // credentials: "include",
    }),
    tagTypes: ["Notifications"],
    endpoints: (builder) => ({
        notifications: builder.query({
            query: (query) => {
                return {
                    url: `/notifications${query}`,
                }
            },
            providesTags: ["Notifications"]
        }),
        markAsRead: builder.mutation({
            query: (body) => {
                return {
                    url: "/notifications/read",
                    method: "post",
                    body,
                }
            },
            invalidatesTags: ["Notifications"]
        }),
        notificationDetail: builder.query({
            query: (notificationId) => {
                return {
                    url: `/notifications/${notificationId}/detail`,
                }
            },
            providesTags: ["Notifications"]
        }),

    })
})

export const { useNotificationsQuery, useMarkAsReadMutation, useNotificationDetailQuery } = notificationService