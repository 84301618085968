import { useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Button from "src/components/Common/Inputs/Button";
import Header from "src/components/Header/Header";
import OperatorWorkScheduleInner from "src/components/Operators/OperatorWorkScheduleInner";
import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import t from "src/hooks/useTranslate";
import { useUserQuery } from "src/services/authService";
import { useUpdateOperatorWorkingHoursMutation } from "src/services/operatorsService";
import { useGetUserByIdQuery } from "src/services/usersService";
import { successToast, errorToast } from "src/store/Settings/toastStore";

const OperatorWorkSchedule = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const operator = useAppSelector((state) => state.operators.operator);
  const {
    data: profile,
    refetch,
    isFetching,
  } = useGetUserByIdQuery(searchParams.get("id"), {
    skip: !searchParams.get("id"),
  });
  const [
    updateWorkingHours,
    {
      isLoading: isWorkingHoursUpdating,
      isSuccess: isWorkingHoursUpdated,
      error: workingHoursError,
      isError: isWorkingHoursNotUpdated,
    },
  ] = useUpdateOperatorWorkingHoursMutation();

  const onUpdateWorkHours = async () => {
    let isValid = true;
    operator.working_hours.forEach((hours) => {
      if (hours.length && (!hours[0] || !hours[1])) {
        isValid = false;
      }
    });
    if (isValid) {
      await updateWorkingHours({
        id: operator.id,
        body: {
          working_hours: operator.working_hours,
          is_hidden: operator.is_hidden,
          status: operator.status,
        },
      });
    } else {
      dispatch(errorToast("Bütün iş saatlarını doldurun"));
    }
  };

  useEffect(() => {
    if (isWorkingHoursUpdated) {
      dispatch(successToast("İş saatları uğurla dəyişdirildi"));
      navigate("/operators");
    }
  }, [isWorkingHoursUpdated]);

  useEffect(() => {
    if (isWorkingHoursNotUpdated) {
      //@ts-ignore
      dispatch(errorToast(workingHoursError?.data.message));
    }
  }, [isWorkingHoursNotUpdated]);

  useEffect(() => {
    if (searchParams.get("id")) {
      refetch();
    }
  }, [searchParams.get("id")]);

  return (
    <>
      <Header title={t("operators")} />
      <OperatorWorkScheduleInner
        operator={operator}
        onSubmit={onUpdateWorkHours}
        isLoading={isWorkingHoursUpdating}
        isDefaultOperatorFetching={isFetching}
        defaultOperator={profile ?? null}
      />
    </>
  );
};

export default OperatorWorkSchedule;
