import { useState } from "react";
import t from "src/hooks/useTranslate";
import Shimmer from "../Common/Shimmer";

const AnnouncementsByCity = ({ data, isLoading, setParams }) => {
  const [selectedTab, setSelectedTab] = useState(1);

  const tabs = [
    {
      id: 1,
      key: "month",
      name: "monthly",
    },
    {
      id: 2,
      key: "quarter",
      name: "quarter",
    },
    {
      id: 3,
      key: "year",
      name: "yearly",
    },
  ];

  return (
    <div className="flex flex-col relative h-[320px] px-4 py-6 w-[calc(70%-12px)] bg-white dark:bg-lightBlack rounded-xl drop-shadow-lg">
      <div className="flex items-center justify-between mb-4">
        <h3 className="text-16 font-bold dark:text-white">
          {t("announcements_by_city_title")}
        </h3>
        <div className="flex gap-x-4">
          {tabs.map((tab) => (
            <button
              key={tab.id}
              className={`text-darkSnow ${
                selectedTab === tab.id
                  ? "underline font-semibold"
                  : "font-medium"
              }`}
              onClick={() => {
                setSelectedTab(tab.id);
                setParams(`?filter=${tab.key}`);
              }}
            >
              {t(tab.name)}
            </button>
          ))}
        </div>
      </div>
      <div className="flex flex-col flex-wrap gap-y-2 gap-x-4 h-full overflow-x-auto">
        {data && !isLoading
          ? data?.map((city, i) => (
              <div className="flex items-center" key={city.code}>
                <p className="w-32 dark:text-white">
                  <span className="inline-block w-5">{i + 1}.</span> {city.name}{" "}
                  ({city.code})
                </p>
                <p className="text-22 text-green font-bold mr-16">
                  {city.count}
                </p>
              </div>
            ))
          : [...Array(20)].map((_, i) => <Shimmer className="h-7" key={i} />)}
      </div>
    </div>
  );
};

export default AnnouncementsByCity;
