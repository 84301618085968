import React, { FC, useState, useEffect, ChangeEvent, useRef } from "react";
import { IInput } from "src/types/Common/i_input";
import { ReactComponent as CalendarSVG } from "src/assets/vectors/calendar.svg";
import Popover from "../Popover";
import { Calendar } from "react-calendar";
import t from "src/hooks/useTranslate";
import InputMask from "react-input-mask";
import { ddmmyyyy } from "src/helper/getFormattedDate";
import months from "src/data/months.json";
import weekdays from "src/data/weekdays_data.json";

const InputRangeDatePicker: FC<any> = ({
  label,
  className,
  defaultValue = null,
  maxDate,
  minDate,
  rangeStartDate,
  rangeEndDate,
  onChange,
  children,
  ...inputProps
}) => {
  const [date, setDate] = useState<any>();
  const [value, setValue] = useState<any>("");
  const [dirty, setDirty] = useState<any>(false);
  const ref = React.createRef<HTMLButtonElement>();
  const input = useRef<any>();

  useEffect(() => {
    if (rangeEndDate || rangeStartDate) {
      setDate([rangeStartDate, rangeEndDate]);
    }
  }, [rangeEndDate, rangeStartDate]);

  useEffect(() => {
    if (date) {
      setValue(ddmmyyyy(date[0]) + "-" + ddmmyyyy(date[1]));
      // ref.current!.click();
    }
  }, [date]);

  useEffect(() => {
    if ((value && !value.includes("_")) || (value == "" && dirty)) {
      onChange?.(value);
    }
  }, [value]);

  useEffect(() => {
    if (defaultValue || defaultValue == "") {
      setValue(defaultValue);
    }
    if (defaultValue == "") {
      setDate("");
    }
  }, [defaultValue]);

  const maskChars = {
    "#": "[0-9]",
  };

  const tileContent = ({ date, view }) => {
    if (view === "year") {
      const monthName = months.map((month) => t(month.name))[date.getMonth()];
      return <span className="text-gray-500">{monthName}</span>;
    }
  };
  return (
    <div>
      {label && <p className="mb-2">{label}</p>}
      <div
        className={[
          "range_date_picker relative flex px-4 border h-10.5 border-solid border-gray rounded bg-white dark:bg-softBlack",
          className,
        ].join(" ")}
      >
        <InputMask
          {...inputProps}
          mask={"##.##.####-##.##.####"}
          className="w-full bg-white dark:bg-softBlack dark:text-white"
          //@ts-ignore
          maskChar="_"
           //@ts-ignore
          formatChars={maskChars}
          placeholder="__.__.____ - __.__.____"
          ref={input}
          value={value}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setValue(e.target.value);
            e.target.value !== "__.__.____-__.__.____" && setDirty(true);
          }}
        />
        <Popover
          className="absolute top-[calc(100%+16px)] -right-4 bg-white px-4 py-5 rounded-xl drop-shadow after:absolute after:right-4 after:-top-1.5 after:w-3 after:h-3 after:bg-white after:rotate-45 "
          ref={ref}
          button={<CalendarSVG className="dark:text-white" />}
        >
          <Calendar
            onChange={(e) => {
              setDate(e);
            }}
            selectRange={true}
            value={date}
            navigationLabel={({ date, label, view }) => {
              if (view === "month") {
                const monthName = months.map((month) => t(month.name))[
                  date.getMonth()
                ];
                return monthName;
              } else {
                return label;
              }
            }}
            formatShortWeekday={(locale, date) => {
              const weekday =
                date.getDay() == 0
                  ? t(weekdays[6].name)
                  : t(weekdays[date.getDay() - 1].name);
              return weekday.slice(0, 3);
            }}
            locale="az"
            tileContent={tileContent}
          />
        </Popover>
      </div>
    </div>
  );
};

export default InputRangeDatePicker;
