export const emailRequiredPattern =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const emailPattern =
  /^(?:(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})))?$/;
export const namePattern = /^(?:[A-Za-z ,.'-ıəçşüöğ]{3,32})?$/;
export const nameRequiredPattern = /^[A-Za-z ,.'-ıəçşüöğ]{3,32}$/i;
export const passwordPattern = /^(?:.{8,32})?$/;
export const passwordRequiredPattern = /^.{8,32}$/;
export const requiredPattern = /^.{1,}$/;
export const phonePattern = /^(?:[0-9]{9})?$/;
export const phoneRequiredPattern = /^[0-9]{9}$/;
export const carNumberPattern = /^(?:([0-9]{2}[A-Za-z]{2}[0-9]{3}))?$/;
export const carAndMotoNumberPattern = /^(?:([0-9]{2}[A-Za-z]{1,2}[0-9]{3}))?$/;
export const carNumberRequiredPattern = /^([0-9]{2}[A-Za-z]{2}[0-9]{3})$/;
