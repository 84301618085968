import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { getCookie } from "src/helper/custom_cookies"

const getToken = () => getCookie('mcrmtkn')

export const authService = createApi({
    reducerPath: "authService",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_BASE_URL,
        prepareHeaders: (headers) => {
            const token = getToken()
            if (token) {
                headers.set("Authorization", "Bearer " + token)
            }
            headers.set('Accept', 'application/json')
            return headers
        },
        // credentials: "include",
    }),
    tagTypes: ["Auth"],
    endpoints: (builder) => ({
        loginUser: builder.mutation({
            query: (body: { email: string, password: string }) => {
                return {
                    url: "/login",
                    method: "post",
                    body,
                }
            },
        }),
        logoutUser: builder.mutation({
            query: () => {
                return {
                    url: "/logout",
                    method: "post",
                }
            },
        }),
        user: builder.query({
            query: (tkn = getCookie("mcrmtkn")) => {
                return {
                    url: "/me",
                    headers: {Authorization: "Bearer " + tkn}
                }
                
            },
            providesTags: ["Auth"]
        })
    })
})

export const { useLoginUserMutation, useLogoutUserMutation, useUserQuery } = authService