import t from "src/hooks/useTranslate";
import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import {
  nameRequiredPattern,
  passwordPattern,
  passwordRequiredPattern,
  phonePattern,
} from "src/utils/validationPatterns";
import Avatar from "../Common/Avatar";
import Input from "../Common/Inputs/Input";
import InputCalendar from "../Common/Inputs/InputCalendar";
import Select from "../Common/Select";
import genderData from "src/data/gender_data.json";
import Button from "../Common/Inputs/Button";
import Accordion from "../Common/Accordion";
import { ReactComponent as UpChevronSVG } from "src/assets/vectors/up_chevron.svg";
import InputPassword from "../Common/Inputs/InputPassword";
import { ddmmyyyy } from "src/helper/getFormattedDate";
import {
  setAllInputs,
  setAvatar,
  setSelectInput,
} from "src/store/Profile/profileStore";
import { successToast } from "src/store/Settings/toastStore";
import { useEffect, useState } from "react";
import InputPhone from "../Common/Inputs/InputPhone";
import { validateName } from "src/helper/validation";

const ProfileInfo = ({ isLoading, isUpdated, onSubmit }: any) => {
  const dispatch = useAppDispatch();
  const profile = useAppSelector((state) => state.profile.user);
  const [repeatPasswordValidation, setRepeatPasswordValidation] =
    useState(false);
  const [validForm, setValidForm] = useState(false);

  useEffect(() => {
    profile.password === profile.password_confirmation
      ? setRepeatPasswordValidation(false)
      : setRepeatPasswordValidation(true);
  }, [profile.password_confirmation]);

  useEffect(() => {
    if (
      validateName(profile.name, true) &&
      validateName(profile.lastname, true) &&
      profile.gender != 0 &&
      ((profile.password &&
        profile.password_confirmation &&
        profile.password === profile.password_confirmation &&
        profile.old_password) ||
        (!profile.old_password &&
          !profile.password &&
          !profile.password_confirmation))
    ) {
      setValidForm(true);
    } else {
      setValidForm(false);
    }
  }, [profile]);

  return (
    <form onSubmit={onSubmit}>
      <div className="flex gap-x-6">
        <Avatar
          imageUrl={profile.avatar ? profile.avatar : ""}
          setImage={(e: string) => dispatch(setAvatar(e))}
        />
        <div className="w-1/3 space-y-4">
          <Input
            className="min-w-[260px]"
            label={t("name") + "*"}
            placeholder={t("name_placeholder")}
            validpattern={nameRequiredPattern}
            defaultValue={profile.name}
            onChange={(e) =>
              dispatch(
                setAllInputs({
                  key: "user",
                  name: e.target.name,
                  value: e.target.value,
                })
              )
            }
            name="name"
          />
          <InputCalendar
            className="relative min-w-[260px] text-gray z-20 pointer-events-none after:absolute after:inset-0 after:bg-gray dark:after:bg-black after:opacity-50"
            label={t("birthday")}
            value={profile.birthday}
          />
        </div>
        <div className="w-1/3 space-y-4">
          <Input
            className="min-w-[260px]"
            label={t("lastname") + "*"}
            placeholder={t("lastname_placeholder")}
            validpattern={nameRequiredPattern}
            defaultValue={profile.lastname}
            onChange={(e) =>
              dispatch(
                setAllInputs({
                  key: "user",
                  name: e.target.name,
                  value: e.target.value,
                })
              )
            }
            name="lastname"
          />
          <InputPhone
            className="relative min-w-[260px] text-gray z-20 pointer-events-none after:absolute after:inset-0 after:bg-gray dark:after:bg-black after:opacity-50"
            validpattern={phonePattern}
            value={profile.phone}
            label={t("phone") + "*"}
            name="phone"
          />
        </div>
        <div className="w-1/3 space-y-4">
          <Select
            data={genderData}
            label={t("gender") + "*"}
            defaultTitle="Cinsi seç"
            defaultValue={profile.gender}
            onChange={(e) =>
              dispatch(
                setSelectInput({ key: "user", name: "gender", value: e.id })
              )
            }
          />
          <Button
            className="w-full text-white font-medium !mt-[46px] bg-blue disabled:bg-gray/50"
            isLoading={isLoading}
            disabled={!validForm}
          >
            {t("remember")}
          </Button>
        </div>
      </div>
      <div className="w-[calc(100%-136px)] mt-6 ml-auto">
        <Accordion
          buttonClassName="w-fit"
          accordionButton={
            <div className="w-fit h-10.5 flex items-center bg-gray gap-x-3 px-4 text-white rounded">
              <p>{t("change_password")}</p>
              <UpChevronSVG className="w-3 mr-auto duration-300 ui-not-open:rotate-180" />
            </div>
          }
        >
          <div className="w-full flex gap-x-4 mt-4">
            <InputPassword
              className="min-w-[260px]"
              label={t("recent_password") + "*"}
              placeholder={t("password_placeholder")}
              validpattern={passwordPattern}
              onChange={(e) =>
                dispatch(
                  setAllInputs({
                    key: "user",
                    name: e.target.name,
                    value: e.target.value,
                  })
                )
              }
              name="old_password"
            />
            <InputPassword
              className="min-w-[260px]"
              label={t("new_password") + "*"}
              placeholder={t("password_placeholder")}
              validpattern={passwordPattern}
              onChange={(e) =>
                dispatch(
                  setAllInputs({
                    key: "user",
                    name: e.target.name,
                    value: e.target.value,
                  })
                )
              }
              name="password"
            />
            <InputPassword
              className="min-w-[260px]"
              label={t("repeat_new_password") + "*"}
              placeholder={t("password_placeholder")}
              validpattern={repeatPasswordValidation}
              onChange={(e) =>
                dispatch(
                  setAllInputs({
                    key: "user",
                    name: e.target.name,
                    value: e.target.value,
                  })
                )
              }
              name="password_confirmation"
            />
          </div>
        </Accordion>
      </div>
    </form>
  );
};

export default ProfileInfo;
