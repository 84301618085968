import { ChangeEvent, FC, Fragment, useEffect, useState } from "react";
import { Combobox, Transition } from "@headlessui/react";
import { ISearch } from "src/types/Common/i_search";
import { IBaseSelect } from "src/types/Common/i_base_select";
import t from "src/hooks/useTranslate";
import { useDebounceEffect } from "src/hooks/useDebounceEffect";
import { ReactComponent as SearchSVG } from "src/assets/vectors/search.svg";
import { Link } from "react-router-dom";
import Shimmer from "../Shimmer";

//TODO: Loading

const InputSearch: FC<ISearch> = ({
  data,
  className,
  placeholder,
  showItemCount,
  isLoading,
  children,
  label,
  defaultValue = null,
  optionHidden = false,
  onChange,
  onInput,
}) => {
  const [selected, setSelected] = useState<{ id: number; name: string }>({
    id: 0,
    name: "",
  });
  const [query, setQuery] = useState("");
  const [value, setValue] = useState("");
  const [dirty, setDirty] = useState(false);
  const [isActive, setIsActive] = useState(false);

  // const filteredData =
  //   query === ""
  //     ? data
  //     : data?.filter((obj: IBaseSelect) =>
  //         obj.name
  //           .toLowerCase()
  //           .replace(/\s+/g, "")
  //           .includes(query.toLowerCase().replace(/\s+/g, ""))
  //       );

  const changeHandler = (e: IBaseSelect) => {
    setSelected(e);
    onChange?.(e);
  };

  useDebounceEffect(() => dirty && onInput(value), 500, [query, value]);

  const inputHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setQuery(e.currentTarget.value);
    setValue(e.currentTarget.value);
    setSelected({ id: 0, name: e.currentTarget.value });
    setDirty(true);
  };

  useEffect(() => {
    if (defaultValue) {
      setSelected({ id: 0, name: defaultValue });
    }
  }, [defaultValue]);

  return (
    <div className={["w-full flex", className].join(" ")}>
      {label && <p className="mb-2 dark:text-white">{label}</p>}
      <Combobox value={selected} onChange={changeHandler}>
        <div className="relative w-full">
          <div className="relative h-10.5 px-3 border border-gray flex gap-x-2 items-center cursor-default overflow-hidden rounded bg-white dark:bg-softBlack text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
            <Combobox.Input
              className="w-full border-none text-sm leading-5 text-darkBlue bg-white dark:bg-softBlack dark:text-white"
              displayValue={(search: IBaseSelect) =>
                `${search.name}${
                  search["lastname"] ? " " + search["lastname"] : ""
                }`
              }
              onChange={inputHandler}
              placeholder={placeholder}
            />
            <Combobox.Button className="flex items-center">
              <SearchSVG className="text-gray dark:text-white" />
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            // afterLeave={() => setQuery("")}
          >
            <Combobox.Options
              className={`absolute
                ${
                  optionHidden ? "hidden" : "false"
                } right-0 mt-1 w-full overflow-auto z-20 rounded-md bg-white dark:bg-softBlack py-4 px-4 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm border border-solid border-gray ${
                data?.length > 4 ? "h-[245px]" : "h-auto"
              }`}
            >
              <>
                {data && !isLoading ? (
                  data?.length > 0 ? (
                    data?.slice(0, showItemCount).map((obj: any) => (
                      <Combobox.Option
                        key={obj.id}
                        className={({ active }) =>
                          `relative h-10.5 cursor-pointer flex items-center select-none p-2 rounded hover:bg-light dark:hover:bg-lightBlack dark:text-white ${
                            active ? "bg-light dark:bg-lightBlack" : ""
                          }`
                        }
                        value={obj}
                      >
                        {({ selected, active }) => {
                          return (
                            <>
                              {obj.name} {obj.lastname || ""}
                              {/* {children} */}
                              {selected ? (
                                <span
                                  className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                    active ? "" : ""
                                  }`}
                                >
                                  <p>ok</p>
                                </span>
                              ) : null}
                            </>
                          );
                        }}
                      </Combobox.Option>
                    ))
                  ) : (
                    <div className="relative h-auto cursor-default select-none py-2 px-4 text-gray dark:text-white">
                      {t("nothing_found")}
                    </div>
                  )
                ) : (
                  <div className="space-y-4">
                    <Shimmer className="w-full h-10.5" />
                    <Shimmer className="w-full h-10.5" />
                    <Shimmer className="w-full h-10.5" />
                  </div>
                )}
              </>
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </div>
  );
};

export default InputSearch;
