import { Fragment } from "react";
import { Menu } from "@headlessui/react";
import { Link } from "react-router-dom";
import Dropdown from "../Common/Dropdown";
import { ReactComponent as MailSVG } from "src/assets/vectors/mail.svg";
import { ReactComponent as DoubleCheckSVG } from "src/assets/vectors/double_check.svg";
import { ReactComponent as InfoSVG } from "src/assets/vectors/info.svg";
import t from "src/hooks/useTranslate";
import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import { ENotificationTypes } from "src/enums/e_notification_types";

export const renderLinks = (type, actionId) => {
  switch (type) {
    case ENotificationTypes.timeOffApproved:
    case ENotificationTypes.timeOffReject:
      return "/profile?tab=3";
    case ENotificationTypes.vacationApproved:
    case ENotificationTypes.vacationRejected:
      return "/profile?tab=2";
    case ENotificationTypes.timeOffSend:
      return "/permissions";
    case ENotificationTypes.vacationSend:
      return "/vacations";
    case ENotificationTypes.ticketSend:
    case ENotificationTypes.ticketResponse:
      return `/requests/${actionId}`;
    case ENotificationTypes.shiftChangeApproved:
    case ENotificationTypes.shiftChangeRejected:
    case ENotificationTypes.shiftChangeSend:
      return "/shift-change";
    case ENotificationTypes.voiceNote:
      return "/announcements";
    default:
      return "/";
  }
};

const HeaderMail = ({ data, onMarkAsRead }) => {
  const dispatch = useAppDispatch();
  const notifications = useAppSelector(
    (state) => state.notification.notifications
  );


  return (
    <Dropdown
      button={
        <div className="relative">
          <MailSVG className="dark:text-white" />
          {notifications?.data?.some(
            (notification) => notification.is_read == false
          ) && (
            <div className="absolute -top-1 -right-1 w-2.5 h-2.5 bg-red rounded-full"></div>
          )}
        </div>
      }
      className="flex"
    >
      <div className="flex flex-col absolute top-[calc(100%+16px)] -right-4 bg-white dark:bg-lightBlack min-w-[480px] rounded-xl drop-shadow after:absolute after:right-4 after:-top-1.5 after:w-3 after:h-3 after:bg-white dark:after:bg-lightBlack after:rotate-45 divide-y divide-light dark:divide-white">
        <div className="flex items-center justify-center gap-x-2 p-4">
          <button
            className={`flex items-center justify-center gap-x-2 ${
              notifications?.data?.length == 0
                ? "text-gray pointer-events-none"
                : "text-blue"
            }`}
            onClick={() => onMarkAsRead(null)}
          >
            <DoubleCheckSVG />
            <p className="font-semibold">{t("mark_as_read_all")}</p>
          </button>
        </div>
        {notifications?.data?.length > 0 ? (
          notifications?.data?.map((notification) => (
            <Menu.Item as={Fragment} key={notification.id}>
              <Link
                to={renderLinks(notification.type, notification.actionId)}
                state={{ notificationId: notification.id, actionId: notification.actionId }}
                className="flex items-center gap-x-4 p-4 rounded whitespace-nowrap"
                onClick={() => !notification.is_read && onMarkAsRead(notification.id)}
              >
                <MailSVG
                  className={!notification.is_read ? "text-red" : "text-gray"}
                />
                <p
                  className={`ui-active:!text-red ${
                    !notification.is_read
                      ? "text-darkBlue dark:text-white"
                      : "text-gray"
                  }`}
                >
                  {notification.message}
                </p>
                <p
                  className={`ui-active:!text-red ${
                    !notification.is_read
                      ? "text-darkBlue dark:text-white"
                      : "text-gray"
                  }`}
                >
                  {notification.created_at}
                </p>
                <p
                  className={`ui-active:!text-red ml-auto ${
                    !notification.is_read
                      ? "text-darkBlue dark:text-white"
                      : "text-gray"
                  }`}
                >
                  {notification.is_read ? t("was_read") : t("unread")}
                </p>
              </Link>
            </Menu.Item>
          ))
        ) : (
          <div className="w-full p-4 flex items-center justify-center">
            <div className="w-fit flex items-center gap-x-3 p-4 rounded-xl bg-light dark:bg-softBlack text-gray dark:text-white">
              <InfoSVG />
              <p>Göstəriləcək hər hansı bir məlumat yoxdur</p>
            </div>
          </div>
        )}
        <Menu.Item>
          <Link to="/notifications" className="p-4 text-center">
            {t("last_month_notifications")}
          </Link>
        </Menu.Item>
      </div>
    </Dropdown>
  );
};

export default HeaderMail;
