import { EProfileSteps } from "src/enums/e_profile_steps";
import { ITabStep } from "src/types/Common/i_tab_step";
import { ReactComponent as UserSVG } from "src/assets/vectors/user.svg";
import { ReactComponent as CalendarSVG } from "src/assets/vectors/calendar.svg";
import { ReactComponent as ClockSVG } from "src/assets/vectors/clock.svg";

export const ProfileStepsData: ITabStep[] = [
    {
        id: EProfileSteps.profileinfo,
        title: "user_info",
        icon: UserSVG
    },
    {
        id: EProfileSteps.vacation,
        title: "vacation",
        icon: CalendarSVG
    },
    {
        id: EProfileSteps.getpermission,
        title: "get_permission",
        icon: ClockSVG
    }
];