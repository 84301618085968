import t from "src/hooks/useTranslate";
import { ReactComponent as InfoSVG } from "src/assets/vectors/info.svg";
import { useEffect, useState } from "react";
import { EAscDesc } from "src/enums/e_asc_desc";

import InputSearch from "src/components/Common/Inputs/InputSearch";
import Shimmer from "src/components/Common/Shimmer";
import months from "src/data/months.json";
import { ddmmyyyy } from "src/helper/getFormattedDate";
import InputCalendar from "src/components/Common/Inputs/InputCalendar";
import Pagination from "src/components/Common/Pagination";

const GarageRegistrationTable = ({
  data,
  isLoading,
  setParams,
  value,
  setValue,
}) => {
  // const [value, setValue] = useState(ddmmyyyy(new Date()));
  const [params, setParamsData] = useState(new URLSearchParams());
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState("");

  useEffect(() => {
    if (value !== ddmmyyyy(new Date())) {
      params.delete("page");
      params.set("date", value.split(".").slice(1).join("."));
      setValue(value);
    }
  }, [value]);

  useEffect(() => {
    data && setParams("?" + params.toString());
  }, [page, perPage, value, query]);

  return (
    <div className="w-full relative bg-white dark:bg-lightBlack rounded-xl drop-shadow-lg min-h-[476px]">
      <div className="flex items-center p-4">
        <p className="text-16 font-bold dark:text-white">
          {t("active_informations")}
        </p>
        <div className="flex items-center gap-x-4 ml-auto">
          <div className="relative">
            <InputCalendar
              className="min-w-[260px] z-20"
              maxDetail={"year"}
              monthView
              controlled
              value={value}
              onChange={(e) => {
                params.delete("page");
                params.set("date", e.split(".").slice(1).join("."));
                setValue(e);
              }}
            />
          </div>
          <InputSearch
            data={[]}
            className="min-w-[300px]"
            optionHidden
            placeholder={t("search") + "..."}
            onInput={(query: string) => {
              params.delete("page");
              params.set("search", query);
              setQuery(query);
            }}
          />
        </div>
      </div>
      <table className="min-w-[991px] w-full">
        <thead>
          <tr className="[&>th]:text-left [&>th]:px-2 [&>th]:py-4 [&>th]:dark:text-white [&>th]:whitespace-nowrap border-y border-solid border-gray">
            <th className="w-auto !pl-4">{t("date")}</th>
            <th className="w-auto">{t("car_number")}</th>
            <th className="w-auto">{t("user")}</th>
            <th className="w-auto !pr-4">{t("end_date")}</th>
          </tr>
        </thead>
        <tbody>
          {data && !isLoading ? (
            data.data.length > 0 &&
            data.data.map((garage, i) => (
              <tr
                className="relative [&>td]:py-4 [&>td]:px-2 [&>td]:dark:text-white after:absolute after:top-0 after:left-0 after:w-full after:h-[1px] after:bg-light after:opacity-50"
                key={i}
              >
                <td className="!pl-4">
                  {ddmmyyyy(new Date(garage.created_at))}
                </td>
                <td>{garage.car_number}</td>
                <td>{garage.username}</td>
                <td className="!pr-4">{ddmmyyyy(new Date(garage.end_date))}</td>
              </tr>
            ))
          ) : (
            <>
              {[...Array(perPage)].map((_, i) => (
                <tr
                  className="[&>td]:py-4 [&>td]:px-2 bg-white dark:bg-lightBlack"
                  key={i}
                >
                  <td className="w-auto">
                    <Shimmer className="h-10.5" />
                  </td>
                  <td className="w-auto">
                    <Shimmer className="h-10.5" />
                  </td>
                  <td className="w-auto">
                    <Shimmer className="h-10.5" />
                  </td>
                  <td className="w-auto">
                    <Shimmer className="h-10.5" />
                  </td>
                </tr>
              ))}
            </>
          )}
        </tbody>
      </table>
      {data && !data.data.length && (
        <div className="w-full h-[500px] p-4 flex items-center justify-center">
          <div className="w-fit flex items-center gap-x-3 p-4 rounded-xl bg-light dark:bg-softBlack text-gray dark:text-white">
            <InfoSVG />
            <p>Göstəriləcək hər hansı bir məlumat yoxdur</p>
          </div>
        </div>
      )}
      {data?.total > 5 && (
        <div className="w-full px-4 py-4 border-t border-solid border-gray">
          <Pagination
            page={Math.ceil(data?.total / perPage)}
            total={data?.total}
            perPage={perPage}
            pageChange={(e) => {
              setPage(e + 1);
              params.set("page", (e + 1).toString());
            }}
            setPerPage={(val) => {
              setPerPage(val);
              params.set("per_page", val.toString());
            }}
          />
        </div>
      )}
    </div>
  );
};

export default GarageRegistrationTable;
