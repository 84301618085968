import { SyntheticEvent, useEffect, useState } from "react";
import t from "src/hooks/useTranslate";
import Header from "src/components/Header/Header";
import ProfileGetPermission from "src/components/Profile/ProfileGetPermission";
import ProfileGetPermissionTable from "src/components/Profile/ProfileGetPermissionTable";
import ProfileInfo from "src/components/Profile/ProfileInfo";
import ProfileVacation from "src/components/Profile/ProfileVacation";
import ProfileVacationTable from "src/components/Profile/ProfileVacationTable";
import { ProfileStepsData } from "src/data/profile_steps_data";
import { EProfileSteps } from "src/enums/e_profile_steps";
import { dataURLtoFile } from "src/helper/dataUrlToFile";
import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import {
  useAddTimeOffMutation,
  useAddVacationMutation,
  useProfileUpdateMutation,
  useVacationsQuery,
} from "src/services/profileService";
import {
  resetGetPermissionFields,
  resetGetVacationFields,
} from "src/store/Profile/profileStore";
import { successToast, errorToast } from "src/store/Settings/toastStore";
import dataReasons from "src/data/time_off_reasons.json";
import { IProfile } from "src/types/i_profile";
import { useSearchParams } from "react-router-dom";

const Profile = () => {
  const dispatch = useAppDispatch();
  let [params, setSearchParams] = useSearchParams();
  const [currentStep, setCurrentStep] = useState<EProfileSteps>(null);
  const profile = useAppSelector((state) => state.profile.user);
  const get_permission_fields = useAppSelector(
    (state) => state.profile.get_permission_fields
  );
  const get_vacation_fields = useAppSelector(
    (state) => state.profile.get_vacation_fields
  );
  const [
    updateProfileInfo,
    { isLoading: profileInfoUpdating, isSuccess: profileInfoUpdated },
  ] = useProfileUpdateMutation();
  const [
    addTimeOff,
    {
      isLoading: timeOffAdding,
      isSuccess: timeOffAdded,
      isError: timeOffNotAdded,
      error: timeOffError,
    },
  ] = useAddTimeOffMutation();

  const [
    addVacation,
    {
      isLoading: vacationAdding,
      isSuccess: vacationAdded,
      isError: vacationNotAdded,
      error: vacationError,
    },
  ] = useAddVacationMutation();

  /******VACATIONS*******/
  const [vacationParams, setVacationParams] = useState("");

  const {
    data: vacations,
    refetch: vacationRefetch,
    isFetching: vacationLoading,
  } = useVacationsQuery(vacationParams, {
    skip: currentStep != EProfileSteps.vacation,
  });

  useEffect(() => {
    if (
      params.get("tab") &&
      Object.values(EProfileSteps)
        .slice(Object.values(EProfileSteps).length / 2)
        .some((x) => x.toString() === params.get("tab"))
    ) {
      return setCurrentStep(Number(params.get("tab")));
    }
    setCurrentStep(EProfileSteps.profileinfo);
  }, []);

  // useEffect(() => {
  //   vacationRefetch();
  // }, [vacationParams]);

  const renderTabs = () => {
    switch (currentStep) {
      case EProfileSteps.profileinfo:
        return (
          <ProfileInfo
            isLoading={profileInfoUpdating}
            isUpdated={profileInfoUpdated}
            onSubmit={onProfileInfoUpdate}
          />
        );
      case EProfileSteps.vacation:
        return (
          <ProfileVacation
            vacationDays={vacations?.vacation}
            isLoading={vacationAdding}
            onSubmit={onVacationSubmit}
          />
        );
      case EProfileSteps.getpermission:
        return (
          <ProfileGetPermission
            isLoading={timeOffAdding}
            onSubmit={onGetPermissionSubmit}
            isSuccess={timeOffAdded}
          />
        );

      default:
        return;
    }
  };

  const renderTable = () => {
    switch (currentStep) {
      case EProfileSteps.profileinfo:
        return;
      case EProfileSteps.vacation:
        return (
          <ProfileVacationTable
            data={vacations}
            isLoading={vacationLoading}
            setParams={setVacationParams}
          />
        );
      case EProfileSteps.getpermission:
        return <ProfileGetPermissionTable />;

      default:
        return;
    }
  };

  const onProfileInfoUpdate = async (e: SyntheticEvent) => {
    e.preventDefault();

    const formData = new FormData();
    for (const key in profile) {
      formData.append(
        key,
        profile[key as keyof typeof profile] as unknown as string
      );
    }
    if (!profile.avatar) {
      formData.delete("avatar");
      formData.append("remove_image", "true");
    } else if (
      profile.avatar.startsWith(process.env.REACT_APP_BASE_API_PATHNAME!)
    ) {
      formData.delete("avatar");
    } else {
      formData.set("avatar", dataURLtoFile(profile.avatar, "image"));
    }
    if (
      profile.password !== profile.password_confirmation ||
      !profile.old_password
    ) {
      formData.delete("password");
      formData.delete("password_confirmation");
      formData.delete("old_password");
    }

    await updateProfileInfo(formData as unknown as IProfile);
  };

  const onGetPermissionSubmit = async () => {
    const body = {
      ...get_permission_fields,
      comment: t(
        dataReasons.find((x) => x.id == get_permission_fields.comment).name
      ),
    };
    if (
      get_permission_fields.comment == 5 &&
      !get_permission_fields.comment_text
    ) {
      await addTimeOff({ ...get_permission_fields, comment_text: t("other") });
    } else {
      await addTimeOff(body);
    }
  };

  const onVacationSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();
    await addVacation(get_vacation_fields);
  };

  useEffect(() => {
    if (timeOffAdded) {
      dispatch(successToast("Time off added!"));
      dispatch(resetGetPermissionFields());
    }
    if (vacationAdded) {
      dispatch(successToast("Vacation added!"));
      dispatch(resetGetVacationFields());
    }
  }, [timeOffAdded, vacationAdded]);

  useEffect(() => {
    if (timeOffNotAdded) {
      //@ts-ignore
      dispatch(errorToast(timeOffError?.data.message));
    }
  }, [timeOffNotAdded]);

  useEffect(() => {
    if (vacationNotAdded) {
      //@ts-ignore
      dispatch(errorToast(vacationError?.data.message));
    }
  }, [vacationNotAdded]);

  useEffect(() => {
    profileInfoUpdated && dispatch(successToast("Profile user is updated"));
  }, [profileInfoUpdated]);

  return (
    <>
      <Header title={t("my_profile")} />
      <div className="w-full flex flex-wrap gap-6">
        <div className="w-full min-h-[367px] relative py-6 bg-white dark:bg-lightBlack rounded-xl drop-shadow-lg z-10">
          <ul className="flex items-center gap-x-4 px-4 pb-6 border-b border-solid border-b-gray">
            {ProfileStepsData.map((step) => {
              const Icons = step.icon;
              return (
                <li
                  key={step.id}
                  className={`flex items-center gap-x-2 px-4 py-2 rounded cursor-pointer ${
                    step.id === currentStep
                      ? "bg-light text-darkBlue dark:bg-softBlack dark:text-white"
                      : "font-medium text-gray"
                  }`}
                  onClick={() => {
                    setSearchParams({ tab: step.id.toString() });
                    setCurrentStep(step.id);
                  }}
                >
                  <Icons />
                  {t(step.title)}
                </li>
              );
            })}
          </ul>
          <div className="px-4 pt-6">{renderTabs()}</div>
        </div>
        {renderTable()}
      </div>
    </>
  );
};

export default Profile;
