import t from "src/hooks/useTranslate";
import Button from "src/components/Common/Inputs/Button";
import Shimmer from "src/components/Common/Shimmer";
import Header from "src/components/Header/Header";
import { ReactComponent as InfoSVG } from "src/assets/vectors/info.svg";
import { ReactComponent as WhatsappSVG } from "src/assets/vectors/whatsapp.svg";
import { useEffect, useState } from "react";
import Input from "src/components/Common/Inputs/Input";
import Select from "src/components/Common/Select";
import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import InputRangeDatePicker from "src/components/Common/Inputs/InputRangeDatePicker";
import Modal from "src/components/Common/Modal";
import newAnnouncementStatusData from "src/data/announcement_new_status_data.json";
import announceTypeData from "src/data/announce_type_data.json";
import {setForm} from "src/store/Announcements/announcementStore";
import AnnouncementAudioRecording from "src/components/Announcements/AnnouncementAudioRecording";
import {
    useAnnouncementsNewQuery,
    useCommentToVoiceMutation,
    useGetNewAnnouncementMutation,
    useGetOperatorsQuery,
} from "src/services/announcementsService";
import Pagination from "src/components/Common/Pagination";
import usePermission from "src/hooks/usePermission";
import { EPermissions } from "src/enums/e_permissions";
import {useLocation, useNavigate} from "react-router-dom";
import {errorToast, successToast} from "../../../store/Settings/toastStore";
const initial_filter = {
    id: "",
    link: 0,
    car_number: "",
    vin: "",
    phone: "",
    status: 0,
    comment: "",
    announce_type: 0,
    date: "",
    user_id: 0,
};

const AnnouncementsTWO = () => {
    let { state } = useLocation();
    const navigate = useNavigate()
    const dispatch = useAppDispatch();
    const [modal, setModal] = useState(false);
    const [announcementID, setAnnouncementID] = useState(0);
    const [filterData, setFilterData] = useState(initial_filter);
    const [params, setParams] = useState(new URLSearchParams());
    const [page, setPage] = useState(1);
    const [isAdmin, setIsAdmin] = useState(true);
    const { data: operators } =
        useGetOperatorsQuery("");
    const isOperator = useAppSelector((state) => state.profile.user.is_operator);
    const [perPage, setPerPage] = useState(10);
    const canCreate = usePermission([EPermissions.announce_create]);
    const canViewRecordingList = usePermission([
        EPermissions.announce_voice_list,
    ]);

    const {
        data: announcements,
        isFetching: isAnnouncementsLoading,
    } = useAnnouncementsNewQuery(params.toString() ? "?" + params.toString() : "");

    const [getNewAnnouncement, { isLoading: isLoadingGetNewAnnouncement }] = useGetNewAnnouncementMutation();
    const [modalType, setModalType] = useState("form");

    useEffect(() => {
        if (state?.notificationId) {
            setAnnouncementID(state.actionId);
            setModalType("audio");
            setIsAdmin(false);
            setModal(true);
            window.history.replaceState({}, document.title);
        }
    }, [state]);

    const [
        commentToVoice,
        {
            isLoading: commentToVoiceLoading,
            isSuccess: commentToVoiceSuccess,
            error: commentToVoiceError,
            isError: isCommentToVoiceError,
        },
    ] = useCommentToVoiceMutation();

    const onCommentToVoice = async (body: any) => {
        await commentToVoice({ id: announcementID, body });
    };

    useEffect(() => {
        if (commentToVoiceSuccess) {
            setModal(false);
            successToast("Komment uğurla əlavə edildi");
        }
    }, [commentToVoiceSuccess]);

    useEffect(() => {
        if (isCommentToVoiceError) {
            //@ts-ignore
            distpatch(errorToast(commentToVoiceError?.data.message));
        }
    }, [isCommentToVoiceError]);


    const renderModal = () => {
        switch (modalType) {
            case "audio":
                return (
                    <AnnouncementAudioRecording
                        announcementID={announcementID}
                        onClose={() => setModal(false)}
                        onSubmit={onCommentToVoice}
                        isLoading={commentToVoiceLoading}
                        isAdmin={isAdmin}
                    />
                );
            default:
                return;
        }
    };

    const handleHistory = () => {
        getNewAnnouncement().unwrap().then(({ success, data }) => {
            if (success) {
                dispatch(setForm(data));
                navigate(`/announcementsTwo/details`);
            } else {
                console.log(success)
                dispatch(errorToast("Sənin iş saatın bitib"))
            }
        }).catch(
            console.error
        );
    };

    return (
        <>
            <Header title={t("announcements")} backLink="/">
                <div className="w-full flex items-center justify-center gap-x-4">
                    {newAnnouncementStatusData.slice(1).map((info) => (
                        <div
                            className="relative w-6 h-6 border border-solid border-gray rounded-full cursor-pointer group"
                            style={{ backgroundColor: info.color }}
                            key={info.id}
                        >
                            <p className="hidden absolute top-[calc(100%+12px)] right-0 whitespace-nowrap px-2 py-1 bg-white drop-shadow-lg rounded group-hover:flex">
                                {t(info.name)}
                            </p>
                        </div>
                    ))}
                </div>
            </Header>
            <div className="flex flex-wrap gap-6 w-full relative py-6 bg-white dark:bg-lightBlack rounded-xl drop-shadow-lg">
                <div className="relative w-full min-h-[467px] overflow-x-auto">
                    <table className="w-full">
                        <thead>
                        <tr className="[&>th]:text-left [&>th]:pb-6 [&>th]:px-2 [&>th]:dark:text-white [&>th]:whitespace-nowrap border-b border-solid border-gray">
                            <th className="w-[5%] !pl-4">
                                ID
                                <Input
                                    type="number"
                                    onKeyDown={(e) =>
                                        ["e", "E", "+", "-", ".", ","].includes(e.key) &&
                                        e.preventDefault()
                                    }
                                    defaultValue={filterData.id}
                                    maxLength={8}
                                    onDebouncedValue={(val) => {
                                        setFilterData({ ...filterData, id: val });
                                        params.delete("page");
                                        params.set("id", val);
                                    }}
                                />
                            </th>
                            <th className="w-[8%]">
                                {t("link")}
                                <div className="[&_ul]:!w-min [&_ul]:!px-3">
                                    <Select
                                        data={[
                                            { id: 1, name: "turbo.az" },
                                            { id: 2, name: "lalafo.az" },
                                            { id: 3, name: "Digər" },
                                        ]}
                                        defaultTitle={t("choose")}
                                        defaultValue={filterData.link}
                                        onChange={(e) => {
                                            setFilterData({ ...filterData, link: e.id });
                                            params.delete("page");
                                            params.set("link", e.id.toString());
                                        }}
                                    />
                                </div>
                            </th>
                            <th className="w-[8%]">
                                {t("car_number")}
                                <Input
                                    placeholder="10AA100"
                                    defaultValue={filterData.car_number}
                                    maxLength={9}
                                    onDebouncedValue={(val) => {
                                        setFilterData({
                                            ...filterData,
                                            car_number: val,
                                        });
                                        params.delete("page");
                                        params.set("car_number", val.replaceAll("-", ""));
                                    }}
                                />
                            </th>
                            <th className="w-[10%]">
                                VIN
                                <Input
                                    className="[&>input]:uppercase"
                                    defaultValue={filterData.vin}
                                    maxLength={17}
                                    onDebouncedValue={(val) => {
                                        setFilterData({ ...filterData, vin: val });
                                        params.delete("page");
                                        params.set("vin", val);
                                    }}
                                />
                            </th>
                            <th className="w-[8%]">
                                {t("phone")}
                                <Input
                                    className="[&>input]:uppercase"
                                    type="number"
                                    defaultValue={filterData.phone}
                                    maxLength={10}
                                    onKeyDown={(e) =>
                                        ["e", "E", "+", "-", ".", ","].includes(e.key) &&
                                        e.preventDefault()
                                    }
                                    onDebouncedValue={(val) => {
                                        setFilterData({ ...filterData, phone: val });
                                        params.delete("page");
                                        params.set("phone", val);
                                    }}
                                />
                            </th>
                            <th className="w-[10%]">
                                {t("status")}
                                <div className="[&_ul]:!w-min [&_ul]:!px-3">
                                    <Select
                                        data={newAnnouncementStatusData}
                                        defaultTitle={t("choose")}
                                        defaultValue={filterData.status}
                                        onChange={(e) => {
                                            setFilterData({
                                                ...filterData,
                                                status: e.id,
                                            });
                                            params.delete("page");
                                            params.set("status", e.id.toString());
                                        }}
                                    />
                                </div>
                            </th>
                            <th className="w-[16%]">
                                {t("comment")}{" "}
                                <Input
                                    defaultValue={filterData.comment}
                                    onDebouncedValue={(val) => {
                                        setFilterData({ ...filterData, comment: val });
                                        params.delete("page");
                                        params.set("comment", val);
                                    }}
                                />
                            </th>
                            <th className="w-[10%]">
                                {t("type")}
                                <div className="[&_ul]:!w-min [&_ul]:!px-3">
                                    <Select
                                        data={announceTypeData}
                                        defaultTitle={t("choose")}
                                        defaultValue={filterData.announce_type}
                                        onChange={(e) => {
                                            setFilterData({
                                                ...filterData,
                                                announce_type: e.id,
                                            });
                                            params.delete("page");
                                            params.set("announce_type", e.id.toString());
                                        }}
                                    />
                                </div>
                            </th>
                            <th className="w-[10%]">
                                {t("date")}
                                <InputRangeDatePicker
                                    className="z-20"
                                    defaultValue={filterData.date}
                                    onChange={(e: string) => {
                                        setFilterData({
                                            ...filterData,
                                            date: e,
                                        });
                                        params.delete("page");
                                        params.set("date", e.toString());
                                    }}
                                />
                            </th>
                            <th className="w-[10%]">
                                {t("user")}{" "}
                                <div
                                    className={`[&_ul]:!w-min [&_ul]:!px-3 relative ${
                                        isOperator
                                            ? "text-gray after:z-20 pointer-events-none after:absolute after:inset-0 after:bg-gray dark:after:bg-black after:opacity-50"
                                            : undefined
                                    }`}
                                >
                                    <Select
                                        data={
                                            operators
                                                ? [{ id: 0, name: t("all") }, ...operators.data]
                                                : [{ id: 0, name: t("all") }]
                                        }
                                        defaultTitle={t("name")}
                                        defaultValue={filterData.user_id}
                                        onChange={(e) => {
                                            if (e.id == 0) {
                                                setFilterData({
                                                    ...filterData,
                                                    user_id: 0,
                                                });
                                                params.delete("page");
                                                params.delete("user_id");
                                            } else {
                                                setFilterData({
                                                    ...filterData,
                                                    user_id: e.id,
                                                });
                                                params.delete("page");
                                                params.set("user_id", e.id.toString());
                                            }
                                        }}
                                    />
                                </div>
                            </th>
                            <th className="w-[2%] !pr-4">
                                <Button
                                    className="bg-blue text-white mt-6"
                                    onClick={() => {
                                        setFilterData(initial_filter);
                                        params.delete("page");
                                        for (const key in filterData) {
                                            params.delete(key);
                                        }
                                    }}
                                >
                                    {t("reset")}
                                </Button>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {announcements && !isAnnouncementsLoading ? (
                            announcements.data.map((announcement: any) => (
                                <tr
                                    className="relative h-20 [&>td]:py-4 [&>td]:px-2 after:absolute after:top-0 after:left-0 after:w-full after:h-[1px] after:bg-gray after:opacity-50"
                                    style={{
                                        backgroundColor: newAnnouncementStatusData.find(
                                            (status) => status.id == announcement.status
                                        )?.color,
                                        opacity: announcement.status === 9 ? "0.6" : "",
                                    }}
                                    key={announcement.id}
                                >
                                    <td className="!pl-4">{announcement.id}</td>
                                    <td className="text-blue">
                                        <a
                                            href={announcement.link}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {announcement.link_text}
                                        </a>
                                        {announcement.mashin_link && (
                                            <a
                                                href={announcement.mashin_link}
                                                className="block"
                                                target="_blank"
                                            >
                                                mashin.al
                                            </a>
                                        )}
                                    </td>
                                    <td>{announcement.car_number_mask}</td>
                                    <td>{announcement.vin}</td>
                                    <td>
                                        <div className='flex items-center gap-1'>
                                            {canViewRecordingList && announcement.status == 7 && (
                                                <p
                                                    className="text-blue text-12 cursor-pointer hover:brightness-125"
                                                    onClick={() => {
                                                        window.open(`https://wa.me/${announcement.phone}?text=Salam,Sizi Maşin.al saytından narahat edirik şəkilləri göndərərsiniz?`, '_blank');
                                                    }}
                                                >
                                                    <WhatsappSVG className='w-5 h-5'/>
                                                </p>
                                            )}
                                            <p>
                                                {announcement.phone}
                                            </p>
                                        </div>

                                        {canViewRecordingList && announcement.recordings_count !== 0 && (
                                            <p
                                                className="text-blue text-12 cursor-pointer hover:brightness-125 text-center"
                                                onClick={() => {
                                                    setModalType("audio");
                                                    setIsAdmin(true);
                                                    setAnnouncementID(announcement.id);
                                                    setModal(true);
                                                }}
                                            >
                                                {t("audio_recording")}
                                            </p>
                                        )}

                                    </td>
                                    <td>
                                        {announcement.status === 9
                                            ? "Vaxtı bitmiş"
                                            : t(
                                                newAnnouncementStatusData.find(
                                                    (status) => status.id == announcement.status
                                                )?.name as string
                                            )}
                                    </td>
                                    <td>
                                        <p className="line-clamp-3">
                                            {announcement.comment}
                                            {/* {announcement.comment ? (
                          <EyeSVG
                            className="cursor-pointer"
                            onClick={() => {
                              setText(announcement.comment);
                              setModalType("comment");
                              setModal(true);
                            }}
                          />
                        ) : (
                          ""
                        )} */}
                                        </p>
                                    </td>
                                    <td>
                                        {t(
                                            announceTypeData.find(
                                                (type) => type.id == announcement.announce_type
                                            )?.name as string
                                        )}
                                    </td>
                                    <td>{announcement.created_at}</td>
                                    <td>
                                        {`${
                                            announcement.user?.name
                                        }${announcement.user?.lastname.slice(0, 1)}.`}
                                    </td>
                                    {/*<td>*/}
                                    {/*  {canEdit && (*/}
                                    {/*      <EditSVG*/}
                                    {/*          className="text-blue ml-auto mr-4 cursor-pointer"*/}
                                    {/*          onClick={() => {*/}
                                    {/*            dispatch(resetForm());*/}
                                    {/*            setAnnouncementID(announcement.id);*/}
                                    {/*            setModalType("edit");*/}
                                    {/*            setModal(true);*/}
                                    {/*          }}*/}
                                    {/*      />*/}
                                    {/*  )}*/}
                                    {/*</td>*/}
                                </tr>
                            ))
                        ) : (
                            <>
                                {[...Array(10)].map((_, i) => (
                                    <tr
                                        className="[&>td]:py-4 [&>td]:px-2 bg-white dark:bg-lightBlack"
                                        key={i}
                                    >
                                        <td className="w-[5%] !pl-4">
                                            <Shimmer className="h-4"/>
                                        </td>
                                        <td className="w-[10%]">
                                            <Shimmer className="h-4"/>
                                        </td>
                                        <td className="w-[10%]">
                                            <Shimmer className="h-4"/>
                                        </td>
                                        <td className="w-[10%]">
                                            <Shimmer className="h-4"/>
                                        </td>
                                        <td className="w-[10%]">
                                            <Shimmer className="h-4" />
                                        </td>
                                        <td className="w-[10%]">
                                            <Shimmer className="h-4" />
                                        </td>
                                        <td className="w-[5%]">
                                            <Shimmer className="h-4" />
                                        </td>
                                        <td className="w-[10%]">
                                            <Shimmer className="h-4" />
                                        </td>
                                        <td className="w-[13%]">
                                            <Shimmer className="h-4" />
                                        </td>
                                        <td className="w-[10%]">
                                            <Shimmer className="h-4" />
                                        </td>
                                        <td className="w-[2%] !pr-4">
                                            <Shimmer className="h-4" />
                                        </td>
                                    </tr>
                                ))}
                            </>
                        )}
                        </tbody>
                    </table>
                    {announcements?.data.length < 1 && (
                        <div className="absolute inset-0 top-20 flex items-center justify-center">
                            <div className="w-fit flex items-center gap-x-3 p-4 rounded-xl bg-light dark:bg-softBlack text-gray dark:text-white">
                                <InfoSVG />
                                <p>Göstəriləcək hər hansı bir məlumat yoxdur</p>
                            </div>
                        </div>
                    )}
                </div>
                {announcements?.meta.total > 5 && (
                    <div className="w-full px-4 pt-4">
                        <Pagination
                            page={Math.ceil(announcements?.meta.total / perPage)}
                            total={announcements?.meta.total}
                            perPage={perPage}
                            pageChange={(e) => {
                                setPage(e + 1);
                                params.set("page", (e + 1).toString());
                            }}
                            setPerPage={(val) => {
                                setPerPage(val);
                                params.set("per_page", val.toString());
                            }}
                        />
                    </div>
                )}
            </div>
            {canCreate && (
                <Button
                    className="!fixed bottom-10 left-1/2 -translate-x-1/2 bg-blue w-40 !h-12 flex items-center gap-x-2 text-white text-30"
                    onClick={handleHistory}
                    isLoading={isLoadingGetNewAnnouncement}
                >
                    + <p className="text-16">{t("get")}</p>
                </Button>
            )}
            <Modal
                visible={modal}
                setVisible={setModal}
                clickOutside={modalType === "audio"}
                dialogClassName="min-w-[750px] overflow-visible"
            >
                {renderModal()}
            </Modal>
        </>
    );
};

export default AnnouncementsTWO;
