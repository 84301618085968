import React, { useEffect } from "react";
import t from "src/hooks/useTranslate";
import { IBaseSelect } from "src/types/Common/i_base_select";
import Select from "../Common/Select";
import { ReactComponent as ExcelSVG } from "src/assets/vectors/file_excel.svg";
//@ts-ignore
import { CSVLink } from "react-csv";
import Button from "../Common/Inputs/Button";
import {
  useLastOperatorExcelsQuery,
  useOperatorsExcelMutation,
  useOperatorsLastExcelExportMutation,
} from "src/services/operatorsService";
import { useAppDispatch } from "src/hooks/redux";
import { successToast } from "src/store/Settings/toastStore";

const OperatorsExcelExports = () => {
  const dispatch = useAppDispatch();
  const { data: operatorExcels } = useLastOperatorExcelsQuery("");

  const [
    getOperatorsExcel,
    { isLoading: isExcelDownloading, isSuccess: isExcelDownloaded },
  ] = useOperatorsExcelMutation();

  const [
    getLastOperatorsExcel,
    { isLoading: isLastExcelsDownloading, isSuccess: isLastExcelsDownloaded },
  ] = useOperatorsLastExcelExportMutation();

  const onGetOperatorsExcel = async () => {
    await getOperatorsExcel("");
  };

  const onGetLastOperatorsExcel = async (id) => {
    await getLastOperatorsExcel(id);
  };

  useEffect(() => {
    if (isExcelDownloaded) {
      dispatch(successToast("Excel uğurla yükləndi"));
    }
  }, [isExcelDownloaded]);

  useEffect(() => {
    if (isLastExcelsDownloaded) {
      dispatch(successToast("Excel uğurla yükləndi"));
    }
  }, [isLastExcelsDownloaded]);

  return (
    <div className="w-1/2 relative py-6 px-4 bg-white dark:bg-lightBlack rounded-xl mt-6 drop-shadow-lg">
      <div className="w-full flex">
        <div className="w-1/2 flex flex-col gap-y-4 pr-4">
          <p className="text-16 font-bold dark:text-white">
            {t("employees_excel_export")}
          </p>

          <Button
            className="bg-darkBlue dark:bg-blue text-white flex items-center gap-x-2"
            onClick={onGetOperatorsExcel}
            isLoading={isExcelDownloading}
          >
            <ExcelSVG /> {t("export_to_excel")}
          </Button>
        </div>
        <div className="w-1/2 flex flex-col gap-y-4 pl-4">
          <p className="text-16 font-bold dark:text-white">
            {t("last_exports")}
          </p>
          <div className="space-y-4">
            <div className="flex justify-between gap-x-2">
              <p className="text-gray">{t("date")}</p>
              <p className="text-gray">{t("download")}</p>
            </div>
            {operatorExcels?.map((last) => (
              <div className="flex justify-between gap-x-2 dark:text-white" key={last.id}>
                <p className="dark:text-white">{last.date.split(" ")[0]}</p>
                <span
                  className="flex items-center gap-x-2 cursor-pointer"
                  onClick={() => onGetLastOperatorsExcel(last.id)}
                >
                  <ExcelSVG /> {t("export_to_excel")}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OperatorsExcelExports;
