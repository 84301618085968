import { FC, memo, useEffect, useRef, useState } from "react";
import ReactPaginate from "react-paginate";
import { ReactComponent as DoubleChevronLeftSVG } from "src/assets/vectors/double_chevron_left.svg";
import { ReactComponent as DoubleChevronRightSVG } from "src/assets/vectors/double_chevron_right.svg";
import { ReactComponent as UpChevronSVG } from "src/assets/vectors/up_chevron.svg";
import t from "src/hooks/useTranslate";
import { IBaseSelect } from "src/types/Common/i_base_select";
import { IPagination } from "src/types/Common/i_pagination";
import Select from "./Select";
import Shimmer from "./Shimmer";
import { useSearchParams } from "react-router-dom";

const Pagination: FC<IPagination> = memo(
  ({ page, total, perPage, setPerPage, pageChange, active = 1 }) => {
    const container = useRef<any>(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const [activePage, setActivePage] = useState(active);
    const handlePageClick = (event: { selected: number }) => {
      container.current &&
        window.scrollTo({
          top: container.current.parentElement.parentElement.offsetTop - 48,
          left: 0,
          behavior: "smooth",
        });
      setActivePage(event.selected + 1);
      pageChange(event.selected);
    };

    // useEffect(() => {
    //   setActivePage(active);
    // }, [active]);

    return (
      <div ref={container}>
        <div
          className={`w-full items-center gap-x-4 ${total ? "flex" : "hidden"}`}
        >
          <div className="flex items-center gap-x-8">
            <div className="flex items-center gap-x-4">
              <p className="dark:text-white whitespace-nowrap">
                {t("total") + ": " + total}
              </p>
              <p className="dark:text-white whitespace-nowrap">
                {t("shown") +
                  ": " +
                  (searchParams.get("per_page")
                    ? searchParams.get("per_page")
                    : perPage)}
              </p>
            </div>
            <div className="w-24">
              <Select
                data={[
                  { id: 1, name: "5" },
                  { id: 2, name: "10" },
                  { id: 3, name: "20" },
                  { id: 4, name: "50" },
                  { id: 5, name: "100" },
                ]}
                defaultTitle={
                  searchParams.get("per_page")
                    ? searchParams.get("per_page")
                    : perPage.toString()
                }
                onChange={(e) => {
                  handlePageClick({ selected: 0 });
                  setActivePage(1);
                  setPerPage(Number(e.name));
                }}
              />
            </div>
          </div>
          {total > perPage && (
            <ReactPaginate
              containerClassName="w-fit py-1 px-4 ml-auto flex items-center gap-x-4 border border-solid border-gray rounded select-none [&_*]:dark:text-white"
              pageClassName="h-9 flex items-center text-center rounded hover:bg-light hover:dark:bg-softBlack"
              pageLinkClassName="py-2 px-4"
              activeClassName="bg-light dark:bg-softBlack"
              previousLabel={<DoubleChevronLeftSVG />}
              nextLabel={<DoubleChevronRightSVG />}
              breakLabel="..."
              pageRangeDisplayed={2}
              marginPagesDisplayed={2}
              initialPage={
                searchParams.get("page")
                  ? Number(searchParams.get("page")) - 1
                  : undefined
              }
              pageCount={Math.round(page)}
              forcePage={
                searchParams.get("page")
                  ? Number(searchParams.get("page")) - 1
                  : activePage - 1
              }
              onPageChange={handlePageClick}
            />
          )}
        </div>

        <div
          className={`w-full items-center gap-x-4 ${
            !total ? "flex" : "hidden"
          }`}
        >
          <Shimmer className="w-60 h-4" />
          <Shimmer className="w-16 h-10.5" />
          <Shimmer className="ml-auto w-60 h-10.5" />
        </div>
      </div>
    );
  }
);

export default Pagination;
