import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getCookie } from "src/helper/custom_cookies";

export const ringoStatService = createApi({
  reducerPath: "ringoStatService",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: (headers) => {
      headers.set(
        "Authorization",
        getCookie("mcrmtkn") ? "Bearer " + getCookie("mcrmtkn") : ""
      );
      headers.set("Accept", "application/json");
      return headers;
    },
    // credentials: "include",
  }),
  tagTypes: ["RingoStatService"],
  endpoints: (builder) => ({
    ringoStatList: builder.mutation({
      query: (payload) => {
        return {
          url: `/ringostat/list${payload.params}`,
          method: "post",
          body: payload.body,
        };
      },
    }),
    getBodyOptions: builder.mutation({
      query: () => {
        return {
          url: "https://dev.mashin.al/api/get_body_options",
          method: "get",
        };
      },
    }),
    getAutoSalonList: builder.query({
      query: () => {
        return {
          url: `/ringostat/salon_filter`,
        };
      },
      providesTags: ["RingoStatService"],
    }),
  }),
});

export const {
  useRingoStatListMutation,
  useGetBodyOptionsMutation,
  useGetAutoSalonListQuery,
} = ringoStatService;
