import React, { useCallback, useEffect, useState } from "react";
import Button from "src/components/Common/Inputs/Button";
import Shimmer from "src/components/Common/Shimmer";
import Header from "src/components/Header/Header";
import adminResponseStatus from "src/data/admin_response_status_data.json";
import { ReactComponent as UpChevronSVG } from "src/assets/vectors/up_chevron.svg";
import { ReactComponent as ExcelSVG } from "src/assets/vectors/file_excel.svg";
import { ReactComponent as InfoSVG } from "src/assets/vectors/info.svg";
import { ReactComponent as EyeSVG } from "src/assets/vectors/eye.svg";
import { ReactComponent as MailSVG } from "src/assets/vectors/mail.svg";
import { EAscDesc } from "src/enums/e_asc_desc";
import t from "src/hooks/useTranslate";
import InputSearch from "src/components/Common/Inputs/InputSearch";
import {
  useGetAllTimeOffsQuery,
  useTimeOffApproveMutation,
  useTimeOffsExcelMutation,
} from "src/services/timeOffService";
import { ddmmyyyy, ddmmyyyy_hhmm } from "src/helper/getFormattedDate";
import Modal from "src/components/Common/Modal";
import { successToast } from "src/store/Settings/toastStore";
import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import usePermission from "src/hooks/usePermission";
import { EPermissions } from "src/enums/e_permissions";
import Pagination from "src/components/Common/Pagination";
import AdminConfirmModal from "src/components/Common/AdminConfirmModal";
import { ETimeOffModals } from "src/enums/e_timeoff_modals";
import TextModal from "src/components/Common/TextModal";
import { useLocation } from "react-router-dom";
import { useNotificationDetailQuery } from "src/services/notificationService";
import { ETheme } from "src/enums/e_theme";

const Permissions = () => {
  const dispatch = useAppDispatch();
  let { state } = useLocation();
  const [params, setParams] = useState(new URLSearchParams());

  const [orderById, setOrderById] = useState(EAscDesc.desc);
  const [modal, setModal] = useState(false);
  const [modalType, setModalType] = useState(ETimeOffModals.default);
  const [text, setText] = useState("");
  const [activeId, setActiveId] = useState(0);
  const [reason, setReason] = useState("");
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [activeUserName, setActiveUserName] = useState("");
  const theme = useAppSelector((state) => state.theme.theme);
  const canStatusUpdate = usePermission([EPermissions.time_off_status_update]);
  const canStatusApprove = usePermission([
    EPermissions.time_off_status_approve,
  ]);
  const {
    data: notificationDetail,
    isLoading: notificationDetailLoading,
    isSuccess: notificationDetailLoaded,
  } = useNotificationDetailQuery(state?.notificationId, {
    skip: !state || !state?.notificationId,
  });
  const {
    data: timeOffs,
    refetch,
    isFetching,
    isSuccess,
  } = useGetAllTimeOffsQuery(params.toString() ? "?" + params.toString() : "");

  useEffect(() => {
    if (state?.notificationId) {
      setModalType(ETimeOffModals.notification);
      setModal(true);
      window.history.replaceState({}, document.title);
    }
  }, [state]);

  const [
    timeOffApprove,
    { isLoading: timeOffApproving, isSuccess: timeOffApproved },
  ] = useTimeOffApproveMutation();

  const [
    getTimeOffsExcel,
    { isLoading: isExcelDownloading, isSuccess: isExcelDownloaded },
  ] = useTimeOffsExcelMutation();

  useEffect(() => {
    if (state?.notificationId) {
      setModalType(ETimeOffModals.notification);
      setModal(true);
      window.history.replaceState({}, document.title);
    }
  }, [state]);

  const onGetTimeOffsExcel = async () => {
    await getTimeOffsExcel("?" + params.toString());
  };

  useEffect(() => {
    if (isExcelDownloaded) {
      dispatch(successToast("Excel uğurla yükləndi"));
    }
  }, [isExcelDownloaded]);

  useEffect(() => {
    refetch();
  }, [page, perPage, orderById, search]);

  const onTimeOffApprove = async (status) => {
    const body = {
      status,
      reason,
    };
    if (state?.actionId) {
      await timeOffApprove({ id: state?.actionId, body });
    } else {
      await timeOffApprove({ id: activeId, body });
    }
  };

  useEffect(() => {
    if (timeOffApproved) {
      dispatch(successToast(t("operation_complated")));
      setModal(false);
      setActiveId(0);
      setReason("");
    }
  }, [timeOffApproved]);

  const renderModal = () => {
    switch (modalType) {
      case ETimeOffModals.notification:
      case ETimeOffModals.submit:
        return (
          <AdminConfirmModal
            userName={
              modalType === ETimeOffModals.notification
                ? notificationDetail?.data.user
                : activeUserName
            }
            setModal={setModal}
            setReason={setReason}
            onSubmit={onTimeOffApprove}
            isLoading={timeOffApproving}
            isNotification={modalType === ETimeOffModals.notification}
            data={
              modalType === ETimeOffModals.notification &&
              notificationDetail?.data
            }
          />
        );
      case ETimeOffModals.comment:
      case ETimeOffModals.reason:
        return (
          <TextModal
            setModal={setModal}
            title={
              modalType === ETimeOffModals.reason
                ? `${t("reason")} (${activeUserName})`
                : `${t("comment")} (${activeUserName})`
            }
            text={text}
          />
        );
      default:
        return;
    }
  };

  // console.log("timeOffs", timeOffs);

  return (
    <>
      <Header title={t("permissions")} />
      <div className="w-full relative py-6 bg-white dark:bg-lightBlack rounded-xl drop-shadow-lg">
        <div className="min-h-[526px] relative w-full overflow-auto">
          <table className="min-w-[991px] w-full">
            <thead>
              <tr className="[&>th]:text-left [&>th]:pb-6 [&>th]:px-2 [&>th]:dark:text-white [&>th]:whitespace-nowrap border-b border-solid border-gray">
                <th
                  className="w-20 flex items-center gap-x-1.5 !pl-4 cursor-pointer"
                  onClick={() => {
                    setOrderById(
                      orderById == EAscDesc.asc ? EAscDesc.desc : EAscDesc.asc
                    );
                    params.set(
                      "id",
                      orderById == EAscDesc.asc ? EAscDesc.desc : EAscDesc.asc
                    );
                  }}
                >
                  ID
                  <UpChevronSVG
                    className={`w-3 text-blue ${
                      orderById == EAscDesc.desc ? "rotate-180" : ""
                    }`}
                  />
                </th>
                <th className="w-auto">
                  <InputSearch
                    data={[]}
                    placeholder={t("search") + "..."}
                    optionHidden
                    isLoading={isFetching}
                    onInput={(query: string) => {
                      params.delete("page");
                      params.set("search", query);
                      setSearch(query);
                    }}
                  />
                </th>
                <th className="w-auto">Başlama tarixi</th>
                <th className="w-auto">İcazə saatı</th>
                <th className="w-auto">Admin</th>
                <th className="w-auto">Status</th>
                <th className="w-auto">Yaranma tarixi</th>
                <th className="w-auto">Səbəb</th>
                <th className="min-w-[100px] w-auto !pr-4">Rəy</th>
                <th className="w-auto !pr-4">Əməliyyatlar</th>
              </tr>
            </thead>
            <tbody>
              {timeOffs && !isFetching ? (
                timeOffs.data.length > 0 &&
                timeOffs.data.map((timeOff, index) => (
                  <tr
                    className={`relative [&>td]:py-4 [&>td]:px-2 [&>td]:dark:text-white after:absolute after:top-0 after:left-0 after:w-full after:h-[1px] after:bg-gray after:opacity-50 ${
                      timeOff.has_notification
                        ? "bg-light dark:bg-softBlack"
                        : ""
                    }`}
                    key={timeOff.id}
                  >
                    <td className="w-10 !pl-4">
                      {page > 1 ? index + 1 + perPage * (page - 1) : index + 1}
                    </td>
                    <td className="relative">
                      {timeOff.has_notification && (
                        <MailSVG className="absolute top-1/2 -translate-y-1/2 -left-4 text-red" />
                      )}
                      {timeOff.user}
                    </td>
                    <td>{ddmmyyyy_hhmm(new Date(timeOff.start_date))}</td>
                    <td>
                      +
                      {timeOff.hour == 24
                        ? "1 " + t("day")
                        : timeOff.hour + " " + t("hour")}
                    </td>
                    <td>{timeOff.admin ?? ""}</td>
                    <td
                      style={{
                        color:
                          theme == ETheme.light
                            ? adminResponseStatus[timeOff.status]["color"]
                            : adminResponseStatus[timeOff.status]["darkColor"],
                      }}
                    >
                      {t(adminResponseStatus[timeOff.status]["name"])}
                    </td>
                    <td>{ddmmyyyy_hhmm(new Date(timeOff.created_at))}</td>
                    <td>
                      {timeOff.comment ? (
                        <EyeSVG
                          className="cursor-pointer"
                          onClick={() => {
                            setActiveUserName(timeOff.user);
                            setText(timeOff.comment);
                            setModalType(ETimeOffModals.reason);
                            setModal(true);
                          }}
                        />
                      ) : (
                        ""
                      )}
                    </td>
                    <td>
                      {timeOff.admin_comment ? (
                        <EyeSVG
                          className="cursor-pointer"
                          onClick={() => {
                            setActiveUserName(timeOff.user);
                            setText(timeOff.admin_comment);
                            setModalType(ETimeOffModals.comment);
                            setModal(true);
                          }}
                        />
                      ) : (
                        "-"
                      )}
                    </td>
                    <td className="!pr-4">
                      {timeOff.status === 1 && (
                        <Button
                          className="bg-blue text-white"
                          onClick={() => {
                            setActiveId(timeOff.id);
                            setActiveUserName(timeOff.user);
                            setModalType(ETimeOffModals.submit);
                            setModal(true);
                          }}
                        >
                          {t("submit")}
                        </Button>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <>
                  {[...Array(perPage)].map((_, i) => (
                    <tr className="[&>td]:py-4 [&>td]:px-2" key={i}>
                      <td className="w-20">
                        <Shimmer className="h-10.5" />
                      </td>
                      {[...Array(9)].map((__, idx) => (
                        <td className="w-auto" key={idx}>
                          <Shimmer className="h-10.5" />
                        </td>
                      ))}
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </table>
          {timeOffs && !timeOffs.data.length && (
            <div className="w-full h-[500px] p-4 flex items-center justify-center">
              <div className="w-fit flex items-center gap-x-3 p-4 rounded-xl bg-light dark:bg-softBlack text-gray dark:text-white">
                <InfoSVG />
                <p>Göstəriləcək hər hansı bir məlumat yoxdur</p>
              </div>
            </div>
          )}
        </div>
        {timeOffs?.meta.total > 5 && (
          <div className="w-full px-4 pt-4">
            <Pagination
              page={Math.ceil(timeOffs?.meta.total / perPage)}
              total={timeOffs?.meta.total}
              perPage={perPage}
              pageChange={(e) => {
                setPage(e + 1);
                params.set("page", (e + 1).toString());
              }}
              setPerPage={(val) => {
                setPerPage(val);
                params.set("per_page", val.toString());
              }}
            />
          </div>
        )}
      </div>
      {timeOffs?.data?.length > 0 && (
        <Button
          className="w-48 items-center gap-x-2 bg-darkBlue dark:bg-blue text-light mt-4 ml-auto"
          onClick={onGetTimeOffsExcel}
          isLoading={isExcelDownloading}
        >
          <ExcelSVG /> {t("export_to_excel")}
        </Button>
      )}

      <Modal
        visible={modal}
        setVisible={setModal}
        dialogClassName="min-w-[550px] max-w-[550px]"
      >
        {renderModal()}
      </Modal>
    </>
  );
};

export default Permissions;
