import { ReactComponent as InfoSVG } from "src/assets/vectors/info.svg";
import t from "src/hooks/useTranslate";
import Shimmer from "../Common/Shimmer";

const ConfirmProcessingTable = ({ data, isLoading }) => {
  return (
    <div className="min-h-[476px]">
      <table className="min-w-[991px] w-full">
        <thead>
          <tr className="[&>th]:text-left [&>th]:px-2 [&>th]:py-4 [&>th]:dark:text-white [&>th]:whitespace-nowrap border-y border-solid border-gray">
            <th className="w-[25%] !pl-4">{t("fullname")}</th>
            <th className="w-[25%]">ID</th>
            <th className="w-[25%]">{t("announcement")}</th>
            <th className="w-[25%] !pr-4">{t("date")}</th>
          </tr>
        </thead>
        <tbody>
          {data && !isLoading ? (
            data.data.length > 0 ? (
              data.data.map((announce, i) => (
                <tr
                  className="relative [&>td]:py-4 [&>td]:px-2 [&>td]:dark:text-white after:absolute after:top-0 after:left-0 after:w-full after:h-[1px] after:bg-light after:opacity-50"
                  key={i}
                >
                  <td className="!pl-4">{announce.full_name}</td>
                  <td>
                    {announce.url ? (
                      <a
                        href={announce.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue"
                      >
                        {announce.id}
                      </a>
                    ) : (
                      <p>{announce.id}</p>
                    )}
                  </td>
                  <td>{announce.car_name}</td>
                  <td className="!pr-4">{announce.date}</td>
                </tr>
              ))
            ) : (
              <div className="w-full absolute top-2/3 -translate-y-2/3 p-4 flex items-center justify-center">
                <div className="w-fit flex items-center gap-x-3 p-4 rounded-xl bg-light dark:bg-softBlack text-gray dark:text-white">
                  <InfoSVG />
                  <p>Göstəriləcək hər hansı bir məlumat yoxdur</p>
                </div>
              </div>
            )
          ) : (
            <>
              {[...Array(10)].map((_, i) => (
                <tr
                  className="[&>td]:py-4 [&>td]:px-2 bg-white dark:bg-lightBlack"
                  key={i}
                >
                  <td className="w-[25%]">
                    <Shimmer className="h-10.5" />
                  </td>
                  <td className="w-[25%]">
                    <Shimmer className="h-10.5" />
                  </td>
                  <td className="w-[25%]">
                    <Shimmer className="h-10.5" />
                  </td>
                  <td className="w-[25%]">
                    <Shimmer className="h-10.5" />
                  </td>
                </tr>
              ))}
            </>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default ConfirmProcessingTable;
