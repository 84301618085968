import { Menu, Transition } from "@headlessui/react";
import { FC, useState } from "react";

const Dropdown: FC<any> = ({
  className = "relative",
  button,
  isSelectOpened,
  children,
}) => {
  const [z, setZ] = useState(9);

  return (
    <Menu as="div" className={className} style={{ zIndex: z }}>
      {({ open }) => {
        setTimeout(() => {
          open ? setZ(12) : setZ(9);
        });
        isSelectOpened?.(open);
        return (
          <>
            <Menu.Button>{button}</Menu.Button>
            <Transition
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              <Menu.Items>{children}</Menu.Items>
            </Transition>
          </>
        );
      }}
    </Menu>
  );
};

export default Dropdown;
