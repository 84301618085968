import { Fragment } from "react";
import { ReactComponent as UpChevronSVG } from "src/assets/vectors/up_chevron.svg";
import t from "src/hooks/useTranslate";
import { SidebarSectionsData } from "src/data/sidebar_sections_data";
import { Link, NavLink } from "react-router-dom";
import Accordion from "src/components/Common/Accordion";
import { useAppSelector } from "src/hooks/redux";

const SidebarSections = () => {
  const user = useAppSelector((state) => state.profile.user);

  // console.log("subsection.sub_section_categories", SidebarSectionsData);

  return (
    <div className="flex flex-col gap-y-4 bg-darkBlue dark:bg-lightBlack px-4 py-6 text-white rounded-b-xl">
      {SidebarSectionsData.map((section) => {
        return (
          (!section.permissions ||
            section.permissions?.some((subSP) =>
              user.all_permissions.map((allP) => allP.name).includes(subSP)
            )) && (
            <Accordion
              key={section.id}
              defaultOpen={true}
              accordionButton={
                <div className="flex items-center text-left bg-red px-3 text-white rounded">
                  <h3 className="uppercase text-20 font-bold">
                    {t(section.title)}
                  </h3>
                  <UpChevronSVG className="w-3 ml-auto duration-300 ui-not-open:rotate-180" />
                </div>
              }
            >
              <ul className="flex flex-col gap-y-4 pl-5 mb-4">
                {section.sub_sections.map((subsection) => {
                  const Icons = subsection.icon;
                  return (
                    (!subsection.permissions ||
                      subsection.permissions?.some((subSP) =>
                        user.all_permissions
                          .map((allP) => allP.name)
                          .includes(subSP)
                      )) && (
                      <Fragment key={subsection.id}>
                        <NavLink
                          style={({ isActive }) =>
                            isActive && subsection.link
                              ? { color: "red" }
                              : undefined
                          }
                          to={subsection.link ? subsection.link : ""}
                          onClick={(e) =>
                            !subsection.link && e.preventDefault()
                          }
                          className={
                            subsection.link ? "" : "pointer-events-none"
                          }
                          end
                        >
                          <li className="flex gap-x-3.5 hover:text-red">
                            <Icons />
                            {t(subsection.title)}
                          </li>
                        </NavLink>
                        {subsection.sub_section_categories && (
                          <ul className="list-disc ml-6 flex flex-col gap-4">
                            {subsection.sub_section_categories?.map(
                              (category) =>
                                (!category.permissions ||
                                  category.permissions?.every((catP) =>
                                    user.all_permissions
                                      .map((allP) => allP.name)
                                      .includes(catP)
                                  )) && (
                                  <NavLink
                                    style={({ isActive }) =>
                                      isActive && category.link
                                        ? { color: "red" }
                                        : undefined
                                    }
                                    to={category.link ? category.link : ""}
                                    className={
                                      category.link ? "" : "pointer-events-none"
                                    }
                                    key={category.id}
                                    onClick={(e) => {
                                      if (!category.link) {
                                        e.preventDefault();
                                      } else {
                                        window.scrollTo({
                                          top: 0,
                                          left: 0,
                                          behavior: "smooth",
                                        });
                                      }
                                    }}
                                  >
                                    <li className="hover:text-red">
                                      {t(category.title)}
                                    </li>
                                  </NavLink>
                                )
                            )}
                          </ul>
                        )}
                      </Fragment>
                    )
                  );
                })}
              </ul>
            </Accordion>
          )
        );
      })}
    </div>
  );
};

export default SidebarSections;
