import { SyntheticEvent, useEffect, useState } from "react";
import t from "src/hooks/useTranslate";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "src/components/Header/Header";
import UsersFiles from "src/components/Users/UsersFiles";
import UsersForm from "src/components/Users/UsersForm";
import UsersPassword from "src/components/Users/UsersPassword";
import UsersPermissions from "src/components/Users/UsersPermissions";
import { UsersStepsData } from "src/data/create_users_steps_data";
import { EUsersCrudSteps } from "src/enums/e_users_crud_steps";
import { dataURLtoFile } from "src/helper/dataUrlToFile";
import { validateEmail, validateName } from "src/helper/validation";
import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import { useGetGroupAndPermissionsQuery } from "src/services/groupAndPermissionsService";
import {
  useCreateUserMutation,
  useUpdateUserPermissionsMutation,
  useUpdateUserMutation,
  useDeleteUserFileMutation,
} from "src/services/usersService";
import { successToast } from "src/store/Settings/toastStore";
import { setNewUser, setPermissions } from "src/store/Users/usersStore";
import { ITabStep } from "src/types/Common/i_tab_step";
import { setPermissions as setPermissionStore } from "src/store/Settings/permissionsStore";

const UsersCreate = () => {
  const [currentStep, setCurrentStep] = useState(EUsersCrudSteps.form);
  const newUserData = useAppSelector((state) => state.users.newUser);
  const userForm = useAppSelector((state) => state.users.users_form);
  const navigate = useNavigate();
  const visitedSteps = useAppSelector((state) => state.users.visitedSteps);
  const { data: groupAndPermissions } = useGetGroupAndPermissionsQuery("");
  const dispatch = useAppDispatch();
  const users_permissions = useAppSelector(
    (state) => state.users.users_permissions
  );

  useEffect(() => {
    users_permissions.child.length > 0 &&
      dispatch(
        setPermissionStore({
          exist: [],
          data: users_permissions.child,
        })
      );
  }, [users_permissions]);

  const [
    createUserForm,
    {
      data: usersFormData,
      isLoading: isUsersFormCreating,
      isSuccess: isUsersFormCreated,
      error: userCreateError,
      isError: isUserCreateError
    },
  ] = useCreateUserMutation();

  const [
    updateUserForm,
    { isLoading: isUserFormUpdating, isSuccess: isUserFormUpdated },
  ] = useUpdateUserMutation();

  const [
    setUsersPermission,
    {
      isLoading: isUsersPermissionCreating,
      isSuccess: isUsersPermissionCreated,
    },
  ] = useUpdateUserPermissionsMutation();

  const renderSwitch = () => {
    switch (currentStep) {
      case EUsersCrudSteps.form:
        return (
          <UsersForm
            userForm={userForm}
            isLoading={isUsersFormCreating || isUserFormUpdating}
            onSubmit={onCreateForm}
          />
        );
      case EUsersCrudSteps.permissions:
        return (
          <UsersPermissions
            isLoading={isUserFormUpdated || isUsersPermissionCreating}
            onSubmit={(allSelected: number[]) =>
              onCreatePermissions(allSelected)
            }
          />
        );
      default:
        return;
    }
  };

  const onCreateForm = async (e: SyntheticEvent) => {
    e.preventDefault();
    let formData = new FormData();
    for (const key in userForm) {
      if (key === "avatar") continue;
      formData.append(key, userForm[key]);
    }

    const avatar = userForm.avatar && dataURLtoFile(userForm.avatar, "image");
    avatar ? formData.append("avatar", avatar) : formData.delete("avatar");

    if (Object.keys(newUserData).length === 0) {
      return await createUserForm(formData);
    }
    const body = {
      id: newUserData.id,
      data: formData,
    };
    await updateUserForm(body);
  };

  useEffect(() => {
    if (isUserCreateError) {
      //@ts-ignore
      dispatch(errorToast(userCreateError?.data.message));
    }
  }, [isUserCreateError]);

  const onCreatePermissions = async (allSelected: number[]) => {
    await setUsersPermission({
      id: newUserData.id,
      permission: allSelected.filter((permission: any) => permission !== 0),
    });
  };

  useEffect(() => {
    if (isUsersFormCreated) {
      dispatch(setNewUser(usersFormData.user));
      setCurrentStep(EUsersCrudSteps.permissions);
    }
    if (isUserFormUpdated) {
      setCurrentStep(EUsersCrudSteps.permissions);
    }
    if (isUsersPermissionCreated) {
      dispatch(successToast("İstifadəçi əlavə edildi."));
      navigate("/users");
    }
  }, [isUsersFormCreated, isUserFormUpdated, isUsersPermissionCreated]);

  useEffect(() => {
    groupAndPermissions && dispatch(setPermissions(groupAndPermissions));
  }, [groupAndPermissions]);

  return (
    <>
      <Header title={t("create_users")} backLink="/users" />
      <div className="relative py-6 bg-white dark:bg-lightBlack rounded-xl mt-6 drop-shadow-lg">
        <ul className="flex items-center gap-x-4 px-4 pb-6 border-b border-solid border-b-gray">
          {UsersStepsData.slice(0, 2).map((step: ITabStep) => {
            const Icons = step.icon;
            return (
              <li
                key={step.id}
                className={`flex items-center gap-x-2 px-4 py-2 rounded ${
                  step.id === currentStep
                    ? "bg-light text-darkBlue dark:bg-softBlack dark:text-white"
                    : "font-medium text-gray dark:text-white"
                } ${visitedSteps.includes(step.id) ? "cursor-pointer" : ""}`}
                onClick={() =>
                  visitedSteps.includes(step.id) && setCurrentStep(step.id)
                }
              >
                <Icons />
                {t(step.title)}
              </li>
            );
          })}
        </ul>
        <div className="px-4 pt-6">{renderSwitch()}</div>
      </div>
    </>
  );
};

export default UsersCreate;
