import React, { FC, useEffect, useState } from 'react'
import { EPermissions } from 'src/enums/e_permissions';
import { useAppSelector } from './redux';

const usePermission = (permissions: EPermissions[], atLeast: boolean = false) => {
    const me = useAppSelector((state) => state.profile.user);
    const [check, setCheck] = useState<boolean>()

    useEffect(() => {
        atLeast ?  setCheck(permissions.some((catP) =>
            me.all_permissions
                .map((allP) => allP.name)
                .includes(catP))) :
             setCheck(permissions.every((catP) =>
                me.all_permissions
                    .map((allP) => allP.name)
                    .includes(catP)))

    }, [me, check])


    return check
}

export default usePermission