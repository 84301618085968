import React, { useEffect, useState } from "react";
import MashinalAppNotificationStatisticsTable from "src/components/Finance/MashinAlApp/MashinalAppNotificationStatisticsTable";
import Header from "src/components/Header/Header";
import t from "src/hooks/useTranslate";
import { useMashinalAppNotificationStatisticsQuery } from "src/services/financeService";

type Props = {};

const MashinalAppNotificationStatistics = (props: Props) => {
  const [params, setParams] = useState("");
  const {
    data: statistics,
    refetch: statisticsRefetch,
    isFetching: statisticsLoading,
  } = useMashinalAppNotificationStatisticsQuery(params);

  useEffect(() => {
    statisticsRefetch();
  }, [params]);

  return (
    <>
      <Header title={t("Mashin.al APP")} />
      <MashinalAppNotificationStatisticsTable
        data={statistics}
        isLoading={statisticsLoading}
        setParams={setParams}
      />
    </>
  );
};

export default MashinalAppNotificationStatistics;
