import LineChart from "src/components/Common/Charts/LineChart";
 import t from "src/hooks/useTranslate";

const PublishedAnnouncementsChart = ({title, data, isLoading}) => {

  return (
    <div className="relative h-[460px] px-4 pb-7 w-[calc(55%-12px)] bg-white dark:bg-lightBlack rounded-xl mt-6 drop-shadow-lg">
      <div className="bg-darkBlue dark:bg-softBlack h-[400px] pt-12 pb-8 rounded-xl overflow-hidden -translate-y-6">
        <LineChart data={data} />
      </div>
      <p className="text-16 font-bold dark:text-white">{title}</p>
    </div>
  );
};

export default PublishedAnnouncementsChart;
