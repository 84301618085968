import { useState } from "react";
import t from "src/hooks/useTranslate";
import { ReactComponent as CarFrontSVG } from "src/assets/vectors/car_front.svg";
import { ReactComponent as MotocycleSVG } from "src/assets/vectors/motocycle.svg";
import { ReactComponent as SettingsComplexSVG } from "src/assets/vectors/settings_complex.svg";
import { ReactComponent as RegistrationMarkSVG } from "src/assets/vectors/registration_mark.svg";
import { ReactComponent as UserSVG } from "src/assets/vectors/user.svg";
import { ReactComponent as InfoSVG } from "src/assets/vectors/info.svg";
import Shimmer from "../Common/Shimmer";

const TopPublished = ({ title, data, isLoading, setParams }) => {
  const [selectedTab, setSelectedTab] = useState(1);

  const tabs = [
    {
      id: 1,
      name: "day",
    },
    {
      id: 2,
      name: "week",
    },
    {
      id: 3,
      name: "month",
    },
  ];

  return (
    <div className="relative flex flex-col bg-white h-[460px] px-4 dark:bg-lightBlack w-[calc(45%-12px)] rounded-xl mt-6 pb-6 drop-shadow-lg">
      <div className="bg-red rounded-xl -translate-y-6">
        <div className="px-6 py-7 flex justify-between">
          <div className="space-y-2">
            <div className="flex">
              {/* {tabs.map((tab) => (
                <button
                  key={tab.id}
                  className={`text-light mr-4 ${
                    selectedTab === tab.id
                      ? "underline underline-offset-2 font-semibold text-white"
                      : "font-medium"
                  }`}
                  onClick={() => {
                    setSelectedTab(tab.id);
                    setParams(`?date=${tab.name}`);
                  }}
                >
                  {t(tab.name)}
                </button>
              ))} */}
            </div>
            <p className="text-white text-20 font-bold">{title}</p>
          </div>
          <div className="flex items-center gap-x-6 text-white">
            <span className="items-center gap-x-3">
              <CarFrontSVG className="w-9 h-9 mx-auto" />
              <p className="text-center text-20 font-bold mt-1">
                {data?.car ?? 0}
              </p>
            </span>
            <span className="items-center gap-x-3">
              <MotocycleSVG className="w-9 h-9 mx-auto" />
              <p className="text-center text-20 font-bold mt-1">
                {data?.moto ?? 0}
              </p>
            </span>
            <span className="items-center gap-x-3">
              <SettingsComplexSVG className="w-9 h-9 mx-auto" />
              <p className="text-center text-20 font-bold mt-1">
                {data?.parts ?? 0}
              </p>
            </span>
            <span className="items-center gap-x-3">
              <RegistrationMarkSVG className="w-9 h-9 mx-auto" />
              <p className="text-center text-20 font-bold mt-1">
                {data?.registration_marks ?? 0}
              </p>
            </span>
          </div>
        </div>
      </div>
      <ul className="overflow-auto space-y-4 pr-2">
        {data && !isLoading ? (
          data.data.length > 0 ? (
            data.data.map((user) => (
              <li
                className="flex gap-x-2 items-center dark:text-white"
                key={user.user_id}
              >
                <UserSVG /> {user.user_name}{" "}
                <span className="ml-auto">
                  {user.count + " " + t("announcement")}
                </span>
              </li>
            ))
          ) : (
            <div className="w-full relative top-1/2 -translate-y-1/2 p-4 flex items-center justify-center">
              <div className="w-fit flex items-center gap-x-3 p-4 rounded-xl bg-light dark:bg-softBlack text-gray dark:text-white">
                <InfoSVG />
                <p>Göstəriləcək hər hansı bir məlumat yoxdur</p>
              </div>
            </div>
          )
        ) : (
          [...Array(5)].map((_, i) => <Shimmer className="h-5" key={i} />)
        )}
      </ul>
    </div>
  );
};

export default TopPublished;
