import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { IAnnouncement } from 'src/types/i_announcement'

const announcement_form = {
    car_number: "",
    vin: "",
    link: "",
    mashin_link: "",
    turbo_link: "",
    comment: "",
    announce_type: 1,
    vehicle: {
        city: "",
        model: "",
        ban_type: "",
        mileage: "",
        engine_capacity: "",
        year: "",
        engine: "",
        engine_power: "",
        transmission: "",
        gearing: "",
        color: "",
        barter: 0,
        credit: 0,
        price: "",
    }
}

const initialState: { announcement_form: IAnnouncement, announcement_filter: any } = {
    announcement_form,
    announcement_filter: {
        id: "",
        link: "",
        car_number: "",
        vin: "",
        phone: "",
        status: 0,
        announce_type: 0,
        date: "",
        user_id: 0
    }
}

export const announcementSlice = createSlice({
    name: 'announcement',
    initialState,
    reducers: {
        setForm: (state, action: PayloadAction<IAnnouncement>) => {
            state.announcement_form = action.payload
        },
        setAllInputs: (state, action: PayloadAction<any>) => {
            //@ts-ignore
            state[action.payload.key as keyof typeof state] = {
                //@ts-ignore
                ...state[action.payload.key as keyof typeof state],
                [action.payload.name]: action.payload.value,
            };
        },
        resetForm: (state) => {
            state.announcement_form = announcement_form;
        },
        resetFilter: (state) => {
            state.announcement_filter = {
                id: "",
                link: "",
                car_number: "",
                vin: "",
                phone: "",
                status: 0,
                announce_type: 0,
                date: "",
                user_id: 0
            };
        }
    },
})

export const { setForm, setAllInputs, resetForm, resetFilter } = announcementSlice.actions

export default announcementSlice.reducer
