import { useNavigate } from "react-router-dom";
import { EPermissions } from "src/enums/e_permissions";
import usePermission from "src/hooks/usePermission";

const HasPermission = ({ viewPermissions, atLeast = false, children }) => {
  const view = usePermission(viewPermissions, atLeast);
  const navigate = useNavigate();

  return view != undefined && (view ? children : navigate("/requests"));
};

export default HasPermission;
