import React from "react";
import { useNavigate } from "react-router-dom";
import { EGender } from "src/enums/e_gender";
import t from "src/hooks/useTranslate";
import Button from "../Common/Inputs/Button";

const RequestProfileInfo = ({ data }) => {
  const navigate = useNavigate();
  return (
    <div className="min-w-[256px] px-4 py-6 border-r border-solid border-gray dark:text-white">
      <div className="h-full flex flex-col gap-y-6">
        <div className="space-y-4">
          <p className="text-16 font-bold">{data?.author.full_name}</p>
          <div className="w-28 h-28 rounded-full overflow-hidden">
            {data && (
              <img
                src={
                  data.author.avatar
                    ? data.author.avatar
                    : data.author.gender === EGender.male
                    ? "/assets/images/male_avatar.png"
                    : "/assets/images/female_avatar.png"
                }
                className="w-full h-full object-cover"
                alt={data.author.full_name}
              />
            )}
          </div>
        </div>

        <div className="space-y-2">
          <p className="font-semibold">{t("job_position")}:</p>
          <p>{data?.author.position}</p>
        </div>
        <div className="space-y-2">
          <p className="font-semibold">{t("email")}:</p>
          <p>{data?.author.email}</p>
        </div>
        <div className="space-y-2">
          <p className="font-semibold">{t("contacts")}:</p>
          <p>0{data?.author.phone}</p>
        </div>
        {data?.type === 1 && <div className="py-4 px-3 !mt-auto border border-solid border-gray dark:border-light rounded space-y-4">
          <div className="space-y-2">
            <p className="font-semibold">{t("client")}:</p>
            <p>{data?.client.full_name}</p>
          </div>
          <div className="space-y-2">
            <p className="font-semibold">{t("contacts")}:</p>
            <p>0{data?.client.phone}</p>
          </div>
          <div className="space-y-2">
            <p className="font-semibold">{t("email")}:</p>
            <p>{data?.client.email || "-----"}</p>
          </div>
        </div>}
      </div>
    </div>
  );
};

export default RequestProfileInfo;
