import { useRef, useState, useEffect } from "react";
import * as echarts from "echarts";
import useEventListener from "src/hooks/useEvenetLisener";

const EChart = ({ option }) => {
  let chart = useRef(null);
  let [chartEl, setChartEl] = useState(chart);

  useEventListener(window, "resize", () => chartEl.resize());
  useEffect(() => {
      if (!chartEl.current) {
        chartEl.setOption(option);
      } else {
        setChartEl(echarts.init(chart.current));
      }
  }, [option, chartEl]);

  return <div ref={chart} className="w-full h-full"></div>;
};

export default EChart;
