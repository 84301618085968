import React, { useState } from "react";
import Popover from "./Popover";
import { ReactComponent as CalendarSVG } from "src/assets/vectors/calendar.svg";
import Calendar from "react-calendar";
import t from "src/hooks/useTranslate";
import { ddmmyyyy } from "src/helper/getFormattedDate";
import months from "src/data/months.json";
import weekdays from "src/data/weekdays_data.json";

const CalendarPopover = ({ onChange, minDate, maxDate, value }) => {
  const [date, setDate] = useState(new Date());
  const ref = React.createRef<HTMLButtonElement>();

  return (
    <Popover
      className="absolute top-[calc(100%+16px)] -right-4 bg-white px-4 py-5 rounded-xl drop-shadow after:absolute after:right-4 after:-top-1.5 after:w-3 after:h-3 after:bg-white after:rotate-45 "
      ref={ref}
      button={
        <div className="flex items-center justify-center h-10 z-10 rounded px-6 bg-light dark:bg-softBlack text-darkBlue text-12 font-medium">
          <p className="mr-4 whitespace-nowrap dark:text-white">
            {`${t("current_day")} ( ${value} )`}
          </p>
          <CalendarSVG className="text-gray dark:text-white" />
        </div>
      }
    >
      <Calendar
        minDate={minDate}
        maxDate={maxDate}
        onChange={(e) => {
          onChange(ddmmyyyy(e));
          setDate(e);
          ref.current!.click();
        }}
        value={date}
        navigationLabel={({ date, label, view }) => {
          if (view === "month") {
            const monthName = months.map((month) => t(month.name))[
              date.getMonth()
            ];
            return monthName;
          } else {
            return label
          }
        }}
        formatShortWeekday={(locale, date) => {
          const weekday = date.getDay() == 0 ? t(weekdays[6].name) :  t(weekdays[date.getDay() - 1].name)
          return weekday.slice(0, 3)
        }}
        locale="az"
      />
    </Popover>
  );
};

export default CalendarPopover;
