import { ChangeEvent, FC, useCallback, useEffect, useState } from "react";
import ReactInputMask from "react-input-mask";
import { IBaseSelect } from "src/types/Common/i_base_select";
import { IInput } from "src/types/Common/i_input";
import Select from "../Select";

const InputPhone: FC<IInput> = ({
  label,
  className,
  validpattern,
  onChange,
  isSelectOpened,
  placeholder = "məs. 000-00-00",
  wideOpenSelect = false,
  defaultPrefix,
  isClear,
  value = "",
  children,
  ...inputProps
}) => {
  const maskChars = {
    "#": "[0-9]",
  };
  const [error, setError] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [prefix, setPrefix] = useState(!defaultPrefix ? "" : "012");
  const [phone, setPhone] = useState("");

  const blurHandler = useCallback(() => {
    setDirty(true);
  }, []);

  useEffect(() => {
    if (value) {
      setPrefix("0" + value.toString().substring(0, 2));
      setPhone(value.toString().substring(2));
    }
  }, [value]);

  useEffect(() => {
    validpattern?.test((prefix + phone).substring(1).replaceAll("-", ""))
      ? setError(false)
      : setError(true);
    if (prefix && phone) {
      if (
        Number((prefix.substring(1) + phone).replace(/\D/g, "")) == 0 ||
        prefix == "-"
      ) {
        setPrefix("");
        setPhone("");
        setError(false);
        // @ts-ignore
        onChange?.("");
      } else {
        onChange?.(
          Number(
            (prefix.substring(1) + phone).replace(/\D/g, "")
          ) as unknown as ChangeEvent<HTMLInputElement>
        );
      }
    }
  }, [prefix, phone]);

  useEffect(() => {
    if (isClear) {
      setPrefix("");
      setPhone("");
      setError(false);
      setDirty(false);
    }
  }, [isClear]);

  return (
    <div>
      {label && <p className="mb-2 dark:text-white">{label}</p>}
      <div
        className={[
          `flex pr-4 border h-10.5 border-solid rounded bg-white dark:bg-softBlack ${
            validpattern && error && dirty ? "border-red" : "border-gray"
          }`,
          className,
        ].join(" ")}
      >
        <div className="min-w-[80px] [&_div]:h-full [&_button]:h-full [&_button]:!border-none [&_button]:!bg-transparent bg-white rounded-l dark:bg-softBlack [&_ul]:!w-min [&_ul]:!px-3">
          <Select
            isSelectOpened={(val) => isSelectOpened?.(val)}
            data={[
              { id: 0, name: "-" },
              { id: 1, name: "010" },
              { id: 2, name: "012" },
              { id: 3, name: "050" },
              { id: 4, name: "051" },
              { id: 5, name: "055" },
              { id: 6, name: "060" },
              { id: 7, name: "070" },
              { id: 8, name: "077" },
              { id: 9, name: "099" },
            ]}
            optionsClassName={wideOpenSelect ? "!max-h-[unset]" : ""}
            defaultTitle={
              value
                ? "0" + value.toString().substring(0, 2)
                : !defaultPrefix
                ? "-"
                : "012"
            }
            isReset={isClear}
            onChange={(e) => setPrefix(e.name)}
          />
        </div>
        <ReactInputMask
          {...inputProps}
          mask="###-##-##"
          className={`w-full border-l border-solid pl-4 bg-white dark:bg-softBlack dark:text-white ${
            validpattern && error && dirty ? "border-red" : "border-gray"
          }`}
          //@ts-ignore
          maskChar="_"
           //@ts-ignore
          formatChars={maskChars}
          placeholder={placeholder}
          value={phone}
          onBlur={blurHandler}
          onChange={(e) => setPhone(e.target.value.replaceAll("-", ""))}
        />
        {children}
      </div>
    </div>
  );
};

export default InputPhone;
