import { FC, Fragment, useEffect, useState } from "react";
import { ReactComponent as UpChevronSVG } from "src/assets/vectors/up_chevron.svg";
import { Listbox, Transition } from "@headlessui/react";
import { IBaseSelect } from "src/types/Common/i_base_select";
import t from "src/hooks/useTranslate";
import { ISelect } from "src/types/Common/i_select";

const Select: FC<ISelect> = ({
  data,
  label,
  defaultTitle,
  isSelectOpened,
  defaultValue,
  optionsClassName = "",
  isReset,
  onChange,
  className,
}) => {
  const [selected, setSelected] = useState({ id: 0, name: "" });
  const [z, setZ] = useState(11);

  useEffect(() => {
    if (defaultValue !== null) {
      typeof defaultValue == "number"
        ? setSelected(data?.find((el) => el.id == defaultValue))
        : setSelected(data?.find((el) => el.name == defaultValue));
    }
  }, [defaultValue]);

  const handler = (e: IBaseSelect) => {
    setSelected(e);
    onChange(e);
  };

  useEffect(() => {
    if (isReset) {
      setSelected({ id: 0, name: "" });
    }
  }, [isReset]);

  return (
    <div className="w-full relative" style={{ zIndex: z }}>
      {label && <p className="mb-2 dark:text-white">{label}</p>}
      <Listbox value={selected} onChange={handler}>
        {({ open }) => {
          setTimeout(() => {
            open ? setZ(12) : setZ(11);
          });
          isSelectOpened?.(open);
          return (
            <div className="relative">
              <Listbox.Button className="relative w-full h-10.5 cursor-pointer bg-white dark:bg-softBlack font-medium py-2 pl-3 pr-10 text-left border border-solid border-gray rounded">
                <span className="block truncate dark:text-white">
                  {t(selected?.name) || defaultTitle}
                </span>
                <span
                  className={`pointer-events-none absolute duration-300 ease-in right-3 flex inset-y-0 items-center ${
                    open ? "-rotate-0" : "rotate-180"
                  }`}
                >
                  <UpChevronSVG className="w-3 h-3 text-darkBlue dark:text-white" />
                </span>
              </Listbox.Button>
              <Transition
                as={Fragment}
                enter="transition ease-in duration-180"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition ease-in duration-180"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options
                  className={[
                    "absolute mt-2 px-4 py-1 rounded-b max-h-60 w-full overflow-auto bg-white dark:bg-softBlack border border-solid border-gray rounded sm:text-sm",
                    optionsClassName,
                  ].join(" ")}
                >
                  {data?.map((obj) => (
                    <Listbox.Option
                      key={obj.id}
                      className={({ active }) =>
                        `relative cursor-pointer text-14 select-none py-2 px-2.5 rounded ${
                          active ? "bg-light dark:bg-lightBlack" : ""
                        }`
                      }
                      value={obj}
                    >
                      {({ selected }) => (
                        <>
                          <span
                            className={`block truncate dark:text-white ${
                              selected ? "font-medium" : "font-normal"
                            }`}
                          >
                            {t(obj.name) +
                              (obj["lastname"]
                                ? " " + obj["lastname"]
                                : obj["price"]
                                ? " - " + obj["price"] + " m"
                                : "")}
                          </span>
                          {/* {selected ? (
                            <span className="absolute inset-y-0 left-0 flex text-amber-600">
                              <UpChevron className="w-3 h-3 text-black" />
                            </span>
                          ) : null} */}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          );
        }}
      </Listbox>
    </div>
  );
};

export default Select;
