import React from "react";
import { Link, useParams } from "react-router-dom";
import Button from "src/components/Common/Inputs/Button";
import InputRangeDatePicker from "src/components/Common/Inputs/InputRangeDatePicker";
import InputSearch from "src/components/Common/Inputs/InputSearch";
import Shimmer from "src/components/Common/Shimmer";
import Header from "src/components/Header/Header";
import RequestChat from "src/components/Requests/RequestChat";
import RequestProfileInfo from "src/components/Requests/RequestProfileInfo";
import { ReactComponent as BackSVG } from "src/assets/vectors/back.svg";
import { EPermissions } from "src/enums/e_permissions";
import usePermission from "src/hooks/usePermission";
import t from "src/hooks/useTranslate";
import { useGetRequestQuery } from "src/services/requestsService";

const RequestDetails = () => {
  const params = useParams();
  const { data: request, isLoading } = useGetRequestQuery(params.requestId);

  return (
    <>
      <Header title={t("requests")} />
      <div className="w-full relative bg-white dark:bg-lightBlack rounded-xl drop-shadow-lg z-10">
        <div className="flex items-center gap-x-4 p-4 border-b border-solid border-gray">
          <Link
            className="w-44 h-10.5 flex justify-center items-center gap-x-2 duration-200 rounded border border-solid border-gray dark:text-white"
            to="/requests"
          >
            <BackSVG />
            {t("return_back")}
          </Link>
        
            <Link
              to="/requests/create"
              className="ml-auto bg-blue text-white !px-20 whitespace-nowrap rounded"
            >
              <p className="flex items-center gap-x-1">
                <span className="text-30">+</span> {t("new_request")}
              </p>
            </Link>
       
        </div>
        <div className="flex">
          {request ? (
            <>
              <RequestProfileInfo data={request.data} />
              <RequestChat data={request.data} isLoading={isLoading} />
            </>
          ) : (
            <div className="w-full p-4 flex gap-x-4">
              <Shimmer className="w-[256px] h-[512px]" />
              <Shimmer className="w-full h-[512px]" />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default RequestDetails;
