import { useEffect, useState } from "react";
import { EAscDesc } from "src/enums/e_asc_desc";
import Pagination from "src/components/Common/Pagination";
import { ReactComponent as UpChevronSVG } from "src/assets/vectors/up_chevron.svg";
import { ReactComponent as InfoSVG } from "src/assets/vectors/info.svg";
import { useAppDispatch } from "src/hooks/redux";
import Button from "../Common/Inputs/Button";
import {
  useGetUsersQuery,
  useSetGroupsToUserMutation,
} from "src/services/usersService";
import { IUser } from "src/types/i_user";
import { useParams } from "react-router-dom";
import Shimmer from "../Common/Shimmer";
 
import t from "src/hooks/useTranslate";
import { successToast } from "src/store/Settings/toastStore";
import MultipleSelect from "../Common/MultipleSelect";
import { useGetGroupAndPermissionsQuery } from "src/services/groupAndPermissionsService";

const GroupsUsers = () => {
  const [params, setParams] = useState(new URLSearchParams());
  const param = useParams();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [orderById, setOrderById] = useState(EAscDesc.asc);
  const [modal, setModal] = useState(false);
  const {
    data: users,
    refetch,
    isFetching: isUsersLoading,
  } = useGetUsersQuery(
    params.toString()
      ? "?" + params.toString() + `&role=${param.groupId}&user_status=1`
      : `?user_status=1&role=${param.groupId}`
  );

  useEffect(() => {
    refetch();
  }, [setPage, setPerPage]);

  return (
    <>
      <div className="w-full min-h-[467px]">
        <table className="w-full">
          <thead>
            <tr className="[&>th]:text-left [&>th]:pb-6 [&>th]:px-2 [&>th]:dark:text-white [&>th]:whitespace-nowrap border-b border-solid border-gray">
              <th
                className="w-16 flex items-center gap-x-1.5 !pl-4 cursor-pointer"
                onClick={() => {
                  setOrderById(
                    orderById == EAscDesc.asc ? EAscDesc.desc : EAscDesc.asc
                  );
                  params.set(
                    "id",
                    orderById == EAscDesc.asc ? EAscDesc.desc : EAscDesc.asc
                  );
                  refetch();
                }}
              >
                ID
                <UpChevronSVG
                  className={`w-3 text-blue ${
                    orderById == EAscDesc.asc ? "rotate-180" : ""
                  }`}
                />
              </th>
              <th className="w-32">{t("name")}</th>
              <th className="w-32">{t("lastname")}</th>
              <th className="w-48">{t("job_position")}</th>
              <th className="w-32">{t("main_group")}</th>
              <th className="!pr-4">{t("other_groups")}</th>
            </tr>
          </thead>
          <tbody>
            {users && !isUsersLoading ? (
              users.data.length > 0 ? users.data.map((user: IUser) => (
                <tr
                  className="relative [&>td]:py-4 [&>td]:px-2 [&>td]:dark:text-white after:absolute after:top-0 after:left-0 after:w-full after:h-[1px] after:bg-gray"
                  key={user.id}
                >
                  <td className="w-10 !pl-4">{user.id}</td>
                  <td>{user.name}</td>
                  <td>{user.lastname}</td>
                  <td>{user.position}</td>
                  <td className="text-red">{user.main_role?.name}</td>
                  <td className="!pr-4">
                    <div className="flex items-center gap-x-4">
                      <ChangeGroupsUser user={user} />
                    </div>
                  </td>
                </tr>
              )) : (
                <div className="w-full absolute inset-0 top-1/3 p-4 flex items-center justify-center">
              <div className="w-fit flex items-center gap-x-3 p-4 rounded-xl bg-light dark:bg-softBlack text-gray dark:text-white">
                <InfoSVG />
                <p>Göstəriləcək hər hansı bir məlumat yoxdur</p>
              </div>
            </div>
              )
            )  : (
              <>
                {[...Array(5)].map((_, i) => (
                  <tr
                    className="[&>td]:py-4 [&>td]:px-2 dark:bg-lightBlack"
                    key={i}
                  >
                    <td className="w-auto">
                      <Shimmer className="h-10.5" />
                    </td>
                    <td className="w-auto">
                      <Shimmer className="h-10.5" />
                    </td>
                    <td className="w-auto">
                      <Shimmer className="h-10.5" />
                    </td>
                    <td className="w-auto">
                      <Shimmer className="h-10.5" />
                    </td>
                    <td className="w-auto">
                      <Shimmer className="h-10.5" />
                    </td>
                    <td className="w-auto">
                      <Shimmer className="h-10.5" />
                    </td>
                  </tr>
                ))}
              </>
            )}
          </tbody>
        </table>
      </div>
      {users?.meta.total > perPage && (
        <div className="px-4 pt-4">
          <Pagination
            page={Math.ceil(users?.meta.total / perPage)}
            total={users?.meta.total}
            perPage={perPage}
            pageChange={(e) => {
              setPage(e + 1);
              params.set("page", (e + 1).toString());
            }}
            setPerPage={(val) => {
              setPerPage(val);
              params.set("per_page", val.toString());
            }}
          />
          
        </div>
      )}
    </>
  );
};

export default GroupsUsers;

const ChangeGroupsUser = ({ user }) => {
  const dispatch = useAppDispatch();
  const { data: groupAndPermissions } = useGetGroupAndPermissionsQuery("");
  const [groupsToUserData, setGroupsToUserData] = useState({
    id: 0,
    roles: [],
  });
  const [
    setGroupsToUser,
    { isLoading: isUsersGroupLoading, isSuccess: isUserGroupLoaded },
  ] = useSetGroupsToUserMutation();

  const onSetGroupsToUser = async () => {
    await setGroupsToUser(groupsToUserData);
  };

  useEffect(() => {
    if (isUserGroupLoaded) {
      dispatch(successToast("İstifadəçinin grupları yeniləndi"));
    }
  }, [isUserGroupLoaded]);
  return (
    <>
      <MultipleSelect
        defaultValue={user.roles}
        disabledId={user.main_role.id}
        data={groupAndPermissions?.roles}
        onChange={(groups) =>
          setGroupsToUserData({
            id: user.id,
            roles: groups
              .filter((group) => group.id !== user.main_role.id)
              .map((gr) => gr.id),
          })
        }
      />
      <Button
        className="w-32 bg-blue text-white"
        onClick={() => onSetGroupsToUser()}
        isLoading={isUsersGroupLoading}
      >
        {t("submit")}
      </Button>
    </>
  );
};
