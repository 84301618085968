import React, { memo } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import { Bar } from "react-chartjs-2";
import { faker } from "@faker-js/faker";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  plugins: {
    title: {
      display: false,
      text: "Chart.js Bar Chart - Stacked",
    },
    legend: {
      display: false, // Hide the legend with labels and colors
    },
  },
  responsive: true,

  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
};

const StackedBar = ({ chartData }) => {
  const labels = chartData.map((item) => item.date);
  const dataSetArr = chartData.map((item) => {
    const obj = { min: 0, max: 0 };
    obj.min = item.answered;
    obj.max = item.no_answer;

    return obj;
  });

  const data = {
    labels,
    datasets: [
      {
        data: chartData.map((item) => item.no_answer),
        backgroundColor: "#64BD79",
      },
      {
        data: chartData.map((item) => item.answered),
        backgroundColor: "#39CEF3",
      },
    ],
  };
  return <Bar options={options} data={data} />;
};

export default memo(StackedBar);
