import { ReactComponent as XSVG } from "src/assets/vectors/x.svg";
import { ReactComponent as PaperclipSVG } from "src/assets/vectors/paperclip.svg";
import t from "src/hooks/useTranslate";

const RequestAttachmentsModal = ({ data, setModal }) => {
  return (
    <>
      <div className="flex items-center justify-between gap-x-4 dark:text-white p-4 border-b border-solid border-gray">
        <p className="text-16 font-bold">{t("files")}</p>
        <XSVG
          className="text-gray cursor-pointer dark:text-white"
          onClick={() => setModal(false)}
        />
      </div>
      <div className="p-4 space-y-4">
        {data.map((file) => (
          
            <div className="flex items-center gap-x-2 text-blue" key={file.uuid}>
              <a
                href={file.original_url}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center gap-x-1 font-medium"
              >
                <PaperclipSVG />
                {file.file_name}
              </a>
            </div>
        ))}
      </div>
    </>
  );
};

export default RequestAttachmentsModal;
