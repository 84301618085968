import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as EditSVG } from "src/assets/vectors/edit.svg";
import { ReactComponent as TrashSVG } from "src/assets/vectors/trash.svg";
import { ReactComponent as XSVG } from "src/assets/vectors/x.svg";
import { EPermissions } from "src/enums/e_permissions";
import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import usePermission from "src/hooks/usePermission";
import t from "src/hooks/useTranslate";
import { useDeleteFaqMutation } from "src/services/faqService";
import { setFaqForSendInput } from "src/store/FAQ/FAQStore";
import { successToast } from "src/store/Settings/toastStore";
import { phonePattern } from "src/utils/validationPatterns";
import Accordion from "../Common/Accordion";
import Button from "../Common/Inputs/Button";
import InputPhone from "../Common/Inputs/InputPhone";
import Modal from "../Common/Modal";

const FAQHeadersSection = ({
  data,
  sendFaq,
  isLoading,
  setSortableDisabled,
}) => {
  const [canOpen, setCanOpen] = useState(false);
  const [modal, setModal] = useState(false);
  const [activeId, setActiveId] = useState(0);
  const [state, setState] = useState({
    phone: "",
    special_message: "",
    reply: 0,
  });
  const canEdit = usePermission([EPermissions.faq_edit]);
  const canDelete = usePermission([EPermissions.faq_delete]);
  const dispatch = useAppDispatch();
  // const state = useAppSelector((state) => state.faq.faq_for_send);
  const onReplyClick = (id) => {
    state.reply === id
      ? dispatch(setFaqForSendInput({ name: "reply", value: 0 }))
      : dispatch(setFaqForSendInput({ name: "reply", value: id }));

    state.reply === id
      ? setState({ ...state, reply: 0 })
      : setState({ ...state, reply: id });
  };

  const [deleteFaq, { isLoading: faqDeleting, isSuccess }] =
    useDeleteFaqMutation();

  const onSubmit = () => {
    if (state.reply !== 0) {
      data.quick_replies.forEach((reply) => {
        if (reply.id === state.reply) {
          sendFaq({ phone: state.phone, reply: reply.reply });
        }
      });
    } else if (state.special_message.length > 0) {
      sendFaq({ phone: state.phone, reply: state.special_message });
    } else {
      sendFaq({ phone: state.phone, reply: data.description });
    }
  };

  const onDeleteFaq = async () => {
    await deleteFaq(activeId);
  };

  useEffect(() => {
    if (isSuccess) {
      dispatch(successToast("FAQ silindi"));
    }
  }, [isSuccess]);

  return (
    <>
      <div className="space-y-4 mt-4">
        <div className="pb-4 border-b border-solid border-gray">
          <Accordion
            overflow={canOpen}
            setClose={false}
            accordionButton={
              <div className="h-10.5 flex items-center justify-between hover:bg-light dark:hover:bg-softBlack gap-x-3 px-4 rounded">
                <p className="w-full text-left font-semibold dark:text-white">
                  {data.title}
                </p>
                <div className="relative w-4 h-4 flex justify-center items-center before:absolute before:inset-x-0 before:h-0.5 before:bg-darkBlue dark:before:bg-white before:rounded after:absolute after:h-full after:w-0.5 after:bg-darkBlue dark:after:bg-white after:rounded after:duration-150 after:ui-open:rotate-90"></div>
              </div>
            }
          >
            <div
              className="pt-4 space-y-4"
              onMouseEnter={() => setSortableDisabled(true)}
              onMouseLeave={() => setSortableDisabled(false)}
            >
              <p className="text-gray dark:text-white whitespace-pre-wrap">{data.description}</p>
              <div className="flex justify-between items-start">
                <div className="flex items-center gap-x-4 ml-auto">
                  {canEdit && (
                    <Link to={`/faq/edit/${data.id}`}>
                      <EditSVG className="text-blue cursor-pointer" />
                    </Link>
                  )}
                  {canDelete && (
                    <TrashSVG
                      className="text-red cursor-pointer"
                      onClick={() => {
                        setActiveId(data.id);
                        setModal(true);
                      }}
                    />
                  )}
                </div>
              </div>
              {data.quick_replies.every((reply) => reply.reply !== "") && (
                <div className="grid grid-cols-3 gap-x-4">
                  {data.quick_replies.map((reply) => (
                    <div
                      className={`flex-1 px-3 py-4 rounded break-all ${
                        reply.reply ? "cursor-pointer" : ""
                      } ${
                        state.reply === reply.id
                          ? "bg-darkBlue text-white dark:bg-dark"
                          : "bg-light text-gray dark:bg-softBlack dark:text-white"
                      }`}
                      key={reply.id}
                      onClick={() => reply.reply && onReplyClick(reply.id)}
                    >
                      {reply.reply}
                    </div>
                  ))}
                </div>
              )}
              <div
                className={`relative h-52 flex flex-col p-4 border border-solid border-gray rounded ${
                  state.reply !== 0
                    ? "after:absolute after:inset-0 after:bg-gray dark:after:bg-black after:opacity-30"
                    : ""
                }`}
              >
                <p className="font-medium mb-3 dark:text-white">Xüsusi mesaj</p>

                <textarea
                  className="flex-1 resize-none dark:bg-transparent dark:text-white"
                  placeholder="Səbəbi yazın"
                  rows={3}
                  maxLength={160}
                  value={state.special_message}
                  onChange={(e) => {
                    setState({ ...state, special_message: e.target.value });
                    dispatch(
                      setFaqForSendInput({
                        name: "special_message",
                        value: e.target.value,
                      })
                    );
                  }}
                />
                <p className="text-gray mt-4">
                  {state.special_message.length}/160
                </p>
              </div>
              <div className="flex items-center gap-x-4">
                <div className="">
                  <InputPhone
                    isSelectOpened={(val: boolean) => setCanOpen(val)}
                    validpattern={phonePattern}
                    placeholder="999-99-99"
                    value={""}
                    isClear={!state.phone}
                    onChange={(e) => {
                      setState({ ...state, phone: String(e) });
                      dispatch(setFaqForSendInput({ name: "phone", value: e }));
                    }}
                  />
                </div>
                <Button
                  className="bg-blue text-white"
                  isLoading={isLoading}
                  onClick={onSubmit}
                >
                  {t("send")}
                </Button>
              </div>
            </div>
          </Accordion>
        </div>
      </div>
      <Modal
        visible={modal}
        setVisible={setModal}
        dialogClassName="min-w-[450px]"
      >
        <div className="flex items-center justify-between gap-x-4 dark:text-white p-4 border-b border-solid border-gray">
          <p className="text-16 font-bold">{t("delete_caution_question")}</p>
          <XSVG
            className="text-gray cursor-pointer dark:text-white"
            onClick={() => setModal(false)}
          />
        </div>
        <div className="p-4">
          <Button
            className="w-full bg-red mt-8 text-white text-16 font-medium"
            isLoading={faqDeleting}
            onClick={onDeleteFaq}
          >
            Sil
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default FAQHeadersSection;
