import { SyntheticEvent } from "react";
import { ReactComponent as XSVG } from "src/assets/vectors/x.svg";
import Input from "src/components/Common/Inputs/Input";
import Select from "src/components/Common/Select";
import { useAppDispatch } from "src/hooks/redux";
import t from "src/hooks/useTranslate";
import { useGetGroupAndPermissionsQuery } from "src/services/groupAndPermissionsService";
import { setAllInputs } from "src/store/Targets/TargetStore";
import Button from "../Common/Inputs/Button";

const TargetFormModal = ({ form, id, onSubmit, setModal, isLoading }) => {
  const { data: groups } = useGetGroupAndPermissionsQuery("");
  const dispatch = useAppDispatch();
  return (
    <>
      <div className="flex items-center justify-between gap-x-4 p-4 border-b border-solid border-gray">
        <p className="text-16 font-bold dark:text-white">Hədəf təyin et</p>
        <XSVG
          className="text-gray cursor-pointer"
          onClick={() => setModal(false)}
        />
      </div>
      <form
        className="space-y-4 p-4"
        onSubmit={(e: SyntheticEvent) => {
          e.preventDefault();
          onSubmit();
        }}
      >
        <Input
          label={t("daily")}
          type="number"
          defaultValue={id != 0 ? form.daily : ""}
          onKeyDown={(e) =>
            !/[0-9]/i.test(e.key) && e.key !== "Backspace" && e.preventDefault()
          }
          onChange={(e) =>
            dispatch(
              setAllInputs({
                key: "daily",
                value: Number(e.target.value),
              })
            )
          }
        />
        <Input
          label={t("monthly")}
          type="number"
          defaultValue={id != 0 ? form.monthly : ""}
          onKeyDown={(e) =>
            !/[0-9]/i.test(e.key) && e.key !== "Backspace" && e.preventDefault()
          }
          onChange={(e) =>
            dispatch(
              setAllInputs({
                key: "monthly",
                value: Number(e.target.value),
              })
            )
          }
        />
        <Select
          label={t("group")}
          data={groups?.roles}
          defaultTitle={t("choose")}
          defaultValue={id != 0 ? form.role_id : ""}
          onChange={(e) =>
            dispatch(
              setAllInputs({
                key: "role_id",
                value: e.id,
              })
            )
          }
        />
        <Button className="w-full bg-green text-white" isLoading={isLoading}>
          {t("submit")}
        </Button>
      </form>
    </>
  );
};

export default TargetFormModal;
