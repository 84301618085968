import Shimmer from "../Common/Shimmer";
import { ReactComponent as EditSVG } from "src/assets/vectors/edit.svg";
import { ReactComponent as TrashSVG } from "src/assets/vectors/trash.svg";
import { ReactComponent as BlockSVG } from "src/assets/vectors/block.svg";
import { ReactComponent as RefreshSVG } from "src/assets/vectors/refresh.svg";
import { ReactComponent as XCircledSVG } from "src/assets/vectors/x_circled.svg";
import t from "src/hooks/useTranslate";
import { useGetOperatorsQuery } from "src/services/operatorsService";
import Pagination from "../Common/Pagination";
import { Link } from "react-router-dom";

import { ChangeEvent, FC, useEffect, useState } from "react";
import { EIsActive } from "src/enums/e_is_active";
import CountdownTimer from "../Common/CountdownTimer";
import { ddmmyyyy, msToHMS } from "src/helper/getFormattedDate";
import usePermission from "src/hooks/usePermission";
import { EPermissions } from "src/enums/e_permissions";
import Checkbox from "../Common/Inputs/Checkbox";
import { useAppSelector } from "src/hooks/redux";
import { EOperatorActivenessStatus } from "src/enums/e_operator_activeness_status";
import Button from "../Common/Inputs/Button";
import IconWithTooltip from "../Common/IconWithTooltip";

const activenessColors = [
  {
    id: 1,
    name: "offline",
    color: "red",
  },
  {
    id: 2,
    name: "online",
    color: "#64BD79",
  },
  {
    id: 3,
    name: "on_break",
    color: "#3A70DD",
  },
  {
    id: 4,
    name: "on_lunch",
    color: "orange",
  },
];

const OperatorsTable: FC<{
  onDelete: (id: number) => void;
  onStatusChange: (id: number) => void;
  onTemporaryStatusChange: (id: number) => void;
}> = ({ onDelete, onStatusChange, onTemporaryStatusChange }) => {
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [isHidden, setIsHidden] = useState(false);
  const [params, setParams] = useState(new URLSearchParams());
  const canEdit = usePermission([
    EPermissions.call_center_edit,
    EPermissions.user_edit,
  ]);
  const canDelete = usePermission([
    EPermissions.call_center_delete,
    EPermissions.user_delete,
  ]);
  const me = useAppSelector((state) => state.profile.user);
  const {
    data: operators,
    refetch,
    isFetching,
  } = useGetOperatorsQuery(params.toString() ? "?" + params.toString() : "");

  useEffect(() => {
    refetch();
  }, [setPage, setPerPage, isHidden]);

  return (
    <div className="relative z-10 bg-white dark:bg-lightBlack rounded-xl mt-6 drop-shadow-lg">
      <div className="w-full  min-h-[467px]">
        <div className="flex gap-x-4 p-4 pb-0">
          <Checkbox
            id={1}
            label={"Gizlədilmişləri göstər"}
            isChecked={isHidden}
            onChange={() => {
              setIsHidden(!isHidden);
              params.set("is_hidden", isHidden ? "0" : "1");
            }}
          />
          <div className="flex items-center justify-center gap-x-4 ml-auto">
            {activenessColors.map((activeness) => (
              <div
                className="relative w-6 h-6 border border-solid border-gray rounded-full cursor-pointer group"
                style={{ backgroundColor: activeness.color }}
                key={activeness.id}
              >
                <p className="hidden absolute top-[calc(100%+12px)] right-0 whitespace-nowrap px-2 py-1 bg-white drop-shadow-lg rounded group-hover:flex">
                  {t(activeness.name)}
                </p>
              </div>
            ))}
          </div>
          <Button className="bg-blue text-white" onClick={refetch}>
            <RefreshSVG className="w-5 h-5 mr-2" /> {t("refresh")}
          </Button>
        </div>
        <table className="min-w-[991px] w-full">
          <thead>
            <tr className="[&>th]:text-left [&>th]:py-4 [&>th]:px-2 [&>th]:dark:text-white [&>th]:whitespace-nowrap border-b border-solid border-gray">
              <th className="w-[10%] !pl-4">ID</th>
              <th className="w-[12%]">{t("name")}</th>
              <th className="w-[14%]">{t("lastname")}</th>
              <th className="w-[14%]">{t("working_hours")}</th>
              <th className="w-[10%]">{t("announcement_count")}</th>
              <th className="w-[20%]">{t("remaining_lunch_time")}</th>
              <th className="w-[20%]">{t("remaining_break_time")}</th>
              <th className="w-[10%] !pr-4">{t("operations")}</th>
            </tr>
          </thead>
          <tbody>
            {operators && !isFetching ? (
              <>
                {operators.data.map((operator: any) => (
                  <tr
                    className="relative [&>td]:py-4 [&>td]:px-2 [&>td]:dark:text-white after:absolute after:top-0 after:left-0 after:w-full after:h-[1px] after:bg-gray after:opacity-50"
                    key={operator.id}
                  >
                    <td className="w-20 !pl-4">{operator.id}</td>
                    <td className="relative">
                      <div
                        className="absolute -left-3 top-1/2 -translate-y-1/2 w-3 h-3 rounded-full"
                        style={{
                          backgroundColor: activenessColors.find(
                            (a) => Number(a.id) - 1 === operator.active
                          ).color,
                        }}
                      ></div>
                      {operator.name}
                    </td>
                    <td>{operator.lastname}</td>
                    <td
                      className={!operator.is_workday.length ? "text-red" : ""}
                    >
                      {operator.is_workday.length
                        ? operator.is_workday.join("-")
                        : t("day_off")}
                    </td>
                    <td>
                      {!operator.is_workday.length
                        ? "-"
                        : operator.invoice_count}
                    </td>
                    <td>{!operator.lunch_time ? "-" : operator.lunch_time}</td>
                    <td>{!operator.break_time ? "-" : operator.break_time}</td>
                    <td className="!pr-4">
                      <div className="flex items-center gap-x-1">
                        {canEdit && (
                          <Link
                            to={`/operators/work-schedule?id=${operator.id}`}
                          >
                            <IconWithTooltip
                              icon={<EditSVG className="text-blue" />}
                              tooltip={t("edit")}
                              onClick={() => null}
                            />
                          </Link>
                        )}
                        {/* {canDelete && (
                          <TrashSVG
                            className="text-red cursor-pointer"
                            onClick={() => onDelete(operator.id)}
                          />
                        )} */}
                        {canEdit &&
                          operator.id !== me.id &&
                          (operator.status == EIsActive.active ? (
                            <IconWithTooltip
                              icon={<BlockSVG className="text-gray" />}
                              tooltip={t("deactivate_account")}
                              onClick={() => onStatusChange(operator.id)}
                            />
                          ) : (
                            <IconWithTooltip
                              icon={<RefreshSVG className="text-gray" />}
                              tooltip={t("account_recovery")}
                              onClick={() => onStatusChange(operator.id)}
                            />
                          ))}
                        {canEdit &&
                          operator.id !== me.id &&
                          (operator.disabled_to_date &&
                          operator.status === EIsActive.deactive ? (
                            <>
                              <CountdownTimer
                                initialMinute={
                                  msToHMS(
                                    new Date(
                                      operator.disabled_to_date
                                    ).getTime() - new Date().getTime()
                                  ).split(":")[1]
                                }
                                initialSeconds={
                                  msToHMS(
                                    new Date(
                                      operator.disabled_to_date
                                    ).getTime() - new Date().getTime()
                                  ).split(":")[2]
                                }
                              >
                                <IconWithTooltip
                                  icon={<XCircledSVG className="text-gray" />}
                                  tooltip={t("account_block_temporary")}
                                  onClick={() =>
                                    onTemporaryStatusChange(operator.id)
                                  }
                                />
                              </CountdownTimer>
                            </>
                          ) : (
                            operator.status === EIsActive.active &&
                            !operator.disabled_to_date && (
                              <IconWithTooltip
                                icon={
                                  <XCircledSVG className="text-darkBlue dark:text-white" />
                                }
                                tooltip={t("account_block_temporary")}
                                onClick={() =>
                                  onTemporaryStatusChange(operator.id)
                                }
                              />
                            )
                          ))}
                      </div>
                    </td>
                  </tr>
                ))}
              </>
            ) : (
              <>
                {[...Array(perPage)].map((_, i) => (
                  <tr
                    className="[&>td]:py-4 [&>td]:px-2 bg-white dark:bg-lightBlack"
                    key={i}
                  >
                    <td className="w-[10%]">
                      <Shimmer className="h-10.5" />
                    </td>
                    <td className="w-[12%]">
                      <Shimmer className="h-10.5" />
                    </td>
                    <td className="w-[14%]">
                      <Shimmer className="h-10.5" />
                    </td>
                    <td className="w-[14%]">
                      <Shimmer className="h-10.5" />
                    </td>
                    <td className="w-[10%]">
                      <Shimmer className="h-10.5" />
                    </td>
                    <td className="w-[20%]">
                      <Shimmer className="h-10.5" />
                    </td>
                    <td className="w-[20%]">
                      <Shimmer className="h-10.5" />
                    </td>
                    <td className="w-[10%]">
                      <Shimmer className="h-10.5" />
                    </td>
                  </tr>
                ))}
              </>
            )}
          </tbody>
        </table>
        {operators?.meta.total > 5 && (
          <div className="px-4 py-4 border-t border-gray">
            <Pagination
              page={Math.ceil(operators?.meta.total / perPage)}
              total={operators?.meta.total}
              perPage={perPage}
              pageChange={(e) => {
                setPage(e + 1);
                params.set("page", (e + 1).toString());
              }}
              setPerPage={(val) => {
                setPerPage(val);
                params.set("per_page", val.toString());
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default OperatorsTable;
