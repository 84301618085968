import { useEffect, Suspense, useState } from "react";
import Toast from "src/components/Common/Toast";
import AppRoutes from "src/routes";
import { useAppDispatch } from "src/hooks/redux";
import { initTheme } from "src/store/Settings/themeStore";
import ScrollToTop from "./components/Common/ScrollToTop";
import { getCookie, setCookie } from "./helper/custom_cookies";
import Pusher from "pusher-js";
import { useUserQuery } from "./services/authService";
import {
  newNotification,
  setNotifications,
} from "./store/Notifications/notificationStore";
import { ENotificationTypes } from "./enums/e_notification_types";
import IsOperatorActiveAlert from "./components/Common/IsOperatorActiveAlert";
import {
  operatorCount,
  operatorCountSetter,
} from "./helper/operatorCountSetter";
import { usePingMutation } from "./services/workingHoursKPIService";
import usePermission from "./hooks/usePermission";
import { EPermissions } from "./enums/e_permissions";
import { ErrorBoundary } from "react-error-boundary";
import ServerError from "./pages/ServerError";
import { Link } from "react-router-dom";

function App() {
  const dispatch = useAppDispatch();
  const {
    data: me,
    refetch,
    isSuccess,
  } = useUserQuery(getCookie("mcrmtkn"), {
    skip: !getCookie("mcrmtkn"),
  });

  useEffect(() => {
    if (isSuccess) {
      const id = me.user.id;
      const pusher = new Pusher("cef8f454e53df461094a", {
        cluster: "ap2",
        // encrypted: true
        authEndpoint: "https://api.crm.al.ventures/broadcasting/auth",
        auth: {
          headers: {
            Authorization: "Bearer " + getCookie("mcrmtkn"),
          },
        },
      });

      const channel = pusher.subscribe(`private-App.Models.User.${id}`);
      channel.bind(
        "Illuminate\\Notifications\\Events\\BroadcastNotificationCreated",
        function (data) {
          if (data.type === ENotificationTypes.refreshNotification) {
            dispatch(setNotifications(data));
          } else {
            dispatch(newNotification(data));
          }
        }
      );
      return () => {
        pusher.unsubscribe(`private-App.Models.User.${id}`);
      };
    }
  }, [isSuccess]);

  return (
    <ErrorBoundary FallbackComponent={ServerError}>
      <Suspense fallback={"Loading..."}>
        <ScrollToTop />
        <div className="h-full w-full">
          <AppRoutes /> 
        </div>
        <Toast />
      </Suspense>
    </ErrorBoundary>
  );
}

export default App;
