import React, {useState} from "react";
import ReactInputMask from "react-input-mask";

const InputCarNumber = ({label, className, validPattern, onChange, value}) => {
    const [error, setError] = useState(false);
    const [dirty, setDirty] = useState(false);

    const blurHandler = (e: any) => {
        setDirty(true);
    };

    const maskChars = {
        "#": "[0-9]",
        $: "[a-zA-Z]",
    };

    return (
        <div className="w-full">
            {label && <p className="mb-2 dark:text-white">{label}</p>}
            <div
                className={[
                    `flex px-4 border h-10.5 border-solid rounded bg-white dark:bg-softBlack ${
                        validPattern && error && dirty ? "border-red" : "border-gray"
                    }`,
                    className,
                ].join(" ")}
            >
                <ReactInputMask
                    mask="##-$$-###"
                    className="w-full uppercase dark:bg-softBlack dark:text-white"
                    //@ts-ignore
                    maskChar="_"
                    tabIndex={-1}
                    onBlur={blurHandler}
                    value={value}
                    //@ts-ignore
                    formatChars={maskChars}
                    placeholder="10-AA-100"
                    onChange={(e) => {
                        validPattern?.test(e.target.value.replaceAll("-", ""))
                            ? setError(false)
                            : setError(true);
                        onChange(e.target.value.replaceAll("-", ""));
                        // (!e.target.value.includes("_") || (e.target.value == "__-__-___" && dirty)) && onChange(e.target.value.replaceAll("-", ""))
                    }}
                />
            </div>
        </div>
    );
};

export default InputCarNumber;
