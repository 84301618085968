import Header from "src/components/Header/Header";
import OperatorsByStatus from "src/components/Reports/OperatorsByStatus";
import OperatorsByTarget from "src/components/Reports/OperatorsByTarget";
import OperatorsByType from "src/components/Reports/OperatorsByType";
import OperatorsCommon from "src/components/Reports/OperatorsCommon";
import t from "src/hooks/useTranslate";

const Reports = () => {
  return (
    <>
      <Header title={t("reports")} />
      <div className="grid grid-cols-2 gap-4">
        <OperatorsCommon />
        <OperatorsByTarget />
        <OperatorsByStatus />
        <OperatorsByType />
      </div>
    </>
  );
};

export default Reports;
