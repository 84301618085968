import { FC } from "react";
import { IShimmer } from "src/types/Common/i_shimmer";

const Shimmer: FC<IShimmer> = ({ className, children }) => {
  return (
    <div
      className={`relative bg-light dark:bg-softBlack opacity-75 overflow-hidden rounded animate-pulse ${className}`}
    >
      {children}
    </div>
  );
};

export default Shimmer;
