import {
  configureStore,
  isRejectedWithValue,
  Middleware,
  MiddlewareAPI,
} from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { authService } from "src/services/authService";
import { groupAndPermissionsService } from "src/services/groupAndPermissionsService";
import { usersService } from "src/services/usersService";
import { timeOffService } from "src/services/timeOffService";
import { vacationService } from "src/services/vacationService";
import { profileService } from "src/services/profileService";
import { workingHoursKPIService } from "src/services/workingHoursKPIService";
import { announcementsService } from "src/services/announcementsService";
import { operatorsService } from "src/services/operatorsService";
import { requestsService } from "src/services/requestsService";
import { reportService } from "src/services/reportService";
import { faqService } from "src/services/faqService";
import themeReducer from "./Settings/themeStore";
import toastReducer from "./Settings/toastStore";
import usersReducer from "./Users/usersStore";
import profileReducer from "./Profile/profileStore";
import groupsReducer from "./Groups/groupsStore";
import permissionsReducer from "./Settings/permissionsStore";
import announcementReducer from "./Announcements/announcementStore";
import operatorsReducer from "./Operators/operatorsStore";
import targetReducer from "./Targets/TargetStore";
import blacklistReducer from "./Blacklist/blacklistStore";
import requestsReducer from "./Requests/RequestsStore";
import faqReducer from "./FAQ/FAQStore";
import notificationReducer from "./Notifications/notificationStore";
import mashinalAppReducer from "./MashinalApp/mashinalAppStore";
import { blacklistService } from "src/services/blacklistService";
import { targetService } from "src/services/targetService";
import { dashboardService } from "src/services/dashboardService";
import { notificationService } from "src/services/notificationService";
import { mashinAlDashboardService } from "src/services/mashinAlDashboardService";
import { financeService } from "src/services/financeService";
import { ringoStatService } from "src/services/ringoStatService";

export const store = configureStore({
  reducer: {
    profile: profileReducer,
    toast: toastReducer,
    permissions: permissionsReducer,
    theme: themeReducer,
    users: usersReducer,
    groups: groupsReducer,
    announcements: announcementReducer,
    operators: operatorsReducer,
    target: targetReducer,
    blacklist: blacklistReducer,
    requests: requestsReducer,
    faq: faqReducer,
    notification: notificationReducer,
    mashinalApp: mashinalAppReducer,
    [authService.reducerPath]: authService.reducer,
    [usersService.reducerPath]: usersService.reducer,
    [timeOffService.reducerPath]: timeOffService.reducer,
    [vacationService.reducerPath]: vacationService.reducer,
    [workingHoursKPIService.reducerPath]: workingHoursKPIService.reducer,
    [profileService.reducerPath]: profileService.reducer,
    [groupAndPermissionsService.reducerPath]:
      groupAndPermissionsService.reducer,
    [announcementsService.reducerPath]: announcementsService.reducer,
    [operatorsService.reducerPath]: operatorsService.reducer,
    [targetService.reducerPath]: targetService.reducer,
    [blacklistService.reducerPath]: blacklistService.reducer,
    [requestsService.reducerPath]: requestsService.reducer,
    [reportService.reducerPath]: reportService.reducer,
    [faqService.reducerPath]: faqService.reducer,
    [dashboardService.reducerPath]: dashboardService.reducer,
    [notificationService.reducerPath]: notificationService.reducer,
    [mashinAlDashboardService.reducerPath]: mashinAlDashboardService.reducer,
    [financeService.reducerPath]: financeService.reducer,
    [ringoStatService.reducerPath]: ringoStatService.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(
      authService.middleware,
      groupAndPermissionsService.middleware,
      usersService.middleware,
      timeOffService.middleware,
      vacationService.middleware,
      workingHoursKPIService.middleware,
      profileService.middleware,
      announcementsService.middleware,
      operatorsService.middleware,
      targetService.middleware,
      blacklistService.middleware,
      requestsService.middleware,
      reportService.middleware,
      faqService.middleware,
      dashboardService.middleware,
      notificationService.middleware,
      mashinAlDashboardService.middleware,
      financeService.middleware,
      ringoStatService.middleware
    ),
});

export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    if (isRejectedWithValue(action)) {
      console.warn("We got a rejected action!", action.error.data.message);
    }

    return next(action);
  };

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
setupListeners(store.dispatch);
