import { ChangeEvent, useEffect, useRef, useState } from "react";
import { EAscDesc } from "src/enums/e_asc_desc";
import Shimmer from "../Common/Shimmer";
import { ReactComponent as UpChevronSVG } from "src/assets/vectors/up_chevron.svg";
import { ReactComponent as MailSVG } from "src/assets/vectors/mail.svg";
import { ddmmyyyy } from "src/helper/getFormattedDate";
import Button from "../Common/Inputs/Button";
import {
  useSendVacationFileMutation,
  useVacationsQuery,
} from "src/services/profileService";
import adminResponseStatus from "src/data/admin_response_status_data.json";
import t from "src/hooks/useTranslate";
import InputFile from "../Common/Inputs/InputFile";
import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import { successToast } from "src/store/Settings/toastStore";
import Pagination from "../Common/Pagination";
import { useLocation } from "react-router-dom";
import { useNotificationDetailQuery } from "src/services/notificationService";
import Modal from "../Common/Modal";
import GetVacationNotificationModal from "./GetVacationNotificationModal";
import { ETheme } from "src/enums/e_theme";

const ProfileVacationTable = ({ data, isLoading, setParams }) => {
  let { state } = useLocation();
  const dispatch = useAppDispatch();
  const [orderById, setOrderById] = useState(EAscDesc.desc);
  const [params, setParamsData] = useState(new URLSearchParams());
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [modal, setModal] = useState(false);
  const [activeId, setActiveId] = useState(0);
  const theme = useAppSelector((state) => state.theme.theme)
  const {
    data: notificationDetail,
    isLoading: notificationDetailLoading,
    isSuccess: notificationDetailLoaded,
  } = useNotificationDetailQuery(state?.notificationId, {
    skip: !state || !state?.notificationId,
  });
  const [
    sendVacationFile,
    { isLoading: vacationFileSending, isSuccess: vacationFileSent },
  ] = useSendVacationFileMutation();

  useEffect(() => {
    if (state?.notificationId) {
      setModal(true);
      window.history.replaceState({}, document.title);
    }
  }, [state]);

  const onSendVacationFile = async (file) => {
    let formData = new FormData();
    formData.append("file", file.value);

    await sendVacationFile({ id: file.id, body: formData });
  };

  useEffect(() => {
    if (vacationFileSent) {
      dispatch(successToast("Fayl göndərildi"));
    }
  }, [vacationFileSent]);

  useEffect(() => {
    setParams("?" + params.toString());
  }, [page, perPage, orderById]);

  return (
    <>
      <div className="w-full relative py-6 bg-white dark:bg-lightBlack rounded-xl mt-2 drop-shadow-lg">
        <div className="w-full overflow-auto min-h-[376px]">
          <table className="min-w-[991px] w-full">
            <thead>
              <tr className="[&>th]:text-left [&>th]:pb-6 [&>th]:px-2 [&>th]:dark:text-white [&>th]:whitespace-nowrap border-b border-solid border-gray">
                <th
                  className="w-20 flex items-center gap-x-1.5 !pl-4 cursor-pointer"
                  onClick={() => {
                    setOrderById(
                      orderById == EAscDesc.asc ? EAscDesc.desc : EAscDesc.asc
                    );
                    params.set(
                      "id",
                      orderById == EAscDesc.asc ? EAscDesc.desc : EAscDesc.asc
                    );
                  }}
                >
                  ID
                  <UpChevronSVG
                    className={`w-3 text-blue ${
                      orderById == EAscDesc.desc ? "rotate-180" : ""
                    }`}
                  />
                </th>
                <th className="w-auto">{t("start_date")}</th>
                <th className="w-auto">{t("end_date")}</th>
                <th className="w-40">{t("day")}</th>
                <th className="w-auto">{t("status")}</th>
                <th className="w-auto">{t("comment")}</th>
                <th className="w-64 !pr-4">{t("scan")}</th>
              </tr>
            </thead>
            <tbody>
              {data && !isLoading ? (
                data.data.map((vacation) => (
                  <tr
                    className={`relative h-20 [&>td]:py-4 [&>td]:px-2 [&>td]:dark:text-white after:absolute after:top-0 after:left-0 after:w-full after:h-[1px] after:bg-gray after:opacity-50 ${
                      vacation.has_notification
                        ? "bg-light dark:bg-softBlack"
                        : ""
                    }`}
                    key={vacation.id}
                  >
                    <td className="w-10 !pl-4">{vacation.id}</td>
                    <td className="relative">
                      {vacation.has_notification && (
                        <MailSVG className="absolute top-1/2 -translate-y-1/2 -left-4 text-red" />
                      )}
                      {ddmmyyyy(new Date(vacation.start_date))}
                    </td>
                    <td>{ddmmyyyy(new Date(vacation.end_date))}</td>
                    <td>+{vacation.work_days + " " + t("day")}</td>
                    <td
                      style={{
                        color: theme == ETheme.light
                        ? adminResponseStatus[vacation.status]["color"]
                        : adminResponseStatus[vacation.status]["darkColor"],
                      }}
                    >
                      {t(adminResponseStatus[vacation.status]["name"])}
                    </td>
                    <td>
                      {vacation.admins?.[vacation.admins.length - 1].reason}
                    </td>
                    <td className="!pr-4">
                      {!vacation.file && vacation.status === 2 && (
                        <FileSending
                          vacationId={vacation.id}
                          onSubmit={(val) => {
                            setActiveId(vacation.id);
                            onSendVacationFile(val);
                          }}
                          isLoading={
                            activeId == vacation.id && vacationFileSending
                          }
                        />
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <>
                  {[...Array(10)].map((_, i) => (
                    <tr
                      className="[&>td]:py-4 [&>td]:px-2 bg-white dark:bg-lightBlack"
                      key={i}
                    >
                      <td className="w-20">
                        <Shimmer className="h-4" />
                      </td>
                      <td className="w-auto">
                        <Shimmer className="h-4" />
                      </td>
                      <td className="w-40">
                        <Shimmer className="h-4" />
                      </td>
                      <td className="w-28">
                        <Shimmer className="h-4" />
                      </td>
                      <td className="w-24">
                        <Shimmer className="h-4" />
                      </td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </table>
        </div>
        {data?.total > 5 && (
          <div className="px-4 mt-4">
            <Pagination
              page={Math.ceil(data?.total / perPage)}
              total={data?.total}
              perPage={perPage}
              pageChange={(e) => {
                setPage(e + 1);
                params.set("page", (e + 1).toString());
              }}
              setPerPage={(val) => {
                setPerPage(val);
                params.set("per_page", val.toString());
              }}
            />
          </div>
        )}
      </div>
      <Modal
        visible={modal}
        setVisible={setModal}
        dialogClassName="min-w-[550px] max-w-[550px]"
      >
        <GetVacationNotificationModal
          data={notificationDetail?.data}
          setModal={setModal}
        />
      </Modal>
    </>
  );
};

export default ProfileVacationTable;

const FileSending = ({ vacationId, onSubmit, isLoading }) => {
  const input = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<any>({});

  return (
    <div className="flex items-center gap-x-2">
      <Button
        className={`w-32 ${
          file.id == vacationId
            ? "bg-green text-white"
            : "border border-solid border-gray text-gray dark:text-white"
        }`}
        onClick={() => input.current!.click()}
      >
        {file.id == vacationId ? t("loaded") : t("choose_file")}
      </Button>

      <input
        type="file"
        ref={input}
        className="hidden"
        onChange={(e) => {
          e.target.files[0] &&
            setFile({ id: vacationId, value: e.target.files[0] });
        }}
      />
      <Button
        className="w-32 bg-blue text-white disabled:bg-gray"
        onClick={() => onSubmit(file)}
        isLoading={isLoading}
        disabled={Object.values(file).length == 0}
      >
        {t("send")}
      </Button>
    </div>
  );
};
