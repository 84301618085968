import React, { useEffect, useState } from "react";
import IndividualForm from "src/components/Finance/Individual/IndividualForm";
import IndividualTable from "src/components/Finance/Individual/IndividualTable";
import Header from "src/components/Header/Header";
import { useAppDispatch } from "src/hooks/redux";
import t from "src/hooks/useTranslate";
import {
  useFinanceIndividualQuery,
  useGetUserInfoByPhoneMutation,
} from "src/services/financeService";
import { errorToast } from "src/store/Settings/toastStore";

const FinanceIndividual = () => {
  const [individualFinanceParams, setIndividualFinanceParams] = useState("");
  const { data, refetch, isFetching } = useFinanceIndividualQuery(
    individualFinanceParams
  );

  const onSubmitIndividualForm = async (query) => {
    if (query.includes("day")) {
      const redirect_url = await fetch(
        `https://mashin.al/api/add-money?${query}`
      ).then((resp) => resp.json());

      window.open(redirect_url.data.redirect_url, "_blank");
    } else {
      window.open(`https://mashin.al/api/add-money?${query}`, "_blank");
    }
  };

  useEffect(() => {
    refetch();
  }, [individualFinanceParams]);

  return (
    <>
      <Header title={t("individual")} />
      <IndividualForm onSubmit={onSubmitIndividualForm} />

      <IndividualTable
        data={data}
        isLoading={isFetching}
        setParams={setIndividualFinanceParams}
      />
    </>
  );
};

export default FinanceIndividual;
