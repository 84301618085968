import t from "src/hooks/useTranslate";

//*****with this number - 10DD111 -  you can check insurance data *****

const AutoHistoryInformations = ({ data }) => {
  return (
    <div className="flex items-start gap-x-4">
      <div className="w-1/2 flex relative py-4 bg-white dark:bg-lightBlack rounded-xl drop-shadow-lg">
        <ul className="flex-1 even:[&>li]:bg-lightGrey  even:dark:[&>li]:bg-softBlack [&>li]:px-4 [&>li]:py-2.5 [&>li]:text-gray dark:[&>li]:text-white">
          <li>{t("brand")}</li>
          <li>{t("car_number")}</li>
          <li>VIN</li>
          <li>{t("color")}</li>
          <li>{t("car_type")}</li>
          <li>{t("car_body_type")}</li>
          <li>{t("year")}</li>
          <li>{t("engine_capacity")}</li>
          <li>ID</li>
        </ul>
        <ul className="flex-[2] [&>li]:px-4 [&>li]:py-2.5 [&>li]:font-semibold [&>li]:text-darkBlue dark:[&>li]:text-white  even:[&>li]:bg-lightGrey  even:dark:[&>li]:bg-softBlack">
          <li>{data.car_info?.marka || t("none")}</li>
          <li>{data.car_info?.carNumber || t("none")}</li>
          <li>{data.car_info?.bodyNumber || t("none")}</li>
          <li>{data.car_info?.color || t("none")}</li>
          <li>{data.car_info?.vehType || t("none")}</li>
          <li>{data.car_info?.vehBodyType || t("none")}</li>
          <li>{data.car_info?.manufactYear || t("none")}</li>
          <li>{data.car_info?.engincapacity || t("none")}</li>
          <li>{data.car_info?.vehUniqueId || t("none")}</li>
        </ul>
      </div>
      <div className="w-1/2 relative space-y-4 bg-white dark:bg-lightBlack rounded-xl drop-shadow-lg">
        <div className="w-full flex py-4">
          <ul className="flex-1 even:[&>li]:bg-lightGrey  even:dark:[&>li]:bg-softBlack even_grey [&>li]:px-4 [&>li]:py-2.5 [&>li]:text-gray dark:[&>li]:text-white">
            <li>ID</li>
            <li>{t("note")}</li>
            <li>{t("insurance")}</li>
          </ul>
          <ul className="flex-[2] even:[&>li]:bg-lightGrey even:dark:[&>li]:bg-softBlack [&>li]:px-4 [&>li]:py-2.5 [&>li]:font-semibold [&>li]:text-darkBlue dark:[&>li]:text-white">
            <li>{data?.details?.id || t("none")}</li>
            <li>{data?.details?.note || t("none")}</li>
            <li>{data?.details?.insurance || t("none")} </li>
          </ul>
        </div>

        {data?.details?.insuranceAccident?.length > 0 &&
          data?.details?.insuranceAccident.map((accident) => (
            <div className="bg-light space-y-4 py-4 my-4" key={accident.id}>
              <p className="font-semibold px-4 text-darkBlue">
                {t("compulsory_insurance_event")}
              </p>
              <div className="flex">
                <div className="w-[70%] flex">
                  <ul className="flex-1 [&>li]:px-4 space-y-4 [&>li]:text-gray">
                    <li>ID</li>
                    <li>{t("note")}</li>
                    <li>{t("car_number")}</li>
                    <li>{t("brand")}</li>
                    <li>{t("date")}</li>
                    <li>{t("damage")}</li>
                  </ul>
                  <ul className="flex-[1] [&>li]:px-4 space-y-4 [&>li]:font-semibold [&>li]:text-darkBlue">
                    <li>{accident.id}</li>
                    <li>{accident.note || t("none")}</li>
                    <li>{accident.carNumber || t("none")}</li>
                    <li>{accident.marka || t("none")}</li>
                    <li>{accident.actionDate || t("none")}</li>
                    <li>{accident.damagePropertyInfo || t("none")}</li>
                  </ul>
                </div>
                <div className="w-[34%]">
                  <img
                    src="/assets/images/accident.png"
                    className="w-full"
                    alt="accident"
                  />
                </div>
              </div>
            </div>
          ))}

        {
          <div className="space-y-3">
            <p className="font-semibold px-4 text-darkBlue dark:text-white">
              {t("penalty")}
            </p>
            <div className="w-full flex">
              <ul className="flex-1 odd:[&>li]:bg-lightGrey odd:dark:[&>li]:bg-softBlack [&>li]:px-4 [&>li]:py-2.5  [&>li]:text-gray dark:[&>li]:text-white">
                <li>{t("penalty_count") || t("none")}</li>
                <li>{t("amount")}</li>
                {/* <li>{t("credit")}</li> */}
                <li>{t("limit")}</li>
                <li>{t("note")}</li>
              </ul>
              <ul className="flex-[2] odd:[&>li]:bg-lightGrey odd:dark:[&>li]:bg-softBlack [&>li]:px-4 [&>li]:py-2.5  [&>li]:font-semibold [&>li]:text-darkBlue dark:[&>li]:text-white">
                <li>{data.details?.penalty?.penaltyCount || t("none")}</li>
                <li>{data.details?.penalty?.amount || t("none")}</li>
                <li>{data.details?.penalty?.limit || t("none")}</li>
                <li>{data.details?.penalty?.note || t("none")}</li>
              </ul>
            </div>
          </div>
        }

        <div className="space-y-3">
          <p className="font-semibold px-4  text-darkBlue dark:text-white">
            {t("technical")}
          </p>
          <div className="w-full flex">
            <ul className="flex-1 odd:[&>li]:bg-lightGrey odd:dark:[&>li]:bg-softBlack [&>li]:px-4 [&>li]:py-2.5 [&>li]:text-gray dark:[&>li]:text-white">
              <li>{t("start_date")}</li>
              <li>{t("end_date")}</li>
              <li>{t("certificate_number")}</li>
              <li>{t("car_number")}</li>
            </ul>
            <ul className="flex-[2] odd:[&>li]:bg-lightGrey odd:dark:[&>li]:bg-softBlack [&>li]:px-4 [&>li]:py-2.5 [&>li]:font-semibold [&>li]:text-darkBlue dark:[&>li]:text-white">
              <li>{data.details?.technical?.beginDate || t("none")}</li>
              <li>{data.details?.technical?.endDate || t("none")}</li>
              <li>{data.details?.technical?.certNumber || t("none")}</li>
              <li>{data.details?.technical?.vehRegNumber || t("none")}</li>
            </ul>
          </div>
        </div>
        <div className="space-y-3 pb-4">
          <p className="font-semibold text-darkBlue dark:text-white px-4">
            {t("vehicle_information")}
          </p>
          <div className="w-full flex">
            <ul className="flex-1 odd:[&>li]:bg-lightGrey odd:dark:[&>li]:bg-softBlack [&>li]:px-4 [&>li]:py-2.5 [&>li]:text-gray dark:[&>li]:text-white">
              <li>{t("status")}</li>
              <li>{t("first_registration_date")}</li>
              <li>{t("registration_count")}</li>
              <li>{t("note")}</li>
            </ul>
            <ul className="flex-[2] odd:[&>li]:bg-lightGrey odd:dark:[&>li]:bg-softBlack [&>li]:px-4 [&>li]:py-2.5 [&>li]:font-semibold [&>li]:text-darkBlue dark:[&>li]:text-white">
              <li>{data.details?.vehicleDetail?.status || t("none")}</li>
              <li>{data.details?.vehicleDetail?.firstRegDate || t("none")}</li>
              <li>{data.details?.vehicleDetail?.regCount || t("none")}</li>
              <li>{data.details?.vehicleDetail?.note || t("none")}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AutoHistoryInformations;
