import { useEffect, useState } from "react";
import UserOperationsTable from "src/components/Finance/UserOperations/UserOperationsTable";
import Header from "src/components/Header/Header";
import t from "src/hooks/useTranslate";
import { useFinanceUserOperationsQuery } from "src/services/financeService";

const FinanceUserOperations = () => {
  const [params, setParams] = useState("");
  const { data, refetch, isFetching } = useFinanceUserOperationsQuery(params);

  useEffect(() => {
    refetch();
  }, [params]);

  return (
    <>
      <Header title={t("user_operations")} />
      <UserOperationsTable
        data={data}
        isLoading={isFetching}
        setParams={setParams}
      />
    </>
  );
};

export default FinanceUserOperations;
