import { FC, useEffect, useState } from "react";
import t from "src/hooks/useTranslate";
import Shimmer from "../Common/Shimmer";
import ChatOption from "./ChatOption";
import MashinAlMessagesSection from "./MashinAlMessagesSection";
import { ReactComponent as InfoSVG } from "src/assets/vectors/info.svg";

type MashinAlChatProps = {
  groups: any;
  messages: any;
  onOpenMessages: (id: number) => void;
  groupsLoading: boolean;
  messagesLoading: boolean;
};

const MashinAlChat: FC<MashinAlChatProps> = ({
  groups,
  messages,
  onOpenMessages,
  groupsLoading,
  messagesLoading,
}) => {
  const [sellerId, setSellerId] = useState(0);
  const [activeId, setActiveId] = useState(0);

  useEffect(() => {
    if (groups && groups.data.length > 0) {
      setSellerId(groups.data[0].announce.seller_id);
      setActiveId(groups.data[0].id);
    }
  }, [groups]);

  return (
    <div className="flex flex-col p-4 gap-y-4 min-h-[376px]">
      <p className="text-16 font-bold dark:text-white">
        {t("incoming_messages")}
      </p>
      {groups && !groupsLoading ? (
        groups.data.length > 0 ? (
          <div className="flex gap-x-6">
            <>
              <div className="flex-[2] space-y-4 h-[676px] overflow-auto">
                {groups.data?.map((group) => (
                  <div
                    onClick={() => {
                      setSellerId(group.announce.seller_id);
                      onOpenMessages(group.id);
                      setActiveId(group.id);
                    }}
                    key={group.id}
                  >
                    <ChatOption data={group} activeId={activeId} />
                  </div>
                ))}
              </div>
              <div className="flex-[3] space-y-4 h-[676px] overflow-auto">
                <MashinAlMessagesSection
                  data={messages}
                  isLoading={messagesLoading}
                  sellerId={sellerId}
                />
              </div>
            </>
          </div>
        ) : (
          <div className="w-full flex-1 p-4 flex items-center justify-center">
            <div className="w-fit flex items-center gap-x-3 -translate-y-1/2 p-4 rounded-xl bg-light dark:bg-softBlack text-gray dark:text-white">
              <InfoSVG />
              <p>Göstəriləcək hər hansı bir məlumat yoxdur</p>
            </div>
          </div>
        )
      ) : (
        <div className="flex gap-x-6">
          <div className="flex-[2] space-y-4">
            {[...Array(5)].map((_, i) => (
              <div className="flex gap-x-4 items-center" key={i}>
                <Shimmer className="min-w-[110px] min-h-[110px] max-w-[110px] max-h-[110px] w-[110px] h-[110px] !rounded-full" />
                <Shimmer className="h-40 w-full" />
              </div>
            ))}
          </div>
          <div className="flex-[3] space-y-4">
            {[...Array(5)].map((_, i) => (
              <Shimmer key={i} className="h-40" />
            ))}
          </div>
        </div>
        // <h1>Hec bir data yoxdu</h1>
      )}
    </div>
  );
};

export default MashinAlChat;
