import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ETheme } from 'src/enums/e_theme'

const initialState: { target_form: any } = {
    target_form: {
        daily: null,
        monthly: null,
        role_id: null
    }
}

export const targetSlice = createSlice({
    name: 'target',
    initialState,
    reducers: {
        setTarget: (state, action: PayloadAction<any>) => {
            for (const key in state.target_form) {
                state.target_form[key] = action.payload[key]
            }
        },
        setAllInputs: (state, action: PayloadAction<any>) => {
            state.target_form[action.payload.key] = action.payload.value
        },
        resetForm: (state) => {
            Object.assign(state.target_form, initialState.target_form);
        }
    },
})

export const { setTarget, setAllInputs, resetForm } = targetSlice.actions

export default targetSlice.reducer