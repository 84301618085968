import { useEffect, useState } from "react";
import { ReactComponent as InfoSVG } from "src/assets/vectors/info.svg";
import {
  ddmmyyyy,
  ddmmyyyy_hhmm,
  ddmmyyyy_hhmmss,
} from "src/helper/getFormattedDate";
import t from "src/hooks/useTranslate";
import Pagination from "../Common/Pagination";
import Shimmer from "../Common/Shimmer";

const LateModeratorsTable = ({ data, isLoading, setParams, date }) => {
  const [params, _] = useState(new URLSearchParams());
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (date) {
      setPage(1);
      params.set("page", "1");
      params.set("date", date);
      setParams("?" + params.toString());
    }
  }, [date]);

  return (
    <div className="min-h-[476px]">
      <table className="min-w-[991px] w-full">
        <thead>
          <tr className="[&>th]:text-left [&>th]:px-2 [&>th]:py-4 [&>th]:dark:text-white [&>th]:whitespace-nowrap border-y border-solid border-gray">
            <th className="w-auto !pl-4">{t("fullname")}</th>
            <th className="w-auto">ID</th>
            <th className="w-auto">{t("announcement")}</th>
            <th className="w-auto">{t("start_date")}</th>
            <th className="w-auto">{t("end_date")}</th>
            <th className="w-auto !pr-4">{t("note")}</th>
          </tr>
        </thead>
        <tbody>
          {data && !isLoading ? (
            data.data?.length > 0 ? (
              data.data?.map((moderator, i) => (
                <tr
                  className="relative [&>td]:py-4 [&>td]:px-2 [&>td]:dark:text-white after:absolute after:top-0 after:left-0 after:w-full after:h-[1px] after:bg-light after:opacity-50"
                  key={i}
                >
                  <td className="!pl-4">{moderator.full_name}</td>
                  <td>
                    {moderator.url ? (
                      <a
                        href={moderator.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue"
                      >
                        {moderator.id}
                      </a>
                    ) : (
                      <p>{moderator.id}</p>
                    )}
                  </td>
                  <td>{moderator.car_name}</td>
                  <td>{moderator.created_at}</td>
                  <td>{moderator.end_at}</td>
                  <td className="!pr-4">{moderator.comment}</td>
                </tr>
              ))
            ) : (
              <div className="w-full absolute top-2/3 -translate-y-2/3 p-4 flex items-center justify-center">
                <div className="w-fit flex items-center gap-x-3 p-4 rounded-xl bg-light dark:bg-softBlack text-gray dark:text-white">
                  <InfoSVG />
                  <p>Göstəriləcək hər hansı bir məlumat yoxdur</p>
                </div>
              </div>
            )
          ) : (
            <>
              {[...Array(10)].map((_, i) => (
                <tr
                  className="[&>td]:py-4 [&>td]:px-2 bg-white dark:bg-lightBlack"
                  key={i}
                >
                  <td className="w-auto">
                    <Shimmer className="h-10.5" />
                  </td>
                  <td className="w-auto">
                    <Shimmer className="h-10.5" />
                  </td>
                  <td className="w-auto">
                    <Shimmer className="h-10.5" />
                  </td>
                  <td className="w-auto">
                    <Shimmer className="h-10.5" />
                  </td>
                  <td className="w-auto">
                    <Shimmer className="h-10.5" />
                  </td>
                  <td className="w-auto">
                    <Shimmer className="h-10.5" />
                  </td>
                </tr>
              ))}
            </>
          )}
        </tbody>
      </table>
      {data?.meta?.total > 5 && (
        <div className="w-full px-4 py-4 border-t border-solid border-gray">
          <Pagination
            page={Math.ceil(data?.meta?.total / perPage)}
            total={data?.meta?.total}
            perPage={perPage}
            pageChange={(e) => {
              setPage(e + 1);
              params.set("page", (e + 1).toString());
              setParams("?" + params.toString());
            }}
            setPerPage={(val) => {
              setPerPage(val);
              params.set("per_page", val.toString());
              setParams("?" + params.toString());
            }}
          />
        </div>
      )}
    </div>
  );
};

export default LateModeratorsTable;
