import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { IProfile } from 'src/types/i_profile'
import { EGender } from 'src/enums/e_gender'
import { ddmmyyyy } from 'src/helper/getFormattedDate'

export interface IGetAPermissionFields {
    start_date: string
    hour: number
    comment: string | number
    comment_text?: string
}

export interface IGetVacationFields {
    start_date: string
    days: number
}

const initialState: { operatorOptions: any, user: IProfile, get_permission_fields: IGetAPermissionFields, get_vacation_fields: IGetVacationFields } = {
    operatorOptions: null,
    user: {
        id: null,
        avatar: "",
        name: "",
        lastname: "",
        birthday: "",
        gender: EGender.unknown,
        old_password: "",
        password: "",
        password_confirmation: "",
        phone: "",
        all_permissions: [],
        full_name: "",
        main_role: { name: "" },
        vacation: null,
        working_hours: [[], [], [], [], [], [], []],
        is_operator: false
    },
    get_permission_fields: {
        start_date: "",
        hour: 0,
        comment: 0
    },
    get_vacation_fields: {
        start_date: "",
        days: 0
    },
}

export const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        setOperatorOptions: (state, action: PayloadAction<any>) => {
            state.operatorOptions = action.payload
        },
        setUser: (state, action: PayloadAction<IProfile>) => {

            for (const key in state.user) {
                state.user = { ...state.user, [key]: action.payload[key as keyof typeof state.user] }
            }
            state.user = {
                ...state.user, birthday: ddmmyyyy(new Date(action.payload.birthday)), old_password: "",
                password: "",
                password_confirmation: "",
            };
        },
        setAvatar: (state, action: PayloadAction<string>) => {
            state.user = { ...state.user, avatar: action.payload };
        },
        setAllInputs: (state, action: PayloadAction<any>) => {
            //@ts-ignore
            state[action.payload.key as keyof typeof state] = {
                ...state[action.payload.key as keyof typeof state],
                [action.payload.name]: action.payload.value.trim(),
            };
        },
        setSelectInput: (
            state,
            action: PayloadAction<{ key: string, name: string; value: string | number }>
        ) => {
            //@ts-ignore
            state[action.payload.key as keyof typeof state] = {
                ...state[action.payload.key as keyof typeof state],
                [action.payload.name]: action.payload.value,
            };
        },
        setCalendarInput: (state, action: PayloadAction<string>) => {
            state.get_permission_fields = { ...state.get_permission_fields, start_date: action.payload };
        },
        setModAllInputs: (state, action: PayloadAction<any>) => {
            //@ts-ignore
            state[action.payload.key as keyof typeof state] = {
                //@ts-ignore
                ...state[action.payload.key as keyof typeof state],
                [action.payload.name]: action.payload.value,
            };
        },
        resetGetPermissionFields: (state) => {
            Object.assign(state.get_permission_fields, initialState.get_permission_fields);
        },
        resetGetVacationFields: (state) => {
            Object.assign(state.get_vacation_fields, initialState.get_vacation_fields);
        },
    },
})

export const { setOperatorOptions, setUser, setAvatar, setAllInputs, setSelectInput, setCalendarInput, setModAllInputs, resetGetPermissionFields, resetGetVacationFields } = profileSlice.actions

export default profileSlice.reducer