import React, {FC, useEffect, useState} from 'react';
import InputPhone from "../Common/Inputs/InputPhone";
import {carNumberRequiredPattern, phonePattern} from "../../utils/validationPatterns";
import t from "../../hooks/useTranslate";
import {setAllInputs, setForm} from "../../store/Announcements/announcementStore";
import Select from "../Common/Select";
import announceTypeData from "../../data/announce_type_data.json";
import InputCarNumber from "../Common/Inputs/InputCarNumber";
import Input from "../Common/Inputs/Input";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {useGetNewAnnouncementMutation, useUpdateNewAnnouncementsMutation, useCallToCustomerMutation} from "../../services/announcementsService";
import newAnnouncementStatusData from "../../data/announcement_new_status_data.json";
import CarInfo from "./CarInfo";
import Button from "../Common/Inputs/Button";
import {useNavigate} from "react-router-dom";
import { ReactComponent as PhoneCallSVG } from "src/assets/vectors/phoneCall.svg";
import {errorToast} from "../../store/Settings/toastStore";

const CommonInfo: FC = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const announcement_form = useAppSelector(
        (state) => state.announcements.announcement_form
    );

    const [getNewAnnouncement] = useGetNewAnnouncementMutation({
        fixedCacheKey: 'announcement'
    });

    const fetchDetails = () => {
        getNewAnnouncement().unwrap().then(({ success, data }) => {
            console.log(success)
            if (success) {
                dispatch(setForm(data));
                navigate(`/announcementsTwo/details`);
            } else {
             dispatch(errorToast("Sənin iş saatın bitib"));
            }
        }).catch(
            console.error
        );
    };

    useEffect(() => {
        fetchDetails();
    }, []);

    const [
        updateNewAnnouncementForm,
        {
            isLoading: isAnnouncementFormUpdating
        },
    ] = useUpdateNewAnnouncementsMutation();

    const handleEdit =  () => {
        const updatedData = {
            car_number: announcement_form.car_number,
            vin: announcement_form.vin,
            status: announcement_form.status,
            id: announcement_form.id,
            comment: announcement_form.comment,
        };
        updateNewAnnouncementForm(updatedData).unwrap().then(() => navigate('..')).catch(console.error);
    }

    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [callToCustomerMutation] = useCallToCustomerMutation();
    const handlePhoneCallClick = () => {
        setConfirmDialogOpen(true);
    };

    const handleConfirmYes = () => {
        setConfirmDialogOpen(false);
        callToCustomerMutation(announcement_form.phone.toString()).unwrap().then(() => {
            console.log("Call to customer success");
        }).catch((error) => {
            console.error("Call to customer error", error);
        });
    };

    const handleConfirmNo = () => {
        console.log("User clicked No");
        setConfirmDialogOpen(false);
    };

    return (
        <>
            <h3 className={"text-16 font-bold dark:text-white p-4"}>{t("commonInfo")}</h3>
            <form className="grid grid-cols-2 gap-4 gap-x-4 p-4 border-b border-solid border-gray">
                <InputPhone
                    className="relative min-w-[260px] text-gray z-20 pointer-events-none after:absolute after:inset-0 after:bg-gray dark:after:bg-black after:opacity-50"
                    validpattern={phonePattern}
                    wideOpenSelect
                    disabled
                    value={announcement_form.phone}
                    label={t("phone") + "*"}
                    onChange={(e) =>
                        dispatch(
                            setAllInputs({
                                key: "announcement_form",
                                name: "phone",
                                value: e,
                            })
                        )
                    }
                    name="phone"
                />
                <Select
                    className={"pointer-events-none after:absolute after:inset-0 after:bg-gray dark:after:bg-black after:opacity-50 "}
                    label={t("type")}
                    data={announceTypeData.slice(1)}
                    defaultTitle={t("choose")}
                    defaultValue={announcement_form.announce_type}
                    onChange={(e) =>
                        dispatch(
                            setAllInputs({
                                key: "announcement_form",
                                name: "announce_type",
                                value: e.name,
                            })
                        )
                    }
                />
                {(announcement_form.announce_type === 1 ||
                    announcement_form.announce_type === 4) && (
                    <div className="">
                        <InputCarNumber
                            label={t("car_number")}
                            className="w-full h-10.5 block border border-solid border-gray rounded uppercase"
                            validPattern={carNumberRequiredPattern}
                            value={announcement_form.car_number}
                            onChange={(e: any) =>
                                dispatch(
                                    setAllInputs({
                                        key: "announcement_form",
                                        name: "car_number",
                                        value: e,
                                    })
                                )
                            }
                        />
                    </div>
                )}
                {announcement_form.announce_type === 1 && (
                    <Input
                        label={"VIN"}
                        className="[&>input]:uppercase"
                        validpattern={/^(?:[A-Za-z0-9]{17})?$/}
                        onChange={(e) =>
                            dispatch(
                                setAllInputs({
                                    key: "announcement_form",
                                    name: "vin",
                                    value: e.target.value.trim(),
                                })
                            )
                        }
                    />
                )}
                <div className={announcement_form.announce_type === 4 ? "" : ""}>
                    <Input
                        label={t("link")}
                        placeholder="https://..."
                        disabled
                        defaultValue={announcement_form.turbo_link}
                        onChange={(e) =>
                            dispatch(
                                setAllInputs({
                                    key: "announcement_form",
                                    name: "link",
                                    value: e.target.value.trim(),
                                })
                            )
                        }
                    />
                </div>
                <div>
                    <Select
                        label={t("status")}
                        optionsClassName={"!max-h-[unset] !bottom-[calc(100%+14px)]"}
                        data={newAnnouncementStatusData
                            .slice(1)}
                        defaultValue={announcement_form.status}
                        defaultTitle={t("choose")}
                        onChange={(e) => {
                            dispatch(
                                setAllInputs({
                                    key: "announcement_form",
                                    name: "mashin_link",
                                    value: "",
                                })
                            );
                            dispatch(
                                setAllInputs({
                                    key: "announcement_form",
                                    name: "status",
                                    value: e.id,
                                })
                            );
                        }}
                    />
                </div>
                <div className="col-span-2 space-y-2">
                    <p className="dark:text-white">{t("comment")}</p>
                    <textarea
                        className="w-full p-4 mb-4 border border-solid border-gray rounded resize-none dark:bg-softBlack dark:text-white"
                        placeholder="Səbəbi yazın"
                        rows={4}
                        defaultValue={announcement_form.comment ?? ''}
                        onChange={(e) =>
                            dispatch(
                                setAllInputs({
                                    key: "announcement_form",
                                    name: "comment",
                                    value: e.target.value,
                                })
                            )
                        }
                    />
                </div>
            </form>
            <div
                className={'!fixed  bottom-9 left-[50%] -translate-x-1/2  z-999 flex justify-center items-center'}
            >
                <Button
                    onClick={handleEdit}
                    className="bg-blue text-white col-span-2"
                    isLoading={isAnnouncementFormUpdating}
                >
                    {t("remember")}
                </Button>
            </div>
            <div
                className={'!fixed z-20  bottom-6 right-0 -translate-x-1/2  z-999 flex justify-center items-center'}
            >
                <Button
                    className="bg-white text-white clickToCall rounded-full w-16 h-16"
                    onClick={handlePhoneCallClick}
                >
                    <PhoneCallSVG className="w-10 h-10" />
                </Button>
            </div>

            {confirmDialogOpen && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-20">
                    <div className="bg-white p-8 rounded-lg">
                        <p>Zəng etmək istəyirsən?</p>
                        <div className="flex justify-end mt-4">
                            <Button className="mr-4" onClick={handleConfirmYes}>Bəli</Button>
                            <Button onClick={handleConfirmNo} className="">Xeyr</Button>
                        </div>
                    </div>
                </div>
            )}
            <CarInfo/>
            {/*<CarPictures/>*/}
        </>
    );
};

export default CommonInfo;
