import React from "react";
import t from "src/hooks/useTranslate";
import InputPhone from "../Common/Inputs/InputPhone";
import Shimmer from "../Common/Shimmer";
import Button from "../Common/Inputs/Button";

const data_table = [
  {
    id: 1,
    center: "+994 55 834 43 43",
    alternative: "+994 50 424 42 43",
    status: "Yeni avtomobil",
    brand: "Audi Q3 II (F3) 35 TFSI",
  },
  {
    id: 2,
    center: "+994 55 834 43 43",
    alternative: "+994 50 424 42 43",
    status: "Yeni avtomobil",
    brand: "Audi Q3 II (F3) 35 TFSI",
  },
  {
    id: 3,
    center: "+994 55 834 43 43",
    alternative: "+994 50 424 42 43",
    status: "Yeni avtomobil",
    brand: "Audi Q3 II (F3) 35 TFSI",
  },
];

const AlternativeNumbers = () => {
  return (
    <div>
      <p className="mb-4 dark:text-white">
        {t("alternative_number_will_be_deleted")}
      </p>
      <div className="flex items-center gap-x-4">
        <div>
          <InputPhone placeholder={t("center_number")} />
        </div>
        <span className="dark:text-white">{t("or")}</span>
        <div>
          <InputPhone placeholder={t("alternative_number")} />
        </div>
      </div>

      <div className="mt-12">
        <table className="w-full">
          <thead>
            <tr className="[&>th]:text-left [&>th]:pb-4 [&>th]:dark:text-white [&>th]:whitespace-nowrap border-b border-solid border-gray">
              <th className="w-[7%]">{t("center_number")}</th>
              <th className="w-[7%]">{t("alternative_number")}</th>
              <th className="w-[10%]">{t("target")}</th>
              <th className="w-[5%]"></th>
            </tr>
          </thead>
          <tbody className="">
            {data_table.map((item) => (
              <tr
                key={item.id}
                className="border-t border-[#E7E7E7] [&>td]:dark:text-white"
              >
                <td className="">{item.center}</td>
                <td>{item.alternative}</td>
                <td className="flex flex-col gap-x-1 py-5">
                  <span>{item.status}</span>
                  <span className="text-[#3A70DD]"> {item.brand}</span>
                </td>
                <td>
                  <Button className="bg-blue text-white">
                    {t("to_complain")}
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AlternativeNumbers;
