import { FC, useEffect, useState } from "react";
import { ReactComponent as XSVG } from "src/assets/vectors/x.svg";
import { RequestsSettingsData } from "src/data/requests_settings_data";
import t from "src/hooks/useTranslate";
import {
  useAddRequestTemplatesMutation,
  useDeleteRequestTemplatesMutation,
  useGetAllTemplatesQuery,
} from "src/services/requestsService";
import Shimmer from "../Common/Shimmer";
import RequestAddTemplateInput from "./RequestAddTemplateInput";
import { RequestTemplate } from "./RequestTemplate";
import usePermission from "src/hooks/usePermission";
import { EPermissions } from "src/enums/e_permissions";
import { errorToast, successToast } from "src/store/Settings/toastStore";
import { useAppDispatch } from "src/hooks/redux";

const RequestConfigurationModal: FC<{ setVisible: (val: boolean) => void }> = ({
  setVisible,
}) => {
  const dispatch = useAppDispatch()
  const canCreate = usePermission([EPermissions.ticket_settings_create]);

  const {
    data: templates,
    isLoading: isTemplatesLoading,
    isSuccess: isTemplatesLoaded,
  } = useGetAllTemplatesQuery("?withCount=true");
  const [activeId, setActiveId] = useState({ id: 0, type: null });
  const [
    updateTemplate,
    { isLoading: templateUpdating, isSuccess: templateUpdated },
  ] = useAddRequestTemplatesMutation();

  const [
    deleteTemplate,
    { isLoading: templateDeleting, isSuccess: templateDeleted, error: deleteError, isError: isDeleteError },
  ] = useDeleteRequestTemplatesMutation();

  const onTemplateUpdate = async (body) => {
    await updateTemplate(body);
  };

  const onTemplateDelete = async (body) => {
    await deleteTemplate(body);
  };

useEffect(() => {
  if (templateDeleted) {
    dispatch(successToast("Şablon silindi"));
  }
}, [templateDeleted])

useEffect(() => {
  if (isDeleteError) {
    //@ts-ignore
    dispatch(errorToast(deleteError?.data.message));
  }
}, [isDeleteError])


  return (
    <>
      <div className="flex items-center justify-between gap-x-4 border-b border-solid dark:border-gray p-4">
        <p className="text-16 font-bold dark:text-white">{t("settings")}</p>
        <XSVG
          className="text-gray cursor-pointer dark:text-white"
          onClick={() => setVisible(false)}
        />
      </div>
      <div className="border-b border-solid dark:border-gray">
        {canCreate && (
          <div className="flex divide-x dark:divide-gray">
            {RequestsSettingsData.map((data) => (
              <RequestAddTemplateInput data={data} />
            ))}
          </div>
        )}

        <p className="p-4 border-y border-solid dark:border-gray text-center text-16 font-bold dark:text-white">
          {t("templates")}
        </p>
        <div className="flex divide-x">
          {templates && !isTemplatesLoading ? (
            Object.entries(templates.data).map((data: any) => (
              <div className="flex-1 space-y-2 py-4 max-h-[400px] overflow-auto">
                {data[1]?.map((template) => (
                  <RequestTemplate
                    template={template}
                    type={data[0]}
                    activeId={activeId}
                    setActiveId={setActiveId}
                    onUpdate={(value) =>
                      onTemplateUpdate({
                        type: data[0],
                        data: { id: template.id, name: value },
                      })
                    }
                    onDelete={(e) =>
                      onTemplateDelete({
                        id: e.id,
                        type: e.type,
                      })
                    }
                    isDeleting={templateDeleting}
                    isSuccess={templateUpdated}
                    isLoading={templateUpdating}
                    key={template.id}
                  />
                ))}
              </div>
            ))
          ) : (
            <>
              {[...Array(4)].map((_, i) => (
                <div className="flex-1 space-y-4 p-4" key={i}>
                  {[...Array(4)].map((__, idx) => (
                    <div className="flex gap-4" key={idx}>
                      <Shimmer className="flex-1 h-10.5" />
                      <Shimmer className="w-6 h-10.5" />
                    </div>
                  ))}
                </div>
              ))}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default RequestConfigurationModal;
