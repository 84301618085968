import React, { useState, useRef, useEffect, useCallback } from "react";
import t from "src/hooks/useTranslate";

import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
} from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { imgPreview } from "src/helper/imagePreview";
import { useDebounceEffect } from "src/hooks/useDebounceEffect";
import Button from "./Inputs/Button";
import Shimmer from "./Shimmer";

function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

export default function Cropper({ files, onSave }: any) {
  const [imgSrc, setImgSrc] = useState("");
  const imgRef = useRef<HTMLImageElement>(null);
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [preview, setPreview] = useState("");

  useEffect(() => {
    if (files && files.length > 0) {
      setCrop(undefined);
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setImgSrc(reader.result?.toString() || "")
      );
      reader.readAsDataURL(files[0]);
    }
  }, []);

  const onCropComplete = useCallback(
    (croppedAreaPixels: React.SetStateAction<any>) => {
      setCompletedCrop(croppedAreaPixels);
    },
    []
  );

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    const { width, height } = e.currentTarget;
    setCrop(centerAspectCrop(width, height, 1));
  }

  useDebounceEffect(
    async () => {
      if (completedCrop?.width && completedCrop?.height && imgRef.current) {
        try {
          const prvImg = await imgPreview(
            imgRef.current,
            completedCrop,
            scale,
            rotate
          );
          setPreview(prvImg);
        } catch (error) {}
      }
    },
    100,
    [completedCrop, scale, rotate]
  );

  const onRotateHandler = (e: any) => {
    setRotate(e.currentTarget.value);
  };

  const scaleHandler = (e: any) => {
    e.stopPropagation();
    if (e.deltaY > 0 && scale < 0.2) {
      return;
    } else if (e.deltaY > 0 && scale > 0.1) {
      setScale((prev) => prev - 0.1);
    } else if (e.deltaY < 0 && scale > 0.1 && scale < 5) {
      setScale((prev) => prev + 0.1);
    }
  };

  return (
    <div className="App">
      {!!imgSrc ? (
        <div
          onWheel={scaleHandler}
          className="flex justify-center items-center"
        >
          <ReactCrop
            crop={crop}
            onChange={(_, percentCrop) => setCrop(percentCrop)}
            onComplete={onCropComplete}
            aspect={1}
          >
            <img
              ref={imgRef}
              className="!max-h-[calc(100vh-200px)]"
              alt="Crop me"
              src={imgSrc}
              style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
              onLoad={onImageLoad}
            />
          </ReactCrop>
        </div>
      ) : (
        <Shimmer className="w-full aspect-video" />
      )}
      <div className="w-full px-8 mt-4">
        <input
          type="range"
          min="-180"
          max="180"
          value={rotate}
          onChange={onRotateHandler}
          className="slider"
          id="myRange"
        />
        <div className="flex w-full justify-between dark:text-white">
          <p>
            -180<sup>o</sup>
          </p>
          <p className="-translate-x-[2px]">0</p>
          <p>
            180<sup>o</sup>
          </p>
        </div>
      </div>
      <Button
        className="w-40 bg-green text-white mt-4 disabled:bg-gray"
        isLoading={!preview}
        onClick={() => onSave(preview)}
      >
        {t("remember")}
      </Button>
    </div>
  );
}
