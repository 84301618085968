import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { removeArrayValueByIndex } from 'src/helper/removeArrayValueByIndex'
import { EGroupsCrudSteps } from 'src/enums/e_groups_crud_steps'

const initialState: {
    groups_form: any, newGroup: any,
    all_group_permission_selected: any,
    groups_permissions: any,
    visitedSteps: EGroupsCrudSteps[]
} = {
    groups_form: {
        name: ""
    },
    newGroup: {},
    all_group_permission_selected: [],

    groups_permissions: {
        id: 0,
        name: "All",
        child: [],
    },
    visitedSteps: []
}

export const groupsSlice = createSlice({
    name: 'groups',
    initialState,
    reducers: {
        setGroup: (state, action: PayloadAction<any>) => {
            state.groups_form = action.payload

            state.all_group_permission_selected = []
            action.payload.permissions.forEach((permission: any) => {
                state.all_group_permission_selected.push(permission.id)
            })
            let isAllSelected = state.groups_permissions.child.every((child: any) => state.all_group_permission_selected.includes(child.id))
            isAllSelected && state.all_group_permission_selected.push(state.groups_permissions.id)
        },
        setAllInputs: (state, action: PayloadAction<any>) => {
            state.groups_form = { ...state.groups_form, [action.payload.name]: action.payload.value }
        },
        setPermissions: (state, action: PayloadAction<any>) => {
            state.groups_permissions.child = action.payload?.permissions
        },
        setNewGroup: (state, action: PayloadAction<any>) => {
            state.newGroup = action.payload
        },
        setVisitedGroupStep: (state, action: PayloadAction<EGroupsCrudSteps>) => {
            !state.visitedSteps.includes(action.payload) && state.visitedSteps.push(action.payload)
        },
        setGroupPermissions: (state, action: PayloadAction<any>) => {
            // console.log(action.payload);

        },
        reset: (state) => {
            Object.assign(state, initialState)
        },
    },
})

export const { setGroup, setAllInputs, setPermissions, setVisitedGroupStep, setNewGroup, setGroupPermissions, reset } = groupsSlice.actions
export default groupsSlice.reducer