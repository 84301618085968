import t from "src/hooks/useTranslate";
import { useState, useEffect } from "react";
import { ReactComponent as InfoSVG } from "src/assets/vectors/info.svg";
import CalendarPopover from "../Common/CalendarPopover";
import Shimmer from "../Common/Shimmer";
import { ddmmyyyy } from "src/helper/getFormattedDate";

const UsefulUsageOfWorkTime = ({ data, isLoading, setParams }) => {
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(0);
  const [value, setValue] = useState(ddmmyyyy(new Date()));

  useEffect(() => {
    if (data && data.data.length > 0) {
      let arr = [];
      for (let i = 0; i < data.data.length; i++) {
        arr.push(data.data[i].count);
      }
      setMax(Math.max(...arr));
      setMin(Math.min(...arr));
    }
  }, [data]);

  return (
    <div className="flex flex-col relative h-[400px] p-4 w-[calc(65%-12px)] bg-white dark:bg-lightBlack rounded-xl drop-shadow-lg z-10">
      <div className="flex items-center gap-x-2">
        <h3 className="text-16 font-bold mr-auto dark:text-white">
          {t("useful_usage_schedule_of_worktime")}
        </h3>
        <CalendarPopover
          minDate={new Date("01.01.2019")}
          maxDate={new Date()}
          value={value}
          onChange={(e) => {
            setValue(e);
            setParams(`?date=${e}`);
          }}
        />
      </div>
      <ul
        className={`relative h-full overflow-auto mt-8 flex flex-col flex-wrap gap-y-4 ${
          data && data.data.length > 8
            ? "after:absolute after:top-0 after:left-1/2 after:-translate-x-1/2 after:h-full after:w-0.5 after:bg-gray after:opacity-30"
            : ""
        }`}
      >
        {data && !isLoading ? (
          data.data.length > 0 ? (
            data.data.map((user) => (
              <li
              className="h-5 flex items-center gap-x-6 w-[calc(50%-1rem)] [&:nth-child(n+9)]:ml-8"
              key={user.user_id}
              >
                <p className="whitespace-nowrap min-w-[128px] truncate dark:text-white">
                  {user.user_name}
                </p>
                <div className="relative h-1 w-full bg-light rounded-full">
                  <div
                    className="absolute top-1/2 -translate-y-1/2 left-0 h-[200%] bg-aqua rounded-full"
                    style={{
                      width:
                        min == max
                          ? "50%"
                          : user.count == min
                          ? "3%"
                          : ((user.count - min) * 100) / (max - min) + "%",
                    }}
                  ></div>
                </div>
                <p className="ml-auto min-w-[54px] text-right lowercase dark:text-white">
                  {user.count + " " + t("announcement")}
                </p>
              </li>
            ))
          ) : (
            <div className="w-full relative top-1/2 -translate-y-1/2 p-4 flex items-center justify-center">
              <div className="w-fit flex items-center gap-x-3 p-4 rounded-xl bg-light dark:bg-softBlack text-gray dark:text-white">
                <InfoSVG />
                <p>Göstəriləcək hər hansı bir məlumat yoxdur</p>
              </div>
            </div>
          )
          ) : (
            [...Array(16)].map((_, i) => (
              <Shimmer
                className="w-[calc(50%-1rem)] h-5 [&:nth-child(n+9)]:ml-8"
                key={i}
              />
            ))
          )}
      </ul>
    </div>
  );
};

export default UsefulUsageOfWorkTime;
