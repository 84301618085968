import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import FinanceDetailsStatistics from "src/components/Finance/Dashboard/Details/FinanceDetailsStatistics";
import FinanceDetailsTable from "src/components/Finance/Dashboard/Details/FinanceDetailsTable";
import Header from "src/components/Header/Header";
import t from "src/hooks/useTranslate";
import {
  useFinanceDashboardDetailQuery,
  useFinanceDashboardDetailStatisticsQuery,
} from "src/services/financeService";

const FinanceDetails = () => {
  const urlParams = useParams();
  const [filterParams, setFilterParams] = useState("");

  const { data: statistics, isFetching: statisticsLoading } =
    useFinanceDashboardDetailStatisticsQuery(urlParams.autosalonId);
  const {
    data: list,
    refetch: listRefetch,
    isFetching: listLoading,
  } = useFinanceDashboardDetailQuery({
    id: urlParams.autosalonId,
    query: filterParams,
  });

  useEffect(() => {
    listRefetch();
  }, [filterParams]);

  return (
    <>
      <Header title={statistics ? statistics.auto_salon : t("autosalon")} />
      <div className="space-y-6">
        <FinanceDetailsStatistics
          data={statistics}
          isLoading={statisticsLoading}
        />
        <FinanceDetailsTable
          data={list}
          isLoading={listLoading}
          setParams={setFilterParams}
        />
      </div>
    </>
  );
};

export default FinanceDetails;
