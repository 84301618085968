import { ReactComponent as XSVG } from "src/assets/vectors/x.svg";
import t from "src/hooks/useTranslate";
import Button from "../Common/Inputs/Button";

const GetPermissionNotificationModal = ({ data, setModal }) => {
  return (
    <>
      <div className="flex items-center justify-between gap-x-4 p-4 border-b border-solid border-gray">
        <p className="text-16 font-bold dark:text-white">
          {t("permission_request_notice")}
        </p>
        <XSVG
          className="text-gray cursor-pointer dark:text-white"
          onClick={() => setModal(false)}
        />
      </div>
      <div className="p-6 space-y-4">
        <div className="space-y-2">
          <p className="text-gray">{t("permission_date")}</p>
          <div className="w-full h-10.5 px-4 flex items-center bg-light dark:bg-lightBlack border border-gray rounded">
            <p>{data?.date}</p>
          </div>
        </div>
        <div className="space-y-2">
          <div className="flex gap-x-4 justify-between items-center text-gray">
            <p>{t("status")}</p>
            <p>
              {t("date")}: {data?.created_at}
            </p>
          </div>
          <div
            className={`w-full h-10.5 px-4 flex items-center border-gray bg-light dark:bg-lightBlack border rounded ${
              data?.status == 2
                ? "border-green text-green"
                : "border-red text-red"
            }`}
          >
            <p>{data?.status_label}</p>
          </div>
        </div>
        <Button className="w-full border border-gray dark:text-white !mt-6" onClick={() => setModal(false)}>{t("close")}</Button>
      </div>
    </>
  );
};

export default GetPermissionNotificationModal;
