import { useEffect, useRef, useState } from "react";
import { EAscDesc } from "src/enums/e_asc_desc";
import Shimmer from "../Common/Shimmer";
import { ReactComponent as UpChevronSVG } from "src/assets/vectors/up_chevron.svg";
import { ddmmyyyy_hhmm } from "src/helper/getFormattedDate";
import { ReactComponent as EyeSVG } from "src/assets/vectors/eye.svg";
import { ReactComponent as MailSVG } from "src/assets/vectors/mail.svg";
import { useTimeOffsQuery } from "src/services/profileService";
import adminResponseStatus from "src/data/admin_response_status_data.json";
import t from "src/hooks/useTranslate";
import Pagination from "../Common/Pagination";
import { useAppSelector } from "src/hooks/redux";
import { ETheme } from "src/enums/e_theme";
import Modal from "../Common/Modal";
import TextModal from "../Common/TextModal";
import { useLocation } from "react-router-dom";
import { useNotificationDetailQuery } from "src/services/notificationService";
import GetPermissionNotificationModal from "./GetPermissionNotificationModal";

enum EGetPermissionTableModal {
  notification = 1,
  comment,
}

const ProfileGetPermissionTable = () => {
  let { state } = useLocation();
  const [orderById, setOrderById] = useState(EAscDesc.desc);
  const input = useRef<HTMLInputElement>(null);
  const [params, setParams] = useState(new URLSearchParams());
  const [modal, setModal] = useState(false);
  const [modalType, setModalType] = useState(
    EGetPermissionTableModal.notification
  );
  const [text, setText] = useState("");
  const [activeUserName, setActiveUserName] = useState("");
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const theme = useAppSelector((state) => state.theme.theme);
  const {
    data: notificationDetail,
    isLoading: notificationDetailLoading,
    isSuccess: notificationDetailLoaded,
  } = useNotificationDetailQuery(state?.notificationId, {
    skip: !state || !state?.notificationId,
  });
  const {
    data: timeOffs,
    refetch,
    isFetching,
  } = useTimeOffsQuery(params.toString() ? "?" + params.toString() : "");

  useEffect(() => {
    if (state?.notificationId) {
      setModalType(EGetPermissionTableModal.notification);
      setModal(true);
      window.history.replaceState({}, document.title);
    }
  }, [state]);

  useEffect(() => {
    refetch();
  }, [setPage, setPerPage, setOrderById]);

  return (
    <>
      <div className="w-full relative py-6 bg-white dark:bg-lightBlack rounded-xl mt-2 drop-shadow-lg">
        <div className="w-full overflow-auto dark:text-white min-h-[376px]">
          <table className="min-w-[991px] w-full">
            <thead>
              <tr className="[&>th]:text-left [&>th]:pb-6 [&>th]:px-2 [&>th]:whitespace-nowrap border-b border-solid border-gray">
                <th
                  className="w-20 flex items-center gap-x-1.5 !pl-4 cursor-pointer"
                  onClick={() => {
                    setOrderById(
                      orderById == EAscDesc.asc ? EAscDesc.desc : EAscDesc.asc
                    );
                    params.set(
                      "id",
                      orderById == EAscDesc.asc ? EAscDesc.desc : EAscDesc.asc
                    );
                  }}
                >
                  ID
                  <UpChevronSVG
                    className={`w-3 text-blue ${
                      orderById == EAscDesc.asc ? "rotate-180" : ""
                    }`}
                  />
                </th>
                <th className="w-auto">{t("start_date")}</th>
                <th className="w-auto">{t("time_off_hour")}</th>
                <th className="w-auto">{t("reason")}</th>
                <th className="w-auto">{t("status")}</th>
                <th className="min-w-[100px] w-auto !pr-4">{t("comment")}</th>
                {/* <th className="w-auto !pr-4">{t("operations")}</th> */}
              </tr>
            </thead>
            <tbody>
              {timeOffs && !isFetching ? (
                timeOffs.data.map((timeOff) => (
                  <tr
                    className={`relative [&>td]:py-4 [&>td]:px-2 after:absolute after:top-0 after:left-0 after:w-full after:h-[1px] after:bg-gray after:opacity-50 ${
                      timeOff.has_notification ? "bg-light dark:bg-softBlack" : ""
                    }`}
                    key={timeOff.id}
                  >
                    <td className="w-10 !pl-4">{timeOff.id}</td>
                    <td className="relative">
                      {timeOff.has_notification && (
                        <MailSVG className="absolute top-1/2 -translate-y-1/2 -left-4 text-red" />
                      )}
                      {ddmmyyyy_hhmm(new Date(timeOff.start_date))}
                    </td>
                    <td>
                      +
                      {timeOff.hour == 24
                        ? "1 " + t("day")
                        : timeOff.hour + " " + t("hour")}
                    </td>
                    <td>{timeOff.comment}</td>
                    <td
                      style={{
                        color:
                          theme == ETheme.light
                            ? adminResponseStatus[timeOff.status]["color"]
                            : adminResponseStatus[timeOff.status]["darkColor"],
                      }}
                    >
                      {t(adminResponseStatus[timeOff.status]["name"])}
                    </td>
                    <td className="!pr-4">
                      {timeOff.admin_comment ? (
                        <EyeSVG
                          className="cursor-pointer"
                          onClick={() => {
                            setActiveUserName(timeOff.user);
                            setText(timeOff.admin_comment);
                            setModalType(EGetPermissionTableModal.comment);
                            setModal(true);
                          }}
                        />
                      ) : (
                        ""
                      )}
                    </td>
                    {/* <td className="!pr-4">
                    <div className="flex items-center gap-x-2">
                      <Button
                        className="border border-solid border-gray text-gray"
                        onClick={() => input.current!.click()}
                      >
                        {t("choose_file")}
                      </Button>
                      <Button className="bg-blue text-white">
                        {t("submit")}
                      </Button>
                    </div>
                  </td> */}
                  </tr>
                ))
              ) : (
                <>
                  {[...Array(10)].map((_, i) => (
                    <tr className="[&>td]:py-4 [&>td]:px-2" key={i}>
                      <td className="w-20">
                        <Shimmer className="h-4" />
                      </td>
                      <td className="w-auto">
                        <Shimmer className="h-4" />
                      </td>
                      <td className="w-auto">
                        <Shimmer className="h-4" />
                      </td>
                      <td className="w-auto">
                        <Shimmer className="h-4" />
                      </td>
                      <td className="w-auto">
                        <Shimmer className="h-4" />
                      </td>
                      <td className="w-auto">
                        <Shimmer className="h-4" />
                      </td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </table>
        </div>
        {timeOffs?.total > 5 && (
          <div className="px-4 pt-4">
            <Pagination
              page={Math.ceil(timeOffs?.total / perPage)}
              total={timeOffs?.total}
              perPage={perPage}
              pageChange={(e) => {
                setPage(e + 1);
                params.set("page", (e + 1).toString());
              }}
              setPerPage={(val) => {
                setPerPage(val);
                params.set("per_page", val.toString());
              }}
            />
          </div>
        )}

        {/* <input type="file" ref={input} className="hidden" /> */}
      </div>
      <Modal
        visible={modal}
        setVisible={setModal}
        dialogClassName="min-w-[550px] max-w-[550px]"
      >
        {modalType == EGetPermissionTableModal.comment ? (
          <TextModal setModal={setModal} title={t("comment")} text={text} />
        ) : (
          <GetPermissionNotificationModal data={notificationDetail?.data} setModal={setModal} />
        )}
      </Modal>
    </>
  );
};

export default ProfileGetPermissionTable;
