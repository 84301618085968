import React, { useEffect, useState } from "react";
import t from "src/hooks/useTranslate";
import Shimmer from "../Common/Shimmer";

const RequestsSidebar = ({
  data,
  isLoading,
  onStatusChange,
  activeTab,
  setActiveTab,
}) => {
  useEffect(() => {
    if (data) {
      setActiveTab(data[0]);
    }
  }, [data]);

  return (
    <div className="min-w-[256px] px-4 py-6 border-r border-solid border-gray">
      <p className="w-full text-16 font-bold pb-4 border-b border-solid border-light dark:text-white">
        {t("inbox")}
      </p>
      <ul className="space-y-2 mt-4">
        {data && !isLoading ? (
          data.map((status) => (
            <li
              className={`h-10.5 px-4 flex justify-between items-center gap-x-2 rounded text-gray font-medium cursor-pointer hover:font-semibold hover:text-darkBlue hover:bg-light dark:text-white dark:hover:bg-softBlack  ${
                activeTab?.id === status.id
                  ? "bg-light text-darkBlue dark:bg-softBlack font-semibold"
                  : ""
              }`}
              onClick={() => {
                setActiveTab(status);
                onStatusChange({ key: status.key, value: status.value });
              }}
              key={status.id}
            >
              <p>{status.label}</p>
              <p>{status.count}</p>
            </li>
          ))
        ) : (
          <div className="space-y-4">
            {[...Array(5)].map((_, i) => (
              <Shimmer className="w-full h-10" key={i} />
            ))}
          </div>
        )}
      </ul>
    </div>
  );
};

export default RequestsSidebar;
