import React, { useEffect, useState } from "react";
import Header from "src/components/Header/Header";
import AlternativeNumbers from "src/components/RingoStat/AlternativeNumbers";
import CallList from "src/components/RingoStat/CallList";
import t from "src/hooks/useTranslate";
import { useRingoStatListMutation } from "src/services/ringoStatService";
// import { useRingoStatListQuery } from "src/services/ringoStatService";

const tabs = [
  { id: 1, name: t("call_list") },
  // { id: 2, name: t("alternative_numbers") },
];

const DashbordRingo = () => {
  const [activeTab, setActiveTab] = useState(1);


  return (
    <>
      <Header title={t("ringo_dashboard")} />
      <div className="w-full relative bg-white dark:bg-lightBlack rounded-xl drop-shadow-lg">
        <h3 className="font-semibold px-6 py-6 dark:text-white">
          {t("follow_calls")}
        </h3>

        <div className="flex mx-6 gap-x-6 border-b border-cream">
          {/* {tabs.map((item) => (
            <div
              className={`cursor-pointer pb-2.5 -mb-0.5 ${
                item.id === activeTab
                  ? "border-blue border-b-4 text-black dark:text-white"
                  : "text-gray"
              }`}
              onClick={() => setActiveTab(item.id)}
            >
              <p className="">{item.name}</p>
            </div>
          ))} */}
        </div>

        <div className="px-6 pt-6 w-full">
          {activeTab === 1 ? (
            <div>
              <CallList />
            </div>
          ) : (
            <AlternativeNumbers />
          )}
        </div>
      </div>
    </>
  );
};

export default DashbordRingo;
