import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { getCookie } from "src/helper/custom_cookies";

export const faqService = createApi({
    reducerPath: "faqService",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_BASE_URL,
        prepareHeaders: (headers) => {
            headers.set("Authorization", getCookie("mcrmtkn") ? "Bearer " + getCookie("mcrmtkn") : "")
            headers.set('Accept', 'application/json')
            return headers
        },
    }),
    tagTypes: ["FAQ"],
    endpoints: (builder) => ({
        getFaqByQuery: builder.query({
            query: ({id, query}) => {
                return {
                    url: `/faqs/${id}/list${query}`,
                    method: "get"
                }
            },
            providesTags: ["FAQ"]
        }),
        getFaqById: builder.query({
            query: (id) => {
                return {
                    url: `/faqs/${id}/edit`,
                }
            },
            // providesTags: ["FAQ"]
        }),
        getFaqSections: builder.query({
            query: () => {
                return {
                    url: "/faqs/categories",
                }
            },
            providesTags: ["FAQ"]
        }),
        deleteFaqSection: builder.mutation({
            query: (id) => {
                return {
                    url: `/faqs/categories/${id}`,
                    method: "delete"
                }
            },
            invalidatesTags: ["FAQ"]
        }),
        changeFaqSection: builder.mutation({
            query: (body) => {
                return {
                    url: "/faqs/categories/storeOrUpdate",
                    method: "post",
                    body
                }
            },
            invalidatesTags: ["FAQ"]
        }),
        // getFaqById: builder.query({
        //     query: (categoryId) => {
        //         return {
        //             url: `/faqs/${categoryId}/list`,
        //         }
        //     },
        //     providesTags: ["FAQ"]
        // }),
        createFaq: builder.mutation({
            query: (body) => {
                return {
                    url: "/faqs/store",
                    method: "post",
                    body
                }
            },
            invalidatesTags: ["FAQ"]
        }),
        updateFaq: builder.mutation({
            query: (body) => {
                return {
                    url: `/faqs/${body.id}/update`,
                    method: "post",
                    body
                }
            },
            invalidatesTags: ["FAQ"]
        }),
        sendFaq: builder.mutation({
            query: (body) => {
                return {
                    url: "/faqs/sendReplyToNumber",
                    method: "post",
                    body
                }
            },
        }),
        deleteFaq: builder.mutation({
            query: (id) => {
                return {
                    url: `/faqs/${id}`,
                    method: "delete"
                }
            },
            invalidatesTags: ["FAQ"]
        }),
        reOrderFaq: builder.mutation({
            query: (body) => {
                return {
                    url: "/faqs/reOrder",
                    method: "post",
                    body
                }
            },
            // invalidatesTags: ["FAQ"]
        }),
        
    })
})

export const { useGetFaqByQueryQuery, useGetFaqSectionsQuery, useDeleteFaqSectionMutation, useChangeFaqSectionMutation, useGetFaqByIdQuery, useCreateFaqMutation, useUpdateFaqMutation, useSendFaqMutation, useDeleteFaqMutation, useReOrderFaqMutation } = faqService