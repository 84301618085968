import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getCookie } from "src/helper/custom_cookies";
import { IProfile } from "src/types/i_profile";

export const vacationService = createApi({
  reducerPath: "vacationService",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: (headers) => {
      headers.set(
        "Authorization",
        getCookie("mcrmtkn") ? "Bearer " + getCookie("mcrmtkn") : ""
      );
      headers.set("Accept", "application/json");
      return headers;
    },
    // credentials: "include",
  }),
  tagTypes: ["Vacation"],
  endpoints: (builder) => ({
    getVacations: builder.query({
      query: (query) => {
        return {
          url: `/vacations/all${query}`,
        };
      },
      providesTags: ["Vacation"],
    }),
    getCalendarVacations: builder.query({
      query: (query) => {
        return {
          url: `/vacations/lasts${query}`,
        };
      },
      providesTags: ["Vacation"],
    }),
    confirmVacation: builder.mutation({
      query: ({ id, body }) => {
        return {
          url: `/profile/accept_vacation/${id}`,
          method: "post",
          body,
        };
      },
      invalidatesTags: ["Vacation"],
    }),
    vacationsExcel: builder.mutation({
      query: (query) => {
        return {
          url: `/vacations/all/export${query}`,
          method: "get",
          responseHandler: async (response) => {
            // console.log("first");
            window.location.assign(
              window.URL.createObjectURL(await response.blob())
            );
          },
        };
      },
    }),
  }),
});

export const {
  useGetVacationsQuery,
  useGetCalendarVacationsQuery,
  useConfirmVacationMutation,
  useVacationsExcelMutation,
} = vacationService;
