import React from 'react';
import {setAllInputs} from "../../store/Announcements/announcementStore";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import Input from "../Common/Inputs/Input";
import t from "../../hooks/useTranslate";

const CarInfo = () => {
    const dispatch = useAppDispatch();

    const announcement_form = useAppSelector(
        (state) => state.announcements.announcement_form
    );

    return (
        <>
            <h3 className={"text-16 font-bold dark:text-white p-4"}>{t("carInfo")}</h3>
            <div className="grid grid-cols-2 gap-4 p-4 border-b border-solid border-gray">
                <Input
                    label={t("city")}
                    defaultValue={announcement_form.vehicle?.city}
                    disabled
                    onChange={(e) =>
                        dispatch(
                            setAllInputs({
                                key: "announcement_form",
                                name: "city",
                                value: e.target.value.trim(),
                            })
                        )
                    }
                />
                <Input
                    label={t("model")}
                    defaultValue={announcement_form.vehicle?.model}
                    disabled
                    onChange={(e) =>
                        dispatch(
                            setAllInputs({
                                key: "announcement_form",
                                name: "model",
                                value: e.target.value.trim(),
                            })
                        )
                    }
                />
                <Input
                    label={t("banType")}
                    defaultValue={announcement_form.vehicle?.ban_type}
                    disabled
                    onChange={(e) =>
                        dispatch(
                            setAllInputs({
                                key: "announcement_form",
                                name: "ban_type",
                                value: e.target.value.trim(),
                            })
                        )
                    }
                />
                < Input
                    label={t("mileage")}
                    defaultValue={announcement_form.vehicle?.mileage}
                    disabled
                    onChange={(e) =>
                        dispatch(
                            setAllInputs({
                                key: "announcement_form",
                                name: "mileage",
                                value: e.target.value.trim(),
                            })
                        )
                    }
                />
                <Input
                    label={t("year")}
                    defaultValue={announcement_form.vehicle?.year}
                    disabled
                    onChange={(e) =>
                        dispatch(
                            setAllInputs({
                                key: "announcement_form",
                                name: "year",
                                value: e.target.value.trim(),
                            })
                        )
                    }
                />
                <Input
                    label={t("engine")}
                    defaultValue={announcement_form.vehicle?.engine}
                    disabled
                    onChange={(e) =>
                        dispatch(
                            setAllInputs({
                                key: "announcement_form",
                                name: "engine",
                                value: e.target.value.trim(),
                            })
                        )
                    }
                />
                <Input
                    label={t("engine_capacity")}
                    defaultValue={announcement_form.vehicle?.engine_capacity}
                    disabled
                    onChange={(e) =>
                        dispatch(
                            setAllInputs({
                                key: "announcement_form",
                                name: "engine_capacity",
                                value: e.target.value.trim(),
                            })
                        )
                    }
                />
                <Input
                    label={t("engine_power")}
                    defaultValue={announcement_form.vehicle?.engine_power}
                    disabled
                    onChange={(e) =>
                        dispatch(
                            setAllInputs({
                                key: "announcement_form",
                                name: "engine_power",
                                value: e.target.value.trim(),
                            })
                        )
                    }
                />
                <Input
                    label={t("transmission")}
                    defaultValue={announcement_form.vehicle?.transmission}
                    disabled
                    onChange={(e) =>
                        dispatch(
                            setAllInputs({
                                key: "announcement_form",
                                name: "transmission",
                                value: e.target.value.trim(),
                            })
                        )
                    }
                />
                <Input
                    label={t("gearing")}
                    defaultValue={announcement_form.vehicle?.gearing}
                    disabled
                    onChange={(e) =>
                        dispatch(
                            setAllInputs({
                                key: "announcement_form",
                                name: "gearing",
                                value: e.target.value.trim(),
                            })
                        )
                    }
                />
                <Input
                    label={t("color")}
                    defaultValue={announcement_form.vehicle?.color}
                    disabled
                    onChange={(e) =>
                        dispatch(
                            setAllInputs({
                                key: "announcement_form",
                                name: "color",
                                value: e.target.value.trim(),
                            })
                        )
                    }
                />

                <Input
                    label={t("barter")}
                    defaultValue={announcement_form.vehicle?.barter === 0 ? t("no") : t("yes")}
                    disabled
                    onChange={(e) =>
                        dispatch(
                            setAllInputs({
                                key: "announcement_form",
                                name: "barter",
                                value: e.target.value.trim(),
                            })
                        )
                    }
                />
                <Input
                    label={t("credit")}
                    defaultValue={announcement_form.vehicle?.credit === 0 ? t("no") : t("yes")}
                    disabled
                    onChange={(e) =>
                        dispatch(
                            setAllInputs({
                                key: "announcement_form",
                                name: "credit",
                                value: e.target.value.trim(),
                            })
                        )
                    }
                />
                <Input
                    label={t("price")}
                    defaultValue={announcement_form.vehicle?.price}
                    disabled
                    onChange={(e) =>
                        dispatch(
                            setAllInputs({
                                key: "announcement_form",
                                name: "price",
                                value: e.target.value.trim(),
                            })
                        )
                    }
                />
            </div>
        </>
    );
};

export default CarInfo;
