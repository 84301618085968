import { createSlice } from '@reduxjs/toolkit'
import { ETheme } from 'src/enums/e_theme'

const initialState: { theme: ETheme } = {
    theme: ETheme.light,
}

export const themeSlice = createSlice({
    name: 'theme',
    initialState,
    reducers: {
        initTheme: (state) => {
            if (window.matchMedia('(prefers-color-scheme: dark)').matches && !localStorage.getItem("theme")) {
                state.theme = ETheme.dark
                document.documentElement.classList.add(ETheme.dark)
            } else {
                if (localStorage.getItem("theme") === ETheme.dark) {
                    state.theme = ETheme.dark
                    document.documentElement.classList.add(ETheme.dark)
                } else {
                    state.theme = ETheme.light
                    document.documentElement.classList.remove(ETheme.dark)
                }
            }
        },
        changeTheme: (state) => {
            if (state.theme === ETheme.dark) {
                state.theme = ETheme.light
                document.documentElement.classList.remove(ETheme.dark)
                localStorage.setItem("theme", ETheme.light)
            } else {
                state.theme = ETheme.dark
                document.documentElement.classList.add(ETheme.dark)
                localStorage.setItem("theme", ETheme.dark)
            }
        },
    },
})

export const { initTheme, changeTheme } = themeSlice.actions

export default themeSlice.reducer