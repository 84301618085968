import React from 'react'
import CredentialsTable from 'src/components/Finance/Credentials/CredentialsTable'
import Header from 'src/components/Header/Header'
import t from 'src/hooks/useTranslate'

const Credentials = () => {
  return (
    <>
    <Header title={t("credentials")} />
    <CredentialsTable />
    </>
  )
}

export default Credentials