import { useEffect, useState } from "react";
import t from "src/hooks/useTranslate";
import { ReactComponent as InfoSVG } from "src/assets/vectors/info.svg";
import Shimmer from "../Common/Shimmer";

const TopRejected = ({ data, isLoading, setParams }) => {
  const [selectedTab, setSelectedTab] = useState(1);
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(0);
  const tabs = [
    {
      id: 1,
      key: "day",
      name: "daily",
    },
    {
      id: 2,
      key: "week",
      name: "weekly",
    },
    {
      id: 3,
      key: "month",
      name: "monthly",
    },
  ];

  useEffect(() => {
    if (data && data.length > 0) {
      let arr = [];
      for (let i = 0; i < data.length; i++) {
        arr.push(data[i].count);
      }
      setMax(Math.max(...arr));
      setMin(Math.min(...arr));
    }
  }, [data]);

  return (
    <div className="flex flex-col relative h-[400px] px-4 py-6 w-[calc(35%-12px)] bg-white dark:bg-lightBlack rounded-xl drop-shadow-lg">
      <h3 className="text-16 font-bold mb-4 dark:text-white">
        {t("rejected_announcements_status")}
      </h3>
      <div className="flex mb-6">
        {tabs.map((tab) => (
          <button
            key={tab.id}
            className={`text-gray mr-4 ${
              selectedTab === tab.id ? "underline font-semibold" : "font-medium"
            }`}
            onClick={() => {
              setSelectedTab(tab.id);
              setParams(`?filter=${tab.key}`);
            }}
          >
            {t(tab.name)}
          </button>
        ))}
      </div>
      <ul className="h-full overflow-auto pr-2">
        {data && !isLoading ? (
          data.length > 0 ? data.map((user) => (
            <li
              key={user.user_id}
              className="flex items-center gap-x-6 mb-4 last:mb-0"
            >
              <p className="whitespace-nowrap min-w-[128px] truncate dark:text-white">
                {user.user?.full_name}
              </p>
              <div className="relative h-1 w-full bg-light rounded-full overflow-hidden">
                <div
                  className="absolute top-0 left-0 h-full bg-red"
                  style={{
                    width:
                      min == max
                        ? "100%"
                        : user.count == min
                        ? "3%"
                        : ((user.count - min) * 100) / (max - min) + "%",
                  }}
                ></div>
              </div>
              <p className="ml-auto w-16 text-red text-22 font-bold text-right">
                {user.count}
              </p>
            </li>
          )) : (
            <div className="w-full relative top-1/2 -translate-y-1/2 p-4 flex items-center justify-center">
              <div className="w-fit flex items-center gap-x-3 p-4 rounded-xl bg-light dark:bg-softBlack text-gray dark:text-white">
                <InfoSVG />
                <p>Göstəriləcək hər hansı bir məlumat yoxdur</p>
              </div>
            </div>
          )
        ) : (
          <div className="space-y-4">
            {[...Array(5)].map((_, i) => (
              <Shimmer className="h-5" key={i} />
            ))}
          </div>
        )}
      </ul>
    </div>
  );
};

export default TopRejected;
