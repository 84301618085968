import { FC, useEffect, useState } from "react";
 import t from "src/hooks/useTranslate";
import { useAppDispatch } from "src/hooks/redux";
import { setPasswordInputs } from "src/store/Users/usersStore";
import { passwordRequiredPattern } from "src/utils/validationPatterns";
import Button from "../Common/Inputs/Button";
import InputPassword from "../Common/Inputs/InputPassword";

const UsersPassword: FC<{ onSubmit: () => void, isLoading: boolean }> = ({ onSubmit, isLoading }) => {
   
  const dispatch = useAppDispatch();
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [repeatPasswordValidation, setRepeatPasswordValidation] =
    useState(false);

  useEffect(() => {
    password === repeatPassword
      ? setRepeatPasswordValidation(false)
      : setRepeatPasswordValidation(true);
  }, [repeatPassword]);

  return (
    <div className="flex gap-x-6">
      <InputPassword
        label={t("password") + "*"}
        placeholder={t("password")}
        className="min-w-[260px]"
        validpattern={passwordRequiredPattern}
        name="password"
        onChange={(e) => {
          setPassword(e.target.value);
          dispatch(
            setPasswordInputs({ name: e.target.name, value: e.target.value })
          );
        }}
      />
      <InputPassword
        label={t("repeat_password") + "*"}
        placeholder={t("repeat_password")}
        className="min-w-[260px]"
        validpattern={repeatPasswordValidation}
        name="password_confirmation"
        onChange={(e) => {
          setRepeatPassword(e.target.value);
          dispatch(
            setPasswordInputs({ name: e.target.name, value: e.target.value })
          );
        }}
      />
      <Button
        className="min-w-[260px] bg-blue mt-auto text-white disabled:bg-gray"
        onClick={onSubmit}
        isLoading={isLoading}
      >
        {t("continue")}
      </Button>
    </div>
  );
};

export default UsersPassword;
