import React, { useEffect, useState } from "react";
import MashinalAppRoutesTable from "src/components/Finance/MashinAlApp/MashinalAppRoutesTable";
import Header from "src/components/Header/Header";
import { useMashinalAppRoutesListQuery } from "src/services/financeService";

const MashinalAppRoutes = () => {
  const [params, setParams] = useState("");
  const {
    data: routes,
    refetch,
    isFetching: routesLoading,
  } = useMashinalAppRoutesListQuery(params);

  useEffect(() => {
    refetch();
  }, [params]);

  return (
    <>
      <Header title="Link Tənzimləmələri" />
      <MashinalAppRoutesTable
        data={routes}
        isLoading={routesLoading}
        setParams={setParams}
      />
    </>
  );
};

export default MashinalAppRoutes;
