import { useEffect, useState } from "react";
import Header from "src/components/Header/Header";
import t from "src/hooks/useTranslate";
import { ReactComponent as ExcelSVG } from "src/assets/vectors/file_excel.svg";
import {
  useConfirmVacationMutation,
  useGetCalendarVacationsQuery,
  useGetVacationsQuery,
  useVacationsExcelMutation,
} from "src/services/vacationService";
import { ddmmyyyy } from "src/helper/getFormattedDate";
import Button from "src/components/Common/Inputs/Button";
import Modal from "src/components/Common/Modal";
import { useAppDispatch } from "src/hooks/redux";
import { successToast } from "src/store/Settings/toastStore";
import InputCalendar from "src/components/Common/Inputs/InputCalendar";
import VacationCalendar from "src/components/Vacations/VacationCalendar";
import AdminConfirmModal from "src/components/Common/AdminConfirmModal";
import VacationTable from "src/components/Vacations/VacationTable";
import { useLocation } from "react-router-dom";
import { useNotificationDetailQuery } from "src/services/notificationService";
import { EVacationModals } from "src/enums/e_vacation_modals";
import Pagination from "src/components/Common/Pagination";

const Vacations = () => {
  const dispatch = useAppDispatch();
  let { state } = useLocation();
  const [activeTab, setActiveTab] = useState(0);
  const [activeUserName, setActiveUserName] = useState("");
  const [activeId, setActiveId] = useState(0);
  const [reason, setReason] = useState("");
  const [date, setDate] = useState(ddmmyyyy(new Date()));
  const [modal, setModal] = useState(false);

  const [perPage, setPerPage] = useState(10);
  const [params, setParams] = useState(new URLSearchParams());

  const [modalType, setModalType] = useState(EVacationModals.default);

  const {
    data: notificationDetail,
    refetch: notificationRefetch,
    isLoading: notificationDetailLoading,
    isSuccess: notificationDetailLoaded,
  } = useNotificationDetailQuery(state?.notificationId, {
    skip: !state || !state?.notificationId,
  });

  useEffect(() => {
    if (state?.notificationId) {
      notificationRefetch();
      setModalType(EVacationModals.notification);
      setModal(true);
      window.history.replaceState({}, document.title);
    }
  }, [state]);

  /********VACATION TABLE*********/
  const [tableParams, setTableParams] = useState(
    `?date=${date.split(".").slice(1).join(".")}`
  );
  const {
    data: vacationsTable,
    refetch: refetchVacationsTable,
    isFetching: isVacationTableLoading,
  } = useGetVacationsQuery(tableParams, {
    skip: activeTab === 1,
  });

  useEffect(() => {
    activeTab == 0 && refetchVacationsTable();
  }, [tableParams]);

  /********VACATION CALENDAR*********/
  const [calendarParams, setCalendarParams] = useState(
    `?date=${date.split(".").slice(1).join(".")}`
  );
  const {
    data: vacationsCalendar,
    refetch: refetchVacationsCalendar,
    isFetching: isVacationCalendarLoading,
    isSuccess,
  } = useGetCalendarVacationsQuery(calendarParams, {
    skip: activeTab === 0,
  });

  useEffect(() => {
    activeTab == 1 && refetchVacationsCalendar();
  }, [calendarParams]);

  // useEffect(() => {
  //   refetchVacationsTable();
  // }, [page, perPage, date]);

  const [
    confirmVacation,
    { isLoading: isVacationConfirming, isSuccess: isVacationConfirmed },
  ] = useConfirmVacationMutation();

  const onConfirmVacation = async (status) => {
    const body = {
      status,
      reason,
    };
    if (state?.actionId) {
      await confirmVacation({ id: state?.actionId, body });
    } else {
      await confirmVacation({ id: activeId, body });
    }
  };

  const [
    getVacationsExcel,
    { isLoading: isExcelDownloading, isSuccess: isExcelDownloaded },
  ] = useVacationsExcelMutation();

  const onGetVacationsExcel = async () => {
    await getVacationsExcel(tableParams);
  };

  useEffect(() => {
    if (isExcelDownloaded) {
      dispatch(successToast("Excel uğurla yükləndi"));
    }
  }, [isExcelDownloaded]);

  useEffect(() => {
    if (isVacationConfirmed) {
      dispatch(successToast("Təsdiq edildi"));
      setModal(false);
      setActiveId(0);
      setReason("");
    }
  }, [isVacationConfirmed]);


  return (
    <>
      <Header title={t("vacations")} />
      <div className="w-full relative bg-white dark:bg-lightBlack rounded-xl drop-shadow-lg">
        <div className="flex justify-between items-center px-6 py-6 border-b border-solid border-gray">
          <div className="flex rounded-lg overflow-hidden">
            <div
              className={`w-28 flex-1 text-center px-6 py-4 ${
                activeTab === 0
                  ? "bg-green text-white"
                  : "bg-light dark:bg-softBlack cursor-pointer text-gray dark:text-white"
              }`}
              onClick={() => setActiveTab(0)}
            >
              <p className="text-14 font-medium">Ay</p>
            </div>
            <div
              className={`w-28 flex-1 text-center px-6 py-4 ${
                activeTab === 1
                  ? "bg-green text-white"
                  : "bg-light dark:bg-softBlack cursor-pointer text-gray dark:text-white"
              }`}
              onClick={() => setActiveTab(1)}
            >
              <p className="text-14 font-medium">List</p>
            </div>
          </div>
          <div className="relative">
            <InputCalendar
              className="min-w-[260px] z-20"
              maxDetail={"year"}
              value={date}
              monthView
              onChange={(e) => {
                params.delete("per_page");

                setPerPage(10);

                setDate(e);
                setCalendarParams(`?date=${e.split(".").slice(1).join(".")}`);
                setTableParams(`?date=${e.split(".").slice(1).join(".")}`);
              }}
            />
          </div>
        </div>
        {activeTab === 0 ? (
          <div>
            <VacationTable
              date={date}
              data={vacationsTable}
              isLoading={isVacationTableLoading}
              onConfirmClick={(vacation) => {
                setActiveId(vacation.id);
                setActiveUserName(vacation.user);
                setModalType(EVacationModals.submit);
                setModal(true);
              }}
              setParams={setTableParams}
              setPerpage={setPerPage}
            />
          </div>
        ) : (
          <VacationCalendar
            date={date}
            data={vacationsCalendar}
            isLoading={isVacationCalendarLoading}
            isSuccess={isSuccess}
            setParams={setCalendarParams}
          />
        )}
      </div>
      {((activeTab === 0 && vacationsTable?.data?.length > 0) ||
        (activeTab === 1 && vacationsCalendar?.data?.length > 0)) && (
        <Button
          className="w-48 items-center gap-x-2 bg-darkBlue dark:bg-blue text-light mt-4 ml-auto"
          onClick={onGetVacationsExcel}
          isLoading={isExcelDownloading}
        >
          <ExcelSVG /> {t("export_to_excel")}
        </Button>
      )}

      <Modal
        visible={modal}
        setVisible={setModal}
        dialogClassName="min-w-[450px]"
      >
        <AdminConfirmModal
          userName={
            modalType === EVacationModals.notification
              ? notificationDetail?.data.user
              : activeUserName
          }
          setModal={setModal}
          setReason={setReason}
          onSubmit={onConfirmVacation}
          isLoading={isVacationConfirming}
          isNotification={modalType === EVacationModals.notification}
          data={notificationDetail?.data}
        />
      </Modal>
    </>
  );
};

export default Vacations;
