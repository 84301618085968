import { Menu } from "@headlessui/react";
import Dropdown from "../Common/Dropdown";
import { ReactComponent as EditSVG } from "src/assets/vectors/edit.svg";
import { ReactComponent as TrashSVG } from "src/assets/vectors/trash.svg";
import { ReactComponent as MenuDotsSVG } from "src/assets/vectors/menu_dots.svg";
import { ReactComponent as CheckSVG } from "src/assets/vectors/check.svg";
import { ReactComponent as XSVG } from "src/assets/vectors/x.svg";
import Input from "../Common/Inputs/Input";
import { useEffect, useState } from "react";
import usePermission from "src/hooks/usePermission";
import { EPermissions } from "src/enums/e_permissions";

export const RequestTemplate = ({
  template,
  type,
  activeId,
  setActiveId,
  onUpdate,
  onDelete,
  isDeleting,
  isSuccess,
  isLoading,
}) => {
  const canEdit = usePermission([EPermissions.ticket_settings_edit]);
  const canDelete = usePermission([EPermissions.ticket_settings_delete]);
  const [value, setValue] = useState("");

  useEffect(() => {
    if (isSuccess) {
      setActiveId({ id: 0, type: null });
    }
  }, [isSuccess]);

  return (
    <div
      className="h-10.5 flex items-center justify-between gap-x-4 px-4"
      key={template.id}
    >
      {activeId.id === template.id && activeId.type === type ? (
        <>
          <Input
            defaultValue={template.name}
            maxLength={32}
            onChange={(e) => setValue(e.target.value)}
          />
          <div className="flex gap-x-4">
            {isLoading ? (
              <div className="relative">
                <div className="w-6 h-6 border-blue opacity-20 border-2 rounded-full"></div>
                <div className="w-6 h-6 border-blue opacity-70 border-t-2 animate-spin rounded-full absolute left-0 top-0"></div>
              </div>
            ) : (
              <CheckSVG
                className="text-blue w-6 h-6 cursor-pointer"
                onClick={() => {
                  onUpdate(value);
                }}
              />
            )}

            <XSVG
              className="text-red w-6 cursor-pointer"
              onClick={() => setActiveId({ id: 0, type: null })}
            />
          </div>
        </>
      ) : (
        <>
          <p className="dark:text-white">
            {template.name} <span>({template.tickets_count})</span>
          </p>
          {(canEdit || canDelete) && (
            <Dropdown
              button={
                isDeleting && activeId.id == template.id ? (
                  <div className="relative">
                    <div className="w-6 h-6 border-light opacity-20 border-2 rounded-full"></div>
                    <div className="w-6 h-6 border-light opacity-70 border-t-2 animate-spin rounded-full absolute left-0 top-0"></div>
                  </div>
                ) : (
                  <MenuDotsSVG className="dark:text-white" />
                )
              }
              className="flex"
            >
              <div className="absolute top-[calc(100%+16px)] -right-2 bg-white dark:bg-lightBlack px-4 py-2 rounded-xl drop-shadow after:absolute after:right-4 after:-top-1.5 after:w-3 after:h-3 after:bg-white dark:after:bg-lightBlack after:rotate-45">
                <Menu.Item as={"div"} className="flex gap-x-4">
                  {canEdit && (
                    <EditSVG
                      className="h-10.5 text-blue cursor-pointer"
                      onClick={() => setActiveId({ id: template.id, type })}
                    />
                  )}

                  {canDelete && (
                    <TrashSVG
                      className="h-10.5 text-red cursor-pointer"
                      onClick={() => {
                        setActiveId({ id: template.id, type: null });
                        onDelete({ id: template.id, type });
                      }}
                    />
                  )}
                </Menu.Item>
              </div>
            </Dropdown>
          )}
        </>
      )}
    </div>
  );
};
