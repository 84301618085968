import { useState } from "react";
import t from "src/hooks/useTranslate";
import Shimmer from "../Common/Shimmer";
import { ReactComponent as InfoSVG } from "src/assets/vectors/info.svg";

const CallDencity = ({ data, isLoading, setParams }) => {
  const [selectedTab, setSelectedTab] = useState(1);

  const tabs = [
    {
      id: 1,
      key: "month",
      name: "monthly",
    },
    {
      id: 2,
      key: "quarter",
      name: "quarter",
    },
    {
      id: 3,
      key: "year",
      name: "yearly",
    },
  ];

  return (
    <div className="flex flex-col relative h-[280px] px-4 pt-[22px] pb-4 w-[calc(30%-12px)] bg-white dark:bg-lightBlack rounded-xl drop-shadow-lg">
      <div className="flex items-center justify-between">
        <h3 className="text-16 font-bold dark:text-white">
          {t("call_dencity")}
        </h3>
        <div className="flex gap-x-4">
          {tabs.map((tab) => (
            <button
              key={tab.id}
              className={`text-darkSnow ${
                selectedTab === tab.id
                  ? "underline font-semibold"
                  : "font-medium"
              }`}
              onClick={() => {
                setSelectedTab(tab.id);
                setParams(`?filter=${tab.key}`);
              }}
            >
              {t(tab.name)}
            </button>
          ))}
        </div>
      </div>
      <p className="text-gray mt-2 mb-5 text-left">
        {t("based_numbers_called")}
      </p>
      <div className="pr-4 overflow-auto">

      {data && !isLoading ? (
        data.data.length > 0 ? data.data.map((link, i) => (
          <div
            key={i}
            className="flex justify-between items-center mb-4 last-of-type:mb-0"
          >
            <p className="dark:text-white">{link.operator}</p>
            <p className="text-22 font-bold dark:text-white">{link.count}</p>
          </div>
        )) : (
          <div className="w-full p-4 flex items-center justify-center">
            <div className="w-fit flex items-center gap-x-3 p-4 rounded-xl bg-light dark:bg-softBlack text-gray dark:text-white">
              <InfoSVG />
              <p>Göstəriləcək hər hansı bir məlumat yoxdur</p>
            </div>
          </div>
        )
      ) : (
        <div className="space-y-4">
          {[...Array(3)].map((_, i) => (
            <Shimmer className="h-10.5" key={i} />
          ))}
        </div>
      )}
      </div>
    </div>
  );
};

export default CallDencity;
