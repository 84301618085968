import { useEffect, useState } from "react";
import t from "src/hooks/useTranslate";
import { useAddRequestTemplatesMutation } from "src/services/requestsService";
import Button from "../Common/Inputs/Button";
import { ReactComponent as PlusSVG } from "src/assets/vectors/plus.svg";
import Input from "../Common/Inputs/Input";

const RequestAddTemplateInput = ({ data }) => {
  const [value, setValue] = useState<any>("");
  const [addTemplate, { isLoading, isSuccess }] =
    useAddRequestTemplatesMutation();
  const onAddTemplate = async () => {
    let body = {
      type: data.type,
      data: { name: value },
    };
    await addTemplate(body);
  };

  useEffect(() => {
    if (isSuccess) {
      setValue("");
    }
  }, [isSuccess]);

  return (
    <div className="flex flex-1 items-end gap-x-4 p-4" key={data.id}>
      <Input
        label={t(data.title)}
        placeholder={t("enter_the_text")}
        defaultValue={value}
        maxLength={32}
        onChange={(e) => setValue(e.target.value)}
      />
      <Button
        className="w-8 !p-0"
        isLoading={isLoading}
        onClick={onAddTemplate}
      >
        <PlusSVG className="dark:text-white" />
      </Button>
    </div>
  );
};

export default RequestAddTemplateInput;
