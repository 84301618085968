import { useEffect, useState } from "react";
import t from "src/hooks/useTranslate";
import {
  useMashinAlAcceptedAnnouncementsQuery,
  useMashinAlModerationAverageQuery,
  useMashinAlModeratorAnnounceCountQuery,
} from "src/services/mashinAlDashboardService";
import AverageByTime from "../Dashboard/AverageByTime";
import PublishedAnnouncementsChart from "../Dashboard/PublishedAnnouncementsChart";
import TopPublished from "../Dashboard/TopPublished";

const MashinAlStatistics = ({ date }) => {
  /******ACCEPTED ANNOUNCEMENTS*******/

  const {
    data: acceptedAnnouncements,
    isLoading: isAcceptedAnnouncementsLoading,
  } = useMashinAlAcceptedAnnouncementsQuery("");

  /******ANNOUNCE COUNT FOR MODERATORS******/
  const [moderatorAnnounceCountParams, setModeratorAnnounceCountParams] =
    useState("");

  const {
    data: moderatorAnnounceCount,
    refetch: moderatorAnnounceCountRefetch,
    isFetching: isModeratorAnnounceCountLoading,
  } = useMashinAlModeratorAnnounceCountQuery(moderatorAnnounceCountParams);

  useEffect(() => {
    moderatorAnnounceCountRefetch();
  }, [moderatorAnnounceCountParams, date]);

  /******MODERATION AVERAGE*******/
  const [moderationAverageParams, setModerationAverageParams] = useState("");

  const {
    data: moderationAverage,
    refetch: moderationAverageRefetch,
    isFetching: isModerationAverageLoading,
  } = useMashinAlModerationAverageQuery(moderationAverageParams);

  useEffect(() => {
    moderationAverageRefetch();
  }, [moderationAverageParams]);

  useEffect(() => {
    setModerationAverageParams(date ? `?date=${date}` : "");
    setModeratorAnnounceCountParams(date ? `?date=${date}` : "");
  }, [date]);

  return (
    <div className="flex flex-wrap gap-6 px-4 pt-0 pb-6 w-full">
      <PublishedAnnouncementsChart
        title={t("accepted_announcements")}
        data={acceptedAnnouncements?.data}
        isLoading={isAcceptedAnnouncementsLoading}
      />
      <TopPublished
        title={t("announce_count_for_moderator")}
        data={moderatorAnnounceCount}
        isLoading={isModeratorAnnounceCountLoading}
        setParams={setModeratorAnnounceCountParams}
      />
      <AverageByTime
        title={t("average_announce_moderating_time")}
        data={moderationAverage}
        isLoading={isModerationAverageLoading}
        setParams={setModerationAverageParams}
      />
    </div>
  );
};

export default MashinAlStatistics;
