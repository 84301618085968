import { Disclosure, Transition } from "@headlessui/react";
import { FC } from "react";

const Accordion: FC<any> = ({
  accordionButton,
  defaultOpen,
  setClose,
  overflow = false,
  buttonClassName,
  children,
}) => {

  return (
    <Disclosure defaultOpen={defaultOpen}>
      {({ open, close }) => {
        setClose && open && close()
        return (
          <>
            <Disclosure.Button className={["w-full text-inherit", buttonClassName].join(" ")}>
              {accordionButton}
            </Disclosure.Button>
            <Transition
              className={overflow ? "overflow-visible" : "overflow-auto"}
              enter="transition transition-all duration-300 ease-in !overflow-hidden"
              enterFrom="transform max-h-0"
              enterTo="transform max-h-[2000px]"
              leave="transition transition-all duration-200 ease-out !overflow-hidden"
              leaveFrom="transform max-h-[2000px]"
              leaveTo="transform max-h-0"
            >
              <Disclosure.Panel>{children}</Disclosure.Panel>
            </Transition>
          </>
        );
      }}
    </Disclosure>
  );
};

export default Accordion;
