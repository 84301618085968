import React, { useEffect, useState } from "react";
import InputCalendar from "src/components/Common/Inputs/InputCalendar";
import DashboardInformations from "src/components/Finance/Dashboard/DashboardInformations";
import DashboardInformationsTable from "src/components/Finance/Dashboard/DashboardInformationsTable";
import Header from "src/components/Header/Header";
import { ddmmyyyy } from "src/helper/getFormattedDate";
import t from "src/hooks/useTranslate";
import {
  useFinanceDashboardQuery,
  useFinanceDashboardStatisticsQuery,
} from "src/services/financeService";

const FinanceDashboard = () => {
  const [statisticsParams, setStatisticsParams] = useState("");
  const {
    data: statisticsData,
    refetch: statisticsDataRefetch,
    isFetching: statisticsDataLoading,
  } = useFinanceDashboardStatisticsQuery(statisticsParams);

  useEffect(() => {
    statisticsDataRefetch();
  }, [statisticsParams]);

  const [tableParams, setTableParams] = useState("");
  const {
    data: tableData,
    refetch: tableDataRefetch,
    isFetching: tableDataLoading,
  } = useFinanceDashboardQuery(tableParams);

  useEffect(() => {
    tableDataRefetch();
  }, [tableParams]);

  return (
    <>
      <Header title={t("autosalon")} />
      <div className="space-y-6">
        <DashboardInformations
          data={statisticsData}
          isLoading={statisticsDataLoading}
          // setParams={setStatisticsParams}
        />
        <DashboardInformationsTable
          data={tableData}
          isLoading={tableDataLoading}
          setParams={setTableParams}
        />
      </div>
    </>
  );
};

export default FinanceDashboard;
