import { useEffect, useState, useRef } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
 import t from "src/hooks/useTranslate";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const LineChart = ({data}) => {
  const test = useRef(null);
   
  const [rootColor, setRootColor] = useState("");

  useEffect(() => {
    let r: any = document.querySelector(":root");
    let rs = getComputedStyle(r);
    setRootColor(rs.getPropertyValue("--primary-color"));
  }, []);

  const options = {
    responsive: true,
    maintainAspectRatio: false,

    scales: {
      xAxes: {
        grid: {
          color: "transparent",
        },
        ticks: {
          color: "white",
          padding: 24,
        },
      },
      yAxes: {
        grid: {
          color: "white",
          borderDash: [8, 4],
        },
        ticks: {
          color: "white",
          padding: 8,
          maxTicksLimit: 4
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        displayColors: false,
        backgroundColor: rootColor,
      },
    },
  };

  const labels = [
    t("january"),
    t("february"),
    t("march"),
    t("april"),
    t("may"),
    t("june"),
    t("july"),
    t("august"),
    t("september"),
    t("october"),
    t("november"),
    t("december"),
  ];

  const chartData = {
    labels,
    datasets: [
      {
        data: data?.map(el => el.count),

        borderColor: "white",
        backgroundColor: "white",
      },
    ],
  };
  return (
    <Line options={options} data={chartData} ref={test} />
  );
};

export default LineChart;
