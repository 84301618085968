import React, {
  ChangeEvent,
  SyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import t from "src/hooks/useTranslate";
import Header from "../Header/Header";
import { ReactComponent as BackSVG } from "src/assets/vectors/back.svg";
import Button from "../Common/Inputs/Button";
import Select from "../Common/Select";
import { IBaseSelect } from "src/types/Common/i_base_select";
import Input from "../Common/Inputs/Input";
import InputPhone from "../Common/Inputs/InputPhone";
import {
  emailRequiredPattern,
  phonePattern,
} from "src/utils/validationPatterns";
import Modal from "../Common/Modal";
import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import { resetRequestForm, setAllInputs } from "src/store/Requests/RequestsStore";
import InputFile from "../Common/Inputs/InputFile";
import InputSearch from "../Common/Inputs/InputSearch";
import TagSelect from "../Common/TagSelect";
import RequestConfigurationModal from "./RequestConfigurationModal";
import {
  useAddRequestMutation,
  useGetAllTemplatesQuery,
} from "src/services/requestsService";
import { useGetGroupAndPermissionsQuery } from "src/services/groupAndPermissionsService";
import { useGetUsersPartiallyMutation } from "src/services/usersService";
import { errorToast, successToast } from "src/store/Settings/toastStore";
import { validateName, validatePhone } from "src/helper/validation";
import usePermission from "src/hooks/usePermission";
import { EPermissions } from "src/enums/e_permissions";

export enum ERequestType {
  client = 1,
  internal,
}

const RequestCreate = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const canViewList = usePermission([EPermissions.ticket_settings_list]);
  const {
    data: templates,
    isLoading: isTemplatesLoading,
    isSuccess: isTemplatesLoaded,
  } = useGetAllTemplatesQuery("");
  const { data: groupAndPermissions } = useGetGroupAndPermissionsQuery("");
  const [getUsers, { data: users, isLoading: isUsersLoading }] =
    useGetUsersPartiallyMutation();
  const [modal, setModal] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [file, setFile] = useState<any>(null);
  const requestForm = useAppSelector((state) => state.requests.request_form);

  const fetchOperators = useCallback(
    async (search) => {
      await getUsers(
        `?search=${search}&pagination=0${
          requestForm.assigned_group_id
            ? "&role=" + requestForm.assigned_group_id
            : ""
        }`
      );
    },
    [requestForm.assigned_group_id]
  );

  const [addReview, { isLoading, isSuccess, error, isError }] =
    useAddRequestMutation();

    useEffect(() => {
      dispatch(resetRequestForm())
    }, [])
    

  const onRequestSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();
    let formData = new FormData();
    for (const key in requestForm) {
      if (key == "tags") {
        requestForm.tags.forEach((tag) => {
          formData.append("tags[]", tag.toString());
        });
        continue;
      }
      if (requestForm[key] == 0 || requestForm[key] == "") {
        continue;
      }
      formData.append(key, requestForm[key]);
    }

    file &&
      [...file].forEach((fl) => {
        formData.append("files[]", fl);
      });

    await addReview(formData);
  };

  useEffect(() => {
    if (
      (requestForm.type === ERequestType.client
        ? requestForm.source_type !== 0
        : true) &&
      requestForm.title_id !== 0 &&
      requestForm.project_id !== 0 &&
      requestForm.subject_id !== 0 &&
      requestForm.tags.length &&
      (requestForm.type === ERequestType.client
        ? validateName(requestForm.full_name, true)
        : true) &&
      (requestForm.type === ERequestType.client
        ? // @ts-ignore
          validatePhone(requestForm.phone, true)
        : true)
    ) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [requestForm]);

  useEffect(() => {
    if (isSuccess) {
      navigate("/requests");
      dispatch(successToast("Sorgu uğurla yaradıldı"));
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      Object.values(error["data"].errors).forEach((err) =>
        dispatch(errorToast(err[0]))
      );
    }
  }, [isError]);

  return (
    <>
      <Header title={t("requests")} />
      <div className="w-full relative bg-white dark:bg-lightBlack rounded-xl drop-shadow-lg z-10">
        <div className="flex items-center gap-x-4 p-4 border-b border-solid border-gray">
          <p className="text-16 font-bold dark:text-white">
            {t("new_request")}
          </p>
          {canViewList && (
            <Button
              className="bg-green text-white ml-auto"
              onClick={() => setModal(true)}
            >
              {t("settings")}
            </Button>
          )}

          <Link
            to="/requests"
            className="h-10.5 flex items-center gap-x-2 dark:text-white px-4 whitespace-nowrap rounded border border-solid border-gray"
          >
            <BackSVG />
            <p>{t("return_back")}</p>
          </Link>
        </div>
        <form className="p-4 grid grid-cols-2 gap-4" onSubmit={onRequestSubmit}>
          <div className="order-1">
            <Select
              data={[
                { id: 1, name: "client_request" },
                { id: 2, name: "internal_request" },
              ]}
              label={t("request_type")}
              defaultTitle={t("choose")}
              defaultValue={ERequestType.client}
              onChange={(e) =>
                dispatch(
                  setAllInputs({
                    key: "request_form",
                    name: "type",
                    value: e.id,
                  })
                )
              }
            />
          </div>
          {requestForm.type === ERequestType.client && (
            <div className="order-2">
              <Select
                data={[
                  { id: 1, name: "email" },
                  { id: 2, name: "phone" },
                ]}
                label={t("source_of_request")}
                defaultTitle={t("choose")}
                onChange={(e) =>
                  dispatch(
                    setAllInputs({
                      key: "request_form",
                      name: "source_type",
                      value: e.id,
                    })
                  )
                }
              />
            </div>
          )}
          <div className="order-3">
            <Select
              data={templates?.data.titles}
              label={t("title")}
              defaultTitle={t("choose")}
              onChange={(e) =>
                dispatch(
                  setAllInputs({
                    key: "request_form",
                    name: "title_id",
                    value: e.id,
                  })
                )
              }
            />
          </div>
          <div className="order-4">
            <Select
              data={templates?.data.projects}
              label={t("project_name")}
              defaultTitle={t("choose")}
              onChange={(e) =>
                dispatch(
                  setAllInputs({
                    key: "request_form",
                    name: "project_id",
                    value: e.id,
                  })
                )
              }
            />
          </div>
          <div className="order-5">
            <Select
              data={templates?.data.subjects}
              label={t("request_subject")}
              defaultTitle={t("choose")}
              onChange={(e) =>
                dispatch(
                  setAllInputs({
                    key: "request_form",
                    name: "subject_id",
                    value: e.id,
                  })
                )
              }
            />
          </div>
          <div className="order-6">
            <Select
              data={groupAndPermissions?.roles}
              label={t("group")}
              defaultTitle={t("choose")}
              onChange={(e) =>
                dispatch(
                  setAllInputs({
                    key: "request_form",
                    name: "assigned_group_id",
                    value: e.id,
                  })
                )
              }
            />
          </div>
          {requestForm.type === ERequestType.client && (
            <div className="order-7">
              <Input
                label={t("client_fullname")}
                maxLength={125}
                onChange={(e) =>
                  dispatch(
                    setAllInputs({
                      key: "request_form",
                      name: "full_name",
                      value: e.target.value,
                    })
                  )
                }
              />
            </div>
          )}
          <div className="order-8">
            <Select
              data={[
                { id: 1, name: "high" },
                { id: 2, name: "average" },
                { id: 3, name: "low" },
              ]}
              label={t("priority")}
              defaultTitle={t("choose")}
              defaultValue={2}
              onChange={(e) =>
                dispatch(
                  setAllInputs({
                    key: "request_form",
                    name: "priority_status",
                    value: e.id,
                  })
                )
              }
            />
          </div>
          {requestForm.type === ERequestType.client && (
            <div className="order-9">
              <InputPhone
                validpattern={phonePattern}
                label={t("phone") + "*"}
                onChange={(e) =>
                  dispatch(
                    setAllInputs({
                      key: "request_form",
                      name: "phone",
                      value: e,
                    })
                  )
                }
                name="phone"
              />
            </div>
          )}
          <div className="dark:text-white space-y-2 order-10">
            <p>{t("co_worker")}</p>
            <InputSearch
              data={users ? users.data : null}
              placeholder={t("search") + "..."}
              onInput={(query: string) => fetchOperators(query)}
              onChange={(e) =>
                dispatch(
                  setAllInputs({
                    key: "request_form",
                    name: "assigned_user_id",
                    value: e.id,
                  })
                )
              }
            ></InputSearch>
          </div>
          <div className="order-11">
            <Input
              label={t("email")}
              placeholder={t("email_placeholder")}
              maxLength={256}
              validpattern={emailRequiredPattern}
              onChange={(e) =>
                dispatch(
                  setAllInputs({
                    key: "request_form",
                    name: "email",
                    value: e.target.value,
                  })
                )
              }
              name="email"
            />
          </div>
          <div
            className={
              requestForm.type === ERequestType.client
                ? "order-12"
                : "order-[13]"
            }
          >
            <TagSelect
              data={templates?.data.tags}
              label={t("tags")}
              defaultTitle={t("choose")}
              onChange={(e) =>
                dispatch(
                  setAllInputs({
                    key: "request_form",
                    name: "tags",
                    value: e.map((item) => item.id),
                  })
                )
              }
            />
          </div>
          <div
            className={`dark:text-white space-y-2 ${
              requestForm.type === ERequestType.client
                ? "order-[13]"
                : "order-12"
            } `}
          >
            <p>{t("review")}</p>
            <textarea
              className="w-full h-72 p-4 border border-solid border-gray rounded resize-none dark:bg-softBlack cursor-auto"
              placeholder="Səbəbi yazın"
              rows={3}
              maxLength={1000}
              onChange={(e) =>
                dispatch(
                  setAllInputs({
                    key: "request_form",
                    name: "text",
                    value: e.target.value,
                  })
                )
              }
            />
          </div>
          <div className="order-[14] col-span-2">
            <InputFile
              // label={t("file") + "*"}
              fileName={file}
              multiple
              className="[&>div]:border-none [&>div]:!bg-transparent [&>div>div]:border [&>div>div]:rounded"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                // @ts-ignore
                setFile(e.target.files)
              }
            />
          </div>
          <Button
            className="bg-blue text-white order-[15] disabled:bg-gray"
            disabled={!isValid}
            isLoading={isLoading}
          >
            {t("create_request")}
          </Button>
        </form>
      </div>
      <Modal
        visible={modal}
        setVisible={setModal}
        dialogClassName="min-w-[1250px]"
      >
        <RequestConfigurationModal setVisible={setModal} />
      </Modal>
    </>
  );
};

export default RequestCreate;
