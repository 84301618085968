import { FC, useEffect, useState } from "react";
import FlipNumbers from "react-flip-numbers";
import t from "src/hooks/useTranslate";

export interface ICountDownAnimatedProps {
  hours: number;
  minutes: number;
  seconds?: number;
}

const CountDownAnimated: FC<ICountDownAnimatedProps> = ({
  hours,
  minutes,
  seconds,
}) => {
  const [timeLeft, setTimeLeft] = useState<number>(
    hours * 3600 + minutes * 60
  );
  const [fHour, setFHour] = useState("0")
  const [fMinute, setFMinute] = useState("0")
  // const [fSecond, setFSecond] = useState("0")

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft((prevTimeLeft) => prevTimeLeft - 60);
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const formattedTime =
      timeLeft > 0
        ? new Date(timeLeft * 1000).toISOString().substr(11, 8)
        : "00:00:00";
  
    setFHour(formattedTime.split(":")[0])
    setFMinute(formattedTime.split(":")[1])
    // setFSecond(formattedTime.split(":")[2])
  }, [timeLeft])

  const isDark =
    (window.matchMedia("(prefers-color-scheme: dark)").matches &&
      !localStorage.getItem("theme")) ||
    localStorage.getItem("theme") == "dark";

  return (
    <>
      <div className="flex gap-x-4">
        {typeof hours === "number" && (
          <div className="space-y-1 [&_span]:font-bold">
            <FlipNumbers
              height={53}
              width={40}
              color={isDark ? "white" : "black"}
              background="transparent"
              play
              perspective={400}
              numbers={fHour}
            />
            <p className="text-center uppercase">{t("hour")}</p>
          </div>
        )}
        {typeof minutes === "number" && (
          <div className="space-y-1 [&_span]:font-bold">
            <FlipNumbers
              height={53}
              width={40}
              color={isDark ? "white" : "black"}
              background="transparent"
              play
              perspective={400}
              numbers={fMinute}
            />
            <p className="text-center uppercase">{t("minute")}</p>
          </div>
        )}
        {/* {typeof seconds === "number" && (
          <div className="space-y-1 [&_span]:font-bold">
            <FlipNumbers
              height={53}
              width={40}
              color={isDark ? "white" : "black"}
              background="transparent"
              play
              perspective={400}
              numbers={fSecond}
            />

            <p className="text-center uppercase">{t("seconds")}</p>
          </div>
        )} */}
      </div>
      {/* <p>{fSecond}</p> */}
    </>
  );
};

export default CountDownAnimated;
