import React, { useEffect, useState } from "react";
import MashinAlAppTable from "src/components/Finance/MashinAlApp/MashinAlAppTable";
import Header from "src/components/Header/Header";
import t from "src/hooks/useTranslate";
import {
  useDeleteMashinalAppNotificationMutation,
  useMashinalAppNotificationsQuery,
} from "src/services/financeService";

type Props = {};

const MashinalApp = (props: Props) => {
  const [params, setParams] = useState("");
  const {
    data: notifications,
    refetch,
    isFetching,
  } = useMashinalAppNotificationsQuery(params);

  useEffect(() => {
    refetch();
    console.log("params: " + params);
  }, [params]);

  return (
    <>
      <Header title={t("Mashin.al APP")} />
      <MashinAlAppTable
        data={notifications}
        isLoading={isFetching}
        setParams={setParams}
      />
    </>
  );
};

export default MashinalApp;
