import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: { blacklist_form } = {
    blacklist_form: {
        phone: null,
        comment: ""
    }
}

export const blacklistSlice = createSlice({
    name: 'blacklist',
    initialState,
    reducers: {
        setAllInputs: (state, action: PayloadAction<any>) => {
            state.blacklist_form[action.payload.key] = action.payload.value
        },
        resetBlacklistForm: (state) => {
            Object.assign(state.blacklist_form, initialState.blacklist_form);
        },
    },
})

export const { setAllInputs, resetBlacklistForm } = blacklistSlice.actions

export default blacklistSlice.reducer