import { EGender } from "src/enums/e_gender";
import { ReactComponent as PaperclipSVG } from "src/assets/vectors/paperclip.svg";
import { ddmmyyyy, ddmmyyyy_hhmm } from "src/helper/getFormattedDate";

export enum ERequestAction {
  assign = "assign",
  reply = "reply",
}

const RequestChatMessage = ({ data, setModal, setFiles }) => {
  return (
    <>
      <div
        className={`flex rounded-xl px-4 py-3.5 my-2 gap-x-4 ${
          data?.action_type === ERequestAction.assign
            ? "bg-light dark:bg-softBlack"
            : "bg-softGreen dark:bg-green"
        }`}
      >
        <div className="w-14 h-14 rounded-full overflow-hidden drop-shadow">
          {data && (
            <img
              src={
                data.author.avatar
                  ? data.author.avatar
                  : data.author.gender === EGender.male
                  ? "/assets/images/male_avatar.png"
                  : "/assets/images/female_avatar.png"
              }
              className="w-full h-full object-cover"
              alt={data?.author.full_name}
            />
          )}
        </div>
        <div className="space-y-2 flex-1">
          <div className="flex justify-between gap-x-4">
            <p className="font-semibold">
              {data?.author.full_name}{" "}
              {data?.action_type === ERequestAction.assign
                ? "sorğunu yönəltdi: " + data?.assigned
                : "sorğuya cavab verdi:"}
            </p>
            <div className="text-gray text-right">
              <p>{ddmmyyyy_hhmm(new Date(data?.created_at))}</p>
              {data?.media &&
                (Object.values(data?.media).length === 1 ? (
                  <a
                    href={Object.values<any>(data?.media)[0].original_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center gap-x-1 text-blue font-medium"
                  >
                    <PaperclipSVG />{" "}
                    {Object.values<any>(data?.media)[0].file_name}
                  </a>
                ) : (
                  <p
                    className="flex items-center gap-x-1 text-blue cursor-pointer"
                    onClick={() => {
                      setFiles(Object.values(data?.media));
                      setModal(true);
                    }}
                  >
                    <PaperclipSVG /> Butun fayllar
                  </p>
                ))}
            </div>
          </div>
          <div className="flex items-center justify-between gap-x-4">
            <p
              className={`text-gray break-all ${
                data?.action_type === ERequestAction.assign
                  ? ""
                  : "dark:text-white"
              }`}
            >
              {data?.text}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default RequestChatMessage;
