import { ReactComponent as XSVG } from "src/assets/vectors/x.svg";
import { ReactComponent as InfoSVG } from "src/assets/vectors/info.svg";
import { EBreakType } from "src/enums/e_break_type";
import t from "src/hooks/useTranslate";
import Button from "./Inputs/Button";

const BreakInfoModal = ({ setModal, breakType, full_name, onSubmit }) => {
  return (
    <>
      <div className="flex items-center justify-between gap-x-4 p-4 border-b border-solid border-gray">
        <p className="text-16 font-bold dark:text-white">{`${
          breakType == EBreakType.lunch ? t("lunch_break") : t("break")
        } (${full_name})`}</p>
        <XSVG
          className="text-gray cursor-pointer dark:text-white"
          onClick={() => setModal(false)}
        />
      </div>
      <div className="flex flex-col items-center p-4 space-y-2">
        <div className="w-full h-[170px]">
          <img
            src={breakType == EBreakType.lunch ? "/assets/images/lunch.png" : "/assets/images/break.png"}
            alt="Session expired"
            className="w-full h-full object-contain"
          />
        </div>
        <div className="w-fit flex items-center gap-x-3 p-4 rounded-xl bg-light dark:bg-softBlack text-gray dark:text-white">
          <InfoSVG className="w-6 h-6 min-w-fit" />
          <p>
            {breakType == EBreakType.lunch
              ? "Nahar fasiləsi 60 dəqiqədir. Bu müddətdən sonra fasiləni sonlandırmadığınız təqdirdə, gündəlik ayrılan istirahət vaxtınızdan tutulacaq."
              : "Gündəlik təyin olunmuş ümumi istirahət müddəti, hər iş saat aralığı üçün 10 dəqiqə nəzərdə tutulub (Nahar vaxtı, ilk və son iş saatı istisna olmaqla). Bütün istirahət müddəti başa çatdıqdan sonra sistem avtomatik çıxış edəcək."}
          </p>
        </div>
        <Button className="w-full bg-blue text-white" onClick={onSubmit}>
          Fasilə götür
        </Button>
      </div>
    </>
  );
};

export default BreakInfoModal;
