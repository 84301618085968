import { FC, useState } from "react";
import InputPassword from "./Inputs/InputPassword";
import { ReactComponent as InfoSVG } from "src/assets/vectors/info.svg";
import t from "src/hooks/useTranslate";
import Button from "./Inputs/Button";
import CountDownAnimated from "./CountDownAnimated";
import { passwordRequiredPattern } from "src/utils/validationPatterns";
import { validatePassword } from "src/helper/validation";
import { EBreakType } from "src/enums/e_break_type";

interface IBreakTimesModalProps {
  onSubmit: (password: string) => void;
  isLoading: boolean;
  time: {hour: number, minute: number}
  full_name: string,
  breakType: EBreakType
}

const BreakTimesModal: FC<IBreakTimesModalProps> = ({
  onSubmit,
  isLoading,
  time,
  full_name,
  breakType
}) => {
  const [password, setPassword] = useState("");

  return (
    <div className="fixed inset-0 z-50 bg-gray/30 flex justify-center items-center">
      <div className="min-w-[567px] bg-white rounded-md shadow-md space-y-2">
        <div className="flex-1 border-b border-gray">
          <p className="p-4 text-16 font-bold text-center">{`${breakType == EBreakType.lunch ? t("lunch_break") : t(
            "break"
          )} (${full_name})`}</p>
        </div>
        <div className="py-6 px-10">
          <div className="w-[400px] mx-auto flex flex-col items-center gap-y-4">
            <CountDownAnimated hours={time.hour} minutes={time.minute} />
            <div className="flex items-center justify-center">
              <div className="w-fit flex items-center gap-x-3 p-4 rounded-xl bg-light dark:bg-softBlack text-gray dark:text-white">
                <InfoSVG className="min-w-[24px] min-h-[24px]" />
                <p>
                  Fasiləni sonlandırmaq üçün sizə məxsus şifrəni daxil edib,
                  fasiləni sonlandır düyməsini kliklə
                </p>
              </div>
            </div>
            <form
              className="w-full space-y-6"
              onSubmit={(e) => {
                e.preventDefault();
                onSubmit(password);
              }}
            >
              <InputPassword
                placeholder={t("password_placeholder")}
                validpattern={passwordRequiredPattern}
                maxLength={64}
                onChange={(e) => setPassword(e.target.value)}
                name="password"
              />
              <Button
                className="w-full bg-blue text-white disabled:bg-gray"
                isLoading={isLoading}
                disabled={!validatePassword(password, true)}
              >
                Fasiləni sonlandır
              </Button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BreakTimesModal;
