import { emailPattern, emailRequiredPattern, namePattern, nameRequiredPattern, passwordPattern, passwordRequiredPattern, phonePattern, phoneRequiredPattern } from "src/utils/validationPatterns";

export const validateName = (val: string, required: boolean = false) => {
  const pattern = namePattern;
  const requiredPattern = nameRequiredPattern;
  return required ? requiredPattern.test(val) : pattern.test(val);
};

export const validateEmail = (val: string, required: boolean = false) => {
  const pattern = emailPattern
  const requiredPattern = emailRequiredPattern
  return required ? requiredPattern.test(val) : pattern.test(val);
};

export const validatePhone = (val: number, required: boolean = false) => {
  const pattern = phonePattern
  const requiredPattern = phoneRequiredPattern
  return required ? requiredPattern.test(val as unknown as string) : pattern.test(val as unknown as string);
};

export const validatePassword = (val: string, required: boolean = false) => {
  const pattern = passwordPattern
  const requiredPattern = passwordRequiredPattern
  return required ? requiredPattern.test(val) : pattern.test(val);
};

export const validateRequired = (val: string[]) => {
  return !val.includes("")
}