import React, { memo } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

export const options = {
  cutout: 75,
  plugins: {
    title: {
      display: false,
      text: "Chart.js",
    },
    legend: {
      display: false, // Hide the legend with labels and colors
    },
  },

  responsive: false,
};

const DoughnutChart = ({ chartData, width = 190, height = 180 }) => {
  const data = {
    labels: ["Red", "Blue"],
    datasets: [
      {
        label: "# of Votes",
        data: [chartData.answered, chartData.no_answer],
        backgroundColor: ["#39CEF3", "#64BD79"],
        borderWidth: 0.2,
        //   borderRadius: [40, 0, 0],
      },
    ],
  };
  return (
    <Doughnut data={data} options={options} width={width} height={height} />
  );
};

export default memo(DoughnutChart);
