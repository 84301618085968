import React from 'react'
import Header from 'src/components/Header/Header'
import AutoLogsTable from 'src/components/MashinAl/AutoLogsTable'
import t from 'src/hooks/useTranslate'

const AutoLogs = () => {
  return (
    <>
    <Header title={t("auto_history") + " " + t("logs")} backLink="/auto-history" />
    <AutoLogsTable />
    </>
  )
}

export default AutoLogs