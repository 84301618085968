import React, { useEffect, useState } from "react";
 import t from "src/hooks/useTranslate";
import Header from "src/components/Header/Header";
import { ReactComponent as CheckSVG } from "src/assets/vectors/check.svg";
import { ETheme } from "src/enums/e_theme";
import { useAppDispatch } from "src/hooks/redux";
import { changeTheme } from "src/store/Settings/themeStore";

const InterfaceSetting = () => {
   
  const dispatch = useAppDispatch();
  const [theme, setTheme] = useState<ETheme>(
    window.matchMedia("(prefers-color-scheme: dark)").matches &&
      !localStorage.getItem("theme")
      ? ETheme.dark
      : localStorage.getItem("theme") === ETheme.dark
      ? ETheme.dark
      : ETheme.light
  );

  return (
    <>
      <Header title={t("interface")} backLink />
      <div className="relative px-4 py-6 bg-white dark:bg-lightBlack rounded-xl text-center drop-shadow-lg">
        <h1 className="text-22 font-bold text-left dark:text-white uppercase underline underline-offset-8 mb-6">
          {t("interface")}
        </h1>
        <div className="flex flex-col flex-[0.5] p-4 bg-light dark:bg-dark rounded">
          <h2 className="text-left text-16 font-bold mb-4 dark:text-white">
            {t("interface_options")}
          </h2>
          <div className="h-full flex gap-x-4">
            <div
              className="flex flex-col gap-y-4 flex-1 p-4 bg-white rounded cursor-pointer"
              onClick={() => {
                if (theme === ETheme.dark) {
                  dispatch(changeTheme());
                  setTheme(ETheme.light);
                }
              }}
            >
              <p className="text-left font-medium">{t("light_mode")}</p>
              <div className="h-full flex justify-center items-center select-none">
                <img
                  src="assets/images/light.gif"
                  className="w-32 h-32 object-contain"
                  alt="light mode"
                />
              </div>
              <div className="min-h-[24px]">
                {theme === ETheme.light && (
                  <CheckSVG className="h-6 w-6 ml-auto p-1 bg-green text-white rounded-full" />
                )}
              </div>
            </div>
            <div
              className="flex flex-col gap-y-4 flex-1 p-4 bg-lightBlack rounded cursor-pointer"
              onClick={() => {
                if (theme === ETheme.light) {
                  dispatch(changeTheme());
                  setTheme(ETheme.dark);
                }
              }}
            >
              <p className="text-left font-medium text-white">
                {t("dark_mode")}
              </p>
              <div className="h-full flex justify-center items-center select-none">
                <img
                  src="assets/images/dark.gif"
                  className="w-32 h-32 object-contain"
                  alt="night mode"
                />
              </div>
              <div className="min-h-[24px]">
                {theme === ETheme.dark && (
                  <CheckSVG className="h-6 w-6 ml-auto p-1 bg-green text-white rounded-full" />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InterfaceSetting;
