import { EGender } from "src/enums/e_gender";
import { useGetTopOperatorsQuery } from "src/services/operatorsService";
import Shimmer from "../Common/Shimmer";

const TopOperators = () => {
  const { data: topOperators, isSuccess: isReady } =
    useGetTopOperatorsQuery("");

  return (
    <div className="grid grid-cols-4 gap-x-4 dark:text-white">
      {isReady ? (
        topOperators.map((top: any, i: number) => (
          <div
            className="flex flex-col gap-y-4 py-6 px-8 bg-white dark:bg-softBlack rounded-lg drop-shadow"
            key={i}
          >
            <p className="text-16 font-bold">{top.text}</p>
            <div className="flex items-center gap-x-4 mt-auto">
              <div className="w-16 h-16 rounded-full overflow-hidden">
                <img
                  src={
                    top.data.user.avatar
                      ? top.data.user.avatar
                      : top.data.user.gender === EGender.male
                      ? "/assets/images/male_avatar.png"
                      : "/assets/images/female_avatar.png"
                  }
                  alt={top.data.user.full_name}
                  className="w-full h-full object-cover"
                />
              </div>
              <div className="flex flex-col justify-between gap-y-2">
                <p>{top.data.user.full_name}</p>
                <span className="min-w-[60px] py-1 px-3 bg-green text-white text-center text-16 font-bold rounded w-min">
                  {top.data.announce_count}
                </span>
              </div>
            </div>
          </div>
        ))
      ) : (
        <>
          <Shimmer className="h-44" />
          <Shimmer className="h-44" />
          <Shimmer className="h-44" />
          <Shimmer className="h-44" />
        </>
      )}
    </div>
  );
};

export default TopOperators;
