import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { getCookie } from "src/helper/custom_cookies"

export const workingHoursKPIService = createApi({
    reducerPath: "workingHoursKPIService",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_BASE_URL,
        prepareHeaders: (headers) => {
            headers.set("Authorization", getCookie("mcrmtkn") ? "Bearer " + getCookie("mcrmtkn") : "")
            headers.set('Accept', 'application/json')
            return headers
        },
        // credentials: "include",
    }),
    tagTypes: ["WorkingHoursKPI"],
    endpoints: (builder) => ({
        workingHoursReportKPI: builder.query({
            query: (query) => {
                return {
                    url: `/calls/working_hours_report${query}`,
                }
            },
            providesTags: ["WorkingHoursKPI"]
        }),
        workingHoursReportKPIExcel: builder.mutation({
            query: (query) => {
                return {
                    url: `/calls/working_hours_report${query}`,
                    method: "get",
                    responseHandler: async (response) => window.location.assign(window.URL.createObjectURL(await response.blob())),
                }
            },
        }),
        ping: builder.mutation({
            query: () => {
                return {
                    url: "/working_hour/ping",
                }
            },
        }),
    })
})

export const { useWorkingHoursReportKPIQuery, useWorkingHoursReportKPIExcelMutation, usePingMutation } = workingHoursKPIService