import { useRoutes } from "react-router-dom";
import Main from "src/layout/Main";
import Home from "src/pages/Home";
import Login from "src/pages/Login";
import Users from "src/pages/HR/Users/Users";
import UsersCreate from "src/pages/HR/Users/UsersCreate";
import Inner from "src/layout/Inner";
import ProtectedRoutes from "./ProtectedRoutes";
import Groups from "src/pages/HR/Groups/Groups";
import GroupsCreate from "src/pages/HR/Groups/GroupsCreate";
import UsersEdit from "src/pages/HR/Users/UsersEdit";
import Permissions from "src/pages/HR/Permissions/Permissions";
import GroupsEdit from "src/pages/HR/Groups/GroupsEdit";
import Profile from "src/pages/Profile";
import Announcements from "src/pages/MCRM/Announcements";
import Operators from "src/pages/MCRM/Operators";
import OperatorWorkSchedule from "src/pages/MCRM/OperatorWorkSchedule";
import ShiftChange from "src/pages/MCRM/ShiftChange";
import OperatorsWorkingHoursKPI from "src/pages/HR/OperatorsWorkingHoursKPI";
import Requests from "src/pages/MCRM/Requests/Requests";
import InterfaceSetting from "src/pages/Settings/InterfaceSetting";
import RequestCreate from "src/components/Requests/RequestCreate";
import RequestDetails from "src/pages/MCRM/Requests/RequestDetails";
import FAQ from "src/pages/MCRM/FAQ/FAQ";
import FAQCreate from "src/pages/MCRM/FAQ/FAQCreate";
import FAQEdit from "src/pages/MCRM/FAQ/FAQEdit";
import Vacations from "src/pages/HR/Vacations/Vacations";
import Blacklist from "src/pages/MCRM/Blacklist";
import FinanceDashboard from "src/pages/Finance/Dashboard/FinanceDashboard";
import FinanceIndividual from "src/pages/Finance/Individual/FinanceIndividual";
import Garage from "src/pages/Finance/Garage/Garage";
import Credentials from "src/pages/Finance/Credentials/Credentials";
import MashinAlDashboard from "src/pages/MashinAl/Dashboard/MashinAlDashboard";
import AutoHistory from "src/pages/MashinAl/AutoHistory/AutoHistory";
import AutoLogs from "src/pages/MashinAl/AutoHistory/AutoLogs";
import Targets from "src/pages/MCRM/Targets";
import { EPermissions } from "src/enums/e_permissions";
import HasPermission from "src/components/Common/HasPermission";
import Notifications from "src/pages/Notifications";
import FinanceDetails from "src/pages/Finance/Dashboard/FinanceDetails";
import Test from "src/pages/Test";
import Reports from "src/pages/MCRM/Reports";
import NotFound from "src/pages/NotFound";
import MashinalApp from "src/pages/Finance/MashinalApp/MashinalApp";
import MashinalAppNotificationCreate from "src/pages/Finance/MashinalApp/MashinalAppNotificationCreate";
import MashinalAppNotificationEdit from "src/pages/Finance/MashinalApp/MashinalAppNotificationEdit";
import MashinalAppRoutes from "src/pages/Finance/MashinalApp/MashinalAppRoutes";
import FinanceUserOperations from "src/pages/Finance/UserOperations/FinanceUserOperations";
import MashinalAppNotificationStatistics from "src/pages/Finance/MashinalApp/MashinalAppNotificationStatistics";
import DashboardRingo from "src/pages/Ringo/DashbordRingo";
import Announcements2 from "../pages/MCRM/AnnouncementTwo";
import Details from "../pages/MCRM/AnnouncementTwo/Details";

const AppRoutes = () => {
  const routesConfig = [
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/test",
      element: <Test />,
    },
    {
      element: <ProtectedRoutes />,
      children: [
        {
          element: <Inner />,
          children: [
            {
              path: "/users/*",
              children: [
                {
                  index: true,
                  element: (
                      <HasPermission viewPermissions={[EPermissions.user_list]}>
                        <Users />
                      </HasPermission>
                  ),
                },
                {
                  path: "create",
                  element: (
                      <HasPermission
                          viewPermissions={[
                            EPermissions.user_list,
                            EPermissions.user_create,
                          ]}
                      >
                        <UsersCreate />
                      </HasPermission>
                  ),
                },
                {
                  path: "edit/:userId",
                  element: (
                      <HasPermission
                          viewPermissions={[
                            EPermissions.user_list,
                            EPermissions.user_edit,
                          ]}
                      >
                        <UsersEdit />
                      </HasPermission>
                  ),
                },
              ],
            },
            {
              path: "/groups/*",
              children: [
                {
                  index: true,
                  element: (
                      <HasPermission viewPermissions={[EPermissions.group_list]}>
                        <Groups />
                      </HasPermission>
                  ),
                },
                {
                  path: "create",
                  element: (
                      <HasPermission
                          viewPermissions={[
                            EPermissions.group_list,
                            EPermissions.group_create,
                          ]}
                      >
                        <GroupsCreate />
                      </HasPermission>
                  ),
                },
                {
                  path: "edit/:groupId",
                  element: (
                      <HasPermission
                          viewPermissions={[
                            EPermissions.group_list,
                            EPermissions.group_edit,
                          ]}
                      >
                        <GroupsEdit />
                      </HasPermission>
                  ),
                },
              ],
            },
            {
              path: "/announcements",
              element: (
                  <HasPermission viewPermissions={[EPermissions.announce_list]}>
                    <Announcements />
                  </HasPermission>
              ),
            },
            {
              path: "/announcementsTwo",
              children: [
                {
                  index: true,
                  element: (
                      <HasPermission viewPermissions={[EPermissions.announce_list]}>
                        <Announcements2 />
                      </HasPermission>
                  ),
                },
                {
                  path: "/announcementsTwo/details",
                  element: (
                      <HasPermission viewPermissions={[EPermissions.announce_list]}>
                        <Details  />
                      </HasPermission>
                  ),
                },
              ],

            },
            {
              path: "/interface-setting",
              element: <InterfaceSetting />,
            },
            // {
            //   path: "/turbo-guru/*",
            //   children: [
            //     {
            //       index: true,
            //       element: <TurboGuru />,
            //     },
            //     {
            //       path: "edit/:modelId",
            //       element: <TurboGuruEdit />,
            //     },
            //     {
            //       path: "view/:modelId",
            //       element: <TurboGuruView />,
            //     },
            //   ],
            // },
            {
              path: "/auto-history/*",
              children: [
                {
                  index: true,
                  element: <AutoHistory />,
                },
                {
                  path: "logs",
                  element: <AutoLogs />,
                },
              ],
            },
          ],
        },
        {
          element: <Main />,
          children: [
            {
              path: "/",
              element: (
                  <HasPermission viewPermissions={[EPermissions.report_list]}>
                    <Home />
                  </HasPermission>
              ),
            },
            {
              path: "/profile",
              element: <Profile />,
            },
            {
              path: "/notifications",
              element: <Notifications />,
            },
            {
              path: "/permissions",
              element: (
                  <HasPermission viewPermissions={[EPermissions.time_off_list]}>
                    <Permissions />
                  </HasPermission>
              ),
            },
            {
              path: "/vacations",
              element: (
                  <HasPermission viewPermissions={[EPermissions.vacations_list]}>
                    <Vacations />
                  </HasPermission>
              ),
            },
            {
              path: "/ringo/*",
              children: [
                {
                  index: true,
                  element: (
                      <HasPermission
                          viewPermissions={[EPermissions.ringo_stat_list]}
                      >
                        <DashboardRingo />
                      </HasPermission>
                  ),
                },
              ],
            },
            {
              path: "/operators/*",
              children: [
                {
                  index: true,
                  element: (
                      <HasPermission
                          viewPermissions={[EPermissions.call_center_list]}
                      >
                        <Operators />
                      </HasPermission>
                  ),
                },
                {
                  path: "work-schedule",
                  element: (
                      <HasPermission
                          viewPermissions={[EPermissions.call_center_create]}
                      >
                        <OperatorWorkSchedule />
                      </HasPermission>
                  ),
                },
              ],
            },
            {
              path: "/shift-change",
              element: (
                  <HasPermission
                      viewPermissions={[
                        EPermissions.shift_change_list,
                        EPermissions.shift_change_change,
                      ]}
                      atLeast
                  >
                    <ShiftChange />
                  </HasPermission>
              ),
            },
            {
              path: "/reports",
              element: (
                  <HasPermission viewPermissions={[EPermissions.report_list]}>
                    <Reports />
                  </HasPermission>
              ),
            },
            {
              path: "/targets",
              element: (
                  <HasPermission viewPermissions={[EPermissions.target_list]}>
                    <Targets />
                  </HasPermission>
              ),
            },
            {
              path: "/working-hours-kpi",
              element: (
                  <HasPermission
                      viewPermissions={[EPermissions.call_center_working_hours]}
                  >
                    <OperatorsWorkingHoursKPI />
                  </HasPermission>
              ),
            },
            {
              path: "/blacklist",
              element: (
                  <HasPermission viewPermissions={[EPermissions.black_list_list]}>
                    <Blacklist />
                  </HasPermission>
              ),
            },
            {
              path: "/requests/*",
              children: [
                {
                  index: true,
                  element: <Requests />,
                },
                {
                  path: "create",
                  element: <RequestCreate />,
                },
                {
                  path: ":requestId",
                  element: <RequestDetails />,
                },
              ],
            },
            {
              path: "/faq/*",
              children: [
                {
                  index: true,
                  element: (
                      <HasPermission viewPermissions={[EPermissions.faq_list]}>
                        <FAQ />
                      </HasPermission>
                  ),
                },
                {
                  path: "create",
                  element: (
                      <HasPermission viewPermissions={[EPermissions.faq_create]}>
                        <FAQCreate />
                      </HasPermission>
                  ),
                },
                {
                  path: "edit/:faqId",
                  element: (
                      <HasPermission viewPermissions={[EPermissions.faq_edit]}>
                        <FAQEdit />
                      </HasPermission>
                  ),
                },
              ],
            },
            {
              path: "/mashin-al-autosalon/*",
              children: [
                {
                  index: true,
                  element: (
                      <HasPermission
                          viewPermissions={[EPermissions.finance_mashin_al]}
                      >
                        <FinanceDashboard />
                      </HasPermission>
                  ),
                },
                {
                  path: ":autosalonId",
                  element: (
                      <HasPermission
                          viewPermissions={[EPermissions.finance_mashin_al]}
                      >
                        <FinanceDetails />
                      </HasPermission>
                  ),
                },
              ],
            },
            {
              path: "/mashin-al-individual",
              element: (
                  <HasPermission
                      viewPermissions={[EPermissions.finance_mashin_al]}
                  >
                    <FinanceIndividual />
                  </HasPermission>
              ),
            },
            {
              path: "/mashin-al-user-operations",
              element: (
                  <HasPermission
                      viewPermissions={[EPermissions.finance_mashin_al]}
                  >
                    <FinanceUserOperations />
                  </HasPermission>
              ),
            },
            {
              path: "/mashin-al-garage",
              element: (
                  <HasPermission
                      viewPermissions={[EPermissions.finance_mashin_al]}
                  >
                    <Garage />
                  </HasPermission>
              ),
            },
            {
              path: "/credentials",
              element: (
                  <HasPermission
                      viewPermissions={[EPermissions.finance_mashin_al]}
                  >
                    <Credentials />
                  </HasPermission>
              ),
            },
            {
              path: "/mashin-al-app/*",
              children: [
                {
                  index: true,
                  element: (
                      <HasPermission
                          viewPermissions={[EPermissions.finance_mashin_al]}
                      >
                        <MashinalApp />
                      </HasPermission>
                  ),
                },
                {
                  path: "create",
                  element: (
                      <HasPermission
                          viewPermissions={[EPermissions.finance_mashin_al]}
                      >
                        <MashinalAppNotificationCreate />
                      </HasPermission>
                  ),
                },
                {
                  path: "edit/:mashinalAppNotificationId",
                  element: (
                      <HasPermission
                          viewPermissions={[EPermissions.finance_mashin_al]}
                      >
                        <MashinalAppNotificationEdit />
                      </HasPermission>
                  ),
                },
                {
                  path: "statistics",
                  element: (
                      <HasPermission
                          viewPermissions={[EPermissions.finance_mashin_al]}
                      >
                        <MashinalAppNotificationStatistics />
                      </HasPermission>
                  ),
                },
                {
                  path: "routes",
                  element: (
                      <HasPermission
                          viewPermissions={[EPermissions.finance_mashin_al]}
                      >
                        <MashinalAppRoutes />
                      </HasPermission>
                  ),
                },
              ],
            },
            {
              path: "/mashin-al-moderators",
              element: (
                  <HasPermission viewPermissions={[EPermissions.mashin_al_list]}>
                    <MashinAlDashboard />
                  </HasPermission>
              ),
            },
            // {
            //   path: "/telegram-bot",
            //   element: <TelegramBot />,
            // },
          ],
        },
      ],
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ];

  const routes = useRoutes(routesConfig);

  return routes;
};

export default AppRoutes;
