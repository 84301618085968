import { useEffect, useMemo, useState } from "react";
import { ddmmyyyy } from "src/helper/getFormattedDate";
import t from "src/hooks/useTranslate";
import CalendarPopover from "../Common/CalendarPopover";
import InputCalendar from "../Common/Inputs/InputCalendar";
import months from "src/data/months.json";
import Shimmer from "../Common/Shimmer";

const RejectionAccordingTime = ({ data, isLoading, setParams }) => {
  const [selectedTab, setSelectedTab] = useState(1);
  const [paramsData, setParamsData] = useState(new URLSearchParams());

  const hours = [
    {
      id: 1,
      name: "10:00",
    },
    {
      id: 2,
      name: "11:00",
    },
    {
      id: 3,
      name: "12:00",
    },
    {
      id: 4,
      name: "13:00",
    },
    {
      id: 5,
      name: "14:00",
    },
    {
      id: 6,
      name: "15:00",
    },
    {
      id: 7,
      name: "16:00",
    },
    {
      id: 8,
      name: "17:00",
    },
    {
      id: 9,
      name: "18:00",
    },
    {
      id: 10,
      name: "19:00",
    },
    {
      id: 11,
      name: "20:00",
    },
    {
      id: 12,
      name: "21:00",
    },
  ];

  const [selectedData, setSelectedData] = useState(hours);
  const [value, setValue] = useState(ddmmyyyy(new Date()));

  const tabs = [
    {
      id: 1,
      key: "day",
      name: "daily",
    },
    {
      id: 2,
      key: "year",
      name: "yearly",
    },
  ];

  useEffect(() => {
    selectedTab == 1 ? setSelectedData(hours) : setSelectedData(months);
  }, [selectedTab]);

  useEffect(() => {
    setParams("?" + paramsData.toString());
  }, [selectedTab, value]);

  const totalRejection = useMemo(() => {
    let total = 0;
    for (let i = 0; i < data?.data.length; i++) {
      total += data.data[i].count;
    }
    return total;
  }, [data]);

  const mostRejection = useMemo(() => {
    let total = 0;
    let most;
    for (let i = 0; i < data?.data.length; i++) {
      if (data.data[i].count > total) {
        total = data.data[i].count;
        most = data.data[i].id;
      }
    }
    return most;
  }, [data]);

  return (
    <div className="flex flex-col relative h-[280px] px-4 py-6 w-[calc(70%-12px)] bg-white dark:bg-lightBlack rounded-xl drop-shadow-lg z-10">
      <div className="flex items-center justify-between mb-2">
        <h3 className="text-16 font-bold dark:text-white">
          {t("rejection_dynamics")}
        </h3>
        <div className="flex gap-x-4">
          {tabs.map((tab) => (
            <button
              key={tab.id}
              className={`text-darkSnow ${
                selectedTab === tab.id
                  ? "underline font-semibold"
                  : "font-medium"
              }`}
              onClick={() => {
                paramsData.set("filter", tab.key);
                setSelectedTab(tab.id);
              }}
            >
              {t(tab.name)}
            </button>
          ))}
        </div>
      </div>
      <div className="h-full flex flex-col justify-between">
        {selectedTab == 1 ? (
          <CalendarPopover
            minDate={new Date("01.01.2019")}
            maxDate={new Date()}
            value={value}
            onChange={(e) => {
              paramsData.set("date", e);
              setValue(e);
            }}
          />
        ) : (
          <div className="relative w-52">
            <InputCalendar
              className="z-20"
              maxDetail={"year"}
              monthView
              value={value}
              minDate={new Date("01.01.2019")}
              maxDate={new Date()}
              //@ts-ignore
              onChange={(e) => {
                paramsData.set("date", e);
                setValue(e);
              }}
            />
          </div>
        )}
        <div className="space-y-4">
          <div className="h-12 w-full flex items-center px-2 border border-solid border-gray rounded-lg">
            {selectedData.map((item, i) => (
              <div
                className="relative h-10.5 flex items-center justify-center flex-1 [&>div]:last:hidden"
                key={i}
              >
                <p
                  className={`text-center dark:text-white ${
                    selectedTab == 1 ? "text-14" : "-rotate-[25deg] text-12"
                  }`}
                >
                  {selectedTab == 1 ? item.name : t(item.name)}
                </p>
                {data && !isLoading ? (
                  data?.data.some((reject) => reject.id == item.id) && (
                    <div className="absolute flex items-center hover:z-20 -top-6 left-1/2 h-4 w-[calc(100%-8px)] cursor-pointer group">
                      <div
                        className={`w-full h-1 rounded-full mt-1 ${
                          mostRejection == item.id ? "bg-red" : "bg-gray"
                        }`}
                      ></div>
                      <div
                        className={`absolute bottom-[calc(100%+16px)] left-1/2 -translate-x-1/2 px-4 py-2 text-white rounded hidden whitespace-nowrap group-hover:flex group-hover:items-center group-hover:gap-x-2 after:absolute after:bottom-0 after:left-1/2 after:-translate-x-1/2 after:translate-y-1/2 after:bg-inherit after:rotate-45 after:h-2 after:w-2 ${
                          mostRejection == item.id ? "bg-red" : "bg-gray"
                        }`}
                      >
                        {data?.data.find((reject) => reject.id == item.id)
                          ?.count || "0"}
                        <span className="ml-0.5">etiraz</span>
                      </div>
                    </div>
                  )
                ) : (
                  <Shimmer className="!absolute bottom-[calc(100%+12px)] left-1/2 w-[calc(100%-8px)] h-1" />
                )}
              </div>
            ))}
          </div>
          <div className="flex dark:text-white">
            <p className="text-16 font-bold">Cəmi: {totalRejection} etiraz</p>
            <div className="flex items-center ml-auto gap-x-6 text-gray">
              <div className="flex items-center gap-x-2">
                <div className="w-6 h-6 rounded bg-red" />
                <p>Ən çox etiraz</p>
              </div>
              <div className="flex items-center gap-x-2">
                <div className="w-6 h-6 rounded bg-gray" />
                <p>Etiraz</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RejectionAccordingTime;
