import React from 'react'

const Footer = () => {
  return (
    <div className="flex items-center justify-between pt-8 pb-4 mt-auto text-14 font-semibold text-darkBlue">
        <p className='dark:text-white'>Copyright © {new Date().getFullYear()} </p>
        <p className='dark:text-white'>Made with ❤️ by Alventures </p>
    </div>
  )
}

export default Footer