import { ReactComponent as ThreeUserSvg } from "src/assets/vectors/three_user.svg";
import { ReactComponent as UserPermissionSvg } from "src/assets/vectors/user_permission.svg";
import { ReactComponent as UsersSvg } from "src/assets/vectors/users.svg";
import { ReactComponent as ResponsibleSvg } from "src/assets/vectors/responsible.svg";
import { EGroupsCrudSteps } from "src/enums/e_groups_crud_steps";
import { ITabStep } from "src/types/Common/i_tab_step";

export const GroupsStepsData: ITabStep[] = [
  {
    id: EGroupsCrudSteps.form,
    title: "create_group",
    icon: ThreeUserSvg,
  },
  {
    id: EGroupsCrudSteps.permissions,
    title: "group_permissions",
    icon: UserPermissionSvg,
  },
  {
    id: EGroupsCrudSteps.users,
    title: "users",
    icon: UsersSvg,
  },
  {
    id: EGroupsCrudSteps.responsible_person,
    title: "responsible_person",
    icon: ResponsibleSvg,
  },
];
