import { useState, useEffect } from "react";

interface Props {
  data: { hour: number; minute: number };
}

const CountDownHHMM = ({ data }: Props) => {
  const [hour, setHour] = useState(data.hour);
  const [minute, setMinute] = useState(data.minute);

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (minute > 0) {
        setMinute(minute - 1);
      }
      if (minute <= 0) {
        if (hour <= 0) {
          clearInterval(myInterval);
        } else {
          setHour(hour - 1);
          setMinute(59);
        }
      }
    }, 1000 * 60);
    return () => {
      clearInterval(myInterval);
    };
  });

  return (
        <p className="w-10">
          {hour < 10 ? `0${hour}` : hour}:
          {minute < 10 ? `0${minute}` : minute}
        </p>
  );
};

export default CountDownHHMM;
