import React, { useEffect, useState } from "react";
import StackedBar from "../Common/Charts/StackedBar";
import DoughnutChart from "../Common/Charts/DoughnutChart";
import { ReactComponent as UpChevronSVG } from "src/assets/vectors/up_chevron.svg";
import { ReactComponent as PlayBtn } from "src/assets/vectors/play_audio.svg";
import { ReactComponent as InfoSVG } from "src/assets/vectors/info.svg";

import t from "src/hooks/useTranslate";
import InputPhone from "../Common/Inputs/InputPhone";
import Checkbox from "../Common/Inputs/Checkbox";
import {
  useGetAutoSalonListQuery,
  useGetBodyOptionsMutation,
  useRingoStatListMutation,
} from "src/services/ringoStatService";
import InputRangeDatePicker from "../Common/Inputs/InputRangeDatePicker";

import SelectRingo from "./SelectRingo";
import Shimmer from "../Common/Shimmer";
import Pagination from "../Common/Pagination";
import { useSearchParams } from "react-router-dom";
import Select from "../Common/Select";
import { IBaseSelect } from "src/types/Common/i_base_select";

const call_types = [
  { id: 1, name: "Hamisi", type: "all" },
  { id: 2, name: "Qəbul olunan", type: "answered" },
  { id: 3, name: "Buraxılan", type: "no_answer" },
];

const CallList = () => {
  const [callType, setCallType] = useState(call_types[0].type);
  let [searchParams, setSearchParams] = useSearchParams();
  const [mobile, setMobile] = useState<any>("");
  const [isClear, setIsClear] = useState<any>(false);
  const [play, setPlay] = useState<Record<number, boolean>>({});
  const [selectedCarId, setSelectedCarId] = useState<Record<number, boolean>>(
    {}
  );
  const [perPage, setPerPage] = useState(
    searchParams.get("per_page") ? Number(searchParams.get("per_page")) : 10
  );
  const [page, setPage] = useState(1);
  const [autoSalonId, setAutoSalonId] = useState(0);
  let currentDate = new Date();
  let day = currentDate.getDate();
  let month = currentDate.getMonth() + 1; // Months are zero-indexed, so we add 1
  let year = currentDate.getFullYear();

  const [payload, setPayload] = useState<any>({
    start_date: `${
      Math.abs(day - 15) < 10 ? "0" + Math.abs(day - 15) : Math.abs(day - 15)
    }.${day > 15 ? month : month - 1}.${year}`,
    end_date: `${day < 10 ? "0" + day : day}.${month}.${year}`,
    call_type: callType,
    autosalon_id: autoSalonId,
  });

  const [dateSpan, setDateSpan] = useState(
    `01.${month}.${year}-${day}.${month}.${year}`
  );

  const [
    getRingoStatList,
    {
      data: responseData,
      isSuccess: successRingoData,
      isLoading,
      isError: isRingoStatListError,
    },
  ] = useRingoStatListMutation();

  const [getBodyOptions, { data: bodyOptionsValue }] =
    useGetBodyOptionsMutation();

  const { data: autoSalonList } = useGetAutoSalonListQuery("");

  const handleRingoStat = async () => {
    await getRingoStatList({
      body: payload,
      params: `?page=${page}&per_page=${perPage}`,
    });
  };

  const getBodyOptionsHandler = async () => {
    await getBodyOptions("");
  };

  useEffect(() => {
    setDateSpan(`${payload.start_date}-${payload.end_date}`);
  }, [payload]);

  useEffect(() => {
    getBodyOptionsHandler();
  }, []);

  const mobileOnChangeHandle = (e) => {
    setMobile(e);
  };
  const keyDownHandle = (e) => {
    if (e.code === "Backspace" && mobile.toString().length === 2) {
      setIsClear(true);
      setMobile("");
    } else {
      setIsClear(false);
    }
  };

  useEffect(() => {
    const numberMobile = `994${mobile}`;

    if (numberMobile.length >= 12) {
      setPayload((prev) => ({ ...prev, connected_with: numberMobile }));
    } else if (mobile.toString().length === 0 && isClear) {
      setPayload((prev) => {
        const { connected_with, ...rest } = prev;
        return rest;
      });
    }
  }, [mobile, isClear]);

  useEffect(() => {
    handleRingoStat();
    setPlay({});
  }, [payload, page, perPage]);

  const no_answer_percentage = (
    (+responseData?.doughnut.no_answer /
      (+responseData?.doughnut.answered + +responseData?.doughnut.no_answer)) *
    100
  ).toFixed(1);

  const answered_percentage = (
    (+responseData?.doughnut.answered /
      (+responseData?.doughnut.answered + +responseData?.doughnut.no_answer)) *
    100
  ).toFixed(1);

  const selectedCarHandle = (id) => {
    setSelectedCarId((prev) => ({
      // ...prev,
      [id]: !prev[id],
    }));
  };

  const playHandler = (id) => {
    setPlay((prev) => ({
      // ...prev,
      [id]: !prev[id],
    }));
  };

  const onChangeCalendarHandle = (dateSpan: string) => {
    if (dateSpan) {
      const start_date = dateSpan.split("-")[0];
      const end_date = dateSpan.split("-")[1];
      setPayload((prev) => ({ ...prev, start_date, end_date }));
    } else {
      setPayload((prev) => {
        return {
          ...prev,
          start_date: `01.${month}.${year}`,
          end_date: `${day}.${month}.${year}`,
        };
      });
    }
  };

  function formatDate(dateString) {
    const parts = dateString.split(".");
    if (parts.length === 3) {
      return `${parts[1]}.${parts[0]}.${parts[2]}`;
    }
    return dateString; // Return the original string if the format is unexpected
  }

  return (
    <div>
      <div className="relative flex flex-col w-full">
        <div className="flex flex-col w-full mb-6 pb-6 border-b border-b-cream">
          <div className="flex flex-wrap gap-x-6 gap-y-6">
            <div className="bg-white min-w-[225px] grid grid-cols-3 border border-gray rounded-md ">
              {call_types.map((item) => (
                <span
                  className={[
                    "text-center overflow-hidden whitespace-nowrap px-3 py-2 cursor-pointer first:rounded-l-md first:border-r first:border-r-gray last:border-l last:border-l-gray last:rounded-r-md",

                    payload.call_type === item.type
                      ? "bg-[#F0F2F5] text-black dark:text-white dark:bg-softBlack pointer-events-none"
                      : "bg-white",
                  ].join(" ")}
                  key={item.id}
                  onClick={() =>
                    setPayload((prev) => ({
                      ...prev,
                      call_type: item.type,
                    }))
                  }
                >
                  {item.name}
                </span>
              ))}
            </div>
            <div className="min-w-[225px]">
              <InputPhone
                className=""
                value={!isClear ? mobile.toString() || "" : ""}
                onChange={(e) => mobileOnChangeHandle(e)}
                isClear={isClear}
                name="phone"
                onKeyUp={keyDownHandle}
                //7404774
              />
            </div>
            <div className="min-w-[225px]">
              <InputRangeDatePicker
                className="z-20"
                defaultValue={payload.start_date + "-" + payload.end_date}
                onChange={onChangeCalendarHandle}
                rangeStartDate={new Date(formatDate(payload.start_date))}
                rangeEndDate={new Date(formatDate(payload.end_date))}
              />
            </div>
            {autoSalonList?.length > 0 && (
              <div className="min-w-[225px] xl:mr-10">
                <Select
                  data={[{ id: 0, name: t("all") }, ...autoSalonList]}
                  defaultTitle={t("autosalon")}
                  onChange={(select: IBaseSelect) => {
                    setAutoSalonId(select.id);
                    setPayload((prev) => ({
                      ...prev,
                      autosalon_id: select.id,
                    }));
                  }}
                />
              </div>
            )}

            <div className="flex gap-x-3 min-w-[225px]">
              <div className="flex items-center">
                <span>
                  <Checkbox
                    isChecked={payload?.is_unique || false}
                    onChange={(e) => {
                      setPayload((prev) => {
                        if (e.target.checked) {
                          return {
                            ...prev,
                            is_unique: e.target.checked,
                          };
                        } else {
                          const { is_unique, ...rest } = prev;
                          return rest;
                        }
                      });
                    }}
                  />
                </span>
                <span className="text-black dark:text-white first-letter:uppercase">
                  unikal
                </span>
              </div>
              <div className="flex items-center">
                <span>
                  <Checkbox
                    isChecked={payload?.is_target || false}
                    onChange={(e) => {
                      setPayload((prev) => {
                        if (e.target.checked) {
                          return {
                            ...prev,
                            is_target: e.target.checked,
                          };
                        } else {
                          const { is_target, ...rest } = prev;
                          return rest;
                        }
                      });
                    }}
                  />
                </span>
                <span className="text-black dark:text-white first-letter:uppercase">
                  target
                </span>
              </div>
            </div>
          </div>
        </div>
        {(responseData || isRingoStatListError) && !isLoading ? (
          responseData?.stacked?.length > 0 ? (
            <div>
              <div className=" border-cream mt-6">
                <div className="my-6">
                  {successRingoData ? (
                    <div className="flex justify-center gap-x-6 h-[420px] max-h-[420px]">
                      <div className="w-full max-w-[992px] border border-gray p-6 rounded-md">
                        <StackedBar chartData={responseData?.stacked} />
                      </div>

                      <div className="w-[291px] flex flex-col justify-between border border-gray p-6 rounded-md">
                        <div className="font-semibold text-black dark:text-white">
                          {t("calls")}
                        </div>

                        <div className="">
                          <DoughnutChart chartData={responseData?.doughnut} />
                        </div>
                        <div className="flex flex-col gap-y-1">
                          <div className="flex items-center justify-between text-black dark:text-white">
                            <span className="flex items-center gap-x-1">
                              <span className="rounded-full bg-[#39CEF3] w-3 h-3"></span>
                              <span>{t("accepted")}</span>
                            </span>
                            <span className="flex gap-x-1">
                              <span> {responseData?.doughnut?.answered}</span>
                              {responseData?.doughnut?.answered !== null ? (
                                <span>({answered_percentage}%)</span>
                              ) : (
                                "Yoxdur"
                              )}
                            </span>
                          </div>
                          <div className="flex items-center justify-between text-black dark:text-white">
                            <span className="flex items-center gap-x-1">
                              <span className="rounded-full bg-[#64BD79] w-3 h-3"></span>
                              <span>{t("missed")}</span>
                            </span>
                            <span className="flex gap-x-1">
                              <span>{responseData?.doughnut?.no_answer}</span>
                              {responseData?.doughnut?.no_answer !== null ? (
                                <span>({no_answer_percentage}%)</span>
                              ) : (
                                "Yoxdur"
                              )}{" "}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <Shimmer className="w-full h-10" />
                  )}
                </div>
              </div>

              <div className="pt-6 border-t border-t-cream dark:border-t-white">
                <table className="w-full">
                  {successRingoData ? (
                    <thead>
                      <tr className="[&>th]:text-left [&>th]:pb-4 [&>th]:dark:text-white [&>th]:whitespace-nowrap">
                        <th className="w-[7%]">{t("announcements")}</th>
                        <th className="w-[2%]">{t("hour")}</th>
                        <th className="w-[3%]">{t("client_number")}</th>
                        <th className="w-[8%]">{t("calls")}</th>
                        <th className="w-[4%]">{t("status")}</th>
                        <th className="w-[3%]">{t("center_number")}</th>
                        <th className="w-[3%]">{t("price")}</th>
                      </tr>
                    </thead>
                  ) : (
                    <Shimmer className="w-full h-10" />
                  )}

                  {successRingoData ? (
                    <tbody>
                      {Object.keys(responseData?.table.data)?.map((date) => (
                        <React.Fragment key={date}>
                          <tr className="bg-[#F1F3F4] rounded-md">
                            <td
                              className="pl-4 py-3 rounded-md text-[#7B809A]"
                              colSpan={7}
                            >
                              {date}
                            </td>
                          </tr>
                          {responseData?.table?.data[date]?.map(
                            (entry, index) => (
                              <>
                                <tr
                                  key={index}
                                  className={[
                                    "[&>td]:py-3 border-b border-cream",
                                    selectedCarId[entry.call_id]
                                      ? "bg-[#F1F5FB] rounded-md"
                                      : "bg-white",
                                  ].join(" ")}
                                >
                                  {entry.car ? (
                                    <td
                                      className="pl-4 cursor-pointer flex gap-x-2 items-center rounded-md"
                                      onClick={() =>
                                        selectedCarHandle(entry.call_id || 0)
                                      }
                                    >
                                      <span
                                        className={[
                                          "transition-all",
                                          selectedCarId[entry.call_id || 0]
                                            ? "rotate-0"
                                            : "rotate-[180deg]",
                                        ].join(" ")}
                                      >
                                        <UpChevronSVG className="w-3 h-3 text-black font-semibold" />
                                      </span>
                                      {entry.car ? (
                                        <span className="">
                                          {entry.car.breadcrumb.brand ??
                                            "N/A" +
                                              entry.car.breadcrumb.model ??
                                            "N/A" +
                                              entry.car.breadcrumb
                                                ?.generation ??
                                            "N/A"}{" "}
                                        </span>
                                      ) : (
                                        <span>N/A</span>
                                      )}
                                    </td>
                                  ) : (
                                    <td className="pl-10">N/A</td>
                                  )}

                                  <td>{entry.call_time ?? "N/A"}</td>
                                  <td>{entry.caller ?? "N/A"}</td>
                                  {entry.record ? (
                                    <td className="flex w-full gap-x-2 items-center h-[40px]">
                                      <span className="w-10">
                                        {entry.duration ?? "N/A"}
                                      </span>

                                      <span
                                        className="cursor-pointer w-6"
                                        onClick={() =>
                                          playHandler(entry.call_id)
                                        }
                                      >
                                        <PlayBtn />
                                      </span>

                                      {play[entry.call_id] && (
                                        <div className="w-[calc(100%-80px)] h-[33px] mx-2">
                                          <audio
                                            controls
                                            autoPlay
                                            className="w-full h-full"
                                          >
                                            <source
                                              src={entry.record}
                                              type="audio/mpeg"
                                            />
                                            Your browser does not support the
                                            audio tag.
                                          </audio>
                                        </div>
                                      )}
                                    </td>
                                  ) : (
                                    <td>----</td>
                                  )}

                                  <td className="">
                                    {entry.is_unique && (
                                      <span className="bg-[#39CEF3] dark:text-white rounded-md px-3 mr-2 py-1.5 first-letter:uppercase">
                                        {" "}
                                        {entry.is_unique && t("is_unique")}
                                      </span>
                                    )}
                                    {entry.is_target && (
                                      <span className="bg-[rgba(248,23,52,0.40)] dark:text-white rounded-md px-3 py-1.5 first-letter:uppercase">
                                        {" "}
                                        {entry.is_target && t("is_target")}
                                      </span>
                                    )}
                                  </td>
                                  <td>{entry?.connected_with ?? "N/A"}</td>
                                  <td>{entry?.car?.price ?? "N/A"}</td>

                                  {/* Add more cells for other properties */}
                                </tr>
                                {selectedCarId[entry.call_id] && (
                                  <tr>
                                    {entry?.car ? (
                                      <td colSpan={7}>
                                        <SelectRingo
                                          car={entry?.car}
                                          bodyOptionsValue={
                                            bodyOptionsValue.main
                                          }
                                        />
                                      </td>
                                    ) : (
                                      <td>infor car</td>
                                    )}
                                  </tr>
                                )}
                              </>
                            )
                          )}
                        </React.Fragment>
                      ))}
                    </tbody>
                  ) : (
                    <div className="py-10">
                      {" "}
                      <Shimmer className="w-full h-10" />
                    </div>
                  )}
                </table>
              </div>
            </div>
          ) : (
            <div className="w-full flex justify-center items-center my-28">
              <div className="w-fit flex items-center gap-x-3 p-4 rounded-xl bg-light dark:bg-softBlack text-gray dark:text-white">
                <InfoSVG />
                <p>Göstəriləcək hər hansı bir məlumat yoxdur</p>
              </div>
            </div>
          )
        ) : (
          [...Array(5)].map((_, i) => (
            <Shimmer className="w-full h-10 my-2 " key={i} />
          ))
        )}
      </div>
      {responseData?.table.meta.total > 5 && (
        <div className="w-full px-4 py-4 border-t border-solid border-gray">
          <Pagination
            page={Math.ceil(responseData.table?.meta.total / perPage)}
            total={responseData.table?.meta.total}
            perPage={perPage}
            pageChange={(e) => {
              setPage(e + 1);
              // params.set("page", (e + 1).toString());
              setSearchParams((params) => {
                params.set("page", (e + 1).toString());
                return params;
              });
            }}
            setPerPage={(val) => {
              setPerPage(val);
              // params.set("per_page", val.toString());
              setSearchParams((params) => {
                params.set("per_page", val.toString());
                return params;
              });
            }}
          />
        </div>
      )}
    </div>
  );
};

export default CallList;
