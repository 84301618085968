import { useEffect, useState } from "react";
import t from "src/hooks/useTranslate";
import Pagination from "../Common/Pagination";
import { ReactComponent as InfoSVG } from "src/assets/vectors/info.svg";
import { ReactComponent as EyeSVG } from "src/assets/vectors/eye.svg";
import { ReactComponent as MailSVG } from "src/assets/vectors/mail.svg";
import { ReactComponent as CheckSVG } from "src/assets/vectors/check.svg";
import Shimmer from "../Common/Shimmer";
import { useNavigate } from "react-router-dom";
import { renderLinks } from "../Header/HeaderMail";
import IconWithTooltip from "../Common/IconWithTooltip";

const NotificationsTable = ({ data, isLoading, setParams, onMarkAsRead }) => {
  const [paramsData, setParamsData] = useState(new URLSearchParams());
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();

  useEffect(() => {
    paramsData.toString() && setParams("?" + paramsData.toString());
  }, [page, perPage]);

  return (
    <div className="space-y-6 dark:text-white">
      <div className="w-fit flex items-center gap-x-3 p-4 rounded-xl bg-white dark:bg-softBlack text-gray dark:text-white drop-shadow-lg">
        <InfoSVG />
        <p>{t("notification_note")}</p>
      </div>
      <div className="w-full relative flex flex-col justify-between bg-white dark:bg-lightBlack rounded-xl drop-shadow-lg">
        <div className="relative min-h-[456px]">
          <table className="min-w-[991px] w-full">
            <thead>
              <tr className="[&>th]:text-left [&>th]:py-6 [&>th]:px-2 [&>th]:whitespace-nowrap border-b border-solid border-gray">
                <th className="w-1/2 !pl-4">{t("notification")}</th>
                <th className="w-1/4 text-center">{t("date")}</th>
                <th className="w-1/4 !pr-4">{t("status")}</th>
              </tr>
            </thead>
            <tbody>
              {data && !isLoading ? (
                data?.data.length > 0 &&
                data?.data.map((notification) => (
                  <tr
                    className="relative [&>td]:py-4 [&>td]:px-2 [&>td]:h-[40px] after:absolute after:top-0 after:left-0 after:w-full after:h-[1px] after:bg-light after:opacity-50 cursor-pointer"
                    onClick={() =>
                      navigate(
                        renderLinks(notification.type, notification.actionId),
                        {
                          state: {
                            notificationId: notification.id,
                            actionId: notification.actionId,
                          },
                        }
                      )
                    }
                    key={notification.id}
                  >
                    <td className="!pl-4">
                      <div className="flex items-center gap-x-4">
                        <MailSVG
                          className={
                            notification.is_read ? "text-gray" : "text-red"
                          }
                        />
                        {notification.message}
                      </div>
                    </td>
                    <td>{notification.created_at}</td>
                    <td className="!pr-4">
                      <div
                        className={`flex gap-x-2 items-center ${
                          notification.is_read ? "text-gray" : ""
                        }`}
                      >
                        <p>
                          {notification.is_read ? t("was_read") : t("unread")}
                        </p>
                        {notification.is_read ? (
                          <CheckSVG />
                        ) : (
                          <IconWithTooltip
                            icon={<EyeSVG className="w-6 h-6" />}
                            tooltip={t("mark_as_read")}
                            onClick={(e) => {
                              e.stopPropagation();
                              if (!notification.is_read) {
                                onMarkAsRead(notification.id);
                              }
                            }}
                          />
                        )}
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <>
                  {[...Array(perPage)].map((_, i) => (
                    <tr className="[&>td]:py-4 [&>td]:px-2" key={i}>
                      {[...Array(4)].map((__, idx) => (
                        <td className="w-auto" key={idx}>
                          <Shimmer className="h-10.5" />
                        </td>
                      ))}
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </table>
          {data?.data.length < 1 && !isLoading && (
            <div className="absolute inset-0 flex items-center justify-center">
              <div className="w-fit flex items-center gap-x-3 p-4 rounded-xl bg-light dark:bg-softBlack text-gray dark:text-white">
                <InfoSVG />
                <p>Göstəriləcək hər hansı bir məlumat yoxdur</p>
              </div>
            </div>
          )}
        </div>
        {data?.meta.total > 5 && (
          <div className="w-full px-4 py-6 border-t border-solid border-gray">
            <Pagination
              page={Math.ceil(data?.meta.total / perPage)}
              total={data?.meta.total}
              perPage={perPage}
              pageChange={(e) => {
                setPage(e + 1);
                paramsData.set("page", (e + 1).toString());
              }}
              setPerPage={(val) => {
                setPerPage(val);
                paramsData.set("per_page", val.toString());
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default NotificationsTable;
