import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { getCookie } from "src/helper/custom_cookies"

export const reportService = createApi({
    reducerPath: "reportService",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_BASE_URL,
        prepareHeaders: (headers) => {
            headers.set("Authorization", getCookie("mcrmtkn") ? "Bearer " + getCookie("mcrmtkn") : "")
            headers.set('Accept', 'application/json')
            return headers
        },
        // credentials: "include",
    }),
    // tagTypes: ["Group and Permissions"],
    endpoints: (builder) => ({
        operatorsReportByTarget: builder.mutation({
            query: (query) => {
                return {
                    url: `/announce/export_report${query}`,
                    method: "get",
                    responseHandler: async (response) => window.location.assign(window.URL.createObjectURL(await response.blob())),
                }
            },
        }),
        operatorsReportByStatus: builder.mutation({
            query: (query) => {
                return {
                    url: `/announce/report_by_status${query}`,
                    method: "get",
                    responseHandler: async (response) => window.location.assign(window.URL.createObjectURL(await response.blob())),
                }
            },
        }),
        operatorsReportByType: builder.mutation({
            query: (query) => {
                return {
                    url: `/announce/report_by_announce_type${query}`,
                    method: "get",
                    responseHandler: async (response) => window.location.assign(window.URL.createObjectURL(await response.blob())),
                }
            },
        }),
    })
})

export const { useOperatorsReportByTargetMutation, useOperatorsReportByStatusMutation, useOperatorsReportByTypeMutation } = reportService