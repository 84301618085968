import { Outlet } from "react-router-dom";
import Footer from "src/components/Footer";
import Sidebar from "src/components/Sidebar/Sidebar";

const Main = () => {
  return (
    <div className="min-h-screen flex gap-x-8 p-4">
      <Sidebar />
      <div className="w-[calc(100%-282px)] flex flex-col">
        <Outlet />
        <Footer />
      </div>
    </div>
  );
};

export default Main;
