import { useState } from "react";
import t from "src/hooks/useTranslate";
import InputCalendar from "../../Common/Inputs/InputCalendar";
import { ReactComponent as InfoSVG } from "src/assets/vectors/info.svg";
import { ddmmyyyy } from "src/helper/getFormattedDate";
import Shimmer from "src/components/Common/Shimmer";

const DashboardInformations = ({ data, isLoading }) => {
  const [value, setValue] = useState(ddmmyyyy(new Date()));

  return (
    <div className="flex flex-wrap gap-6 w-full relative bg-white dark:bg-lightBlack rounded-xl drop-shadow-lg">
      <div className="w-full p-4 flex items-center justify-between">
        <p className="text-16 font-bold dark:text-white">{t("informations")}</p>
        {/* <div className="relative">
          <InputCalendar
            className="min-w-[260px] z-20"
            maxDetail={"year"}
            monthView
            value={value}
            //@ts-ignore
            onChange={(e) => {
              setParams("?date=" + e.split(".").slice(1).join("."));
              setValue(e);
            }}
          />
        </div> */}
      </div>
      <div className="w-full p-4 pt-0">
        <div className="flex flex-col flex-wrap gap-y-6 gap-x-32 h-[280px] dark:text-white overflow-auto">
          {data && !isLoading ? (
            data.data.length > 0 ? (
              data.data.map((stat) => (
                <div
                  className="w-auto flex items-center justify-between gap-x-20"
                  key={stat.id}
                >
                  <div className="py-3">
                    <p className="text-16 font-semibold">{stat.label}</p>
                  </div>
                  <div className="flex items-center justify-center h-9 px-4 bg-green rounded text-white min-w-[80px]">
                    {stat.count}
                  </div>
                </div>
              ))
            ) : (
              <div className="w-full relative top-1/2 -translate-y-1/2 p-4 flex items-center justify-center">
                <div className="w-fit flex items-center gap-x-3 p-4 rounded-xl bg-light dark:bg-softBlack text-gray dark:text-white">
                  <InfoSVG />
                  <p>Göstəriləcək hər hansı bir məlumat yoxdur</p>
                </div>
              </div>
            )
          ) : (
            [...Array(8)].map((_, i) => <Shimmer className="h-10.5" key={i} />)
          )}
        </div>
      </div>
    </div>
  );
};

export default DashboardInformations;
