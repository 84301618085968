import Shimmer from "../Common/Shimmer";
import MashinAlMessagesGallery from "./MashinAlMessagesGallery";

const MashinAlMessagesSection = ({ data, isLoading, sellerId }) => {
  return data && !isLoading ? (
    data.data.length > 0 ? (
      data.data.map((message) => (
        <div className="flex flex-col" key={message.id}>
          <div className="space-y-4">
            <div
              className={[
                "w-fit p-4 border dark:border-white rounded-lg",
                sellerId === message.sender.id ? "ml-auto" : "",
              ].join(" ")}
            >
              <p className="dark:text-white">
                {sellerId === message.sender.id ? "Satıcı" : "Alıcı"}:
                {message.sender.full_name} | {message.created_at}
              </p>
            </div>
            <div
              className={[
                "relative p-4 rounded-lg w-fit after:absolute after:top-0 after:-translate-y-1/2 after:w-4 after:h-4 after:rotate-45",
                sellerId === message.sender.id
                  ? "bg-softGreen after:bg-softGreen after:right-4 ml-auto"
                  : "bg-light dark:bg-softBlack after:bg-light dark:after:bg-softBlack after:left-4 dark:text-white",
              ].join(" ")}
            >
              <p>{message.text}</p>
              {message.images.length > 0 && (
                <MashinAlMessagesGallery images={message.images} />
              )}
            </div>
          </div>
        </div>
      ))
    ) : (
      <p className="dark:text-white">Mesaj yoxdu</p>
    )
  ) : (
    <div className="space-y-4">
      {[...Array(5)].map((_, i) => (
        <Shimmer key={i} className="h-40" />
      ))}
    </div>
  );
};
{
  /* <div className="p-4 space-y-4">
  <div className="w-fit bg-softGreen p-4 rounded-lg">
    <p>Satıcı: Xalid | 02.02.2022 17:26</p>
  </div>
  <p>
    Aleykum salam. Problem yoxdur.Nə vaxt istəyirsən əlaqə saxla danışaq.
  </p>
</div> */
}

export default MashinAlMessagesSection;
