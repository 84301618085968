import { ReactComponent as UsersSvg } from "src/assets/vectors/users.svg";
import { ReactComponent as HomeSvg } from "src/assets/vectors/home.svg";
import { ReactComponent as AnnouncementsSvg } from "src/assets/vectors/announcements.svg";
import { ReactComponent as UserSvg } from "src/assets/vectors/user.svg";
import { ReactComponent as SwitchCameraSvg } from "src/assets/vectors/switch_camera.svg";
import { ReactComponent as CalculatorSvg } from "src/assets/vectors/calculator.svg";
import { ReactComponent as AimSvg } from "src/assets/vectors/aim.svg";
import { ReactComponent as BlacklistSvg } from "src/assets/vectors/blacklist.svg";
import { ReactComponent as MessageCircleSvg } from "src/assets/vectors/message_circle.svg";
import { ReactComponent as FaqSvg } from "src/assets/vectors/faq.svg";
import { ReactComponent as CarSvg } from "src/assets/vectors/car.svg";
import { ReactComponent as TelegramSvg } from "src/assets/vectors/telegram.svg";
import { ReactComponent as ColumnsSvg } from "src/assets/vectors/columns.svg";
import { ISection } from "src/types/i_sidebar";
import { EPermissions } from "src/enums/e_permissions";

export const SidebarSectionsData: ISection[] = [
  {
    id: 1,
    title: "hr",
    sub_sections: [
      {
        id: 1,
        title: "human_resources",
        icon: UsersSvg,
        link: null,
        sub_section_categories: [
          {
            id: 1,
            title: "users",
            link: "/users",
            permissions: [EPermissions.user_list],
          },
          {
            id: 2,
            title: "groups",
            link: "/groups",
            permissions: [EPermissions.group_list],
          },
          {
            id: 3,
            title: "permissions",
            link: "/permissions",
            permissions: [EPermissions.time_off_list],
          },
          {
            id: 4,
            title: "vacations",
            link: "/vacations",
            permissions: [EPermissions.vacations_list],
          },
          {
            id: 5,
            title: "operator_working_hours_kpi",
            link: "/working-hours-kpi",
            permissions: [EPermissions.call_center_working_hours],
          },
        ],
        permissions: [
          EPermissions.user_list,
          EPermissions.group_list,
          EPermissions.time_off_list,
          EPermissions.vacations_list,
          EPermissions.call_center_working_hours,
        ],
      },
    ],
    permissions: [
      EPermissions.user_list,
      EPermissions.group_list,
      EPermissions.time_off_list,
      EPermissions.vacations_list,
      EPermissions.call_center_working_hours,
      EPermissions.ringo_stat_list,
    ],
  },
  {
    id: 2,
    title: "mcrm",
    sub_sections: [
      {
        id: 2,
        title: "dashboard",
        icon: HomeSvg,
        link: "/",
        sub_section_categories: null,
        permissions: [EPermissions.report_list],
      },
      {
        id: 3,
        title: "announcements",
        icon: AnnouncementsSvg,
        link: "/announcements",
        sub_section_categories: null,
        permissions: [EPermissions.announce_list],
      },
      {
        id: 4,
        title: "announcementsNew",
        icon: AnnouncementsSvg,
        link: "/announcementsTwo",
        sub_section_categories: null,
        permissions: [EPermissions.announce_list],
      },
      {
        id: 5,
        title: "cc_operators",
        icon: UserSvg,
        link: "/operators",
        sub_section_categories: null,
        permissions: [EPermissions.call_center_list],
      },
      {
        id: 6,
        title: "shift_change",
        icon: SwitchCameraSvg,
        link: "/shift-change",
        sub_section_categories: null,
        permissions: [
          EPermissions.shift_change_change,
          EPermissions.shift_change_list,
        ],
      },
      {
        id: 7,
        title: "reports",
        icon: CalculatorSvg,
        link: "/reports",
        sub_section_categories: null,
        permissions: [EPermissions.report_list],
      },
      {
        id: 8,
        title: "aims",
        icon: AimSvg,
        link: "/targets",
        sub_section_categories: null,
        permissions: [EPermissions.target_list],
      },
      {
        id: 9,
        title: "blacklist",
        icon: BlacklistSvg,
        link: "/blacklist",
        sub_section_categories: null,
        permissions: [EPermissions.black_list_list],
      },
      {
        id: 10,
        title: "requests",
        icon: MessageCircleSvg,
        link: "/requests",
        sub_section_categories: null,
      },
      {
        id: 11,
        title: "faq",
        icon: FaqSvg,
        link: "/faq",
        sub_section_categories: null,
        permissions: [EPermissions.faq_list],
      },
    ],
  },
  {
    id: 3,
    title: "mashin.al",
    sub_sections: [
      {
        id: 11,
        title: "dashboard",
        icon: HomeSvg,
        link: null,
        sub_section_categories: [
          {
            id: 12,
            title: "autosalon",
            link: "/mashin-al-autosalon",
            permissions: [EPermissions.mashin_al_list],
          },
          {
            id: 6,
            title: "moderators",
            link: "/mashin-al-moderators",
            permissions: [EPermissions.finance_mashin_al],
          },
          {
            id: 7,
            title: "individual",
            link: "/mashin-al-individual",
            permissions: [EPermissions.finance_mashin_al],
          },
          {
            id: 15,
            title: "user_operations",
            link: "/mashin-al-user-operations",
            permissions: [EPermissions.finance_mashin_al],
          },
          // {
          //   id: 8,
          //   title: "shop_salon",
          //   link: "",
          //   permissions: [EPermissions.finance_mashin_al]
          // },
          {
            id: 9,
            title: "garage",
            link: "/mashin-al-garage",
            permissions: [EPermissions.finance_mashin_al],
          },
          // {
          //   id: 10,
          //   title: "credentials",
          //   link: "/credentials",
          //   permissions: [EPermissions.finance_mashin_al]
          // },
          {
            id: 11,
            title: "notifications",
            link: "/mashin-al-app",
            permissions: [EPermissions.finance_mashin_al],
          },
          {
            id: 13,
            title: "auto_history",
            link: "/auto-history",
            permissions: [EPermissions.mashin_al_auto_history],
          },
        ],
        permissions: [EPermissions.finance_mashin_al],
      },
    ],
    permissions: [EPermissions.finance_mashin_al],
  },
  {
    id: 4,
    title: "Ringo Stat",
    sub_sections: [
      {
        id: 2,
        title: "dashboard",
        icon: HomeSvg,
        link: "/ringo",
        sub_section_categories: null,
        // permissions: [EPermissions.ringo_stat_list],
      },
    ],
    permissions: [EPermissions.ringo_stat_list],
  },

  // {
  //   id: 4,
  //   title: "mashin.al",
  //   sub_sections: [
  //     {
  //       id: 12,
  //       title: "dashboard",
  //       icon: HomeSvg,
  //       link: "/mashin-al-dashboard",
  //       sub_section_categories: null,
  //       permissions: [EPermissions.mashin_al_list]
  //     },
  //     {
  //       id: 13,
  //       title: "Telegram BOT",
  //       icon: TelegramSvg,
  //       link: "/telegram-bot",
  //       sub_section_categories: null
  //     },
  //     {
  //       id: 14,
  //       title: "data",
  //       icon: ColumnsSvg,
  //       link: null,
  //       sub_section_categories: [
  //         {
  //           id: 12,
  //           title: "Turbo.guru",
  //           link: "/turbo-guru",
  //         },
  //         {
  //           id: 13,
  //           title: "auto_history",
  //           link: "/auto-history",
  //         },
  //       ]
  //     },
  //   ],
  //   permissions: [EPermissions.mashin_al_list]
  // },
];

