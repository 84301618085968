import { ReactComponent as ExcelSVG } from "src/assets/vectors/file_excel.svg";
import InputRangeDatePicker from 'src/components/Common/Inputs/InputRangeDatePicker';
import { ddmmyyyy } from 'src/helper/getFormattedDate';
import Button from 'src/components/Common/Inputs/Button';
import t from "src/hooks/useTranslate";
import { useState } from "react";

const ReportTemplate = ( {title, index, onChange, onSubmit, isLoading } ) => {
  const [z, setZ] = useState(10)
  return (
    <div className="relative py-6 px-4 bg-white dark:bg-lightBlack rounded-xl mt-6 drop-shadow-lg space-y-4" style={{zIndex: z}} onMouseEnter={() => setZ(20)} onMouseLeave={() => setZ(10)}>
        <p className="text-16 font-bold dark:text-white flex justify-between">{title} <span className="text-gray dark:text-white">#{index}</span></p>
        <div className=" flex gap-4 [&>*]:w-1/2">
          <InputRangeDatePicker
            defaultValue={`${ddmmyyyy(
              new Date(new Date().setMonth(new Date().getMonth() - 1))
            )}-${ddmmyyyy(new Date())}`}
            className="z-20"
            onChange={(e: string) => onChange(e)}
          />
          <Button
            className="bg-darkBlue dark:bg-blue text-white"
            onClick={onSubmit}
            isLoading={isLoading}
          >
            <ExcelSVG /> {t("export_to_excel")}
          </Button>
        </div>
      </div>
  )
}

export default ReportTemplate