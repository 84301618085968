import { FC, SyntheticEvent, useEffect, useState } from "react";
import t from "src/hooks/useTranslate";
import { ReactComponent as XSVG } from "src/assets/vectors/x.svg";
import noteDestinationData from "src/data/note_destination_data.json";
import { getCookie } from "src/helper/custom_cookies";
import { ddmmyyyy } from "src/helper/getFormattedDate";
import {
  useAnnouncementRecordingQuery,
  useCommentToVoiceMutation,
  useGetRecordingFileMutation,
} from "src/services/announcementsService";
import Button from "../Common/Inputs/Button";
import Select from "../Common/Select";
import Shimmer from "../Common/Shimmer";
import { EAudioCommentType } from "src/enums/e_audio_comment_type";
import usePermission from "src/hooks/usePermission";
import { EPermissions } from "src/enums/e_permissions";

const AnnouncementAudioRecording: FC<{
  onClose?: () => void;
  announcementID: number;
  onSubmit: (val: { comment: string; type: EAudioCommentType }) => void;
  isLoading: boolean;
  isAdmin: boolean;
}> = ({ announcementID, onClose, onSubmit, isLoading, isAdmin }) => {
  const [comment, setComment] = useState("");
  const [type, setType] = useState(0);
  const [audioSrc, setAudioSrc] = useState<any>([]);
  const [activeAudio, setActiveAudio] = useState("");
  const canCommentRecording = usePermission([
    EPermissions.announce_voice_add_note,
  ]);

  const { data: recordings, isSuccess: announcementReady } =
    useAnnouncementRecordingQuery(announcementID);

  const [getFile, { data: recordingFile, isSuccess: isRecordingFileSuccess }] =
    useGetRecordingFileMutation();

  useEffect(() => {
    if (announcementReady) {
      let src = [];
      recordings.data?.forEach(async (x) => {
        try {
          const l = await getFile(x.id);
          var reader = new FileReader();
          reader.readAsDataURL(l["data"]);
          reader.onloadend = function () {
            var base64data = reader.result;
            src.push(base64data);
            setAudioSrc([...src]);
          };
        } catch (error) {}
      });
    }
  }, [recordings]);

  return (
    <>
      <div className="relative flex items-center justify-between gap-x-4 p-4 mb-4 border-b border-solid border-gray dark:text-white">
        <p className="text-16 font-bold">{t("create_announcement")}</p>
        <XSVG
          className="text-gray cursor-pointer dark:text-white"
          onClick={onClose}
        />
      </div>
      <form
        onSubmit={(e: SyntheticEvent) => {
          e.preventDefault();
          onSubmit({ comment, type });
        }}
        className="p-4"
      >
        <table className="min-w-[991px] w-full">
          <thead>
            <tr className="[&>th]:text-left [&>th]:pb-6 [&>th]:px-4 [&>th]:dark:text-white [&>th]:whitespace-nowrap">
              <th className="w-1/5 !pl-4">ID</th>
              <th className="w-1/5">{t("phone")}</th>
              <th className="w-1/5">{t("audio_recording")}</th>
              <th className="w-1/5">{t("operator")}</th>
              <th className="w-1/5 !pr-4">{t("recorded_at")}</th>
            </tr>
          </thead>
          <tbody>
            {announcementReady ? (
              recordings.data.map((record: any, i) => (
                <tr
                  className="relative [&>td]:py-4 [&>td]:px-4 [&>td]:dark:text-white border-y border-solid border-gray"
                  key={record.id}
                >
                  <td className="w-10 !pl-4">{record.invoice_id}</td>
                  <td>{record.phone}</td>
                  <td>
                    <audio src={audioSrc[i]} className="h-10" controls />
                  </td>
                  <td>{record.operator.full_name}</td>
                  <td className="!pr-4">
                    {ddmmyyyy(new Date(record.recording_start_at))}
                  </td>
                </tr>
              ))
            ) : (
              <>
                <tr className="[&>td]:py-4 [&>td]:px-2 bg-white dark:bg-lightBlack">
                  <td className="w-1/5">
                    <Shimmer className="h-4" />
                  </td>
                  <td className="w-1/5">
                    <Shimmer className="h-4" />
                  </td>
                  <td className="w-1/5">
                    <Shimmer className="h-4" />
                  </td>
                  <td className="w-1/5">
                    <Shimmer className="h-4" />
                  </td>
                  <td className="w-1/5">
                    <Shimmer className="h-4" />
                  </td>
                </tr>
              </>
            )}
          </tbody>
        </table>
        {canCommentRecording && (
          <div className="mt-8 space-y-4">
            <p className="text-16 text-center font-bold dark:text-white">
              {t("add_note")}
            </p>
            <div className="flex gap-x-4">
              <div className="w-1/2 space-y-2">
                <p className="text-gray dark:text-white">{t("note_subject")}</p>
                <div className={`relative ${recordings?.note?.comment && !isAdmin ? "after:absolute after:inset-0 after:bottom-2 after:bg-gray dark:after:bg-black after:opacity-50 after:rounded" : ""}`}>
                <textarea
                  className="w-full h-[100px] px-4 py-2 border border-solid border-gray rounded resize-none bg-white dark:bg-softBlack dark:text-white"
                  placeholder={t("enter_the_text")}
                  onKeyDown={(e) =>
                    comment.length > 0 &&
                    comment[comment.length - 1] == " " &&
                    e.code === "Space" &&
                    e.preventDefault()
                  }
                  defaultValue={recordings?.note?.comment ? recordings?.note?.comment : ""}
                  disabled={recordings?.note?.comment && !isAdmin}
                  onChange={(e) => setComment(e.target.value)}
                />
                </div>
              </div>
              <div className="w-1/2 space-y-2">
                <div className={`relative space-y-4 ${recordings?.note?.type && !isAdmin ? "[&_button]:bg-gray/50 [&_button]:pointer-events-none" : ""}`}>
                  <Select
                    data={noteDestinationData}
                    label={t("note_destination")}
                    defaultTitle={t("choose")}
                    defaultValue={recordings?.type && !isAdmin ? recordings?.type : null}
                    onChange={(e) => setType(e.id)}
                  />
                  {
                    recordings?.note && !isAdmin ? (
                      <div className="flex justify-between">
                        <p><span className="text-gray">Qeydin sahibi: </span> {recordings?.note.user}</p>
                        <p><span className="text-gray">{t("date")}: </span> {recordings?.note.date}</p>
                      </div>
                    ) : <Button
                    className="w-full bg-blue text-white disabled:bg-gray"
                    isLoading={isLoading}
                    disabled={comment.trim() == "" || type === 0}
                  >
                    {t("send")}
                  </Button>
                  }
                </div>
              </div>
            </div>
          </div>
        )}
      </form>
    </>
  );
};

export default AnnouncementAudioRecording;
