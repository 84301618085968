import { FC, useEffect, useState, useRef, ReactElement, useMemo } from "react";
import { ETheme } from "src/enums/e_theme";
import EChart from "./EChart";

const Doughnut: FC<any> = ({ dataSummary, chartData }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [render, setRender] = useState<ReactElement<HTMLDivElement> | null>(
    null
  );
  const [option, setOption] = useState({});

  useMemo(
    () =>
      setOption({
        tooltip: {
          trigger: "item",
          formatter: function (params) {
            return `${params.name} : ${params.data.value} (${(
              (100 * params.data.value) /
              dataSummary
            ).toFixed(2)}%)`;
          },
        },
        series: [
          {
            name: "Area Mode",
            type: "pie",
            radius: ["40%", "70%"],
            itemStyle: {
              borderRadius: 10,
              borderColor:
                window.matchMedia("(prefers-color-scheme: dark)").matches &&
                !localStorage.getItem("theme")
                  ? "white"
                  : localStorage.getItem("theme") === ETheme.dark
                  ? "#081A3E"
                  : "white",
              borderWidth: 4,
            },
            label: {
              position: "outer",
              alignTo: "labelLine",
              formatter: "{c|{c}}\n{b|{b}}",
              rich: {
                b: {
                  color:
                    window.matchMedia("(prefers-color-scheme: dark)").matches &&
                    !localStorage.getItem("theme")
                      ? "#081A3E"
                      : localStorage.getItem("theme") === ETheme.dark
                      ? "white"
                      : "#081A3E",
                  fontSize: 12,
                  lineHeight: 12,
                },
                c: {
                  color: "#64BD79",
                  fontSize: 17,
                  fontWeight: "bold",
                  lineHeight: 17,
                  textShadowBlur: 1,
                  textShadowColor: "#000",
                },
              },
            },
            emphasis: {
              show: true,
              fontSize: "40",
              fontWeight: "bold",
              scale: true,
            },
            color: ["#39CEF3"],
            data: chartData,
          },
        ],
      }),
    [chartData]
  );

  useEffect(
    () =>
      chartData &&
      setRender(
        <div
          className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 -z-10 flex justify-center rounded-full items-center bg-white dark:bg-softBlack drop-shadow-lg aspect-square text-22 font-bold text-green"
          style={{
            width:
              (Math.min(ref.current?.clientWidth!, ref.current?.clientHeight!) /
                100) *
                80 +
              "px",
          }}
        >
          {dataSummary}
        </div>
      ),
    [ref.current, dataSummary]
  );

  // console.log("option", option);

  return (
    <div className="relative w-full h-full" ref={ref}>
      <EChart option={option} />
      {render}
    </div>
  );
};

export default Doughnut;
