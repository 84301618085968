import { useState } from "react";
import t from "src/hooks/useTranslate";
import { ReactComponent as InfoSVG } from "src/assets/vectors/info.svg";
import { ReactComponent as BackSVG } from "src/assets/vectors/back.svg";
import Shimmer from "src/components/Common/Shimmer";
import Pagination from "src/components/Common/Pagination";
import { Link } from "react-router-dom";
type Props = {
  data: any;
  isLoading: boolean;
  setParams: (query: string) => void;
};

const MashinalAppNotificationStatisticsTable = ({
  data,
  isLoading,
  setParams,
}: Props) => {
  const [params, _] = useState(new URLSearchParams());
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);

  return (
    <div className="space-y-6">
      <div className="w-full flex flex-col relative bg-white dark:bg-lightBlack rounded-xl drop-shadow-lg min-h-[476px]">
        {/* <div className="flex items-end justify-between p-4">
          <div className="space-y-2 w-[300px] dark:text-white">
            {t("date")}
            <InputRangeDatePicker
              className="z-20"
              onChange={(e: string) => {
                paramsData.delete("page");
                paramsData.set("date", e);
                setDate(e);
              }}
            />
          </div>
          <InputSearch
            data={[]}
            className="w-[400px]"
            optionHidden
            placeholder={t("search") + "..."}
            onInput={(query: string) => {
              paramsData.delete("page");
              paramsData.set("search", query);
              setQuery(query);
            }}
          />
        </div> */}
        <div className="flex items-center justify-between p-4">
          <h2 className="text-16 font-bold dark:text-white">
            Mashin.al App üçün bildirişlərin statistikası
          </h2>
          <Link
            to="/mashin-al-app"
            className="h-10.5 flex items-center gap-x-2 dark:text-white px-4 whitespace-nowrap rounded border border-solid border-gray"
          >
            <BackSVG />
            <p className="dark:text-white">{t("return_back")}</p>
          </Link>
        </div>
        <table className="min-w-[991px] w-full">
          <thead>
            <tr className="[&>th]:text-left [&>th]:px-2 [&>th]:py-4 [&>th]:dark:text-white [&>th]:whitespace-nowrap border-b border-solid border-gray">
              <th className="w-[34%] !pl-4">{t("title")}</th>
              <th className="w-[22%]">{t("link")}</th>
              <th className="w-[22%]">{t("view_count")}</th>
              <th className="w-[22%] !pr-4">{t("date")}</th>
            </tr>
          </thead>
          <tbody>
            {data && !isLoading ? (
              data.data?.length > 0 &&
              data.data.map((stats) => (
                <tr
                  className="relative [&>td]:py-4 [&>td]:px-2 [&>td]:dark:text-white after:absolute after:top-0 after:left-0 after:w-full after:h-[1px] after:bg-light after:opacity-50"
                  key={stats.id}
                >
                  <td className="!pl-4">{stats.title}</td>
                  <td>{stats.label}</td>
                  <td>{stats.view_count}</td>
                  <td className="!pr-4">{stats.created_at}</td>
                </tr>
              ))
            ) : (
              <>
                {[...Array(perPage)].map((_, i) => (
                  <tr
                    className="[&>td]:py-4 [&>td]:px-2 bg-white dark:bg-lightBlack"
                    key={i}
                  >
                    <td className="w-[34%]">
                      <Shimmer className="h-10.5" />
                    </td>
                    <td className="w-[22%]">
                      <Shimmer className="h-10.5" />
                    </td>
                    <td className="w-[22%]">
                      <Shimmer className="h-10.5" />
                    </td>
                    <td className="w-[22%]">
                      <Shimmer className="h-10.5" />
                    </td>
                  </tr>
                ))}
              </>
            )}
          </tbody>
        </table>
        {data && !data.data.length && (
          <div className="w-full h-[500px] p-4 flex items-center justify-center">
            <div className="w-fit flex items-center gap-x-3 p-4 rounded-xl bg-light dark:bg-softBlack text-gray dark:text-white">
              <InfoSVG />
              <p>Göstəriləcək hər hansı bir məlumat yoxdur</p>
            </div>
          </div>
        )}
        {data?.meta?.total > 5 && (
          <div className="w-full px-4 py-4 border-t border-solid border-gray mt-auto">
            <Pagination
              page={Math.ceil(data?.meta?.total / perPage)}
              total={data?.meta?.total}
              perPage={perPage}
              pageChange={(e) => {
                setPage(e + 1);
                params.set("page", (e + 1).toString());
                setParams("?" + params.toString());
              }}
              setPerPage={(val) => {
                setPerPage(val);
                params.set("page", "1");
                params.set("per_page", val.toString());
                setParams("?" + params.toString());
              }}
            />
          </div>
        )}
      </div>
      {/* <Button
        className="ml-auto gap-x-2 bg-darkBlue dark:bg-blue text-white whitespace-nowrap"
        onClick={onExcelExport}
        isLoading={excelExportLoading}
      >
        <ExcelSVG /> <p>{t("export_to_excel")}</p>
      </Button> */}
    </div>
  );
};

export default MashinalAppNotificationStatisticsTable;
