import Header from "src/components/Header/Header";
import ShiftChangeAdmin from "src/components/ShiftChange/ShiftChangeAdmin";
import ShiftChangeOperator from "src/components/ShiftChange/ShiftChangeOperator";
import { EPermissions } from "src/enums/e_permissions";
import usePermission from "src/hooks/usePermission";
import t from "src/hooks/useTranslate";

const ShiftChange = () => {
  const isAdmin = usePermission(
    [EPermissions.shift_change, EPermissions.shift_change_list],
    true
  );

  return (
    <>
      <Header title={t("shift_change")} />
      {isAdmin !== undefined &&
        (isAdmin ? <ShiftChangeAdmin /> : <ShiftChangeOperator />)}
    </>
  );
};

export default ShiftChange;
