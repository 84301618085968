import { FC, SyntheticEvent, useEffect } from "react";
import ReactInputMask from "react-input-mask";
import Button from "../Common/Inputs/Button";
import Input from "../Common/Inputs/Input";
import Select from "../Common/Select";
import { ReactComponent as XSVG } from "src/assets/vectors/x.svg";
import { phonePattern } from "src/utils/validationPatterns";
import t from "src/hooks/useTranslate";
import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import {
  setAllInputs,
  setForm,
} from "src/store/Announcements/announcementStore";
import announceTypeData from "src/data/announce_type_data.json";
import InputPhone from "../Common/Inputs/InputPhone";
import announcementStatusData from "src/data/announcement_status_data.json";
import { useShowAnnouncementsQuery } from "src/services/announcementsService";
import Shimmer from "../Common/Shimmer";

const AnnouncementFormEdit: FC<{
  isOperator: boolean;
  announcementID: number;
  onClose?: () => void;
  onSubmit: (e: SyntheticEvent) => void;
  isLoading: boolean;
}> = ({ isOperator, announcementID, onClose, onSubmit, isLoading }) => {
  const dispatch = useAppDispatch();
  const { data: announcement, isSuccess: announcementReady } =
      useShowAnnouncementsQuery(announcementID);
  const maskChars = {
    "#": "[0-9]",
    $: "[a-zA-Z]",
  };

  useEffect(() => {
    announcementReady && dispatch(setForm(announcement.data.invoice));
  }, [announcementReady]);

  const announcement_form = useAppSelector(
    (state) => state.announcements.announcement_form
  );

  return (
    <>
      <div className="relative flex items-center justify-between gap-x-4 p-4 border-b border-solid border-gray">
        <p className="text-16 font-bold dark:text-white">
          {t("edit_announcement")}
        </p>
        <XSVG className="text-gray cursor-pointer" onClick={onClose} />
      </div>
      <form className="grid grid-cols-2 gap-4 p-4" onSubmit={onSubmit}>
        {announcementReady ? (
          <>
            <InputPhone
              validpattern={phonePattern}
              wideOpenSelect
              label={t("phone")}
              value={announcement?.data.invoice.phone}
              onChange={(e) =>
                dispatch(
                  setAllInputs({
                    key: "announcement_form",
                    name: "phone",
                    value: e,
                  })
                )
              }
              name="phone"
            />
            <Select
              label={t("type")}
              data={announceTypeData.slice(1)}
              defaultTitle={t("choose")}
              defaultValue={announcement?.data.invoice.announce_type}
              onChange={(e) =>
                dispatch(
                  setAllInputs({
                    key: "announcement_form",
                    name: "announce_type",
                    value: e.id,
                  })
                )
              }
            />
            {(announcement.announce_type === 1 ||
              announcement.announce_type === 4) && (
              <div className="">
                <p>{t("car_number")}</p>
                <ReactInputMask
                  mask="##-$$-###"
                  className="w-full h-10.5 px-4 block border border-solid border-gray rounded uppercase"
                  //@ts-ignore
                  maskChar="_"
                  tabIndex={-1}
                  //@ts-ignore
                  formatChars={maskChars}
                  placeholder="10-AA-100"
                  value={announcement?.data.invoice.car_number_mask}
                  onChange={(e) =>
                    dispatch(
                      setAllInputs({
                        key: "announcement_form",
                        name: "car_number",
                        value: e.target.value.replaceAll("-", ""),
                      })
                    )
                  }
                />
              </div>
            )}
            {announcement.announce_type === 1 && (
              <Input
                label={"VIN"}
                className="[&>input]:uppercase"
                defaultValue={announcement?.data.invoice.vin}
                maxLength={17}
                validpattern={/^(?:[A-Za-z0-9]{17})?$/}
                onChange={(e) =>
                  dispatch(
                    setAllInputs({
                      key: "announcement_form",
                      name: "vin",
                      value: e.target.value.trim(),
                    })
                  )
                }
              />
            )}
            <Input
              label={t("link")}
              placeholder="https://..."
              defaultValue={announcement?.data.invoice.turbo_link}
              maxLength={2048}
              onChange={(e) =>
                dispatch(
                  setAllInputs({
                    key: "announcement_form",
                    name: "link",
                    value: e.target.value.trim(),
                  })
                )
              }
            />
            <Input
              label={"Mashin.al " + t("link")}
              className={`relative min-w-[260px] ${
                announcement_form.status == 2
                  ? ""
                  : "pointer-events-none after:absolute after:inset-0 after:bg-gray dark:after:bg-black after:opacity-50 after:z-10 text-gray"
              }`}
              placeholder="https://..."
              value={announcement_form.mashin_link}
              defaultValue={announcement?.data.invoice.mashin_link}
              onChange={(e) =>
                dispatch(
                  setAllInputs({
                    key: "announcement_form",
                    name: "mashin_link",
                    value: e.target.value.trim(),
                  })
                )
              }
            />
            <div className="space-y-6">
              <Select
                label={t("status")}
                optionsClassName={"!max-h-[unset] !bottom-[calc(100%+14px)]"}
                data={announcementStatusData
                  .filter((ads) => (isOperator ? ads.id != 3 : true))
                  .slice(1)}
                defaultValue={announcement?.data.invoice.status}
                defaultTitle={t("choose")}
                onChange={(e) => {
                  dispatch(
                    setAllInputs({
                      key: "announcement_form",
                      name: "status",
                      value: e.id,
                    })
                  );
                }}
              />
              <Button
                className="w-full bg-blue text-white"
                isLoading={isLoading}
              >
                {t("remember")}
              </Button>
            </div>
            <div className="space-y-2">
              <p className="dark:text-white">{t("comment")}</p>
              <textarea
                className="w-full p-4 mb-4 border border-solid border-gray rounded resize-none dark:bg-softBlack dark:text-white cursor-auto"
                placeholder="Səbəbi yazın"
                defaultValue={announcement?.data.invoice.comment}
                rows={3}
                maxLength={3000}
                onChange={(e) =>
                  dispatch(
                    setAllInputs({
                      key: "announcement_form",
                      name: "comment",
                      value: e.target.value,
                    })
                  )
                }
              />
            </div>
          </>
        ) : (
          <>
            <div className="space-y-2">
              <Shimmer className="w-1/2 h-4" />
              <Shimmer className="w-full h-10.5" />
            </div>
            <div className="space-y-2">
              <Shimmer className="w-1/2 h-4" />
              <Shimmer className="w-full h-10.5" />
            </div>
            <div className="space-y-2">
              <Shimmer className="w-1/2 h-4" />
              <Shimmer className="w-full h-10.5" />
            </div>
            <div className="space-y-2">
              <Shimmer className="w-1/2 h-4" />
              <Shimmer className="w-full h-10.5" />
            </div>
            <div className="space-y-2">
              <Shimmer className="w-1/2 h-4" />
              <Shimmer className="w-full h-10.5" />
            </div>
            <div className="space-y-2">
              <Shimmer className="w-1/2 h-4" />
              <Shimmer className="w-full h-10.5" />
            </div>
            <div className="space-y-4">
              <div className="space-y-2">
                <Shimmer className="w-1/2 h-4" />
                <Shimmer className="w-full h-10.5" />
              </div>
              <div className="space-y-2">
                <Shimmer className="w-1/2 h-4" />
                <Shimmer className="w-full h-10.5" />
              </div>
            </div>
            <div className="space-y-2">
              <Shimmer className="w-1/2 h-4" />
              <Shimmer className="w-full h-[124px]" />
            </div>
          </>
        )}
      </form>
    </>
  );
};

export default AnnouncementFormEdit;
