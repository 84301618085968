import { useState, useEffect } from "react";
import t from "src/hooks/useTranslate";
import { Switch } from "@headlessui/react";
import Input from "src/components/Common/Inputs/Input";
import Button from "src/components/Common/Inputs/Button";
import InputPassword from "src/components/Common/Inputs/InputPassword";
import { useLoginUserMutation } from "src/services/authService";
import { errorToast, successToast } from "src/store/Settings/toastStore";
import { useAppDispatch } from "src/hooks/redux";
import { useNavigate } from "react-router-dom";
import { getCookie, setCookie } from "src/helper/custom_cookies";
import { emailRequiredPattern, passwordRequiredPattern } from "src/utils/validationPatterns";
import { validateEmail, validatePassword } from "src/helper/validation";

const Login = () => {
  const navigate = useNavigate();
  const [enabled, setEnabled] = useState(false);
  const [valid, setValid] = useState(false);
  const dispatch = useAppDispatch();
  const [values, setValues] = useState({
    email: "",
    password: "",
  });

  const [
    loginUser,
    {
      data: loginData,
      isLoading: isLoginLoading,
      isSuccess: isLoginSuccess,
      error: loginError,
      isError: isLoginError,
    },
  ] = useLoginUserMutation();

  const inputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    await loginUser(values);
  };

  useEffect(() => {
    if (
      validateEmail(values.email, true) &&
      validatePassword(values.password, true)
    ) {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [values]);

  useEffect(() => {
    if (isLoginSuccess) {
      enabled
        ? localStorage.setItem("email", values.email)
        : localStorage.removeItem("email");
      dispatch(successToast(t("login_success")));
      setCookie("mcrmtkn", loginData.meta.token, 365);
      navigate("/");
    }
  }, [isLoginSuccess]);

  useEffect(() => {
    if (isLoginError) {
      //@ts-ignore
      dispatch(errorToast(loginError?.data.message));
    }
  }, [isLoginError]);

  useEffect(() => {
    if (localStorage.getItem("email")) {
      setEnabled(true);
      setValues({ ...values, email: localStorage.getItem("email") });
    }
  }, []);

  return (
    <div className="relative w-full min-h-[100vh] flex items-center justify-center bg-[url(src/assets/images/login-background.jpg)] bg-no-repeat bg-cover	 after:absolute after:inset-0 after:bg-darkBlue after:opacity-90">
      <div className="relative z-10 w-[410px] bg-white dark:bg-lightBlack px-6 pb-10 pt-40 rounded-lg">
        <div className="absolute w-[378px] h-36 -top-6 left-1/2 -translate-x-1/2 flex items-center justify-center bg-red text-white rounded-lg">
          <h1 className="text-30 font-bold">{t("entertosystem")}</h1>
        </div>
        <form className="[&>*]:mb-4" onSubmit={onSubmit}>
          <Input
            type="email"
            label={t("email") + "*"}
            defaultValue={values.email}
            onChange={inputChangeHandler}
            validpattern={emailRequiredPattern}
            placeholder={t("email")}
            name="email"
          />
          <InputPassword
            placeholder={t("password")}
            label={t("password") + "*"}
            onChange={inputChangeHandler}
            validpattern={passwordRequiredPattern}
            name="password"
          />
          <div className="flex items-center gap-x-4">
            <Switch
              checked={enabled}
              onChange={setEnabled}
              className={`${enabled ? "bg-blue" : "bg-darkSnow/40"}
          relative ml-3 inline-flex h-4 w-8 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 after:absolute after:-inset-x-2.5 after:-inset-y-1`}
            >
              <span
                aria-hidden="true"
                className={`${
                  enabled
                    ? "left-full -translate-x-1/2"
                    : "left-0 -translate-x-1/2"
                }
            absolute top-1/2 -translate-y-1/2 pointer-events-none inline-block h-5 w-5 rounded-full bg-white drop-shadow-md shadow-black ring-0 duration-200 ease-in-out`}
              />
            </Switch>
            <p className="text-gray">{t("remember")}</p>
          </div>
          <Button
            type="submit"
            isLoading={isLoginLoading}
            disabled={!valid}
            className="uppercase font-bold bg-darkBlue dark:bg-blue text-white w-full mt-6 !mb-0"
          >
            {t("enter")}
          </Button>
        </form>
      </div>
    </div>
  );
};

export default Login;
