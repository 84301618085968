import { Switch } from "@headlessui/react";
import { FC, useEffect, useState } from "react";
import t from "src/hooks/useTranslate";
import Avatar from "../Common/Avatar";
import Button from "../Common/Inputs/Button";
import InputCalendar from "../Common/Inputs/InputCalendar";
import Input from "../Common/Inputs/Input";
import InputPassword from "../Common/Inputs/InputPassword";
import Select from "../Common/Select";
import genderData from "src/data/gender_data.json";
import { ReactComponent as InfoSVG } from "src/assets/vectors/info.svg";
import { useAppDispatch } from "src/hooks/redux";
import {
  setAllInputs,
  setAvatar,
  setCalendarInput,
  setPhoneInput,
  setSelectInput,
  setSwitchInput,
  setVisitedUserStep,
} from "src/store/Users/usersStore";
import { useGetGroupAndPermissionsQuery } from "src/services/groupAndPermissionsService";
import {
  emailRequiredPattern,
  nameRequiredPattern,
  passwordRequiredPattern,
  phonePattern,
  phoneRequiredPattern,
  requiredPattern,
} from "src/utils/validationPatterns";
import {
  validateEmail,
  validateName,
  validatePassword,
  validatePhone,
  validateRequired,
} from "src/helper/validation";
import { EUsersCrudSteps } from "src/enums/e_users_crud_steps";
import { EIsActive } from "src/enums/e_is_active";
import InputPhone from "../Common/Inputs/InputPhone";

const UsersForm: FC<any> = ({
  userForm,
  isLoading,
  onSubmit,
  isEdit = false,
}) => {
  const dispatch = useAppDispatch();
  const { data: groupAndPermissions } = useGetGroupAndPermissionsQuery("");
  const [validForm, setValidForm] = useState(false);

  useEffect(() => {
    dispatch(setVisitedUserStep(EUsersCrudSteps.form));
  }, []);

  useEffect(() => {
    let validMaxDate = new Date(
      new Date().setFullYear(new Date().getFullYear() - 16)
    ).getFullYear();

    if (
      validateName(userForm.name, true) &&
      validateName(userForm.lastname, true) &&
      validateName(userForm.father_name, true) &&
      validateEmail(userForm.email, true) &&
      validatePhone(userForm.phone, true) &&
      validatePassword(userForm.password, true) &&
      validateRequired([userForm.position, userForm.salary, userForm.vacation]) &&
      userForm.gender != 0 &&
      userForm.roles != 0 &&
      userForm.birthday.split(".")[2] < validMaxDate
    ) {
      setValidForm(true);
    } else {
      setValidForm(false);
    }
  }, [userForm]);

  return (
    <form className="flex gap-x-6" onSubmit={onSubmit}>
      <Avatar
        imageUrl={userForm.avatar ? userForm.avatar : ""}
        setImage={(e: string) => dispatch(setAvatar(e))}
      />
      <div className="flex gap-x-6">
        <div className="w-1/4 space-y-4">
          <Input
            className="min-w-[260px]"
            label={t("name") + "*"}
            placeholder={t("name_placeholder")}
            maxLength={40}
            onKeyDown={(e) =>
              !/[a-züöğıəşçİ]/i.test(e.key) && e.preventDefault()
            }
            validpattern={nameRequiredPattern}
            defaultValue={userForm.name ? userForm.name : ""}
            onChange={(e) =>
              dispatch(
                setAllInputs({ name: e.target.name, value: e.target.value })
              )
            }
            name="name"
          />
          <Input
            className="min-w-[260px]"
            label={t("lastname") + "*"}
            placeholder={t("lastname_placeholder")}
            maxLength={80}
            onKeyDown={(e) =>
              !/[a-züöğıəşçİ]/i.test(e.key) && e.preventDefault()
            }
            validpattern={nameRequiredPattern}
            defaultValue={userForm.lastname ? userForm.lastname : ""}
            onChange={(e) =>
              dispatch(
                setAllInputs({ name: e.target.name, value: e.target.value })
              )
            }
            name="lastname"
          />
          <Input
            className="min-w-[260px]"
            label={t("fathername") + "*"}
            placeholder={t("fathername_placeholder")}
            maxLength={40}
            onKeyDown={(e) =>
              !/[a-züöğıəşçİ]/i.test(e.key) && e.preventDefault()
            }
            validpattern={nameRequiredPattern}
            defaultValue={userForm.father_name ? userForm.father_name : ""}
            onChange={(e) =>
              dispatch(
                setAllInputs({ name: e.target.name, value: e.target.value })
              )
            }
            name="father_name"
          />
          <Input
            className="min-w-[260px]"
            label={t("job_position") + "*"}
            placeholder={t("position_placeholder")}
            maxLength={80}
            validpattern={nameRequiredPattern}
            defaultValue={userForm.position ? userForm.position : ""}
            onChange={(e) =>
              dispatch(
                setAllInputs({ name: e.target.name, value: e.target.value })
              )
            }
            name="position"
          />
        </div>
        <div className="w-1/4 space-y-4">
          <InputCalendar
            className="min-w-[260px] z-20"
            label={t("birthday")}
            value={userForm.birthday ? userForm.birthday : ""}
            minDate={
              new Date(new Date().setFullYear(new Date().getFullYear() - 100))
            }
            maxDate={
              new Date(new Date().setFullYear(new Date().getFullYear() - 16))
            }
            onChange={(e) => dispatch(setCalendarInput(e))}
          />
          <Select
            data={genderData}
            label={t("gender") + "*"}
            defaultTitle="Cinsi seç"
            defaultValue={userForm.gender != 0 ? userForm.gender : 0}
            onChange={(e) =>
              dispatch(setSelectInput({ name: "gender", value: e.id }))
            }
          />
          <Input
            className="min-w-[260px]"
            label={t("email") + "*"}
            placeholder={t("email_placeholder")}
            maxLength={256}
            validpattern={emailRequiredPattern}
            defaultValue={userForm.email ? userForm.email : ""}
            onChange={(e) =>
              dispatch(
                setAllInputs({ name: e.target.name, value: e.target.value })
              )
            }
            name="email"
          />
          <Input
            type="number"
            className="min-w-[260px]"
            label={t("vacation_days") + "*"}
            placeholder={t("vacation_days_placeholder")}
            maxLength={4}
            validpattern={requiredPattern}
            defaultValue={userForm.vacation ? userForm.vacation : ""}
            onChange={(e) =>
              dispatch(
                setAllInputs({ name: e.target.name, value: e.target.value })
              )
            }
            name="vacation"
          />
        </div>

        <div className="w-1/4 space-y-4">
          <InputPhone
            className="min-w-[260px]"
            validpattern={phoneRequiredPattern}
            value={userForm.phone ? userForm.phone : ""}
            label={t("phone") + "*"}
            onChange={(e) => dispatch(setPhoneInput(e as any))}
            name="phone"
          />

          <Select
            data={groupAndPermissions?.roles}
            label={t("group")}
            defaultTitle="Qrupu seç"
            defaultValue={userForm.roles ? userForm.roles : 0}
            onChange={(e) =>
              dispatch(setSelectInput({ name: "roles", value: e.id }))
            }
          />

          <Input
            type="number"
            className="min-w-[260px]"
            label={t("salary") + "*"}
            placeholder={t("salary_placeholder")}
            maxLength={9}
            validpattern={requiredPattern}
            defaultValue={userForm.salary ? userForm.salary : ""}
            onChange={(e) =>
              dispatch(
                setAllInputs({ name: e.target.name, value: e.target.value })
              )
            }
            name="salary"
          />
          {!isEdit && (
            <InputPassword
              className="min-w-[260px]"
              label={t("password") + "*"}
              placeholder={t("password_placeholder")}
              validpattern={passwordRequiredPattern}
              maxLength={64}
              value={userForm.password ? userForm.password : ""}
              onChange={(e) =>
                dispatch(
                  setAllInputs({ name: e.target.name, value: e.target.value })
                )
              }
              name="password"
            />
          )}
        </div>

        <div className="w-1/4 flex flex-col">
          <p className="dark:text-white">{t("status")}</p>
          <div className="flex items-center gap-x-4 mt-3">
            <Switch
              checked={userForm.status == EIsActive.active ? true : false}
              onChange={(e: boolean) =>
                dispatch(
                  setSwitchInput(e ? EIsActive.active : EIsActive.deactive)
                )
              }
              className={`${
                userForm.status == EIsActive.active
                  ? "bg-darkBlue dark:bg-blue"
                  : "bg-gray"
              }
          relative inline-flex h-5 w-[42px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
              <span className="sr-only">Use setting</span>
              <span
                aria-hidden="true"
                className={`${
                  userForm.status == EIsActive.active
                    ? "translate-x-[22px]"
                    : "translate-x-0"
                }
            pointer-events-none inline-block h-4 w-4 transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
              />
            </Switch>
            <p
              className={
                userForm.status == EIsActive.active ? "text-green" : "text-red"
              }
            >
              {userForm.status == EIsActive.active
                ? t("active")
                : t("deactive")}
            </p>
          </div>
          <div className="flex gap-x-2 mt-12">
            <InfoSVG className="min-w-[24px] dark:text-white" />
            <p className="dark:text-white">
              {t("user_create_form_description")}
            </p>
          </div>
          <Button
            className="w-full text-white font-medium !mt-auto bg-blue"
            isLoading={isLoading}
            disabled={!validForm}
          >
            {t("continue")}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default UsersForm;
