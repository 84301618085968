import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { EIsActive } from 'src/enums/e_is_active'

const initialState: { operator: any } = {
    operator: {
        id: 0,
        avatar: null,
        name: "",
        lastname: "",
        father_name: "",
        position: "",
        birthday: "",
        gender: 0,
        email: "",
        phone: "",
        roles: 0,
        salary: 0,
        status: EIsActive.deactive,
        is_hidden: EIsActive.deactive,
        all_permissions: [],
        working_hours: [[], [], [], [], [], [], []]
    }
}

export const operatorsSlice = createSlice({
    name: 'operator',
    initialState,
    reducers: {
        setOperator: (state, action: PayloadAction<any>) => {
            for (const key in state.operator) {
                state.operator = { ...state.operator, [key]: action.payload[key as keyof typeof state.operator]}
            }
        },
        setWorkingSchedule: (state, action) => {
            state.operator.working_hours[action.payload.weekday] = [...action.payload.value]
        },
        setAllInputs: (state, action: PayloadAction<any>) => {
            //@ts-ignore
            state[action.payload.key as keyof typeof state] = {
                ...state[action.payload.key as keyof typeof state],
                [action.payload.name]: action.payload.value,
            };
        },
    },
})

export const { setOperator, setWorkingSchedule, setAllInputs } = operatorsSlice.actions

export default operatorsSlice.reducer