import { useEffect, useState } from "react";
import InputSearch from "src/components/Common/Inputs/InputSearch";
import Pagination from "src/components/Common/Pagination";
import Shimmer from "src/components/Common/Shimmer";
import t from "src/hooks/useTranslate";
import { ReactComponent as FileSVG } from "src/assets/vectors/file.svg";

const CredentialsTable = () => {
  const [params, setParams] = useState(new URLSearchParams());
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  useEffect(() => {
    // refetch()
  }, [setPage, setPerPage]);
  return (
    <div className="w-full relative bg-white dark:bg-lightBlack rounded-xl drop-shadow-lg">
      <div className="flex justify-between items-center p-4">
        <p className="text-16 font-bold dark:text-white">{t("informations")}</p>
        <InputSearch
          data={[]}
          className="w-[300px]"
          placeholder={t("search") + "..."}
          onInput={(query: string) => null}
          onChange={(e) => console.log(e)}
        />
      </div>
      <table className="min-w-[991px] w-full">
        <thead>
          <tr className="[&>th]:text-left [&>th]:px-2 [&>th]:py-4 [&>th]:dark:text-white [&>th]:whitespace-nowrap border-y border-solid border-gray">
            <th className="w-auto !pl-4">{t("date")}</th>
            <th className="w-auto">{t("car_number")}</th>
            <th className="w-auto">{t("user")}</th>
            <th className="w-auto">{t("end_date")}</th>
            <th className="w-auto">{t("status")}</th>
            <th className="w-auto">{t("operation_status")}</th>
            <th className="w-autp !pr-4">{t("operation_status")} PDF</th>
          </tr>
        </thead>
        <tbody>
          {true ? (
            [...Array(5)].map((_, i) => (
              <tr
                className="relative [&>td]:py-4 [&>td]:px-2 [&>td]:dark:text-white after:absolute after:top-0 after:left-0 after:w-full after:h-[1px] after:bg-light after:opacity-50"
                key={i}
              >
                <td className="!pl-4">23.02.2022 01:19:54</td>
                <td>77-BZ-351</td>
                <td>Məhəmməd(9945554512)</td>
                <td>23.02.2022 01:19:54</td>
                <td>Gözləyir</td>
                <td>Ödəniş təsdiqlənib</td>
                <td className="!pr-4">
                  <FileSVG />
                </td>
              </tr>
            ))
          ) : (
            <>
              {[...Array(10)].map((_, i) => (
                <tr
                  className="[&>td]:py-4 [&>td]:px-2 bg-white dark:bg-lightBlack"
                  key={i}
                >
                  {[...Array(7)].map((__, idx) => (
                    <td className="w-auto" key={idx}>
                      <Shimmer className="h-10.5" />
                    </td>
                  ))}
                </tr>
              ))}
            </>
          )}
        </tbody>
      </table>
      {5 > perPage && (
        <div className="w-full px-4 py-4 border-t border-solid border-gray">
          <Pagination
            page={Math.ceil(20 / perPage)}
            total={20}
            perPage={perPage}
            pageChange={(e) => {
              setPage(e + 1);
              params.set("page", (e + 1).toString());
            }}
            setPerPage={(val) => {
              setPerPage(val);
              params.set("per_page", val.toString());
            }}
          />
        </div>
      )}
    </div>
  );
};

export default CredentialsTable;
