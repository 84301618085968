import { profile } from "console";
import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { EGender } from "src/enums/e_gender";
import { EPermissions } from "src/enums/e_permissions";
import { ddmmyyyy, ddmmyyyy_hhmm } from "src/helper/getFormattedDate";
import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import usePermission from "src/hooks/usePermission";
import t from "src/hooks/useTranslate";
import {
  useDeleteRequestMutation,
} from "src/services/requestsService";
import { setRequestCheckboxes } from "src/store/Requests/RequestsStore";
import { successToast } from "src/store/Settings/toastStore";
import Button from "../Common/Inputs/Button";
import Checkbox from "../Common/Inputs/Checkbox";
import Shimmer from "../Common/Shimmer";

const RequestsTable = ({ data, activeTab, isLoading }) => {
  const [selected, setSelected] = useState([]);
  const dispatch = useAppDispatch();
  const canDeleteTicket = usePermission([EPermissions.ticket_delete])
  const [
    deleteRequest,
    { isLoading: isRequestDeleting, isSuccess: isRequestDeleted },
  ] = useDeleteRequestMutation();

  const onDeleteRequest = async () => {
    await deleteRequest({ id: selected.filter((el) => el !== 0) });
  };

  useEffect(() => {
    if (isRequestDeleted) {
      dispatch(successToast("Sorğu uğurla silindi"));
    }
  }, [isRequestDeleted]);

  return (
    <div className="min-h-[526px] relative flex-1 px-4 py-6">
      <div className="h-10.5 flex items-center justify-between mb-4">
        <p className="text-16 font-bold dark:text-white">{t("requests")}: {activeTab?.label}</p>
        {activeTab?.id === 5 && canDeleteTicket && (
          <Button
            className="w-48 bg-red text-white"
            onClick={onDeleteRequest}
            isLoading={isRequestDeleting}
          >
            {t("delete_selected_requests")}
          </Button>
        )}
      </div>
      <table className="w-full">
        <thead>
          <tr className="[&>th]:text-left [&>th]:pb-6 [&>th]:dark:text-white [&>th]:px-2 [&>th]:whitespace-nowrap border-b border-solid border-gray">
            {activeTab?.id === 5 && canDeleteTicket && data?.length > 0 && (
              <th className="w-auto flex items-center gap-x-1.5 !pl-4 cursor-pointer">
                <Checkbox
                  id={0}
                  isChecked={selected.includes(0)}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    selected.includes(0)
                      ? setSelected([])
                      : setSelected([0, ...data.map((request) => request.id)]);
                  }}
                />
              </th>
            )}
            <th className="w-14"></th>
            <th className="w-auto">{t("subject")}</th>
            <th className="w-auto">{t("status")}</th>
            <th className="w-36 !pr-4">{t("date")}</th>
          </tr>
        </thead>
        <tbody>
          {data && !isLoading ? (
            data.length ? (
              data.map((request) =>  (
                  <tr
                    className="relative [&>td]:py-4 [&>td]:px-2 [&>td]:dark:text-white after:absolute after:top-0 after:left-0 after:w-full after:h-[1px] after:bg-gray after:opacity-50"
                    key={request.id}
                  >
                    {activeTab?.id === 5 && canDeleteTicket && (
                      <td className="w-10 !pl-4">
                        <Checkbox
                          key={request.id}
                          id={request.id}
                          isChecked={selected.includes(request.id)}
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            selected.includes(request.id)
                              ? setSelected(
                                  selected.filter(
                                    (check) => check != request.id && check != 0
                                  )
                                )
                              : !data
                                  .map((request) => request.id)
                                  .filter(
                                    (dif) =>
                                      ![...selected, request.id].includes(dif)
                                  ).length
                              ? setSelected((prev) => [0, ...prev, request.id])
                              : setSelected((prev) => [...prev, request.id]);
                          }}
                        />
                      </td>
                    )}
                    <td>
                      <div className="w-14 h-14 rounded-full overflow-hidden">
                        <img
                          src={
                            request.author.avatar
                              ? request.author.avatar
                              : request.author.gender === EGender.male
                              ? "/assets/images/male_avatar.png"
                              : "/assets/images/female_avatar.png"
                          }
                          className="w-full h-full object-cover"
                          alt=""
                        />
                      </div>
                    </td>
                    <td className="cursor-pointer flex flex-col justify-between hover:text-blue">
                      <Link to={"/requests/" + request.id}>
                        <p className="font-semibold">
                          #{request.id} - {request.title}
                        </p>
                        <p className="font-medium text-gray">
                          Started by {request.author.full_name} - {" "}
                          {request.assign_to ?? t("unassigned")}
                        </p>
                      </Link>
                    </td>
                    <td>{request.status}</td>
                    <td>
                      {request.created_at}
                    </td>
                  </tr>
                )
              )
            ) : (
              <div className="p-4 absolute inset-0 flex flex-col justify-center items-center bg-white dark:bg-lightBlack z-[9]">
                <div className="flex-1">
                  <img
                    src="/assets/images/search_no_results.png"
                    alt="No Results"
                    className="w-full h-full object-contain"
                  />
                </div>
                <p className="text-22 my-4 dark:text-white font-bold">
                  Hər hansı sorğu yoxdur
                </p>
              </div>
            )
          ) : (
            <>
              {[...Array(5)].map((_, i) => (
                <tr className="[&>td]:py-4 [&>td]:px-2" key={i}>
                  {activeTab?.id === 5 && (
                    <td className="w-16">
                      <Shimmer className="h-10.5 bg-light" />
                    </td>
                  )}
                  <td className="w-16">
                    <Shimmer className="h-10.5 bg-light" />
                  </td>
                  <td className="auto">
                    <Shimmer className="h-10.5 bg-light" />
                  </td>
                  <td className="auto">
                    <Shimmer className="h-10.5 bg-light" />
                  </td>
                  <td className="auto">
                    <Shimmer className="h-10.5 bg-light" />
                  </td>
                </tr>
              ))}
            </>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default RequestsTable;
