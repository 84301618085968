import { useEffect, useState } from "react";
import t from "src/hooks/useTranslate";
import { useNavigate } from "react-router-dom";
import GroupsForm from "src/components/Groups/GroupsForm";
import GroupsPermissions from "src/components/Groups/GroupsPermissions";
import Header from "src/components/Header/Header";
import { GroupsStepsData } from "src/data/create_groups_steps_data";
import { EGroupsCrudSteps } from "src/enums/e_groups_crud_steps";
import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import {
  useCreateGroupMutation,
  useGetGroupAndPermissionsQuery,
  useSetGroupPermissionsMutation,
  useUpdateGroupMutation,
} from "src/services/groupAndPermissionsService";
import {
  reset,
  setNewGroup,
  setPermissions,
} from "src/store/Groups/groupsStore";
import { setPermissions as setPermissionStore } from "src/store/Settings/permissionsStore";
import { errorToast, successToast } from "src/store/Settings/toastStore";

const GroupsCreate = () => {
  const dispatch = useAppDispatch();
  const [currentStep, setCurrentStep] = useState(EGroupsCrudSteps.form);
  const groupForm = useAppSelector((state) => state.groups.groups_form);
  const visitedSteps = useAppSelector((state) => state.groups.visitedSteps);
  const newGroupData = useAppSelector((state) => state.groups.newGroup);
  const { data: groupAndPermissions } = useGetGroupAndPermissionsQuery("");
  const navigate = useNavigate();
  const groups_permissions = useAppSelector(
    (state) => state.groups.groups_permissions
  );
  const all_groups_permissions = useAppSelector(
    (state) => state.groups.all_group_permission_selected
  );

  useEffect(() => {
    groups_permissions.child.length > 0 &&
      dispatch(
        setPermissionStore({
          exist: [],
          data: groups_permissions.child,
        })
      );
  }, [groups_permissions]);

  const renderSwitch = () => {
    switch (currentStep) {
      case EGroupsCrudSteps.form:
        return (
          <GroupsForm
            groupForm={groupForm}
            onSubmit={onCreateForm}
            isGroupCreating={isGroupFormCreating}
            isGroupUpdating={isGroupFormUpdating}
          />
        );
      case EGroupsCrudSteps.permissions:
        return (
          <GroupsPermissions
            isLoading={isGroupFormCreating || isGroupFormUpdating}
            onSubmit={(allSelected: number[]) =>
              onCreatePermissions(allSelected)
            }
          />
        );
      default:
        return;
    }
  };

  useEffect(() => {
    setCurrentStep(EGroupsCrudSteps.form);
  }, []);

  //Form

  const [
    createGroup,
    {
      data: createdGroupData,
      isLoading: isGroupFormCreating,
      isSuccess: isGroupFormCreated,
      isError: isGroupFormNotCreated,
    },
  ] = useCreateGroupMutation();


  useEffect(() => {
    if (isGroupFormNotCreated) {
      dispatch(errorToast(t("group_exist")));
    }
  }, [isGroupFormNotCreated]);

  const [
    updateGroup,
    {
      data: updatedGroupData,
      isLoading: isGroupFormUpdating,
      isSuccess: isGroupFormUpdated,
      isError: isGroupFormNotUpdated,
    },
  ] = useUpdateGroupMutation();

  const onCreateForm = async (e: any) => {
    e.preventDefault();
    if (Object.keys(newGroupData).length === 0) {
      return await createGroup(groupForm);
    }
    const body = {
      id: newGroupData.id,
      name: groupForm.name,
    };
    await updateGroup(body);
  };

  useEffect(() => {
    if (isGroupFormCreated) {
      dispatch(setNewGroup(createdGroupData.role));
      setCurrentStep(EGroupsCrudSteps.permissions);
    }
    if (isGroupFormUpdated) {
      setCurrentStep(EGroupsCrudSteps.permissions);
    }
  }, [isGroupFormCreated, isGroupFormUpdated]);

  //Permission

  useEffect(() => {
    groupAndPermissions && dispatch(setPermissions(groupAndPermissions));
  }, [groupAndPermissions]);

  const [
    setGroupsPermission,
    {
      data: groupsPermissionData,
      isLoading: isGroupsPermissionLoading,
      isSuccess: isGroupsPermissionSuccess,
      isError: isGroupsPermissionError,
    },
  ] = useSetGroupPermissionsMutation();

  const onCreatePermissions = async (allSelected: number[]) => {
    await setGroupsPermission({
      id: newGroupData.id,
      permission: allSelected.filter((permission: any) => permission !== 0),
    });
  };

  useEffect(() => {
    if (isGroupsPermissionSuccess) {
      dispatch(reset());
      dispatch(successToast("Qrup əlavə edildi."));
      navigate("/groups");
    }
  }, [isGroupsPermissionSuccess]);

  return (
    <>
      <Header title={t("create_group")} backLink="/groups" />
      <div className="relative py-6 bg-white dark:bg-lightBlack rounded-xl mt-6 drop-shadow-lg">
        <ul className="flex items-center gap-x-4 px-4 pb-6 border-b border-solid border-b-gray">
          {GroupsStepsData.map((step) => {
            const Icons = step.icon;
            return (
              <li
                key={step.id}
                className={`flex items-center gap-x-2 px-4 py-2 rounded last:hidden ${
                  step.id === currentStep
                    ? "bg-light text-darkBlue dark:bg-softBlack dark:text-white"
                    : "font-medium text-gray dark:text-white"
                } ${visitedSteps.includes(step.id) ? "cursor-pointer" : ""}`}
                onClick={() =>
                  visitedSteps.includes(step.id) && setCurrentStep(step.id)
                }
              >
                <Icons />
                {t(step.title)}
              </li>
            );
          })}
        </ul>
        <div className="px-4 pt-6">{renderSwitch()}</div>
      </div>
    </>
  );
};

export default GroupsCreate;
