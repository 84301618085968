import { FC, Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { ReactComponent as CheckSVG } from "src/assets/vectors/check.svg";
import { ReactComponent as UpChevronSVG } from "src/assets/vectors/up_chevron.svg";
import { Listbox, Transition } from "@headlessui/react";
import { IBaseSelect } from "src/types/Common/i_base_select";
import t from "src/hooks/useTranslate";

const MultipleSelect: FC<any> = ({
  data,
  label,
  defaultTitle,
  isSelectOpened,
  defaultValue,
  isReset,
  onChange,
  disabledId
}) => {
  const [selected, setSelected] = useState([]);
  const [z, setZ] = useState(11);

  const handler = (e: IBaseSelect[]) => {
    setSelected(e);
    onChange(e);
  };

  useEffect(() => {
    if (isReset) {
      setSelected([]);
    }
  }, [isReset]);

  useEffect(() => {
    if (data && defaultValue) {
      setSelected(
        data.filter((val) =>
          defaultValue.map((defVal) => defVal.id).includes(val.id)
        )
      );
    }
  }, [data, defaultValue]);

  return (
    <div className="w-full relative" style={{ zIndex: z }}>
      {label && <p className="mb-2 dark:text-white">{label}</p>}
      <Listbox value={selected} onChange={handler} multiple>
        {({ open }) => {
          setTimeout(() => {
            open ? setZ(12) : setZ(11);
          });
          isSelectOpened?.(open);
          return (
            <div className="relative">
              <Listbox.Button className="relative w-full h-10.5 cursor-pointer bg-white dark:bg-softBlack font-medium py-2 pl-3 pr-10 text-left border border-solid border-gray rounded">
                <span className="block truncate dark:text-white">
                  {/* {t(selected?.name) || defaultTitle} */}
                  {selected.map((slct) => slct.name).join(", ") || defaultTitle}
                </span>
                <span
                  className={`pointer-events-none absolute duration-300 ease-in right-3 flex inset-y-0 items-center ${
                    open ? "-rotate-0" : "rotate-180"
                  }`}
                >
                  <UpChevronSVG className="w-3 h-3 text-darkBlue dark:text-white" />
                </span>
              </Listbox.Button>
              <Transition
                as={Fragment}
                enter="transition ease-in duration-180"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition ease-in duration-180"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute mt-2 px-4 py-1 rounded-b max-h-60 w-full overflow-auto bg-white dark:bg-softBlack border border-solid border-gray rounded sm:text-sm">
                  {data?.map((obj) => (
                    <Listbox.Option
                      key={obj.id}
                      className={({ active }) =>
                        `relative text-[17px] select-none py-2 pl-10 rounded ${
                          active ? "bg-light dark:bg-lightBlack" : ""
                        } ${
                          disabledId === obj.id
                            ? "hidden"
                            : "cursor-pointer"
                        }`
                      }
                      value={obj}
                    >
                      {({ selected }) => (
                        <>
                          <span
                            className={`block truncate dark:text-white ${
                              selected ? "font-medium" : "font-normal"
                            }`}
                          >
                            {t(obj.name)}
                          </span>
                          {selected ? (
                            <span className="absolute inset-y-0 -left-2 flex items-center">
                              <CheckSVG className="absolute w-4 h-4 top-1/2 -translate-y-1/2 left-4 text-green" />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          );
        }}
      </Listbox>
    </div>
  );
};

export default MultipleSelect;