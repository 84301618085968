import { FC, Fragment, useEffect, useState } from "react";
import { ReactComponent as UpChevronSVG } from "src/assets/vectors/up_chevron.svg";
import { ReactComponent as CheckSVG } from "src/assets/vectors/check.svg";
import { Listbox, Transition } from "@headlessui/react";
import { IBaseSelect } from "src/types/Common/i_base_select";
import t from "src/hooks/useTranslate";

const TagSelect: FC<any> = ({
  data,
  label,
  defaultTitle,
  isSelectOpened,
  defaultValue,
  isReset,
  onChange,
}) => {
  const [selected, setSelected] = useState([]);
  const [z, setZ] = useState(11);

  useEffect(() => {
    if (defaultValue && data.length) {
      setSelected([
        ...data?.filter((el) =>
          defaultValue.map((val) => val.id).includes(el.id)
        ),
      ]);
    }
  }, [defaultValue, data]);

  const handler = (e: IBaseSelect[]) => {
    setSelected(e);
    onChange(e);
  };

  useEffect(() => {
    if (isReset) {
      setSelected([]);
    }
  }, [isReset]);

  return (
    <div className="w-full relative" style={{ zIndex: z }}>
      {label && <p className="mb-2 dark:text-white">{label}</p>}
      <Listbox value={selected} onChange={handler} multiple>
        {({ open }) => {
          setTimeout(() => {
            open ? setZ(12) : setZ(11);
          });
          isSelectOpened?.(open);
          return (
            <div className="relative">
              <Listbox.Button className="relative w-full h-10.5 cursor-pointer bg-white dark:bg-softBlack font-medium py-2 pl-3 pr-10 text-left border border-solid border-gray rounded">
                <span className="h-6 flex flex-wrap gap-x-3 gap-y-4 whitespace-nowrap overflow-auto dark:text-white">
                  {selected.length
                    ? selected?.map((item) => (
                        <span className="px-4 bg-light dark:bg-lightBlack rounded" key={item.id}>
                          {t(item.name)}
                        </span>
                      ))
                    : defaultTitle}
                </span>
                <span
                  className={`pointer-events-none absolute duration-300 ease-in right-3 flex inset-y-0 items-center ${
                    open ? "-rotate-0" : "rotate-180"
                  }`}
                >
                  <UpChevronSVG className="w-3 h-3 text-darkBlue dark:text-white" />
                </span>
              </Listbox.Button>
              <Transition
                as={Fragment}
                enter="transition ease-in duration-180"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition ease-in duration-180"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute flex flex-wrap gap-4 mt-2 px-4 py-1 rounded-b max-h-60 w-full overflow-auto bg-white dark:bg-softBlack border border-solid border-gray rounded sm:text-sm">
                  {data?.map((obj) => (
                    <Listbox.Option
                      key={obj.id}
                      className={({ active, selected }) =>
                        `relative flex items-center gap-x-3 cursor-pointer text-[17px] select-none py-2 px-2.5 rounded ${
                          active || selected
                            ? "!bg-blue text-white dark:bg-lightBlack"
                            : "bg-light dark:bg-lightBlack"
                        }`
                      }
                      value={obj}
                    >
                      {({ selected }) => (
                        <>
                          <span
                            className={`truncate dark:text-white ${
                              selected ? "font-medium" : "font-normal"
                            }`}
                          >
                            {t(obj.name)}
                          </span>
                          {selected ? <CheckSVG className="text-white" /> : ""}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          );
        }}
      </Listbox>
    </div>
  );
};

export default TagSelect;
