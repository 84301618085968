import { ReactComponent as XSVG } from "src/assets/vectors/x.svg";

const TextModal = ({ setModal, title, text }) => {
  return (
    <>
      <div className="flex items-center justify-between gap-x-4 p-4 border-b border-solid border-gray">
        <p className="text-16 font-bold dark:text-white">{title}</p>
        <XSVG
          className="text-gray cursor-pointer dark:text-white"
          onClick={() => setModal(false)}
        />
      </div>
      <div className="p-4 dark:text-white">
        <p>{text}</p>
      </div>
    </>
  );
};

export default TextModal;
