export const ENotificationTypes = {
    timeOffSend: "time_off_send",
    timeOffApproved: "time_off_approved",
    timeOffReject: "time_off_reject",
    vacationSend: "vacation_send",
    vacationApproved: "vacation_approved",
    vacationRejected: "vacation_rejected",
    ticketSend: "ticket_send",
    ticketResponse: "ticket_response",
    shiftChangeSend: "shift_change_send",
    shiftChangeApproved: "shift_change_approved",
    shiftChangeRejected: "shift_change_rejected",
    voiceNote: "voice_note",
    refreshNotification: "refresh_notification",
}