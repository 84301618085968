import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MashinAlAppNotificationForm from "src/components/Finance/MashinAlApp/MashinAlAppNotificationForm";
import Header from "src/components/Header/Header";
import { dataURLtoFile } from "src/helper/dataUrlToFile";
import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import t from "src/hooks/useTranslate";
import { useCreateMashinalAppNotificationMutation } from "src/services/financeService";
import { resetForm } from "src/store/MashinalApp/mashinalAppStore";
import { errorToast, successToast } from "src/store/Settings/toastStore";

type Props = {};

const MashinalAppDetails = (props: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const notificationForm = useAppSelector(
    (state) => state.mashinalApp.mashinalAppNotificationForm
  );
  const [
    createAppNotification,
    {
      isSuccess: notificationCreated,
      isLoading: notificationCreating,
      isError: notificationNotCreated,
      error: notificationCreateError,
    },
  ] = useCreateMashinalAppNotificationMutation();

  const onNotificationCreate = async (e, type) => {
    e.preventDefault();

    const formData = new FormData();
    for (const key in notificationForm) {
      formData.append(
        key,
        notificationForm[
          key as keyof typeof notificationForm
        ] as unknown as string
      );
    }

    if (type === 1) {
      formData.delete("send_at");
    }

    if (!notificationForm.image) {
      formData.delete("image");
    } else {
      formData.set("image", dataURLtoFile(notificationForm.image, "image"));
    }

    await createAppNotification(formData);
  };

  useEffect(() => {
    if (notificationCreated) {
      dispatch(successToast("Bildiriş uğurla əlavə edildi"));
      dispatch(resetForm());
      navigate("/mashin-al-app");
    }
  }, [notificationCreated]);

  useEffect(() => {
    if (notificationNotCreated) {
      //@ts-ignore
      Object.values(notificationCreateError?.data?.errors).forEach((x) =>
        //@ts-ignore
        dispatch(errorToast(x[0]))
      );
    }
  }, [notificationNotCreated]);

  return (
    <>
      <Header title={t("Mashin.al APP")} />
      <MashinAlAppNotificationForm
        onSubmit={onNotificationCreate}
        form={notificationForm}
        isLoading={notificationCreating}
        title={t("new_notification")}
      />
    </>
  );
};

export default MashinalAppDetails;
