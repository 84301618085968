import { useEffect, useState } from "react";
import { ReactComponent as UpChevronSVG } from "src/assets/vectors/up_chevron.svg";
import { EAscDesc } from "src/enums/e_asc_desc";
import t from "src/hooks/useTranslate";
import Pagination from "../Common/Pagination";
import Shimmer from "../Common/Shimmer";
import { useAutoHistoryLogsQuery } from "src/services/mashinAlDashboardService";
import { useSearchParams } from "react-router-dom";

const AutoLogsTable = () => {
  let [searchParams, setSearchParams] = useSearchParams();

  const [orderById, setOrderById] = useState(EAscDesc.asc);
  const [params, setParams] = useState(new URLSearchParams());
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(
    searchParams.get("per_page") ? Number(searchParams.get("per_page")) : 10
  );

  const {
    data: logsData,
    refetch: logsDataRefetch,
    isFetching: logsDataIsLoading,
  } = useAutoHistoryLogsQuery(document.location.search);

  useEffect(() => {
    // refetch()
    console.log(document.location.search);
    logsDataRefetch();
  }, [setPage, setPerPage]);

  useEffect(() => {
    logsDataRefetch();
  }, [logsData, logsDataRefetch]);

  return (
    <div className="w-full relative bg-white dark:bg-lightBlack rounded-xl drop-shadow-lg">
      <table className="min-w-[991px] w-full">
        <thead>
          <tr className="[&>th]:text-left [&>th]:p-2 [&>th]:dark:text-white [&>th]:whitespace-nowrap border-b border-solid border-gray">
            <th
              className="w-1/4 flex items-center gap-x-1.5 cursor-pointer !pl-4"
              onClick={() => {
                setOrderById(
                  orderById == EAscDesc.asc ? EAscDesc.desc : EAscDesc.asc
                );
              }}
            >
              ID{" "}
              <UpChevronSVG
                className={`w-3 text-blue ${
                  orderById == EAscDesc.asc ? "rotate-180" : ""
                }`}
              />
            </th>
            <th className="w-1/4">{t("name")}</th>
            <th className="w-1/4">{t("car_number_or_vin")}</th>
            <th className="w-1/4 !pr-4">{t("origin_history")}</th>
          </tr>
        </thead>

        <tbody>
          {logsData?.data.length > 0 ? (
            logsData?.data?.map((log, i) => (
              <tr
                className="relative odd:bg-light dark:odd:bg-softBlack [&>td]:py-4 [&>td]:px-2 [&>td]:dark:text-white after:absolute after:top-0 after:left-0 after:w-full after:h-[1px] after:bg-light after:opacity-50"
                key={log.id}
              >
                <td className="!pl-4">{log.id}</td>
                <td>{log.name}</td>
                <td>{log.car_number_or_vin.toUpperCase()}</td>
                <td className="!pr-4">
                  {" "}
                  {new Date(log.created_at).toLocaleDateString()}{" "}
                  {new Date(log.created_at).toLocaleTimeString()}
                </td>
              </tr>
            ))
          ) : (
            <>
              {[...Array(10)].map((_, i) => (
                <tr
                  className="[&>td]:py-4 [&>td]:px-2 bg-white dark:bg-lightBlack"
                  key={i}
                >
                  <td className="w-auto">
                    <Shimmer className="h-10.5" />
                  </td>
                  <td className="w-auto">
                    <Shimmer className="h-10.5" />
                  </td>
                  <td className="w-auto">
                    <Shimmer className="h-10.5" />
                  </td>
                  <td className="w-auto">
                    <Shimmer className="h-10.5" />
                  </td>
                </tr>
              ))}
            </>
          )}
        </tbody>
      </table>
      {logsData?.meta.total > perPage && (
        <div className="w-full px-4 py-4 border-t border-solid border-gray">
          <Pagination
            // page={Math.ceil(20 / perPage)}
            page={Math.ceil(logsData?.meta.total / perPage)}
            total={logsData?.meta.total}
            perPage={perPage}
            pageChange={(e) => {
              setPage(e + 1);
              // params.set("page", (e + 1).toString());
              setSearchParams((params) => {
                params.set("page", (e + 1).toString());
                return params;
              });
            }}
            setPerPage={(val) => {
              setPerPage(val);
              // params.set("per_page", val.toString());
              setSearchParams((params) => {
                params.set("per_page", val.toString());
                return params;
              });
            }}
          />
        </div>
      )}
    </div>
  );
};

export default AutoLogsTable;
