import React from "react";
import { useCallback, useState, useEffect } from "react";
import { ReactComponent as XSVG } from "src/assets/vectors/x.svg";
import { ReactComponent as TrashSVG } from "src/assets/vectors/trash.svg";
import { ReactComponent as CheckSVG } from "src/assets/vectors/check.svg";
import { ReactComponent as InfoSVG } from "src/assets/vectors/info.svg";
import t from "src/hooks/useTranslate";
import {
  useDeleteResponsibleMutation,
  useGetResponsiblePersonPartiallyMutation,
} from "src/services/usersService";
import Button from "../Common/Inputs/Button";
import Input from "../Common/Inputs/Input";
import { IUser } from "src/types/i_user";
import Shimmer from "../Common/Shimmer";
import { useDebounceEffect } from "src/hooks/useDebounceEffect";
import IconWithTooltip from "../Common/IconWithTooltip";
import { useLocation } from "react-router-dom";
import {
  useCreateResponsiblePersonMutation,
  useGetResponsiblePersonsMutation,
} from "src/services/usersService";

const GroupAddResponsiblePerson = ({
  setModal,
  onSubmit,
  isLoading,
  group,
}) => {
  const [selected, setSelected] = useState<IUser[]>([]);
  const [selectedResponsibleList, setSelectedResponsibleList] = useState<
    IUser[]
  >([]);
  const [query, setQuery] = useState<string>("");
  const [responsiblePersons, setResponsiblePersons] = useState([]);

  const [
    searchResponsibles,
    {
      data: responsibles,
      isLoading: isResponsibleLoading,
      isSuccess: isRespobsibleLoaded,
    },
  ] = useGetResponsiblePersonPartiallyMutation();
  const [
    getResponsiblePersonsList,
    {
      data: responsiblesList,
      isLoading: responsiblesListIsloading,
      isSuccess: responsiblesListIsSuccess,
    },
  ] = useGetResponsiblePersonsMutation();
  const [
    postResponsibleList,
    {
      data: selectedResponsibles,
      isLoading: isSelectedResponsiblesLoading,
      isSuccess: isSelectedResponsiblesSuccess,
    },
  ] = useCreateResponsiblePersonMutation();

  const [
    deleteResponsible,
    {
      data,
      isLoading: deleteResponsibleIsloading,
      isSuccess: deleteResponsibleIsSuccess,
    },
  ] = useDeleteResponsibleMutation();

  useEffect(() => {
    getResponsiblePersonsList(group.id);
  }, [group.id]);

  useEffect(() => {}, [responsiblesList]);

  const fetchResponsible = useCallback(async (search) => {
    const body = { groupId: group.id, query: `?fullname=${search}` };
    await searchResponsibles(body);
  }, []);

  const changeSelectedUser = (user: IUser) => {
    if (selected.some((slcd) => slcd.id === user.id)) {
      setSelected(selected.filter((slcd) => slcd.id !== user.id));
    } else {
      setSelected((prev) => [...prev, user]);
    }
  };
  const deleteRespHandler = (user: IUser) => {
    deleteResponsible({ groupId: group.id, respId: user.id });
  };

  useDebounceEffect(() => fetchResponsible(query), 500, [query]);

  useEffect(() => {
    if (deleteResponsibleIsSuccess) {
      getResponsiblePersonsList(group.id);
      fetchResponsible(query);
    }
  }, [deleteResponsibleIsSuccess]);

  useEffect(() => {
    if (isSelectedResponsiblesSuccess) {
      fetchResponsible(query);
      setSelected([]);
      getResponsiblePersonsList(group.id);
    }
  }, [isSelectedResponsiblesSuccess]);

  const postResponsiblePersonHandler = () => {
    const selectedRespIdList = selected.map((item) => item.id);
    // const selectedResponsibles = selected.map((item) => {
    //   const persons = [{ id: item.id, fullname: item.full_name }];
    //   return persons;
    // });
    const body = { data: { list: selectedRespIdList }, groupId: group.id };

    postResponsibleList(body);
  };

  return (
    <>
      <div className="flex items-center justify-between gap-x-4 p-4 ">
        <p className="text-16 text-gray font-bold dark:text-white">
          {t("responsible_person")}
        </p>
      </div>
      <div className="flex w-full gap-x-4 p-4">
        <div className="flex w-2/3 flex-col justify-between space-y-4">
          <div className="h-full flex flex-col gap-y-2">
            <Input
              placeholder="Axtar..."
              onChange={(e) => setQuery(e.target.value)}
            />
            {query.trim() != "" && (
              <ul className="flex-1 max-h-[303.2px] p-4 space-y-2 border border-solid border-gray rounded overflow-auto">
                {responsibles && !isResponsibleLoading ? (
                  responsibles?.length ? (
                    responsibles?.map((user: IUser) => (
                      <li
                        className={`relative cursor-pointer rounded px-2 py-0.5 pl-10 hover:bg-light dark:hover:bg-softBlack ${
                          selected.map((slcd) => slcd.id).includes(user.id)
                            ? "text-green"
                            : "dark:text-white"
                        }`}
                        onClick={() => changeSelectedUser(user)}
                        key={user.id}
                      >
                        {selected.map((slcd) => slcd.id).includes(user.id) && (
                          <CheckSVG className="absolute w-4 h-4 top-1/2 -translate-y-1/2 left-4" />
                        )}
                        {user.full_name}
                      </li>
                    ))
                  ) : (
                    <div className="w-full relative top-1/2 -translate-y-1/2 p-4 flex items-center justify-center">
                      <div className="w-fit flex items-center gap-x-3 p-4 rounded-xl bg-light dark:bg-softBlack text-gray dark:text-white">
                        <InfoSVG />
                        <p>Göstəriləcək hər hansı bir məlumat yoxdur</p>
                      </div>
                    </div>
                  )
                ) : (
                  [...Array(5)].map((_, i) => (
                    <Shimmer className="h-10.5" key={i} />
                  ))
                )}
              </ul>
            )}
          </div>
        </div>
        <div className="flex w-1/3 flex-col space-y-2">
          <Button
            className="min-h-[42px] w-full disabled:bg-gray bg-blue text-white"
            isLoading={isSelectedResponsiblesLoading}
            onClick={postResponsiblePersonHandler}
            disabled={selected.length == 0 && true}
          >
            {t("remember")}
          </Button>
          <div className="border border-solid border-gray rounded">
            <p className="h-10.5 flex justify-center items-center font-medium border-b border-solid border-gray dark:text-white">
              {t("selected_responsible_person")}
              <span className="ml-1">
                {responsiblesListIsSuccess &&
                  `(${responsiblesList?.data?.length})`}
              </span>
            </p>
            <ul className="p-4">
              {responsiblesListIsloading
                ? [...Array(5)].map((_, i) => (
                    <li className="h-10.5 flex justify-between items-center gap-x-4 dark:text-white mb-4">
                      <Shimmer className="h-10.5 w-full" key={i} />
                    </li>
                  ))
                : responsiblesList?.data?.map((user, i) => (
                    <li
                      className="h-10.5 flex justify-between items-center gap-x-4 dark:text-white"
                      key={user.id}
                    >
                      <p>{user?.fullname}</p>
                      <IconWithTooltip
                        icon={<TrashSVG className="text-red" />}
                        tooltip={t("delete")}
                        onClick={() => deleteRespHandler(user)}
                      />
                    </li>
                  ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default GroupAddResponsiblePerson;
