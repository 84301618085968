import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { getCookie } from "src/helper/custom_cookies"

export const dashboardService = createApi({
    reducerPath: "dashboardService",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_BASE_URL,
        prepareHeaders: (headers) => {
            headers.set("Authorization", getCookie("mcrmtkn") ? "Bearer " + getCookie("mcrmtkn") : "")
            headers.set('Accept', 'application/json')
            return headers
        },
        // credentials: "include",
    }),
    tagTypes: ["Dashboard"],
    endpoints: (builder) => ({
        publishedChart: builder.query({
            query: () => {
                return {
                    url: "/mcrm/published/chart",
                }
            },
            // providesTags: ["Dashboard"]
        }),
        topPublished: builder.query({
            query: (query) => {
                return {
                    url: `/mcrm/published/published_by_type${query}`,
                }
            },
            // providesTags: ["Dashboard"]
        }),
        publishedLinks: builder.query({
            query: (query) => {
                return {
                    url: `/mcrm/published/links${query}`,
                }
            },
            // providesTags: ["Dashboard"]
        }),
        announcementsByCityList: builder.query({
            query: (query) => {
                return {
                    url: `/mcrm/car_number_chart${query}`,
                }
            },
            // providesTags: ["Dashboard"]
        }),
        callDensity: builder.query({
            query: (query) => {
                return {
                    url: `/mcrm/published/operators${query}`,
                }
            },
            // providesTags: ["Dashboard"]
        }),
        rejectionAccordingTime: builder.query({
            query: (query) => {
                return {
                    url: `/mcrm/rejected/chart${query}`,
                }
            },
            // providesTags: ["Dashboard"]
        }),
        successfullAnnouncementsGraphics: builder.query({
            query: (query) => {
                return {
                    url: `/mcrm/published/range_percent${query}`,
                }
            },
            // providesTags: ["Dashboard"]
        }),
        callAverage: builder.query({
            query: (query) => {
                return {
                    url: `/mcrm/published/call_avg_by_cc${query}`,
                }
            },
            // providesTags: ["Dashboard"]
        }),
        alverchiMeter: builder.query({
            query: (query) => {
                return {
                    url: `/mcrm/published/alverchimetr${query}`,
                }
            },
            // providesTags: ["Dashboard"]
        }),
        usefulUsageOfWorkTime: builder.query({
            query: (query) => {
                return {
                    url: `/mcrm/published/work_day_time${query}`,
                }
            },
            // providesTags: ["Dashboard"]
        }),
        topRejecteds: builder.query({
            query: (query) => {
                return {
                    url: `/mcrm/rejected/top${query}`,
                }
            },
            // providesTags: ["Dashboard"]
        }),
        topOvertimePublished: builder.query({
            query: (query) => {
                return {
                    url: `/mcrm/published/non_work_time${query}`,
                }
            },
            // providesTags: ["Dashboard"]
        }),
        topPopularForSale: builder.query({
            query: (query) => {
                return {
                    url: `/mcrm/published/popular_autos${query}`,
                }
            },
            // providesTags: ["Dashboard"]
        }),
        // logoutUser: builder.mutation({
        //     query: () => {
        //         return {
        //             url: "/logout",
        //             method: "post",
        //         }
        //     },
        //     invalidatesTags: ["Dashboard"]
        // }),
    })
})

export const { usePublishedChartQuery, useTopPublishedQuery, usePublishedLinksQuery, useAnnouncementsByCityListQuery, useCallDensityQuery, useRejectionAccordingTimeQuery, useSuccessfullAnnouncementsGraphicsQuery, useCallAverageQuery, useAlverchiMeterQuery, useUsefulUsageOfWorkTimeQuery, useTopRejectedsQuery, useTopOvertimePublishedQuery, useTopPopularForSaleQuery } = dashboardService