import { ButtonHTMLAttributes, ReactNode } from "react";
import { ReactComponent as EyeSVG } from "src/assets/vectors/eye.svg";

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  icon: ReactNode;
  tooltip: string;
  tooltipBottom?: boolean;
}

const IconWithTooltip = ({
  icon,
  tooltip,
  tooltipBottom = false,
  ...rest
}: Props) => {
  return (
    <button
      className={`w-fit relative p-2 rounded hover:bg-light dark:hover:bg-softBlack
       duration-150 group hover:text-white ui-not-disabled:cursor-pointer`}
      {...rest}
    >
      {icon}
      {tooltip && (
        <span
          className={`absolute right-0 py-1 px-2 text-black bg-white dark:bg-softBlack dark:text-white rounded drop-shadow-xl opacity-0 pointer-events-none z-10 group-hover:pointer-events-auto group-hover:opacity-100 duration-200 ${
            tooltip.length > 100 ? "max-w-[320px]" : "whitespace-nowrap"
          } ${
            tooltipBottom
              ? "-bottom-1/2 group-hover:-bottom-full"
              : "-top-1/2 group-hover:-top-full"
          }`}
        >
          {tooltip}
        </span>
      )}
    </button>
  );
};

export default IconWithTooltip;