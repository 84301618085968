import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ddmmyyyy_hhmm } from "src/helper/getFormattedDate";

let dt = new Date();
dt.setHours(dt.getHours() + 2);

const initialState: { mashinalAppNotificationForm; mashinalAppRouteForm } = {
  mashinalAppNotificationForm: {
    send_to: "",
    image: "",
    title: "",
    description: "",
    route_id: 0,
    payload: "",
    save: 1,
    send_at: ddmmyyyy_hhmm(dt),
  },
  mashinalAppRouteForm: {
    name: "",
    route: "",
    payload: "",
  },
};

export const mashinalAppSlice = createSlice({
  name: "mashinalApp",
  initialState,
  reducers: {
    setForm: (state, action: PayloadAction<any>) => {
      for (const key in state.mashinalAppNotificationForm) {
        state.mashinalAppNotificationForm[key] = action.payload[key];
      }
    },
    setAllInputs: (state, action: PayloadAction<any>) => {
      state.mashinalAppNotificationForm[action.payload.key] =
        action.payload.value;
    },
    resetForm: (state) => {
      state.mashinalAppNotificationForm = {
        image: "",
        title: "",
        send_to: "",
        description: "",
        route_id: 0,
        payload: "",
        save: 1,
        send_at: ddmmyyyy_hhmm(dt),
      };
    },
    setRouteForm: (state, action: PayloadAction<any>) => {
      for (const key in state.mashinalAppRouteForm) {
        state.mashinalAppRouteForm[key] = action.payload[key];
      }
    },
    setRoutesInputs: (state, action: PayloadAction<any>) => {
      state.mashinalAppRouteForm[action.payload.key] = action.payload.value;
    },
    resetRoutesForm: (state) => {
      state.mashinalAppRouteForm = {
        name: "",
        route: "",
        payload: "",
      };
    },
  },
});

export const {
  setForm,
  setAllInputs,
  resetForm,
  setRouteForm,
  setRoutesInputs,
  resetRoutesForm,
} = mashinalAppSlice.actions;

export default mashinalAppSlice.reducer;
