import t from "src/hooks/useTranslate";
import Button from "src/components/Common/Inputs/Button";
import AlverchiMeter from "src/components/Dashboard/AlverchiMeter";
import AnnouncementsByCity from "src/components/Dashboard/AnnouncementsByCity";
import CallDencity from "src/components/Dashboard/CallDencity";
import Links from "src/components/Dashboard/Links";
import TopOvertimePublished from "src/components/Dashboard/TopOvertimePublished";
import TopPublished from "src/components/Dashboard/TopPublished";
import PublishedAnnouncementsChart from "src/components/Dashboard/PublishedAnnouncementsChart";
import TopRejected from "src/components/Dashboard/TopRejected";
import RejectionAccordingTime from "src/components/Dashboard/RejectionAccordingTime";
import SuccessfullAnnouncementsGraphics from "src/components/Dashboard/SuccessfullAnnouncementsGraphics";
import UsefulUsageOfWorkTime from "src/components/Dashboard/UsefulUsageOfWorkTime";
import Header from "src/components/Header/Header";
import {
  useAlverchiMeterQuery,
  useAnnouncementsByCityListQuery,
  useCallAverageQuery,
  useCallDensityQuery,
  usePublishedChartQuery,
  usePublishedLinksQuery,
  useRejectionAccordingTimeQuery,
  useSuccessfullAnnouncementsGraphicsQuery,
  useTopOvertimePublishedQuery,
  useTopPopularForSaleQuery,
  useTopPublishedQuery,
  useTopRejectedsQuery,
  useUsefulUsageOfWorkTimeQuery,
} from "src/services/dashboardService";
import { useEffect, useState } from "react";
import { ddmmyyyy } from "src/helper/getFormattedDate";
import TopPopularForSale from "src/components/Dashboard/TopPopularForSale";
import AverageByTime from "src/components/Dashboard/AverageByTime";

const Home = () => {
  /******PUBLISHED CHART*******/
  const {
    data: publishedChart,
    refetch: publishedChartRefetch,
    isLoading: isPublishedChartLoading,
  } = usePublishedChartQuery("");

  /******PUBLISHED LINKS*******/
  const [linkParams, setLinkParams] = useState("");

  const {
    data: publishedLinks,
    refetch: publishedLinksRefetch,
    isFetching: isPublishedLinksLoading,
  } = usePublishedLinksQuery(linkParams);

  useEffect(() => {
    publishedLinksRefetch();
  }, [linkParams]);

  /******TOP PUBLISHED*******/
  const [topPublishedParams, setTopPublishedParams] = useState("");

  const {
    data: topPublished,
    refetch: topPublishedRefetch,
    isFetching: isTopPublishedLoading,
  } = useTopPublishedQuery(topPublishedParams);

  useEffect(() => {
    topPublishedRefetch();
  }, [topPublishedParams]);

  /******ANNOUNCEMENTS BY CITY LIST*******/
  const [announcementsByCityListParams, setAnnouncementsByCityListParams] =
    useState("");

  const {
    data: announcementsByCityList,
    refetch: announcementsByCityListRefetch,
    isFetching: isAnnouncementsByCityListLoading,
  } = useAnnouncementsByCityListQuery(announcementsByCityListParams);

  useEffect(() => {
    announcementsByCityListRefetch();
  }, [announcementsByCityListParams]);

  /******CALL DENSITY*******/
  const [callDensityParams, setCallDensityParams] = useState("");

  const {
    data: callDensity,
    refetch: callDensityRefetch,
    isFetching: isCallDensityLoading,
  } = useCallDensityQuery(callDensityParams);

  useEffect(() => {
    callDensityRefetch();
  }, [callDensityParams]);

  /******REJECTION ACCORDING TIME*******/
  const [rejectionAccordingTimeParams, setRejectionAccordingTimeParams] =
    useState(`?date=${ddmmyyyy(new Date())}&filter=day`);

  const {
    data: rejectionAccordingTime,
    refetch: rejectionAccordingTimeRefetch,
    isFetching: isRejectionAccordingTimeLoading,
  } = useRejectionAccordingTimeQuery(rejectionAccordingTimeParams);

  useEffect(() => {
    rejectionAccordingTimeRefetch();
  }, [rejectionAccordingTimeParams]);

  /******SUCCESSFULL ANNOUNCEMENTS GRAPHICS*******/
  const [
    successfullAnnouncementsGraphicsParams,
    setSuccessfullAnnouncementsGraphicsParams,
  ] = useState("");

  const {
    data: successfullAnnouncementsGraphics,
    refetch: successfullAnnouncementsGraphicsRefetch,
    isFetching: isSuccessfullAnnouncementsGraphicsLoading,
  } = useSuccessfullAnnouncementsGraphicsQuery(
    successfullAnnouncementsGraphicsParams
  );

  useEffect(() => {
    successfullAnnouncementsGraphicsRefetch();
  }, [successfullAnnouncementsGraphicsParams]);

  /******USEFUL USAGE OF WORKTIME*******/
  const [usefulUsageOfWorkTimeParams, setUsefulUsageOfWorkTimeParams] =
    useState("");

  const {
    data: usefulUsageOfWorkTime,
    refetch: usefulUsageOfWorkTimeRefetch,
    isFetching: isUsefulUsageOfWorkTimeLoading,
  } = useUsefulUsageOfWorkTimeQuery(usefulUsageOfWorkTimeParams);

  useEffect(() => {
    usefulUsageOfWorkTimeRefetch();
  }, [usefulUsageOfWorkTimeParams]);

  /******TOP REJECTED*******/
  const [topRejectedsParams, setTopRejectedsParams] = useState("");

  const {
    data: topRejecteds,
    refetch: topRejectedsRefetch,
    isFetching: isTopRejectedsLoading,
  } = useTopRejectedsQuery(topRejectedsParams);

  useEffect(() => {
    topRejectedsRefetch();
  }, [topRejectedsParams]);

  /******TOP OVERTIME PUBLISHED*******/
  const [topOvertimePublishedParams, setTopOvertimePublishedParams] =
    useState("?filter=week");

  const {
    data: topOvertimePublished,
    refetch: topOvertimePublishedRefetch,
    isFetching: isTopOvertimePublishedLoading,
  } = useTopOvertimePublishedQuery(topOvertimePublishedParams);

  useEffect(() => {
    topOvertimePublishedRefetch();
  }, [topOvertimePublishedParams]);

  /******CALL AVERAGE*******/
  const [callAverageParams, setCallAverageParams] = useState("");

  const {
    data: callAverage,
    refetch: callAverageRefetch,
    isFetching: isCallAverageLoading,
  } = useCallAverageQuery(callAverageParams);

  useEffect(() => {
    callAverageRefetch();
  }, [callAverageParams]);

  /******ALVERCHI METER*******/
  const [alverchiMeterParams, setAlverchiMeterParams] = useState("");

  const {
    data: alverchiMeter,
    refetch: alverchiMeterRefetch,
    isFetching: isAlverchiMeterLoading,
  } = useAlverchiMeterQuery(alverchiMeterParams);

  useEffect(() => {
    alverchiMeterRefetch();
  }, [alverchiMeterParams]);

  /******TOP POPULAR FOR SALE*******/
  const [topPopularForSaleParams, setTopPopularForSaleParams] = useState("");

  const {
    data: topPopularForSale,
    refetch: topPopularForSaleRefetch,
    isFetching: isTopPopularForSaleLoading,
  } = useTopPopularForSaleQuery(topPopularForSaleParams);

  useEffect(() => {
    topPopularForSaleRefetch();
  }, [topPopularForSaleParams]);

  useEffect(() => {
    const refetchPage = setInterval(() => {
      publishedChartRefetch();
      publishedLinksRefetch();
      topPublishedRefetch();
      announcementsByCityListRefetch();
      callDensityRefetch();
      rejectionAccordingTimeRefetch();
      successfullAnnouncementsGraphicsRefetch();
      usefulUsageOfWorkTimeRefetch();
      topRejectedsRefetch();
      topOvertimePublishedRefetch();
      callAverageRefetch();
    }, 180000);
    return () => clearInterval(refetchPage);
  }, []);

  return (
    <>
      <Header title={t("home")} />
      <div className="flex flex-wrap gap-6 w-full">
        <PublishedAnnouncementsChart
          title={t("published_announcements")}
          data={publishedChart?.data}
          isLoading={isPublishedChartLoading}
        />
        <TopPublished
          title={t("published")}
          data={topPublished}
          isLoading={isTopPublishedLoading}
          setParams={setTopPublishedParams}
        />
        <Links
          data={publishedLinks}
          isLoading={isPublishedLinksLoading}
          setParams={setLinkParams}
        />
        <AnnouncementsByCity
          data={announcementsByCityList}
          isLoading={isAnnouncementsByCityListLoading}
          setParams={setAnnouncementsByCityListParams}
        />
        <CallDencity
          data={callDensity}
          isLoading={isCallDensityLoading}
          setParams={setCallDensityParams}
        />
        <RejectionAccordingTime
          data={rejectionAccordingTime}
          isLoading={isRejectionAccordingTimeLoading}
          setParams={setRejectionAccordingTimeParams}
        />
        <SuccessfullAnnouncementsGraphics
          data={successfullAnnouncementsGraphics}
          isLoading={isSuccessfullAnnouncementsGraphicsLoading}
          setParams={setSuccessfullAnnouncementsGraphicsParams}
        />
        <AverageByTime
          title={t("call_average")}
          data={callAverage}
          isLoading={isCallAverageLoading}
          setParams={setCallAverageParams}
        />
        <AlverchiMeter
          data={alverchiMeter}
          isLoading={isAlverchiMeterLoading}
          setParams={setAlverchiMeterParams}
        />
        <TopRejected
          data={topRejecteds}
          isLoading={isTopRejectedsLoading}
          setParams={setTopRejectedsParams}
        />
        <UsefulUsageOfWorkTime
          data={usefulUsageOfWorkTime}
          isLoading={isUsefulUsageOfWorkTimeLoading}
          setParams={setUsefulUsageOfWorkTimeParams}
        />
        <TopOvertimePublished
          data={topOvertimePublished}
          isLoading={isTopOvertimePublishedLoading}
          setParams={setTopOvertimePublishedParams}
        />
        <TopPopularForSale
          data={topPopularForSale}
          isLoading={isTopPopularForSaleLoading}
          setParams={setTopPopularForSaleParams}
        />
      </div>
    </>
  );
};

export default Home;
