import { ReactComponent as BackSVG } from "src/assets/vectors/back.svg";
import { Link } from "react-router-dom";
import Shimmer from "src/components/Common/Shimmer";
import t from "src/hooks/useTranslate";

type Props = {
  data: any;
  isLoading: boolean;
};

const FinanceDetailsStatistics = ({ data, isLoading }: Props) => {
  return (
    <div className="w-full relative bg-white dark:bg-lightBlack rounded-xl drop-shadow-lg space-y-6 px-4 py-6">
      <div className="space-y-8">
        <div className="flex items-center justify-between gap-x-4 ">
          <div className="space-y-1">
            <p className="text-16 font-bold dark:text-white">Auto Depo</p>
            {data ? (
              <p className="text-12 text-gray">{data.is_official}</p>
            ) : (
              <Shimmer className="h-3" />
            )}
          </div>
          <Link
            to="/mashin-al-autosalon"
            className="h-10.5 flex items-center gap-x-2 dark:text-white px-4 whitespace-nowrap rounded border border-solid border-gray"
          >
            <BackSVG />
            <p>{t("return_back")}</p>
          </Link>
        </div>
        <div className="grid grid-cols-4 gap-x-4">
          {data && !isLoading
            ? data.data.map((statistic) => (
                <div
                  className="p-4 bg-light dark:bg-softBlack dark:text-white rounded-lg space-y-2 [&>div>p:nth-child(1)]:text-gray [&>div>p:nth-child(2)]:text-16 [&>div>p:nth-child(2)]:font-medium"
                  key={statistic.id}
                >
                  <p className="text-16 font-semibold text-center">
                    {statistic.label}
                  </p>
                  {statistic.data.map((info) => (
                    <div className="space-y-1" key={info.id}>
                      <p>{info.label}:</p>
                      <p>{info.value}</p>
                    </div>
                  ))}
                </div>
              ))
            : [...Array(4)].map((_, i) => <Shimmer key={i} className="h-80" />)}
        </div>
      </div>
    </div>
  );
};

export default FinanceDetailsStatistics;
