const AnnounceInformation = ({ data }) => {
  const isDark =
    (window.matchMedia("(prefers-color-scheme: dark)").matches &&
      !localStorage.getItem("theme")) ||
    localStorage.getItem("theme") == "dark";

  return (
    <div className="w-full relative p-4 bg-white dark:bg-lightBlack rounded-xl drop-shadow-lg">
      <p
        className="text-16 font-bold mb-6"
        style={{ color: isDark ? data.dark_color : data.light_color }}
      >
        {data.label}
      </p>
      <div className="grid grid-cols-5 gap-x-6">
        {data.data.map((announce) => (
          <div className="flex-1 flex items-center gap-x-2" key={announce.id}>
            <p className="text-14 font-semibold dark:text-white">
              {announce.label}
            </p>
            <div
              className="w-24 flex justify-center items-center h-9 border border-solid border-green text-green rounded"
              style={{
                borderColor: isDark ? data.dark_color : data.light_color,
                color: isDark ? data.dark_color : data.light_color,
              }}
            >
              <p className="text-16 font-semibold">{announce.value}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AnnounceInformation;
