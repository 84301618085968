import { FC, SyntheticEvent, useEffect, useState } from "react";
import t from "src/hooks/useTranslate";
import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import {
  setAllInputs,
  setCalendarInput,
  setSelectInput,
} from "src/store/Profile/profileStore";
import Button from "../Common/Inputs/Button";
import InputCalendar from "../Common/Inputs/InputCalendar";
import dataReasons from "src/data/time_off_reasons.json";
import Select from "../Common/Select";

const dataHours = [
  { id: 1, name: "+1 saat" },
  { id: 2, name: "+2 saat" },
  { id: 3, name: "+3 saat" },
  { id: 4, name: "+4 saat" },
  { id: 5, name: "+5 saat" },
  { id: 24, name: "+1 gün" },
];

const ProfileGetPermission: FC<{
  onSubmit: (e: SyntheticEvent) => void;
  isLoading: boolean;
  isSuccess: boolean;
}> = ({ onSubmit, isLoading, isSuccess }) => {
  const dispatch = useAppDispatch();
  const [commentTextArea, setCommentTextArea] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const get_permission_fields = useAppSelector(
    (state) => state.profile.get_permission_fields
  );

  useEffect(() => {
    if (
      get_permission_fields.start_date !== "" &&
      get_permission_fields.hour !== 0 &&
      get_permission_fields.comment !== 0
    ) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [get_permission_fields]);

  useEffect(() => {
    if (isSuccess) {
      setCommentTextArea(false);
    }
  }, [isSuccess]);

  return (
    <form
      className="flex items-stretch gap-x-4"
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit(e);
      }}
    >
      <div className="w-1/2 space-y-4">
        <InputCalendar
          className="min-w-[260px] z-20"
          label={t("date_and_time") + "*"}
          timePicker={true}
          value={get_permission_fields.start_date}
          minDate={new Date()}
          maxDate={new Date(new Date().setDate(new Date().getDate() + 30))}
          onChange={(e: any) => dispatch(setCalendarInput(e))}
        />
        <Select
          data={dataHours}
          label={t("hour") + "*"}
          defaultTitle="Saatı seçin"
          defaultValue={get_permission_fields.hour}
          onChange={(e) =>
            dispatch(
              setSelectInput({
                key: "get_permission_fields",
                name: "hour",
                value: e.id,
              })
            )
          }
        />
        <Select
          data={dataReasons}
          label={t("reason") + "*"}
          defaultTitle="Səbəbi seçin"
          defaultValue={get_permission_fields.comment}
          onChange={(e) => {
            if (e.id === 5) {
              dispatch(
                setSelectInput({
                  key: "get_permission_fields",
                  name: "comment",
                  value: e.id,
                })
              );
              return setCommentTextArea(true);
            }
            setCommentTextArea(false);
            dispatch(
              setSelectInput({
                key: "get_permission_fields",
                name: "comment",
                value: e.id,
              })
            );
          }}
        />
        <Button
          className="w-full bg-blue text-white disabled:bg-gray"
          isLoading={isLoading}
          disabled={!isValid}
        >
          {t("submit")}
        </Button>
      </div>
      <div className="w-1/2">
        {commentTextArea && (
          <textarea
            className="w-full h-full p-4 border border-solid border-gray rounded resize-none dark:bg-softBlack dark:text-white"
            placeholder="Səbəbi yazın"
            onChange={(e) =>
              dispatch(
                setAllInputs({
                  key: "get_permission_fields",
                  name: "comment_text",
                  value: e.target.value,
                })
              )
            }
          />
        )}
      </div>
    </form>
  );
};

export default ProfileGetPermission;
