import { Fragment, useState } from "react";
import t from "src/hooks/useTranslate";
import Input from "../Common/Inputs/Input";
import { ReactComponent as TrashSVG } from "src/assets/vectors/trash.svg";
import { ReactComponent as XSVG } from "src/assets/vectors/x.svg";
import { ReactComponent as PlusSVG } from "src/assets/vectors/plus.svg";
import Select from "../Common/Select";
import {
  changeShortContent,
  deleteFormById,
  setAllFaqFormInputs,
  setShortContent,
} from "src/store/FAQ/FAQStore";
import { useAppDispatch } from "src/hooks/redux";

const FAQForm = ({ isNotLast, id, sectionData, faq, faqForm }: any) => {
  const dispatch = useAppDispatch();

  return (
    <div className="w-full flex gap-x-4 py-6 px-4">
      <div className="flex-1 space-y-4">
        <Input
          label={t("title")}
          defaultValue={!id && faqForm[0] ? faqForm[0].title : ""}
          onChange={(e) =>
            dispatch(
              setAllFaqFormInputs({
                id,
                name: "title",
                value: e.target.value.trim(),
              })
            )
          }
        />
        <div className="relative">
          <p className="dark:text-white mb-2">{t("comment")}</p>
          <textarea
            className="w-full p-4 border border-solid border-gray rounded resize-none dark:bg-softBlack dark:text-white"
            placeholder="Şərhi yazın"
            rows={3}
            defaultValue={!id && faqForm[0] ? faqForm[0].description : ""}
            onChange={(e) => {
              dispatch(
                setAllFaqFormInputs({
                  id,
                  name: "description",
                  value: e.target.value.trim(),
                })
              );
            }}
          />
        </div>
        <div className="grid grid-cols-3 gap-x-4">
          {faq.quick_replies.map((reply, i) => (
            <Fragment key={reply.id}>
              <div className="relative flex-grow bg-light p-3 rounded dark:bg-softBlack">
                {!(faq.quick_replies.length === 1 &&
                  faq.quick_replies[0].reply === "") && (
                    <XSVG
                      className="absolute top-0 right-0 translate-x-1/2 -translate-y-1/2 p-1 box-content rounded-full bg-red border-2 border- border-white text-white w-4 h-4 cursor-pointer"
                      onClick={() =>
                        dispatch(
                          changeShortContent({
                            id: faq.id,
                            contentID: reply.id,
                            type: faq.quick_replies.length > 1 ? 0 : 2,
                          })
                        )
                      }
                    />
                  )}

                <textarea
                  className="w-full resize-none bg-transparent dark:text-white"
                  placeholder="Mesajı yazın"
                  rows={3}
                  maxLength={160}
                  onKeyDown={(e) =>
                    !/[a-zA-Z 0-9!@#$%^&*()_+{}\[\]:"<>?,./]/i.test(e.key) &&
                    e.preventDefault()
                  }
                  value={
                    !id && faqForm[0]?.quick_replies[i]?.reply
                      ? faqForm[0].quick_replies[i]?.reply
                      : faq.quick_replies[i]?.reply
                  }
                  onChange={(e) =>
                    dispatch(
                      setShortContent({
                        id,
                        contentID:
                          !id && faqForm[0]
                            ? faqForm[0]?.quick_replies[i].id
                            : reply.id,
                        value: e.target.value.trim(),
                      })
                    )
                  }
                />
                <p className="text-right dark:text-white">
                  {faq.quick_replies[i]?.reply?.length}/160
                </p>
              </div>
              {!faq.quick_replies[i + 1] && faq.quick_replies.length !== 3 && (
                <div>
                  <PlusSVG
                    className="bg-light rounded text-gray box-content p-2 cursor-pointer"
                    onClick={() =>
                      dispatch(
                        changeShortContent({
                          id: faq.id,
                          contentID: Date.now(),
                          type: 1,
                        })
                      )
                    }
                  />
                </div>
              )}
            </Fragment>
          ))}
        </div>
        <div className="flex gap-x-4">
          <div className="w-[calc(33.3%-10px)]">
            <Select
              label="Bölmə seç"
              data={sectionData}
              defaultTitle={t("choose")}
              defaultValue={!id && faqForm[0] ? faqForm[0].category_id : ""}
              onChange={(e) =>
                dispatch(
                  setAllFaqFormInputs({
                    id,
                    name: "category_id",
                    value: e.id,
                  })
                )
              }
            />
          </div>
          {/* <div className="flex-grow">
            <Input
              label="Sıralama"
              className="w-16"
              defaultValue={
                !id && faqForm[0]?.order !== 0 ? faqForm[0].order : ""
              }
              onKeyDown={(e) =>
                !/[0-9]/i.test(e.key) &&
                e.key !== "Backspace" &&
                e.preventDefault()
              }
              onChange={(e) =>
                dispatch(
                  setAllFaqFormInputs({
                    id,
                    name: "order",
                    value: Number(e.target.value),
                  })
                )
              }
            />
          </div> */}
        </div>
      </div>
      {isNotLast && (
        <TrashSVG
          className="w-6 mt-10 text-red cursor-pointer"
          onClick={() => dispatch(deleteFormById(id))}
        />
      )}
    </div>
  );
};

export default FAQForm;
