import { useEffect, useState } from "react";
import t from "src/hooks/useTranslate";
import { Link, Navigate, useNavigate, useSearchParams } from "react-router-dom";
import Pagination from "src/components/Common/Pagination";
import Shimmer from "src/components/Common/Shimmer";
import { ReactComponent as UpChevronSVG } from "src/assets/vectors/up_chevron.svg";
import { ReactComponent as XSVG } from "src/assets/vectors/x.svg";
import { ReactComponent as EditSVG } from "src/assets/vectors/edit.svg";
import { ReactComponent as TrashSVG } from "src/assets/vectors/trash.svg";
import Header from "src/components/Header/Header";
import { EAscDesc } from "src/enums/e_asc_desc";
import {
  useDeleteGroupMutation,
  useGetGroupAndPermissionsQuery,
} from "src/services/groupAndPermissionsService";
import { ddmmyyyy } from "src/helper/getFormattedDate";
import Modal from "src/components/Common/Modal";
import Button from "src/components/Common/Inputs/Button";
import { useAppDispatch } from "src/hooks/redux";
import { reset } from "src/store/Groups/groupsStore";
import { permissionsStoreReset } from "src/store/Settings/permissionsStore";
import { EFormNDeleteModal } from "src/enums/e_form_n_delete_modal";
import DeleteCautionModal from "src/components/Common/DeleteCautionModal";
import usePermission from "src/hooks/usePermission";
import { EPermissions } from "src/enums/e_permissions";
import LoadingPage from "src/components/Common/LoadingPage";
import HasPermission from "src/components/Common/HasPermission";
import IconWithTooltip from "src/components/Common/IconWithTooltip";
import { successToast } from "src/store/Settings/toastStore";

const Groups = () => {
  const dispatch = useAppDispatch();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [params, setParams] = useState(new URLSearchParams());
  const {
    data: groupAndPermissions,
    refetch,
    isFetching,
  } = useGetGroupAndPermissionsQuery(
    params.toString() ? "?" + params.toString() : ""
  );
  const [orderById, setOrderById] = useState(EAscDesc.asc);
  //TODO create group interface
  const [forDeleteGroup, setForDeleteGroup] = useState<any>(null);
  const [modal, setModal] = useState(false);
  const [modalType, setModalType] = useState(EFormNDeleteModal.delete);
  const canCreate = usePermission([EPermissions.group_create]);
  const canEdit = usePermission([EPermissions.group_edit]);
  const canDelete = usePermission([EPermissions.group_delete]);

  const [
    deleteGroup,
    {
      data: GroupDeleteData,
      isLoading: isGroupDeleteLoading,
      isSuccess: isGroupDeleteSuccess,
      isError: isGroupDeleteError,
    },
  ] = useDeleteGroupMutation();

  useEffect(() => {
    dispatch(reset());
    dispatch(permissionsStoreReset());
    refetch();
  }, []);

  const deleteGroupHandle = () => {
    deleteGroup({ id: forDeleteGroup?.id });
  };

  useEffect(() => {
    if (isGroupDeleteSuccess) {
      setModal(false);
      dispatch(successToast(t("deleted_successfully")));
    }
  }, [isGroupDeleteSuccess]);

  const onDeleteHandler = (group) => {
    if (group.users_count === 0) {
      setForDeleteGroup(group);
      setModalType(EFormNDeleteModal.delete);
      setModal(true);
    }
  };

  useEffect(() => {
    refetch();
  }, [setPage, setPerPage, setOrderById]);

  return (
    <>
      <Header title={t("groups")} backLink={"/"}>
        {canCreate && (
          <Link
            to="/groups/create"
            className="w-full rounded px-6 bg-green text-white h-10 flex items-center justify-center whitespace-nowrap"
          >
            <span className="text-22 mr-2">+</span> {t("new_group")}
          </Link>
        )}
      </Header>
      <div className="relative py-6 bg-white dark:bg-lightBlack rounded-xl drop-shadow-lg">
        <div className="w-full overflow-auto">
          <table className="min-w-[991px] w-full">
            <thead>
              <tr className="[&>th]:text-left [&>th]:pb-6 [&>th]:px-2 [&>th]:dark:text-white [&>th]:whitespace-nowrap border-b border-solid border-gray">
                <th
                  className="w-20 flex items-center gap-x-1.5 !pl-4 cursor-pointer"
                  onClick={() => {
                    setOrderById(
                      orderById == EAscDesc.asc ? EAscDesc.desc : EAscDesc.asc
                    );
                    params.set(
                      "id",
                      orderById == EAscDesc.asc ? EAscDesc.desc : EAscDesc.asc
                    );
                  }}
                >
                  S/N
                  {/* <UpChevronSVG
                    className={`w-3 text-blue ${
                      orderById == EAscDesc.asc ? "rotate-180" : ""
                    }`}
                  /> */}
                </th>
                <th className="w-auto">Ad</th>
                <th className="w-40">Əməkdaşların sayı</th>
                <th className="w-28">Yaranma tarixi</th>
                <th className="w-24 !pr-4">Əməliyyatlar</th>
              </tr>
            </thead>
            <tbody>
              {groupAndPermissions && !isFetching ? (
                groupAndPermissions?.roles?.map((group: any, index: number) => (
                  <tr
                    className="relative [&>td]:py-4 [&>td]:px-2 [&>td]:dark:text-white after:absolute after:top-0 after:left-0 after:w-full after:h-[1px] after:bg-gray after:opacity-50"
                    key={group.id}
                  >
                    <td className="w-10 !pl-5">
                      {" "}
                      {page > 1 ? index + 1 + perPage * (page - 1) : index + 1}
                    </td>
                    <td>{group.name}</td>
                    <td>{group.users_count}</td>
                    <td>{ddmmyyyy(new Date(group.created_at))}</td>
                    <td className="!pr-4">
                      <div className="flex items-center gap-x-2">
                        {canEdit && (
                          <Link to={`/groups/edit/${group.id}`}>
                            <IconWithTooltip
                              icon={<EditSVG className="text-blue" />}
                              tooltip={t("edit")}
                            />
                          </Link>
                        )}
                        {canDelete && (
                          <IconWithTooltip
                            icon={
                              <TrashSVG
                                className={
                                  group.users_count > 0
                                    ? "text-gray"
                                    : "text-red"
                                }
                              />
                            }
                            tooltip={t("delete")}
                            onClick={() => onDeleteHandler(group)}
                            disabled={group.users_count > 0}
                          />
                          // <TrashSVG
                          //   className={
                          //     group.users_count > 0
                          //       ? "text-gray pointer-events-none"
                          //       : "text-red cursor-pointer"
                          //   }
                          //   onClick={() => onDeleteHandler(group)}
                          // />
                        )}
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <>
                  {[...Array(10)].map((_, i) => (
                    <tr
                      className="[&>td]:py-4 [&>td]:px-2 bg-white dark:bg-lightBlack"
                      key={i}
                    >
                      <td className="w-20">
                        <Shimmer className="h-4" />
                      </td>
                      <td className="w-auto">
                        <Shimmer className="h-4" />
                      </td>
                      <td className="w-40">
                        <Shimmer className="h-4" />
                      </td>
                      <td className="w-28">
                        <Shimmer className="h-4" />
                      </td>
                      <td className="w-24">
                        <Shimmer className="h-4" />
                      </td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </table>
        </div>
        {groupAndPermissions?.meta.total > 5 && (
          <div className="px-4 pt-4">
            <Pagination
              page={Math.ceil(groupAndPermissions?.meta.total / perPage)}
              total={groupAndPermissions?.meta.total}
              perPage={perPage}
              pageChange={(e) => {
                setPage(e + 1);
                params.set("page", (e + 1).toString());
              }}
              setPerPage={(val) => {
                setPerPage(val);
                params.set("per_page", val.toString());
              }}
            />
          </div> 
        )}
      </div>
      <Modal
        visible={modal}
        setVisible={setModal}
        dialogClassName="min-w-[450px]"
      >
        {modalType === EFormNDeleteModal.delete ? (
          <DeleteCautionModal
            setModal={setModal}
            onSubmit={deleteGroupHandle}
            isLoading={isGroupDeleteLoading}
          />
        ) : (
          <h1>mmm</h1>
        )}
      </Modal>
    </>
  );
};

export default Groups;
