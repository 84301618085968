import React, { FC, useState, useEffect, ChangeEvent, useRef } from "react";
import { IInput } from "src/types/Common/i_input";
import { ReactComponent as CalendarSVG } from "src/assets/vectors/calendar.svg";
import Popover from "../Popover";
import { Calendar } from "react-calendar";
import t from "src/hooks/useTranslate";
import InputMask from "react-input-mask";
import months from "src/data/months.json";
import weekdays from "src/data/weekdays_data.json";
import { ddmmyyyy } from "src/helper/getFormattedDate";

const InputCalendar: FC<any> = ({
  label,
  className,
  // defaultValue,
  maxDate,
  minDate,
  maxDetail = undefined,
  value,
  setValue,
  timePicker = false,
  onChange,
  monthView = false,
  yearView = false,
  controlled = false,
  children,
  ...inputProps
}) => {
  const [date, setDate] = useState<any>();
  const [time, setTime] = useState<any>("09:00");
  const [timeStep, setTimeStep] = useState(false);
  const ref = React.createRef<HTMLButtonElement>();
  const calendarRef = React.createRef<any>();
  const input = useRef<any>();

  // useEffect(() => {
  //   if (date) {
  //     if (timePicker) {
  //       onChange?.(ddmmyyyy(date) + " " + time);
  //       setTimeStep(true);
  //     } else {
  //       onChange?.(ddmmyyyy(date));
  //       ref.current!.click();
  //     }
  //   }
  // }, [date]);

  useEffect(() => {
    // if (defaultValue) {
    //   setValue(defaultValue);
    // }
    if (controlled && value !== ddmmyyyy(new Date())) {
      // console.log(value);
      const [day, month, year] = value.split(".");
      setDate(new Date(`${month}.${day}.${year}`));
    }
  }, [value]);

  const maskChars = {
    "#": "[0-9]",
  };

  const onTimeHandler = (val: any) => {
    const timeValue = val < 10 ? "0" + val + ":00" : val + ":00";
    setTime(timeValue);
    onChange?.(ddmmyyyy(date) + " " + timeValue);
    ref.current!.click();
    setTimeStep(false);
  };

  const tileContent = ({ date, view }) => {
    if (view === "year") {
      const monthName = months.map((month) => t(month.name))[date.getMonth()];
      return <span className="text-gray-500">{monthName}</span>;
    }
  };

  return (
    <div>
      {label && <p className="mb-2 dark:text-white">{label}</p>}
      <div
        className={[
          "relative flex px-4 border h-10.5 border-solid border-gray rounded bg-white dark:bg-softBlack",
          className,
        ].join(" ")}
      >
        {!monthView && !yearView && (
          <InputMask
            {...inputProps}
            mask={timePicker ? "##.##.#### ##:##" : "##.##.####"}
            className="w-full bg-white dark:bg-softBlack dark:text-white"
            //@ts-ignore
            maskChar="_"
             //@ts-ignore
            formatChars={maskChars}
            placeholder="__.__.____"
            ref={input}
            value={value}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              onChange?.(e.target.value)
            }
          />
        )}
        <Popover
          className="absolute top-[calc(100%+16px)] -right-4 bg-white px-4 py-5 rounded-xl drop-shadow after:absolute after:right-4 after:-top-1.5 after:w-3 after:h-3 after:bg-white after:rotate-45"
          popoverClassName={monthView || yearView ? "!w-full" : ""}
          ref={ref}
          button={
            monthView ? (
              <div className="flex">
                <p
                  className="flex-1 flex items-center font-medium text-16 rounded-l bg-transparent dark:text-white z-50"
                  onClick={(e) => {
                    e.preventDefault();
                    ref.current!.click();
                  }}
                >
                  {t(
                    months.find(
                      (month) => month.id == Number(value.split(".")[1])
                    ).name
                  ) +
                    " " +
                    value.split(".")[2]}
                </p>{" "}
                <CalendarSVG className="dark:text-white" />
              </div>
            ) : yearView ? (
              <div className="flex">
                <p
                  className="flex-1 flex items-center font-medium text-16 rounded-l bg-transparent dark:text-white z-50"
                  onClick={(e) => {
                    e.preventDefault();
                    ref.current!.click();
                  }}
                >
                  {value.split(".")[2]}
                </p>{" "}
                <CalendarSVG className="dark:text-white" />
              </div>
            ) : (
              <CalendarSVG className="dark:text-white" />
            )
          }
          onClick={() => setTimeStep(false)}
        >
          {timePicker ? (
            timeStep ? (
              <div className="w-[300px]">
                <div className="w-full grid grid-cols-4 grid-rows-6 gap-2">
                  {[...Array(24)].map((_, i) => (
                    <p
                      className="text-center cursor-pointer rounded hover:bg-blue hover:text-white"
                      onClick={() => onTimeHandler(i)}
                      key={i}
                    >
                      {i}:00
                    </p>
                  ))}
                </div>
              </div>
            ) : (
              <Calendar
                onChange={(e) => {
                  onChange?.(ddmmyyyy(e) + " " + time);
                  setTimeStep(true);
                  setDate(e);
                }}
                maxDate={maxDate}
                minDate={minDate}
                value={date}
                navigationLabel={({ date, label, view }) => {
                  if (view === "month") {
                    const monthName = months.map((month) => t(month.name))[
                      date.getMonth()
                    ];
                    return monthName;
                  } else {
                    return label;
                  }
                }}
                formatShortWeekday={(locale, date) => {
                  const weekday =
                    date.getDay() == 0
                      ? t(weekdays[6].name)
                      : t(weekdays[date.getDay() - 1].name);
                  return weekday.slice(0, 3);
                }}
                locale="az"
                tileContent={tileContent}
              />
            )
          ) : (
            <Calendar
              onChange={(e) => {
                onChange?.(ddmmyyyy(e));
                ref.current!.click();
                setDate(e);
              }}
              maxDetail={maxDetail}
              maxDate={maxDate}
              minDate={minDate}
              value={date}
              navigationLabel={({ date, label, view }) => {
                if (view === "month") {
                  const monthName = months.map((month) => t(month.name))[
                    date.getMonth()
                  ];
                  return monthName;
                } else {
                  return label;
                }
              }}
              formatShortWeekday={(locale, date) => {
                const weekday =
                  date.getDay() == 0
                    ? t(weekdays[6].name)
                    : t(weekdays[date.getDay() - 1].name);
                return weekday.slice(0, 3);
              }}
              locale="az"
              tileContent={tileContent}
            />
          )}
        </Popover>
      </div>
    </div>
  );
};

export default InputCalendar;
