import { useState, useEffect } from "react";
import t from "src/hooks/useTranslate";
import Button from "src/components/Common/Inputs/Button";
import Header from "src/components/Header/Header";

import Modal from "src/components/Common/Modal";

import TargetsTable from "src/components/Targets/TargetsTable";
import TargetStatistics from "src/components/Targets/TargetStatistics";
import {
  useCreateTargetMutation,
  useDeleteTargetMutation,
  useGetTargetsQuery,
  useReportTargetQuery,
  useUpdateTargetMutation,
} from "src/services/targetService";
import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import {
  resetForm,
  setAllInputs,
  setTarget,
} from "src/store/Targets/TargetStore";
import { successToast } from "src/store/Settings/toastStore";
import { EFormNDeleteModal } from "src/enums/e_form_n_delete_modal";
import DeleteCautionModal from "src/components/Common/DeleteCautionModal";
import TargetFormModal from "src/components/Targets/TargetFormModal";
import usePermission from "src/hooks/usePermission";
import { EPermissions } from "src/enums/e_permissions";

const Targets = () => {
  const dispatch = useAppDispatch();
  const [params, setParams] = useState(new URLSearchParams());
  const [modal, setModal] = useState(false);
  const [modalType, setModalType] = useState(EFormNDeleteModal.create_edit);
  const [activeID, setActiveID] = useState(0);
  const canCreate = usePermission([EPermissions.target_create]);

  const { data: targets, isFetching: isTargetsLoading } =
    useGetTargetsQuery("");
  const {
    data: reports,
    refetch,
    isFetching: isReportsLoading,
  } = useReportTargetQuery(params.toString() ? "?" + params.toString() : "");
  const target_form = useAppSelector((state) => state.target.target_form);
  const [
    createTarget,
    { isLoading: isTargetCreating, isSuccess: isTragetCreated },
  ] = useCreateTargetMutation();

  const [
    updateTarget,
    { isLoading: isTargetUpdating, isSuccess: isTargetUpdated },
  ] = useUpdateTargetMutation();

  const [
    deleteTarget,
    { isLoading: isTargetDeleting, isSuccess: isTargetDeleted },
  ] = useDeleteTargetMutation();

  const onSubmit = async () => {
    if (activeID != 0) {
      await updateTarget({ id: activeID, body: target_form });
    } else {
      await createTarget(target_form);
    }
  };

  const onDeleteTarget = async () => {
    await deleteTarget(activeID);
  };

  useEffect(() => {
    refetch();
    dispatch(resetForm());
  }, []);

  useEffect(() => {
    if (isTragetCreated) {
      dispatch(successToast("Hədəf yaradıldı"));
      setModal(false);
    }
  }, [isTragetCreated]);

  useEffect(() => {
    if (isTargetUpdated) {
      dispatch(successToast("Hədəf dəyişdirildi"));
      setModal(false);
      setActiveID(0);
    }
  }, [isTargetUpdated]);

  useEffect(() => {
    if (isTargetDeleted) {
      dispatch(successToast("Hədəf silindi"));
      setModal(false);
      setActiveID(0);
    }
  }, [isTargetDeleted]);

  return (
    <>
      <Header title={t("aims")}>
        {canCreate && (
          <Button
            className="bg-green px-4 flex items-center gap-x-2 rounded text-white ml-auto whitespace-nowrap"
            onClick={() => {
              setActiveID(0);
              setModalType(EFormNDeleteModal.create_edit);
              setModal(true);
            }}
          >
            <p className="text-16 font-medium">+</p>
            <p>{t("define_aims")}</p>
          </Button>
        )}
      </Header>
      <div className="flex items-start gap-x-4">
        <div className="flex-[0.7] pt-6 bg-white dark:bg-lightBlack rounded-xl drop-shadow-lg">
          <TargetsTable
            data={targets}
            onEditClick={(tgt) => {
              setActiveID(tgt.id);
              dispatch(setTarget(tgt));
              setModalType(EFormNDeleteModal.create_edit);
              setModal(true);
            }}
            onDeleteClick={(id) => {
              setActiveID(id);
              setModalType(EFormNDeleteModal.delete);
              setModal(true);
            }}
            isLoading={isTargetsLoading}
          />
        </div>
        <div className="min-h-[129.5px] flex-[0.3] py-6 px-4 bg-white dark:bg-lightBlack rounded-xl drop-shadow-lg">
          <TargetStatistics
            data={reports}
            isLoading={isReportsLoading}
            // switchType={(e) => {
            //   e
            //     ? params.set("filter", "monthly")
            //     : params.set("filter", "daily");
            //   refetch();
            // }}
            onChange={(date) => {
              params.set("date", date);
              refetch();
            }}
          />
        </div>
      </div>
      <Modal visible={modal} setVisible={setModal} dialogClassName="w-96">
        {modalType === EFormNDeleteModal.create_edit ? (
          <TargetFormModal
            form={target_form}
            id={activeID}
            onSubmit={onSubmit}
            isLoading={isTargetCreating || isTargetUpdating}
            setModal={setModal}
          />
        ) : (
          <DeleteCautionModal
            setModal={setModal}
            onSubmit={onDeleteTarget}
            isLoading={isTargetDeleting}
          />
        )}
      </Modal>
    </>
  );
};

export default Targets;
