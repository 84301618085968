import { useEffect, useState } from "react";
import Button from "src/components/Common/Inputs/Button";
import InputRangeDatePicker from "src/components/Common/Inputs/InputRangeDatePicker";
import InputSearch from "src/components/Common/Inputs/InputSearch";
import Shimmer from "src/components/Common/Shimmer";
import t from "src/hooks/useTranslate";
import { ReactComponent as ExcelSVG } from "src/assets/vectors/file_excel.svg";
import { ReactComponent as InfoSVG } from "src/assets/vectors/info.svg";
import Pagination from "src/components/Common/Pagination";
import { useFinanceIndividualExcelMutation } from "src/services/financeService";

const IndividualTable = ({ data, isLoading, setParams }) => {
  const [paramsData, setParamsData] = useState(new URLSearchParams());
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [date, setDate] = useState("");
  const [query, setQuery] = useState("");

  useEffect(() => {
    data && setParams("?" + paramsData.toString());
  }, [page, perPage, date, query]);

  const [excelExport, { isLoading: excelExportLoading }] =
    useFinanceIndividualExcelMutation();

  const onExcelExport = async () => {
    await excelExport(
      paramsData.toString()
        ? "?want_export=true&" + paramsData.toString()
        : "?want_export=true"
    );
  };

  return (
    <div className="space-y-6">
      <div className="w-full relative bg-white dark:bg-lightBlack rounded-xl drop-shadow-lg min-h-[476px]">
        <div className="flex items-end justify-between p-4">
          <div className="space-y-2 w-[300px] dark:text-white">
            {t("date")}
            <InputRangeDatePicker
              className="z-20"
              onChange={(e: string) => {
                paramsData.delete("page");
                paramsData.set("date", e);
                setDate(e);
              }}
            />
          </div>
          <InputSearch
            data={[]}
            className="w-[400px]"
            optionHidden
            placeholder={t("search") + "..."}
            onInput={(query: string) => {
              paramsData.delete("page");
              paramsData.set("search", query);
              setQuery(query);
            }}
          />
        </div>
        <table className="min-w-[991px] w-full">
          <thead>
            <tr className="[&>th]:text-left [&>th]:px-2 [&>th]:py-4 [&>th]:dark:text-white [&>th]:whitespace-nowrap border-y border-solid border-gray">
              <th className="w-auto !pl-4">{t("date")}</th>
              <th className="w-auto">{t("owner")}</th>
              <th className="w-auto">{t("phone")}</th>
              <th className="w-auto">{t("amount")}{ data && !isLoading && (<span>: {data.total}</span>)}</th>
              <th className="w-auto !pr-4">{t("payment_type")}</th>
            </tr>
          </thead>
          <tbody>
            {data && !isLoading ? (
              data.data?.length > 0 &&
              data.data.map((individual) => (
                <tr
                  className="relative [&>td]:py-4 [&>td]:px-2 [&>td]:dark:text-white after:absolute after:top-0 after:left-0 after:w-full after:h-[1px] after:bg-light after:opacity-50"
                  key={individual.id}
                >
                  <td className="!pl-4">{individual.date_time}</td>
                  <td>{individual.user}</td>
                  <td>{individual.phone}</td>
                  <td>{individual.price} AZN</td>
                  <td className="!pr-4">{individual.provider}</td>
                </tr>
              ))
            ) : (
              <>
                {[...Array(perPage)].map((_, i) => (
                  <tr
                    className="[&>td]:py-4 [&>td]:px-2 bg-white dark:bg-lightBlack"
                    key={i}
                  >
                    {[...Array(5)].map((__, idx) => (
                      <td className="w-auto" key={idx}>
                        <Shimmer className="h-10.5" />
                      </td>
                    ))}
                  </tr>
                ))}
              </>
            )}
          </tbody>
        </table>
        {data && !data.data.length && (
          <div className="w-full h-[500px] p-4 flex items-center justify-center">
            <div className="w-fit flex items-center gap-x-3 p-4 rounded-xl bg-light dark:bg-softBlack text-gray dark:text-white">
              <InfoSVG />
              <p>Göstəriləcək hər hansı bir məlumat yoxdur</p>
            </div>
          </div>
        )}
        {data?.meta?.total > 5 && (
          <div className="w-full px-4 py-4 border-t border-solid border-gray">
            <Pagination
              page={Math.ceil(data?.meta?.total / perPage)}
              total={data?.meta?.total}
              perPage={perPage}
              pageChange={(e) => {
                setPage(e + 1);
                paramsData.set("page", (e + 1).toString());
              }}
              setPerPage={(val) => {
                setPerPage(val);
                paramsData.set("per_page", val.toString());
              }}
            />
          </div>
        )}
      </div>
      <Button
        className="ml-auto gap-x-2 bg-darkBlue dark:bg-blue text-white whitespace-nowrap"
        onClick={onExcelExport}
        isLoading={excelExportLoading}
      >
        <ExcelSVG /> <p>{t("export_to_excel")}</p>
      </Button>
    </div>
  );
};

export default IndividualTable;
