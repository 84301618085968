import SidebarSections from "./SidebarSections";
import SidebarUser from "./SidebarUser";

const Sidebar = () => {
  return (
    <div className="min-w-[250px] rounded-xl overflow-hidden">
      <SidebarUser />
      <SidebarSections />
    </div>
  );
};

export default Sidebar;
