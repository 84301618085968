import t from "src/hooks/useTranslate";
import { ReactComponent as DownloadSVG } from "src/assets/vectors/download.svg";
import { ReactComponent as InfoSVG } from "src/assets/vectors/info.svg";
import Button from "../Common/Inputs/Button";
import InputCalendar from "../Common/Inputs/InputCalendar";
import Select from "../Common/Select";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import { setModAllInputs } from "src/store/Profile/profileStore";
import { RadioGroup } from "@headlessui/react";

const dataDays = [
  { id: 1, value: 1, name: "+1 gün" },
  { id: 2, value: 2, name: "+2 gün" },
  { id: 3, value: 3, name: "+3 gün" },
  { id: 4, value: 4, name: "+4 gün" },
  { id: 5, value: 5, name: "+5 gün" },
  { id: 6, value: 6, name: "+6 gün" },
  { id: 7, value: 7, name: "+7 gün" },
  { id: 8, value: 8, name: "+8 gün" },
  { id: 9, value: 9, name: "+9 gün" },
  { id: 10, value: 10, name: "+10 gün" },
  { id: 11, value: 11, name: "+11 gün" },
  { id: 12, value: 12, name: "+12 gün" },
  { id: 13, value: 13, name: "+13 gün" },
  { id: 14, value: 14, name: "+14 gün" },
];

enum ECompany {
  AlVentures = "Alventures",
  BestComp = "Bestcomp Group",
}

const ProfileVacation = ({ vacationDays = null, isLoading, onSubmit }) => {
  const dispatch = useAppDispatch();
  const [company, setCompany] = useState();
  const [isValid, setIsValid] = useState(false);
  const get_vacation_fields = useAppSelector(
    (state) => state.profile.get_vacation_fields
  );

  const radioData = [
    { id: 1, value: ECompany.AlVentures },
    { id: 2, value: ECompany.BestComp },
  ];

  useEffect(() => {
    if (
      get_vacation_fields.start_date !== "" &&
      get_vacation_fields.days !== 0
    ) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [get_vacation_fields]);

  if (vacationDays <= 0 && typeof vacationDays == "number") {
    return (
      <div className="w-full flex flex-col items-center">
        <p className="text-16 font-bold dark:text-white">{t("my_vacation_days")}:</p>
        <div className="w-52 h-52">
          <img
            className="w-full h-full object-contain"
            src="assets/images/vacation_empty.png"
            alt={vacationDays.toString()}
          />
        </div>
        <div className="flex items-center gap-x-2 text-gray font-medium mt-6">
          <InfoSVG />
          <p>{t("empty_vacation_days_info")}</p>
        </div>
      </div>
    );
  }

  return (
    <form className="w-1/2 space-y-2" onSubmit={onSubmit}>
      <div className="space-y-4">
        <div className="flex dark:text-white">
          <p className="text-gray dark:text-white">
            {t("my_vacation_days")}:{" "}
            <span className="text-blue">{vacationDays}</span>
          </p>
        </div>
        <div className="relative dark:text-white">
          <p className="text-gray dark:text-white">{t("application_form")}:</p>
          <div className="relative bottom-1.5 flex items-center gap-x-4">
            <RadioGroup
              value={company}
              onChange={setCompany}
              className="flex items-center flex-wrap gap-4"
            >
              {radioData.map((option) => (
                <RadioGroup.Option value={option.value} key={option.id}>
                  {({ checked }) => (
                    <div className="flex items-center gap-x-2 cursor-pointer whitespace-nowrap">
                      <div
                        className={`relative w-4 h-4 border-2 border-solid rounded-full bg-transparent after:absolute after:left-1/2 after:top-1/2 after:-translate-y-1/2 after:-translate-x-1/2 after:w-2 after:h-2 after:rounded-full ${
                          checked
                            ? "border-green after:bg-green"
                            : "border-darkBlue dark:border-white after:bg-transparent"
                        }`}
                      ></div>
                      <span
                        className={
                          checked
                            ? "text-green"
                            : "text-darkBlue dark:text-white"
                        }
                      >
                        {t(option.value)}
                      </span>
                    </div>
                  )}
                </RadioGroup.Option>
              ))}
            </RadioGroup>
            <a
              href={
                company === ECompany.AlVentures
                  ? "https://api.crm.al.ventures/storage/documents/mezuniyyet%20alventures.doc"
                  : "https://api.crm.al.ventures/storage/documents/Erize_Mezuniyyet_Bestcomp.docx"
              }
              rel="noopener noreferrer"
              className={`p-2 border border-gray rounded ${
                company ? "" : "bg-light dark:bg-softBlack pointer-events-none"
              }`}
            >
              <DownloadSVG className="text-blue" />
            </a>
          </div>
        </div>
      </div>
      <InputCalendar
        className="min-w-[260px] z-20"
        label={t("date")}
        value={get_vacation_fields.start_date}
        minDate={new Date()}
        maxDate={new Date(new Date().setFullYear(new Date().getFullYear() + 1))}
        onChange={(e) =>
          dispatch(
            setModAllInputs({
              key: "get_vacation_fields",
              name: "start_date",
              value: e,
            })
          )
        }
      />
      <Select
        data={dataDays}
        label={t("day") + "*"}
        defaultValue={get_vacation_fields.days}
        defaultTitle="Günü seçin"
        onChange={(e) =>
          dispatch(
            setModAllInputs({
              key: "get_vacation_fields",
              name: "days",
              value: e.value!,
            })
          )
        }
      />
      <Button
        className="w-full bg-blue text-white !mt-8 disabled:bg-gray"
        isLoading={isLoading}
        disabled={!isValid}
      >
        {t("submit")}
      </Button>
    </form>
  );
};

export default ProfileVacation;
