import React from 'react'

const LoadingPage = () => {
  return (
    <div className="w-full h-screen bg-red">
        <img
          src="/assets/images/Cover.webp"
          className="w-full h-full object-cover"
          alt="MCRM"
        />
      </div>
  )
}

export default LoadingPage