import { Switch } from "@headlessui/react";
import { useState } from "react";
import t from "src/hooks/useTranslate";
import Button from "src/components/Common/Inputs/Button";
import Shimmer from "../Common/Shimmer";

const Links = ({ data, isLoading, setParams }) => {
  const [enabled, setEnabled] = useState(false);

  return (
    <div className="relative h-[320px] px-4 py-6 w-[calc(30%-12px)] bg-white dark:bg-lightBlack rounded-xl text-center drop-shadow-lg">
      <div className="flex justify-between items-center gap-x-4">
        <h3 className="text-16 font-bold dark:text-white">
          {t("links")} ( <span className="lowercase">{enabled ? t("within_year") : t("within_month")}</span>{" "}
          )
        </h3>
        <Switch
          checked={enabled}
          onChange={(e) => {
            setEnabled(e);
            setParams(e ? "?filter=year" : "");
          }}
          className={`${enabled ? "bg-blue" : "bg-darkSnow/40"} ${
            isLoading ? "pointer-events-none" : "cursor-pointer"
          } 
          relative ml-3 inline-flex h-4 w-8 shrink-0 rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 after:absolute after:-inset-x-2.5 after:-inset-y-1`}
        >
          <span
            aria-hidden="true"
            className={`${
              enabled ? "left-full -translate-x-1/2" : "left-0 -translate-x-1/2"
            }
            absolute top-1/2 -translate-y-1/2 pointer-events-none inline-block h-5 w-5 rounded-full bg-white drop-shadow-md shadow-black ring-0 duration-200 ease-in-out`}
          />
        </Switch>
      </div>
      <p className="text-gray mt-2 mb-6 text-left">{t("published")}</p>
      {data && !isLoading ? (
        data?.map((link, i) => (
          <div
            key={i}
            className="flex justify-between items-center mb-4 last-of-type:mb-8"
          >
            <p className="dark:text-white capitalize">{link.name}</p>
            <p className="text-22 font-bold dark:text-white">{link.value}</p>
          </div>
        ))
      ) : (
        <div className="space-y-4">
          {[...Array(3)].map((_, i) => (
            <Shimmer className="h-10.5" key={i} />
          ))}
        </div>
      )}
    </div>
  );
};

export default Links;
