import { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import InputSearch from "src/components/Common/Inputs/InputSearch";
import Header from "src/components/Header/Header";
import { ReactComponent as EditSVG } from "src/assets/vectors/edit.svg";
import t from "src/hooks/useTranslate";
import FAQHeadersSection from "src/components/FAQ/FAQHeadersSection";
import Modal from "src/components/Common/Modal";
import FAQSectionsModal from "src/components/FAQ/FAQSectionsModal";
import {
  useChangeFaqSectionMutation,
  useDeleteFaqSectionMutation,
  useGetFaqByIdQuery,
  useGetFaqByQueryQuery,
  useGetFaqSectionsQuery,
  useReOrderFaqMutation,
  useSendFaqMutation,
} from "src/services/faqService";
import { useAppDispatch } from "src/hooks/redux";
import Shimmer from "src/components/Common/Shimmer";
import { successToast } from "src/store/Settings/toastStore";
import { resetFaqForm, resetFaqForSend } from "src/store/FAQ/FAQStore";
import { EPermissions } from "src/enums/e_permissions";
import usePermission from "src/hooks/usePermission";
import { ReactSortable } from "react-sortablejs";
import IconWithTooltip from "src/components/Common/IconWithTooltip";

const enum EFAQPageType {
  main,
  create,
  edit,
}

const FAQ = () => {
  const dispatch = useAppDispatch();
  const canCreate = usePermission([EPermissions.faq_create]);
  const [modal, setModal] = useState(false);
  const [query, setQuery] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [params, setParams] = useState(new URLSearchParams());
  const [sectionsData, setSectionsData] = useState([]);
  const [
    changeSection,
    { isLoading: isSectionChanging, isSuccess: isSectionCreated },
  ] = useChangeFaqSectionMutation();
  const [
    deleteSection,
    { isLoading: isSectionDeleting, isSuccess: isSectionDeleted },
  ] = useDeleteFaqSectionMutation();
  const [postFaq, { isLoading: isFaqSending, isSuccess: isFaqSent }] =
    useSendFaqMutation();
  const [reOrderFaq] = useReOrderFaqMutation();
  const {
    data: sections,
    isLoading: isSectionsLoading,
    isSuccess: isSectionFetched,
  } = useGetFaqSectionsQuery("");
  const [selectedFaq, setSelectedFaq] = useState(
    searchParams.get("section") || 0
  );
  const [faqs, setFaqs] = useState([]);
  const [sortableDisabled, setSortableDisabled] = useState(false);
  const [validToOrder, setValidToOrder] = useState(false);
  const {
    data: titles,
    isFetching: isFaqSearching,
    refetch,
    isSuccess: isFaqSearched,
  } = useGetFaqByQueryQuery(
    {
      id: searchParams.get("section")
        ? searchParams.get("section")
        : selectedFaq,
      query: params.toString() ? "?" + params.toString() : "",
    },
    { skip: selectedFaq == 0 }
  );

  const onSectionCreate = async (val) => {
    await changeSection({ title: val });
  };

  const onSectionUpdate = async (val) => {
    await changeSection({ id: val.id, title: val.title });
  };

  useEffect(() => {
    if (isSectionFetched && !searchParams.get("section")) {
      setSelectedFaq(sections[0]?.id);
    }
  }, [isSectionFetched]);

  useEffect(() => {
    if (isFaqSent) {
      dispatch(resetFaqForSend());
      dispatch(successToast("Faq sent"));
    }
  }, [isFaqSent]);

  const onSendFaq = async (body) => {
    await postFaq(body);
  };

  const onDeleteSection = async (id: number) => {
    await deleteSection(id);
  };

  useEffect(() => {
    if (isSectionDeleted) {
      setModal(false);
      dispatch(successToast("Faq deleted"));
    }
  }, [isSectionDeleted]);

  useEffect(() => {
    if (titles) {
      setSectionsData(titles?.data.faqCategories);
    }
  }, [titles]);

  useEffect(() => {
    dispatch(resetFaqForm());
  }, []);

  useEffect(() => {
    if (faqs.length && validToOrder) {
      reOrderFaq({ faqs: faqs.map((x) => x.id) });
    }
  }, [validToOrder]);

  useEffect(() => {
    if (selectedFaq != 0) {
      refetch();
    }
  }, [query, selectedFaq]);

  useEffect(() => {
    titles && setFaqs(titles.data.faqs);
  }, [titles]);

  return (
    <>
      <Header title={t("faq")}>
        {canCreate && (
          <Link
            to="create"
            className="h-10.5 bg-green px-4 flex items-center gap-x-2 rounded text-white ml-auto whitespace-nowrap"
          >
            <span className="text-16 font-medium">+</span>
            <p>{t("new_template")}</p>
          </Link>
        )}
      </Header>
      <div className="w-full relative bg-white dark:bg-lightBlack rounded-xl drop-shadow-lg">
        <div className="w-full p-4 border-b border-solid border-gray">
          <InputSearch
            data={[]}
            placeholder={t("search")}
            showItemCount={5}
            onInput={(query: string) => {
              setQuery(query);
              params.set("search", query);
            }}
            optionHidden
          >
            <p className="p-2 rounded hover:bg-light cursor-pointer">Test1</p>
          </InputSearch>
        </div>
        <div className="flex">
          <div className="min-w-[256px] max-w-[256px] p-4 border-r border-solid border-gray">
            <div className="flex items-center justify-between gap-x-4 pb-4 border-b border-solid border-gray">
              <p className="text-16 font-bold dark:text-white">
                {t("sections")}
              </p>
              <IconWithTooltip
                icon={<EditSVG className="text-blue" />}
                tooltip={t("edit")}
                onClick={() => setModal(true)}
              />
            </div>
            <ul className="space-y-4 mt-4">
              {titles ? (
                sectionsData.length ? (
                  sectionsData.map((section) => (
                    <li
                      className={`px-4 py-2.5 flex justify-between items-center gap-x-2 rounded text-gray font-medium cursor-pointer break-all hover:font-semibold hover:text-darkBlue hover:bg-light dark:text-white dark:hover:bg-softBlack ${
                        selectedFaq == section.id
                          ? "bg-light text-darkBlue dark:bg-softBlack font-semibold"
                          : ""
                      }`}
                      onClick={() => {
                        setSelectedFaq(section.id);
                        selectedFaq !== section.id && setFaqs([]);
                        setSearchParams({ section: section.id });
                      }}
                      key={section.id}
                    >
                      <p>{section.title}</p>
                      <p>
                        {titles &&
                          titles.data.faqCategories.find(
                            (x) => x.id == section.id
                          )?.faqs_count}
                      </p>
                    </li>
                  ))
                ) : (
                  <></>
                )
              ) : (
                [...Array(5)].map((_, i) => (
                  <Shimmer className="h-10.5" key={i} />
                ))
              )}
            </ul>
          </div>
          {titles && !isFaqSearching ? (
            !titles.data.faqs.length ? (
              <div className="w-full h-full flex flex-col justify-center items-center">
                <div className="w-1/2 aspect-square">
                  <img
                    src="/assets/images/search_no_results.png"
                    alt="No Results"
                    className="w-full h-full object-contain"
                  />
                </div>
                <p className="text-22 mb-8 dark:text-white font-bold">
                  Hər hansı şablon yoxdur
                </p>
              </div>
            ) : (
              <div className="flex-1 p-4">
                <div className="pb-4 border-b border-solid border-gray">
                  <p className="text-16 font-bold dark:text-white">
                    {t("titles")}
                  </p>
                </div>
                <ReactSortable
                  list={faqs.map((x) => ({ ...x, chosen: true }))}
                  onStart={() => setValidToOrder(false)}
                  setList={setFaqs}
                  onEnd={(e) => setValidToOrder(true)}
                  disabled={sortableDisabled}
                >
                  {faqs.map((title) => (
                    <FAQHeadersSection
                      data={title}
                      sendFaq={(body) => onSendFaq(body)}
                      isLoading={isFaqSending}
                      setSortableDisabled={setSortableDisabled}
                      key={title.id}
                    />
                  ))}
                </ReactSortable>
              </div>
            )
          ) : (
            <div className="flex-1 p-4 space-y-4">
              {[...Array(5)].map((_, i) => (
                <Shimmer className="w-full h-10.5" key={i} />
              ))}
            </div>
          )}
        </div>
      </div>
      <Modal
        visible={modal}
        setVisible={setModal}
        dialogClassName="min-w-[650px]"
      >
        <FAQSectionsModal
          data={sections}
          setVisible={setModal}
          onCreate={(val) => onSectionCreate(val)}
          onUpdate={(val) => onSectionUpdate(val)}
          isLoading={isSectionChanging}
          isSuccess={isSectionCreated}
          onDelete={onDeleteSection}
        />
      </Modal>
    </>
  );
};

export default FAQ;
