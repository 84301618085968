import az from "src/locales/az.json";
import ru from "src/locales/ru.json";
import en from "src/locales/en.json";
import { getCookie } from "src/helper/custom_cookies";
import { ELang } from "src/enums/e_lang";

const t = (key: string) => {
  const locale = getCookie("locale") ? getCookie("locale") : "az"

  const checkLangWord = (word: string) => word || az[key as keyof typeof az] || key;

  const languages: Record<string, () => string> = {
    [ELang.EN]() {
      return checkLangWord((en as typeof az)[key as keyof typeof az]);
    },
    [ELang.RU]() {
      return checkLangWord((ru as typeof az)[key as keyof typeof az]);
    },
    [ELang.AZ]() {
      return checkLangWord(az[key as keyof typeof az]);
    }
  };

  if (locale) {
    const handler = languages[locale] || languages[ELang.AZ];
    return handler();
  }

  return languages[ELang.AZ]();
};

export default t;