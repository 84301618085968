import t from "src/hooks/useTranslate";
import { useAdminShiftChangeListQuery } from "src/services/operatorsService";
import { ReactComponent as EyeSVG } from "src/assets/vectors/eye.svg";
import { ReactComponent as InfoSVG } from "src/assets/vectors/info.svg";
import Shimmer from "../Common/Shimmer";
import { useEffect, useState } from "react";
import Pagination from "../Common/Pagination";
import TextModal from "../Common/TextModal";
import Modal from "../Common/Modal";

const ShiftChangeAdmin = () => {
  const [params, setParams] = useState(new URLSearchParams());
  const [modal, setModal] = useState(false);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [shiftNote, setShiftNote] = useState("");
  const {
    data: shiftChangeList,
    refetch,
    isFetching,
  } = useAdminShiftChangeListQuery(
    params.toString() ? "?" + params.toString() : ""
  );

  useEffect(() => {
    refetch();
  }, [page, perPage]);

  return (
    <>
      <div className="relative py-6 bg-white dark:bg-lightBlack rounded-xl drop-shadow-lg">
        <div className="w-full overflow-auto min-h-[476px]">
          <table className="min-w-[991px] w-full">
            <thead>
              <tr className="[&>th]:text-left [&>th]:pb-6 [&>th]:px-2 [&>th]:dark:text-white [&>th]:whitespace-nowrap border-b border-solid border-gray">
                <th className="w-20 !pl-4">ID</th>
                <th className="w-auto">{t("co_worker")}1</th>
                <th className="w-auto">{t("co_worker")}2</th>
                {/* <th className="w-28">{t("account_type")}</th> */}
                <th className="w-auto">{t("request_date")}</th>
                <th className="w-auto">{t("shift_date")}</th>
                <th className="w-auto">{t("status")}</th>
                <th className="w-24 !pr-4">{t("review")}</th>
              </tr>
            </thead>
            <tbody>
              {shiftChangeList && !isFetching ? (
                shiftChangeList.data.length > 0 ? (
                  shiftChangeList.data.map((shiftChange) => (
                    <tr
                      className="relative [&>td]:py-4 [&>td]:px-2 [&>td]:dark:text-white after:absolute after:top-0 after:left-0 after:w-full after:h-[1px] after:bg-gray after:opacity-50"
                      key={shiftChange.id}
                    >
                      <td className="w-10 !pl-4">{shiftChange.id}</td>
                      <td>{shiftChange.changer_user}</td>
                      <td>{shiftChange.user}</td>
                      <td>{shiftChange.created_at}</td>
                      <td>{shiftChange.change_day}</td>
                      <td
                        className={
                          shiftChange.status == 2
                            ? "text-blue"
                            : shiftChange.status == 1
                            ? "text-green"
                            : "text-red"
                        }
                      >
                        {shiftChange.status_label}
                      </td>
                      <td className="!pr-4">
                        {(shiftChange.status == 2 && shiftChange.note) ? (
                          <EyeSVG
                            className="cursor-pointer"
                            onClick={() => {
                              setShiftNote(shiftChange.note);
                              setModal(true);
                            }}
                          />
                        ) : "-"}
                      </td>
                    </tr>
                  ))
                ) : (
                  <div className="w-full absolute top-1/2 -translate-y-1/2 p-4 flex items-center justify-center">
                    <div className="w-fit flex items-center gap-x-3 p-4 rounded-xl bg-light dark:bg-softBlack text-gray dark:text-white">
                      <InfoSVG />
                      <p>Göstəriləcək hər hansı bir məlumat yoxdur</p>
                    </div>
                  </div>
                )
              ) : (
                <>
                  {[...Array(perPage)].map((_, i) => (
                    <tr
                      className="[&>td]:py-4 [&>td]:px-2 bg-white dark:bg-lightBlack"
                      key={i}
                    >
                      <td className="w-20">
                        <Shimmer className="h-4" />
                      </td>
                      <td className="w-auto">
                        <Shimmer className="h-4" />
                      </td>
                      <td className="w-auto">
                        <Shimmer className="h-4" />
                      </td>
                      <td className="w-auto">
                        <Shimmer className="h-4" />
                      </td>
                      <td className="w-auto">
                        <Shimmer className="h-4" />
                      </td>
                      <td className="w-24">
                        <Shimmer className="h-4" />
                      </td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </table>
        </div>
        {shiftChangeList?.meta.total > 5 && (
          <div className="px-4 pt-4">
            <Pagination
              page={Math.ceil(shiftChangeList?.meta.total / perPage)}
              total={shiftChangeList?.meta.total}
              perPage={perPage}
              pageChange={(e) => {
                setPage(e + 1);
                params.set("page", (e + 1).toString());
              }}
              setPerPage={(val) => {
                setPerPage(val);
                params.set("per_page", val.toString());
              }}
            />
          </div>
        )}
      </div>
      <Modal
        visible={modal}
        setVisible={setModal}
        dialogClassName="min-w-[750px]"
      >
        <TextModal setModal={setModal} title={t("comment")} text={shiftNote} />
      </Modal>
    </>
  );
};

export default ShiftChangeAdmin;
