import { useState, useEffect } from "react";
import t from "src/hooks/useTranslate";
import { Link, useSearchParams } from "react-router-dom";
import Header from "src/components/Header/Header";
import {
  useDeleteUserFileMutation,
  useDeleteUserMutation,
  useGetUsersQuery,
  useUsersExcelMutation,
} from "src/services/usersService";
import { ReactComponent as EditSVG } from "src/assets/vectors/edit.svg";
import { ReactComponent as TrashSVG } from "src/assets/vectors/trash.svg";
import { ReactComponent as ExcelSVG } from "src/assets/vectors/file_excel.svg";
import { ReactComponent as InfoSVG } from "src/assets/vectors/info.svg";
import Pagination from "src/components/Common/Pagination";
import { ReactComponent as UpChevronSVG } from "src/assets/vectors/up_chevron.svg";
import { ReactComponent as CheckSVG } from "src/assets/vectors/check.svg";
import { ReactComponent as XSVG } from "src/assets/vectors/x.svg";
import { ddmmyyyy } from "src/helper/getFormattedDate";
import { EAscDesc } from "src/enums/e_asc_desc";
import Modal from "src/components/Common/Modal";
import Button from "src/components/Common/Inputs/Button";
import { IUser } from "src/types/i_user";
import Shimmer from "src/components/Common/Shimmer";
import { reset } from "src/store/Users/usersStore";
import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import { permissionsStoreReset } from "src/store/Settings/permissionsStore";
import { EGender } from "src/enums/e_gender";
import { successToast } from "src/store/Settings/toastStore";
import { EPermissions } from "src/enums/e_permissions";
import usePermission from "src/hooks/usePermission";
import Input from "src/components/Common/Inputs/Input";
import Select from "src/components/Common/Select";
import { IBaseSelect } from "src/types/Common/i_base_select";
import { useGetGroupAndPermissionsQuery } from "src/services/groupAndPermissionsService";
import IconWithTooltip from "src/components/Common/IconWithTooltip";

const Users = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const [params, setParams] = useState(new URLSearchParams());
  const dispatch = useAppDispatch();
  const [page, setPage] = useState(1);
  const [value, setValue] = useState("");
  const [perPage, setPerPage] = useState(
    searchParams.get("per_page") ? Number(searchParams.get("per_page")) : 10
  );
  const [orderById, setOrderById] = useState(
    searchParams.get("id") || EAscDesc.asc
  );
  const [orderByStatus, setOrderByStatus] = useState(
    searchParams.get("status") || EAscDesc.desc
  );
  const [forDeleteUser, setForDeleteUser] = useState<IUser | null>(null);
  const [modal, setModal] = useState(false);
  const canCreate = usePermission([EPermissions.user_create]);
  const canEdit = usePermission([EPermissions.user_edit]);
  const canDelete = usePermission([EPermissions.user_delete]);

  const {
    data: users,
    refetch,
    isFetching: isUsersLoading,
  } = useGetUsersQuery(
    document.location.search
      ? document.location.search + "&with_hidden=true"
      : "?with_hidden=true"
  );

  const { data: groupAndPermissions, isFetching } =
    useGetGroupAndPermissionsQuery("");

  useEffect(() => {
    dispatch(reset());
    dispatch(permissionsStoreReset());
    refetch();
  }, []);

  useEffect(() => {
    refetch();
  }, [searchParams]);

  const [
    deleteUser,
    {
      data: usersDeleteData,
      isLoading: isUsersDeleteLoading,
      isSuccess: isUsersDeleteSuccess,
      isError: isUsersDeleteError,
    },
  ] = useDeleteUserMutation();

  const [
    getUsersExcel,
    { isLoading: isExcelDownloading, isSuccess: isExcelDownloaded },
  ] = useUsersExcelMutation();

  const deleteUserHandle = async () => {
    try {
      await deleteUser({ id: forDeleteUser?.id });
      // fetchUsers();
    } catch (error) {}
  };

  const onGetUsersExcel = async () => {
    await getUsersExcel("");
  };

  useEffect(() => {
    if (isUsersDeleteSuccess) {
      setModal(false);
    }
  }, [isUsersDeleteSuccess]);

  useEffect(() => {
    if (isExcelDownloaded) {
      dispatch(successToast("Excel uğurla yükləndi"));
    }
  }, [isExcelDownloaded]);



  return (
    <>
      <Header title={t("users")} backLink="/">
        {canCreate && (
          <Link
            to="/users/create"
            className="w-full rounded px-6 bg-green text-white h-10 flex items-center justify-center whitespace-nowrap"
          >
            <span className="text-22 mr-2">+</span> {t("new_user")}
          </Link>
        )}
      </Header>
      <div className="relative py-6 bg-white dark:bg-lightBlack rounded-xl drop-shadow-lg">
        <div className="w-full overflow-auto min-h-[476px]">
          <table className="min-w-[1550px] w-full">
            <thead>
              <tr className="[&>th]:text-left [&>th]:pb-6 [&>th]:px-2 [&>th]:dark:text-white [&>th]:whitespace-nowrap border-b border-solid border-gray">
                <th
                  className="w-14 flex items-center gap-x-1.5 !pl-4 cursor-pointer"
                  onClick={() => {
                    setOrderById(
                      orderById == EAscDesc.asc ? EAscDesc.desc : EAscDesc.asc
                    );
                    setOrderByStatus(EAscDesc.desc);
                    searchParams.delete("status");
                    searchParams.delete("page");
                    setSearchParams((params) => {
                      params.set(
                        "id",
                        orderById == EAscDesc.asc ? EAscDesc.desc : EAscDesc.asc
                      );
                      return params;
                    });
                  }}
                >
                  S/N
                  {/* <UpChevronSVG
                    className={`min-w-[12px] text-blue ${
                      orderById == EAscDesc.asc ? "rotate-180" : ""
                    }`}
                  /> */}
                </th>
                <th className="w-[5%]">Avatar</th>
                <th className="w-[10%]">
                  <Input
                    defaultValue={searchParams.get("search") || ""}
                    placeholder={t("fullname")}
                    onDebouncedValue={(val) => {
                      setValue(val);
                      searchParams.delete("page");
                      val === ""
                        ? setSearchParams((params) => {
                            searchParams.delete("search");
                            return params;
                          })
                        : setSearchParams((params) => {
                            params.set("search", val);
                            return params;
                          });
                    }}
                  />
                </th>
                <th className="w-[7%]">Ata adı</th>
                <th className="w-[10%]">Vəzifə</th>
                <th className="w-[8%]">Doğum günü</th>
                <th className="w-[8%]">İşə b/t</th>
                <th className="w-[10%]">E-poçt</th>
                <th className="w-[10%] [&_ul]:!w-min">
                  <Select
                    data={
                      groupAndPermissions
                        ? [
                            { id: 0, name: t("all") },
                            ...groupAndPermissions.roles,
                          ]
                        : [{ id: 0, name: t("all") }]
                    }
                    defaultValue={
                      groupAndPermissions?.roles.find(
                        (role) => role.id == searchParams.get("role")
                      )?.id || 0
                    }
                    onChange={(e) => {
                      if (e.id == 0) {
                        setSearchParams((params) => {
                          params.delete("role");
                          return params;
                        });
                      } else {
                        setSearchParams((params) => {
                          params.set("role", e.id.toString());
                          return params;
                        });
                      }
                    }}
                  />
                </th>
                <th className="w-[5%]">Maaş</th>
                <th className="w-[2%]">Fayl</th>
                <th
                  className="w-[5%] flex items-center gap-x-1.5 cursor-pointer"
                  onClick={() => {
                    setOrderByStatus(
                      orderByStatus == EAscDesc.asc
                        ? EAscDesc.desc
                        : EAscDesc.asc
                    );
                    setOrderById(EAscDesc.asc);
                    searchParams.delete("id");
                    setSearchParams((params) => {
                      params.set(
                        "status",
                        orderByStatus == EAscDesc.asc
                          ? EAscDesc.desc
                          : EAscDesc.asc
                      );
                      return params;
                    });
                  }}
                >
                  Status
                  <UpChevronSVG
                    className={`min-w-[12px] text-blue ${
                      orderByStatus == EAscDesc.desc ? "rotate-180" : ""
                    }`}
                  />
                </th>
                <th className="w-[8%]">Yaranma tarixi</th>
                <th className="w-[10%] !pr-4">Əməliyyatlar</th>
              </tr>
            </thead>
            <tbody>
              {users && !isUsersLoading ? (
                users.data.length > 0 ? (
                  users.data.map((user: IUser, index: number) => (
                    <tr
                      className="relative [&>td]:py-4 [&>td]:px-2 [&>td]:dark:text-white after:absolute after:top-0 after:left-0 after:w-full after:h-[1px] after:bg-gray"
                      key={user.id}
                    >
                      <td className="!pl-5">
                        {page > 1
                          ? index + 1 + perPage * (page - 1)
                          : index + 1}
                      </td>
                      <td>
                        <div className="w-14 h-14 rounded-full overflow-hidden">
                          <img
                            src={
                              user.avatar
                                ? user.avatar
                                : user.gender === EGender.male
                                ? "/assets/images/male_avatar.png"
                                : "/assets/images/female_avatar.png"
                            }
                            className="w-full h-full object-cover"
                            alt=""
                          />
                        </div>
                      </td>
                      <td
                        className={user.is_hidden == 1 ? "!text-red" : ""}
                      >{`${user.name} ${user.lastname}`}</td>
                      <td>{user.father_name}</td>
                      <td>{user.position}</td>
                      <td>{ddmmyyyy(new Date(user.birthday))}</td>
                      <td>{ddmmyyyy(new Date(user.created_at))}</td>
                      <td>{user.email}</td>
                      <td>{user.main_role.name}</td>
                      <td>{user.salary}</td>
                      <td>
                        {user.files === 0 ? (
                          <IconWithTooltip
                            icon={<XSVG className="text-red" />}
                            tooltip={t("doesnt_exist")}
                            disabled
                          />
                        ) : (
                          <IconWithTooltip
                            icon={<CheckSVG className="text-green w-6 h-6" />}
                            tooltip={t("exist")}
                            disabled
                          />
                        )}
                      </td>
                      <td
                        className={
                          user.status === 0 ? "text-red" : "text-green"
                        }
                      >
                        {user.status === 0 ? t("deactive") : t("active")}
                      </td>
                      <td>{ddmmyyyy(new Date(user.created_at))}</td>
                      <td className="!pr-4">
                        <div className="flex items-center gap-x-1">
                          {canEdit && (
                            <Link to={`/users/edit/${user.id}`}>
                              <IconWithTooltip
                                icon={<EditSVG className="text-blue" />}
                                tooltip={t("edit")}
                              />
                            </Link>
                          )}
                          {canDelete && (
                            <IconWithTooltip
                              icon={<TrashSVG className="text-red" />}
                              tooltip={t("delete")}
                              onClick={() => {
                                setForDeleteUser(user);
                                setModal(true);
                              }}
                            />
                          )}
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <div className="w-full absolute top-1/2 -translate-y-1/2 p-4 flex items-center justify-center">
                    <div className="w-fit flex items-center gap-x-3 p-4 rounded-xl bg-light dark:bg-softBlack text-gray dark:text-white">
                      <InfoSVG />
                      <p>Göstəriləcək hər hansı bir məlumat yoxdur</p>
                    </div>
                  </div>
                )
              ) : (
                <>
                  {[...Array(10)].map((_, i) => (
                    <tr
                      className="[&>td]:py-4 [&>td]:px-2 dark:bg-lightBlack"
                      key={i}
                    >
                      <td className="w-14">
                        <Shimmer className="h-4" />
                      </td>
                      <td className="w-20">
                        <Shimmer className="w-14 h-14 !rounded-full" />
                      </td>
                      <td className="w-32">
                        <Shimmer className="h-4" />
                      </td>
                      <td className="w-32">
                        <Shimmer className="h-4" />
                      </td>
                      <td className="w-32">
                        <Shimmer className="h-4" />
                      </td>
                      <td className="w-44">
                        <Shimmer className="h-4" />
                      </td>
                      <td className="w-28">
                        <Shimmer className="h-4" />
                      </td>
                      <td className="w-20">
                        <Shimmer className="h-4" />
                      </td>
                      <td className="w-64">
                        <Shimmer className="h-4" />
                      </td>
                      <td className="w-32">
                        <Shimmer className="h-4" />
                      </td>
                      <td className="w-20">
                        <Shimmer className="h-4" />
                      </td>
                      <td className="w-12">
                        <Shimmer className="h-4" />
                      </td>
                      <td className="w-20">
                        <Shimmer className="h-4" />
                      </td>
                      <td className="w-28">
                        <Shimmer className="h-4" />
                      </td>
                      <td className="w-24">
                        <Shimmer className="h-4" />
                      </td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </table>
        </div>
        {users?.meta.total > 5 && (
          <div className="px-4 pt-4">
            <Pagination
              page={Math.ceil(users?.meta.total / perPage)}
              total={users?.meta.total}
              perPage={perPage}
              pageChange={(e) => {
                setPage(e + 1);
                setSearchParams((params) => {
                  params.set("page", (e + 1).toString());
                  return params;
                });
              }}
              setPerPage={(val) => {
                setPerPage(val);
                setSearchParams((params) => {
                  params.set("per_page", val.toString());
                  return params;
                });
              }}
            />
          </div>
        )}
      </div>
      <Button
        className="w-48 items-center gap-x-2 bg-darkBlue dark:bg-blue text-light mt-4 ml-auto"
        onClick={onGetUsersExcel}
        isLoading={isExcelDownloading}
      >
        <ExcelSVG /> {t("export_to_excel")}
      </Button>
      <Modal
        visible={modal}
        setVisible={setModal}
        dialogClassName="min-w-[450px]"
      >
        <div className="flex items-center justify-between gap-x-4 p-4 border-b border-solid border-gray">
          <p className="text-16 font-bold">{t("delete_caution_question")}</p>
          <XSVG
            className="text-gray cursor-pointer"
            onClick={() => setModal(false)}
          />
        </div>
        <div className="p-4">
          <Button
            className="w-full bg-red mt-8 text-white text-16 font-medium"
            isLoading={isUsersDeleteLoading}
            onClick={deleteUserHandle}
          >
            Sil
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default Users;
