import { createSlice, current, PayloadAction } from '@reduxjs/toolkit'
import { ETheme } from 'src/enums/e_theme'
import { removeArrayValueByIndex } from 'src/helper/removeArrayValueByIndex'

export interface IFAQQuickReply {
    id: number
    reply: string
}


export interface IFAQ {
    id: number
    title: string
    description: string
    quick_replies: IFAQQuickReply[]
    category_id: number
    // order: number
}

const initialState: { faq_forms: IFAQ[], section, faq_for_send } = {
    faq_forms: [
        {
            id: 1,
            title: "",
            description: "",
            quick_replies: [
                { id: 1, reply: "" },
                // { id: 2, reply: "" },
                // { id: 3, reply: "" },
            ],
            category_id: 0,
            // order: 0
        }
    ],
    section: {
        title: "",
    },
    faq_for_send: {
        phone: "",
        special_message: "",
        reply: 0,
    }
}

export const faqSlice = createSlice({
    name: 'faq',
    initialState,
    reducers: {
        addFAQForm: (state, action: PayloadAction<any>) => {
            state.faq_forms.push({
                id: action.payload.id,
                title: "",
                description: "",
                quick_replies: [
                    { id: 1, reply: "" },
                    // { id: 2, reply: "" },
                    // { id: 3, reply: "" },
                ],
                category_id: 0,
                // order: 0
            })
        },
        setFaq: (state, action: PayloadAction<any>) => {
            state.faq_forms[0] = {
                ...action.payload, quick_replies: action.payload.quick_replies.length > 0 ? action.payload.quick_replies : [
                    { id: 1, reply: "" }
                ]
            }
        },
        setAllFaqFormInputs: (state, action: PayloadAction<any>) => {
            let currentForm = action.payload.id ? state.faq_forms.find((form) => form.id === action.payload.id) : state.faq_forms[0];
            currentForm[action.payload.name] = action.payload.value
        },
        resetFaqForSend: (state) => {
            Object.assign(state.faq_for_send, initialState.faq_for_send);
        },
        resetFaqForm: (state) => {
            state.faq_forms = [
                {
                    id: 1,
                    title: "",
                    description: "",
                    quick_replies: [
                        { id: 1, reply: "" },
                        // { id: 2, reply: "" },
                        // { id: 3, reply: "" },
                    ],
                    category_id: 0,
                    // order: 0
                }
            ];
        },
        setSectionInput: (state, action: PayloadAction<any>) => {
            state.section = action.payload
        },
        setFaqForSendInput: (state, action: PayloadAction<any>) => {
            state.faq_for_send[action.payload.name] = action.payload.value
        },
        changeShortContent: (state, action: PayloadAction<any>) => {
            let currentForm = action.payload.id ? state.faq_forms.find((form) => form.id === action.payload.id) : state.faq_forms[0];
            let currentShortContent = currentForm.quick_replies.find((short) => short.id === action.payload.contentID)
            if (action.payload.type === 1) {

                currentForm.quick_replies.push({ id: action.payload.contentID, reply: "" })
            } else if (action.payload.type === 2) {
                currentShortContent.reply = ""
            } else {
                removeArrayValueByIndex(currentForm.quick_replies, currentShortContent)
            }
        },
        setShortContent: (state, action) => {
            let currentForm = action.payload.id ? state.faq_forms.find((form) => form.id === action.payload.id) : state.faq_forms[0];
            let currentShortContent = currentForm.quick_replies.find((short) => short.id === action.payload.contentID)
            if (currentShortContent) {
                currentShortContent.reply = action.payload.value
            }
            //  else {
            //     currentForm.quick_replies.push({
            //         id: action.payload.contentID,
            //         description: action.payload.value
            //     })
            // }
        },
        deleteFormById: (state, action) => {
            let currentForm = state.faq_forms.find((form) => form.id === action.payload);
            removeArrayValueByIndex(state.faq_forms, currentForm)
        },
        clearReplyById: (state, action) => {
            let currentForm = state.faq_forms.find((form) => form.id === action.payload);
        }
    },
})

export const { addFAQForm, setFaq, setAllFaqFormInputs, resetFaqForSend, resetFaqForm, setSectionInput, setFaqForSendInput, changeShortContent, setShortContent, deleteFormById } = faqSlice.actions

export default faqSlice.reducer