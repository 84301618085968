import { SyntheticEvent, useEffect, useState } from "react";
import IconWithTooltip from "src/components/Common/IconWithTooltip";
import { ReactComponent as EditSVG } from "src/assets/vectors/edit.svg";
import { ReactComponent as TrashSVG } from "src/assets/vectors/trash.svg";
import { ReactComponent as InfoSVG } from "src/assets/vectors/info.svg";
import { ReactComponent as EyeSVG } from "src/assets/vectors/eye.svg";
import { ReactComponent as BackSVG } from "src/assets/vectors/back.svg";
import Shimmer from "src/components/Common/Shimmer";
import t from "src/hooks/useTranslate";
import { Link, useNavigate } from "react-router-dom";
import Pagination from "src/components/Common/Pagination";
import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import {
  resetRoutesForm,
  setRouteForm,
} from "src/store/MashinalApp/mashinalAppStore";
import Modal from "src/components/Common/Modal";
import DeleteCautionModal from "src/components/Common/DeleteCautionModal";
import {
  useCreateMashinalAppRouteMutation,
  useDeleteMashinalAppRouteMutation,
  useUpdateMashinalAppRouteMutation,
} from "src/services/financeService";
import { successToast } from "src/store/Settings/toastStore";
import TextModal from "src/components/Common/TextModal";
import Button from "src/components/Common/Inputs/Button";
import MashinAlAppRoutesForm from "./MashinAlAppRoutesForm";

export const enum ERoutesTableModal {
  PAYLOAD_VIEW = 1,
  DELETE,
  FORM,
}

const MashinalAppRoutesTable = ({ data, isLoading, setParams }: any) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [params, setParamsState] = useState(new URLSearchParams());
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [activeId, setActiveId] = useState(0);
  const [activePayloadForView, setActivePayloadForView] = useState("");
  const [modalType, setModalType] = useState(ERoutesTableModal.PAYLOAD_VIEW);
  const [modal, setModal] = useState(false);
  const form = useAppSelector(
    (state) => state.mashinalApp.mashinalAppRouteForm
  );

  const [
    createRoute,
    {
      isLoading: createRouteLoading,
      isSuccess: createRouteSuccess,
      isError: createRouteHasError,
      error: createRouteError,
    },
  ] = useCreateMashinalAppRouteMutation();

  const [
    updateRoute,
    {
      isLoading: updateRouteLoading,
      isSuccess: updateRouteSuccess,
      isError: updateRouteHasError,
      error: updateRouteError,
    },
  ] = useUpdateMashinalAppRouteMutation();

  const [
    deleteRoute,
    {
      isLoading: deleteRouteLoading,
      isSuccess: deleteRouteSuccess,
      isError: deleteRouteHasError,
      error: deleteRouteError,
    },
  ] = useDeleteMashinalAppRouteMutation();

  const renderModal = () => {
    switch (modalType) {
      case ERoutesTableModal.PAYLOAD_VIEW:
        return (
          <TextModal
            setModal={setModal}
            title={"Payload"}
            text={activePayloadForView}
          />
        );
      case ERoutesTableModal.DELETE:
        return (
          <DeleteCautionModal
            setModal={setModal}
            onSubmit={handleDeleteSubmit}
            isLoading={deleteRouteLoading}
          />
        );
      case ERoutesTableModal.FORM:
        return (
          <MashinAlAppRoutesForm
            title={"Form"}
            setModal={setModal}
            form={form}
            onSubmit={handleFormSubmit}
            isLoading={createRouteLoading || updateRouteLoading}
          />
        );
      default:
        return;
    }
  };

  const handleFormSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();
    if (activeId !== 0) {
      await updateRoute({ id: activeId, body: form });
    } else {
      await createRoute(form);
    }
  };

  const handleDeleteSubmit = async (e: SyntheticEvent) => {
    await deleteRoute(activeId);
  };

  useEffect(() => {
    if (createRouteSuccess) {
      setModal(false);
      dispatch(successToast("Link uğurla əlavə edildi"));
    }
  }, [createRouteSuccess]);

  useEffect(() => {
    if (updateRouteSuccess) {
      setModal(false);
      dispatch(successToast("Link uğurla redaktə edildi"));
    }
  }, [updateRouteSuccess]);

  useEffect(() => {
    if (deleteRouteSuccess) {
      setModal(false);
      dispatch(successToast("Link uğurla silindi"));
    }
  }, [deleteRouteSuccess]);

  return (
    <>
      <div className="min-h-[556px] w-full relative flex flex-col justify-between bg-white dark:bg-lightBlack rounded-xl drop-shadow-lg">
        <div>
          <div className="py-6 px-4 flex justify-between items-center gap-x-4">
            <h2 className="text-16 font-bold dark:text-white">
              Mashin.al App üçün linklər
            </h2>
            <div className="flex items-center gap-x-4">
              <Button
                className="min-w-[150px] bg-green text-white whitespace-nowrap"
                onClick={() => {
                  dispatch(resetRoutesForm());
                  setActiveId(0);
                  setModalType(ERoutesTableModal.FORM);
                  setModal(true);
                }}
              >
                <p className="flex items-center">
                  <span className="text-22 mr-2">+</span> {t("new_link")}
                </p>
              </Button>
              <Button
                className="dark:text-white whitespace-nowrap rounded border border-solid border-gray"
                onClick={() => navigate(-1)}
              >
                <BackSVG />
                <p className="dark:text-white">{t("return_back")}</p>
              </Button>
            </div>
          </div>
          <table className="w-full">
            <thead>
              <tr className="[&>th]:text-left [&>th]:py-6 [&>th]:px-2 [&>th]:dark:text-white [&>th]:whitespace-nowrap border-y border-solid border-gray">
                <th className="w-[15%] !pl-4">{t("ID")}</th>
                <th className="w-[35%]">{t("name")}</th>
                <th className="w-[20%]">{t("link")}</th>
                <th className="w-[10%]">{t("Payload")}</th>
                <th className="w-[20%] !pr-4">{t("operations")}</th>
              </tr>
            </thead>
            <tbody>
              {data && !isLoading ? (
                data.data.length > 0 &&
                data.data.map((route) => (
                  <tr
                    className="relative [&>td]:py-4 [&>td]:px-2 [&>td]:dark:text-white after:absolute after:top-0 after:left-0 after:w-full after:h-[1px] after:bg-gray"
                    key={route.id}
                  >
                    <td className="!pl-4">{route.id}</td>
                    <td>{route.name}</td>
                    <td>{route.route}</td>
                    <td>
                      {route.payload ? (
                        <EyeSVG
                          className="cursor-pointer"
                          onClick={() => {
                            setActivePayloadForView(route.payload);
                            setModalType(ERoutesTableModal.PAYLOAD_VIEW);
                            setModal(true);
                          }}
                        />
                      ) : (
                        ""
                      )}
                    </td>
                    <td className="!pr-4">
                      <div className="flex items-center gap-x-1">
                        <IconWithTooltip
                          icon={<EditSVG className="text-blue" />}
                          tooltip={t("edit")}
                          onClick={() => {
                            dispatch(setRouteForm(route));
                            setModalType(ERoutesTableModal.FORM);
                            setActiveId(route.id);
                            setModal(true);
                          }}
                        />
                        {route.can_delete && (
                          <IconWithTooltip
                            icon={<TrashSVG className="text-red" />}
                            tooltip={t("delete")}
                            onClick={() => {
                              setActiveId(route.id);
                              setModalType(ERoutesTableModal.DELETE);
                              setModal(true);
                            }}
                          />
                        )}
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <>
                  {[...Array(10)].map((_, i) => (
                    <tr className="[&>td]:py-4 [&>td]:px-2" key={i}>
                      <td className="w-[15%]">
                        <Shimmer className="h-10.5" />
                      </td>
                      <td className="w-[35%]">
                        <Shimmer className="h-10.5" />
                      </td>
                      <td className="w-[20%]">
                        <Shimmer className="h-10.5" />
                      </td>
                      <td className="w-[10%]">
                        <Shimmer className="h-10.5" />
                      </td>
                      <td className="w-[20%]">
                        <Shimmer className="h-10.5" />
                      </td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </table>
          {data && !data.data.length && (
            <div className="w-full h-[500px] p-4 flex items-center justify-center">
              <div className="w-fit flex items-center gap-x-3 p-4 rounded-xl bg-light dark:bg-softBlack text-gray dark:text-white">
                <InfoSVG />
                <p>Göstəriləcək hər hansı bir məlumat yoxdur</p>
              </div>
            </div>
          )}
        </div>
        {data?.meta.total > 5 && (
          <div className="w-full px-4 py-6">
            <Pagination
              page={Math.ceil(data?.meta.total / perPage)}
              total={data?.meta.total}
              perPage={perPage}
              pageChange={(e) => {
                setPage(e + 1);
                params.set("page", (e + 1).toString());
                setParams("?" + params.toString());
              }}
              setPerPage={(val) => {
                setPerPage(val);
                params.set("page", "1");
                params.set("per_page", val.toString());
                setParams("?" + params.toString());
              }}
            />
          </div>
        )}
      </div>
      <Modal
        visible={modal}
        setVisible={setModal}
        dialogClassName="min-w-[450px]"
      >
        {renderModal()}
      </Modal>
    </>
  );
};

export default MashinalAppRoutesTable;
