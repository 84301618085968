import { ReactComponent as UserSVG } from "src/assets/vectors/user.svg";
import { ReactComponent as InfoSVG } from "src/assets/vectors/info.svg";
import { Switch } from "@headlessui/react";
import { useState } from "react";
import Shimmer from "../Common/Shimmer";
import t from "src/hooks/useTranslate";
import InputRangeDatePicker from "../Common/Inputs/InputRangeDatePicker";
import { ddmmyyyy } from "src/helper/getFormattedDate";

const TargetStatistics = ({ data, isLoading, onChange }) => {
  const [enabled, setEnabled] = useState(false);
  // {enabled ? t("monthly") : t("daily")}
  return (
    <>
      <div className="flex justify-between items-center mb-4">
        <p className="text-16 dark:text-white">Hədəf statistikası</p>
        <InputRangeDatePicker
          defaultValue={`${ddmmyyyy(new Date())}-${ddmmyyyy(new Date())}`}
          className="z-20"
          onChange={(e: string) => onChange(e)}
        />
        {/* <Switch
          checked={enabled}
          onChange={(e) => {
            setEnabled(e);
            switchType(e);
          }}
          className={`${enabled ? "bg-blue" : "bg-darkSnow/40"} ${
            isLoading ? "pointer-events-none" : "cursor-pointer"
          } 
          relative ml-3 inline-flex h-4 w-8 shrink-0 rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 after:absolute after:-inset-x-2.5 after:-inset-y-1`}
        >
          <span
            aria-hidden="true"
            className={`${
              enabled ? "left-full -translate-x-1/2" : "left-0 -translate-x-1/2"
            }
            absolute top-1/2 -translate-y-1/2 pointer-events-none inline-block h-5 w-5 rounded-full bg-white drop-shadow-md shadow-black ring-0 duration-200 ease-in-out`}
          />
        </Switch> */}
      </div>
      {data && !isLoading ? (
        data.length > 0 ? (
          data.map((report) => (
            <div
              className="h-10.5 flex justify-between items-center dark:text-white"
              key={report.user_id}
            >
              <div className="flex items-center gap-x-2">
                <UserSVG className="text-blue cursor-pointer" />
                <p>{report.user.full_name}</p>
              </div>
              <p>{report.count}</p>
            </div>
          ))
        ) : (
          <div className="w-full h-full flex flex-col justify-center items-center">
            <div className="w-1/2 aspect-square">
              <img
                src="/assets/images/search_no_results.png"
                alt="No Results"
                className="w-full h-full object-contain"
              />
            </div>
            <p className="flex items-center gap-x-2 text-gray dark:text-white font-medium">
              <InfoSVG /> Hal-hazırda hər hansı məlumat yoxdur
            </p>
          </div>
        )
      ) : (
        <div className="space-y-4">
          {[...Array(3)].map((_, i) => (
            <Shimmer className="h-10.5" key={i} />
          ))}
        </div>
      )}
    </>
  );
};

export default TargetStatistics;
