import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { removeArrayValueByIndex } from 'src/helper/removeArrayValueByIndex'
import { IPermission } from 'src/types/i_permission'


const initialState: { visited: boolean, all_selected: number[], permissions: IPermission } = {
    visited: false,
    all_selected: [],
    permissions: {
        id: 0,
        name: "All",
        label: "all",
        child: [],
    },
}

export const permissionsSlice = createSlice({
    name: 'permission',
    initialState,
    reducers: {
        setPermissions: (state, action: PayloadAction<{ exist: IPermission[], data: IPermission[] }>) => {
            if (!state.visited) {
                state.permissions.child = action.payload.data;

                state.all_selected = []
                action.payload.exist?.forEach((permission: any) => {
                    state.all_selected.push(permission.id)
                })
                let isAllSelected = state.permissions.child.every((child: any) => state.all_selected.includes(child.id))
                isAllSelected && state.all_selected.push(state.permissions.id)

                state.visited = true
            }
        },
        changePermissions: (state, action: PayloadAction<any>) => {
            //All
            if (action.payload.id == 0) {
                if (state.all_selected.includes(action.payload.id)) {
                    state.all_selected = []
                } else {
                    state.all_selected = []
                    state.all_selected.push(action.payload.id)
                    action.payload.child?.forEach((parent: any) => {
                        state.all_selected.push(parent.id)
                        parent.child?.forEach((child: any) => {
                            state.all_selected.push(child.id)
                        });
                    });
                }
            }
            //Parent
            if (action.payload.parent_id == 0) {
                if (state.all_selected.includes(action.payload.id)) {
                    removeArrayValueByIndex(state.all_selected, action.payload.id)
                    // removeArrayValueByIndex(state.all_selected, state.permissions.id)
                    action.payload.child?.forEach((child: any) => {
                        removeArrayValueByIndex(state.all_selected, child.id)
                    });

                } else {
                    state.all_selected.push(action.payload.id)
                    action.payload.child?.forEach((child: any) => {
                        if (!state.all_selected.includes(child.id)) {

                            state.all_selected.push(child.id)
                        }
                    });
                    let isAllParentSelected = state.permissions.child.every((child: any) => state.all_selected.includes(child.id))
                    isAllParentSelected && state.all_selected.push(state.permissions.id)
                }
            }
            //Child
            if (action.payload.id != 0 && action.payload.parent_id != 0) {
                if (state.all_selected.includes(action.payload.id)) {
                    let parent = state.permissions.child.find((parent: any) => action.payload.parent_id == parent.id)
                    removeArrayValueByIndex(state.all_selected, action.payload.id)
                    removeArrayValueByIndex(state.all_selected, state.permissions.id)
                    removeArrayValueByIndex(state.all_selected, parent!.id)
                } else {
                    state.all_selected.push(action.payload.id)
                    let parent = state.permissions.child.find((parent: any) => action.payload.parent_id == parent.id)

                    let isChildrenSelected = parent?.child?.every((child: any) => state.all_selected.includes(child.id))
                    isChildrenSelected && state.all_selected.push(parent!.id)
                }
            }

            let isAllSelected = state.permissions.child.every((child: any) => state.all_selected.includes(child.id))
            isAllSelected ? !state.all_selected.includes(state.permissions.id) && state.all_selected.push(state.permissions.id) : removeArrayValueByIndex(state.all_selected, state.permissions.id)
        },
        permissionsStoreReset: (state) => {
            Object.assign(state, initialState);
        },
    },
})

export const { setPermissions, changePermissions, permissionsStoreReset } = permissionsSlice.actions

export default permissionsSlice.reducer