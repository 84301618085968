import { RadioGroup } from "@headlessui/react";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { ReactComponent as PaperclipSVG } from "src/assets/vectors/paperclip.svg";
import { ReactComponent as InfoSVG } from "src/assets/vectors/info.svg";
import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import t from "src/hooks/useTranslate";
import {
  useCloseRequestMutation,
  useGetRequestTagsQuery,
  useOpenRequestMutation,
  useSendRequestAnswerMutation,
} from "src/services/requestsService";
import { useGetUsersAndGroupsMutation } from "src/services/usersService";
import {
  resetRequestDetail,
  setAllInputs,
  setAssignedRequests,
} from "src/store/Requests/RequestsStore";
import Button from "../Common/Inputs/Button";
import InputFile from "../Common/Inputs/InputFile";
import InputSearchGroupNUsersMixed from "../Common/Inputs/InputSearchGroupNUsersMixed";
import Modal from "../Common/Modal";
import Select from "../Common/Select";
import TagSelect from "../Common/TagSelect";
import RequestChatMessage from "./RequestChatMessage";
import { successToast, errorToast } from "src/store/Settings/toastStore";
import RequestSelectedsModal from "./RequestSelectedsModal";
import { ERequestAnswer } from "src/enums/e_request_answer";
import RequestAttachmentsModal from "./RequestAttachmentsModal";
import { useNavigate } from "react-router-dom";
import Shimmer from "../Common/Shimmer";
import { ddmmyyyy, ddmmyyyy_hhmm } from "src/helper/getFormattedDate";
import usePermission from "src/hooks/usePermission";
import { EPermissions } from "src/enums/e_permissions";

export enum ERequestDetailsModals {
  selecteds = 1,
  files,
}

const RequestChat = ({ data, isLoading: isRequestsLoading }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { data: tags } = useGetRequestTagsQuery("");
  const [category, setCategory] = useState(ERequestAnswer.answer);
  const [file, setFile] = useState<any>(null);
  const [selectedFiles, setSelectedFiles] = useState<any>([]);
  const requestDetail = useAppSelector(
    (state) => state.requests.request_detail
  );
  const canCloseTicket = usePermission([EPermissions.ticket_close])
  const [modal, setModal] = useState(false);
  const [modalType, setModalType] = useState(0);
  const [
    getUsersNGroups,
    { data: usersNgroups, isLoading: isUsersNGroupsLoading },
  ] = useGetUsersAndGroupsMutation();

  const [
    closeRequest,
    {
      isLoading: isRequestClosing,
      isSuccess: isRequestClosed,
      error: requestCloseError,
      isError: requestNotClosed,
    },
  ] = useCloseRequestMutation();

  const [
    openRequest,
    {
      isLoading: isRequestOpening,
      isSuccess: isRequestOpened,
      error: openError,
      isError: isRequestNotOpen,
    },
  ] = useOpenRequestMutation();

  const [
    sendAnswer,
    { data: answers, isLoading: isAnswerSending, isSuccess: isAnswerSent },
  ] = useSendRequestAnswerMutation();
  const assignedGroups = useAppSelector(
    (state) => state.requests.request_detail.assigned_groups
  );
  const assignedUsers = useAppSelector(
    (state) => state.requests.request_detail.assigned_users
  );

  const fetchUsersNGroups = useCallback(async (search) => {
    await getUsersNGroups(`?search=${search}`);
  }, []);

  useEffect(() => {
    fetchUsersNGroups("");
    dispatch(resetRequestDetail());
  }, []);

  const radioData = [
    { id: 1, value: ERequestAnswer.answer },
    { id: 2, value: ERequestAnswer.redirect },
  ];

  const onSendAnswer = async () => {
    let formData = new FormData();
    for (const key in requestDetail) {
      if (key == "tags") {
        requestDetail.tags.forEach((tag) => {
          formData.append("tags[]", tag.id);
        });
        continue;
      }
      if (key == "assigned_groups" && requestDetail.assigned_groups.length) {
        requestDetail.assigned_groups.forEach((group) => {
          formData.append("assigned_groups[]", group.id);
        });
        continue;
      }
      if (key == "assigned_users" && requestDetail.assigned_users.length) {
        requestDetail.assigned_users.forEach((user) => {
          formData.append("assigned_users[]", user.id);
        });
        continue;
      }
      formData.append(key, requestDetail[key]);
    }
    file &&
      [...file].forEach((fl) => {
        formData.append("files[]", fl);
      });
    await sendAnswer({ id: data?.id, body: formData });
  };

  const onCloseRequest = async () => {
    if (canCloseTicket) {
      await closeRequest(data.id);
      
    } else {
      dispatch(errorToast("Sizin sorğunu bağlamağa icazəniz yoxdur !"))
    }
  };

  const onOpenRequest = async () => {
    await openRequest(data.id);
  };

  useEffect(() => {
    if (isRequestClosed) {
      dispatch(successToast("Sorgu baglandi"));
      navigate("/requests");
    }
  }, [isRequestClosed]);

  useEffect(() => {
    if (requestNotClosed) {
      //@ts-ignore
      dispatch(errorToast(requestCloseError?.data.message));
    }
  }, [requestNotClosed]);

  useEffect(() => {
    if (isRequestOpened) {
      dispatch(successToast("Sorgu yenidən açıldı"));
    }
  }, [isRequestOpened]);

  useEffect(() => {
    if (isRequestNotOpen) {
      //@ts-ignore
      dispatch(errorToast(openError?.data.message));
    }
  }, [isRequestNotOpen]);

  useEffect(() => {
    if (isAnswerSent) {
      dispatch(successToast("Sorgu gonderildi"));
      dispatch(resetRequestDetail());
      setCategory(ERequestAnswer.answer);
      setFile(null);
    }
  }, [isAnswerSent]);

  return (
    <>
      <div className="flex-1 flex flex-col gap-y-4 px-4 py-6 dark:text-white">
        <div className="w-full flex gap-x-4">
          <div className="w-40">
            <Select
              data={[
                { id: 1, name: "Yeni" },
                { id: 2, name: "Bağlı" },
              ]}
              defaultTitle={t("choose")}
              onChange={() => null}
            />
          </div>
          <div className="flex-1">
            <TagSelect
              data={tags ? tags : []}
              defaultTitle={t("choose")}
              defaultValue={data?.tags}
              onChange={(e) =>
                dispatch(
                  setAllInputs({
                    key: "request_detail",
                    name: "tags",
                    value: e,
                  })
                )
              }
            />
          </div>
        </div>
        <div className="space-y-4 mt-6">
          <div className="flex items-center justify-between gap-x-4">
            <h2 className="text-20 font-semibold">
              #{data?.id} - {data?.title}
            </h2>
            {data?.media &&
              (Object.values(data?.media).length === 1 ? (
                <a
                  href={Object.values<any>(data?.media)[0].original_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center gap-x-1 text-blue font-medium"
                >
                  <PaperclipSVG />{" "}
                  {Object.values<any>(data?.media)[0].file_name}
                </a>
              ) : (
                <p
                  className="flex items-center gap-x-1 text-blue cursor-pointer"
                  onClick={() => {
                    setSelectedFiles(Object.values(data?.media));
                    setModalType(ERequestDetailsModals.files);
                    setModal(true);
                  }}
                >
                  <PaperclipSVG /> Butun fayllar
                </p>
              ))}
          </div>
          <div className="flex items-center justify-between gap-x-4">
            <p>
              {data?.author.full_name} {t("typing")}:
            </p>
            <p className="text-gray">
              {ddmmyyyy_hhmm(new Date(data?.created_at))}
            </p>
          </div>
          <p className="text-gray break-all">{data?.text}</p>
        </div>
        <div className="max-h-[520px] flex flex-col-reverse p-4 overflow-auto mt-4">
          {data && !isRequestsLoading ? (
            data.actions.length ? (
              [...data?.actions].reverse().map((action) => (
                <RequestChatMessage
                  data={action}
                  setModal={() => {
                    setModalType(ERequestDetailsModals.files);
                    setModal(true);
                  }}
                  setFiles={(f) => setSelectedFiles(f)}
                  key={action.id}
                />
              ))
            ) : (
              <div className="w-fit flex items-center gap-x-3 p-4 rounded-xl bg-light dark:bg-softBlack text-gray dark:text-white">
                <InfoSVG />
                <p>
                  Hal hazırda sorğuya cavab verilməmiş və ya yönləndirmə
                  edilməmişdir.
                </p>
              </div>
            )
          ) : (
            <div className="space-y-4">
              {[
                ...Array(6).map((_, i) => (
                  <Shimmer className="h-10.5" key={i} />
                )),
              ]}
            </div>
          )}
        </div>
        {data && data.status !== 3 ? (
          <>
            <div className="mt-auto border border-solid border-gray dark:border-light divide-y divide-gray dark:divide-light rounded-xl">
              <div className="flex items-center justify-between gap-x-4 p-4">
                <div className="flex items-center h-10.5 gap-x-4">
                  <p>{t("answer_to_request")}:</p>
                  <RadioGroup
                    value={category}
                    onChange={setCategory}
                    className="flex items-center flex-wrap gap-4 ml-auto"
                  >
                    {radioData.map((option) => (
                      <RadioGroup.Option value={option.value} key={option.id}>
                        {({ checked }) => (
                          <div className="flex items-center gap-x-2 cursor-pointer whitespace-nowrap">
                            <div
                              className={`relative w-4 h-4 border-2 border-solid rounded-full bg-transparent after:absolute after:left-1/2 after:top-1/2 after:-translate-y-1/2 after:-translate-x-1/2 after:w-2 after:h-2 after:rounded-full ${
                                checked
                                  ? "border-green after:bg-green"
                                  : "border-darkBlue dark:border-white after:bg-transparent"
                              }`}
                            ></div>
                            <span
                              className={
                                checked
                                  ? "text-green"
                                  : "text-darkBlue dark:text-white"
                              }
                            >
                              {t(option.value)}
                            </span>
                          </div>
                        )}
                      </RadioGroup.Option>
                    ))}
                  </RadioGroup>
                </div>
                {category === ERequestAnswer.redirect && (
                  <div className="flex-1 flex items-center gap-x-4 max-w-2xl">
                    <InputSearchGroupNUsersMixed
                      data={usersNgroups?.data}
                      selectedData={[...assignedGroups, ...assignedUsers]}
                      placeholder={t("search") + "..."}
                      isLoading={false}
                      onInput={(query: string) => fetchUsersNGroups(query)}
                      onChange={(e) => dispatch(setAssignedRequests(e))}
                    />
                    <Button
                      className="bg-blue text-white whitespace-nowrap"
                      onClick={() => {
                        [...assignedGroups, ...assignedUsers].length &&
                          setModal(true);
                        setModalType(ERequestDetailsModals.selecteds);
                      }}
                    >
                      <p>
                        {t("selecteds")} (
                        <span className="inline-block w-6">
                          {[...assignedGroups, ...assignedUsers].length}
                        </span>
                        )
                      </p>
                    </Button>
                  </div>
                )}
              </div>
              <textarea
                className="w-full p-4 border-gray rounded resize-none dark:bg-softBlack dark:text-white"
                placeholder="Səbəbi yazın"
                value={requestDetail.text}
                rows={6}
                maxLength={250}
                onChange={(e) =>
                  dispatch(
                    setAllInputs({
                      key: "request_detail",
                      name: "text",
                      value: e.target.value,
                    })
                  )
                }
              />
            </div>
            <InputFile
              // label={t("file") + "*"}
              fileName={file}
              multiple
              className="[&>div]:border-none [&>div]:!bg-transparent [&>div>div]:border [&>div>div]:rounded"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                // @ts-ignore
                setFile(e.target.files)
              }
            />
            <div className="flex gap-x-4 items-center">
              <Button
                className="flex-1 bg-blue text-white"
                onClick={onSendAnswer}
                isLoading={isAnswerSending}
              >
                {t("send")}
              </Button>
              <Button
                className="flex-1 bg-gray text-white"
                onClick={onCloseRequest}
                isLoading={isRequestClosing}
              >
                {t("close_request")}
              </Button>
            </div>
          </>
        ) : (
          <Button
            className="min-h-[42px] max-h-[42px] mt-auto flex-1 bg-green text-white"
            onClick={onOpenRequest}
            isLoading={isRequestOpening}
          >
            {t("reopen_request")}
          </Button>
        )}
      </div>
      <Modal
        visible={modal}
        setVisible={setModal}
        dialogClassName="min-w-[450px]"
      >
        {modalType === ERequestDetailsModals.selecteds ? (
          <RequestSelectedsModal
            data={[...assignedGroups, ...assignedUsers]}
            setModal={setModal}
            onDelete={(assign) => {
              [...assignedGroups, ...assignedUsers].length === 1 &&
                setModal(false);
              dispatch(setAssignedRequests(assign));
            }}
          />
        ) : (
          <RequestAttachmentsModal data={selectedFiles} setModal={setModal} />
        )}
      </Modal>
    </>
  );
};

export default RequestChat;
