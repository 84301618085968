import { ReactComponent as CheckSVG } from "src/assets/vectors/check.svg";
import { FC, useEffect, useRef } from "react";
import { ICheckbox } from "src/types/Common/i_checkbox";

const Checkbox: FC<ICheckbox> = ({ id, label, isChecked, indeterminate = false, onChange }) => {
  const input = useRef<any>(null);

  useEffect(() => {
    input.current.indeterminate = indeterminate;
  }, [input, indeterminate]);

  return (
    <div
      className="flex items-center gap-x-2"
      onClick={(e) => e.stopPropagation()}
    >
      <div
        className={`flex items-center justify-center w-6 h-6 rounded cursor-pointer ${
          isChecked || indeterminate ? "bg-blue" : "border border-solid border-gray"
        }`}
        onClick={() => input.current.click()}
      >
        {isChecked ? <CheckSVG className="text-white" /> : null}
        {indeterminate ? <div className="h-1 w-4 rounded bg-white"></div> : null}
      </div>
      <input
        id={id?.toString()}
        type="checkbox"
        className="hidden"
        name={label}
        checked={isChecked}
        onChange={onChange}
        ref={input}
      />
      <label htmlFor={id?.toString()} className="cursor-pointer dark:text-white">
        {label}
      </label>
    </div>
  );
};

export default Checkbox;
