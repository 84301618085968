import { useEffect, useState } from "react";
import t from "src/hooks/useTranslate";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Header from "src/components/Header/Header";
import UsersFiles from "src/components/Users/UsersFiles";
import UsersForm from "src/components/Users/UsersForm";
import UsersPassword from "src/components/Users/UsersPassword";
import UsersPermissions from "src/components/Users/UsersPermissions";
import { UsersStepsData } from "src/data/create_users_steps_data";
import { EUsersCrudSteps } from "src/enums/e_users_crud_steps";
import { dataURLtoFile } from "src/helper/dataUrlToFile";
import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import { useGetGroupAndPermissionsQuery } from "src/services/groupAndPermissionsService";
import {
  useGetUserByIdQuery,
  useUpdateUserPermissionsMutation,
  useUpdateUserMutation,
  useUpdateUserPasswordsMutation,
  useAddUserFileMutation,
  useDeleteUserFileMutation,
} from "src/services/usersService";
import { setPermissions, setUser } from "src/store/Users/usersStore";
import { setPermissions as setPermissionStore } from "src/store/Settings/permissionsStore";
import { successToast } from "src/store/Settings/toastStore";

const UsersEdit = () => {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const userPasswords = useAppSelector((state) => state.users.user_passwords);
  const [currentStep, setCurrentStep] = useState(EUsersCrudSteps.form);
  const { data: groupAndPermissions } = useGetGroupAndPermissionsQuery("");
  const { data: user } = useGetUserByIdQuery(params.userId);
  const newUserData = useAppSelector((state) => state.users.newUser);
  const userFile = useAppSelector((state) => state.users.user_file);
  const userForm = useAppSelector((state) => state.users.users_form);
  const userPermissions = useAppSelector(
    (state) => state.users.users_form.permissions
  );
  const users_permissions = useAppSelector(
    (state) => state.users.users_permissions
  );

  useEffect(() => {
    users_permissions.child.length > 0 &&
      userPermissions &&
      dispatch(
        setPermissionStore({
          exist: userPermissions,
          data: users_permissions.child,
        })
      );
  }, [users_permissions, userPermissions]);

  const renderSwitch = () => {
    switch (currentStep) {
      case EUsersCrudSteps.form:
        return (
          <UsersForm
            userForm={userForm}
            isLoading={isUserFormUpdating}
            isEdit={true}
            onSubmit={onUpdateForm}
          />
        );
      case EUsersCrudSteps.permissions:
        return (
          <UsersPermissions
            isLoading={isUsersPermissionUpdating}
            onSubmit={(allSelected: number[]) =>
              onUpdatePermissions(allSelected)
            }
          />
        );
      case EUsersCrudSteps.password:
        return (
          <UsersPassword
            onSubmit={onUpdatePasswords}
            isLoading={isUsersPasswordsUpdating}
          />
        );
      case EUsersCrudSteps.files:
        return (
          <UsersFiles
            isLoading={isUserFileAdding}
            onFileAdded={isUserFileAdded}
            onFileDeleting={isUsersFileDeleting}
            onFileDeleted={isUsersFileDeleted}
            onSubmit={onAddFile}
            onDelete={onDeleteFile}
          />
        );
      default:
        return;
    }
  };

  useEffect(() => {
    user && dispatch(setUser(user));
  }, [user]);

  const [
    setUsersPermission,
    {
      data: usersPermissionData,
      isLoading: isUsersPermissionUpdating,
      isSuccess: isUsersPermissionUpdated,
      isError: isUsersPermissionError,
    },
  ] = useUpdateUserPermissionsMutation();

  const [
    setUsersPassword,
    {
      data: usersPasswordsData,
      isLoading: isUsersPasswordsUpdating,
      isSuccess: isUsersPasswordsUpdated,
      isError: isUsersPasswordsError,
    },
  ] = useUpdateUserPasswordsMutation();

  const [
    deleteUserFile,
    {
      isLoading: isUsersFileDeleting,
      isSuccess: isUsersFileDeleted,
      isError: isUsersFileDeleteError,
    },
  ] = useDeleteUserFileMutation();

  const onUpdatePermissions = async (allSelected: number[]) => {
    await setUsersPermission({
      id: userForm.id,
      permission: allSelected.filter((permission: number) => permission !== 0),
    });
  };

  const onUpdatePasswords = async () => {
    await setUsersPassword({
      id: userForm.id,
      passwords: userPasswords,
    });
  };

  const onUpdateForm = async (e: any) => {
    e.preventDefault();

    let formData = new FormData();
    for (const key in userForm) {
      formData.append(key, userForm[key]);
    }
    if (!userForm.avatar) {
      formData.delete("avatar");
      formData.append("remove_image", "true");
    } else if (!userForm.avatar.includes(",")) {
      formData.delete("avatar");
    } else {
      formData.append("avatar", dataURLtoFile(userForm.avatar, "image"));
    }
    formData.delete("working_hours");
    const body = {
      id: user.id,
      data: formData,
    };

    await updateUserForm(body);
  };

  const [
    updateUserForm,
    {
      data: updatedUserData,
      isLoading: isUserFormUpdating,
      isSuccess: isUserFormUpdated,
      error: userFormUpdateError,
      isError: isUserFormNotUpdated,
    },
  ] = useUpdateUserMutation();

  const [
    addUserFile,
    {
      data: addedUserFilesData,
      isLoading: isUserFileAdding,
      isSuccess: isUserFileAdded,
      isError: isUserFilesNotAdded,
    },
  ] = useAddUserFileMutation();

  const onAddFile = async () => {
    let formData = new FormData();
    for (const key in userFile) {
      formData.append(key, userFile[key]);
    }
    await addUserFile({ id: userForm.id, files: formData });
  };

  const onDeleteFile = async (fileId: number) => {
    await deleteUserFile({ id: userForm.id, fileId: fileId });
  };

  useEffect(() => {
    if (isUserFormNotUpdated) {
      //@ts-ignore
      dispatch(errorToast(userFormUpdateError?.data.message));
    }
  }, [isUserFormNotUpdated]);

  useEffect(() => {
    if (
      isUserFormUpdated ||
      isUsersPermissionUpdated ||
      isUsersPasswordsUpdated
    ) {
      dispatch(successToast("Məlumatlar dəyişdirildi"));
    }
  }, [isUserFormUpdated, isUsersPermissionUpdated, isUsersPasswordsUpdated]);

  useEffect(() => {
    groupAndPermissions && dispatch(setPermissions(groupAndPermissions));
  }, [groupAndPermissions]);

  return (
    <>
      <Header
        title={t("create_users")}
        backLink={location.key === "default" ? "/users" : true}
      />
      <div className="relative py-6 bg-white dark:bg-lightBlack rounded-xl drop-shadow-lg">
        <ul className="flex items-center gap-x-4 px-4 pb-6 border-b border-solid border-b-gray">
          {UsersStepsData.map((step) => {
            const Icons = step.icon;
            return (
              <li
                key={step.id}
                className={`flex items-center gap-x-2 px-4 py-2 rounded cursor-pointer ${
                  step.id === currentStep
                    ? "bg-light text-darkBlue dark:bg-softBlack dark:text-white"
                    : "font-medium text-gray dark:text-white"
                }`}
                onClick={() => setCurrentStep(step.id)}
              >
                <Icons />
                {t(step.title)}
              </li>
            );
          })}
        </ul>
        <div className="px-4 pt-6">{renderSwitch()}</div>
      </div>
    </>
  );
};

export default UsersEdit;
