import { useEffect, useState } from "react";
import t from "src/hooks/useTranslate";
import { Link } from "react-router-dom";
import Button from "src/components/Common/Inputs/Button";
import Modal from "src/components/Common/Modal";
import Header from "src/components/Header/Header";
import OperatorsExcelExports from "src/components/Operators/OperatorsExcelExports";
import OperatorsTable from "src/components/Operators/OperatorsTable";
import TopOperators from "src/components/Operators/TopOperators";
import {
  useChangeStatusUserMutation,
  useChangeStatusTemporaryUserMutation,
  useDeleteUserMutation,
} from "src/services/operatorsService";
import DeleteCautionModal from "src/components/Common/DeleteCautionModal";
import usePermission from "src/hooks/usePermission";
import InputRangeDatePicker from "src/components/Common/Inputs/InputRangeDatePicker";
import { ddmmyyyy } from "src/helper/getFormattedDate";

const Operators = () => {
  const [ID, setID] = useState<number | null>(null);
  const [modal, setModal] = useState<boolean>(false);

  const [
    deleteUser,
    { isLoading: isUsersRemoving, isSuccess: isUsersRemoved },
  ] = useDeleteUserMutation();
  const [
    changeStatusUser,
    // { isLoading: isUsersRemoving, isSuccess: isUsersRemoved },
  ] = useChangeStatusUserMutation();
  const [
    changeStatusTemporaryUser,
    // { isLoading: isUsersRemoving, isSuccess: isUsersRemoved },
  ] = useChangeStatusTemporaryUserMutation();

  const onDelete = async () => {
    await deleteUser({ id: ID });
  };

  const onStatusChange = async (id: number) => {
    await changeStatusUser({ id });
  };

  const onTemporaryStatusChange = async (id: number) => {
    await changeStatusTemporaryUser({ id });
  };

  useEffect(() => {
    if (isUsersRemoved) {
      setModal(false);
      setID(null);
    }
  }, [isUsersRemoved]);

  return (
    <>
      <Header title={t("operators")}>
        <Link
          to="work-schedule"
          className="min-w-[180px] h-10.5 bg-green px-4 flex justify-center items-center gap-x-2 rounded text-white ml-auto whitespace-nowrap"
        >
          <span className="text-16 font-medium">+</span>
          <p>{t("create_operator")}</p>
        </Link>
      </Header>
      <div className="space-y-6">
        <TopOperators />
        <OperatorsTable
          onDelete={(id) => {
            setID(id);
            setModal(true);
          }}
          onStatusChange={(id) => onStatusChange(id)}
          onTemporaryStatusChange={(id) => onTemporaryStatusChange(id)}
        />
        <div className="flex gap-4 items-baseline">
          <OperatorsExcelExports />
        </div>
      </div>
      <Modal
        visible={modal}
        setVisible={setModal}
        dialogClassName="min-w-[450px]"
      >
        <DeleteCautionModal
          setModal={setModal}
          onSubmit={onDelete}
          isLoading={isUsersRemoving}
        />
      </Modal>
    </>
  );
};

export default Operators;
