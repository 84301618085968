import { ReactComponent as XSVG } from "src/assets/vectors/x.svg";
import t from "src/hooks/useTranslate";
import Button from "./Inputs/Button";

const DeleteCautionModal = ({ setModal, onSubmit, isLoading }) => {
  return (
    <>
      <div className="flex items-center justify-between gap-x-4 p-4 border-b border-solid border-gray">
        <p className="text-16 font-bold dark:text-white">
          {t("delete_caution_question")}
        </p>
        <XSVG
          className="text-gray cursor-pointer dark:text-white"
          onClick={() => setModal(false)}
        />
      </div>
      <div className="p-4">
        <Button
          className="w-full bg-red mt-8 text-white text-16 font-medium"
          onClick={onSubmit}
          isLoading={isLoading}
        >
          Sil
        </Button>
      </div>
    </>
  );
};

export default DeleteCautionModal;
