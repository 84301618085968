import { ReactComponent as EditSVG } from "src/assets/vectors/edit.svg";
import { ReactComponent as TrashSVG } from "src/assets/vectors/trash.svg";
import { EPermissions } from "src/enums/e_permissions";
import { useAppDispatch } from "src/hooks/redux";
import usePermission from "src/hooks/usePermission";
import t from "src/hooks/useTranslate";
import Shimmer from "../Common/Shimmer";
import IconWithTooltip from "../Common/IconWithTooltip";

const TargetsTable = ({ data, onEditClick, onDeleteClick, isLoading }) => {
  const canEdit = usePermission([EPermissions.target_edit]);
  const canDelete = usePermission([EPermissions.target_delete]);
  return (
    <table className="w-full dark:text-white">
      <thead className="text-left">
        <tr className="[&>th]:text-left [&>th]:pb-6 [&>th]:px-2 [&>th]:whitespace-nowrap border-b border-solid border-gray">
          <th className="w-28 !pl-4">ID</th>
          <th className="w-1/3">{t("group")}</th>
          <th className="w-1/3">{t("detailed")}</th>
          <th className="w-32 !pr-4">{t("operations")}</th>
        </tr>
      </thead>
      <tbody>
        {data && !isLoading
          ? data?.map((target) => (
              <tr
                className="relative [&>td]:py-4 [&>td]:px-2 after:absolute after:top-0 after:left-0 after:w-full after:h-[1px] after:bg-gray after:opacity-50"
                key={target.id}
              >
                <td className="!pl-4">{target.id}</td>
                <td>{target.role.name}</td>
                <td>{`${t("day")}: ${target.daily} / ${t("month")}: ${
                  target.monthly
                }`}</td>
                <td className="!pr-4">
                  <div className="flex items-center gap-x-1">
                    {canEdit && (
                      <IconWithTooltip
                        icon={<EditSVG className="text-blue" />}
                        tooltip={t("edit")}
                        onClick={() => onEditClick(target)}
                      />
                    )}
                    {canDelete && (
                      <IconWithTooltip
                        icon={<TrashSVG className="text-red" />}
                        tooltip={t("delete")}
                        onClick={() => onDeleteClick(target.id)}
                      />
                    )}
                  </div>
                </td>
              </tr>
            ))
          : [...Array(5)].map((_, i) => (
              <tr
                className="relative [&>td]:py-4 [&>td]:px-2 after:absolute after:top-0 after:left-0 after:w-full after:h-[1px] after:bg-gray after:opacity-50"
                key={i}
              >
                <td>
                  <Shimmer className="h-10.5" />
                </td>
                <td>
                  <Shimmer className="h-10.5" />
                </td>
                <td>
                  <Shimmer className="h-10.5" />
                </td>
                <td>
                  <Shimmer className="h-10.5" />
                </td>
              </tr>
            ))}
      </tbody>
    </table>
  );
};

export default TargetsTable;
