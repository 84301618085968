import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { getCookie } from "src/helper/custom_cookies"

export const blacklistService = createApi({
    reducerPath: "blacklistService",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_BASE_URL,
        prepareHeaders: (headers) => {
            headers.set("Authorization", getCookie("mcrmtkn") ? "Bearer " + getCookie("mcrmtkn") : "")
            headers.set('Accept', 'application/json')
            return headers
        },
        // credentials: "include",
    }),
    tagTypes: ["Blacklist"],
    endpoints: (builder) => ({
        createBlacklist: builder.mutation({
            query: (body) => {
                return {
                    url: "/blacklist",
                    method: "post",
                    body,
                }
            },
            invalidatesTags: ["Blacklist"]
        }),
        updateBlacklist: builder.mutation({
            query: ({id, body}) => {
                return {
                    url: `/blacklist/${id}`,
                    method: "post",
                    body,
                }
            },
            invalidatesTags: ["Blacklist"]
        }),
        deleteBlacklist: builder.mutation({
            query: (id) => {
                return {
                    url: `/blacklist/${id}`,
                    method: "delete"
                }
            },
            invalidatesTags: ["Blacklist"]
        }),
        blacklistAll: builder.query({
            query: (query) => {
                return {
                    url: `/blacklist${query}`,
                }
            },
            providesTags: ["Blacklist"]
        }),
    })
})

export const { useCreateBlacklistMutation, useUpdateBlacklistMutation, useDeleteBlacklistMutation, useBlacklistAllQuery } = blacklistService