import Button from "src/components/Common/Inputs/Button";
import Header from "src/components/Header/Header";
import { ReactComponent as EditSVG } from "src/assets/vectors/edit.svg";
import { ReactComponent as TrashSVG } from "src/assets/vectors/trash.svg";
import { ReactComponent as PlusSVG } from "src/assets/vectors/plus.svg";
import { ReactComponent as InfoSVG } from "src/assets/vectors/info.svg";
import t from "src/hooks/useTranslate";
import Input from "src/components/Common/Inputs/Input";
import InputPhone from "src/components/Common/Inputs/InputPhone";
import InputSearch from "src/components/Common/Inputs/InputSearch";
import Modal from "src/components/Common/Modal";
import { useEffect, useState } from "react";
import DeleteCautionModal from "src/components/Common/DeleteCautionModal";
import CreateEditModal from "src/components/Blacklist/CreateEditModal";
import {
  useBlacklistAllQuery,
  useCreateBlacklistMutation,
  useDeleteBlacklistMutation,
  useUpdateBlacklistMutation,
} from "src/services/blacklistService";
import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import { successToast } from "src/store/Settings/toastStore";
import Shimmer from "src/components/Common/Shimmer";
import {
  resetBlacklistForm,
  setAllInputs,
} from "src/store/Blacklist/blacklistStore";

import { EFormNDeleteModal } from "src/enums/e_form_n_delete_modal";
import usePermission from "src/hooks/usePermission";
import { EPermissions } from "src/enums/e_permissions";
import Pagination from "src/components/Common/Pagination";
import IconWithTooltip from "src/components/Common/IconWithTooltip";

const initial_filter = {
  id: "",
  phone: "",
  comment: "",
  user: "",
};

const Blacklist = () => {
  const dispatch = useAppDispatch();
  const [params, setParams] = useState(new URLSearchParams());
  const [paramsData, setParamsData] = useState("");
  const [activeID, setActiveID] = useState(0);
  const [modal, setModal] = useState(false);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [filterData, setFilterData] = useState(initial_filter);
  const [modalType, setModalType] = useState(EFormNDeleteModal.create_edit);
  const canCreate = usePermission([EPermissions.black_list_create]);
  const canEdit = usePermission([EPermissions.black_list_edit]);
  const canDelete = usePermission([EPermissions.black_list_delete]);
  const blacklist_form = useAppSelector(
    (state) => state.blacklist.blacklist_form
  );
  const {
    data: blacklist,
    refetch,
    isFetching: blacklistLoading,
  } = useBlacklistAllQuery(params.toString() ? "?" + params.toString() : "");

  const [
    createBlacklist,
    { isLoading: isBlacklistCreating, isSuccess: isBlacklistCreated },
  ] = useCreateBlacklistMutation();

  const [
    deleteBlacklist,
    { isLoading: isBlacklistDeleting, isSuccess: isBlacklistDeleted },
  ] = useDeleteBlacklistMutation();

  const [
    updateBlacklist,
    { isLoading: isBlacklistUpdating, isSuccess: isBlacklistUpdated },
  ] = useUpdateBlacklistMutation();

  const onCreateEditBlacklist = async () => {
    if (activeID != 0) {
      await updateBlacklist({ id: activeID, body: blacklist_form });
    } else {
      await createBlacklist(blacklist_form);
    }
  };

  const onDeleteBlacklist = async () => {
    await deleteBlacklist(activeID);
  };

  useEffect(() => {
    if (isBlacklistCreated) {
      dispatch(successToast("Qara siyahıya əlavə olundu"));
      setModal(false);
    }
  }, [isBlacklistCreated]);

  useEffect(() => {
    if (isBlacklistUpdated) {
      dispatch(successToast("Qara siyahı dəyişdirildi"));
      setModal(false);
    }
  }, [isBlacklistUpdated]);

  useEffect(() => {
    if (isBlacklistDeleted) {
      dispatch(successToast("Qara siyahı silindi"));
      setModal(false);
    }
  }, [isBlacklistDeleted]);

  useEffect(() => {
    refetch();
  }, [setPage, setPerPage, filterData]);

  return (
    <>
      <Header title={t("blacklist")}>
        {canCreate && (
          <Button
            className="bg-green text-white flex items-center gap-x-1 ml-auto whitespace-nowrap"
            onClick={() => {
              setActiveID(0);
              dispatch(resetBlacklistForm());
              setModalType(EFormNDeleteModal.create_edit);
              setModal(true);
            }}
          >
            <PlusSVG /> {t("add")}
          </Button>
        )}
      </Header>
      <div className="min-h-[556px] w-full relative flex flex-col justify-between bg-white dark:bg-lightBlack rounded-xl drop-shadow-lg">
        <table className="w-full">
          <thead>
            <tr className="[&>th]:text-left [&>th]:py-6 [&>th]:px-2 [&>th]:dark:text-white [&>th]:whitespace-nowrap border-b border-solid border-gray">
              <th className="w-[10%] !pl-4">
                ID{" "}
                <Input
                  type="number"
                  placeholder={t("search")}
                  onDebouncedValue={(val) => {
                    params.delete("page");
                    setFilterData({ ...filterData, id: val });
                    params.set("id", val);
                  }}
                />
              </th>
              <th className="w-[25%]">
                {t("phone")}
                <Input
                  type="number"
                  placeholder="099 999 99 99"
                  onDebouncedValue={(val) => {
                    params.delete("page");
                    setFilterData({ ...filterData, phone: val });
                    params.set("phone", val);
                  }}
                />
              </th>
              <th className="w-[25%]">
                {t("comment")}{" "}
                <Input
                  placeholder={t("search")}
                  onDebouncedValue={(val) => {
                    params.delete("page");
                    setFilterData({ ...filterData, comment: val });
                    params.set("comment", val);
                  }}
                />
              </th>
              <th className="w-[25%]">
                {t("operator")}{" "}
                <Input
                  placeholder={t("search")}
                  onDebouncedValue={(val) => {
                    params.delete("page");
                    setFilterData({ ...filterData, user: val });
                    params.set("user", val);
                  }}
                />
              </th>
              <th className="w-[15%] !pr-4"></th>
            </tr>
          </thead>
          <tbody>
            {blacklist && !blacklistLoading ? (
              blacklist.data.length > 0 &&
              blacklist.data.map((block) => (
                <tr
                  className="relative [&>td]:py-4 [&>td]:px-2 [&>td]:dark:text-white after:absolute after:top-0 after:left-0 after:w-full after:h-[1px] after:bg-gray"
                  key={block.id}
                >
                  <td className="!pl-4">{block.id}</td>
                  <td>0{block.phone}</td>
                  <td>{block.comment}</td>
                  <td>{block.user}</td>
                  <td className="!pr-4">
                    <div className="flex items-center gap-x-1">
                      {canEdit && (
                        <IconWithTooltip
                          icon={<EditSVG className="text-blue" />}
                          tooltip={t("edit")}
                          onClick={() => {
                            setActiveID(block.id);
                            dispatch(
                              setAllInputs({
                                key: "phone",
                                value: block.phone,
                              })
                            );
                            dispatch(
                              setAllInputs({
                                key: "comment",
                                value: block.comment,
                              })
                            );
                            setModalType(EFormNDeleteModal.create_edit);
                            setModal(true);
                          }}
                        />
                      )}
                      {canDelete && (
                        <IconWithTooltip
                          icon={<TrashSVG className="text-red" />}
                          tooltip={t("delete")}
                          onClick={() => {
                            setActiveID(block.id);
                            setModalType(EFormNDeleteModal.delete);
                            setModal(true);
                          }}
                        />
                      )}
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <>
                {[...Array(perPage)].map((_, i) => (
                  <tr className="[&>td]:py-4 [&>td]:px-2" key={i}>
                    <td className="w-[10%]">
                      <Shimmer className="h-10.5" />
                    </td>
                    <td className="w-[25%]">
                      <Shimmer className="h-10.5" />
                    </td>
                    <td className="w-[25%]">
                      <Shimmer className="h-10.5" />
                    </td>
                    <td className="w-[25%]">
                      <Shimmer className="h-10.5" />
                    </td>
                    <td className="w-[15%]">
                      <Shimmer className="h-10.5" />
                    </td>
                  </tr>
                ))}
              </>
            )}
          </tbody>
        </table>
        {blacklist && !blacklist.data.length && (
          <div className="w-full h-[500px] p-4 flex items-center justify-center">
            <div className="w-fit flex items-center gap-x-3 p-4 rounded-xl bg-light dark:bg-softBlack text-gray dark:text-white">
              <InfoSVG />
              <p>Göstəriləcək hər hansı bir məlumat yoxdur</p>
            </div>
          </div>
        )}
        {blacklist?.meta.total > 5 && (
          <div className="w-full px-4 py-6">
            <Pagination
              page={Math.ceil(blacklist?.meta.total / perPage)}
              total={blacklist?.meta.total}
              perPage={perPage}
              pageChange={(e) => {
                setPage(e + 1);
                params.set("page", (e + 1).toString());
              }}
              setPerPage={(val) => {
                setPerPage(val);
                params.set("page", "1")
                params.set("per_page", val.toString());
              }}
            />
          </div>
        )}
      </div>
      <Modal
        visible={modal}
        setVisible={setModal}
        dialogClassName="min-w-[450px]"
      >
        {modalType === EFormNDeleteModal.create_edit ? (
          <CreateEditModal
            form={blacklist_form}
            setModal={setModal}
            onSubmit={onCreateEditBlacklist}
            isLoading={isBlacklistCreating || isBlacklistUpdating}
          />
        ) : (
          <DeleteCautionModal
            setModal={setModal}
            onSubmit={onDeleteBlacklist}
            isLoading={isBlacklistDeleting}
          />
        )}
      </Modal>
    </>
  );
};

export default Blacklist;
