import { useState, useEffect } from "react";
import Button from "./Inputs/Button";
import { ReactComponent as InfoSVG } from "src/assets/vectors/info.svg";

interface IsOperatorActiveAlertProps {
  onSubmit: () => void;
  isLoading: boolean;
}

const IsOperatorActiveAlert: React.FC<IsOperatorActiveAlertProps> = ({
  onSubmit,
  isLoading,
}) => {
  return (
    <div className="fixed inset-0 z-[100] bg-gray/30 flex justify-center items-center">
      <div className="bg-white dark:bg-softBlack rounded-md shadow-md max-w-md">
        <div className="flex items-center justify-center p-4 border-b border-solid border-gray">
          <p className="text-16 font-bold dark:text-white">
            Sessiya xəbərdarlığı
          </p>
        </div>
        <div className="flex flex-col items-center p-4 space-y-2">
          <div className="w-full h-[170px]">
            <img
              src="/assets/images/session_expired.png"
              alt="Session expired"
              className="w-full h-full object-contain"
            />
          </div>
          <div className="w-fit flex items-center gap-x-3 p-4 rounded-xl bg-light dark:bg-lightBlack text-gray dark:text-white">
            <InfoSVG className="w-6 h-6 min-w-fit" />
            <p>
              Nəsə baş verib? Hərəkətsiz olduğunuz üçün sessiya 1 dəqiqə sonra
              dayandırılacaq. Davam etmək üçün “sessiyanı davam etdir” düyməsini
              kliklə
            </p>
          </div>
          <Button
            className="w-full bg-blue text-white !mt-6"
            onClick={onSubmit}
            isLoading={isLoading}
          >
            Sessiyanı davam etdir
          </Button>
        </div>
      </div>
    </div>
  );
};

export default IsOperatorActiveAlert;
