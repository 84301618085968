import t from "src/hooks/useTranslate";
import InputSearch from "../../Common/Inputs/InputSearch";
import { ReactComponent as EyeSVG } from "src/assets/vectors/eye.svg";
import { ReactComponent as ExcelSVG } from "src/assets/vectors/file_excel.svg";
import Button from "../../Common/Inputs/Button";
import Shimmer from "../../Common/Shimmer";
import { useEffect, useState } from "react";
import { EAscDesc } from "src/enums/e_asc_desc";

import Pagination from "src/components/Common/Pagination";
import { Link } from "react-router-dom";
import { EIsActive } from "src/enums/e_is_active";

const DashboardInformationsTable = ({ data, isLoading, setParams }) => {
  // const [orderById, setOrderById] = useState(EAscDesc.asc);
  const [params, _] = useState(new URLSearchParams());
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);

  return (
    <div className="w-full relative bg-white dark:bg-lightBlack rounded-xl drop-shadow-lg">
      <div className="flex items-center p-4">
        <p className="text-16 font-bold dark:text-white">{t("informations")}</p>
        <div className="flex items-center gap-x-4 ml-auto">
          <InputSearch
            data={[]}
            className="min-w-[300px]"
            optionHidden
            placeholder={t("search") + "..."}
            onInput={(query: string) => {
              params.set("search", query);
              setParams("?" + params.toString());
            }}
            onChange={(e) => console.log(e)}
          />
          <Button className="w-full gap-x-2 bg-darkBlue dark:bg-blue text-white whitespace-nowrap">
            <ExcelSVG /> <p>{t("export_to_excel")}</p>
          </Button>
        </div>
      </div>
      <table className="min-w-[991px] w-full">
        <thead>
          <tr className="[&>th]:text-left [&>th]:px-2 [&>th]:py-4 [&>th]:dark:text-white [&>th]:whitespace-nowrap border-y border-solid border-gray">
            <th className="w-auto !pl-4">{t("salon")}</th>
            <th className="w-auto">{t("contacts")}</th>
            <th className="w-auto">{t("package_price")}</th>
            <th className="w-auto">{t("announcement_count")}</th>
            <th className="w-auto">{t("start_date")}</th>
            <th className="w-auto">{t("end_date")}</th>
            <th className="w-auto">{t("status")}</th>
            <th className="w-auto">{t("last_active_date")}</th>
            <th className="w-auto">{t("view_count")}</th>
            <th className="w-auto">{t("call_count")}</th>
            <th className="w-auto">{t("operations")}</th>
          </tr>
        </thead>
        <tbody>
          {data && !isLoading ? (
            data.data.length > 0 &&
            data.data.map((info) => (
              <tr
                className="relative [&>td]:py-4 [&>td]:px-2 [&>td]:dark:text-white after:absolute after:top-0 after:left-0 after:w-full after:h-[1px] after:bg-light after:opacity-50"
                key={info.id}
              >
                <td className="!pl-4">{info.name}</td>
                <td>
                  {info.phone?.map((phone, pIndex) => (
                    <p key={pIndex}>+{phone}</p>
                  ))}
                </td>
                <td>{info.package_price} AZN</td>
                <td>{info.announce_count}</td>
                <td>{info.start_date}</td>
                <td>{info.end_date}</td>
                <td>
                  {info.status === EIsActive.active
                    ? t("active")
                    : t("deactive")}
                </td>
                <td>{info.last_active_at}</td>
                <td>{info.view_count}</td>
                <td>{info.call_count}</td>
                <td className="!pr-4">
                  <Link to={`/mashin-al-autosalon/${info.id}`}>
                    <EyeSVG />
                  </Link>
                </td>
              </tr>
            ))
          ) : (
            <>
              {[...Array(10)].map((_, i) => (
                <tr
                  className="[&>td]:py-4 [&>td]:px-2 bg-white dark:bg-lightBlack"
                  key={i}
                >
                  <td className="w-auto">
                    <Shimmer className="h-10.5" />
                  </td>
                  <td className="w-auto">
                    <Shimmer className="h-10.5" />
                  </td>
                  <td className="w-auto">
                    <Shimmer className="h-10.5" />
                  </td>
                  <td className="w-auto">
                    <Shimmer className="h-10.5" />
                  </td>
                  <td className="w-auto">
                    <Shimmer className="h-10.5" />
                  </td>
                  <td className="w-auto">
                    <Shimmer className="h-10.5" />
                  </td>
                  <td className="w-auto">
                    <Shimmer className="h-10.5" />
                  </td>
                  <td className="w-auto">
                    <Shimmer className="h-10.5" />
                  </td>
                  <td className="w-auto">
                    <Shimmer className="h-10.5" />
                  </td>
                  <td className="w-auto">
                    <Shimmer className="h-10.5" />
                  </td>
                  <td className="w-auto">
                    <Shimmer className="h-10.5" />
                  </td>
                </tr>
              ))}
            </>
          )}
        </tbody>
      </table>
      {data?.meta.total > 5 && (
        <div className="w-full px-4 py-4 border-t border-solid border-gray">
          <Pagination
            page={Math.ceil(data?.meta.total / perPage)}
            total={data?.meta.total}
            perPage={perPage}
            pageChange={(e) => {
              setPage(e + 1);
              params.set("page", (e + 1).toString());
              setParams("?" + params.toString());
            }}
            setPerPage={(val) => {
              setPerPage(val);
              params.set("per_page", val.toString());
              setParams("?" + params.toString());
            }}
          />
        </div>
      )}
    </div>
  );
};

export default DashboardInformationsTable;
