import { ReactComponent as XSVG } from "src/assets/vectors/x.svg";
import { ReactComponent as TrashSVG } from "src/assets/vectors/trash.svg";
import { ReactComponent as UsersSvg } from "src/assets/vectors/users.svg";
import { ReactComponent as UserSvg } from "src/assets/vectors/user.svg";
import t from 'src/hooks/useTranslate';

const RequestSelectedsModal = ({data, setModal, onDelete}) => {
  return (
    <>
    <div className="flex items-center justify-between gap-x-4 dark:text-white p-4 border-b border-solid border-gray">
          <p className="text-16 font-bold">{t("selecteds")}</p>
          <XSVG
            className="text-gray cursor-pointer dark:text-white"
            onClick={() => setModal(false)}
          />
        </div>
        <div className="p-4 space-y-4">
          {data.map((assign) => (
            <div
              className="flex items-center justify-between gap-x-4 dark:text-white"
              key={assign.id + assign.name}
            >
              <div className="flex items-center gap-x-2">
                <p>
                  {assign.name}{" "}
                  {assign.type === "role" && `(${assign.users_count})`}
                </p>
                {assign.type === "role" ? (
                  <span className="h-6 rounded-full flex items-center gap-x-2 bg-blue px-4 py-1 ml-4 text-white">
                    <UsersSvg className="w-4 h-4" />
                    {t("group")}
                  </span>
                ) : (
                  assign.role && (
                    <span className="h-6 rounded-full flex items-center gap-x-2 bg-green px-4 py-1 ml-4 text-white">
                      <UserSvg className="w-4 h-4" /> {assign.role.name}
                    </span>
                  )
                )}
              </div>
              <TrashSVG
                className="text-red cursor-pointer"
                onClick={() => onDelete(assign)}
              />
            </div>
          ))}
        </div>
    </>
  )
}

export default RequestSelectedsModal