import { ERequestsSettingsType } from "src/enums/e_requests_settings_type";

export const RequestsSettingsData = [
    {
        "id": 1,
        "title": "title",
        "type": ERequestsSettingsType.titles
    },
    {
        "id": 2,
        "title": "project_name",
        "type": ERequestsSettingsType.projects
    },
    {
        "id": 3,
        "title": "request_subject",
        "type": ERequestsSettingsType.subjects
    },
    {
        "id": 4,
        "title": "tags",
        "type": ERequestsSettingsType.tags
    }
]