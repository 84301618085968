import { useState, useEffect } from "react";
import t from "src/hooks/useTranslate";
import Accordion from "src/components/Common/Accordion";
import { ReactComponent as UpChevronSVG } from "src/assets/vectors/up_chevron.svg";
import { ReactComponent as ProfileSVG } from "src/assets/vectors/profile.svg";
import { ReactComponent as ExitSVG } from "src/assets/vectors/exit.svg";
import { ReactComponent as XSVG } from "src/assets/vectors/x.svg";
import { Link, useNavigate } from "react-router-dom";
import { useLogoutUserMutation } from "src/services/authService";
import { eraseCookie, getCookie } from "src/helper/custom_cookies";
import { errorToast, successToast } from "src/store/Settings/toastStore";
import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import { EGender } from "src/enums/e_gender";
import Modal from "../Common/Modal";
import Button from "../Common/Inputs/Button";

const SidebarUser = () => {
  let navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [modal, setModal] = useState(false);
  const [accordionOpen, setAccordionOpen] = useState(true);
  const user: any = useAppSelector((state) => state.profile.user);
  const [
    logoutUser,
    {
      isSuccess: isLogoutSuccess,
      isLoading,
      error: logoutError,
      isError: isLogoutError,
    },
  ] = useLogoutUserMutation();

  const logoutHandler = async () => {
    await logoutUser("");
  };

  useEffect(() => {
    if (isLogoutSuccess) {
      eraseCookie("mcrmtkn");
      navigate("/login");
    }
  }, [isLogoutSuccess]);

  useEffect(() => {
    if (isLogoutError) {
      //@ts-ignore
      if (logoutError.status == 401) {
        navigate("/login");
      } else {
        dispatch(errorToast("Login error"));
      }
    }
  }, [isLogoutError]);

  return (
    <>
      <div className="px-4 pt-6 pb-4 bg-white dark:bg-softBlack">
        <Accordion
          defaultOpen={accordionOpen}
          accordionButton={
            <div
              className="flex items-center text-left"
              onClick={() => setAccordionOpen(!accordionOpen)}
            >
              <div className="h-8 min-w-[32px] rounded-full overflow-hidden">
                <img
                  src={
                    user.avatar
                      ? user.avatar
                      : user.gender === EGender.male
                      ? "/assets/images/male_avatar.png"
                      : "/assets/images/female_avatar.png"
                  }
                  alt={user.full_name}
                  className="w-full max-h-full bg-red-700"
                />
              </div>
              <div className="mx-2.5">
                <h2 className="leading-4 dark:text-white">{user.full_name}</h2>
                <p className="text-10 dark:text-white">{user.main_role.name}</p>
              </div>
              <UpChevronSVG
                className={[
                  "w-3 text-darkBlue dark:text-white ml-auto mr-2 duration-300",
                  accordionOpen ? "" : "rotate-180",
                ].join(" ")}
              />
            </div>
          }
        >
          <ul>
            <Link to="/profile">
              <li className="text-darkBlue dark:text-white flex gap-x-3.5 px-4 py-2 rounded mt-4 mb-2 hover:bg-light dark:hover:bg-lightBlack">
                <ProfileSVG /> {t("my_profile")}
              </li>
            </Link>
            <li
              className="text-red flex gap-x-3.5 px-4 py-2 rounded cursor-pointer hover:bg-light dark:hover:bg-lightBlack"
              onClick={() => setModal(true)}
            >
              <ExitSVG /> {t("exit")}
            </li>
          </ul>
        </Accordion>
      </div>
      <Modal
        visible={modal}
        setVisible={setModal}
        dialogClassName="min-w-[450px]"
      >
        <div className="flex items-center justify-between gap-x-4 p-4 border-b border-solid border-gray">
          <p className="text-16 font-bold dark:text-white">
            {t("exit_caution_question")}
          </p>
          <XSVG
            className="text-gray cursor-pointer dark:text-white"
            onClick={() => setModal(false)}
          />
        </div>
        <div className="p-4">
          <Button
            className="w-full bg-red mt-8 text-white text-16 font-medium"
            onClick={logoutHandler}
            isLoading={isLoading}
          >
            {t("exit")}
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default SidebarUser;
