import { useEffect, useState } from "react";
import Checkbox from "./Inputs/Checkbox";
import { useAppSelector } from "src/hooks/redux";

export type CheckboxDto = {
  id: number;
  parent_id: number | null;
  label: string;
  child: CheckboxType[];
};

export type CheckboxType = CheckboxDto & {
  indeterminate?: boolean;
};

const findNestedObj = (entireObj: any, keyToFind: string, valToFind: any) => {
  let foundObj: any;
  JSON.stringify(entireObj, (_, nestedValue) => {
    if (nestedValue && nestedValue[keyToFind] === valToFind) {
      foundObj = nestedValue;
    }
    return nestedValue;
  });
  return foundObj;
};

let checkedCheckboxes: number[] = [];
let current: CheckboxType;
let parent: CheckboxType;
let checkboxChildren: CheckboxType[];

type Props = {
  data: CheckboxDto;
};

const NestedCheckboxes = ({ data }: Props) => {
  const [checkboxes, setCheckboxes] = useState<CheckboxType[]>([]);
  const me = useAppSelector((state) => state.profile.user);
  const [checkedCheckboxesState, setCheckedCheckboxesState] = useState<
    number[]
  >([]);
  const [triggered, setTriggered] = useState<boolean>(false);

  useEffect(() => {
    setCheckboxes([JSON.parse(JSON.stringify(data))]);
    checkedCheckboxes = me.all_permissions.map((p) => p.id);
  }, [me]);

  const handleCheckboxChange = (checkbox: CheckboxType) => {
    const currentState = findNestedObj(checkboxes, "id", checkbox.id);
    const parentState = findNestedObj(checkboxes, "id", checkbox.parent_id);

    current = currentState;
    parent = parentState;
    checkboxChildren = currentState.child;
    setTriggered(!triggered);

    if (!checkedCheckboxes.includes(checkbox.id)) {
      checkedCheckboxes = [...checkedCheckboxes, checkbox.id];
      if (
        checkbox.hasOwnProperty("indeterminate") &&
        checkbox.indeterminate === true
      ) {
        checkbox.indeterminate = false;
      }
    } else {
      checkedCheckboxes = checkedCheckboxes.filter((id) => id !== checkbox.id);
    }
  };

  const checkParents = (parent: CheckboxType) => {
    if (parent) {
      if (
        parent.child?.every((child) => checkedCheckboxes.includes(child.id))
      ) {
        checkedCheckboxes = [...checkedCheckboxes, parent?.id];
        if (parent?.parent_id !== null) {
          const grandParent = findNestedObj(
            checkboxes,
            "id",
            parent?.parent_id
          );
          parent = grandParent;
          checkParents(grandParent);
        }
      } else {
        checkedCheckboxes = checkedCheckboxes.filter((id) => id !== parent?.id);
        if (parent?.parent_id !== null) {
          const grandParent = findNestedObj(
            checkboxes,
            "id",
            parent?.parent_id
          );
          parent = grandParent;
          checkParents(grandParent);
        }
      }
    }
  };

  const checkChildren = (checkboxChildren: CheckboxType[]) => {
    if (checkboxChildren?.length > 0) {
      if (checkedCheckboxes.includes(current?.id)) {
        let grandChildren = [];
        checkboxChildren.forEach((child) => {
          checkedCheckboxes = [...checkedCheckboxes, child?.id];
          if (child?.child?.length > 0) {
            grandChildren.push(...child?.child);
          }
        });
        if (grandChildren.length > 0) {
          checkboxChildren = grandChildren;
          checkChildren(grandChildren);
        }
      } else {
        let grandChildren = [];
        checkboxChildren.forEach((child) => {
          checkedCheckboxes = checkedCheckboxes.filter(
            (id) => id !== child?.id
          );
          if (child?.child?.length > 0) {
            grandChildren.push(...child?.child);
          }
        });
        if (grandChildren.length > 0) {
          checkboxChildren = grandChildren;
          checkChildren(grandChildren);
        }
      }
    }
  };

  const checkIndeterminates = (parent: CheckboxType) => {
    if (parent) {
      if (parent.indeterminate === true) {
        parent.indeterminate = false;
      }
      if (
        parent.child?.some(
          (child: CheckboxType) => child.indeterminate === true
        ) ||
        parent.child?.some((child: CheckboxType) =>
          checkedCheckboxes.includes(child?.id)
        )
      ) {
        if (!checkedCheckboxes.includes(parent.id)) {
          parent.indeterminate = true;
        }
      }
      if (parent?.parent_id !== null) {
        const grandParent = findNestedObj(checkboxes, "id", parent?.parent_id);
        parent = grandParent;
        checkIndeterminates(grandParent);
      }
    }
  };

  useEffect(() => {
    checkParents(parent);
    checkChildren(checkboxChildren);
    setCheckedCheckboxesState(checkedCheckboxes);
    checkIndeterminates(parent);
  }, [triggered, me]);

  const renderCheckboxes = (checkboxes: CheckboxType[]) => {
    return (
      <ul className="ml-4">
        {checkboxes.map((checkbox) => (
          <li key={checkbox.id}>
            <Checkbox
              id={checkbox.id}
              isChecked={checkedCheckboxesState.includes(checkbox.id)}
              indeterminate={checkbox.indeterminate || false}
              label={checkbox.label + " (" + checkbox.id + ")"}
              onChange={() => handleCheckboxChange(checkbox)}
              key={checkbox.id}
            />

            {checkbox.child &&
              checkbox.child.length > 0 &&
              renderCheckboxes(checkbox.child)}
          </li>
        ))}
      </ul>
    );
  };
  return renderCheckboxes(checkboxes);
};

export default NestedCheckboxes;
