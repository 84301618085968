import { useEffect, useMemo, useState } from "react";
import { useAppDispatch } from "src/hooks/redux";
import t from "src/hooks/useTranslate";
import { setWorkingSchedule } from "src/store/Operators/operatorsStore";
import Select from "../Common/Select";
import hours from "src/data/hours_half_interval_data.json";
import { ReactComponent as CheckedSVG } from "src/assets/vectors/checked.svg";

const WorkingHoursItem = ({ data, value = [], setTime, onDeactivate }: any) => {
  const [startDate, setStartDate] = useState({ id: 0, name: "" });
  const [endDate, setEndDate] = useState({ id: 0, name: "" });

  const [active, setActive] = useState(false);

  useEffect(() => {
    if (value.length) {
      setStartDate({
        id: hours?.find((el) => el.name == value[0])?.id,
        name: value[0],
      });
      setEndDate({
        id: hours?.find((el) => el.name == value[1])?.id,
        name: value[1],
      });
      setActive(true);
    } else {
      setStartDate({
        id: 0,
        name: "",
      });
      setEndDate({
        id: 0,
        name: "",
      });
      setActive(false);
    }
  }, [value]);

  return (
    <div className={"flex items-center gap-x-4"}>
      <div
        className="flex items-center gap-x-2 cursor-pointer"
        onClick={() => {
          active && onDeactivate();
          setActive(!active);
        }}
      >
        {active ? (
          <CheckedSVG className="text-green w-[18px] h-[18px]" />
        ) : (
          <div className="min-w-[18px] h-[18px] rounded border border-solid border-red"></div>
        )}

        <p
          className={`w-36 font-medium ${
            active ? "text-darkBlue dark:text-white" : "text-red"
          }`}
        >
          {t(data.name)}
        </p>
      </div>
      {active ? (
        <>
          <Select
            data={
              endDate.id != 0
                ? hours.slice(
                    0,
                    hours.map((hour) => hour.id).indexOf(endDate.id)
                  )
                : hours
            }
            defaultTitle={t("choose_start_date")}
            defaultValue={value.length && value[0]}
            onChange={(e) => {
              setStartDate(e);
              setTime([e.name, endDate.name]);
            }}
          />
          <Select
            data={
              startDate.id != 0
                ? hours.slice(
                    hours.map((hour) => hour.id).indexOf(startDate.id) + 1,
                    hours.length
                  )
                : hours
            }
            defaultTitle={t("choose_end_date")}
            defaultValue={value.length && value[1]}
            onChange={(e) => {
              setEndDate(e);
              setTime([startDate.name, e.name]);
            }}
          />
        </>
      ) : (
        <div
          className="w-full h-10.5 px-4 col-span-2 border border-solid border-red rounded"
          onClick={() => console.log(value)}
        >
          <p className="text-red leading-[40px]">{t("day_off")}</p>
        </div>
      )}
    </div>
  );
};

export default WorkingHoursItem;
