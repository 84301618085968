import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getCookie } from "src/helper/custom_cookies";

export const mashinAlDashboardService = createApi({
  reducerPath: "mashinAlDashboardService",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: (headers) => {
      headers.set(
        "Authorization",
        getCookie("mcrmtkn") ? "Bearer " + getCookie("mcrmtkn") : ""
      );
      headers.set("Accept", "application/json");
      return headers;
    },
    // credentials: "include",
  }),
  tagTypes: ["MashinAl"],
  endpoints: (builder) => ({
    mashinAlStatistics: builder.query({
      query: (query) => {
        return {
          url: `/mashinal/announces${query}`,
        };
      },
      providesTags: ["MashinAl"],
    }),
    mashinAlAcceptedAnnouncements: builder.query({
      query: () => {
        return {
          url: "/mashinal/accepted_announcements",
        };
      },
      providesTags: ["MashinAl"],
    }),
    mashinAlModeratorAnnounceCount: builder.query({
      query: (query) => {
        return {
          url: `/mashinal/moderator_announcements${query}`,
        };
      },
      providesTags: ["MashinAl"],
    }),
    mashinAlModerationAverage: builder.query({
      query: (query) => {
        return {
          url: `/mashinal/moderator_times${query}`,
        };
      },
      providesTags: ["MashinAl"],
    }),
    mashinAlModerators: builder.query({
      query: (query) => {
        return {
          url: `/mashinal/moderators${query}`,
        };
      },
      providesTags: ["MashinAl"],
    }),
    mashinAlLateModerators: builder.query({
      query: (query) => {
        return {
          url: `/mashinal/delay_moderators${query}`,
        };
      },
      providesTags: ["MashinAl"],
    }),
    mashinAlWaitingAnnouncements: builder.query({
      query: (query) => {
        return {
          url: `/mashinal/pending_announces${query}`,
        };
      },
      providesTags: ["MashinAl"],
    }),
    mashinAlGetMessagesGroups: builder.query({
      query: (query) => {
        return {
          url: `/mashinal/getMessageGroups${query}`,
        };
      },
      providesTags: ["MashinAl"],
    }),
    mashinAlGetMessageById: builder.query({
      query: (id) => {
        return {
          url: `/mashinal/getMessagesByGroups/${id}`,
        };
      },
      providesTags: ["MashinAl"],
    }),

    checkSmsRadarByCarNumber: builder.mutation({
      query: (query: any) => {
        return {
          url: `/mashinal/check_sms_radar_by_car_number?${query}`,
          method: "get",
        };
      },
      // invalidatesTags: ["Users"]
    }),
    checkSmsRadarByVinNumber: builder.mutation({
      query: (query: any) => {
        return {
          url: `/mashinal/check_sms_radar_by_vin?${query}`,
          method: "get",
        };
      },
      // invalidatesTags: ["Users"]
    }),

    // autoHistoryLogs: builder.mutation({
    //   query: () => {
    //     return {
    //       url: `/mashinal/fetch_auto_history_logs`,
    //       method: "get",
    //     };
    //   },
    //   // invalidatesTags: ["Users"]
    // }),

    autoHistoryLogs: builder.query({
      query: (query: any) => {
        return {
          url: `/mashinal/fetch_auto_history_logs${query}`,
        };
      },
      providesTags: ["MashinAl"],
    }),

    // checkSmsRadarByCarNumber: builder.query({
    //   query: (query) => {
    //     return {
    //       url: `check_sms_radar_by_car_number?${query}`,
    //     };
    //   },
    //   //   providesTags: ["MashinAl"],
    // }),
    // checkSmsRadarByVinNumber: builder.query({
    //   query: (query) => {
    //     return {
    //       url: `/mashinal/check_sms_radar_by_vin?${query}`,
    //     };
    //   },
    //   //   providesTags: ["MashinAl"],
    // }),
    // mashinalAppGetUrlById: builder.query({
    //     query: ({ id, type }) => {
    //         return {
    //             url: `mashinal/getUrlById/${id}/${type}`
    //         }
    //     },
    // }),
  }),
});

export const {
  useMashinAlStatisticsQuery,
  useMashinAlAcceptedAnnouncementsQuery,
  useMashinAlModeratorAnnounceCountQuery,
  useMashinAlModerationAverageQuery,
  useMashinAlModeratorsQuery,
  useMashinAlLateModeratorsQuery,
  useMashinAlWaitingAnnouncementsQuery,
  useMashinAlGetMessagesGroupsQuery,
  useMashinAlGetMessageByIdQuery,
  useCheckSmsRadarByCarNumberMutation,
  useCheckSmsRadarByVinNumberMutation,
  useAutoHistoryLogsQuery,
} = mashinAlDashboardService;
