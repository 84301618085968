export enum EPermissions {
  user = "user",
  user_list = "user.list",
  user_create = "user.create",
  user_edit = "user.edit",
  user_session_will_expire = "user.session_will_expire",
  user_delete = "user.delete",
  group = "group",
  group_list = "group.list",
  group_create = "group.create",
  group_edit = "group.edit",
  group_delete = "group.delete",
  time_off = "time_off",
  time_off_list = "time_off.list",
  time_off_status_update = "time_off.status_update",
  time_off_status_approve = "time_off.status_approve",
  vacations = "vacations",
  vacations_list = "vacations.list",
  vacations_status_update = "vacations.status_update",
  vacations_status_approve = "vacations.status_approve",
  call_center = "call_center",
  call_center_list = "call_center.list",
  call_center_working_hours = "call_center.working_hours",
  call_center_create = "call_center.create",
  call_center_edit = "call_center.edit",
  call_center_delete = "call_center.delete",
  announce = "announce",
  announce_list = "announce.list",
  announce_create = "announce.create",
  announce_voice_list = "announce.voice_list",
  announce_voice_add_note = "announce.voice_add_note",
  announce_edit = "announce.edit",
  announce_delete = "announce.delete",
  shift_change = "shift_change",
  shift_change_list = "shift_change.list",
  shift_change_change = "shift_change.change",
  black_list = "black_list",
  black_list_list = "black_list.list",
  black_list_create = "black_list.create",
  black_list_edit = "black_list.edit",
  black_list_delete = "black_list.delete",
  ticket_close = "ticket.close",
  ticket_delete = "ticket.delete",
  ticket_settings = "ticket_settings",
  ticket_settings_list = "ticket_settings.list",
  ticket_settings_create = "ticket_settings.create",
  ticket_settings_edit = "ticket_settings.edit",
  ticket_settings_delete = "ticket_settings.delete",
  faq = "faq",
  faq_list = "faq.list",
  faq_create = "faq.create",
  faq_edit = "faq.edit",
  faq_delete = "faq.delete",
  faq_category = "faq_category",
  faq_category_create = "faq_category.create",
  faq_category_edit = "faq_category.edit",
  faq_category_delete = "faq_category.delete",
  target = "target",
  target_list = "target.list",
  target_create = "target.create",
  target_edit = "target.edit",
  target_delete = "target.delete",
  report = "report",
  report_list = "report.list",
  finance = "finance",
  finance_mashin_al = "finance.mashin_al",
  mashin_al = "mashin_al",
  mashin_al_list = "mashin_al.list",
  call_list_list = "call_list",
  // ringo_stat = "ringostat",
  ringo_stat_list = "ringostat.list",
  mashin_al_auto_history = "mashin_al.auto_history",
}
