import { ReactComponent as UserSVG } from "src/assets/vectors/user.svg";
import { ReactComponent as UserPermissionSVG } from "src/assets/vectors/user_permission.svg";
import { ReactComponent as FileSVG } from "src/assets/vectors/file.svg";
import { ReactComponent as LockSVG } from "src/assets/vectors/lock.svg";
import { EUsersCrudSteps } from "src/enums/e_users_crud_steps";
import { ITabStep } from "src/types/Common/i_tab_step";

export const UsersStepsData: ITabStep[] = [
    {
        id: EUsersCrudSteps.form,
        title: "users_edit",
        icon: UserSVG
    },
    {
        id: EUsersCrudSteps.permissions,
        title: "access_permissions",
        icon: UserPermissionSVG
    },
    {
        id: EUsersCrudSteps.password,
        title: "password",
        icon: LockSVG
    },
    {
        id: EUsersCrudSteps.files,
        title: "files",
        icon: FileSVG
    },
];
