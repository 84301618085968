import React, { FC, useEffect, useRef, useState } from "react";
import FlipNumbers from "react-flip-numbers";
import { ReactSortable } from "react-sortablejs";
import CountDownAnimated from "src/components/Common/CountDownAnimated";
import CountDownAnimationed from "src/components/Common/CountDownAnimated";
import IconWithTooltip from "src/components/Common/IconWithTooltip";
import { ReactComponent as EyeSVG } from "src/assets/vectors/eye.svg";
import NestedCheckboxes, {
  CheckboxType,
} from "src/components/Common/NestedCheckboxes";
import { useGetGroupAndPermissionsQuery } from "src/services/groupAndPermissionsService";
import Checkbox from "src/components/Common/Inputs/Checkbox";
import { useAppSelector } from "src/hooks/redux";

interface ItemType {
  id: number;
  name: string;
}

const mockData: CheckboxType = {
  id: 26,
  parent_id: null,
  label: "Miayu All Checkbox",
  child: [
    {
      id: 0,
      parent_id: 26,
      label: "All Checkbox",
      child: [
        {
          id: 1,
          parent_id: 0,
          label: "Parent Checkbox 1",
          child: [
            {
              id: 2,
              parent_id: 1,
              label: "Child Checkbox 1",
              child: [
                {
                  id: 14,
                  parent_id: 2,
                  label: "Grandchild Checkbox 1",
                  child: [],
                },
              ],
            },
            {
              id: 3,
              parent_id: 1,
              label: "Child Checkbox 2",
              child: [
                {
                  id: 4,
                  parent_id: 3,
                  label: "Grandchild Checkbox 2",
                  child: [],
                },
              ],
            },
          ],
        },
        {
          id: 5,
          parent_id: 0,
          label: "Parent Checkbox 2",
          child: [
            {
              id: 6,
              parent_id: 5,
              label: "Child Checkbox 3",
              child: [
                {
                  id: 7,
                  parent_id: 6,
                  label: "Grandchild Checkbox 3",
                  child: [
                    {
                      id: 8,
                      parent_id: 7,
                      label: "GrandGrandchild Checkbox 3",
                      child: [],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

const Test: FC = () => {
  const { data: groupAndPermissions, isSuccess } =
    useGetGroupAndPermissionsQuery("");
  const me = useAppSelector((state) => state.profile.user);
  const [state, setState] = useState<ItemType[]>([
    { id: 1, name: "shrek" },
    { id: 2, name: "fiona" },
  ]);

  return (
    <>
      <CountDownAnimated hours={3} minutes={2} />
      <ReactSortable list={state} setList={setState} multiDrag>
        {state.map((item) => (
          <div className="p-4 w-1/2 bg-red rounded mb-2" key={item.id}>
            {item.name}
          </div>
        ))}
      </ReactSortable>
      <IconWithTooltip icon={<EyeSVG />} tooltip="" />
      <NestedCheckboxes data={mockData} />
      {/* {isSuccess && (
        <NestedCheckboxes
          data={{
            id: 0,
            parent_id: null,
            label: "All Checkbox",
            child: [...groupAndPermissions.permissions],
          }}
        />
      )} */}
    </>
  );
};

export default Test;
