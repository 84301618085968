import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import t from "src/hooks/useTranslate";
import { ReactComponent as ImageSVG } from "src/assets/vectors/image.svg";
import { ReactComponent as InfoSVG } from "src/assets/vectors/info.svg";
import Select from "src/components/Common/Select";
import Button from "../Common/Inputs/Button";
import Checkbox from "../Common/Inputs/Checkbox";
import {
  useGetOperatorsQuery,
  useUpdateOperatorWorkingHoursMutation,
} from "src/services/operatorsService";
import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import {
  setAllInputs,
  setOperator,
  setWorkingSchedule,
} from "src/store/Operators/operatorsStore";
import WorkingHoursItem from "./WorkingHoursItem";
import weekdays from "src/data/weekdays_data.json";
import { Link, useNavigate } from "react-router-dom";
import InputSearch from "../Common/Inputs/InputSearch";
import { EIsActive } from "src/enums/e_is_active";
import { EGender } from "src/enums/e_gender";
import { useGetUsersPartiallyMutation } from "src/services/usersService";
import Shimmer from "../Common/Shimmer";

const OperatorWorkScheduleInner = ({
  defaultOperator,
  operator,
  onSubmit,
  isLoading,
  isDefaultOperatorFetching,
}) => {
  const dispatch = useAppDispatch();
  const workingHours = useAppSelector(
    (state) => state.operators.operator.working_hours
  );
  const navigate = useNavigate();
  const [avatar, setAvatar] = useState(false);
  const [selected, setSelected] = useState({ id: 0, name: "" });
  const [status, setStatus] = useState(EIsActive.deactive);
  const [isHidden, setIsHidden] = useState(EIsActive.deactive);
  const [getOperators, { data: operators, isLoading: isOperatorsLoading }] =
    useGetUsersPartiallyMutation();
  const limit = 40;

  useEffect(() => {
    if (selected.id !== 0) {
      dispatch(setOperator(selected));
    }
  }, [selected]);

  let total = useMemo(() => {
    let count = 0;
    workingHours.forEach((hours: any) => {
      if (hours.length !== 0) {
        if (hours[0] && hours[1]) {
          let calculated =
            Number(hours[1].split(":")[0]) +
            Number(hours[1].split(":")[1]) / 60 -
            (Number(hours[0].split(":")[0]) +
              Number(hours[0].split(":")[1]) / 60);
          count += calculated > 4 ? calculated - 1 : calculated;
        }
      }
    });
    return Math.round(count * 10) / 10;
  }, [workingHours]);

  const fetchOperators = useCallback(async (search) => {
    await getOperators(`?role=55&search=${search}&pagination=0`);
  }, []);

  useEffect(() => {
    if (defaultOperator) {
      setSelected(defaultOperator);
      setIsHidden(defaultOperator.is_hidden);
      setStatus(defaultOperator.status);
    }
  }, [defaultOperator]);

  return (
    <div className="w-full relative flex bg-white dark:bg-lightBlack rounded-xl drop-shadow-lg">
      <div className="w-1/3 flex flex-col gap-y-4 bg-light dark:bg-softBlack py-6 px-4 rounded-l-xl">
        <p className="text-gray">{t("avatar")}</p>
        <div className="flex justify-center items-center mx-auto w-24 h-24 rounded-full border border-solid border-gray text-gray overflow-hidden">
          {selected.id !== 0 ? (
            <img
              src={
                selected.name && selected["avatar"]
                  ? selected["avatar"]
                  : selected["gender"] === EGender.male
                  ? "/assets/images/male_avatar.png"
                  : "/assets/images/female_avatar.png"
              }
              className="w-full h-full object-cover"
              alt=""
            />
          ) : (
            <ImageSVG />
          )}
        </div>
        <InputSearch
          data={operators ? operators.data : null}
          placeholder={t("search") + "..."}
          showItemCount={5}
          isLoading={isOperatorsLoading}
          defaultValue={defaultOperator ? defaultOperator.full_name : null}
          onInput={(query: string) => fetchOperators(query)}
          onChange={(e) => setSelected(e)}
        />
        {selected.id !== 0 && (
          <div className="mt-auto space-y-4">
            <Checkbox
              id={1}
              label={t("deactivate_account")}
              isChecked={status === EIsActive.deactive}
              onChange={() => {
                setStatus(
                  status == EIsActive.active
                    ? EIsActive.deactive
                    : EIsActive.active
                );
                dispatch(
                  setAllInputs({
                    key: "operator",
                    name: "status",
                    value:
                      status == EIsActive.active
                        ? EIsActive.deactive
                        : EIsActive.active,
                  })
                );
              }}
            />
            <Checkbox
              id={2}
              label={t("hide_user")}
              isChecked={isHidden === EIsActive.active}
              onChange={() => {
                setIsHidden(
                  isHidden == EIsActive.active
                    ? EIsActive.deactive
                    : EIsActive.active
                );
                dispatch(
                  setAllInputs({
                    key: "operator",
                    name: "is_hidden",
                    value:
                      isHidden == EIsActive.active
                        ? EIsActive.deactive
                        : EIsActive.active,
                  })
                );
              }}
            />
          </div>
        )}
      </div>
      <div className="w-2/3 py-6 px-4">
        {isDefaultOperatorFetching ? (
          <div className="space-y-4">
            {[...Array(7)].map((_, i) => (
              <Shimmer className="h-10.5" key={i} />
            ))}
          </div>
        ) : selected.id === 0 ? (
          <div className="relative w-full h-full flex justify-center items-center">
            <div className="text-center text-gray font-medium space-y-2">
              <InfoSVG className="mx-auto w-10 h-10" />
              <p>
                İş saatlarını müəyyənləşdirmək üçün istifadəçi seçimini edin
              </p>
            </div>
            <Link
              to="/operators"
              className="h-10.5 !absolute px-4 flex justify-center items-center bottom-0 left-1/2 -translate-x-1/2 w-48 border border-solid border-gray text-gray rounded"
            >
              {t("return_back")}
            </Link>
          </div>
        ) : (
          <div className="space-y-4">
            <p className="font-bold dark:text-white">{t("work_schedule")}</p>
            <div className="space-y-2">
              {weekdays.map((week, i) => (
                <Fragment key={week.id}>
                  <WorkingHoursItem
                    data={week}
                    setTime={(value: string[]) =>
                      dispatch(setWorkingSchedule({ weekday: i, value }))
                    }
                    value={
                      operator.working_hours[i].length
                        ? operator.working_hours[i]
                        : []
                    }
                    onDeactivate={() =>
                      dispatch(setWorkingSchedule({ weekday: i, value: "" }))
                    }
                  />
                </Fragment>
              ))}
            </div>
            <div className="w-[calc(100%-186px)] h-10.5 px-4 flex justify-between items-center ml-auto bg-light dark:bg-softBlack dark:text-white rounded">
              <p>{t("weekly_working_hours")}</p>
              <p className={total > limit ? "!text-red" : "text-inherit"}>
                {total.toString()} {t("hour")}
              </p>
            </div>
            <div className="w-[calc(100%-186px)] flex items-center gap-x-4 ml-auto">
              <Button
                className="w-full flex justify-center items-center ml-auto duration-200 rounded border border-solid border-gray dark:text-white"
                onClick={() => navigate("/operators")}
              >
                {t("return_back")}
              </Button>
              <Button
                className="w-full flex justify-center items-center ml-auto duration-200 rounded bg-blue text-white disabled:bg-gray"
                onClick={onSubmit}
                isLoading={isLoading}
                disabled={total > limit}
              >
                {t("remember")}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default OperatorWorkScheduleInner;
