import { useState } from "react";
import Button from "./Inputs/Button";
import { ReactComponent as XSVG } from "src/assets/vectors/x.svg";
import t from "src/hooks/useTranslate";

const AdminConfirmModal = ({
  userName,
  setModal,
  setReason,
  onSubmit,
  isLoading,
  isNotification = false,
  data,
}) => {
  const [approveStatus, setApproveStatus] = useState(0);

  return (
    <>
      <div className="flex items-center justify-between gap-x-4 p-4 border-b border-solid border-gray">
        <p className="text-16 font-bold dark:text-white">{userName}</p>
        <XSVG
          className="text-gray cursor-pointer dark:text-white"
          onClick={() => setModal(false)}
        />
      </div>
      <div className="space-y-4 p-4">
        {isNotification && (
          <>
            <div className="space-y-1">
              <p className="dark:text-white">{t("permission_date")}</p>
              <div className="w-full h-10.5 px-4 flex items-center relative text-gray z-20 pointer-events-none rounded border border-gray after:absolute after:inset-0 after:bg-gray dark:after:bg-black after:opacity-50">
                {data?.date}
              </div>
            </div>
            {data?.comment && (
              <div className="space-y-1">
                <p className="dark:text-white">{t("reason")}</p>
                <div className="w-full min-h-[42px] px-4 flex items-center relative text-gray z-20 pointer-events-none rounded border border-gray after:absolute after:inset-0 after:bg-gray dark:after:bg-black after:opacity-50">
                  {data?.comment}
                </div>
              </div>
            )}
          </>
        )}
        <div className="space-y-1">
          <p className="dark:text-white">{t("comment")}</p>
          <div
            className={`relative  ${
              data && data?.status !== 1
                ? "pointer-events-none after:absolute after:inset-0 after:bottom-2 after:bg-gray dark:after:bg-black after:opacity-50 after:rounded"
                : ""
            }`}
          >
            <textarea
              className={`relative w-full p-4 border border-solid border-gray rounded resize-none dark:bg-softBlack dark:text-white`}
              placeholder="Səbəbi yazın"
              rows={5}
              disabled={data && data?.status !== 1}
              defaultValue={data?.note}
              onChange={(e) => setReason(e.target.value)}
            />
          </div>
        </div>
        <div className="flex gap-x-4">
          {data && data?.status !== 1 ? (
            <div
              className={`w-full h-10.5 px-4 flex justify-center items-center text-white rounded ${
                data?.status === 2 ? "bg-green" : "bg-red"
              }`}
            >
              {data?.status === 2 ? t("approved") : t("rejected")}
            </div>
          ) : (
            <>
              <Button
                className={`w-full bg-green text-white ${
                  isLoading && approveStatus == 2 ? "pointer-events-none" : ""
                }`}
                onClick={() => {
                  setApproveStatus(1);
                  onSubmit(2);
                }}
                isLoading={isLoading && approveStatus == 1}
              >
                {t("i_submit")}
              </Button>
              <Button
                className={`w-full bg-red text-white ${
                  isLoading && approveStatus == 1 ? "pointer-events-none" : ""
                }`}
                onClick={() => {
                  setApproveStatus(2);
                  onSubmit(3);
                }}
                isLoading={isLoading && approveStatus == 2}
              >
                {t("i_protest")}
              </Button>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default AdminConfirmModal;
