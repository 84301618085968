import { useEffect, useState } from "react";
import { ReactComponent as XSVG } from "src/assets/vectors/x.svg";
import { validatePhone } from "src/helper/validation";
import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import t from "src/hooks/useTranslate";
import { setAllInputs } from "src/store/Blacklist/blacklistStore";
import { phoneRequiredPattern } from "src/utils/validationPatterns";
import Button from "../Common/Inputs/Button";
import InputPhone from "../Common/Inputs/InputPhone";

const CreateEditModal = ({ form, setModal, onSubmit, isLoading }) => {
  const dispatch = useAppDispatch();
  const [validForm, setValidForm] = useState(false);
  useEffect(() => {
    if (validatePhone(form.phone!, true) && form.comment) {
      setValidForm(true);
    } else {
      setValidForm(false);
    }
  }, [form]);

  return (
    <>
      <div className="flex items-center justify-between gap-x-4 p-4 border-b border-solid border-gray">
        <p className="text-16 font-bold dark:text-white">
          {t("add_to_blacklist")}
        </p>
        <XSVG
          className="text-gray cursor-pointer dark:text-white"
          onClick={() => setModal(false)}
        />
      </div>
      <div className="p-4 space-y-4">
        <InputPhone
          validpattern={phoneRequiredPattern}
          label={t("phone") + "*"}
          value={form.phone ? form.phone : ""}
          onChange={(e) => dispatch(setAllInputs({ key: "phone", value: e }))}
          name="phone"
        />

        <div className="space-y-2">
          <p className="dark:text-white">{t("comment")}</p>
          <textarea
            className="w-full p-4 border border-solid border-gray rounded resize-none dark:bg-softBlack dark:text-white"
            placeholder="Səbəbi yazın"
            defaultValue={form.comment ? form.comment : ""}
            rows={5}
            maxLength={250}
            onChange={(e) =>
              dispatch(setAllInputs({ key: "comment", value: e.target.value }))
            }
            name="comment"
          />
        </div>
        <Button
          className="w-full bg-blue text-white disabled:bg-gray"
          onClick={onSubmit}
          disabled={!validForm}
          isLoading={isLoading}
        >
          {t("remember")}
        </Button>
      </div>
    </>
  );
};

export default CreateEditModal;
