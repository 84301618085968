import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { getCookie } from "src/helper/custom_cookies"
import {IAnnouncementResponse} from "../types/i_announcement";

export const announcementsService = createApi({
    reducerPath: "announcementsService",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_BASE_URL,
        prepareHeaders: (headers) => {
            localStorage.setItem("ssnxprd", "180")
            headers.set("Authorization", getCookie("mcrmtkn") ? "Bearer " + getCookie("mcrmtkn") : "")
            headers.set('Accept', 'application/json')
            return headers
        },
        // credentials: "include",
    }),
    tagTypes: ["Announcements"],
    endpoints: (builder) => ({
        announcements: builder.query({
            query: (query) => {
                return {
                    url: "/calls" + query,
                }
            },
            providesTags: ["Announcements"]
        }),
        announcementsNew: builder.query({
            query: (query) => {
                return {
                    url: "/calls/list" + query,
                }
            },
            providesTags: ["Announcements"]
        }),
        getOperators: builder.query({
            query: () => {
                return {
                    url: "/calls/operators",
                }
            },
            providesTags: ["Announcements"]
        }),
        showAnnouncements: builder.query({
            query: (id) => {
                return {
                    url: "/calls/show/" + id,
                }
            },
            providesTags: ["Announcements"]
        }),
        getNewAnnouncement: builder.mutation<IAnnouncementResponse, void>({
            query: () => {
                return {
                    url: "/calls/get/new",
                }
            },
        }),
        createAnnouncements: builder.mutation({
            query: ({ vehicle, ...body } : any) => {
                return {
                    url: "/calls/store",
                    method: "post",
                    body,
                }
            },
            invalidatesTags: ["Announcements"]
        }),
        updateAnnouncements: builder.mutation({
            query: (body: any) => {
                return {
                    url: `/calls/${body.id}/update`,
                    method: "post",
                    body: body.announcement,
                }
            },
            invalidatesTags: ["Announcements"]
        }),
        updateNewAnnouncements: builder.mutation({
            query: ({ id, ...body }: any) => {
                return {
                    url: `/calls/${id}/update/new`,
                    method: "post",
                    body,
                }
            },
            invalidatesTags: ["Announcements"]
        }),
        announcementRecording: builder.query({
            query: (id) => {
                return {
                    url: "/calls/recordings/" + id,
                }
            },
            providesTags: ["Announcements"]
        }),
        getRecordingFile: builder.mutation({
            query: (id) => {
                return {
                    url: "/calls/recording/" + id,
                    responseHandler: async (response) => await response.blob(),
                }
            },
        }),
        commentToVoice: builder.mutation({
            query: ({body, id}) => {
                return {
                    url: `/calls/update_voice/${id}`,
                    method: "post",
                    body,
                }
            },
        }),
        callToCustomer: builder.mutation({
            query(phone: string) {
                return {
                    url: `3cx/click_to_call/${phone}`,
                    method: "get",
                }
            }
        }),
    })
})
export const { useAnnouncementsQuery, useShowAnnouncementsQuery , useAnnouncementsNewQuery ,useGetNewAnnouncementMutation, useGetOperatorsQuery, useCreateAnnouncementsMutation, useUpdateAnnouncementsMutation, useUpdateNewAnnouncementsMutation , useAnnouncementRecordingQuery, useGetRecordingFileMutation, useCommentToVoiceMutation,useCallToCustomerMutation } = announcementsService
