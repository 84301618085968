import Header from "src/components/Header/Header";
import { ReactComponent as BackSVG } from "src/assets/vectors/back.svg";
import { ReactComponent as PlusSVG } from "src/assets/vectors/plus.svg";
import t from "src/hooks/useTranslate";
import { useNavigate } from "react-router-dom";
import FAQForm from "src/components/FAQ/FAQForm";
import Button from "src/components/Common/Inputs/Button";
import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import { addFAQForm } from "src/store/FAQ/FAQStore";
import {
  useCreateFaqMutation,
  useGetFaqSectionsQuery,
} from "src/services/faqService";
import { errorToast, successToast } from "src/store/Settings/toastStore";

const FAQCreate = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const faqForms = useAppSelector((state) => state.faq.faq_forms);
  const { data: sections, isFetching: isSectionsFetching } =
    useGetFaqSectionsQuery("");
  const [formCount, setFormCount] = useState(1);
  const [validForm, setValidForm] = useState(false);
  const [createFaq, { isLoading: isFaqCreating, isSuccess: isFaqCreated, isError: isFaqNotCreated, error: faqError }] =
    useCreateFaqMutation();

  const sectionData = useMemo(() => {
    let sectionData = [];
    sections?.forEach((element) => {
      sectionData.push({ id: element.id, name: element.title });
    });
    return sectionData;
  }, [sections]);

  const onCreateFaq = async () => {
    await createFaq(faqForms);
  };

  useEffect(() => {
    if (isFaqNotCreated) {
      //@ts-ignore
      dispatch(errorToast(faqError?.data.message));
    }
  }, [isFaqNotCreated]);

  useEffect(() => {
    if (
      faqForms.every((form) => form.title !== "") &&
      faqForms.every((form) => form.description !== "") &&
      // faqForms.every((form) =>
      //   form.quick_replies.some((reply) => reply.reply !== "")
      // ) &&
      faqForms.every((form) => form.category_id !== 0)
    ) {
      setValidForm(true);
    } else {
      setValidForm(false);
    }
  }, [faqForms]);

  useEffect(() => {
    if (isFaqCreated) {
      dispatch(successToast("Faq successfully created"))
      navigate("/faq")
    }
  }, [isFaqCreated])

  return (
    <>
      <Header title={t("faq")} />
      <div className="w-full relative bg-white dark:bg-lightBlack rounded-xl drop-shadow-lg">
        <div className="py-4 px-2 border-b border-solid border-gray text-gray">
          <Button
            className="flex items-center gap-x-1 font-medium"
            onClick={() => navigate("/faq")}
          >
            <BackSVG className="w-5" />
            {t("return_back")}
          </Button>
        </div>
        <div className="w-full">
          <div className="divide-y">
            {faqForms.map((form) => (
              <FAQForm
                key={form.id}
                sectionData={sectionData}
                faqForm={faqForms}
                faq={form}
                isNotLast={faqForms[faqForms.length - 1].id !== form.id}
                id={form.id}
              />
            ))}
          </div>
          <div className="flex">
            <Button
              className="w-full flex items-center gap-x-2 bg-light m-4 mb-0 hover:bg-gray/50"
              disabled={!validForm}
              onClick={() => {
                if (validForm) {
                  dispatch(
                    addFAQForm({ id: faqForms[faqForms.length - 1].id + 1 })
                  );
                }
              }}
            >
              Yeni bölmə <PlusSVG className="w-5" />
            </Button>
          </div>
          <div className="flex">
            <Button
              className="w-full bg-blue m-4 text-white"
              disabled={!validForm}
              isLoading={isFaqCreating}
              onClick={() => {
                if (validForm) {
                  onCreateFaq();
                }
              }}
            >
              {t("remember")}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default FAQCreate;
