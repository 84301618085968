import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { useEffect, useRef, useState } from "react";
import Modal from "../Common/Modal";
import { ReactComponent as InfoSVG } from "src/assets/vectors/info.svg";
import TextModal from "../Common/TextModal";
import Shimmer from "../Common/Shimmer";

const VacationCalendar = ({ date, data, isLoading, isSuccess, setParams }) => {
  const [params, setParamsData] = useState(new URLSearchParams());
  const [modal, setModal] = useState(false);
  const [info, setInfo] = useState(null);
  const [events, setEvents] = useState([]);
  const calendar = useRef(null);

  useEffect(() => {
    params.set("date", date.split(".").slice(1).join("."));
    setParams("?" + params.toString());
  }, [date]);

  useEffect(() => {
    if (isSuccess) {
      setEvents(data?.data);
      let dateCalendarFormat = date.split(".").reverse().join("-");
      let calendarApi = calendar.current.getApi();
      calendarApi.gotoDate(dateCalendarFormat);
    }
  }, [isLoading]);

  const weeks = [
    "Bazar",
    "Bazar ertəsi",
    "Çərşənbə axşamı",
    "Çərşənbə",
    "Cümə axşamı",
    "Cümə",
    "Şənbə",
  ];

  return (
    <div className="min-h-[456px] relative p-4">
      <div
        className={
          data?.data.length > 0 && !isLoading
            ? ""
            : "h-0 opacity-0 pointer-events-none"
        }
      >
        <FullCalendar
          plugins={[dayGridPlugin]}
          initialView="dayGridMonth"
          ref={calendar}
          dayHeaderContent={function (arg) {
            return weeks[arg.date.getDay()];
          }}
          dayHeaderClassNames="dark:text-white"
          firstDay={1}
          events={events}
          eventClick={(e) => {
            setInfo({
              title: e.event.title,
              name: data.data.find((vacation) => vacation.id === e.event.id)
                .full_name,
            });
            setModal(true);
          }}
          headerToolbar={false}
          eventClassNames="border border-solid !border-white cursor-pointer"
          dayCellClassNames="dark:text-white"
        />
      </div>

      {isLoading && (
        <div className="grid grid-cols-7 gap-4">
          {[...Array(28)].map((_, i) => (
            <Shimmer className="h-24" key={i} />
          ))}
        </div>
      )}

      {data?.data.length < 1 && (
        <div className="absolute inset-0 flex items-center justify-center z-[9] bg-white">
          <div className="w-fit flex items-center gap-x-3 p-4 rounded-xl bg-light dark:bg-softBlack text-gray dark:text-white">
            <InfoSVG />
            <p>Göstəriləcək hər hansı bir məlumat yoxdur</p>
          </div>
        </div>
      )}

      <Modal
        visible={modal}
        setVisible={setModal}
        dialogClassName="min-w-[450px]"
      >
        <TextModal setModal={setModal} title={info?.name} text={info?.title} />
      </Modal>
    </div>
  );
};

export default VacationCalendar;
