import React, { useEffect, useRef, useState } from "react";
import t from "src/hooks/useTranslate";
import { ReactComponent as BackSVG } from "src/assets/vectors/back.svg";
import { ReactComponent as DownloadSVG } from "src/assets/vectors/download.svg";
import { ReactComponent as CameraSVG } from "src/assets/vectors/camera.svg";
import { ReactComponent as XSVG } from "src/assets/vectors/x.svg";
import { Link, useSearchParams, useMatch } from "react-router-dom";
import Input from "src/components/Common/Inputs/Input";
import InputCalendar from "src/components/Common/Inputs/InputCalendar";
import Button from "src/components/Common/Inputs/Button";
import Modal from "src/components/Common/Modal";
import Cropper from "src/components/Common/Cropper";
import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import { setAllInputs } from "src/store/MashinalApp/mashinalAppStore";
import Select from "src/components/Common/Select";
import { useMashinalAppRoutesQuery } from "src/services/financeService";
import { validateRequired } from "src/helper/validation";
import { requiredPattern } from "src/utils/validationPatterns";
import Checkbox from "src/components/Common/Inputs/Checkbox";
import { useLocation } from "react-router-dom";
import Shimmer from "src/components/Common/Shimmer";
import { ddmmyyyy_hhmm } from "src/helper/getFormattedDate";

const MashinAlAppNotificationForm = ({ onSubmit, form, isLoading, title }) => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [modal, setModal] = useState(false);
  const [seeNotificationsInProd, setSeeNotificationsInProd] = useState(false);
  const [file, setFile] = useState<any>(null);
  const [image, setImage] = useState<any>(null);
  const [inInput, setInInput] = useState(false);
  const [validForm, setValidForm] = useState(false);
  const [previewImg, setPreviewImg] = useState("");
  const fileRef = useRef<any>(null);
  const [submitType, setSubmitType] = useState(0);

  const { data: routes } = useMashinalAppRoutesQuery("");

  const match = useMatch("/mashin-al-app/edit/:id");
  const isCreate = useMatch("/mashin-al-app/create");

  const isEdit = match?.pathname.includes("edit");

  const location = useLocation();
  const isView = location.search.split("?").includes("view=true");
  const isClone = location.search.split("?").includes("clone=true");

  const isJson = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }

    return true;
  };

  const notification_types_data = [
    { id: 1, name: "Prod", key: "all-prod" },
    { id: 2, name: "Dev", key: "mshnl-dev" },
  ];

  useEffect(() => {
    if (
      validateRequired([form?.title, form?.description]) &&
      form?.route_id != 0 &&
      (!form?.payload || isJson(form?.payload))
    ) {
      setValidForm(true);
    } else {
      setValidForm(false);
    }
    if (isView || isClone) {
      setSeeNotificationsInProd(form?.save === 1);
    }
  }, [form]);

  useEffect(() => {
    setPreviewImg(form?.image);
  }, [form?.image]);

  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFile(e.target.files);
    setModal(true);
  };

  const onSave = (base64: any) => {
    fileRef.current.value = "";
    setPreviewImg(base64);
    dispatch(setAllInputs({ key: "image", value: base64 }));
    setImage(base64);
    setModal(false);
  };

  const removeSelected = () => {
    setPreviewImg("");
    dispatch(setAllInputs({ key: "image", value: "" }));
    setImage("");
  };

  return (
    <>
      <div className="w-full px-4 py-6 relative flex flex-col justify-between bg-white dark:bg-lightBlack rounded-xl drop-shadow-lg">
        <div className="flex justify-between items-center gap-x-4 mb-6">
          <p className="text-16 font-bold dark:text-white">{title}</p>
          <div className="flex items-center gap-x-4">
            <Link
              to="/mashin-al-app/routes"
              className="h-10.5 flex items-center gap-x-2 bg-blue text-white px-4 whitespace-nowrap rounded border border-solid border-transparent"
            >
              {t("settings")}
            </Link>
            <Link
              to="/mashin-al-app"
              className="h-10.5 flex items-center gap-x-2 dark:text-white px-4 whitespace-nowrap rounded border border-solid border-gray"
            >
              <BackSVG />
              <p className="dark:text-white">{t("return_back")}</p>
            </Link>
          </div>
        </div>
        <form
          className="grid grid-cols-2 grid-rows-[145px_290px_75px_380px] gap-y-4 gap-x-6"
          onSubmit={(e) => !searchParams.get("view") && onSubmit(e, submitType)}
        >
          <div>
            <div
              className={
                searchParams.get("view")
                  ? "uppercase mb-1.5 relative pointer-events-none after:absolute after:inset-0 after:top-8 after:rounded after:bg-gray/50 after:z-20"
                  : " mb-1.5"
              }
            >
              <Select
                data={notification_types_data}
                label={t("notification_type") + "*"}
                defaultTitle={
                  isEdit && form
                    ? form?.send_to?.split("-")[1].charAt(0).toUpperCase() +
                      form?.send_to?.split("-")[1].slice(1)
                    : t("choose")
                }
                defaultValue={isEdit ? form?.send_to : t("choose")}
                onChange={(e: any) => {
                  dispatch(setAllInputs({ key: "send_to", value: e.key }));
                }}
              />
            </div>
            <Input
              label={t("title") + "*"}
              defaultValue={form?.title}
              validpattern={requiredPattern}
              className={
                searchParams.get("view")
                  ? "relative pointer-events-none after:absolute after:inset-0 after:bg-gray/50"
                  : ""
              }
              onChange={(e) =>
                dispatch(setAllInputs({ key: "title", value: e.target.value }))
              }
            />
          </div>

          <div className="pt-8 row-span-2">
            <div className="relative h-full flex items-center justify-center border border-dashed rounded bg-light dark:bg-softBlack">
              {form?.image ? (
                <div className="relative h-full w-full">
                  <img
                    src={form?.image}
                    alt=""
                    className="w-full h-full object-contain"
                  />

                  <div
                    className={[
                      "absolute inset-0 flex flex-col justify-center items-center duration-300 opacity-0 bg-white/50 z-10 pointer-events-none",
                      inInput ? "opacity-100" : "opacity-0",
                    ].join(" ")}
                  >
                    <CameraSVG />
                    <p className="text-12 font-medium">{t("change_photo")}</p>
                  </div>
                  {!searchParams.get("view") && (
                    <XSVG
                      className="absolute top-0 right-0 z-10 text-white bg-red w-8 h-8 rounded-full p-1 border-2 border-solid border-white dark:border-lightBlack cursor-pointer hover:brightness-150"
                      onClick={removeSelected}
                    />
                  )}
                </div>
              ) : (
                <div className="flex flex-col items-center justify-center gap-y-2 text-center">
                  <DownloadSVG className="w-6 h-6 text-blue" />
                  <p className="dark:text-white">
                    1:1 nisbətində maksimum 1 mb ölçüsündə JPG və ya PNG
                  </p>
                </div>
              )}
              <input
                type="file"
                accept="image/*"
                className={[
                  "absolute inset-0 opacity-0 cursor-pointer",
                  searchParams.get("view") ? "pointer-events-none" : "",
                ].join(" ")}
                ref={fileRef}
                onChange={onSelectFile}
                onMouseEnter={() => setInInput(true)}
                onMouseLeave={() => setInInput(false)}
              />
            </div>
          </div>
          <Input
            label={t("explanation") + "*"}
            textarea
            defaultValue={form?.description}
            validpattern={requiredPattern}
            className={[
              "!h-full",
              searchParams.get("view")
                ? "relative pointer-events-none after:absolute after:inset-0 after:bg-gray/50"
                : "",
            ].join(" ")}
            onChange={(e) =>
              dispatch(
                setAllInputs({ key: "description", value: e.target.value })
              )
            }
          />
          <div
            className={
              searchParams.get("view")
                ? "relative pointer-events-none after:absolute after:inset-0 after:top-8 after:rounded after:bg-gray/50 after:z-20"
                : ""
            }
          >
            <Select
              data={routes}
              label={t("link") + "*"}
              defaultTitle={t("choose")}
              defaultValue={routes ? form?.route_id : 0}
              onChange={(e: any) => {
                if (e.payload) {
                  dispatch(
                    setAllInputs({
                      key: "payload",
                      value: e.payload,
                    })
                  );
                } else {
                  dispatch(
                    setAllInputs({
                      key: "payload",
                      value: "",
                    })
                  );
                }
                dispatch(
                  setAllInputs({
                    key: "route_id",
                    value: e.id,
                  })
                );
              }}
            />
          </div>
          <div className="flex items-end gap-x-4 [&>div]:flex-1">
            <InputCalendar
              label={t("date_and_time")}
              timePicker={true}
              value={form?.send_at}
              minDate={new Date()}
              className={[
                "min-w-[260px] z-20",
                searchParams.get("view")
                  ? "relative max-w-[260px] pointer-events-none after:absolute after:inset-0 after:bg-gray/50"
                  : "",
              ].join(" ")}
              onChange={(e: any) =>
                dispatch(setAllInputs({ key: "send_at", value: e }))
              }
            />
            {!searchParams.get("view") && (
              <>
                <Button
                  className="w-36 border border-gray [&>div>div]:border-gray dark:text-white"
                  disabled={!validForm}
                  isLoading={isLoading && submitType === 0}
                  onClick={() => setSubmitType(0)}
                >
                  Planla
                </Button>
                <Button
                  className="w-36 bg-blue text-white"
                  disabled={!validForm}
                  isLoading={isLoading && submitType === 1}
                  onClick={() => setSubmitType(1)}
                >
                  Dərhal göndər
                </Button>
              </>
            )}
          </div>
          <div className="flex flex-col gap-4">
            <Input
              label={t("value")}
              defaultValue={form?.value}
              validpattern={requiredPattern}
              className={
                searchParams.get("view")
                  ? "relative pointer-events-none after:absolute after:inset-0 after:bg-gray/50"
                  : ""
              }
              onChange={(e) =>
                dispatch(setAllInputs({ key: "value", value: e.target.value }))
              }
            />
            <Input
              label={t("extra_value")}
              defaultValue={form?.extra_value}
              validpattern={requiredPattern}
              className={
                searchParams.get("view")
                  ? "relative pointer-events-none after:absolute after:inset-0 after:bg-gray/50"
                  : ""
              }
              onChange={(e) =>
                dispatch(
                  setAllInputs({ key: "extra_value", value: e.target.value })
                )
              }
            />
            <Input
              label={t("Payload")}
              textarea
              defaultValue={form?.payload}
              validpattern={
                /^(?:\"[a-z0-9\_]+\":{1}(\"?[a-z0-9\_]+\"?)|(\{.+\})[,\n\}]?)?$/
              }
              className={[
                "!h-[160px]",
                searchParams.get("view")
                  ? "relative after:absolute after:inset-0 after:bg-gray/50"
                  : "",
              ].join(" ")}
              onChange={(e) =>
                dispatch(
                  setAllInputs({ key: "payload", value: e.target.value })
                )
              }
            />
          </div>

          <div
            className={[
              "self-start mt-8",
              searchParams.get("view")
                ? "relative after:absolute after:left-0 after:top-0 after:h-6 after:w-6 after:rounded after:bg-gray/50"
                : "",
            ].join(" ")}
          >
            <Checkbox
              label={t("see_in_notifications")}
              isChecked={isView ? form?.save === 1 : seeNotificationsInProd}
              onChange={(e) => {
                setSeeNotificationsInProd(e.target.checked);
                dispatch(
                  setAllInputs({ key: "save", value: e.target.checked ? 1 : 0 })
                );
              }}
            />
          </div>
        </form>
      </div>
      <Modal
        visible={modal}
        setVisible={(e) => {
          fileRef.current.value = "";
          setModal(e);
        }}
        dialogClassName="w-1/2"
      >
        <div className="p-4">
          <Cropper files={file} onSave={onSave} />
        </div>
      </Modal>
    </>
  );
};

export default MashinAlAppNotificationForm;
