import { ReactComponent as DownloadSVG } from "src/assets/vectors/download.svg";
import { ReactComponent as UpChevronSVG } from "src/assets/vectors/up_chevron.svg";
import { ReactComponent as ExcelSVG } from "src/assets/vectors/file_excel.svg";
import { ReactComponent as MailSVG } from "src/assets/vectors/mail.svg";
import { ReactComponent as InfoSVG } from "src/assets/vectors/info.svg";
import Accordion from "../Common/Accordion";
import { ddmmyyyy } from "src/helper/getFormattedDate";
import t from "src/hooks/useTranslate";
import adminResponseStatusData from "src/data/admin_response_status_data.json";
import Shimmer from "../Common/Shimmer";
import Pagination from "../Common/Pagination";
import { useEffect, useState } from "react";
import usePermission from "src/hooks/usePermission";
import { EPermissions } from "src/enums/e_permissions";

const VacationTable = ({
  date,
  data,
  isLoading,
  onConfirmClick,
  setParams,
  setPerpage,
}) => {
  const [params, setParamsData] = useState(new URLSearchParams());
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const canStatusUpdate = usePermission([EPermissions.vacations_status_update]);
  const canStatusApprove = usePermission([
    EPermissions.vacations_status_approve,
  ]);

  useEffect(() => {
    params.set("date", date.split(".").slice(1).join("."));
  }, [date]);

  useEffect(() => {
    data && setParams("?" + params.toString());
    setPerPage(perPage);
  }, [page, perPage, date]);

  return (
    <div className="min-h-[456px] flex flex-col divide-y divide-light">
      {data && !isLoading ? (
        data?.data.length > 0 ? (
          data?.data.map((vacation) =>
            vacation.admins &&
            vacation.admins[vacation.admins.length - 1].reason ? (
              <div
                className={
                  vacation.has_notification ? "bg-light dark:bg-softBlack" : ""
                }
                key={vacation.id}
              >
                <Accordion
                  accordionButton={
                    <div className="flex items-center gap-x-4 h-20 px-6 text-left">
                      <p className="flex-1 text-green">
                        {ddmmyyyy(new Date(vacation.created_at))}
                      </p>
                      <p className="relative flex-[8] dark:text-white ml-10">
                        {vacation.has_notification && (
                          <MailSVG className="absolute top-1/2 -translate-y-1/2 -left-6 text-red" />
                        )}
                        {`${vacation.user} | ${vacation.work_days} ${t(
                          "day"
                        )} (${ddmmyyyy(
                          new Date(vacation.start_date)
                        )} - ${ddmmyyyy(new Date(vacation.end_date))}) | `}
                        <span
                          className={
                            vacation.status == 1
                              ? "text-blue dark:text-[lightblue]"
                              : vacation.status == 2
                              ? "text-green dark:text-[lightgreen]"
                              : "text-red dark:text-[orangered]"
                          }
                        >
                          {t(
                            adminResponseStatusData.find(
                              (res) => res.id == vacation.status
                            ).name
                          )}
                        </span>{" "}
                        {vacation.admins
                          ? `- ${
                              vacation.admins[vacation.admins.length - 1]
                                .fullname
                            }`
                          : ""}
                      </p>
                      <div className="flex-[3] flex items-center gap-x-2 dark:text-white justify-center">
                        {vacation.file ? (
                          <>
                            <a href={vacation.file} rel="noopener noreferrer">
                              <DownloadSVG className="text-blue" />
                            </a>
                            <p className="text-gray">{t("application_form")}</p>
                          </>
                        ) : vacation.status == 2 ? (
                          <p className="text-red dark:text-[orangered]">
                            Ərizə forması əlavə edilməyib
                          </p>
                        ) : vacation.status == 1 ? (
                          <p className="text-gray">
                            Ərizə forması təsdiqdən sonra yaranacaq
                          </p>
                        ) : (
                          <p className="text-red dark:text-[orangered]">
                            İmtina
                          </p>
                        )}
                      </div>
                      <div className="w-28"></div>
                      <p className="w-32 flex items-center gap-x-4 text-gray font-medium">
                        <UpChevronSVG className="w-3 duration-300 ui-not-open:rotate-180" />
                        {vacation.week_day}
                      </p>
                    </div>
                  }
                >
                  <div className="bg-light py-3 px-4 mb-6 rounded mx-6">
                    <p className="font-semibold">Qeyd:</p>
                    <p className="text-gray font-medium break-all">
                      {vacation.admins[vacation.admins.length - 1].reason}
                    </p>
                  </div>
                </Accordion>
              </div>
            ) : (
              <button
                className="w-full flex items-center gap-x-4 h-20 px-6 text-left cursor-default"
                key={vacation.id}
              >
                <p className="flex-1 text-green">
                  {ddmmyyyy(new Date(vacation.created_at))}
                </p>
                <p className="relative flex-[8] dark:text-white ml-10">
                  {vacation.has_notification && (
                    <MailSVG className="absolute top-1/2 -translate-y-1/2 -left-6 text-red" />
                  )}
                  {`${vacation.user} | ${vacation.work_days} ${t(
                    "day"
                  )} (${ddmmyyyy(new Date(vacation.start_date))} - ${ddmmyyyy(
                    new Date(vacation.end_date)
                  )}) | `}
                  <span
                    className={
                      vacation.status == 1
                        ? "text-blue dark:text-[lightblue]"
                        : vacation.status == 2
                        ? "text-green dark:text-[lightgreen]"
                        : "text-red dark:text-[orangered]"
                    }
                  >
                    {t(
                      adminResponseStatusData.find(
                        (res) => res.id == vacation.status
                      ).name
                    )}
                  </span>{" "}
                  {vacation.admins
                    ? `- ${
                        vacation.admins[vacation.admins.length - 1].fullname
                      }`
                    : ""}
                </p>
                <div className="flex-[3] flex items-center gap-x-2 dark:text-white justify-center">
                  {vacation.file ? (
                    <>
                      <a href={vacation.file} rel="noopener noreferrer">
                        <DownloadSVG className="text-blue" />
                      </a>
                      <p className="text-gray">{t("application_form")}</p>
                    </>
                  ) : vacation.status == 2 ? (
                    <p className="text-red dark:text-[orangered]">
                      Ərizə forması əlavə edilməyib
                    </p>
                  ) : vacation.status == 1 ? (
                    <p className="text-gray">
                      Ərizə forması təsdiqdən sonra yaranacaq
                    </p>
                  ) : (
                    <p className="text-red dark:text-[orangered]">İmtina</p>
                  )}
                </div>
                {(canStatusUpdate || canStatusApprove) && vacation.can_edit ? (
                  <div
                    className="w-28 flex items-center justify-center h-10.5 rounded px-6 bg-blue text-white cursor-pointer hover:brightness-125"
                    onClick={() => {
                      onConfirmClick({ id: vacation.id, user: vacation.user });
                      //   setActiveId(vacation.id);
                      //   setActiveUserName(vacation.user);
                      //   setModal(true);
                    }}
                  >
                    {t("submit")}
                  </div>
                ) : (
                  <div className="w-28"></div>
                )}

                <p className="w-32 flex items-center gap-x-4 text-gray font-medium">
                  <UpChevronSVG className="w-3 pointer-events-none opacity-0" />
                  {vacation.week_day}
                </p>
              </button>
            )
          )
        ) : (
          <div className="w-full h-[456px] flex justify-center items-center">
            <div className="w-fit flex items-center gap-x-3 p-4 rounded-xl bg-light dark:bg-softBlack text-gray dark:text-white">
              <InfoSVG />
              <p>Göstəriləcək hər hansı bir məlumat yoxdur</p>
            </div>
          </div>
        )
      ) : (
        [...Array(5)].map((_, i) => (
          <div className="w-full p-4 flex items-center gap-x-4" key={i}>
            <Shimmer className="flex-1 h-10.5" />
            <Shimmer className="w-2/3 h-10.5" />
            <Shimmer className="w-1/2 h-10.5" />
            <Shimmer className="w-32 h-10.5" />
            <Shimmer className="w-28 h-10.5" />
          </div>
        ))
      )}
      {data?.meta.total > 5 && (
        <div className="w-full px-4 py-4 mt-auto border-t border-solid border-gray">
          <Pagination
            page={Math.ceil(data?.meta.total / perPage)}
            total={data?.meta.total}
            perPage={perPage}
            pageChange={(e) => {
              setPage(e + 1);
              params.set("page", (e + 1).toString());
            }}
            setPerPage={(val) => {
              setPerPage(val);
              params.set("per_page", val.toString());
            }}
          />
        </div>
      )}
    </div>
  );
};

export default VacationTable;
