import { useEffect, useMemo, useState } from "react";
import { EGender } from "src/enums/e_gender";
import weekdays from "src/data/weekdays_data.json";
import t from "src/hooks/useTranslate";
import { ReactComponent as CheckSVG } from "src/assets/vectors/check.svg";
import { ReactComponent as EyeSVG } from "src/assets/vectors/eye.svg";
import { ReactComponent as InfoSlimSVG } from "src/assets/vectors/info_slim.svg";
import { ReactComponent as TrashSVG } from "src/assets/vectors/trash.svg";
import { ReactComponent as InfoSVG } from "src/assets/vectors/info.svg";
import { ReactComponent as XSVG } from "src/assets/vectors/x.svg";
import Button from "../Common/Inputs/Button";
import { ddmmyyyy } from "src/helper/getFormattedDate";
import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import { errorToast, successToast } from "src/store/Settings/toastStore";
import Shimmer from "../Common/Shimmer";
import Modal from "../Common/Modal";
import {
  useGetSuitableOperatorsQuery,
  useOperatorShiftChangeListQuery,
  useSendShiftChangeRequestMutation,
  useShiftChangeConfirmMutation,
  useShiftChangeDeleteMutation,
} from "src/services/operatorsService";
import Pagination from "../Common/Pagination";
import ShiftChangeRequestModal from "./ShiftChangeRequestModal";
import DeleteCautionModal from "../Common/DeleteCautionModal";
import { useLocation } from "react-router-dom";

const ShiftChangeOperator = () => {
  const { state } = useLocation();
  const [modalType, setModalType] = useState("view");
  const [modal, setModal] = useState(false);
  const [params, setParams] = useState(new URLSearchParams());
  const [shiftOpen, setShiftOpen] = useState(false);
  const [body, setBody] = useState({ week_day: 0, date: "" });
  const [activeDay, setActiveDay] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [shiftId, setShiftId] = useState(state?.actionId || 0);
  const [activeOperator, setActiveOperator] = useState(0);
  const [operatorState, setOperatorState] = useState([]);
  const user: any = useAppSelector((state) => state.profile.user);
  const todayIndex = new Date().getDay() == 0 ? 7 : new Date().getDay();
  const today = weekdays.find((day) => day.id == todayIndex);
  const {
    refetch: listRefetch,
    data: shiftChangeList,
    isSuccess: shiftChangeListFetched,
    isFetching: shiftChangeListFetching,
  } = useOperatorShiftChangeListQuery(
    params.toString() ? "?" + params.toString() : ""
  );

  const {
    refetch,
    data: operators,
    isSuccess: operatorsFetched,
    isFetching: operatorsFetching,
  } = useGetSuitableOperatorsQuery(body, { skip: !body.date });
  const [
    sendShiftChangeRequest,
    {
      isLoading: isRequestSending,
      isSuccess: isRequestSent,
      error: requestError,
      isError: isRequestError,
    },
  ] = useSendShiftChangeRequestMutation();

  const [
    shiftChangeDelete,
    {
      isLoading: shiftChangeDeleting,
      isSuccess: shiftChangeDeleted,
      error: shiftChangeDeleteError,
      isError: isShiftChangeDeleteError,
    },
  ] = useShiftChangeDeleteMutation();

  const [
    shiftChangeConfirm,
    {
      isLoading: shiftChangeConfirming,
      isSuccess: shiftChangeConfirmed,
      error: shiftChangeConfirmError,
      isError: isShiftChangeConfirmError,
    },
  ] = useShiftChangeConfirmMutation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (state?.actionId) {
      setShiftId(state.actionId)
      setModal(true);
      window.history.replaceState({}, document.title);
    }
  }, [state]);

  let total = useMemo(() => {
    let count = 0;
    user.working_hours.forEach((hours: any) => {
      if (hours.length !== 0) {
        if (hours[0] && hours[1]) {
          const [hour0, minute0] = hours[0].split(":");
          const [hour1, minute1] = hours[1].split(":");

          let calculated =
            Number(hour1) +
            Number(minute1) / 60 -
            (Number(hour0) + Number(minute0) / 60);
          count += calculated > 4 ? calculated - 1 : calculated;
        }
      }
    });
    return Math.round(count * 10) / 10;
  }, [user]);

  useEffect(() => {
    setActiveOperator(0);
    refetch();
  }, [body]);

  useEffect(() => {
    if (operatorsFetched) {
      setOperatorState(operators);
    }
  }, [operators]);

  const getNextSevenDays = () => {
    const nextWeekDays = weekdays.filter((day) => day.id <= todayIndex);
    const currentWeekDays = weekdays.filter((day) => day.id > todayIndex);
    let total = [...currentWeekDays, ...nextWeekDays];

    total.map((item, i) => {
      let date = new Date();
      date.setDate(date.getDate() + i + 1);
      item["day"] = ddmmyyyy(date);
    });
    return total;
  };

  useEffect(() => {
    listRefetch();
  }, [setPage, setPerPage]);

  const sendRequest = async () => {
    const data = {
      user_id: activeOperator,
      weekday: body.week_day,
      date: body.date,
    };
    if (activeOperator !== 0) {
      await sendShiftChangeRequest(data);
    }
  };

  useEffect(() => {
    if (isRequestError) {
      //@ts-ignore
      dispatch(errorToast(requestError?.data.message));
    }
  }, [isRequestError]);

  const onShiftChangeConfirm = async (body) => {
    await shiftChangeConfirm(body);
  };

  useEffect(() => {
    if (shiftChangeConfirmed) {
      setModal(false);
      dispatch(successToast("Cavabınız göndərildi"));
    }
  }, [shiftChangeConfirmed]);

  useEffect(() => {
    if (isShiftChangeConfirmError) {
      //@ts-ignore
      dispatch(errorToast(shiftChangeConfirmError?.data.message));
    }
  }, [isShiftChangeConfirmError]);

  useEffect(() => {
    if (isRequestSent) {
      setShiftOpen(false);
      setActiveDay(0);
      setActiveOperator(0);
      setOperatorState([]);
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
      dispatch(successToast("Sorğunuz uğurla göndərildi"));
    }
  }, [isRequestSent]);

  const onShiftChangeDelete = async () => {
    await shiftChangeDelete(shiftId);
  };

  useEffect(() => {
    if (shiftChangeDeleted) {
      setModal(false);
      dispatch(successToast("Sorğunuz uğurla silindi"));
    }
  }, [shiftChangeDeleted]);

  const renderModal = () => {
    switch (modalType) {
      case "view":
        return (
          <ShiftChangeRequestModal
            setModal={setModal}
            id={shiftId}
            isLoading={shiftChangeConfirming}
            onSubmit={onShiftChangeConfirm}
          />
        );
      case "delete":
        return (
          <DeleteCautionModal
            setModal={setModal}
            onSubmit={onShiftChangeDelete}
            isLoading={shiftChangeDeleting}
          />
        );
      default:
        return;
    }
  };

  return (
    <div className="space-y-6">
      <div className="w-full relative p-6 bg-white dark:bg-lightBlack rounded-xl drop-shadow-lg z-10">
        <div className="grid grid-cols-[120px_130px_1fr] gap-x-12">
          <div className="space-y-2">
            <div className="flex justify-center items-center mx-auto w-28 h-28 rounded-full border border-solid border-gray text-gray overflow-hidden">
              {user ? (
                <img
                  src={
                    user.avatar
                      ? user.avatar
                      : user.gender === EGender.male
                      ? "/assets/images/male_avatar.png"
                      : "/assets/images/female_avatar.png"
                  }
                  className="w-full h-full object-cover"
                  alt=""
                />
              ) : (
                <img
                  src="/assets/images/female_avatar.png"
                  className="w-full h-full object-cover"
                  alt=""
                />
              )}
            </div>
            <p className="text-center dark:text-white">{user?.full_name}</p>
          </div>
          <div className="space-y-2">
            <p className="h-10.5 font-bold dark:text-white">
              {t("work_schedule")}
            </p>
            {weekdays.map((week, i) => {
              return (
                <div
                  className={`w-[180px] h-10.5 flex items-center gap-x-2 cursor-pointer ${
                    user.working_hours[i].length
                      ? "text-lighBlue"
                      : "text-green"
                  }`}
                  key={week.id}
                >
                  <p className="w-36 font-medium dark:text-white">
                    {t(week.name)}
                  </p>
                </div>
              );
            })}
          </div>
          <div className="w-full pr-[15%] mt-12 dark:text-white">
            <div className="space-y-2">
              {weekdays.map((week, i) => (
                <div className="" key={week.id}>
                  {user.working_hours[i].length ? (
                    <div className="w-full flex gap-x-4">
                      <div className="h-10.5 w-full flex items-center px-4 rounded border border-solid border-gray">
                        <p>{user.working_hours[i][0]}</p>
                      </div>
                      <div className="h-10.5 w-full flex items-center px-4 rounded border border-solid border-gray">
                        <p>{user.working_hours[i][1]}</p>
                      </div>
                    </div>
                  ) : (
                    <div className="w-full h-10.5 flex items-center justify-between px-4 col-span-2 border border-solid border-green rounded">
                      <p className="text-green leading-[40px]">
                        {t("day_off")}
                      </p>
                      {/* <RefreshSVG
                        className="text-blue cursor-pointer"
                        onClick={() => setModal(true)}
                      /> */}
                    </div>
                  )}
                </div>
              ))}
              <div className="w-full h-10.5 px-4 flex justify-between items-center ml-auto bg-light rounded dark:text-darkBlue">
                <p>{t("weekly_working_hours")}</p>
                <p>
                  {total} {t("hour")}
                </p>
              </div>
              <Button
                className={`w-full flex justify-center items-center ml-auto duration-200 !mt-6 rounded ${
                  shiftOpen
                    ? "border border-solid border-gray text-gray"
                    : "bg-blue text-white"
                }`}
                onClick={() => setShiftOpen(!shiftOpen)}
              >
                {t("create_request")}
              </Button>
            </div>
          </div>
        </div>
        {shiftOpen && (
          <div className="grid grid-cols-[298px_1fr] gap-x-12 mt-11">
            <div className="space-y-4">
              <div
                className={`flex items-center justify-between gap-x-2 w-full h-10.5 px-4 border border-solid border-gray rounded cursor-pointer bg-light text-gray dark:text-dark pointer-events-none`}
              >
                {t(today.name)} | {ddmmyyyy(new Date())}
              </div>
              {getNextSevenDays().map((day: any, i) => (
                <div
                  className={`flex items-center justify-between gap-x-2 w-full h-10.5 px-4 border border-solid border-gray rounded cursor-pointer ${
                    activeDay === day.id
                      ? "bg-green text-white"
                      : "hover:bg-light dark:text-white"
                  } ${user.working_hours[day.id - 1].length <= 0 ? "bg-light text-gray dark:text-dark pointer-events-none" : ""}`}
                  key={day.id + i}
                  onClick={() => {
                    if (user.working_hours[day.id - 1].length > 0) {
                      setActiveDay(day.id);
                      setBody({ week_day: day.id, date: day.day });
                    }
                  }}
                >
                  {t(day.name)} | {day.day}{" "}
                  {activeDay === day.id && <CheckSVG className="text-white" />}
                </div>
              ))}
            </div>
            <div className="flex flex-col pr-[15%] space-y-4">
              <div className="w-full border border-solid border-gray rounded flex-grow">
                <div className="flex justify-between items-center px-4 h-10.5 border-b border-solid border-gray dark:text-white">
                  <p className="font-bold">{t("co_workers")}</p>
                  <p className="w-36 font-bold">{t("work_schedule")}</p>
                </div>
                <div className="min-h-[330px] max-h-[330px] p-4 space-y-4 overflow-auto">
                  {operatorState.length > 0 ? (
                    !operatorsFetching ? (
                      operatorState.map((operator) => (
                        <div
                          className="flex justify-between items-center gap-x-6"
                          key={operator.id}
                        >
                          <div
                            className={`w-1/2 h-10.5 px-4 flex items-center justify-between gap-x-2 border border-solid border-gray rounded cursor-pointer ${
                              activeOperator === operator.id
                                ? "bg-green text-white"
                                : "hover:bg-light dark:hover:text-dark dark:text-white"
                            }`}
                            onClick={() => setActiveOperator(operator.id)}
                          >
                            {operator.full_name}{" "}
                            {activeOperator === operator.id && (
                              <CheckSVG className="text-white" />
                            )}
                          </div>
                          <p className="w-36 dark:text-white">
                            {operator.current_working_hours.length
                              ? operator.current_working_hours.join("-")
                              : t("day_off")}
                          </p>
                        </div>
                      ))
                    ) : (
                      <>
                        {[...Array(5)].map((_, i) => (
                          <div className="flex justify-between" key={i}>
                            <Shimmer className="w-1/2 h-10.5" />
                            <Shimmer className="w-36 h-10.5" />
                          </div>
                        ))}
                      </>
                    )
                  ) : (
                    <div className="h-[298px] flex flex-col justify-center items-center gap-y-4 text-gray">
                      <InfoSlimSVG className="w-20 h-20" />
                      <p>Növbə dəyişikliyi üçün tarix seçin</p>
                    </div>
                  )}
                </div>
              </div>
              <Button
                className="w-full bg-blue text-white disabled:bg-gray/50"
                isLoading={isRequestSending}
                disabled={activeOperator === 0}
                onClick={sendRequest}
              >
                {t("send_request")}
              </Button>
            </div>
          </div>
        )}
      </div>
      <div className="relative py-6 bg-white dark:bg-lightBlack rounded-xl drop-shadow-lg">
        <div className="w-full overflow-auto min-h-[476px]">
          <table className="min-w-[991px] w-full">
            <thead>
              <tr className="[&>th]:text-left [&>th]:pb-6 [&>th]:px-2 [&>th]:dark:text-white [&>th]:whitespace-nowrap border-b border-solid border-gray">
                <th className="w-20 !pl-4">ID</th>
                <th className="w-auto">{t("co_worker")}1</th>
                <th className="w-auto">{t("co_worker")}2</th>
                {/* <th className="w-28">{t("account_type")}</th> */}
                <th className="w-auto">{t("request_date")}</th>
                <th className="w-auto">{t("shift_date")}</th>
                <th className="w-auto">{t("status")}</th>
                <th className="w-24 !pr-4">{t("review")}</th>
              </tr>
            </thead>
            <tbody>
              {shiftChangeList && !shiftChangeListFetching ? (
                shiftChangeList?.data.length > 0 ? (
                  shiftChangeList?.data.map((shiftChange) => (
                    <tr
                      className="relative [&>td]:py-4 [&>td]:px-2 [&>td]:dark:text-white after:absolute after:top-0 after:left-0 after:w-full after:h-[1px] after:bg-gray after:opacity-50"
                      key={shiftChange.id}
                    >
                      <td className="w-10 !pl-4">{shiftChange.id}</td>
                      <td>{shiftChange.changer_user}</td>
                      <td>{shiftChange.user}</td>
                      <td>{shiftChange.created_at}</td>
                      <td>{shiftChange.change_day}</td>
                      <td
                        className={
                          shiftChange.status == 2
                            ? "text-blue"
                            : shiftChange.status == 1
                            ? "text-green"
                            : "text-red"
                        }
                      >
                        {shiftChange.status_label}
                      </td>
                      <td className="!pr-4">
                        {shiftChange.is_receiver && (
                          <EyeSVG
                            className="cursor-pointer"
                            onClick={() => {
                              setShiftId(shiftChange.id);
                              setModalType("view");
                              setModal(true);
                            }}
                          />
                        )}
                        {!shiftChange.is_receiver &&
                          shiftChange.status == 2 && (
                            <TrashSVG
                              className="text-red cursor-pointer"
                              onClick={() => {
                                setShiftId(shiftChange.id);
                                setModalType("delete");
                                setModal(true);
                              }}
                            />
                          )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <div className="w-full absolute top-1/2 -translate-y-1/2 p-4 flex items-center justify-center">
                    <div className="w-fit flex items-center gap-x-3 p-4 rounded-xl bg-light dark:bg-softBlack text-gray dark:text-white">
                      <InfoSVG />
                      <p>Göstəriləcək hər hansı bir məlumat yoxdur</p>
                    </div>
                  </div>
                )
              ) : (
                <>
                  {[...Array(perPage)].map((_, i) => (
                    <tr
                      className="[&>td]:py-4 [&>td]:px-2 bg-white dark:bg-lightBlack"
                      key={i}
                    >
                      <td className="w-20">
                        <Shimmer className="h-4" />
                      </td>
                      <td className="w-auto">
                        <Shimmer className="h-4" />
                      </td>
                      <td className="w-auto">
                        <Shimmer className="h-4" />
                      </td>
                      <td className="w-auto">
                        <Shimmer className="h-4" />
                      </td>
                      <td className="w-auto">
                        <Shimmer className="h-4" />
                      </td>
                      <td className="w-24">
                        <Shimmer className="h-4" />
                      </td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </table>
        </div>
        {shiftChangeList?.meta.total > 5 && (
          <div className="px-4 pt-4">
            <Pagination
              page={Math.ceil(shiftChangeList?.meta.total / perPage)}
              total={shiftChangeList?.meta.total}
              perPage={perPage}
              pageChange={(e) => {
                setPage(e + 1);
                params.set("page", (e + 1).toString());
              }}
              setPerPage={(val) => {
                setPerPage(val);
                params.set("per_page", val.toString());
              }}
            />
          </div>
        )}
      </div>
      <Modal
        visible={modal}
        setVisible={setModal}
        // dialogClassName="min-w-[750px]"
      >
        {/* <div className="flex items-center justify-between gap-x-4 p-4 border-b border-solid border-gray">
          <p className="text-16 font-bold dark:text-white">
            {t("delete_caution_question")}
          </p>
          <XSVG
            className="text-gray cursor-pointer dark:text-white"
            onClick={() => setModal(false)}
          />
        </div>
        <div className="p-4 grid grid-cols-3 gap-4">
          {getNextSevenDays().map((day, i) => (
            <div
              key={day.id}
              className={`h-10.5 flex items-center justify-center px-4 bg-light rounded first:opacity-60 first:text-gray first:pointer-events-none cursor-pointer ${
                activeDayModal === day.id
                  ? "bg-green text-white"
                  : "hover:bg-darkSnow"
              }`}
              onClick={() => setActiveDayModal(day.id)}
            >
              {t(day.name)}
            </div>
          ))}
          <Button className="bg-blue text-white">Sorğu göndər</Button>
        </div> */}
        {renderModal()}
      </Modal>
    </div>
  );
};

export default ShiftChangeOperator;
