import React, {
  ChangeEvent,
  FC,
  memo,
  useEffect,
  useRef,
  useState,
} from "react";
import Checkbox from "./Inputs/Checkbox";
import { ReactComponent as PlusSVG } from "src/assets/vectors/plus.svg";
import { ReactComponent as MinusSVG } from "src/assets/vectors/minus.svg";
import { ReactComponent as XSVG } from "src/assets/vectors/x.svg";
import Accordion from "./Accordion";
import Button from "./Inputs/Button";
import t from "src/hooks/useTranslate";
import { IPermission } from "src/types/i_permission";
import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import {
  changePermissions,
  setPermissions,
} from "src/store/Settings/permissionsStore";
import Shimmer from "./Shimmer";

const Permissions: FC<any> = ({ isLoading = false, onSubmit }) => {
  const ref = useRef<any>(null);
  const [height, setHeight] = useState(0);
  const dispatch = useAppDispatch();
  const allSelected = useAppSelector((state) => state.permissions.all_selected);
  const permissions = useAppSelector((state) => state.permissions.permissions);

  useEffect(() => {
    if (!ref.current) return;
    const resizeObserver = new ResizeObserver(() => {
      setHeight(ref.current?.clientHeight);
    });
    resizeObserver.observe(ref.current);
    return () => resizeObserver.disconnect();
  }, []);

  let totalSelectedPermissions = useAppSelector((state) => {
    let total: IPermission[] = [];
    permissions?.child?.forEach((permissionsP: any) => {
      if (
        permissionsP?.child?.length == 0 &&
        allSelected?.includes(permissionsP.id)
      ) {
        total.push(permissionsP);
      } else {
        permissionsP?.child?.forEach((permissionsC: any) => {
          if (allSelected?.includes(permissionsC.id)) {
            total.push(permissionsC);
          }
        });
      }
    });
    return total;
  });

  return (
    <div className="flex gap-x-6">
      <div
        ref={ref}
        className="w-1/2 h-fit min-h-[112px] p-6 border border-solid border-gray rounded space-y-2 overflow-auto"
      >
        {permissions.child.length > 0 ? (
          <>
            <Checkbox
              id={permissions.id}
              label={t(permissions.label)}
              isChecked={allSelected.includes(0)}
              onChange={() => dispatch(changePermissions(permissions))}
            />
            {permissions.child.map((permission: any) => (
              <div
                className="flex flex-col justify-center border border-solid border-gray px-2 py-1.5 rounded"
                key={permission.id!}
              >
                <Accordion
                  accordionButton={
                    <div className="flex items-center gap-x-2 text-left text-darkBlue rounded">
                      <PlusSVG className="text-gray ui-open:hidden" />
                      <MinusSVG className="text-gray ui-not-open:hidden" />
                      <Checkbox
                        id={permission.id!}
                        label={permission.label}
                        isChecked={allSelected.includes(permission.id)}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          dispatch(changePermissions(permission))
                        }
                      />
                    </div>
                  }
                >
                  <div className="flex flex-col gap-y-4 pl-5 mt-3 ml-10">
                    {permission.child?.map((subpermission: any) => (
                      <Checkbox
                        key={subpermission.id}
                        id={subpermission.id}
                        label={subpermission.label}
                        isChecked={allSelected.includes(subpermission.id)}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          dispatch(changePermissions(subpermission))
                        }
                      />
                    ))}
                  </div>
                </Accordion>
              </div>
            ))}
          </>
        ) : (
          [...Array(5)].map((_, i) => <Shimmer className="h-10.5" key={i} />)
        )}
      </div>
      <div
        className="w-1/2 flex flex-col"
        style={{ height: height == 0 ? "" : height }}
      >
        <div className="h-full p-6 border border-solid border-gray rounded space-y-2 overflow-auto">
          {totalSelectedPermissions?.map((permission: IPermission) => (
            <div
              className="flex border border-solid border-gray font-medium rounded"
              key={permission.id}
            >
              <p className="px-4 py-2 dark:text-white">{permission.label}</p>
              <div
                className="w-[41px] h-[41px] flex items-center justify-center text-white ml-auto bg-gray cursor-pointer"
                onClick={() => dispatch(changePermissions(permission))}
              >
                <XSVG />
              </div>
            </div>
          ))}
        </div>
        <Button
          className="w-full min-h-[42px] bg-green text-white mt-5"
          isLoading={isLoading}
          onClick={() => onSubmit(allSelected)}
        >
          {t("submit")}
        </Button>
      </div>
    </div>
  );
};

export default Permissions;
