import { useEffect } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import LoadingPage from "src/components/Common/LoadingPage";
import { getCookie } from "src/helper/custom_cookies";
import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import { useUserQuery } from "src/services/authService";
import { setOperatorOptions, setUser } from "src/store/Profile/profileStore";

const useAuth = () => {
  const user = getCookie("mcrmtkn");
  return user;
};

const ProtectedRoutes = () => {
  const {
    data: user,
    refetch,
    error: userError,
    isError: isUserError,
  } = useUserQuery(getCookie("mcrmtkn"), { skip: !getCookie("mcrmtkn") });
  const isAuth = useAuth();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        refetch();
      }
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    if (user) {
      dispatch(setUser(user.user));
      if (user.user.is_operator) {
        localStorage.setItem("ssnxprd", "180");
        dispatch(setOperatorOptions(user.operatorOptions));
      }
    }
  }, [user]);

  useEffect(() => {
    if (isUserError && userError["status"] == 401) {
      navigate("/login");
    }
  }, [isUserError]);

  return isAuth ? (
    user ? (
      <Outlet />
    ) : (
      <LoadingPage />
    )
  ) : (
    <Navigate to="/login" />
  );
};

export default ProtectedRoutes;
