import { useEffect, useState } from "react";
 import t from "src/hooks/useTranslate";
import { EGroupsCrudSteps } from "src/enums/e_groups_crud_steps";
import { validateName } from "src/helper/validation";
import { useAppDispatch } from "src/hooks/redux";
import {
  setAllInputs,
  setVisitedGroupStep,
} from "src/store/Groups/groupsStore";
import { nameRequiredPattern } from "src/utils/validationPatterns";
import Button from "../Common/Inputs/Button";
import Input from "../Common/Inputs/Input";

const GroupsForm = ({ onSubmit, groupForm, isGroupCreating, isGroupUpdating, isEdit = false }: any) => {
   
  const dispatch = useAppDispatch();
  const [validForm, setValidForm] = useState(false);

  useEffect(() => {
    validateName(groupForm.name, true)
      ? setValidForm(true)
      : setValidForm(false);
  }, [groupForm]);

  useEffect(() => {
    dispatch(setVisitedGroupStep(EGroupsCrudSteps.form));
  }, []);

  return (
    <form className="flex gap-x-6 items-end" onSubmit={onSubmit}>
      <Input
        className="min-w-[260px]"
        label={t("name") + "*"}
        placeholder={t("enter_group_name")}
        validpattern={nameRequiredPattern}
        defaultValue={groupForm ? groupForm.name : ""}
        onChange={(e) =>
          dispatch(setAllInputs({ name: e.target.name, value: e.target.value }))
        }
        name="name"
      />
      <Button
        className="w-80 bg-blue text-white"
        isLoading={isGroupCreating || isGroupUpdating}
        disabled={!validForm}
      >
        {t("continue")}
      </Button>
    </form>
  );
};

export default GroupsForm;
