import React, { useState, useEffect } from "react";
import ReactInputMask from "react-input-mask";

const InputCarAndMotoNumber = ({
  label,
  className,
  validPattern,
  onChange,
}) => {
  const [error, setError] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [mask, setMask] = useState("");
  const [value, setValue] = useState("");

  const blurHandler = (e: any) => {
    setDirty(true);
  };

  const maskChars = {
    "#": "[0-9]",
    $: "[a-zA-Z]{1,2}",
  };

  // const mask = "##-$-###";

  const onChangeHandler = (e) => {
    setValue(e.target.value);

    validPattern?.test(e.target.value.replaceAll("-", ""))
      ? setError(false)
      : setError(true);

    (!e.target.value.includes("_") ||
      (e.target.value == "__-__-___" && dirty)) &&
      onChange(e.target.value.replaceAll("-", ""));
  };

  const keyDownHandler = (e) => {

    const isLetter = /[a-zA-Z]/.test(e.key);
    const isNumber = /[0-9]/.test(e.key);
    const hasFirstLetter = /[a-zA-Z]/.test(value.substring(3, 4)); // Just the first character
    const hasSecondLetter = /[a-zA-Z]/.test(value.substring(4, 5));
    const carMask = "##-$$-###";
    const motoMask = "##-$-###";

    const specialKeys = [
      "Backspace",
      "ArrowRight",
      "ArrowLeft",
      "Enter",
      "Delete",
    ];

    if (specialKeys.includes(e.key)) {
      // Do nothing if it's a special key
      return;
    }
    if (isLetter && motoMask && hasFirstLetter ) {
      return;
    }

    if (hasFirstLetter && !hasSecondLetter) {
      if (isNumber) {
        setMask("##-$-###");
      } else if (isLetter) {
        setMask("##-$$-###");
      }
    } else {
      setMask("##-$$-###");
    }
  };

  return (
    <div className="w-full">
      {label && <p className="mb-2 dark:text-white">{label}</p>}
      <div
        className={[
          `flex px-4 border h-10.5 border-solid rounded bg-white dark:bg-softBlack ${
            validPattern && error && dirty ? "border-red" : "border-gray"
          }`,
          className,
        ].join(" ")}
      >
        <ReactInputMask
          mask={mask}
          className="w-full uppercase dark:bg-softBlack dark:text-white"
          //@ts-ignore
          maskChar="_"
          tabIndex={-1}
          onBlur={blurHandler}
           //@ts-ignore
          formatChars={maskChars}
          placeholder="10-AA-100"
          onChange={onChangeHandler}
          value={value}
          onKeyDown={keyDownHandler}
        />
      </div>
    </div>
  );
};

export default InputCarAndMotoNumber;
