import React from "react";

const ChatOption = ({ data, activeId }) => {
  return (
    <div className="flex items-center gap-x-4">
      <div className="min-w-[110px] min-h-[110px] max-w-[110px] max-h-[110px] w-[110px] h-[110px] rounded-full overflow-hidden">
        <img
          src={data?.announce.announce_img}
          alt={data?.announce.title || "car"}
          className="w-full h-full object-cover"
        />
      </div>
      <div
        className={[
          "w-full flex flex-col gap-y-2 px-8 py-4 bg-light rounded-lg dark:bg-softBlack dark:text-white hover:brightness-75 dark:hover:brightness-125 cursor-pointer",
          activeId === data?.id ? "brightness-75 dark:brightness-125" : "",
        ].join(" ")}
      >
        <p className="font-medium text-blue">{data?.announce.title}</p>
        <p className="font-medium">Satıcı: {data?.announce.seller_name}</p>
        <p className="font-medium">Alıcı: {data?.announce.buyer_name}</p>
      </div>
    </div>
  );
};

export default ChatOption;
