import { FC, useEffect, useState } from "react";
import { useDebounceEffect } from "src/hooks/useDebounceEffect";
import { IInput } from "src/types/Common/i_input";

const Input: FC<IInput> = ({
  label,
  className,
  validpattern,
  onChange,
  onDebouncedValue,
  defaultValue,
  children,
  textarea = false,
  ...inputProps
}) => {
  const [error, setError] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [value, setValue] = useState(defaultValue);

  const blurHandler = (e: any) => {
    setDirty(true);
  };

  useEffect(() => {
    setValue(defaultValue);
    setDirty(false);
  }, [defaultValue]);

  useDebounceEffect(
    () => {
      dirty && onDebouncedValue?.(value);
    },
    500,
    [value]
  );

  return (
    <div className="w-full flex flex-col">
      {label && <p className="mb-2 dark:text-white">{label}</p>}
      <div
        className={[
          `flex px-4 border h-10.5 border-solid rounded bg-white dark:bg-softBlack ${
            validpattern && error && dirty ? "border-red" : "border-gray"
          }`,
          className,
        ].join(" ")}
      >
        {textarea ? (
          <textarea
            {...inputProps}
            className="w-full dark:bg-softBlack dark:text-white resize-none py-2"
            onBlur={(e) => blurHandler(e)}
            value={value}
            onChange={(e) => {
              validpattern?.test(e.target.value)
                ? setError(false)
                : setError(true);
              onChange?.(e as any);
            }}
            onInput={(e) => {
              setDirty(true);
              setValue((e.target as HTMLInputElement).value);
            }}
          />
        ) : (
          <input
            {...inputProps}
            className="w-full dark:bg-softBlack dark:text-white"
            onBlur={(e) => blurHandler(e)}
            value={value}
            onChange={(e) => {
              validpattern?.test(e.target.value)
                ? setError(false)
                : setError(true);
              onChange?.(e);
            }}
            onInput={(e) => {
              setDirty(true);
              setValue((e.target as HTMLInputElement).value);
            }}
          />
        )}

        {children}
      </div>
    </div>
  );
};

export default Input;
