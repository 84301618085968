import React, {FC} from 'react';
import t from "../../../hooks/useTranslate";
import Header from "../../../components/Header/Header";
import CommonInfo from "../../../components/AnnouncementsDetails/CommonInfo";

const Details:FC<{}> = () => {

    return (
        <>
            <Header title={t("Details")} backLink="/announcementsTwo">
            </Header>
            <div className="relative flex items-center justify-between gap-x-4 p-4 border-b border-solid border-gray">
                <p className="text-16 font-bold dark:text-white">
                    {t("get")}
                </p>
            </div>
            <CommonInfo />
        </>
    );
};

export default Details;
