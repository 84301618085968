import {
  useState,
  useCallback,
  ChangeEvent,
  useEffect,
  useRef,
  SyntheticEvent,
} from "react";
import t from "src/hooks/useTranslate";
import Input from "../Common/Inputs/Input";
import InputFile from "../Common/Inputs/InputFile";
import { ReactComponent as EditSVG } from "src/assets/vectors/edit.svg";
import { ReactComponent as TrashSVG } from "src/assets/vectors/trash.svg";
import { ReactComponent as XSVG } from "src/assets/vectors/x.svg";
import { ReactComponent as FileTextSVG } from "src/assets/vectors/file_text.svg";
import { ddmmyyyy } from "src/helper/getFormattedDate";
import Button from "../Common/Inputs/Button";
import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import { setUserFile, setUserFileName } from "src/store/Users/usersStore";
import { IFile } from "src/types/i_file";
import { successToast } from "src/store/Settings/toastStore";
import Modal from "../Common/Modal";

const UsersFiles = ({
  isLoading,
  onFileDeleting,
  onFileAdded,
  onFileDeleted,
  onSubmit,
  onDelete,
}: any) => {
  const dispatch = useAppDispatch();
  const [modal, setModal] = useState(false);
  const [fileIdToDelete, setFileIdToDelete] = useState<number | null>(null);
  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState<File | null>(null);
  const allFiles = useAppSelector((state) => state.users.users_form.files);
  const files = useAppSelector((state) => state.users.user_file);

  const setFileToUpload = (e: SyntheticEvent) => {
    e.preventDefault();
    if (files.file && files.name?.trim() !== "") {
      onSubmit();
    }
  };

  useEffect(() => {
    if (onFileAdded) {
      setFileName("");
      setFile(null);
      dispatch(successToast("File is added"));
    }
  }, [onFileAdded]);

  useEffect(() => {
    if (onFileDeleted) {
      setModal(false);
      dispatch(successToast("File is deleted"));
    }
  }, [onFileDeleted]);

  useEffect(() => {
    dispatch(setUserFileName(fileName));
  }, [fileName]);

  useEffect(() => {
    dispatch(setUserFile(file));
    if (fileName.trim() == "") {
      setFileName(file ? file.name : "");
    }
  }, [file]);

  return (
    <>
      <form className="flex gap-x-6 pb-11" onSubmit={setFileToUpload}>
        <Input
          label={t("filename") + "*"}
          className="min-w-[260px] w-full"
          maxLength={80}
          defaultValue={fileName}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setFileName(e.target.value)
          }
        />
        <InputFile
          label={t("file") + "*"}
          fileName={file?.name}
          className="min-w-[260px] w-full"
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setFile(e.target.files![0])
          }
        />
        <Button
          className="min-w-[160px] bg-blue whitespace-nowrap text-white font-semibold mt-auto"
          isLoading={isLoading}
          disabled={allFiles?.file && allFiles?.name?.trim() == ""}
        >
          {t("remember")}
        </Button>
      </form>
      <div>
        <div className="flex py-6 border-y border-solid border-gray dark:text-white">
          <p className="w-24">№</p>
          <p className="mr-auto">{t("file")}</p>
          <p className="w-48">{t("origin_history")}</p>
          <p className="w-28">{t("operations")}</p>
        </div>
        {allFiles &&
          allFiles.map((file: IFile) => (
            <div className="flex py-4 dark:text-white" key={file.id}>
              <p className="w-24">{file.id}</p>
              <div className="mr-auto">
                <a
                  href={file.url}
                  className="flex items-center gap-x-2"
                  download={file.name}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FileTextSVG />
                  {file.name}
                </a>
              </div>
              <p className="w-48">{ddmmyyyy(new Date(file.created_at))}</p>
              <div className="w-28 flex items-center gap-x-4">
                <TrashSVG
                  className="text-red cursor-pointer"
                  onClick={() => {
                    setFileIdToDelete(file.id);
                    setModal(true);
                  }}
                />
              </div>
            </div>
          ))}
      </div>
      <Modal
        visible={modal}
        setVisible={setModal}
        dialogClassName="min-w-[450px]"
      >
        <div className="flex items-center justify-between gap-x-4 dark:text-white p-4 border-b border-solid border-gray">
          <p className="text-16 font-bold">{t("delete_caution_question")}</p>
          <XSVG
            className="text-gray cursor-pointer dark:text-white"
            onClick={() => setModal(false)}
          />
        </div>
        <div className="p-4">
          <Button
            className="w-full bg-red mt-8 text-white text-16 font-medium"
            isLoading={onFileDeleting}
            onClick={() => onDelete(fileIdToDelete)}
          >
            Sil
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default UsersFiles;
